import { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import {
	getBankTransferRequest,
	getBankTransferDepositRequest,
} from 'redux/reducers/payments/reducer';
import { getBankDepositTransfer } from 'redux/reducers/payments/selectors';
import { IChooseBankAccount } from './types';

export const ChooseBankAccount: FC<IChooseBankAccount> = ({
	assetId,
	currentBank,
	onChange,
	setActiveView,
	goBack,
}) => {
	const dispatch = useDispatch();
	const [selectedBank, setSelctedBank] = useState(currentBank);
	const depositTransfer = useSelector(getBankDepositTransfer);

	useEffect(() => {
		dispatch(getBankTransferRequest());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getBankTransferDepositRequest({ params: { asset_id: String(assetId) } }));
	}, [dispatch, assetId]);

	const handleContinue = () => {
		if (!selectedBank) {
			return;
		}
		onChange(selectedBank);
		setActiveView('currency');
	};

	return (
		<div className="create-offer">
			<div className="create-offer__header">
				<button type="button" onClick={goBack} className="back-step">
					<span className="back-step__img">
						<span className="back-step__arrow icon-arrow" />
					</span>
					<span className="back-step__text">{L.translate('Global.back')}</span>
				</button>
			</div>
			<div className="create-offer__main create-offer__main--border-none create-offer__main--padding-big">
				<div className="create-offer__form">
					<h2 className="section-title">{String(L.translate('DepositCrypto.deposit'))}</h2>
					<div className="form-block">
						<div className="add-card-list add-card-list--type2">
							{depositTransfer?.map((item) => (
								<div className="add-card-item">
									<div className="radio radio--type2 radio--m0">
										<label className="radio__label">
											<input
												type="radio"
												className="hidden"
												name="tokens-group"
												checked={item.id === selectedBank?.id}
												onChange={() => setSelctedBank(item)}
											/>
											<span className="radio__item" />
											<span className="radio__text-group">
												<span className="radio__text radio__text--regular">
													{item.is_partner
														? item.beneficiary_bank
														: L.translate('Deposit.other_bank')}
												</span>
											</span>
										</label>
									</div>
									{!!item.is_partner && item.bank_icon && (
										<div className="add-card-item__logo add-card-item__logo--bank ">
											<img src={item.bank_icon} alt="visa" />
										</div>
									)}
								</div>
							))}
						</div>
					</div>
					<div className="form-submit form-submit--more-mt">
						<button
							type="button"
							className="button button--full-width"
							onClick={handleContinue}
							disabled={!selectedBank}
						>
							{L.translate('Global.continue')}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
