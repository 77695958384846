/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, useEffect, FC, useState, MouseEvent } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
	getP2PQuickRequest,
	setCurrentType,
	setCurrentCoin,
	setCurrency,
	setCurrentPaymnent,
	getPaymentMethodsRequest,
	setPaymentAmount,
} from 'redux/reducers/p2p/reducer';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import {
	getCurrentCoin,
	getP2PAssets,
	getCurrencyItem,
	getCurrentType,
	getCurrentPayment,
	getCurrencies,
} from 'redux/reducers/p2p/selectors';
import {
	IPaymentItem,
	IGetAssets,
	IGetCurrenciesItem,
	IP2PPaymentActiveMethod,
} from 'redux/reducers/p2p/types';
import { numberValidation } from 'services/utils/numberValidation';
import { dataLayer } from 'services/gtm';

import CurrencyButton from 'ui/CurrencyButton';
import ButtonSwitcher from 'ui/ButtonSwitcher';
import {
	getAuthIsAuthenticated,
	getAccountP2P,
	getUserVerified,
} from 'redux/reducers/auth/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import figure1 from 'assets/images/general/p2p-figure1.svg';
import figure2 from 'assets/images/general/p2p-figure2.svg';
import TableNav from './TableNav';
import P2PTable from './P2PTable';
import P2PModal from './P2PModal';

/* eslint-disable no-debugger */

export const getCoinObjByName = (coinCode: any, coinObjectsArray: any) => {
	// if (coinCode === 'all') {
	// 	return { id: 0, code: 'all' };
	// }
	return coinObjectsArray?.find((item: any) => {
		return coinCode === item.code;
	});
};

// ==========================================:
const P2PMarketplace: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation<any>();
	const p2pAssets = useSelector(getP2PAssets);
	const type = useSelector(getCurrentType);
	// const isAll = currentCoin === 'ALL';
	const coin = useSelector(getCurrentCoin);
	const accountP2P = useSelector(getAccountP2P);
	const currenciesList = useSelector(getCurrencies);
	const currencyItem = useSelector(getCurrencyItem);
	const paymentItem = useSelector(getCurrentPayment);
	const searchPerPage = 10;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [currencies, setCurrencies] = useState<Array<IGetAssets> | null>([]);
	const [selectItem, setSelectItem] = useState<IGetCurrenciesItem | null>(currencyItem || null);
	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);
	const [show] = useState(+searchPerPage);
	// const [payment, setPayment] = useState(paymentItem?.method || 'All payments');
	const [amount, setAmount] = useState('');
	const [selectPaymnet, setSelectPaymnet] = useState<IPaymentItem | null>(paymentItem || null);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const userVerified = useSelector(getUserVerified);

	const filterData = (d: any) =>
		Object.keys(d).reduce((acc: any, i) => {
			if (d[i]) {
				acc[i] = d[i];
			}
			return acc;
		}, []);

	const memoSearch = useCallback(
		(params) => {
			dispatch(getP2PQuickRequest({ params }));
		},
		[dispatch],
	);

	const findType = (e: any): any => {
		if (e.includes('/buy')) return 'buy';
		if (e.includes('/sell')) return 'sell';
		return null;
	};

	const findCoin = (e: any): any => {
		if (e.includes('/all')) return 'all';
		if (e.includes('/eth')) return 'eth';
		if (e.includes('/btc')) return 'btc';
		if (e.includes('/dar')) return 'dar';
		if (e.includes('/usdt')) return 'usdt';
		if (e.includes('/pax')) return 'pax';
		if (e.includes('/dai')) return 'dai';
		if (e.includes('/usdc')) return 'usdc';
		if (e.includes('/trx')) return 'trx';
		if (e.includes('/mrt')) return 'mrt';
		return null;
	};
	const selectedCurrencyObject = getCoinObjByName(coin, currencies);
	const coinId = selectedCurrencyObject?.id;
	const currentCurrencyId = currencyItem?.id;

	const assetObj = coinId ? { asset_id: coinId } : {};
	const initPayment = {
		id: 0,
		payment_name: String(L.translate('P2P.all_payments')),
		payment_name_eng: 'All payments',
	};

	const searchData = {
		currency_id: currentCurrencyId,
		...assetObj,
		amount: +amount,
		payment_type: paymentItem?.payment_name_eng === 'All payments' ? null : paymentItem?.id,
		type,
		current_page: currentPage,
		per_page: show,
	};

	const searchType = findType(location.pathname);
	const searchCoin = findCoin(location.pathname);

	const coinClick = (setCoin: string) => {
		setCurrentPage(1);
		if (!searchCoin) {
			history.replace(`/p2p-trade/${type}/${setCoin}`);
			return;
		}
		history.replace(location.pathname.replace(searchCoin, setCoin));
	};

	const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
		const { name } = e.currentTarget;
		dataLayer.push({
			event: name === 'buy' ? 'p2p_buy' : 'p2p_sell',
		});
		// const ty = name === 'sell' ? 'buy' : 'sell';
		dispatch(setCurrentType(name));
		setCurrentPage(1);
		history.replace(location.pathname.replace(searchType, name));
	};

	useEffect(() => {
		setCurrencies(p2pAssets);
	}, [p2pAssets]);

	useEffect(() => {
		if (searchType) {
			dispatch(setCurrentType(searchType));
		}
		if (searchCoin) {
			dispatch(setCurrentCoin(searchCoin));
		}
		if (location.state) {
			// const coincidences = currenciesList?.find(
			// 	(item: IGetCurrenciesItem) => item?.code === location?.state?.fiat,
			// );
			// debugger;
			// setSelectItem(location?.state?.fiat);
			// if (coincidences) {
			dispatch(setCurrency({ ...location.state.currency }));
			setSelectItem({ ...location.state.currency });
			// }
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, searchType, searchCoin, location, currenciesList]);

	useEffect(() => {
		if (coin !== searchCoin && searchCoin) return;
		if (currencyItem?.id && coinId === 0 && type && show) {
			const search = filterData(searchData);
			if (search.currency_id && search.type && search.per_page && search.current_page) {
				search.type = search.type === 'sell' ? 'buy' : 'sell';
				memoSearch({ ...search });
			}
		} else if (currencyItem?.id && coinId && type && show) {
			const search = filterData(searchData);
			if (
				search.currency_id &&
				search.asset_id &&
				search.type &&
				search.per_page &&
				search.current_page
			) {
				search.type = search.type === 'sell' ? 'buy' : 'sell';
				memoSearch({ ...search });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dispatch,
		currencyItem,
		coinId,
		type,
		show,
		history,
		currentPage,
		coin,
		paymentItem,
		searchCoin,
		amount,
	]);

	useEffect(() => {
		dispatch(setPaymentAmount(''));
		dispatch(userSettingsRequest());
	}, [dispatch]);

	const setChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;

		const isValid = numberValidation(val);
		if (!isValid) {
			return;
		}
		if (Number(val) === 0) {
			setAmount('');
		}
		setAmount(e.target.value);
	};
	const handleSelectPaymnets = (value: IPaymentItem | null): void => {
		setSelectPaymnet(value);
		dispatch(setCurrentPaymnent(value));
	};
	const handleSelectCoin = (value: any): void => {
		// const codeCoin = e?.currentTarget?.dataset?.code;
		// const codeCoin = value.code;
		// if (codeCoin) {
		setSelectItem(value);
		history.push({ state: { currency: value } });
		dispatch(setCurrency(value));
		dispatch(setCurrentPaymnent(initPayment));
		setSelectPaymnet(initPayment);
		// }
		// setAmount('');
		// setSelectWallet(value);
		// // setSelectWalletNetworks(null);
		// // setWalletNetworks(value?.networks);
		// setAmount('');
	};

	const handleAction = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
		if (!authIsAuthenticated) {
			history.push({
				pathname: `/login`,
				search: `redirect=/p2p-trade`,
			});
			return;
		}
		if (!accountP2P) {
			e.preventDefault();
			setOpenModal(true);
			return;
		}
		// if (!checkTrading(user)) return;
		history.push({
			pathname: `/p2p-trading/details`,
			search: `id=${e.currentTarget.name}`,
			state: 0,
		});
	};

	useEffect(() => {
		const params: IP2PPaymentActiveMethod = {
			currency_id: String(currentCurrencyId),
		};
		dispatch(getPaymentMethodsRequest(params));
	}, [dispatch, currentCurrencyId]);

	return (
		<>
			<div className="content-wrapper  content-wrapper--bg">
				<div className="p2p-market-section">
					<div className="p2p-figure p2p-figure--first">
						<img src={figure1} alt="figure" />
					</div>
					<div className="p2p-figure p2p-figure--second">
						<img src={figure2} alt="figure" />
					</div>
					<div className="container container--big">
						<div className="page-info">
							<h1 className="page-info__title">
								{String(L.translate('P2P.Marketplace.p2p_title'))}
							</h1>
							<p className="page-info__desc">
								{String(L.translate('P2P.Marketplace.p2p_description'))}
							</p>
						</div>
						<ButtonSwitcher handleClick={handleClick} type={type} />
						<CurrencyButton cb={coinClick} />
						<TableNav
							amount={amount}
							handleSearch={setChangeAmount}
							// currencyItem={currencyItem}
							selectPaymnet={selectPaymnet}
							selectItem={selectItem}
							handleSelectCoin={handleSelectCoin}
							handleSelectPaymnets={handleSelectPaymnets}
							setOpenModal={setOpenModal}
						/>
						<P2PTable
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							selectItem={selectItem}
							handleAction={handleAction}
						/>
					</div>
				</div>
			</div>
			<P2PModal
				title={String(L.translate('P2P.Marketplace.set_nickname'))}
				openModal={openModal}
				closeModal={closeModal}
			/>
		</>
	);
};

export default P2PMarketplace;
