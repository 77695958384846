/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IOurPricesStore,
	TFiatFees,
	TCryptoFees,
	TP2PLimitations,
	TFiatLimitsResponse,
	TCryptoLimitsResponse,
	TConvertFees,
	TSpotFees,
} from './types';

// ==========================================:
export const initialState: IOurPricesStore = {
	loading: false,
	fiatFees: null,
	cryptoFees: null,
	p2pLimitations: null,
	fiatLimits: null,
	cryptoLimits: null,
	limitsLoading: false,
	limitsCryptoLoading: false,
	convertFeesLoading: false,
	convertFees: null,
	spotFeesLoading: false,
	spotFees: null,
};

// ==========================================:
const ourPrices = createSlice({
	name: '@@ourPrices',
	initialState,
	reducers: {
		getFiatFeesRequest: (state) => {
			const ourPricesState = state;
			ourPricesState.loading = true;
		},
		getFiatFeesSuccess: (state, { payload }: PayloadAction<TFiatFees>) => {
			const ourPricesState = state;
			ourPricesState.loading = false;
			ourPricesState.fiatFees = payload;
		},
		getCryptoFeesRequest: (state) => {
			const ourPricesState = state;
			ourPricesState.loading = true;
		},
		getCryptoFeesSuccess: (state, { payload }: PayloadAction<TCryptoFees>) => {
			const ourPricesState = state;
			ourPricesState.loading = false;
			ourPricesState.cryptoFees = payload;
		},
		getP2PLimitationsRequest: (state) => {
			const ourPricesState = state;
			ourPricesState.loading = true;
		},
		getP2PLimitationsSuccess: (state, { payload }: PayloadAction<TP2PLimitations>) => {
			const ourPricesState = state;
			ourPricesState.loading = false;
			ourPricesState.p2pLimitations = payload;
		},
		getFiatFeesLimitsRequest: (state) => {
			const ourPricesState = state;
			ourPricesState.loading = true;
		},
		getFiatFeesLimitsSuccess: (state, { payload }: PayloadAction<TFiatLimitsResponse>) => {
			const ourPricesState = state;
			ourPricesState.loading = false;
			ourPricesState.fiatLimits = payload;
		},
		getCryptoFeesLimitsRequest: (state) => {
			const ourPricesState = state;
			ourPricesState.limitsLoading = true;
		},
		getCryptoFeesLimitsSuccess: (state, { payload }: PayloadAction<TCryptoLimitsResponse>) => {
			const ourPricesState = state;
			ourPricesState.limitsCryptoLoading = false;
			ourPricesState.cryptoLimits = payload;
		},
		getConvertFeesRequest: (state) => {
			const ourPricesState = state;
			ourPricesState.limitsCryptoLoading = true;
		},
		getConvertFeesSuccess: (state, { payload }: PayloadAction<TConvertFees>) => {
			const ourPricesState = state;
			ourPricesState.convertFeesLoading = false;
			ourPricesState.convertFees = payload;
		},
		getSpotFeesRequest: (state) => {
			const ourPricesState = state;
			ourPricesState.spotFeesLoading = true;
		},
		getSpotFeesSuccess: (state, { payload }: PayloadAction<TSpotFees>) => {
			const ourPricesState = state;
			ourPricesState.spotFeesLoading = false;
			ourPricesState.spotFees = payload;
		},
		ourPricesInitState: () => initialState,
	},
});

export default ourPrices.reducer;
export const {
	getFiatFeesRequest,
	getFiatFeesSuccess,
	getCryptoFeesRequest,
	getCryptoFeesSuccess,
	getP2PLimitationsRequest,
	getP2PLimitationsSuccess,
	ourPricesInitState,
	getFiatFeesLimitsRequest,
	getFiatFeesLimitsSuccess,
	getCryptoFeesLimitsRequest,
	getCryptoFeesLimitsSuccess,
	getConvertFeesRequest,
	getConvertFeesSuccess,
	getSpotFeesRequest,
	getSpotFeesSuccess,
} = ourPrices.actions;
