/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, ChangeEvent } from 'react';
import L from 'i18n-react';
import Modal from 'ui/Modal';
import { useDispatch } from 'react-redux';
import { postNickNameRequest } from 'redux/reducers/p2p/reducer';
import { IConfirmDeletePopupProps } from './types';

const P2PModal: FC<IConfirmDeletePopupProps> = ({ openModal, closeModal }) => {
	const dispatch = useDispatch();
	const [nickname, setNickname] = useState('');
	const handleChangeNick = (e: ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.currentTarget;
		setNickname(value);
	};

	const handleSetNickname = () => {
		if (!nickname) return;
		dispatch(postNickNameRequest({ username: nickname }));
		setNickname('');
		closeModal();
	};
	return (
		<Modal openModal={openModal} closeModal={closeModal}>
			<div className="popup-header">
				<p className="popup-header__title">{String(L.translate('P2P.Marketplace.set_nickname'))}</p>
			</div>
			<div className="popup-body popup-body--margin-none">
				<div className="popup-text popup-text--center">
					<p>{String(L.translate('P2P.Marketplace.set_nick_description'))}</p>
				</div>
				<div className="popup-form">
					<div className="input">
						<div className="input-wrapper">
							<input
								className="input-item input-item--with-count"
								type="text"
								autoComplete="off"
								onChange={handleChangeNick}
								name="nickname"
								placeholder="Nickname"
								maxLength={20}
								value={nickname}
							/>
							<span className="field-hint">{nickname.length}/20</span>
						</div>
					</div>
				</div>
			</div>
			<div className="popup-submit popup-submit--sb">
				<button
					className="button button--full-width"
					disabled={!nickname}
					type="button"
					onClick={handleSetNickname}
				>
					{String(L.translate('P2P.Marketplace.set_nickname'))}
				</button>
				{/* <button className="button button--second-grey button--full-width" type="button" onClick={closeModal}>
								{String(L.translate('Base.Modals.ConfirmDeletePopup.no_button'))}
							</button> */}
			</div>
		</Modal>
	);
};

export default P2PModal;
