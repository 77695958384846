/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiTopPairWithChart } from 'services/api/assetPairs/types';
import {
	IAssetPairsFeesAndLimitsResponsePayload,
	IAssetPairsResponsePayload,
	IAssetPairsStore,
} from './types';

// ==========================================:
export const initialState: IAssetPairsStore = {
	assetPairs: null,
	assetPairsFavorite: null,
	assetPairsLoader: false,
	assetPairsFeesAndLimits: null,
	assetPairsFeesAndLimitsLoader: false,
	topPairsWithChart: null,
	topPairsWithChartLoader: false,
	topMorePairsWithChartLoader: false,
};
// ==========================================:
const assetPairs = createSlice({
	name: '@@assetPairs',
	initialState,
	reducers: {
		getAssetPairsRequest: (state) => {
			const assetPairsRequestState = state;

			assetPairsRequestState.assetPairsLoader = true;
		},
		getAssetPairsRequestFavorite: () => {},
		putAssetPairsRequestFavoriteId: (state, action) => {},
		getAssetPairsSuccessFavorite: (state, action) => {
			const { payload } = action;
			const assetPairsSuccessState = state;
			assetPairsSuccessState.assetPairsFavorite = payload;
		},
		getAssetPairsSuccess: (state, action: PayloadAction<IAssetPairsResponsePayload>) => {
			const { payload } = action;
			const assetPairsSuccessState = state;

			assetPairsSuccessState.assetPairs = payload;
			assetPairsSuccessState.assetPairsLoader = false;
		},

		updateAssetPairsSuccess: (state, action: PayloadAction<IAssetPairsResponsePayload>) => {
			const { payload } = action;
			const assetPairsUpdateState = state;

			assetPairsUpdateState.assetPairs = payload;
			assetPairsUpdateState.assetPairsLoader = false;
		},

		getAssetPairsFeesAndLimitsRequest: (state) => {
			const assetPairsFeesAndLimitsRequestState = state;

			assetPairsFeesAndLimitsRequestState.assetPairsFeesAndLimitsLoader = true;
		},

		getAssetPairsFeesAndLimitsSuccess: (
			state,
			action: PayloadAction<IAssetPairsFeesAndLimitsResponsePayload>,
		) => {
			const { payload } = action;
			const assetPairsFeesAndLimitsSuccessState = state;

			assetPairsFeesAndLimitsSuccessState.assetPairsFeesAndLimits = payload;
			assetPairsFeesAndLimitsSuccessState.assetPairsFeesAndLimitsLoader = false;
		},

		getTopPairsWithChartRequest: (state) => {
			state.topPairsWithChartLoader = true;
		},
		getTopPairsWithChartSuccess: (state, { payload }: PayloadAction<IApiTopPairWithChart[]>) => {
			state.topPairsWithChart = payload;
			state.topPairsWithChartLoader = false;
		},
		getTopPairsWithChartError: (state) => {
			state.topPairsWithChartLoader = false;
		},
		getMoreTopPairsWithChartRequest: (state) => {
			state.topMorePairsWithChartLoader = true;
		},
		getMoreTopPairsWithChartSuccess: (
			state,
			{ payload }: PayloadAction<IApiTopPairWithChart[]>,
		) => {
			const requestState = state;
			if (requestState.topPairsWithChart) {
				requestState.topPairsWithChart = [...requestState.topPairsWithChart, ...payload];
			}
			state.topMorePairsWithChartLoader = false;
		},
		getMoreTopPairsWithChartError: (state) => {
			state.topMorePairsWithChartLoader = false;
		},

		assetPairsInitState: () => initialState,
	},
});

export default assetPairs.reducer;
export const {
	getAssetPairsRequest,
	putAssetPairsRequestFavoriteId,
	getAssetPairsRequestFavorite,
	getAssetPairsSuccessFavorite,
	getAssetPairsSuccess,
	updateAssetPairsSuccess,
	getAssetPairsFeesAndLimitsRequest,
	getAssetPairsFeesAndLimitsSuccess,
	assetPairsInitState,
	getTopPairsWithChartRequest,
	getTopPairsWithChartSuccess,
	getTopPairsWithChartError,
	getMoreTopPairsWithChartRequest,
	getMoreTopPairsWithChartSuccess,
	getMoreTopPairsWithChartError,
} = assetPairs.actions;
