import { FC } from 'react';
import { useSelector } from 'react-redux';
import logo from 'assets/images/general/new-logo.webp';
import logoNew from 'assets/images/general/new-logo-light.webp';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { IAppLogo } from './types';

// ==========================================:
const AppLogo: FC<IAppLogo> = ({ isNew }) => {
	const theme = useSelector(getColorTheme);

	return (
		<a href="/" className="logo" aria-label="Home page button">
			<span className="logo__img">
				<img
					src={isNew || theme === 'dark' ? logoNew : logo}
					alt="Logo"
					width="100%"
					height="auto"
				/>
			</span>
		</a>
	);
};

export default AppLogo;
