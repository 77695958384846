/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getP2PAssets, getCurrentCoin } from 'redux/reducers/p2p/selectors';
import { IGetAssets } from 'redux/reducers/p2p/types';
import { renameCoin } from 'services/utils/renameCoin';

import { getAssetsList } from 'redux/reducers/assets/selectors';
import { addImges } from 'services/utils/changeArrey';
import { setCurrentCoin } from '../../redux/reducers/p2p/reducer';
// import { storeCurrentCoin } from '../../redux/selectors';
// import setCurrentCoin from '../../redux/currentCoin/action';
// import { getCurrenciesFromWallets, renameCoin } from '../../services/helpers';
// import { trading } from '../../services/API';
// import notification from '../../services/notification';
/* eslint-disable no-debugger */
import { ICurrencyButtonProps } from './type';
/* eslint-disable consistent-return */
// const reqSvgs = require.context(
//   '../../../public/img/icons-svg',
//   true,
//   /\.svg$/,
// );

// const paths = reqSvgs.keys();
// const svgs = paths.map(path => reqSvgs(path));

// export const getCurrenciesData = async () => {
//   try {
//     const { data, status } = await trading.advertisement.getCurrencies();
//     if (status >= 200 && status < 300) {
//       return data;
//     }
//   } catch (error) {
//     notification({ type: 'error', message: L.translate('t_0274') });
//     return [];
//   }

//   return [];
// };

const CurrencyButton: FC<ICurrencyButtonProps> = ({ cb }) => {
	const dispatch = useDispatch();
	const currentCoinAsset = useSelector(getCurrentCoin);
	const asset = useSelector(getAssetsList);
	const p2pAssets = useSelector(getP2PAssets);
	// const currentType = useSelector(state => state.currentType);
	// const currencies = getCurrenciesFromWallets(wallets);
	const [currencies, setCurrencies] = useState<Array<IGetAssets> | null>([]);

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		dispatch(setCurrentCoin(name));
		if (cb && name) {
			cb(name);
		}
	};

	useEffect(() => {
		// setCurrencies(p2pAssets);
		setCurrencies(addImges(p2pAssets, asset));
	}, [p2pAssets, asset]);

	// useEffect(() => {
	// 	if (currentCoin === 'all' && currencies?.length > 0 && isAllDisabled) {
	// 		const newCurrency = currencies[0]?.code;

	// 		dispatch(setCurrentCoin(newCurrency));
	// 		if (cb && newCurrency) {
	// 			cb(newCurrency);
	// 		}
	// 	}
	// }, [currencies]);

	return (
		<div className="choice-currency">
			{
				<>
					{currencies?.map((item: any) => {
						if (item?.code === 'dar') {
							return;
						}
						return (
							<button
								key={item?.id}
								name={item?.code}
								onClick={handleClick}
								type="button"
								className={
									currentCoinAsset === item?.code
										? 'choice-currency__btn btn-currency btn-currency--active'
										: 'choice-currency__btn btn-currency'
								}
							>
								<div className="btn-currency__icon">
									<img src={item?.img_path} alt="" />
								</div>
								<span className="btn-currency__text"> {renameCoin(item?.code)}</span>
								<span
									className={
										currentCoinAsset === item?.code
											? 'btn-currency__check  icon-Checkbox'
											: 'btn-currency__check'
									}
								/>

								{/* <label>
                  <img
                    src={item ?.img_path}
                    style={{
                      width: '34px',
                      height: '34px',
                      marginRight: '10px',
                    }}
                  />
                  <span className="page-btn__inner">
                    {renameCoin(item ?.code)}
                  </span>
                </label> */}
							</button>
						);
					})}
				</>
			}
		</div>
	);
};
/* eslint-disable */
export default CurrencyButton;
