import { useSelector } from 'react-redux';
import L from 'i18n-react';
import BlueFigure1 from 'assets/images/content/blue-figure1.png';
import BlueFigure2 from 'assets/images/content/blue-figure2.png';
import BlueFigure1Dark from 'assets/images/content/blue-figure1__dark.png';
import BlueFigure2Dark from 'assets/images/content/blue-figure2__dark.png';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import ConvertFormAuth from './ConvertFormAuth';
import ConvertFormGuest from './ConvertFormGuest';

const Convert = () => {
	const isAuth = useSelector(getAuthIsAuthenticated);

	return (
		<section className="section section--convert">
			<div className="convert-box">
				<div className="convert-figure-box">
					<div className="convert-figure convert-figure--type1">
						<img src={BlueFigure1} className="for-light" alt="" />
						<img src={BlueFigure1Dark} className="for-dark" alt="" />
					</div>
					<div className="convert-figure convert-figure--type2">
						<img src={BlueFigure2} className="for-light" alt="" />
						<img src={BlueFigure2Dark} className="for-dark" alt="" />
					</div>
				</div>

				<div className="convert-box__inner">
					<div className="info-content info-content--convert ">
						<h2 className="section-title section-title--white section-title--big">
							{L.translate('ConvertForm.convert_title')}
						</h2>
						<p className="info-content__desc info-content__desc--white-full">
							{L.translate('ConvertForm.section_description')}{' '}
							{L.translate('ConvertForm.section_description_2')}
						</p>
					</div>
					{isAuth ? <ConvertFormAuth /> : <ConvertFormGuest />}
				</div>
			</div>
		</section>
	);
};

export default Convert;
