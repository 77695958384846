import { isAuth, isGuest } from 'routes/authMiddlewares';

// Static pages:
import ChartPage from 'pages/Static/ChartPage';
import Home from 'pages/Static/Home';
import Trading from 'pages/Static/Trading';

import Login from 'pages/Auth/Login';
import Registration from 'pages/Auth/Registration';
import RegistrationDone from 'pages/Auth/Registration/done';
import CreateNewPassword from 'pages/Auth/CreateNewPassword';
import FiatAndSpot from 'pages/Dashboard/FiatAndSpot';
import OpenOrders from 'pages/Dashboard/OpenOrders';
import OrderHistory from 'pages/Dashboard/OrderHistory';
import TradeHistory from 'pages/Dashboard/TradeHistory';
import MarginWallet from 'pages/Dashboard/Margin';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import Forgot2fa from 'pages/Auth/ForgotTwofa';
import EmailConfirm from 'pages/Auth/EmailConfirm';
import GeneralSettings from 'pages/Account/GeneralSettings';
import InviteEarn from 'pages/Account/InviteEarn';
import Auth2fa from 'pages/Account/Auth2fa';
import Payments from 'pages/Account/Payments';
import Notifications from 'pages/Account/Notifications';
import IPConfirmation from 'pages/Auth/IPConfirmation';
import Deposit from 'pages/Dashboard/Deposit';
import DepositCrypto from 'pages/Dashboard/DepositCrypto';
import Withdraw from 'pages/Dashboard/Withdraw';
import WithdrawCrypto from 'pages/Dashboard/WithdrawCrypto';
// import WithdrawStable from 'pages/Dashboard/WithdrawStable';
import P2P from 'pages/Dashboard/P2P';
import ConvertPage from 'pages/Static/Convert';
import Notification from 'pages/Static/Notification';
import TransactionHistoryPage from 'pages/Dashboard/TransactionHistoryPage';
import CheckEmail from 'pages/Auth/CheckEmail';
import BorrowHistory from 'pages/Dashboard/BorrowHistory';
import RepayHistory from 'pages/Dashboard/RepayHistory';
import InterestedHistory from 'pages/Dashboard/InterestedHIstory';
import CallHistory from 'pages/Dashboard/CallHistory';
import LiquidationHistory from 'pages/Dashboard/LiquidationHistory';
import P2POpenTrades from 'pages/Dashboard/P2POpenTrades';
import P2PClosedTrades from 'pages/Dashboard/P2PClosedTrades';
import P2PAdvertisement from 'pages/Dashboard/P2PAdvertisement';
import Advertisement from 'pages/P2P/P2PAdvertisement';
import P2PTrading from 'pages/Static/Marketplace';
import P2PTrade from 'pages/P2P/P2PTrade';
import P2PCenter from 'pages/P2P/P2PCenter';
import TestPage from 'pages/Static/TestPage';
import PaymentMethods from 'pages/Account/PaymentMethods';
import PrivacyPolicy from 'pages/Static/PrivacyPolicy';
import CookiePolicy from 'pages/Static/CookiePolicy';
import UserAgreement from 'pages/Static/UserAgreement';
import { Withdrawal } from 'pages/Static/Popups/Withdrawal';
import OurPrices from 'pages/OurPrices';
import Popups from 'pages/Static/Popups';
import { IRouteItem } from './types';

// ==========================================:
const navList = {
	home: {
		path: '/',
		component: Home,
	},
	login: {
		path: '/login',
		component: isGuest(Login),
	},
	registration: {
		path: '/registration',
		component: isGuest(Registration),
	},
	registrationDone: {
		path: '/registration-done',
		component: isGuest(RegistrationDone),
	},
	emailConfirm: {
		path: '/email-confirm',
		component: isGuest(EmailConfirm),
	},
	emailConfirmCheck: {
		path: '/email-confirm-check',
		component: isGuest(CheckEmail),
	},
	forgotPassword: {
		path: '/forgot-password',
		component: isGuest(ForgotPassword),
	},
	forgot2fa: {
		path: '/forgot-2fa',
		component: isGuest(Forgot2fa),
	},
	createNewPassword: {
		path: '/create-new-password',
		component: isGuest(CreateNewPassword),
	},
	iPConfirmation: {
		path: '/ip-confirmation',
		component: isGuest(IPConfirmation),
	},
	inviteEarn: {
		path: '/invite-earn',
		component: isAuth(InviteEarn),
	},
	generalSettings: {
		path: '/general-settings',
		component: isAuth(GeneralSettings),
	},
	auth2faSettings: {
		path: '/auth-2fa',
		component: isAuth(Auth2fa),
	},
	payments: {
		path: '/payments',
		component: isAuth(Payments),
	},
	notifications: {
		path: '/notifications',
		component: isAuth(Notifications),
	},
	fiatAndSpot: {
		path: '/fiat-and-spot',
		component: isAuth(FiatAndSpot),
	},
	openOrders: {
		path: '/open-orders',
		component: isAuth(OpenOrders),
	},
	orderHistory: {
		path: '/order-history',
		component: isAuth(OrderHistory),
	},
	tradeHistory: {
		path: '/trade-history',
		component: isAuth(TradeHistory),
	},
	p2pTrade: {
		path: `/p2p-trade/:type?/:asset?`,
		component: P2PTrading,
	},
	p2pUserCenter: {
		path: `/p2p-user-center/:type?`,
		component: isAuth(P2PCenter),
	},
	advertisement: {
		path: `/advertisement/:id?`,
		component: isAuth(Advertisement),
	},
	p2p: {
		path: '/p2p',
		component: isAuth(P2P),
	},
	p2pAdvertisement: {
		path: '/p2p-advertisement',
		component: isAuth(P2PAdvertisement),
	},
	p2pOpenTrades: {
		path: '/p2p-open-trades',
		component: isAuth(P2POpenTrades),
	},
	p2pTradeDetils: {
		path: '/p2p-trading/details/:id?',
		component: isAuth(P2PTrade),
	},
	p2pTradePay: {
		path: '/p2p-trading/pay-sell/:trade_id?',
		component: isAuth(P2PTrade),
	},
	p2pTradeReleaseTether: {
		path: '/p2p-trading/release-tether',
		component: isAuth(P2PTrade),
	},
	tradePayClose: {
		path: '/trade-pay-close',
		component: isAuth(P2PTrade),
	},
	p2pClosedTrades: {
		path: '/p2p-closed-trades',
		component: isAuth(P2PClosedTrades),
	},
	marginWallets: {
		path: '/margin/:type?',
		component: isAuth(MarginWallet),
	},
	// marginOpenOrders: {
	// 	path: '/margin-open-orders',
	// 	component: isAuth(OpenOrders),
	// },
	marginOrderHistory: {
		path: '/margin-order-history',
		component: isAuth(OrderHistory),
	},
	marginTradeHistory: {
		path: '/margin-trade-history',
		component: isAuth(TradeHistory),
	},
	borrowedHistory: {
		path: '/margin-borrow',
		component: isAuth(BorrowHistory),
	},
	repayHistory: {
		path: '/margin-repayment',
		component: isAuth(RepayHistory),
	},
	interestedHistory: {
		path: '/margin-interest',
		component: isAuth(InterestedHistory),
	},
	callHistory: {
		path: '/margin-margincall',
		component: isAuth(CallHistory),
	},
	liquidationHistory: {
		path: '/margin-liquidation-orders',
		component: isAuth(LiquidationHistory),
	},
	deposit: {
		path: '/deposit',
		component: isAuth(Deposit),
	},
	// depositCrypto: {
	// 	path: '/deposit-crypto',
	// 	component: isAuth(DepositCrypto),
	// },
	// depositFiat: {
	// 	path: '/deposit-fiat',
	// 	component: isAuth(DepositFiat),
	// },
	// depositStable: {
	// 	path: '/deposit-stable',
	// 	component: isAuth(DepositStable),
	// },
	withdraw: {
		path: '/withdraw',
		component: isAuth(Withdraw),
	},
	// withdrawFiat: {
	// 	path: '/withdraw-fiat',
	// 	component: isAuth(WithdrawFiat),
	// },
	withdrawCrypto: {
		path: '/withdraw-crypto',
		component: isAuth(WithdrawCrypto),
	},
	// withdrawStable: {
	// 	path: '/withdraw-stable',
	// 	component: isAuth(WithdrawStable),
	// },

	convert: {
		path: '/convert',
		component: ConvertPage,
	},
	transactionHistory: {
		path: '/transaction-history/:page?',
		component: isAuth(TransactionHistoryPage),
	},
	notification: {
		path: '/notification',
		component: isAuth(Notification),
	},
	test: {
		path: '/test-page',
		component: isGuest(TestPage),
	},

	spot: {
		path: '/spot/:pair',
		component: Trading,
	},
	// margin: {
	// 	path: '/MarginTrading/:pair',
	// 	component: Trading,
	// },
	chart: {
		path: '/chart/:pair',
		component: ChartPage,
	},
	paymentMethods: {
		path: '/payment-methods',
		component: isAuth(PaymentMethods),
	},
	privacyPolicy: {
		path: '/privacy-policy',
		component: PrivacyPolicy,
	},
	cookiePolicy: {
		path: '/cookie-policy',
		component: CookiePolicy,
	},
	userAgreement: {
		path: '/user-agreement',
		component: UserAgreement,
	},
	message: {
		path: '/deposit-success',
		component: Popups,
	},
	messageFailed: {
		path: '/deposit-failed',
		component: Popups,
	},
	messageWithdrawal: {
		path: '/submited',
		component: Withdrawal,
	},
	messageWithdrawalFaild: {
		path: '/failure',
		component: Withdrawal,
	},
	ourPrices: {
		path: '/our-prices',
		component: OurPrices,
	},
	ourPricesCrypto: {
		path: '/our-prices/crypto',
		component: OurPrices,
	},
	ourPricesLimits: {
		path: '/our-prices/limits',
		component: OurPrices,
	},
};

// ==========================================:
const routesList: Array<IRouteItem> = [];

Object.keys(navList).forEach((item) => routesList.push(navList[item as keyof typeof navList]));

export { navList, routesList };
