import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch } from 'react-redux';
import TransatcionsHistory from 'components/TransatcionsHistory';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import Dashboard from 'layouts/Dashboard';

// ==========================================:
const TransactionHistoryPage: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getWalletsRequest());
	}, [dispatch]);

	return (
		<Dashboard title={String(L.translate('PageTitles.transaction_history'))}>
			<TransatcionsHistory />
		</Dashboard>
	);
};
export default TransactionHistoryPage;
