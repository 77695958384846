import { useState, ChangeEvent, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IAssetPairsItem } from 'redux/reducers/assetPairs/types';
import { getAssetPairsList } from 'redux/reducers/assetPairs/selectors';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import useSelect from 'hooks/useSelect';

const AssetPairSelect = () => {
	const dispatch = useDispatch();
	const assetPairs = useSelector(getAssetPairsList);
	const currentPair = useSelector(getCurrentPair);
	const walletList = useSelector(getAssetsList);
	const theme = useSelector(getColorTheme);

	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const [search, setSearch] = useState('');

	const handleSelect = (code: string) => {
		setOpen(false);
		dispatch(setCurrentPair(code));
	};

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setSearch(value);
	};

	const filteredPairsData = useMemo(() => {
		const pairSearch = (pair: IAssetPairsItem) => {
			return pair.code.toLowerCase().replace('_', '/').includes(search.toLowerCase().trim());
		};

		return assetPairs?.filter((pair) => {
			return pairSearch(pair);
		});
	}, [assetPairs, search]);

	const pairAddImg = (pair: IAssetPairsItem | null, curPair = '' as string) => {
		const currentAsset = walletList?.find((asset) =>
			curPair ? asset.code === curPair?.split('_')[0] : asset.code === pair?.code.split('_')[0],
		);
		return theme === 'dark' ? currentAsset?.dark_img_path : currentAsset?.img_path;
	};

	return (
		<div className={`select market-currency__select ${open ? 'active' : ''}`}>
			<button type="button" className="select__current" ref={triggerRef} onClick={toggleOpen}>
				<span className="select__drop-icon">
					<img src={pairAddImg(null, currentPair) || ''} alt="" />
				</span>
				{transformPairCode(currentPair)} <span className="select__arrow icon-arrow2" />
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-wrap">
					<div className="search search--big">
						<div className="input">
							<div className="input-wrapper">
								<input
									className="input-item input-item--small-height input-item--right-icon"
									type="text"
									placeholder="Enter token name"
									value={search}
									onChange={handleSearch}
								/>
								<button type="button" className="search-btn">
									<span className="search-btn__icon icon-search-normal" />
								</button>
							</div>
						</div>
					</div>
					<div className="select__drop-scroll">
						{filteredPairsData?.map((item) => (
							<div
								key={item.id}
								className={`select__drop-item ${
									currentPair?.toLowerCase() === item?.code?.toLowerCase() ? 'active' : ''
								}`}
							>
								<button
									type="button"
									className="select__drop-link"
									onClick={() => handleSelect(item.code)}
								>
									<span className="select__drop-icon">
										<img src={pairAddImg(item) || ''} alt="" />
									</span>
									<span className="select__drop-text">{transformPairCode(item.code)}</span>
									{currentPair?.toLowerCase() === item?.code?.toLowerCase() ? (
										<div className="select-check">
											<span className="select-check__icon icon-Checkbox" />
										</div>
									) : (
										''
									)}
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AssetPairSelect;
