import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { APP_NAME } from 'services/constants/env';
import Convert from 'layouts/Convert';
import ConvertForm from 'components/Forms/ConvertForm';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getAssetPairsFeesAndLimitsRequest,
	getAssetPairsRequest,
} from 'redux/reducers/assetPairs/reducer';
import ConvertFormAuth from './auth';
import ConvertFormGuest from './guest';

// ==========================================:
const ConvertPage: FC = () => {
	const dispatch = useDispatch();
	const isAuth = useSelector(getAuthIsAuthenticated);

	return (
		<Convert
			title={`${String(APP_NAME)}.com | ${String(L.translate('PageTitles.convert'))}`}
			description={String(L.translate('MetaTags.Description.convert'))}
			showMeta
			showVerificBanner
		>
			{isAuth ? <ConvertFormAuth /> : <ConvertFormGuest />}
		</Convert>
	);
};

export default ConvertPage;
