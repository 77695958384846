import { FC, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMarginWallets } from 'redux/reducers/marginWallets/selectors';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { riskSwitcher } from 'services/utils/riskSwitcher';
import { transformMarginsIsolatedData } from 'services/utils/transformMarginsIsolatedData';
import { IWalletsIsolatedDataType } from 'redux/reducers/marginWallets/types';
import { IisolatedWallet, IRisksProps } from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */

const RiskMargin: FC<IRisksProps> = (props) => {
	const { type, pair } = props;
	const marginWallets = useSelector(getMarginWallets);
	const [walletsMarginIsolated, setWalletsMarginIsolated] = useState<IWalletsIsolatedDataType>([]);
	useEffect(() => {
		if (marginWallets?.marginIsolatedWallets) {
			setWalletsMarginIsolated(
				transformMarginsIsolatedData(marginWallets?.marginIsolatedWallets?.data?.isolated),
			);
		}
	}, [marginWallets?.marginIsolatedWallets]);

	const isolatedWallet = useMemo((): IisolatedWallet | null | undefined | any => {
		if (!walletsMarginIsolated?.length) {
			return null;
		}
		if (walletsMarginIsolated?.length) {
			return walletsMarginIsolated?.find((item: any) => item?.base?.pair[0].code === pair);
		}
		return null;
	}, [walletsMarginIsolated, pair]);

	return (
		<>
			{type === 'cross' ? (
				<div className="trade-tabs__item trade-tabs__item--nohover">
					<p
						className="trade-tabs__count balance-item__value-num"
						style={{
							color: String(
								riskSwitcher(Number(marginWallets?.marginCrossWallets?.data?.margin_level)).color,
							),
						}}
					>
						{marginWallets.marginCrossWallets
							? fixedCropNumber(Number(marginWallets?.marginCrossWallets?.data?.margin_level), 2)
							: 999.0}
					</p>
					<p
						className="margin-level-val__text trade-tabs__probability"
						style={{
							color: riskSwitcher(Number(marginWallets?.marginCrossWallets?.data?.margin_level))
								.color,
						}}
					>
						{riskSwitcher(Number(marginWallets?.marginCrossWallets?.data?.margin_level)).label}
					</p>
				</div>
			) : (
				<div className="trade-tabs__item trade-tabs__item--nohover">
					<p
						className="trade-tabs__count balance-item__value-num"
						style={{
							color: String(riskSwitcher(Number(isolatedWallet?.base?.margin_level)).color),
						}}
					>
						{isolatedWallet
							? fixedCropNumber(Number(isolatedWallet?.base?.margin_level), 2)
							: 999.0}
					</p>
					<p
						className="margin-level-val__text trade-tabs__probability"
						style={{
							color: riskSwitcher(Number(isolatedWallet?.base?.margin_level)).color,
						}}
					>
						{riskSwitcher(Number(isolatedWallet?.base?.margin_level)).label}
					</p>
				</div>
			)}
		</>
	);
};

export default RiskMargin;
