import { FC } from 'react';
import L from 'i18n-react';
import { riskSwitcher } from 'services/utils/riskSwitcher';
import { numberFormat } from 'services/utils/numberFormat';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';

import { IMarginBalanceProps } from './types';

const MarginBalances: FC<IMarginBalanceProps> = ({
	handleSetBalancesIsHide,
	balancesIsHide,
	data,
	currentTab,
}) => {
	return (
		<div className="content-block">
			<div className="balance-list">
				<div className="balance-item item-column">
					<div className="balance-item_flex">
						<span className="balance-item__title">{L.translate('Margin.total_balance')}</span>
						<button
							type="button"
							className="hide-btn balance-item__btn"
							onClick={handleSetBalancesIsHide}
						>
							<span
								className={`hide-btn__icon text-type ${balancesIsHide ? 'icon-eye' : 'icon-eye2'}`}
							/>
							<span className="hide-btn__text">{L.translate('Margin.hide_balance')}</span>
						</button>
					</div>

					<span className="balance-item__value">
						<span className="balance-item__value-num">
							{balancesIsHide ? '********' : fixedCropNumber(Number(data?.total_in_btc), 8)}
						</span>{' '}
						{balancesIsHide ? '' : 'BTC'} <br />
						{balancesIsHide
							? '********'
							: `≈ $ ${numberFormat(Number(data?.total_in_usd), 'en-EN')}`}
					</span>
				</div>
				{currentTab !== 'isolated' && (
					<div className="balance-item item-column">
						<span className="balance-item__title">{L.translate('Margin.Cross.margin_lvl')}</span>
						{balancesIsHide ? (
							'********'
						) : (
							<div className="balance-item__value margin-level">
								<div className="margin-level-slider">
									<img src={riskSwitcher(Number(data?.margin_level)).img_path} alt="" />
								</div>
								<span
									className="balance-item__value-num"
									style={{
										color: String(riskSwitcher(Number(data?.margin_level)).color),
									}}
								>
									{data?.margin_level ? fixedCropNumber(Number(data?.margin_level), 2) : 999.0}
								</span>
								<p
									className="margin-level-val__text trade-tabs__probability"
									style={{
										color: riskSwitcher(Number(data?.margin_level)).color,
									}}
								>
									{riskSwitcher(Number(data?.margin_level)).label}
								</p>
							</div>
						)}
					</div>
				)}
			</div>
			<div className="balance-list list__margin">
				<div className="balance-item item-column">
					<span className="balance-item__title">{L.translate('Margin.total_debt')}</span>
					<span className="balance-item__value">
						<span className="balance-item__value-num">
							{balancesIsHide
								? '********'
								: fixedCropNumber(Number(data?.borrow_in_btc) + Number(data?.interest_in_btc), 8)}
						</span>{' '}
						{balancesIsHide ? '' : 'BTC'} <br />
						{balancesIsHide
							? '********'
							: `≈ $ ${numberFormat(
									Number(data?.borrow_in_usd) + Number(data?.interest_in_usd),
									'en-EN',
							  )}`}
					</span>
				</div>
				<div className="balance-item item-column">
					<span className="balance-item__title">{L.translate('Margin.account_equity')}</span>
					<span className="balance-item__value">
						<span className="balance-item__value-num">
							{balancesIsHide
								? '********'
								: fixedCropNumber(Number(data?.own_in_btc) + Number(data?.frozen_in_btc), 8)}
						</span>{' '}
						{balancesIsHide ? '' : 'BTC'} <br />
						{balancesIsHide
							? '********'
							: `≈ $ ${numberFormat(
									Number(data?.own_in_usd) + Number(data?.frozen_in_usd),
									'en-EN',
							  )}`}
					</span>
				</div>
			</div>
		</div>
	);
};

export default MarginBalances;
