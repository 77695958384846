import {
	ICreateExchangeRequestPayload,
	IExchangeRateRequestPayload,
	IExchangeRateResponsePayload,
	IConvertHistoryResponsePayload,
	IConvertFeePayload,
} from 'redux/reducers/convert/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IConvertApi } from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */

// ==========================================:
export const convert: IConvertApi = {
	getExchangeRate: (payload: IExchangeRateRequestPayload) =>
		http
			.post<IExchangeRateResponsePayload>(endpoint.convert.EXCHANGE_RATE, payload)
			.then((response) => response.data),
	makeExchange: (payload: ICreateExchangeRequestPayload) =>
		http.post(endpoint.convert.MAKE_EXCHANGE, payload).then((response) => response.data),
	getConvertHistory: (payload: any) =>
		http
			.get<IConvertHistoryResponsePayload>(endpoint.convert.GET_HISTORY_EXCHANGE, payload)
			.then((response) => response.data),
	getFee: (payload: IConvertFeePayload) =>
		http.post(endpoint.convert.POST_FEE, payload).then((response) => response.data),
};
