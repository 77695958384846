import { FC } from 'react';
import L from 'i18n-react';
import Dashboard from 'layouts/Dashboard';
import Advertisement from 'components/P2PWallet/P2Psubpages/Advertisement';

// ==========================================:
const P2PAdvertisement: FC = () => {
	return (
		<Dashboard title={String(L.translate('PageTitles.p2p_advertisement'))}>
			<Advertisement />
		</Dashboard>
	);
};

export default P2PAdvertisement;
