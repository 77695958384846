import { FC } from 'react';
import { displayChannelName } from '../../utils';
import BankTransferTooltip from '../../../shared/BankTransferTooltip';
import { ITableRow } from './types';

const TableRow: FC<ITableRow> = ({ item }) => {
	const {
		asset_code,
		channel_name,
		min_amount,
		max_amount,
		deposit_fee,
		min_deposit_fee,
		withdraw_fee,
		min_withdraw_fee,
	} = item;
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name">{asset_code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Chanel name</p>
				{['bank_transfer', 'bank_transfer_other'].includes(channel_name) ? (
					<div className="td-flex">
						<p className="td-name">{displayChannelName(channel_name)}</p>
						<BankTransferTooltip />
					</div>
				) : (
					<p className="td-name">{displayChannelName(channel_name)}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Rate from</p>
				<p className="td-name"> &lt; {min_amount}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Rate to</p>
				<p className="td-name"> &gt; {max_amount}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Deposit % </p>
				<p className="td-name"> {deposit_fee}%</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fixed amount</p>
				<p className="td-name"> {min_deposit_fee}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Withdrawal % </p>
				<p className="td-name"> {withdraw_fee}%</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fixed amount</p>
				<p className="td-name">{min_withdraw_fee}</p>
			</div>
		</div>
	);
};

export default TableRow;
