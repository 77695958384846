import { FC, useState } from 'react';
import L from 'i18n-react';
import { getPayments } from 'redux/reducers/p2p/selectors';
import { useDispatch, useSelector } from 'react-redux';
import CSearchInput from 'ui/CSearchInput';
import { IPaymentItem } from 'redux/reducers/p2p/types';
import { postPaymentMethodRequest } from 'redux/reducers/p2p/reducer';
import { IPropsModal } from './types';

export const WebWallet: FC<IPropsModal> = ({ closeModal, handleStatus }) => {
	const dispatch = useDispatch();
	const [webWallet, setWebWallet] = useState('');
	const [selectPaymnet, setSelectPaymnet] = useState<IPaymentItem | null>(null);
	const payments = useSelector(getPayments);
	const cardBank: Array<IPaymentItem> | any =
		(payments && payments?.filter((item: IPaymentItem) => item.type === 'web-wallet')) || [];
	const handleSelectPaymnets = (value: IPaymentItem): void => {
		setSelectPaymnet(value);
	};

	const handleSubmit = () => {
		const params = {
			payload: {
				payment_method_id: Number(selectPaymnet?.id),
				type: 'web',
				requisite: {
					web_wallet_id: webWallet,
				},
			},
			onSuccess: () => {
				handleStatus && handleStatus('web', 'success');
			},
			onError: () => {
				handleStatus && handleStatus('web', 'error');
			},
		};
		dispatch(postPaymentMethodRequest(params));
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--medium">
					<button type="button" onClick={closeModal} className="popup-close">
						<span className="popup-close__icon icon-close" />
					</button>
					<div className="popup-header">
						<p className="popup-header__title">
							{L.translate('P2P.Profile.Popups.WebWallet.title')}
						</p>
						<div className="popup-text popup-text--center">
							<p>{L.translate('P2P.Profile.Popups.WebWallet.subtitle')}</p>
						</div>
					</div>
					<div className="popup-body">
						<div className="input">
							<p className="input__name">
								{L.translate('P2P.Profile.Popups.WebWallet.Titles.web_wallet_id')}
							</p>
							<div className="input-wrapper">
								<input
									className="input-item"
									type="text"
									placeholder={String(
										L.translate('P2P.Profile.Popups.WebWallet.Placeholders.web_wallet_id'),
									)}
									value={webWallet}
									onChange={(e) => setWebWallet(e.currentTarget.value)}
								/>
							</div>
						</div>

						<div className="input">
							<div className="input__name">
								{L.translate('P2P.Profile.Popups.WebWallet.Titles.bank_name')}
							</div>
							<CSearchInput
								placeholder={String(L.translate('P2P.all_payments'))}
								listData={cardBank || []}
								onSelect={handleSelectPaymnets}
								dataItem={selectPaymnet?.id}
								valueProp="id"
								nameProp="payment_name_eng"
								currenClass="select--regular2"
								showValue={false}
								start="0"
							/>
						</div>
					</div>
					<div className="popup-submit popup-submit--more-mt popup-submit--sb">
						<button onClick={handleSubmit} type="button" className="button button--full-width ">
							{L.translate('P2P.Profile.Popups.WebWallet.confirm_btn')}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
