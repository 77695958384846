export const textSearchValid = (
	first: string,
	second: string,
	search: string,
	start: string,
	name: string,
): boolean => {
	let fullName = '';
	if (second === 'pairCode') {
		fullName = first.replace('_', '/');
	} else if (first !== undefined && second === 'crypto' && search !== undefined) {
		fullName = `${first}`;
	} else if (start === '0' && second !== undefined && search !== undefined && name !== 'full') {
		fullName = `${second.split(' ')[0]}`;
	} else if (first !== undefined && second !== undefined && search !== undefined) {
		fullName = `${first} ${second}`;
	} else {
		return false;
	}
	const regex = new RegExp(start === '0' ? `^${search}` : `${search}`, 'i');
	if (regex.test(fullName)) {
		return true;
	}
	return false;
};
