import { FC, useEffect } from 'react';
import L from 'i18n-react';
import Convert from 'layouts/Convert';
import ConvertForm from 'components/Forms/ConvertForm';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getAssetPairsFeesAndLimitsRequest,
	getAssetPairsRequest,
} from 'redux/reducers/assetPairs/reducer';

// ==========================================:
const ConvertPage: FC = () => {
	const dispatch = useDispatch();
	const isAuth = useSelector(getAuthIsAuthenticated);

	useEffect(() => {
		if (!isAuth) return;
		dispatch(getWalletsRequest());
		dispatch(getAssetPairsRequest());
		dispatch(getAssetPairsFeesAndLimitsRequest());
		dispatch(userSettingsRequest());
	}, [isAuth, dispatch]);

	return <ConvertForm />;
};

export default ConvertPage;
