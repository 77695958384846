import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getP2PBalancesList } from 'redux/reducers/p2p/selectors';

import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { numberFormat } from 'services/utils/numberFormat';
import { IP2PBalancesProps } from './types';

const P2PBalances: FC<IP2PBalancesProps> = ({ balancesIsHide }) => {
	const getP2PList = useSelector(getP2PBalancesList);

	return (
		<div className="content-block">
			<div className="balance-list">
				<div className="balance-item">
					<span className="balance-item__title">
						{String(L.translate('P2P.P2PWallet.estimate_value'))}
					</span>
					<span className="balance-item__value">
						<span className="balance-item__value-num">
							{balancesIsHide
								? '********'
								: fixedCropNumber(getP2PList ? Number(getP2PList?.total_in_btc) : 0, 8)}
						</span>{' '}
						{balancesIsHide ? '' : 'BTC'} <br />
						{balancesIsHide
							? '********'
							: `≈ $ ${numberFormat(getP2PList ? Number(getP2PList?.total_in_usd) : 0, 'en-EN')}`}
					</span>
				</div>
			</div>
		</div>
	);
};

export default P2PBalances;
