import { FC, useState } from 'react';
import { IListMethodItem } from 'redux/reducers/p2p/types';
import L from 'i18n-react';
import visaIcon from 'assets/images/general/visa.webp';
import { ICardItem } from 'redux/reducers/cards/types';
import DeleteCardPopUp from 'layouts-elements/popups/DeleteCardPopUp';
import { WebTable } from './WebTable/WebTable';
import { CardTable } from './CardTable/CardTable';
import { TransferTable } from './TransferTable/TransferTable';
import { PaymentButtons } from '../PaymentButtons/PaymentButtons';

export interface IAction {
	action: string;
	category: 'card' | 'bank' | 'web';
	data: ICardItem;
}

type TInfoProps = {
	setShowModal: (value: boolean) => void;
	payementList: Array<IListMethodItem> | null;
	method: string;
	setMethod: (value: string) => void;
};

export const PaymentCenter: FC<TInfoProps> = ({
	setShowModal,
	payementList,
	method,
	setMethod,
}) => {
	const [action, setAction] = useState<IAction | null>(null);
	// const methodList =
	// 	method === 'web-wallet'
	// 		? payementList.filter((item) => item.payment_method.type === 'web')
	// 		: payementList.filter((item) => item.payment_method.type === 'bank');

	const popUpDeleteClose = () => {
		setAction(null);
	};

	const paymentTable = {
		card: <CardTable list={payementList} setAction={setAction} />,
		bank: <TransferTable list={payementList} setAction={setAction} />,
		web: <WebTable list={payementList} setAction={setAction} />,
	};

	return (
		<div className="p2p-center-payments">
			<DeleteCardPopUp
				popUpOpen={action?.action?.startsWith('delete') || false}
				popUpClose={popUpDeleteClose}
				category={action?.category}
				data={action?.data}
				typeP="p2p"
				method={method}
			/>
			<div className="content-block-wrapper">
				<p className="desc desc--w730">{L.translate('Account.PaymentMethods.page_description')}</p>
				<PaymentButtons
					setShowModal={setShowModal}
					classNames="button button--second-type button--payment-method"
				/>
			</div>

			<div className="payment-method-p2p payment-method-p2p--user-center">
				<div className="payment-method-p2p__tabs">
					<button
						onClick={() => setMethod('card')}
						type="button"
						className={`${method === 'card' ? 'active' : ''}`}
					>
						{L.translate('P2P.Profile.Popups.Card.title')}
					</button>
					<button
						onClick={() => setMethod('bank')}
						type="button"
						className={`${method === 'bank' ? 'active' : ''}`}
					>
						{L.translate('P2P.Profile.Popups.BankTransfer.title')}
					</button>
					<button
						onClick={() => setMethod('web')}
						type="button"
						className={`${method === 'web' ? 'active' : ''}`}
					>
						{L.translate('P2P.Profile.Popups.WebWallet.title')}
					</button>
				</div>
				{/* <div className="payment-method-p2p__action">
					<button type="button" className="payment-method-p2p__button">
						Edit
					</button>
					<button type="button" className="payment-method-p2p__button">
						Delete
					</button>
				</div> */}
			</div>
			{paymentTable[method as keyof typeof paymentTable]}
		</div>
	);
};
