import { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { roundNumberToFixed } from 'services/utils/numberHelpers';
import { useHistory } from 'react-router';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { notificationContainer } from 'services/utils/notificationContainer';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { getAccountStatus } from 'redux/reducers/auth/selectors';
import { getAssetPairsList } from 'redux/reducers/assetPairs/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getMarginWallets } from 'redux/reducers/marginWallets/selectors';
import TransferModal from 'components/TransferModal';
import { CROSS_OPTION, SPOT_OPTION } from 'components/TransferModal/utils';
import { ISpotAndFiatItemProps } from './types';
import CrossMargin from '../../../Trade/MarginModal/CrossModal';
import useClickOutside from '../../../../hooks/useClickOutside';

const MarginItem: FC<ISpotAndFiatItemProps> = ({ data, balancesIsHide }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [openBorrowModal, setOpenBorrowModal] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [activeType, setActiveType] = useState('borrow');

	const handleBorrow = (event: string) => {
		setOpenBorrowModal((prevOpenModal) => !prevOpenModal);
		setActiveType(event);
	};

	const assetPairs = useSelector(getAssetPairsList);
	const currentPair = useSelector(getCurrentPair);
	const accountStatus = useSelector(getAccountStatus);
	const marginWallets = useSelector(getMarginWallets);

	const closeModal = (): void => {
		setOpenModal(false);
		setOpenBorrowModal(false);
	};

	const handleTrade = () => {
		if (accountStatus?.name === 'approved') {
			if (
				Number(marginWallets.marginIsolatedWallets?.data?.total_in_usd) <= 0 ||
				Number(data?.balance) <= 0
			) {
				notificationContainer(
					String(L.translate('MarginTable.MarginItem.transfer_notification')),
					'info',
				);
			}
		} else {
			notificationContainer(String(L.translate('MarginTable.MarginItem.kyc_notification')), 'info');
		}

		const findPairPerCode = assetPairs?.length
			? assetPairs.find((pair) => pair.code === `${data?.asset?.code}_usdt`)
			: null;

		if (findPairPerCode?.code) {
			dispatch(setCurrentPair(findPairPerCode?.code));
		} else {
			dispatch(setCurrentPair('btc_usdt'));
		}
		history.push(`/MarginTrading/${currentPair.toUpperCase()}?type=cross`);
	};

	const colorEquityValue = (value: number): string => {
		if (value > 0) return 'green';
		if (value < 0) return 'red';

		return '#0F0F0F';
	};

	const dropdownNode = useRef<HTMLDivElement | null>(null);
	const [dropOpened, setDropOpened] = useState(false);
	const [actionsOpened, setActionsOpened] = useState(false);

	const toggleDropHandler = () => {
		setDropOpened(!dropOpened);
	};
	const toggleActionsDropdown = () => {
		setActionsOpened(!actionsOpened);
	};
	const closeActionsDropdown = () => {
		setActionsOpened(false);
	};
	useClickOutside(dropdownNode, closeActionsDropdown);

	return (
		<div className="tr">
			<div className="td">
				<div className="balance-currency">
					<div className="balance-currency__img">
						<img src={data?.asset?.img_path} alt="" />
					</div>
					<div className="balance-currency__info">
						<span className="balance-currency__title">{data?.asset?.code?.toUpperCase()}</span>
						<span className="balance-currency__desc">{data?.asset?.name}</span>
					</div>
				</div>
			</div>
			<div
				className={`td td--left-auto td--dropdown td-name--center balance ${
					dropOpened ? 'td--dropdown-active' : ''
				}`}
			>
				<span className="td-hidden-name">Total balance</span>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : roundNumberToFixed(Number(data?.total), 8)}
				</span>
				<span className="td-arrow icon-arrow2" onClick={toggleDropHandler} />
			</div>
			<div className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}>
				<span className="td-hidden-name">Available balance</span>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : roundNumberToFixed(Number(data?.balance), 8)}
				</span>
			</div>
			<div className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}>
				<span className="td-hidden-name">Borrowed</span>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : roundNumberToFixed(Number(data?.borrow), 8)}
				</span>
			</div>
			<div className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}>
				<span className="td-hidden-name">Interest</span>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : roundNumberToFixed(Number(data?.interest_amount), 8)}
				</span>
			</div>
			<div className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}>
				<span className="td-hidden-name">EQUITY VALUE (BTC)</span>
				<div className="td-name td-name--regular">
					{balancesIsHide ? (
						'********'
					) : (
						<>
							<p
								className={`${colorEquityValue(
									Number(data?.own_balance) + Number(data?.frozen_balance),
								)}`}
							>
								{fixedCropNumber(Number(data.own_balance), 8).toFixed(4)} /{' '}
							</p>
							<p
								className={`td-name td-name--regular td-name--subtext ${colorEquityValue(
									Number(data?.own_balance) + Number(data?.frozen_balance),
								)}`}
							>
								{fixedCropNumber(Number(data.own_balance_in_btc), 8).toFixed(4)}
							</p>
						</>
					)}
				</div>
			</div>
			<div className="td td-name--center balance" ref={dropdownNode}>
				<div className={`select select--links ${actionsOpened ? 'active' : ''}`}>
					<button type="button" className="select__current" onClick={toggleActionsDropdown}>
						<span className="select__arrow icon-more" />
					</button>

					<div className="select__drop">
						<button
							className="link link--regular"
							onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
							type="button"
						>
							{L.translate('Margin.Transfer.title')}
						</button>
						{/* <button className="link link--regular" type="button">
							<span onClick={() => handleBorrow('borrow')}>
								{L.translate('Margin.Borrow.title')}
							</span>{' '}
							/{' '}
							<span onClick={() => handleBorrow('repay')}>{L.translate('Margin.Repay.title')}</span>
						</button> */}
						<button
							onClick={() => handleBorrow('borrow')}
							className="link link--regular"
							type="button"
						>
							{L.translate('Margin.Borrow.title')}
						</button>
						<button
							onClick={() => handleBorrow('repay')}
							className="link link--regular"
							type="button"
						>
							{L.translate('Margin.Repay.title')}
						</button>
						<button
							type="button"
							name={data?.asset?.code}
							onClick={handleTrade}
							className="link link--regular"
						>
							{L.translate('Margin.Cross.Funds.Table.trade_button')}
						</button>
						<TransferModal
							isOpen={openModal}
							onClose={closeModal}
							initFrom={CROSS_OPTION}
							initTo={SPOT_OPTION}
							initCoinCode={data.asset.code}
							spotSocket
							isolatedSocket
							p2pSocket
						/>
						<CrossMargin
							openModal={openBorrowModal}
							closeModal={closeModal}
							active={activeType}
							assetCode={data?.asset?.code}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MarginItem;
