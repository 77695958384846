import { FC } from 'react';
import { IProps } from './types';

const InfoIcon: FC<IProps> = ({ color }) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.2622 22C17.7622 22 22.2622 17.5 22.2622 12C22.2622 6.5 17.7622 2 12.2622 2C6.76221 2 2.26221 6.5 2.26221 12C2.26221 17.5 6.76221 22 12.2622 22Z"
				stroke={color || '#777E91'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.2622 11V16"
				stroke={color || '#777E91'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.2622 8H12.2712"
				stroke={color || '#777E91'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default InfoIcon;
