import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { getConvertFeeRequest } from 'redux/reducers/convert/reducer';
import { getConvertFee } from 'redux/reducers/convert/selectors';
import Popup from 'reactjs-popup';
import { IPropsModal } from './types';
/* eslint-disable */
const ConfirmPopup: FC<IPropsModal> = ({ openModal, closeModal, handleConfirm, data }) => {
	const convertFee = useSelector(getConvertFee);
	const dispatch = useDispatch();

	useEffect(() => {
		if (openModal && data.fromAmount) {
			const payload = {
				from_asset_id: data?.from?.asset?.id,
				to_asset_id: data?.to?.asset?.id,
				quantity: Number(data.fromAmount),
			};
			dispatch(getConvertFeeRequest(payload));
		}
	}, [dispatch, openModal]);

	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small ">
						<button className="popup-close" type="button" onClick={closeModal}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title popup-header__title--ta-left">
								{L.translate('ConvertForm.ConfirmPopup.title')}
							</p>
						</div>
						<div className="popup-body">
							<div className="withdrawal-options">
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('ConvertForm.ConfirmPopup.from')}
									</span>
									<span className="withdrawal-option__desc">
										{data?.from?.asset.name} ({data?.from?.asset?.code?.toUpperCase()}){' '}
									</span>
								</div>
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('ConvertForm.ConfirmPopup.to')}:
									</span>
									<span className="withdrawal-option__desc">
										{data?.to?.asset.name} ({data?.to?.asset?.code?.toUpperCase()}){' '}
									</span>
								</div>
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('ConvertForm.ConfirmPopup.amount', {
											value: data?.from?.asset?.code?.toUpperCase(),
										})}
										:
									</span>
									<span className="withdrawal-option__desc"> {data.fromAmount} </span>
								</div>
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('ConvertForm.ConfirmPopup.amount', {
											value: data?.to?.asset?.code?.toUpperCase(),
										})}
										:
									</span>
									<span className="withdrawal-option__desc"> {data.toAmount}</span>
								</div>
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('ConvertForm.ConfirmPopup.you_will_receive')}
									</span>
									<span className="withdrawal-option__desc">
										{' '}
										{Number(data.toAmount) - Number(convertFee?.convert_fee)}{' '}
										{data?.to?.asset?.code?.toUpperCase()}
									</span>
								</div>
							</div>
							<div className="withdrawal-options">
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('ConvertForm.ConfirmPopup.exchange_rate')}:
									</span>
									<span className="withdrawal-option__desc">
										{' '}
										1 {data?.from?.asset?.code?.toUpperCase()}= {data.rate}{' '}
										{data?.to?.asset?.code?.toUpperCase()}
									</span>
								</div>
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('ConvertForm.ConfirmPopup.exchange_fee')}:
									</span>
									<span className="withdrawal-option__desc">
										{' '}
										{convertFee?.convert_fee} {data?.to?.asset?.code?.toUpperCase()}{' '}
									</span>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--more-mt popup-submit--sb">
							<button type="button" onClick={handleConfirm} className="button button--full-width">
								{L.translate('Global.confirm')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ConfirmPopup;
