import { FC } from 'react';
import L from 'i18n-react';
import { IUploadFileInput } from './types';
// ==================================:

const UploadFileInput: FC<IUploadFileInput> = (props) => {
	const { handleUploadFile, errorSelfie } = props;

	return (
		<>
			<div className={errorSelfie ? 'authorization__field error' : 'authorization__field'}>
				<div className="form-upload reset-form">
					<input
						id="file-input"
						type="file"
						className="input-item form-upload__item"
						onChange={(e) => handleUploadFile(e)}
						accept="image/png,image/jpeg"
					/>
					<label htmlFor="file-input" className="input-file-btn">
						<span className="input-file-descr input-file-descr--empty">
							{String(L.translate('Forms.Forgot2fa.forgot_2fa_text_button'))}
						</span>
					</label>
				</div>
			</div>
			{errorSelfie && (
				<div className="error-text error-text--centered">
					<p className="error-text__item">{String(L.translate('Errors.please_upload_selfie'))}</p>
				</div>
			)}
		</>
	);
};

export default UploadFileInput;
