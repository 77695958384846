import { FC, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { getConvertHistory } from 'redux/reducers/convert/selectors';
import { IConvertHistoryRequestData } from 'redux/reducers/convert/types';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { IWalletItem } from 'redux/reducers/wallets/types';

import THAssetDropdown from 'ui/THAssetDropdown';
import THTimeDropdown from 'ui/THTimeDropdown';
import Pagination from 'ui/Pagination';
import { getConvertHistoryRequest } from 'redux/reducers/convert/reducer';

import ConvertTable from './ConvertTable';

const ConvertHistory: FC = () => {
	const walletsList = useSelector(getWalletsList);

	const [selectTime, setSelectTime] = useState<number | string>('All');
	const timeOptions = ['All', 7, 30, 90];

	const [selectAsset, setSelectAsset] = useState<IWalletItem | string>('All');
	const dispatch = useDispatch();

	const convertHistoryList = useSelector(getConvertHistory);

	const [currentPage, setCurrentPage] = useState<number>(1);

	const totalPages = convertHistoryList?.last_page || 1;

	const handleTimeSelect = (value: number) => {
		setSelectTime(value);
		setCurrentPage(1);
	};

	const handleAssetSelect = (value: IWalletItem | string) => {
		setSelectAsset(value);
		setCurrentPage(1);
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	const handleResetClick = () => {
		setSelectTime('All');
		setSelectAsset('All');
	};
	const handleExportHistoryClick = () => {
		if (convertHistoryList?.data?.length) {
			const requestConvertHistoryData: IConvertHistoryRequestData = {
				params: {
					per_page: 10,
					current_page: currentPage,
					export: 1,
				},
			};

			if (typeof selectAsset === 'object' && selectAsset.id) {
				requestConvertHistoryData.params.asset_id = selectAsset.asset.id;
			}

			if (selectTime !== 'All') {
				requestConvertHistoryData.params.past_days = selectTime;
			}

			dispatch(getConvertHistoryRequest(requestConvertHistoryData));
		}
	};

	useEffect(() => {
		const requestConvertHistoryData: IConvertHistoryRequestData = {
			params: {
				per_page: 10,
				current_page: currentPage,
			},
		};

		if (typeof selectAsset === 'object' && selectAsset.id) {
			requestConvertHistoryData.params.asset_id = selectAsset.asset.id;
		}

		if (selectTime !== 'All') {
			requestConvertHistoryData.params.past_days = selectTime;
		}

		dispatch(getConvertHistoryRequest(requestConvertHistoryData));
	}, [dispatch, selectAsset, selectTime, currentPage]);

	return (
		<>
			<div className="transactions__filter transactions-history-filter">
				<div className="transactions__filter-right">
					<div className="transactions__select-block select-block">
						<p className="select-block__name">{L.translate('Transaction.period')}</p>
						<THTimeDropdown value={selectTime} onChange={handleTimeSelect} options={timeOptions} />
					</div>
					<div className="transactions__select-block select-block">
						<p className="select-block__name">{L.translate('Transaction.asset')}</p>
						<THAssetDropdown
							value={selectAsset}
							onChange={handleAssetSelect}
							options={walletsList || []}
						/>
					</div>
				</div>

				<div className="transactions__filter-left transactions__filter-left-wrapper">
					<button
						className="transactions__filter-btn reset-btn"
						type="button"
						onClick={handleResetClick}
					>
						{L.translate('Transaction.reset_filter')}
					</button>
					<button
						className="transactions__filter-btn transactions__filter-btn--convert"
						type="button"
						onClick={handleExportHistoryClick}
					>
						<span className="btn-cover-icon icon-arrow" />
						{L.translate('Transaction.export_convert_history')}
					</button>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="transactions-table table transactions-table--convert">
						<ConvertTable data={convertHistoryList?.data || []} />

						{totalPages > 1 && (
							<Pagination
								pageCount={totalPages}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default ConvertHistory;
