import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import {
	getFiatFeesLimitsRequest,
	getCryptoFeesLimitsRequest,
} from 'redux/reducers/ourPrices/reducer';
import { getCryptoLimits, getLoading } from 'redux/reducers/ourPrices/selectors';
import LimitsSinglTable from './LimitsSinglTable';
import LimitsDailyTable from './LimitsDailyTable';
import LimitsMonthlyTable from './LimitsMonthlyTable';
import TabsLimits from './TabsLimits';
import CryptoTable from './CryptoTable';
import P2PTable from './P2PTable';

const OurPricesLimits = () => {
	const dispatch = useDispatch();
	const [active, setActive] = useState('payment_methods');
	const [activeDaily, setActiveDaily] = useState('payment_methods');
	const [activeMonthly, setActiveMonthly] = useState('payment_methods');
	const limitations = useSelector(getCryptoLimits);

	useEffect(() => {
		const fetchData = () => {
			dispatch(getFiatFeesLimitsRequest());
			dispatch(getCryptoFeesLimitsRequest());
		};
		fetchData();
		const intervalId = setInterval(() => {
			fetchData();
		}, 10000);
		return () => {
			clearInterval(intervalId);
		};
	}, [dispatch]);

	return (
		<>
			<div className="price-table">
				<h3 className="price-table__title">{L.translate('OurPrices.single_limits')}</h3>
				<TabsLimits active={active} setActive={setActive} />
				{active === 'payment_methods' ? (
					<LimitsSinglTable />
				) : (
					<CryptoTable data={limitations?.single} />
				)}
			</div>
			<div className="price-table">
				<h3 className="price-table__title">{L.translate('OurPrices.daily_limits')}</h3>
				<TabsLimits active={activeDaily} setActive={setActiveDaily} />
				{activeDaily === 'payment_methods' ? (
					<LimitsDailyTable />
				) : (
					<CryptoTable data={limitations?.daily} limit="daily" />
				)}
			</div>
			<div className="price-table">
				<h3 className="price-table__title">{L.translate('OurPrices.monthly_limits')}</h3>
				<TabsLimits active={activeMonthly} setActive={setActiveMonthly} />
				{activeMonthly === 'payment_methods' ? (
					<LimitsMonthlyTable />
				) : (
					<CryptoTable data={limitations?.monthly} limit="monthly" />
				)}
			</div>
			<div className="price-table">
				<h3 className="price-table__title">P2P</h3>
				<P2PTable />
			</div>
		</>
	);
};

export default OurPricesLimits;
