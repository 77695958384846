import React from 'react';
import L from 'i18n-react';

const CrossSpotSelect = () => {
	return (
		<>
			<button type="button" className="select__current">
				{String(L.translate('SpotMargin.spot_title'))}
			</button>
			<div className="select__drop">
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<span className="select__drop-link">
							<span className="select__drop-text">{String(L.translate('SpotMargin.spot_2'))}</span>
						</span>
					</div>
					<div className="select__drop-item">
						<span className="select__drop-link">
							<span className="select__drop-text">{String(L.translate('SpotMargin.spot_3'))}</span>
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default CrossSpotSelect;
