import { Suspense, FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { navList } from 'routes/routesList';
import TradeDetails from './TradeDetails';
import TradePaySell from './TradePaySell';
import TradeReleaseTether from './TradeReleaseTether';
import TradePayClose from './TradePayClose';

const SectionTrade: FC = () => {
	return (
		<Suspense fallback="Loading...">
			<Switch>
				<Route path={navList.p2pTradeDetils.path} component={TradeDetails} />
				<Route path={navList.p2pTradePay.path} component={TradePaySell} />
				<Route path={navList.p2pTradeReleaseTether.path} component={TradeReleaseTether} />
				<Route path={navList.tradePayClose.path} component={TradePayClose} />
				<Redirect to={navList.home.path} />
			</Switch>
		</Suspense>
	);
};

export default SectionTrade;
