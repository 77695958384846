/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { useLocation } from 'react-router-dom';
import { getOpenOrdersRequest, removeAllOpenOrdersRequest } from 'redux/reducers/spotTrade/reducer';
import Pagination from 'ui/Pagination';
import {
	getSpotUserOpenOrders,
	getSpotUserOpenOrdersIsLoad,
} from 'redux/reducers/spotTrade/selectors';

import ConfirmDeletePopup from 'ui/ConfirmDeletePopup';
import Loader from 'ui/Loader';
import OpenOrderItem from '../OpenOrderItem';
import { IOpenOrdersTableProps } from './types';

const OpenOrdersTable: FC<IOpenOrdersTableProps> = ({ currentTab }) => {
	const openOrders = useSelector(getSpotUserOpenOrders);
	const openOrdersIsLoad = useSelector(getSpotUserOpenOrdersIsLoad);
	const totalPages = openOrders?.last_page || 1;
	const [currentType, setCurrentType] = useState('spot');

	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const dispatch = useDispatch();
	const location = useLocation<any>();

	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	useEffect(() => {
		if (location?.state?.linkName === 'marginHistory') {
			setCurrentType(location.state?.margin === 'cross' ? 'margin' : 'isolated');
		} else {
			setCurrentType('spot');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state?.margin]);

	useEffect(() => {
		if (authIsAuthenticated) {
			if (location.state?.margin === 'cross') {
				dispatch(
					getOpenOrdersRequest({
						params: {
							per_page: 10,
							margin: 'margin',
							current_page: currentPage,
						},
					}),
				);
				return;
			}
			if (location.state?.margin === 'isolated') {
				dispatch(
					getOpenOrdersRequest({
						params: {
							per_page: 10,
							isolated: 'isolated',
							current_page: currentPage,
						},
					}),
				);
				return;
			}
			dispatch(
				getOpenOrdersRequest({
					params: {
						per_page: 10,
						current_page: currentPage,
					},
				}),
			);
		}
	}, [dispatch, authIsAuthenticated, currentPage, location.state]);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	const handleRemoveUserAllOpenOrders = () => {
		if (openOrders?.data.length) {
			const payload = {
				[`${currentTab === 'cross' ? 'is_margin' : 'is_isolated'}`]: true,
			};
			if (currentTab === 'cross' || currentTab === 'isolated') {
				dispatch(removeAllOpenOrdersRequest(payload));
			} else {
				dispatch(removeAllOpenOrdersRequest(null));
			}
		}

		closeModal();
	};

	return (
		<>
			<SocketWithPrivateTopicConnectWrapper topicName={`open_orders:${currentType}`}>
				<div className="table table--spot-open-orders table--wallet-type">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<span className="td-title">
									{String(
										L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.date_column_name'),
									)}
								</span>
							</div>
							<div className="td">
								<span className="td-title">
									{String(
										L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.pair_column_name'),
									)}
								</span>
							</div>
							<div className="td">
								<span className="td-title">
									{String(
										L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.type_column_name'),
									)}
								</span>
							</div>
							<div className="td">
								<span className="td-title">
									{String(
										L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.side_column_name'),
									)}
								</span>
							</div>
							<div className="td">
								<span className="td-title">
									{String(
										L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.price_column_name'),
									)}
								</span>
							</div>
							<div className="td">
								<span className="td-title">
									{String(
										L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.amount_column_name'),
									)}
								</span>
							</div>
							<div className="td">
								<span className="td-title">
									{String(
										L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.filled_column_name'),
									)}
								</span>
							</div>
							<div className="td">
								<span className="td-title">
									{String(
										L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.total_column_name'),
									)}
								</span>
							</div>
							<div className="td">
								<span className="td-title">
									{String(
										L.translate(
											'Trade.Spot.SpotHistoryTables.OpenOrdersTable.trigger_conditions_column_name',
										),
									)}
								</span>
							</div>
							<div className="td td--right">
								{openOrders?.data?.length ? (
									<button
										type="button"
										className="link"
										onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
										disabled={!openOrders?.data.length}
									>
										{String(
											L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.cancel_all_button'),
										)}
									</button>
								) : null}
								<ConfirmDeletePopup
									title={String(
										L.translate(
											'Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_all_orders_title',
										),
									)}
									bodyMessage={String(
										L.translate(
											'Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_all_body_message',
										),
									)}
									openModal={openModal}
									closeModal={closeModal}
									handleDelete={handleRemoveUserAllOpenOrders}
								/>
							</div>
						</div>
					</div>
					<div className="table-body">
						{openOrdersIsLoad && (
							<div className="open-orders-history-loader-wrapper">
								<Loader />
							</div>
						)}
						{!openOrdersIsLoad && openOrders?.data?.length
							? openOrders?.data?.map((order) => <OpenOrderItem key={order.id} data={order} />)
							: null}

						{!openOrdersIsLoad && openOrders?.data?.length === 0 && (
							<span className="table-empty">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.no_open_orders_text'),
								)}
							</span>
						)}
					</div>
				</div>
				{totalPages > 1 && (
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</SocketWithPrivateTopicConnectWrapper>
		</>
	);
};

export default OpenOrdersTable;
