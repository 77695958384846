import { FC } from 'react';
import L from 'i18n-react';
import useSelect from 'hooks/useSelect';
import { ITimeLimitSelect } from './types';

const OPTIONS = [20, 40, 60, 80, 100, 120, 140, 160, 180];

const TimeLimitSelect: FC<ITimeLimitSelect> = ({ value, onChange }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (_value: number) => {
		setOpen(false);
		onChange(_value);
	};

	return (
		<div className={`select select--regular select--full-width ${open ? 'active' : ''}`}>
			<button type="button" className="select__current" ref={triggerRef} onClick={toggleOpen}>
				{value} {String(L.translate('Global.min'))} <span className="select__arrow icon-arrow2" />
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					{OPTIONS.map((_value) => (
						<div className="select__drop-item" onClick={() => handleSelect(_value)}>
							<button className="select__drop-link" type="button">
								<span className="select__drop-text">
									{_value} {String(L.translate('Global.min'))}
								</span>
							</button>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default TimeLimitSelect;
