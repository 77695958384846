import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import {
	sendWithdrawCryptoEmailCodeRequest,
	confirmCryptoWithdrawRequest,
} from 'redux/reducers/withdrawCrypto/reducer';
import { IConfyrmCryptoWithdrawalRequestPayload } from 'redux/reducers/withdrawCrypto/types';
import { emailCodeErrors, smsCodeErrors, totpErrors } from 'services/constants/2faErrors';
import { getErrorMessage } from 'services/utils/errors';
import { IWithdrawPaypalPayload } from 'redux/reducers/withdrawFiat/types';
import { withdrawFiatRequest } from 'redux/reducers/withdrawFiat/reducer';
import { getWithdrawalFee } from 'redux/reducers/cards/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { getAddressIsInternal } from 'redux/reducers/withdrawCrypto/selectors';
import { useHistory } from 'react-router';
import { IWithdrawFiatConfirmPopupProps } from './types';

const WithdrawFiatConfirmPopup: FC<IWithdrawFiatConfirmPopupProps> = ({
	openModal,
	closeModal,
	recieveAmount,
	amount,
	currentWallet,
	selectedPayment,
	address,
	withdrawPs,
	currentWalletNetwork,
	to,
}) => {
	const [secondsEmail, setSecondsEmail] = useState(0);
	const [secondsSms, setSecondsSms] = useState(0);
	const [isEmailSendCode, setIsEmailSendCode] = useState(false);
	const [isSmsSendCode, setIsSmsSendCode] = useState(false);
	const [isConfirmDesabled, setIsConfirmDesabled] = useState(false);
	const [emailCode, setEmeilCode] = useState('');
	const [smsCode, setSmsCode] = useState('');
	const [totpCode, setTotpCode] = useState('');
	const [emailCodeIsError, setEmailCodeIsError] = useState(false);
	const [smsCodeIsError, setSmsCodeIsError] = useState(false);
	const [totpCodeIsError, setTotpCodeIsError] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();
	const userSettingsData = useSelector(getUserSettingsData);
	const withdrawalFee = useSelector(getWithdrawalFee);
	const walletsList = useSelector(getWalletsList);
	const userData = useSelector(getUserSettingsData);
	const addressIsInternal = useSelector(getAddressIsInternal);

	const assetCode = currentWallet?.asset?.code;
	const fiatCode = assetCode?.slice(0, assetCode.length - 1);
	const fiatId = walletsList?.find((w) => w.asset.code === fiatCode)?.asset?.id;

	const fixedWithdrawFee = userData?.is_vip
		? Number(currentWalletNetwork?.vip_fixed_withdraw_fee)
		: Number(currentWalletNetwork?.fixed_withdraw_fee);

	const withdrawNetworkFee = userData?.is_vip
		? Number(currentWalletNetwork?.vip_withdraw_fee)
		: Number(currentWalletNetwork?.withdraw_fee);

	const withdrawFeeCrypto = fixedWithdrawFee + (fixedWithdrawFee * withdrawNetworkFee) / 100;

	const handleGetEmailCode = () => {
		dispatch(sendWithdrawCryptoEmailCodeRequest());

		setSecondsEmail(60);
	};

	const handleGetSmsCode = () => {
		if (userSettingsData?.data?.phone) {
			dispatch(generateSmsRequest({ phone: userSettingsData?.data?.phone }));

			setSecondsSms(60);
		}
	};

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;

		if (secondsEmail > 0) {
			timer = setTimeout(() => setSecondsEmail(secondsEmail - 1), 1000);
			setIsEmailSendCode(true);
		} else {
			setSecondsEmail(0);
			setIsEmailSendCode(false);
		}

		return () => {
			clearTimeout(timer);
			setIsEmailSendCode(false);
		};
	}, [secondsEmail]);

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;

		if (secondsSms > 0) {
			timer = setTimeout(() => setSecondsSms(secondsSms - 1), 1000);
			setIsSmsSendCode(true);
		} else {
			setSecondsSms(0);
			setIsSmsSendCode(false);
		}

		return () => {
			clearTimeout(timer);
			setIsSmsSendCode(false);
		};
	}, [secondsSms]);

	useEffect(() => {
		setEmailCodeIsError(false);
	}, [emailCode]);

	useEffect(() => {
		setSmsCodeIsError(false);
	}, [smsCode]);

	useEffect(() => {
		setTotpCodeIsError(false);
	}, [totpCode]);

	const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setEmeilCode(value);
		}
	};

	const handleChangeSms = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setSmsCode(value);
		}
	};

	const handleChangeTotp = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setTotpCode(value);
		}
	};

	const handleCloseModal = () => {
		closeModal();
		setEmeilCode('');
		setSmsCode('');
		setTotpCode('');
	};

	const handleConfirmCryptoWithdrawal = () => {
		setIsConfirmDesabled(true);

		if (!fiatId && fiatId !== 0) {
			return;
		}

		const withdrawalPayloadData: IConfyrmCryptoWithdrawalRequestPayload = {
			data: {
				wallet_id: currentWallet?.id,
				amount,
				address,
				network: currentWalletNetwork.network_id,
				email_code: userSettingsData?.email_2fa_enabled ? emailCode.trim() : undefined,
				sms_code: userSettingsData?.phone_2fa_enabled ? smsCode.trim() : undefined,
				totp: userSettingsData?.google2fa_enabled ? totpCode.trim() : undefined,
			},
			history,
			closeModal: handleCloseModal,
			setIsConfirmDesabled,
			onSuccess: () => {
				history.push('/fiat-and-spot');
			},
			onError: (error: any) => {
				const errorMessage = getErrorMessage(error);
				if (!errorMessage) {
					return;
				}
				if (emailCodeErrors.includes(errorMessage)) {
					setEmailCodeIsError(true);
				}
				if (smsCodeErrors.includes(errorMessage)) {
					setSmsCodeIsError(true);
				}
				if (totpErrors.includes(errorMessage)) {
					setTotpCodeIsError(true);
				}
			},
		};

		dispatch(confirmCryptoWithdrawRequest(withdrawalPayloadData));
	};

	const isGoogle = !!(userSettingsData?.google2fa_enabled && !totpCode);
	const isPhone = !!(userSettingsData?.phone_2fa_enabled && !smsCode);
	const isEmail = !!(userSettingsData?.email_2fa_enabled && !emailCode);

	const disabled = isGoogle || isPhone || isEmail;

	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={handleCloseModal}
			closeOnEscape
			lockScroll
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<button className="popup-close" type="button" onClick={handleCloseModal}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header popup-header--padding">
							<p className="popup-header__title">
								{String(L.translate('WithdrawStable.Popups.BlockchainConfirm.title'))}
							</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="withdrawal-options">
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{String(L.translate('WithdrawCrypto.amount'))}
									</span>
									<span className="withdrawal-option__desc">
										{String(L.translate('WithdrawCrypto.receive'))} {recieveAmount}{' '}
										{currentWallet?.asset?.code?.toUpperCase()} (
										{String(L.translate('WithdrawCrypto.fee'))}{' '}
										{addressIsInternal ? 0 : withdrawFeeCrypto})
									</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{String(L.translate('WithdrawCrypto.address'))}
									</span>
									<span className="withdrawal-option__desc">{address}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{String(L.translate('WithdrawCrypto.network'))}
									</span>
									<span className="withdrawal-option__desc">
										{currentWalletNetwork?.network_name?.toUpperCase()}
									</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{String(L.translate('WithdrawCrypto.source'))}
									</span>
									<span className="withdrawal-option__desc">
										{String(L.translate('WithdrawCrypto.spot_wallet'))}
									</span>
								</div>
							</div>
							{userSettingsData?.email_2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className={`input-wrapper ${emailCodeIsError ? 'input--error' : ''}`}>
													<input
														className="input-item"
														type="text"
														placeholder={String(
															L.translate('WithdrawStable.Placeholders.email_verify'),
														)}
														value={emailCode}
														onChange={handleChangeEmail}
													/>
												</div>
											</div>
										</div>
										{isEmailSendCode && secondsEmail > 0 ? (
											<p className="timer-wrapper">{secondsEmail}s</p>
										) : (
											<button
												className="button button--small"
												type="button"
												onClick={handleGetEmailCode}
											>
												{String(L.translate('WithdrawFiat.get_code'))}
											</button>
										)}
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
											{String(L.translate('WithdrawFiat.enter_code'))} {userSettingsData?.email}
										</span>
									</div>
								</div>
							) : null}

							{userSettingsData?.phone_2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className={`input-wrapper ${smsCodeIsError ? 'input--error' : ''}`}>
													<input
														className="input-item"
														type="text"
														placeholder={String(
															L.translate('WithdrawStable.Placeholders.sms_verify'),
														)}
														value={smsCode}
														onChange={handleChangeSms}
													/>
												</div>
											</div>
										</div>
										{isSmsSendCode && secondsSms > 0 ? (
											<p className="timer-wrapper">{secondsSms}s</p>
										) : (
											<button
												className="button button--small"
												type="button"
												onClick={handleGetSmsCode}
											>
												{String(L.translate('WithdrawFiat.get_code'))}
											</button>
										)}
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
											{String(L.translate('WithdrawFiat.sms_sent'))} {userSettingsData?.data?.phone}
										</span>
									</div>
								</div>
							) : null}
							{userSettingsData?.google2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className={`input-wrapper ${totpCodeIsError ? 'input--error' : ''}`}>
													<input
														className="input-item"
														type="text"
														placeholder={String(
															L.translate('WithdrawStable.Placeholders.2fa_code'),
														)}
														value={totpCode}
														onChange={handleChangeTotp}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
											{String(L.translate('WithdrawFiat.enter_google_code'))}
										</span>
									</div>
								</div>
							) : null}
							<ul className="withdrawal-list">
								<li className="withdrawal-list__item">
									{String(L.translate('WithdrawFiat.address_is_correct'))}
								</li>
								<li className="withdrawal-list__item">
									{String(L.translate('WithdrawFiat.transaction_cancel_error'))}
								</li>
							</ul>
						</div>
						<div className="popup-submit popup-submit--sb">
							<button
								className="button button--full-width"
								type="button"
								disabled={disabled || isConfirmDesabled}
								onClick={handleConfirmCryptoWithdrawal}
							>
								{String(L.translate('WithdrawFiat.confirm_button'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default WithdrawFiatConfirmPopup;
