/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import L from 'i18n-react';
import { ITymeLine } from './type';

const PAFTymeLine: FC<ITymeLine> = ({ step }) => {
	return (
		<>
			<div
				className={`timeline ${Number(step === 0) && 'timeline--first'} ${
					Number(step === 2) && 'timeline--end'
				}`}
			>
				<div className={`timeline-step ${Number(step >= 0) && 'timeline-step--active'}`}>
					<div className="timeline-step__num">
						<span>1</span>
					</div>
					<span className="timeline-step__desc">
						{String(L.translate('Advertisement.price_details'))}
					</span>
				</div>
				<div className={`timeline-progress ${Number(step >= 0) && 'timeline-progress--active'}`}>
					<span
						className={`timeline-progress__circle ${
							Number(step >= 0) && 'timeline-progress__circle--active'
						}`}
					/>
					<span className="timeline-progress__line" />
					<span
						className={`timeline-progress__circle ${
							Number(step >= 0) && 'timeline-progress__circle--active'
						}`}
					/>
				</div>
				<div className={`timeline-step ${Number(step >= 1) && 'timeline-step--active'}`}>
					<div className="timeline-step__num">
						<span>2</span>
					</div>
					<span className="timeline-step__desc">
						{String(L.translate('Advertisement.trade_details'))}
					</span>
				</div>
				<div className={`timeline-progress ${Number(step >= 1) && 'timeline-progress--active'}`}>
					<span
						className={`timeline-progress__circle ${
							Number(step > 1) && 'timeline-progress__circle--active'
						}`}
					/>
					<span className="timeline-progress__line" />
					<span
						className={`timeline-progress__circle ${
							Number(step > 1) && 'timeline-progress__circle--active'
						}`}
					/>
				</div>
				<div className={`timeline-step ${Number(step > 1) && 'timeline-step--active'}`}>
					<div className="timeline-step__num">
						<span>3</span>
					</div>
					<span className="timeline-step__desc">
						{String(L.translate('Advertisement.payment_details'))}
					</span>
				</div>
			</div>
		</>
	);
};

export default PAFTymeLine;
