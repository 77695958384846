import { FC } from 'react';
import L from 'i18n-react';
import { useLocation } from 'react-router-dom';
import { INavTrade } from './type';
/* eslint-disable no-debugger */

const NavTrade: FC<INavTrade> = () => {
	const location = useLocation();

	return (
		<div
			className={`timeline ${Number(location.state === 0) && 'timeline--first'} ${
				Number(location.state === 2) && 'timeline--end'
			}`}
		>
			<div
				className={`timeline-step ${
					Number(Number(location.state) >= 0) && 'timeline-step--active'
				}`}
			>
				<div className="timeline-step__num">
					<span>1</span>
				</div>
				<span className="timeline-step__desc">{String(L.translate('P2P.trade_details'))}</span>
			</div>
			<div
				className={`timeline-progress ${
					Number(Number(location.state) >= 0) && 'timeline-progress--active'
				}`}
			>
				<span
					className={`timeline-progress__circle ${
						Number(Number(location.state) >= 0) && 'timeline-progress__circle--active'
					}`}
				/>
				<span className="timeline-progress__line" />
				<span
					className={`timeline-progress__circle ${
						Number(Number(location.state) >= 0) && 'timeline-progress__circle--active'
					}`}
				/>
			</div>
			<div
				className={`timeline-step ${
					Number(Number(location.state) >= 1) && 'timeline-step--active'
				}`}
			>
				<div className="timeline-step__num">
					<span>2</span>
				</div>
				<span className="timeline-step__desc">{String(L.translate('P2P.pay_sell'))}</span>
			</div>
			<div
				className={`timeline-progress ${
					Number(Number(location.state) >= 1) && 'timeline-progress--active'
				}`}
			>
				<span
					className={`timeline-progress__circle ${
						Number(Number(location.state) > 1) && 'timeline-progress__circle--active'
					}`}
				/>
				<span className="timeline-progress__line" />
				<span
					className={`timeline-progress__circle ${
						Number(Number(location.state) > 1) && 'timeline-progress__circle--active'
					}`}
				/>
			</div>
			<div
				className={`timeline-step ${Number(Number(location.state) > 1) && 'timeline-step--active'}`}
			>
				<div className="timeline-step__num">
					<span>3</span>
				</div>
				<span className="timeline-step__desc">{String(L.translate('P2P.payment_details'))}</span>
			</div>
		</div>
	);
};

export default NavTrade;
