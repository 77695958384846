/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IGetPaymentsListPayload,
	IPaymentsStore,
	IPostPaymentsPayload,
	IPostBankAccountRequest,
	IDeleteBankAccountRequest,
	IBankAccountResponse,
	IGetBankTransferRequest,
	IGetBankTransferResponse,
	IGetPaymentBankAccountWithdrawalResponse,
	IGetPaymentBankAccountRequest,
	IPostBankTransferResponse,
	IPostPayPalDepositRequset,
	IPostPayPalDepositResponse,
	TFiatLimitations,
} from './types';

// ==========================================:
export const initialState: IPaymentsStore = {
	urlPayPal: '',
	paymentsList: [],
	paymentsListLoader: false,
	accountBank: null,
	dataBankTransferDeposit: null,
	accountData: null,
	fiatLimitations: null,
	postPayPalLoading: false,
};

// ==========================================:
const payments = createSlice({
	name: '@@payments',
	initialState,
	reducers: {
		getCurrencyPaymentsListRequest: (state, action: PayloadAction<IGetPaymentsListPayload>) => {
			const getCurrencyPaymentsListState = state;
			getCurrencyPaymentsListState.paymentsListLoader = true;
		},
		getCurrencyPaymentsListSuccess: (state, { payload }: PayloadAction<string[]>) => {
			const getCurrencyPaymentsListState = state;
			getCurrencyPaymentsListState.paymentsList = payload;
			getCurrencyPaymentsListState.paymentsListLoader = false;
		},
		getCurrencyPaymentsListError: (state) => {
			const getCurrencyPaymentsListState = state;
			getCurrencyPaymentsListState.paymentsList = [];
			getCurrencyPaymentsListState.paymentsListLoader = false;
		},
		postPaymentsCartubank: (state, action: PayloadAction<IPostPaymentsPayload>) => {
			const orderBookRequestState = state;
		},
		postPaymentsPayPal: (state, action: PayloadAction<IPostPayPalDepositRequset>) => {
			const orderBookRequestState = state;
			orderBookRequestState.postPayPalLoading = true;
		},
		postPaymentsPayPalSuccess: (state, action: PayloadAction<IPostPayPalDepositResponse>) => {
			const orderBookRequestState = state;
			orderBookRequestState.urlPayPal = String(action.payload);
			orderBookRequestState.postPayPalLoading = false;
		},
		postPaymentsPayPalError: (state) => {
			const orderBookRequestState = state;
			orderBookRequestState.postPayPalLoading = false;
		},
		postBankAccounts: (state, action: PayloadAction<IPostBankAccountRequest>) => {},
		postBankAccountsSuccess: (state, action: PayloadAction<IPostBankTransferResponse>) => {
			const paymentState = state;
			paymentState.accountData = action?.payload;
		},
		updateBankAccountRequest: (state, action: PayloadAction<IPostBankAccountRequest>) => {},
		getBankTransferRequest: (state) => {},
		getBankTransferSuccess: (state, action: PayloadAction<IBankAccountResponse>) => {
			const account = state;
			account.accountBank = action?.payload;
		},
		deleteBankAccountRequest: (state, action: PayloadAction<IDeleteBankAccountRequest>) => {},
		getBankTransferDepositRequest: (state, action: PayloadAction<IGetBankTransferRequest>) => {},
		getBankTransferDepositSuccess: (state, action: PayloadAction<IGetBankTransferResponse>) => {
			const deposit = state;
			deposit.dataBankTransferDeposit = action?.payload;
		},
		getBankTransferWithdrawalRequest: (
			state,
			action: PayloadAction<IGetPaymentBankAccountRequest>,
		) => {},
		getBankTransferWithdrawalSuccess: (
			state,
			action: PayloadAction<IGetPaymentBankAccountWithdrawalResponse>,
		) => {
			const deposit = state;
			// deposit.dataBankTransferDeposit = action?.payload;
		},
		getFiatLimitationsRequest: () => {},
		getFiatLimitationsSuccess: (state, { payload }: PayloadAction<TFiatLimitations>) => {
			const paymentState = state;
			paymentState.fiatLimitations = payload;
		},
	},
});
export default payments.reducer;
export const {
	getCurrencyPaymentsListRequest,
	getCurrencyPaymentsListSuccess,
	getCurrencyPaymentsListError,
	postPaymentsCartubank,
	postPaymentsPayPal,
	postPaymentsPayPalSuccess,
	postPaymentsPayPalError,
	postBankAccounts,
	updateBankAccountRequest,
	getBankTransferRequest,
	getBankTransferSuccess,
	deleteBankAccountRequest,
	getBankTransferDepositRequest,
	getBankTransferDepositSuccess,
	getBankTransferWithdrawalRequest,
	getBankTransferWithdrawalSuccess,
	postBankAccountsSuccess,
	getFiatLimitationsRequest,
	getFiatLimitationsSuccess,
} = payments.actions;
