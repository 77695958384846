import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import L from 'i18n-react';
import { getCryptoFeesRequest, getP2PLimitationsRequest } from 'redux/reducers/ourPrices/reducer';
import CryptoTable from './CryptoTable';
import SpotFeesTable from './SpotFeesTable';
import ConvertFeesTable from './ConvertFeesTable';

const OurPricesCrypto = () => {
	const dispatch = useDispatch();
	const [tab, setTab] = useState<'spot' | 'convert'>('spot');

	useEffect(() => {
		const fetchData = () => {
			dispatch(getCryptoFeesRequest());
			dispatch(getP2PLimitationsRequest());
		};
		fetchData();
		const intervalId = setInterval(() => {
			fetchData();
		}, 10000);
		return () => {
			clearInterval(intervalId);
		};
	}, [dispatch]);

	return (
		<>
			<div className="price-table">
				<CryptoTable />
			</div>
			<div className="price-table">
				<h3 className="price-table__title">{L.translate('OurPrices.trading_fees')}</h3>
				<div className="line-tab ">
					<button
						type="button"
						className={`line-tab__item ${tab === 'spot' ? 'active' : ''}`}
						onClick={() => setTab('spot')}
					>
						{L.translate('OurPrices.spot')}
					</button>
					<button
						type="button"
						className={`line-tab__item ${tab === 'convert' ? 'active' : ''}`}
						onClick={() => setTab('convert')}
					>
						{L.translate('OurPrices.convert')}
					</button>
				</div>

				{tab === 'spot' && <SpotFeesTable />}
				{tab === 'convert' && <ConvertFeesTable />}
			</div>
		</>
	);
};

export default OurPricesCrypto;
