import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IMarginWalletsStore,
	IMarginCrossWallets,
	IIndexPrices,
	ITransferMagin,
	IMarginIsolatedWallets,
	IMaximumCrossBorrowAmount,
	ITakeBorrow,
	IBorrowAvailable,
	ITakeIsolatedBorrow,
	IMarginIsolatedWalletsData,
	IMarginCrossWalletsData,
	ITransferHistoryRequestPayload,
	ITransferHistoryResponsePayload,
	IUserMarginWalletRequestPayload,
	IUserMarginWalletResponsePayload,
	IUserLiquidationWalletResponsePayload,
	ICreateExternalTransferPayload,
} from './types';

// ==========================================:
export const initialState: IMarginWalletsStore = {
	marginCrossWallets: null,
	marginIsolatedWallets: null,
	marginMaximumCrossBorrowAmount: null,
	marginMaximumIsolatedBorrowAmount: null,
	marginCrossBorrowList: null,
	marginIsolatedBorrowList: null,
	marginCrossRepayList: null,
	marginIsolatedRepayList: null,
	marginCrossInterestList: null,
	marginIsolatedInterestList: null,
	marginCrossMarginCallsList: null,
	marginIsolatedMarginCallsList: null,
	marginLiquidationList: null,
	marginCrossTransfersList: null,
	marginIsolatedTransfersList: null,
	marginIndexPrices: null,
	loading: false,
	marginWalletsLoader: false,
	transferHistory: null,
	transferHistoryLoader: false,
	makeTransferLoader: false,
	borrowSuccess: null,
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// ==========================================:
const marginWallets = createSlice({
	name: '@@marginWallets',
	initialState,
	reducers: {
		getMarginCrossWalletsRequest: (state) => {
			const marginWalletsRequestState = state;

			marginWalletsRequestState.marginWalletsLoader = true;
		},
		getMarginCrossWalletsSuccess: (state, action: PayloadAction<IMarginCrossWallets>) => {
			const { payload } = action;
			const marginCrossWalletsSuccessState = state;
			marginCrossWalletsSuccessState.marginCrossWallets = payload;
			marginCrossWalletsSuccessState.marginWalletsLoader = false;
		},

		updateCrossWalletsSuccess: (state, action: PayloadAction<IMarginCrossWalletsData>) => {
			const { payload } = action;
			const updateCrossWalletsSuccessState = state;
			if (updateCrossWalletsSuccessState.marginCrossWallets)
				updateCrossWalletsSuccessState.marginCrossWallets.data = payload;
		},

		getMarginIsolatedWalletsRequest: (state) => {
			const marginWalletsIsolatedRequestState = state;

			marginWalletsIsolatedRequestState.marginWalletsLoader = true;
		},
		getMarginIsolatedWalletsSuccess: (state, action: PayloadAction<IMarginIsolatedWallets>) => {
			const marginWalletsIsolatedRequestState = state;
			const { payload } = action;

			marginWalletsIsolatedRequestState.marginIsolatedWallets = payload;
			marginWalletsIsolatedRequestState.marginWalletsLoader = true;
		},
		updateIsolatedWalletsSuccess: (state, action: PayloadAction<IMarginIsolatedWalletsData>) => {
			const { payload } = action;
			const updateIsolatedWalletsSuccessState = state;
			if (updateIsolatedWalletsSuccessState.marginIsolatedWallets)
				updateIsolatedWalletsSuccessState.marginIsolatedWallets.data = payload;
		},

		getMarginIndexPricesRequest: (state) => {
			const marginIndexPricesState = state;

			marginIndexPricesState.marginWalletsLoader = true;
		},
		getMarginIndexPricesSuccess: (state, action: PayloadAction<IIndexPrices>) => {
			const { payload } = action;
			const marginIndexPrices = state;

			marginIndexPrices.marginIndexPrices = payload;
			marginIndexPrices.marginWalletsLoader = false;
		},
		updateMarginIndexPricesSuccess: (state, action: PayloadAction<IIndexPrices>) => {
			const { payload } = action;
			const updatemarginIndexPrices = state;

			updatemarginIndexPrices.marginIndexPrices = payload;
			updatemarginIndexPrices.marginWalletsLoader = false;
		},
		postMarginTransferRequest: (state, action: PayloadAction<ITransferMagin>) => {
			const { payload } = action;
			const transferStatus = state;
		},
		getMaximumBorrowAmoiuntRequest: (state, action: PayloadAction<number>) => {
			const maximumBorrowAmoiunt = state;
		},
		getMaximumBorrowAmountSuccess: (state, action: PayloadAction<IMaximumCrossBorrowAmount>) => {
			const maximumBorrowAmoiunt = state;
			const { payload } = action;

			maximumBorrowAmoiunt.marginMaximumCrossBorrowAmount = payload;
		},
		postTakeBorrowRequest: (state, action: PayloadAction<ITakeBorrow>) => {
			const { payload } = action;
			const transferStatus = state;
		},
		getborrowAvailableRequest: (state, action: PayloadAction<any>) => {
			const maximumBorrowAmoiunt = state;
		},
		getborrowAvailableSuccess: (state, action: PayloadAction<IBorrowAvailable>) => {
			const isolateBorrowAvailable = state;
			const { payload } = action;

			isolateBorrowAvailable.marginMaximumIsolatedBorrowAmount = payload;
		},
		postTakeBorrowIsolateRequest: (state, action: PayloadAction<ITakeIsolatedBorrow>) => {
			const { payload } = action;
			const transferStatus = state;
		},
		getTransferHistoryRequest: (state, action: PayloadAction<ITransferHistoryRequestPayload>) => {
			const getTransferSuccessState = state;
			if (action.payload?.params.export) {
				return;
			}
			getTransferSuccessState.transferHistoryLoader = true;
		},

		getTransferHistorySuccess: (state, action: PayloadAction<ITransferHistoryResponsePayload>) => {
			const transferSuccessState = state;

			const { payload } = action;

			transferSuccessState.transferHistory = payload;
			transferSuccessState.transferHistoryLoader = false;
		},
		getMarginWalletBorrowHistoryRequest: (
			state,
			action: PayloadAction<IUserMarginWalletRequestPayload>,
		) => {
			const borrowHistoryRequestState = state;

			borrowHistoryRequestState.marginWalletsLoader = true;
		},
		getMarginWalletBorrowHistorySuccess: (
			state,
			action: PayloadAction<IUserMarginWalletResponsePayload>,
		) => {
			const { payload } = action;
			const borrowHistorySuccessState = state;

			borrowHistorySuccessState.marginCrossBorrowList = payload;
			borrowHistorySuccessState.marginWalletsLoader = false;
		},
		getRepayHistoryRequest: (state, action: PayloadAction<IUserMarginWalletRequestPayload>) => {
			const repayHistoryRequestState = state;

			repayHistoryRequestState.marginWalletsLoader = true;
		},
		getRepayHistorySuccess: (state, action: PayloadAction<IUserMarginWalletResponsePayload>) => {
			const { payload } = action;
			const repayHistorySuccessState = state;

			repayHistorySuccessState.marginCrossRepayList = payload;
			repayHistorySuccessState.marginWalletsLoader = false;
		},
		getInterestedHistoryRequest: (
			state,
			action: PayloadAction<IUserMarginWalletRequestPayload>,
		) => {
			const InterestedHistoryState = state;

			InterestedHistoryState.marginWalletsLoader = true;
		},
		getInterestedHistorySuccess: (
			state,
			action: PayloadAction<IUserMarginWalletResponsePayload>,
		) => {
			const { payload } = action;
			const interestedHistorySuccessState = state;

			interestedHistorySuccessState.marginCrossInterestList = payload;
			interestedHistorySuccessState.marginWalletsLoader = false;
		},
		getMarginCallHistoryRequest: (
			state,
			action: PayloadAction<IUserMarginWalletRequestPayload>,
		) => {
			const marginCallHistoryRequestState = state;

			marginCallHistoryRequestState.marginWalletsLoader = true;
		},
		getMarginCallHistorySuccess: (
			state,
			action: PayloadAction<IUserMarginWalletResponsePayload>,
		) => {
			const { payload } = action;
			const marginCallHistorySuccessState = state;

			marginCallHistorySuccessState.marginCrossMarginCallsList = payload;
			marginCallHistorySuccessState.marginWalletsLoader = false;
		},
		getLiqudationHistoryRequest: (
			state,
			action: PayloadAction<IUserMarginWalletRequestPayload>,
		) => {
			const LiqudationHistoryRequestState = state;

			LiqudationHistoryRequestState.marginWalletsLoader = true;
		},
		getLiqudationHistorySuccess: (
			state,
			action: PayloadAction<IUserLiquidationWalletResponsePayload>,
		) => {
			const { payload } = action;
			const LiqudationHistorySuccessState = state;

			LiqudationHistorySuccessState.marginLiquidationList = payload;
			LiqudationHistorySuccessState.marginWalletsLoader = false;
		},
		setBorrowSuccess: (state, action) => {
			const borrowState = state;
			borrowState.borrowSuccess = action.payload;
		},
		createExternalTransferRequest: (
			state,
			action: PayloadAction<ICreateExternalTransferPayload>,
		) => {
			const createExternalTransferState = state;

			createExternalTransferState.makeTransferLoader = true;
		},
		createExternalTransferFinally: (state, action: PayloadAction) => {
			const createExternalTransferState = state;

			createExternalTransferState.makeTransferLoader = false;
		},

		// getMarginWalletBorrowHistoryUpdate: (
		// 	state,
		// 	action: PayloadAction<IOrdersHistoryUpdateResponseDataPayload>,
		// ) => {
		// 	const { payload } = action;
		// 	const ordersHistoryUpdateState = state;

		// 	ordersHistoryUpdateState.tablesHistory.ordersHistory = {
		// 		...ordersHistoryUpdateState.tablesHistory.ordersHistory,
		// 		data: payload.orders,
		// 		total: payload?.orders?.length || 0,
		// 	} as IOrdersHistoryData;
		// 	ordersHistoryUpdateState.tablesHistory.ordersHistoryLoader = false;
		// },
	},
});

export default marginWallets.reducer;
export const {
	getMarginCrossWalletsRequest,
	getMarginCrossWalletsSuccess,
	getMarginIsolatedWalletsRequest,
	getMarginIsolatedWalletsSuccess,
	getMarginIndexPricesSuccess,
	getMarginIndexPricesRequest,
	postMarginTransferRequest,
	getMaximumBorrowAmoiuntRequest,
	getMaximumBorrowAmountSuccess,
	postTakeBorrowRequest,
	getborrowAvailableRequest,
	getborrowAvailableSuccess,
	postTakeBorrowIsolateRequest,
	updateIsolatedWalletsSuccess,
	updateCrossWalletsSuccess,
	updateMarginIndexPricesSuccess,
	getTransferHistorySuccess,
	getTransferHistoryRequest,
	getMarginWalletBorrowHistoryRequest,
	getMarginWalletBorrowHistorySuccess,
	getRepayHistoryRequest,
	getRepayHistorySuccess,
	getInterestedHistorySuccess,
	getInterestedHistoryRequest,
	getMarginCallHistorySuccess,
	getMarginCallHistoryRequest,
	getLiqudationHistorySuccess,
	getLiqudationHistoryRequest,
	setBorrowSuccess,
	createExternalTransferRequest,
	createExternalTransferFinally,
} = marginWallets.actions;
