import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import L from 'i18n-react';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { APP_NAME } from 'services/constants/env';
import {
	getIpConfirmIsLoad,
	getLoginData,
	getSocialLoginData,
} from 'redux/reducers/auth/selectors';
import { ipConfirmRequest } from 'redux/reducers/auth/reducer';
import { IipConfirmPayloadData } from 'redux/reducers/auth/types';
import Auth from 'layouts/Auth';
import GoogleCaptcha from 'components/GoogleCaptcha';
import IPConfirmForm from 'components/Forms/IPConfirmForm';
import Loader from 'ui/Loader';

// ==========================================:
const IPConfirmation = () => {
	const ipConfirmData = useRef<IipConfirmPayloadData | null>(null);
	const [open, setOpen] = useState(false);
	const ipConfirmIsLoad = useSelector(getIpConfirmIsLoad);
	const loginData = useSelector(getLoginData);
	const socialLoginData = useSelector(getSocialLoginData);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleIpConfirmSubmit = useCallback(
		(values: IipConfirmPayloadData) => {
			// ipConfirmData.current = values;
			// setOpen(true);
			dispatch(ipConfirmRequest({ data: values, history, captcha: '' }));
		},
		[dispatch, history],
	);

	useEffect(() => {
		if (!loginData && !socialLoginData) {
			history.push('/login');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Auth
			title={`${String(APP_NAME)}.com | ${String(
				L.translate('IPConfirmation.ip_confirmation_title'),
			)}`}
		>
			<div className="authorization">
				<Link to="/login" className="back-btn">
					<span className="back-btn__arrow icon-arrow" />
					<span className="back-btn__text">
						{String(L.translate('Forms.ForgotPassword.forgot_password_link'))}
					</span>
				</Link>
				<p className="authorization__title">
					{String(L.translate('IPConfirmation.ip_confirmation_title'))}
				</p>
				<div className="authorization__details">
					<p>{String(L.translate('IPConfirmation.ip_confirmation_text'))}</p>
				</div>

				<IPConfirmForm ipConfirmSubmit={handleIpConfirmSubmit} />

				{/* {open && (
					<div className="popup-window popup-window--captcha">
						<div className="popup-window__inside">
							<GoogleCaptcha setCaptcha={handleCaptcha} captchaClose={handleCaptchaClose} />
							<div className="popup-window__close" onClick={handleCaptchaClose} />
						</div>
					</div>
				)} */}

				{ipConfirmIsLoad && (
					<div className="auth-loader-wrapper">
						<Loader />
					</div>
				)}
			</div>
		</Auth>
	);
};

export default IPConfirmation;
