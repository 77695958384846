import risk1 from 'assets/img/content/risk-slider1.png';
import risk2 from 'assets/img/content/risk-slider2.png';
import risk3 from 'assets/img/content/risk-slider3.png';
import L from 'i18n-react';

export const riskSwitcher = (riskValue: number) => {
	switch (true) {
		case riskValue >= 2.61:
			return {
				color: '#329F7F',
				img_path: risk1,
				label: L.translate('Margin.Risk.low'),
			};

		case riskValue <= 2.6 && riskValue >= 1.61:
			return {
				color: '#fd7e14',
				img_path: risk2,
				label: L.translate('Margin.Risk.middle'),
			};

		case riskValue <= 1.6 && riskValue >= 1.11:
			return {
				color: '#dc3545',
				img_path: risk3,
				label: L.translate('Margin.Risk.high'),
			};

		default:
			return {
				color: '#329F7F',
				img_path: risk1,
				label: L.translate('Margin.Risk.low'),
			};
	}
};
