// TODO: Переводы
import { FC, useState } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { PaymentItem } from './PaymentItem/PaymentItem';
import { IPropsModal } from './types';

export const SelectPayment: FC<IPropsModal> = ({
	openModal,
	closeModal,
	choosePayment,
	payment,
	currentPayment = 'Card',
}) => {
	const [check, setCheck] = useState(currentPayment);
	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<button type="button" onClick={closeModal} className="popup-close">
					<span className="popup-close__icon icon-close" />
				</button>
				<div className="popup-window__inside">
					<div className="popup popup--small">
						<div className="popup-header">
							<p className="popup-header__title">
								{L.translate('Account.PaymentMethods.choose_payment_method')}
							</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="add-card-list add-card-list--type3">
								{payment?.length &&
									payment?.map((item) => (
										<PaymentItem key={item} checked={check} setChecked={setCheck} card={item} />
									))}
							</div>
						</div>
						<div className="popup-submit popup-submit--more-mt popup-submit--sb">
							<button
								onClick={closeModal}
								type="button"
								className="button button--second-grey button--full-width "
							>
								{String(L.translate('Global.cancel'))}
							</button>
							<button
								onClick={() => choosePayment && choosePayment(check)}
								type="button"
								className="button button--full-width "
							>
								{String(L.translate('Global.confirm'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
