import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCreateNewPassIsLoad } from 'redux/reducers/auth/selectors';
import { createNewPasswordRequest } from 'redux/reducers/auth/reducer';
import L from 'i18n-react';
import Auth from 'layouts/Auth';
import CreateNewPassForm from 'components/Forms/CreateNewPassForm';
import { ICreateNewPasswordFormData } from 'components/Forms/CreateNewPassForm/types';
import { APP_NAME } from 'services/constants/env';
import Loader from 'ui/Loader';

// ==========================================:
const CreateNewPassword: FC = () => {
	const createNewPassLoad = useSelector(getCreateNewPassIsLoad);
	const dispatch = useDispatch();

	const handleCreateNewPass = useCallback(
		(values: ICreateNewPasswordFormData) => {
			dispatch(createNewPasswordRequest(values));
		},
		[dispatch],
	);

	return (
		<Auth title={`${String(APP_NAME)}.com | ${String(L.translate('Auth.create_new_password'))}`}>
			<div className="authorization">
				<CreateNewPassForm createNewPassSubmit={handleCreateNewPass} />
				{createNewPassLoad && (
					<div className="auth-loader-wrapper">
						<Loader />
					</div>
				)}
			</div>
		</Auth>
	);
};

export default CreateNewPassword;
