import { FC } from 'react';
import { IChips } from './types';

const Chips: FC<IChips> = ({ items, onRemove }) => {
	return (
		<>
			{items.map(({ id, name, imageSrc }) => (
				<div className="bank-item" key={id}>
					<span className="bank-item__img">{imageSrc && <img src={imageSrc} alt="" />}</span>
					{name}
					<button className="bank-item__remove" type="button" onClick={() => onRemove(id)}>
						<svg
							width="11"
							height="12"
							viewBox="0 0 11 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.168544 0.668544C0.351602 0.485485 0.648398 0.485485 0.831456 0.668544L10.8315 10.6685C11.0145 10.8516 11.0145 11.1484 10.8315 11.3315C10.6484 11.5145 10.3516 11.5145 10.1685 11.3315L0.168544 1.33146C-0.0145146 1.1484 -0.0145146 0.851602 0.168544 0.668544Z"
								fill="#03BC70"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10.8315 0.668544C11.0145 0.851602 11.0145 1.1484 10.8315 1.33146L0.831456 11.3315C0.648398 11.5145 0.351602 11.5145 0.168544 11.3315C-0.0145146 11.1484 -0.0145146 10.8516 0.168544 10.6685L10.1685 0.668544C10.3516 0.485485 10.6484 0.485485 10.8315 0.668544Z"
								fill="#03BC70"
							/>
						</svg>
					</button>
				</div>
			))}
		</>
	);
};

export default Chips;
