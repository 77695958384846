export const numberValidation = (text: string): boolean => {
	if (text?.length >= 2 && text?.[0] === '0' && text?.[1] !== '.') return false;

	return /^[0-9]*\.?[0-9]*$/.test(text);
};

export const positiveNumber = (value: string | number | null | undefined) => {
	if (Number(value) < 0) {
		return 0;
	}
	return value;
};

export const numberValidationNext = (text: string): boolean => {
	if (Number(text) === 0 || text === '') return true;
	if (text?.length >= 2 && text?.[0] === '0' && text?.[1] !== '.') return true;

	return false;
};

// export const windowReference = (url, makeFrame) => {
// 	// const a = document.createElement('a');
// 	// a.href = url;
// 	// a.target = '_blank';
// 	makeFrame(url);
// 	// a.target="iframe_a"
// 	// a.onclick = "this.target='_blank';";
// 	// //a.onclick = "this.target='iframe_a';";
// 	// a.click();
// };
