import { ChangeEvent, FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import Popup from 'reactjs-popup';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { setPhoneRequest, setPhoneTwoFaRequest } from 'redux/reducers/settings/reducer';
import { IUserPhoneEditPopupProps } from './types';

const UserPhoneEditPopup: FC<IUserPhoneEditPopupProps> = ({
	openModal,
	closeModal,
	twoFa,
	success,
}) => {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [secondsSms, setSecondsSms] = useState(0);
	const [isSmsSendCode, setIsSmsSendCode] = useState(false);
	const [smsCode, setSmsCode] = useState('');

	const dispatch = useDispatch();

	const userData = useSelector(getUserSettingsData);

	useEffect(() => {
		if (userData?.data?.phone) {
			setPhoneNumber(userData.data.phone);
		}
	}, [userData?.data?.phone]);

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;

		if (secondsSms > 0) {
			timer = setTimeout(() => setSecondsSms(secondsSms - 1), 1000);
			setIsSmsSendCode(true);
		} else {
			setSecondsSms(0);
			setIsSmsSendCode(false);
		}

		return () => {
			clearTimeout(timer);
			setIsSmsSendCode(false);
		};
	}, [secondsSms]);

	const handleChangeSms = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setSmsCode(value);
		}
	};

	const handleGetSmsCode = () => {
		if (phoneNumber) {
			dispatch(
				generateSmsRequest({ phone: phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}` }),
			);

			setSecondsSms(60);
		}
	};

	const handleCloseModal = () => {
		if (userData?.data?.phone) {
			setPhoneNumber(userData?.data?.phone);
		} else {
			setPhoneNumber('');
		}

		setSmsCode('');
		closeModal();
	};

	const handleEditPhone = () => {
		if (smsCode && phoneNumber) {
			const data = {
				phone: phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`,
				sms_code: Number(smsCode.trim()),
			};
			if (!twoFa) {
				dispatch(
					setPhoneRequest({
						data,
						closeModal: handleCloseModal,
					}),
				);
			} else {
				dispatch(
					setPhoneTwoFaRequest({
						data: {
							data,
							id: userData?.id,
							closeModal: handleCloseModal,
						},
						success,
					}),
				);
			}
		}
	};

	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={handleCloseModal}
			closeOnEscape
			lockScroll
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup">
						<button className="popup-close" onClick={handleCloseModal} type="button">
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title">
								{userData?.data?.phone
									? String(L.translate('Account.AddPhone.change_phone'))
									: String(L.translate('Account.AddPhone.add_phone'))}
							</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="popup-text popup-text--center">
								<p>{String(L.translate('Account.AddPhone.fill_fields'))}</p>
							</div>
						</div>

						<div className="transfer-form">
							<div className="input-phone-box">
								<div className="input-phone-wrapper">
									<div className="transfer-form__select select-block" />
									<div className="input">
										<div className="input-wrapper">
											<PhoneInput
												country="us"
												value={phoneNumber}
												onChange={(phone) => setPhoneNumber(phone)}
												placeholder={String(L.translate('Account.AddPhone.enter_phone_number'))}
												inputClass="input-item custom-phone-number-input"
											/>
										</div>
									</div>
								</div>

								{isSmsSendCode && secondsSms > 0 ? (
									<span className="timer-wrapper timer-wrapper--phone-input">{secondsSms}s</span>
								) : (
									<button className="button" type="button" onClick={handleGetSmsCode}>
										{String(L.translate('Account.AddPhone.send_code'))}
									</button>
								)}
							</div>

							<div className="input ">
								<div className="input-wrapper">
									<input
										className="input-item"
										type="text"
										placeholder={String(L.translate('Account.AddPhone.phone_code'))}
										name="sms_code"
										value={smsCode}
										onChange={handleChangeSms}
									/>
								</div>
							</div>

							<button
								disabled={!smsCode || !!(smsCode.length < 6) || !phoneNumber}
								className={`button button--full-width ${
									!smsCode || !!(smsCode.length < 6) || !phoneNumber ? 'button--not-verificate' : ''
								} `}
								type="button"
								onClick={handleEditPhone}
							>
								{userData?.data?.phone
									? String(L.translate('Account.AddPhone.change_phone'))
									: String(L.translate('Account.AddPhone.add_phone'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default UserPhoneEditPopup;
