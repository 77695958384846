/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { getProfileRequest } from 'redux/reducers/p2p/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { getAccountP2P } from 'redux/reducers/auth/selectors';

import UserCenter from './UserCenter';
/* eslint-disable no-debugger */
const Profile: FC = () => {
	const location = useLocation<any>();
	const dispatch = useDispatch();
	// const currencyItem = useSelector(getCurrencyItem);
	// const [currentPage, setCurrentPage] = useState<number>(1);
	// const allAdvertisement = useSelector(getAllAdvertisement);
	const userSettings = useSelector(getUserSettingsData);
	const accountP2P = useSelector(getAccountP2P);
	// const advertisement = allAdvertisement?.advertisements;
	// const user = useSelector(getProfile);

	// const { profilename } = queryString.parse(location.search);
	// const { username } = user;
	// const name = profilename || username;
	// const profile = useSelector(storeProfile);
	// // const kycStatus = profile.kyc_status === 'verified';
	// // const phoneStatus = profile.phone_status === 'verified';
	// const actionBtnActive = user.username !== name;
	// const country = useSelector(storeCountryCurrent);
	// const countryId = country && country.currency_id;

	// const fetchUserProfile = useCallback(
	// 	(n) => {
	// 		dispatch(getProfile(n));
	// 	},
	// 	[dispatch],
	// );

	// const handleAddToBlockList = () => {
	// 	if (!profile.user_id) return;
	// 	dispatch(postBlockList({ id: profile.user_id }, closeModal, history));
	// };

	// const addToBlockList = () => {
	// 	dispatch(openModal(() => <AreYouSure confirm={() => handleAddToBlockList()} />));
	// };
	const marginTypeSearch = new URLSearchParams(location.search).get('profilename') || null;
	useEffect(() => {
		if (!userSettings || !accountP2P) return;

		if (marginTypeSearch) {
			dispatch(getProfileRequest({ name: String(marginTypeSearch) }));
			return;
		}
		dispatch(getProfileRequest({ name: String(userSettings?.p2p_username) }));
	}, [dispatch, userSettings, marginTypeSearch, accountP2P]);
	// useEffect(() => {
	// 	if (location.state) {
	// 		dispatch(setCurrency({ ...location.state.currency }));
	// 	}
	// }, [dispatch, location.state]);
	// useEffect(() => {
	// 	// 	if (location.pathname === routes.Profile.path) {
	// 	if (!currencyItem) return;
	// 	// 		if (!name) return;
	// 	// 		if (username === name) {
	// 	// 			dispatch(getProfileAdvertisements('?current_page=1&per_page=5'));
	// 	// 		} else if (name === profilename) {

	// 	dispatch(
	// 		getAllAdvertisementRequest({
	// 			params: {
	// 				per_page: 10,
	// 				current_page: currentPage,
	// 				currency_id: currencyItem.id,
	// 			},
	// 		}),
	// 	);
	// 	// 			dispatch(
	// 	// 				getProfileAdvertOther(
	// 	// 					`?currency_id=${countryId}&username=${name}&type=buy&current_page=1&per_page=5`,
	// 	// 				),
	// 	// 			);
	// 	// 		}
	// 	// 	}
	// }, [location, dispatch, currencyItem, currentPage]);
	return (
		<div className="content-wrapper  content-wrapper--bg">
			<div className="p2p-wrapper">
				<div className="container container--big">
					<UserCenter name={marginTypeSearch} />
				</div>
			</div>
		</div>
	);
};

export default Profile;
