import { useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeEmail2FaRequest,
	changePhone2FaRequest,
	userSettingsRequest,
} from 'redux/reducers/settings/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { ETwoFaMode } from 'redux/reducers/settings/types';
import UserPhoneEditPopup from 'components/UserAccount/UserPhoneEditPopup';
import SuccessPopup from 'layouts-elements/popups/SuccessPopup';

const PhoneMail2Fa = () => {
	const dispatch = useDispatch();
	const userSettings = useSelector(getUserSettingsData);
	const [openModalPhone, setOpenPhoneModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [message, setMessage] = useState('');

	useEffect(() => {
		dispatch(userSettingsRequest());
	}, [dispatch]);

	const closeModalPhone = () => setOpenPhoneModal(false);

	const showPhoneDeactivationSuccess = () => {
		setShowSuccessModal(true);
		setMessage(String(L.translate('Account.TwoFactorAuth.phone_number_verification_deactivated')));
	};

	const showEmailDeactivationSuccess = () => {
		setShowSuccessModal(true);
		setMessage(String(L.translate('Account.TwoFactorAuth.email_address_verification_deactivated')));
	};

	const showPhoneActivationSuccess = () => {
		setShowSuccessModal(true);
		setMessage(String(L.translate('Account.TwoFactorAuth.phone_number_verification_activated')));
	};

	const showEmailActivationSuccess = () => {
		setShowSuccessModal(true);
		setMessage(String(L.translate('Account.TwoFactorAuth.email_address_verification_activated')));
	};

	const changePhoneNumber = () => {
		setOpenPhoneModal((prevOpenModal) => !prevOpenModal);
	};

	const handlePhoneActivate = () => {
		if (userSettings?.data.phone && !userSettings.phone_2fa_enabled) {
			dispatch(
				changePhone2FaRequest({
					data: { mode: ETwoFaMode.ENABLE, id: userSettings.id },
					success: showPhoneActivationSuccess,
				}),
			);
		} else if (!userSettings?.data.phone) {
			changePhoneNumber();
		}
	};

	const handlePhoneDeactivate = () => {
		if (userSettings?.phone_2fa_enabled) {
			dispatch(
				changePhone2FaRequest({
					data: { mode: ETwoFaMode.DISABLE, id: userSettings.id },
					success: showPhoneDeactivationSuccess,
				}),
			);
		}
	};

	const handleEmailActivate = () => {
		if (userSettings && !userSettings.email_2fa_enabled) {
			dispatch(
				changeEmail2FaRequest({
					data: { mode: ETwoFaMode.ENABLE, id: userSettings.id },
					success: showEmailActivationSuccess,
				}),
			);
		} else
			dispatch(
				changeEmail2FaRequest({
					data: { mode: ETwoFaMode.DISABLE, id: userSettings?.id || 0 },
					success: showEmailDeactivationSuccess,
				}),
			);
	};

	const hidenEmail = () => {
		const emailArr = userSettings?.email.split('@');
		return `${emailArr?.[0].slice(0, 3) || ''}${
			emailArr?.[0].slice(3).replace(/[\s\S]/g, '*') || ''
		}@${emailArr?.[1] || ''}`;
	};

	return (
		<>
			<SuccessPopup
				openModal={showSuccessModal}
				closeModal={() => setShowSuccessModal(false)}
				message={message}
			/>
			<UserPhoneEditPopup
				openModal={openModalPhone}
				closeModal={closeModalPhone}
				twoFa={!userSettings?.data.phone}
				success={showPhoneActivationSuccess}
			/>
			<div className="content-block">
				<div className="content-block__item">
					<div className="content-block__header">
						<div className="content-block__title">
							<span className="content-block__title-icon content-block__title-icon--phone content-block__title-icon--svg">
								<svg
									width="18"
									height="22"
									viewBox="0 0 18 22"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M17 6V16C17 20 16 21 12 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H12C16 1 17 2 17 6Z"
										stroke="#777E91"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M11 4.5H7"
										stroke="#777E91"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M8.99995 18.1C9.85599 18.1 10.55 17.406 10.55 16.55C10.55 15.694 9.85599 15 8.99995 15C8.14391 15 7.44995 15.694 7.44995 16.55C7.44995 17.406 8.14391 18.1 8.99995 18.1Z"
										stroke="#777E91"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							<span className="content-block__title-text">
								{String(L.translate('Account.TwoFactorAuth.phone_number_verification'))}
							</span>
						</div>
					</div>
					<div className="content-block__main">
						<div className="tfa-option">
							<div className="tfa-option__text">
								<p>{String(L.translate('Account.TwoFactorAuth.protect_transactions'))}</p>
							</div>
							<div className="tfa-option__value">
								{userSettings?.data.phone ? (
									<div className="confirmed-value">
										<span className="confirmed-value__icon">
											<svg
												width="8"
												height="6"
												viewBox="0 0 8 6"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1 3L2.99765 5L7 1"
													stroke="white"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</span>
										<p className="confirmed-value__text">{userSettings?.data.phone}</p>
									</div>
								) : (
									<div className="confirmed-value">
										<span className="confirmed-value__icon confirmed-value__icon--type2">
											<svg
												width="8"
												height="7"
												viewBox="0 0 8 7"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M7.16637 1.05504C7.36559 0.855821 7.36559 0.53283 7.16637 0.333615C6.96716 0.1344 6.64417 0.1344 6.44495 0.333615L3.99999 2.77857L1.55504 0.333615C1.35582 0.1344 1.03283 0.1344 0.833616 0.333615C0.634401 0.53283 0.634401 0.855821 0.833616 1.05504L3.27857 3.49999L0.833615 5.94495C0.6344 6.14416 0.6344 6.46716 0.833615 6.66637C1.03283 6.86559 1.35582 6.86559 1.55504 6.66637L3.99999 4.22141L6.44495 6.66637C6.64417 6.86559 6.96716 6.86559 7.16637 6.66637C7.36559 6.46716 7.36559 6.14417 7.16637 5.94495L4.72141 3.49999L7.16637 1.05504Z"
													fill="white"
												/>
											</svg>
										</span>
										<p className="confirmed-value__text">
											{String(L.translate('Account.TwoFactorAuth.not_linked'))}
										</p>
									</div>
								)}
							</div>
							<div className="tfa-option__actions">
								{!userSettings?.phone_2fa_enabled ? (
									<button
										className="button  button--small-height2"
										type="button"
										onClick={handlePhoneActivate}
									>
										{userSettings?.data.phone
											? String(L.translate('Account.TwoFactorAuth.enable'))
											: String(L.translate('Account.TwoFactorAuth.add_phone_and_enable'))}
									</button>
								) : (
									<>
										<button
											className="button  button--small-height2"
											type="button"
											onClick={changePhoneNumber}
										>
											{String(L.translate('Account.TwoFactorAuth.change'))}
										</button>
										<button
											className="button button--third-grey button--small-height2"
											type="button"
											onClick={handlePhoneDeactivate}
										>
											{String(L.translate('Account.TwoFactorAuth.delete'))}
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="content-block__item">
					<div className="content-block__header">
						<div className="content-block__title">
							<span className="content-block__title-icon  content-block__title-icon--mail content-block__title-icon--svg">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M22 10.5V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H14"
										stroke="#777E91"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7 9L10.13 11.5C11.16 12.32 12.85 12.32 13.88 11.5L15.06 10.56"
										stroke="#777E91"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M19.5 8C20.8807 8 22 6.88071 22 5.5C22 4.11929 20.8807 3 19.5 3C18.1193 3 17 4.11929 17 5.5C17 6.88071 18.1193 8 19.5 8Z"
										stroke="#777E91"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							<span className="content-block__title-text">
								{String(L.translate('Account.TwoFactorAuth.email_verification'))}
							</span>
						</div>
					</div>
					<div className="content-block__main">
						<div className="tfa-option">
							<div className="tfa-option__text">
								<p>{String(L.translate('Account.TwoFactorAuth.protect_transactions'))}</p>
							</div>
							<div className="tfa-option__value">
								<div className="confirmed-value">
									<span className="confirmed-value__icon">
										<svg
											width="8"
											height="6"
											viewBox="0 0 8 6"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1 3L2.99765 5L7 1"
												stroke="white"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
									<p className="confirmed-value__text">{hidenEmail()}</p>
								</div>
							</div>
							<div className="tfa-option__actions">
								<button
									className={`button ${
										userSettings?.email_2fa_enabled ? 'button--third-grey' : ''
									} button--small-height2`}
									type="button"
									onClick={handleEmailActivate}
								>
									{userSettings?.email_2fa_enabled
										? String(L.translate('Account.TwoFactorAuth.turn_off'))
										: String(L.translate('Account.TwoFactorAuth.turn_on'))}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PhoneMail2Fa;
