import { FC, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { appLanguage, getTranslates } from 'redux/reducers/app/selectors';
import { routesList } from 'routes/routesList';
import { languages } from 'languages';
import LocaleStorage from 'services/HOC/LocaleStorage/index';
import SocketConnectWrapper from 'components/SocketConnectWrapper';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import ThemeProvider from 'components/ThemeProvider';
import ScrollToTop from 'ui/ScrollToTop';
import { getTranslatesRequest } from 'redux/reducers/app/reducer';
import { deepMerge } from 'services/utils/object';

// ==========================================:
const App: FC = () => {
	// const dispatch = useDispatch();
	const language = useSelector(appLanguage);
	// const translates = useSelector(getTranslates);
	// L.setTexts(deepMerge(languages[language], translates));
	L.setTexts(languages[language]);

	// useEffect(() => {
	// 	dispatch(getTranslatesRequest({ lang: language.toLowerCase() }));
	// }, [dispatch, language]);

	return (
		<BrowserRouter>
			<ScrollToTop />
			<SocketConnectWrapper>
				<SocketWithPrivateTopicConnectWrapper topicName="private-notifications">
					<LocaleStorage>
						<ThemeProvider>
							<Switch>
								{routesList?.map((route) => (
									<Route key={route.path} exact path={route.path} component={route.component} />
								))}
								<Redirect to="/" />
							</Switch>
						</ThemeProvider>
					</LocaleStorage>
				</SocketWithPrivateTopicConnectWrapper>
			</SocketConnectWrapper>
		</BrowserRouter>
	);
};

export default App;
