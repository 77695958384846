import { FC } from 'react';
import L from 'i18n-react';
import { getPayments } from 'redux/reducers/p2p/selectors';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import Input from 'ui/Formik/Input';
import CSearchInput from 'ui/CSearchInput';
import { IPaymentItem } from 'redux/reducers/p2p/types';
import { postPaymentMethodRequest } from 'redux/reducers/p2p/reducer';
import TextError from 'ui/Formik/TextError';
import { IPropsModal, ISubmitCards } from './types';

export const Card: FC<IPropsModal> = ({ closeModal, handleStatus }) => {
	const dispatch = useDispatch();
	const payments = useSelector(getPayments);
	const cardBank: Array<IPaymentItem> | any =
		(payments && payments?.filter((item: IPaymentItem) => item.type === 'bank')) || [];

	cardBank.sort((item1: IPaymentItem, item2: IPaymentItem) => {
		if (item1.payment_name_eng < item2.payment_name_eng) {
			return -1;
		}
		if (item1.payment_name_eng > item2.payment_name_eng) {
			return 1;
		}
		return 0;
	});

	const initialValues: ISubmitCards = {
		name: '',
		card_number: '',
		payment_id: '',
	};
	const validationSchema = yup.object().shape({
		card_number: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.card_number_error')))
			.min(16, String(L.translate('Account.PaymentMethods.AddCard.card_number_error_2'))),
		name: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.card_holder_req_err'))),
		payment_id: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.payment_method_error'))),
	});

	const handleSubmit = (value: ISubmitCards) => {
		const selectedPayment = cardBank?.find(
			(item: IPaymentItem) => item.id === Number(value.payment_id),
		);
		if (!selectedPayment) return;
		const params = {
			payload: {
				payment_method_id: Number(selectedPayment?.id),
				type: 'card',
				requisite: {
					name: value.name || '',
					card_number: String(value.card_number).replaceAll(' ', ''),
					bank_address: String(selectedPayment?.payment_name_eng),
				},
			},
			onSuccess: () => {
				handleStatus && handleStatus('card', 'success');
			},
			onError: () => {
				handleStatus && handleStatus('card', 'error');
			},
		};
		dispatch(postPaymentMethodRequest(params));
	};

	return (
		<div className="popup-window">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(false);
					handleSubmit(values);
					resetForm();
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ values, setValues, errors, touched, setErrors, isSubmitting }) => {
					const selectedPayment = cardBank?.find(
						(item: IPaymentItem) => item.id === Number(values.payment_id),
					);
					const isError = (field: keyof typeof values) => touched[field] && errors[field];
					return (
						<Form>
							<div className="popup-window__inside">
								<div className="popup popup--medium">
									<button type="button" onClick={closeModal} className="popup-close">
										<span className="popup-close__icon icon-close" />
									</button>
									<div className="popup-header">
										<p className="popup-header__title">
											{L.translate('P2P.Profile.Popups.Card.title')}
										</p>
										<div className="popup-text popup-text--center">
											<p>{L.translate('P2P.Profile.Popups.Card.subtitle')}</p>
										</div>
									</div>
									<div className="popup-body">
										<Field
											name="name"
											title={`${String(L.translate('P2P.Profile.Popups.Card.Titles.name'))} *`}
											component={Input}
											placeholder={L.translate('P2P.Profile.Popups.Card.Placeholders.name')}
										/>

										<div className="input">
											<p className="input__name">
												{L.translate('P2P.Profile.Popups.Card.Titles.card_number')} *
											</p>
											<div
												className={`input-wrapper ${isError('card_number') ? 'input--error' : ''}`}
											>
												<Field
													name="card_number"
													className="input-item input-item--right-double-icon"
													placeholder={L.translate(
														'P2P.Profile.Popups.Card.Placeholders.card_number',
													)}
													value={values.card_number
														.replace(/[^0-9]/g, '')
														.replace(/(\d{4})/g, '$1 ')
														.trim()}
													maxLength={19}
												/>
											</div>
											<ErrorMessage name="card_number" component={TextError} />
										</div>
										<div className="input">
											<div className="input__name">
												{String(L.translate('P2P.Marketplace.payment_title'))} *
											</div>
											<CSearchInput
												listData={cardBank}
												onSelect={(value: IPaymentItem) => {
													setValues({ ...values, payment_id: String(value.id) });
												}}
												onBlur={() => {
													setErrors({
														...errors,
														payment_id: values.payment_id
															? ''
															: String(L.translate('Account.PaymentMethods.payment_method_error')),
													});
												}}
												valueProp="id"
												nameProp="payment_name_eng"
												dataItem={selectedPayment?.id}
												currenClass="select--regular2"
												showValue={false}
												start="0"
												error={!!isError('payment_id')}
											/>
											{isError('payment_id') ? <TextError>{errors.payment_id}</TextError> : null}
										</div>
									</div>
									<div className="popup-submit popup-submit--more-mt popup-submit--sb">
										<button
											onClick={closeModal}
											type="button"
											className="button button--second-grey button--full-width "
										>
											{String(L.translate('Global.cancel'))}
										</button>
										<button
											type="submit"
											disabled={isSubmitting}
											className="button button--full-width "
										>
											{String(L.translate('Global.confirm'))}
										</button>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
