import {
	IAssetPairsFeesAndLimitsResponsePayload,
	IAssetPairsResponsePayload,
} from 'redux/reducers/assetPairs/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IApiTopPairWithChart, IAssetPairsApi } from './types';

// ==========================================:
export const assetPairs: IAssetPairsApi = {
	getAssetPairs: () =>
		http
			.get<IAssetPairsResponsePayload>(endpoint.assetPairs.ASSET_PAIRS)
			.then((response) => response.data),

	getAssetPairsFavorite: () =>
		http
			.get<IAssetPairsResponsePayload>(endpoint.assetPairs.ASSET_PAIRS_FAVORITES)
			.then((response) => response.data),
	putAssetPairsFavorite: (params) =>
		http
			.put(endpoint.assetPairs.ASSET_PAIRS_FAVORITES, { ...params })
			.then((response) => response.data),
	getAssetPairsFeesAndLimits: () =>
		http
			.get<IAssetPairsFeesAndLimitsResponsePayload>(endpoint.assetPairs.ASSET_PAIRS_FEES_AND_LIMITS)
			.then((response) => response.data),

	getTopPairsWithChart: () =>
		http
			.get<IApiTopPairWithChart[]>(endpoint.assetPairs.GET_TOP_PAIRS_WITH_CHART)
			.then(({ data }) => data),
	getMoreTopPairsWithChart: () =>
		http
			.get<IApiTopPairWithChart[]>(endpoint.assetPairs.GET_MORE_TOP_PAIRS_WITH_CHART)
			.then(({ data }) => data),
};
