import {
	IMarginWalletsStore,
	IIndexPrices,
	IMarginIsolatedWallets,
	ITransferHistoryResponsePayload,
	IUserMarginWalletResponsePayload,
	IUserLiquidationWalletResponsePayload,
	ICreateExternalTransferRequestParams,
} from 'redux/reducers/marginWallets/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IMarginWalletsApi } from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// ==========================================:
export const marginWallets: IMarginWalletsApi = {
	getMarginCrossWallets: () =>
		http
			.get<IMarginWalletsStore>(endpoint.marginWallets.MARGIN_CROSS_WALLETS)
			.then((response) => response.data),
	getMarginIsolatedWallets: () =>
		http
			.get<IMarginIsolatedWallets>(endpoint.marginWallets.MARGIN_ISOLATED_WALLETS)
			.then((response) => response.data),

	getIndexPrices: () =>
		http.get<IIndexPrices>(endpoint.marginWallets.INDEX_PRICES).then((response) => response.data),

	postTransferMargin: async ({ type, params }) => {
		const marginIsoltedTrue = params?.transfer
			? endpoint.marginWallets.TRANSFER_ISOLATED_TO_MARGIN
			: endpoint.marginWallets.TRANSFER_TO_MARGIN;
		const marginIsoltedFalse = params?.transfer
			? endpoint.marginWallets.TRANSFER_ISOLATED_TO_SPOT
			: endpoint.marginWallets.TRANSFER_TO_SPOT;
		return http
			.post<any>(type ? marginIsoltedTrue : marginIsoltedFalse, params)
			.then((response) => response.data);
	},
	getMaximumBorrowAmount: (assetId) =>
		http
			.get<number>(endpoint.marginWallets.BORROW_MAXIMUM_AMOUT(assetId))
			.then((response) => response.data),
	getBorrowAvailable: (params) =>
		http
			.get<any>(endpoint.marginWallets.BORROW_AVAILABLE, { params })
			.then((response) => response.data),
	postBorrowTake: async ({ type, params }) => {
		return http
			.post<any>(
				type === 'repay'
					? endpoint.marginWallets.BORROW_PAY_TAKE(params.assetId, params.amount)
					: endpoint.marginWallets.BORROW_TAKE(params.assetId, params.amount),
			)
			.then((response) => response.data);
	},
	postBorrowIsolatedTake: async ({ type, params }) => {
		return http
			.post<any>(
				type === 'repay'
					? endpoint.marginWallets.BORROW_ISOLATED_PAY_TAKE
					: endpoint.marginWallets.BORROW_ISOLATED_TAKE,
				params,
			)
			.then((response) => response.data);
	},
	getTransferHistory: (payload: any) =>
		http
			.get<ITransferHistoryResponsePayload>(endpoint.marginWallets.GET_HISTORY_TRANSFER, payload)
			.then((response) => response.data),
	getBorrowHisotry: ({ params, isolated }) =>
		http
			.get<IUserMarginWalletResponsePayload>(
				isolated
					? endpoint.marginWallets.BORROW_ISOLATED_HISTORY
					: endpoint.marginWallets.BORROW_HISTORY,
				{ params },
			)
			.then((response) => response.data),
	getRepayHisotry: ({ params, isolated }) =>
		http
			.get<IUserMarginWalletResponsePayload>(
				isolated
					? endpoint.marginWallets.REPAY_HISTORY_ISOLATED
					: endpoint.marginWallets.REPAY_HISTORY,
				{ params },
			)
			.then((response) => response.data),
	getInterestHisotry: ({ params, isolated }) =>
		http
			.get<IUserMarginWalletResponsePayload>(
				isolated
					? endpoint.marginWallets.INTERSET_HISTORY_ISOLATED
					: endpoint.marginWallets.INTEREST_HISTORY,
				{ params },
			)
			.then((response) => response.data),
	getMarginCallHisotry: ({ params, isolated }) =>
		http
			.get<IUserMarginWalletResponsePayload>(
				isolated
					? endpoint.marginWallets.CALL_HISTORY_ISOLATED
					: endpoint.marginWallets.CALL_HISTORY,
				{ params },
			)
			.then((response) => response.data),
	getLiqudityHisotry: ({ params, isolated }) =>
		http
			.get<IUserLiquidationWalletResponsePayload>(
				isolated
					? endpoint.marginWallets.LIQUIDATION_HISTORY_ISOLATED
					: endpoint.marginWallets.LIQUIDATION_HISTORY,
				{ params },
			)
			.then((response) => response.data),
	createExternalTransfer: (params: ICreateExternalTransferRequestParams) =>
		http
			.post<any>(endpoint.marginWallets.CREATE_EXTERNAL_TRANSFER, params)
			.then((response) => response.data),
};
