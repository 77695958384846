import L from 'i18n-react';
import { NavLink } from 'react-router-dom';
import { navList } from 'routes/routesList';

const Tabs = () => {
	return (
		<div className="line-tab line-tab--prices">
			<NavLink to={navList.ourPrices.path} exact className="line-tab__item">
				{L.translate('OurPrices.payment_methods')}
			</NavLink>
			<NavLink to={navList.ourPricesCrypto.path} exact className="line-tab__item">
				{L.translate('OurPrices.cryptocurrency')}
			</NavLink>
			<NavLink to={navList.ourPricesLimits.path} exact className="line-tab__item">
				{L.translate('OurPrices.limits')}
			</NavLink>
		</div>
	);
};

export default Tabs;
