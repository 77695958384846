import { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import L from 'i18n-react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GOOGLE_CAPTCHA, APP_NAME } from 'services/constants/env';
import { forgotTwoFaRequest } from 'redux/reducers/auth/reducer';
import Auth from 'layouts/Auth';
import ForgotTwoFaForm from 'components/Forms/ForgotTwoFaForm';
import { IForgotTwofaFormItem } from 'components/Forms/ForgotTwoFaForm/types';
import { useHistory, useLocation } from 'react-router';
import Loader from 'ui/Loader';
import Information from 'components/HomeBlocks/Information/Inforamtion';
/* eslint-disable @typescript-eslint/no-explicit-any */
// ==========================================:
/* eslint-disable */
const ForgotTwofa: FC = () => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [loginData, setLoginData] = useState<null | IForgotTwofaFormItem>(null);
	const [captchaLoading, setCaptchaLoading] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const handleTwoFaSubmit = useCallback(
		(values: any): void => {
			dispatch(
				forgotTwoFaRequest({
					data: {
						...values,
					},
					history,
				}),
			);
			setOpen(true);
		},
		[dispatch, history],
	);

	useEffect(() => {
		if (location.state === '2fa_success') {
			setMessage('2fa_success');
			return;
		}
		setMessage('');
	}, [location.state]);

	return (
		<Auth
			title={`${String(APP_NAME)}.com | ${String(
				L.translate('Forgot2faPage.forgot_2fa_page_title'),
			)}`}
		>
			<GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA}>
				{message ? (
					<Information text="Your 2FA reset request has been submitted. The result of the reset will be communicated via email" />
				) : (
					<div className="authorization">
						<ForgotTwoFaForm
							twoFaSubmit={handleTwoFaSubmit}
							captchaLoading={captchaLoading}
							setCaptchaLoading={setCaptchaLoading}
						/>
					</div>
				)}

				{captchaLoading && (
					<div className="auth-loader-wrapper">
						<Loader />
					</div>
				)}

				{/* {open && (
				<div className="popup-window popup-window--captcha">
					<div className="popup-window__inside">
						<GoogleCaptcha setCaptcha={handleCaptcha} captchaClose={hangleCaptchaClose} />
						<div className="popup-window__close" onClick={hangleCaptchaClose} />
					</div>
				</div>
			)} */}
			</GoogleReCaptchaProvider>
		</Auth>
	);
};

export default ForgotTwofa;
