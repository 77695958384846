import axios from 'axios';
import L from 'i18n-react';
import { ChangeEvent, FC, useEffect, useMemo, useState, useCallback, FocusEvent } from 'react';
import { useHistory, useLocation } from 'react-router';
import visaIcon from 'assets/images/general/visa.webp';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import {
	getWalletsIsLoad,
	getWalletsList,
	getWalletsAddress,
} from 'redux/reducers/wallets/selectors';
import { IWalletItem, IWalletNetworkItem } from 'redux/reducers/wallets/types';
import { getDepositFee, getCardList } from 'redux/reducers/cards/selectors';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { cardHideNumber } from 'services/utils/cardHelper';
import { clearWalletAddress, getGenerateAddressRequest } from 'redux/reducers/wallets/reducer';
import { ArrowRight, ArrowRightGrey } from 'assets/svg-icon';
import DCDropdown from 'ui/DCDropdown';
import DCNDropdown from 'ui/DCNDropdown';
import Loader from 'ui/Loader';
import TextError from 'ui/Formik/TextError';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import {
	getDepositFiatFeeRequest,
	initialDepositFiatFee,
	getCardsRequest,
} from 'redux/reducers/cards/reducer';
import { TFeeDepositChannelName, IFeeDepoitFiatPayload } from 'redux/reducers/cards/types';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import {
	getCurrencyPaymentsListRequest,
	postPaymentsCartubank,
	getFiatLimitationsRequest,
	getBankTransferRequest,
} from 'redux/reducers/payments/reducer';
import {
	getCurrencyPaymentsList,
	getCurrencyPaymentsListLoader,
	getFiatLimitations,
	getBankAccounts,
} from 'redux/reducers/payments/selectors';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import { convertMessage } from 'services/utils/trimComment';
import { notificationContainer } from 'services/utils/notificationContainer';
import { EPaymentsListType } from 'redux/reducers/payments/types';
import { numberExists } from 'services/utils/number';
import { isStablecoin } from 'services/utils/wallets';
import Notify from 'ui/StableCoinNotify';
import { IStateDepositLocation, ISelectProps } from '../types';

/* eslint-disable */ // ==========================================:
const SelecetCurrencyPayment: FC<ISelectProps> = ({
	setActiveView,
	selectWallet,
	setSelectWallet,
	selectedPayment,
	setSelectedPayment,
	setAmountField,
	setShowModal,
	setTranferModal,
	checkCard,
	selectWalletNetworks,
	setSelectWalletNetworks,
	walletNetworks,
	selectedBank,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const depositFee = useSelector(getDepositFee);
	const cardList = useSelector(getCardList);
	const fiatLimitations = useSelector(getFiatLimitations);
	const { state } = useLocation<IStateDepositLocation>();
	const [fee, setfee] = useState('');
	const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
	const [initPaymentMethod, setInitPaymentMethod] = useState(false);

	const fiatCode = selectWallet?.asset?.code?.slice(0, selectWallet?.asset?.code?.length - 1);

	// const [selectWallet, setSelectWallet] = useState<IWalletItem | null>(
	// 	state?.currentWallet || null,
	// );
	//const [selectedPayment, setSelectedPayment] = useState('');
	const [amount, setAmount] = useState('');
	const [amountNeeded, setAmountNeeded] = useState(false);
	const recive =
		(amount && fee && Number(amount) > Number(fee) && Number(amount) - Number(fee)) || 0;

	const [copyAddressStatus, setAddressCopyStatus] = useState({ copied: false });
	const [copyMemoStatus, setMemoCopyStatus] = useState({ copied: false });

	const userData = useSelector(getUserSettingsData);
	const walletsList = useSelector(getWalletsList);
	const walletsListLoading = useSelector(getWalletsIsLoad);
	const paymentsList = [...useSelector(getCurrencyPaymentsList), 'blockchain_network'];
	const paymentsListLoading = useSelector(getCurrencyPaymentsListLoader);
	const walletsAddress = useSelector(getWalletsAddress);
	const bankAccounts = useSelector(getBankAccounts);

	const isVip = userData?.is_vip;

	const limitation = useMemo(() => {
		const assetCode = selectWallet?.asset.code;
		let channelName = selectedPayment;
		if (selectedPayment === 'cartubank') {
			channelName = checkCard?.channel;
		}
		const fiatCode = assetCode?.endsWith('b')
			? assetCode?.slice(0, assetCode?.length - 1)
			: assetCode;
		const limitation = fiatLimitations?.find(
			(item) => item.asset_code === fiatCode && item.channel_name === channelName,
		);

		return limitation;
	}, [selectWallet, selectedPayment]);

	const cryptoWalletsList = useMemo(() => {
		if (!walletsList) return [];

		return walletsList.filter((wallet) => {
			return isStablecoin(wallet);
		});
	}, [walletsList]);

	const getWalletsSelectPlaceholder = () => {
		if (!walletsListLoading && !cryptoWalletsList.length) {
			return String(L.translate('DepositFiat.no_currencies'));
		}
		return String(L.translate('DepositFiat.select_currency'));
	};

	let closeCopyMessage: ReturnType<typeof setTimeout>;

	const handleAddressCopy = () => {
		setAddressCopyStatus({ copied: true });

		closeCopyMessage = setTimeout(() => {
			setAddressCopyStatus({ copied: false });
		}, 2000);
	};

	const handleMemoCopy = () => {
		setMemoCopyStatus({ copied: true });

		closeCopyMessage = setTimeout(() => {
			setMemoCopyStatus({ copied: false });
		}, 2000);
	};

	useEffect(() => {
		if (selectedPayment === 'cartubank') {
			dispatch(getCardsRequest());
		}
		if (selectedPayment === 'bank_transfer' && !bankAccounts) {
			dispatch(getBankTransferRequest());
		}
	}, [dispatch, selectedPayment]);

	// const handleNetworkSelect = (value: IWalletNetworkItem) => {
	// 	setSelectWalletNetworks(value);

	// 	const walletData = {
	// 		id: Number(selectWallet?.id),
	// 		network: String(value?.network_id),
	// 	};

	// 	dispatch(getGenerateAddressRequest(walletData));
	// };

	// const handleAddressCopy = () => {
	// 	setAddressCopyStatus({ copied: true });

	// 	closeCopyMessage = setTimeout(() => {
	// 		setAddressCopyStatus({ copied: false });
	// 	}, 2000);
	// };

	// const handleMemoCopy = () => {
	// 	setMemoCopyStatus({ copied: true });

	// 	closeCopyMessage = setTimeout(() => {
	// 		setMemoCopyStatus({ copied: false });
	// 	}, 2000);
	// };

	const changeAmountValue = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		if (inputValue && !/^\d+(\.+\d*)?$/.test(inputValue)) {
			return;
		}
		setAmount(e.target.value);
		setAmountField && setAmountField(e.target.value);
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
	};

	const handlePaymentSelect = (e: ChangeEvent<HTMLInputElement>): void => {
		setSelectedPayment && setSelectedPayment(e.target.id);
		setAmount('');
	};

	useEffect(() => clearTimeout(closeCopyMessage));

	useEffect(() => {
		dispatch(userSettingsRequest());
		dispatch(getFiatLimitationsRequest());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (amount && amountNeeded) {
			setAmountNeeded(false);
		}
	}, [amount, amountNeeded, setAmountNeeded]);

	const handleNetworkSelect = (value: IWalletNetworkItem) => {
		setSelectWalletNetworks(value);

		const walletData = {
			id: Number(selectWallet?.id),
			network: String(value?.network_id),
		};

		dispatch(getGenerateAddressRequest(walletData));
	};

	const handleDeposit = () => {
		switch (selectedPayment) {
			case 'paypal':
				setShowModal?.(true);
				break;
			case 'bank_transfer':
				setTranferModal?.(true);
				break;
			case 'cartubank':
				if (checkCard?.holder_name !== 'online') {
					setShowModal?.(true);
					break;
				}
				setActiveView('addCard');
				// dispatch(
				// 	postPaymentsCartubank({
				// 		amount,
				// 		asset_id: selectWallet?.asset?.id || 0,
				// 	}),
				// );
				break;
		}
		//setAmount('');
		// setSelectWallet(null);
		// setSelectedPayment('');
	};

	const depMin = isVip ? limitation?.vip_deposit_min : limitation?.deposit_min;
	const depMax = isVip ? limitation?.vip_deposit_max : limitation?.deposit_max;
	const noLimit = isVip
		? limitation?.vip_deposit_no_limit === 1
		: limitation?.deposit_no_limit === 1;

	const depMinOnChange = !noLimit && depMin && Number(depMin) > Number(amount);
	const depMaxOnChange = !noLimit && depMax && Number(depMax) < Number(amount);

	const disabledBut =
		!noLimit &&
		(!amount ||
			((depMin || depMin === 0) && Number(amount) < depMin) ||
			((depMax || depMax === 0) && Number(amount) > depMax));

	useEffect(() => {
		if (!amount) return;
		if (depMinOnChange || depMaxOnChange) {
			dispatch(initialDepositFiatFee());
			return;
		}

		if (!selectedPayment) {
			return;
		}
		const assetCode = selectWallet?.asset?.code;
		const fiatCode = assetCode?.endsWith('b')
			? assetCode.slice(0, assetCode.length - 1)
			: assetCode;
		const fiatWallet = walletsList?.find((w) => w.asset.code === fiatCode);
		const params: IFeeDepoitFiatPayload = {
			amount,
			asset_id: String(fiatWallet?.asset?.id),
			channel_name: selectedPayment as TFeeDepositChannelName,
		};

		if (selectedPayment === 'cartubank') {
			params.card_number = checkCard?.card_number;
		}

		if (selectedPayment === 'bank_transfer') {
			params.swift = selectedBank?.swift;
		}

		const newTypingTimeout: NodeJS.Timeout = setTimeout(() => {
			dispatch(getDepositFiatFeeRequest(params));
		}, 700);

		setTypingTimeout(newTypingTimeout);
	}, [dispatch, amount, selectedPayment]);

	useEffect(() => {
		if (depositFee) {
			setfee(String(depositFee) || '0');
		} else {
			setfee('0');
		}
	}, [depositFee]);
	useEffect(() => {
		return (): void => {
			if (typingTimeout) {
				clearTimeout(typingTimeout);
			}
		};
	}, [typingTimeout]);

	useEffect(() => {
		const { paymentMethod } = state || {};
		if (!initPaymentMethod && paymentMethod && paymentMethod !== selectedPayment) {
			setSelectedPayment?.(paymentMethod);
			setInitPaymentMethod(true);
		}
	}, [selectedPayment, setSelectedPayment, initPaymentMethod]);

	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);
	const handleInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.addEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.removeEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hanleChangeCardClick = (value: string) => {
		if (!amount) {
			setAmountNeeded(true);
			return;
		} else if (amountNeeded) {
			setAmountNeeded(false);
		}
		setActiveView(value);
	};

	return (
		<div className="create-offer__form">
			<div className="form-block">
				<div className="enter-value">
					{!!selectWallet && (
						<>
							<>
								{paymentsListLoading ? (
									<div className="enter-value__field--loader">
										<Loader />
									</div>
								) : (
									<>
										{paymentsList.length ? (
											<>
												<span className="enter-value__label enter-value__label--padding">
													{String(L.translate('DepositFiat.pay_with'))}
												</span>
												<div className="enter-value__field enter-value__field--radio">
													{paymentsList.map((el) => (
														<div key={el} className="radio">
															<label className="radio__label">
																<input
																	id={el}
																	type="radio"
																	name="payment"
																	className="hidden"
																	checked={selectedPayment === el}
																	onChange={handlePaymentSelect}
																/>
																<span className="radio__item" />
																<span className="radio__text">
																	{convertMessage(el === 'cartubank' ? 'Card' : el)}
																</span>
															</label>
														</div>
													))}
												</div>
											</>
										) : (
											<span className="enter-value__label enter-value__label--padding">
												{String(L.translate('DepositFiat.depositing_is_not_supported'))}
											</span>
										)}
									</>
								)}
							</>
						</>
					)}
				</div>
			</div>
			{!!paymentsList.length && !!selectedPayment && selectedPayment !== 'blockchain_network' && (
				<>
					<div className="form-block form-block--padding">
						<div className="enter-value">
							<span className="block-label block-label--grey">
								2. {L.translate('DepositFiat.enter_amount')}
							</span>
							<span className="enter-value__label enter-value__label--padding">
								{L.translate('DepositFiat.amount')}
							</span>
							<div className="enter-value__field">
								<input
									onChange={changeAmountValue}
									value={amount}
									onFocus={handleInputFocus}
									onBlur={handleInputBlur}
									className={`input-item input-item--right-icon-and-action ${
										amountNeeded || depMaxOnChange ? 'input-item--error' : ''
									}`}
									type="text"
									placeholder={
										selectWallet === null || noLimit
											? ''
											: userData?.status_id === 2
											? `${L.translate('DepositFiat.enter_min')} ${
													numberExists(depMin) && String(depMin)
											  }`
											: `${L.translate('DepositFiat.enter')} ${
													numberExists(depMin) && String(depMin)
											  } - ${numberExists(depMax) && String(depMax)}`
									}
								/>
								<div className="input-icon input-icon--auto input-icon--right">
									<span className="enter-value__coin">{fiatCode?.toUpperCase()}</span>
								</div>
							</div>
							<div className="bottom-24">
								{amountNeeded && <TextError>{L.translate('DepositFiat.enter_amount')}</TextError>}
								{depMaxOnChange ? (
									<div className="input-notify">
										<span className="input-notify__text input-notify--error input-notify__text--medium">
											{L.translate('DepositFiat.max_amount')} {depMax}
										</span>
									</div>
								) : null}
							</div>
						</div>
					</div>
					<div className="form-block">
						<div className="enter-value enter-value--fiat-deposit">
							<span className="enter-value__label enter-value__label--padding">
								{L.translate('DepositFiat.you_receive')}:
							</span>
							<div className="enter-value__group">
								<span className="enter-value__sum">
									{convertExponentialToDecimal(recive) || '0'}{' '}
									{selectWallet?.asset?.code?.toUpperCase()}
								</span>
								{cardList && selectedPayment === 'cartubank' ? (
									<span className="enter-value__label enter-value__label--padding enter-value__label--flex">
										{L.translate('DepositFiat.payment_method')}:
										<img className="enter-value__icon" src={visaIcon} loading="lazy" />
										<span>
											{checkCard?.card_number
												? cardHideNumber(String(checkCard?.card_number), 12, 16)
												: ''}
										</span>
										<button
											type="button"
											onClick={() => hanleChangeCardClick('addCard')}
											className="enter-value__link"
										>
											{L.translate('DepositFiat.change_add_card')}
											<span className="enter-value__link-arrow">
												{!amount ? <ArrowRightGrey /> : <ArrowRight />}
											</span>
										</button>
									</span>
								) : (
									''
								)}
								{selectedPayment === 'bank_transfer' && selectedBank ? (
									<span className="enter-value__label enter-value__label--padding enter-value__label--flex">
										{L.translate('DepositFiat.payment_method')}:
										<img className="enter-value__icon" src={visaIcon} loading="lazy" />
										<span>
											{selectedBank.bank_name}
										</span>
										<button
											type="button"
											onClick={() => setActiveView('chooseBank')}
											className="enter-value__link"
										>
											{L.translate('DepositFiat.change_bank')}
											<span className="enter-value__link-arrow">
												{!amount ? <ArrowRightGrey /> : <ArrowRight />}
											</span>
										</button>
									</span>
								) : (
									''
								)}
							</div>
							<div className="enter-value__group">
								<span className="enter-value__label enter-value__label--padding">
									<span className="enter-value__text-container">
										<span>{L.translate('DepositFiat.transaction_fee')}:</span>
										<span>
											{!amount ? '0' : fee} {fiatCode?.toUpperCase()}
										</span>
									</span>
								</span>
							</div>
						</div>
					</div>
					<Notify />
					<div className="form-submit form-submit--more-mt">
						<button
							onClick={handleDeposit}
							style={{ width: '100%' }}
							className="button button--full-width"
							disabled={!!disabledBut}
						>
							{L.translate('Global.continue')}
						</button>
					</div>
				</>
			)}
			{selectedPayment === 'blockchain_network' && (
				<>
					<div className="form-block">
						<div className="enter-value">
							<span className="block-label block-label--grey">
								{String(L.translate('DepositCrypto.deposit_to'))}
							</span>
							<span className="enter-value__label enter-value__label--padding">
								{String(L.translate('DepositCrypto.network'))}
							</span>
							<div className="enter-value__field">
								<DCNDropdown
									value={selectWalletNetworks}
									onChange={handleNetworkSelect}
									options={walletNetworks}
								/>
							</div>
						</div>
					</div>
					{walletsAddress && (
						<>
							<div className="form-block">
								<span className="enter-value__label enter-value__label--padding">
									{String(L.translate('DepositCrypto.address'))}
								</span>
								<div className="enter-value__field enter-value__field--column">
									{copyAddressStatus?.copied ? (
										<>
											<div className="address-copy-wrapper">
												<p className="address-copy-text">
													{String(L.translate('DepositCrypto.address_copied'))}
												</p>
											</div>
										</>
									) : (
										<span className="enter-value__text">{walletsAddress?.address}</span>
									)}
									<CopyToClipboard text={walletsAddress?.address} onCopy={handleAddressCopy}>
										<button type="button" className="copy-btn">
											<span className="copy-btn__icon icon-fi_copy" />
											<span className="copy-btn__text">
												{String(L.translate('DepositCrypto.click_to_copy'))}
											</span>
										</button>
									</CopyToClipboard>
								</div>
							</div>
							<div className="qr-code">
								<div className="qr-code__img qr-code__img--big">
									<img
										src={walletsAddress?.address_qr}
										alt=""
										width="182"
										height="182"
										loading="lazy"
									/>
								</div>
								<div className="qr-code__main qr-code__main--gap">
									{walletsAddress?.tag && (
										<>
											<div className="qr-code__info">
												<span className="qr-code__info-title">
													{String(L.translate('DepositCrypto.memo'))}
												</span>
												<span className="qr-code__info-desc qr-code__info-desc--big">
													{copyMemoStatus?.copied ? (
														<>
															<div className="address-copy-wrapper">
																<p className="address-copy-text">
																	{String(L.translate('DepositCrypto.memo_copied'))}
																</p>
															</div>
														</>
													) : (
														<>{walletsAddress?.tag}</>
													)}
													<CopyToClipboard text={walletsAddress?.tag} onCopy={handleMemoCopy}>
														<button type="button" className="copy-btn copy-btn--small">
															<span className="copy-btn__icon icon-fi_copy" />
														</button>
													</CopyToClipboard>
												</span>
											</div>
											<div className="input-notify input-notify--second-type">
												<span className="input-notify__char icon-info-circle" />
												<span className="input-notify__text">
													{String(L.translate('DepositCrypto.memo_is_required'))}
												</span>
											</div>
										</>
									)}
									<div className="qr-code__info">
										<span className="qr-code__info-title">
											{String(L.translate('DepositCrypto.minimum_deposit'))}
										</span>
										<span className="qr-code__info-desc">0.00000001</span>
									</div>
								</div>
							</div>
						</>
					)}
					<Notify />
				</>
			)}
		</div>
	);
};

export default SelecetCurrencyPayment;
