/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getConvertHistoryIsLoad } from 'redux/reducers/convert/selectors';
import Loader from 'ui/Loader';
import ConvertTableItem from '../ConvertTableItem';
import { IConvertTableProps } from './types';

// ==========================================:
const ConvertTable: FC<IConvertTableProps> = ({ data }) => {
	const convertHistoryListLoader = useSelector(getConvertHistoryIsLoad);

	return (
		<>
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Transaction.period'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.fee')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.from')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.to')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.amount_from')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.amount_to')}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{convertHistoryListLoader && (
					<div className="transaction-history-loader-wrapper">
						<Loader />
					</div>
				)}

				{data?.length && !convertHistoryListLoader
					? data?.map((item, idx) => <ConvertTableItem key={idx} data={item} />)
					: null}

				{!data?.length && !convertHistoryListLoader && (
					<div className="transaction-history-loader-wrapper">
						{L.translate('Transaction.no_convert_transactions')}
					</div>
				)}
			</div>
		</>
	);
};

export default ConvertTable;
