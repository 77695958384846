/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import L from 'i18n-react';
import TableRow from './TableRow';
import { IPaymentMethodsTable } from './types';

const PaymentMethodsTable: FC<IPaymentMethodsTable> = ({ items }) => {
	return (
		<div className="table table--prices-type table--prices-payment-methods">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.currency')}</p>
					</div>
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.channel_name')}</p>
					</div>
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.rate_from')}</p>
					</div>
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.rate_to')}</p>
					</div>
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.deposit')}</p>
					</div>
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.fixed_amount')}</p>
					</div>
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.withdrawal')}</p>
					</div>
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.fixed_amount')}</p>
					</div>
				</div>
			</div>
			<div className="table-body">
				{items.map((item, idx) => (
					<TableRow key={idx} item={item} />
				))}
			</div>
		</div>
	);
};

export default PaymentMethodsTable;
