import { FC, useEffect, useState, ChangeEvent } from 'react';
import L from 'i18n-react';
import { textSearchValid } from 'services/utils/textSearch';
import { ISearchInputPairProps } from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

const SearchInputPairs: FC<ISearchInputPairProps> = (props) => {
	const { listCoins, onSelect, pair } = props;
	const [dropdown, setDropdown] = useState(false);
	const [search, setSearch] = useState('');
	const changeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length < 15) {
			setSearch(e.target.value);
		}
	};

	useEffect(() => {
		if (!dropdown && pair) {
			const nameCode = pair?.code?.replace('_', '/')?.toUpperCase();
			setSearch(nameCode);
		}
	}, [pair, dropdown]);
	const handleFocus = () => {
		setDropdown(true);
		setSearch('');
	};

	const handleBlur = () => {
		// without setTimeout the dropdown will close before we select asset
		setTimeout(() => {
			setDropdown(false);
		}, 300);
	};
	const filterData = (data: any, searchCoin: string) => {
		return data?.filter((pairCode: any) =>
			textSearchValid(pairCode?.code, 'pairCode', searchCoin, '', ''),
		);
	};
	const data = filterData(listCoins, search);
	return (
		<>
			<div className="transfer-form__select select-block transfer-form__select--box">
				<p className="select-block__name">{String(L.translate('Margin.trading_pair'))}</p>
				<div className={`select select--regular details-list__select ${dropdown ? 'active' : ''}`}>
					<div className="select__current">
						<input
							className="coin-val select__current"
							placeholder={String(L.translate('Margin.select_asset'))}
							value={search}
							onChange={changeSearch}
							onFocus={handleFocus}
							onBlur={handleBlur}
						/>
						<span className="select__arrow icon-arrow2" />
					</div>

					{dropdown && (
						<div className="select__drop">
							<div className="select__drop-scroll">
								{data?.length ? (
									data?.map((pairItem: any) => (
										<div key={pairItem.id} className="select__drop-item">
											<button
												type="button"
												className="select__drop-link"
												data-code={pairItem?.code}
												onClick={onSelect}
											>
												<span className="coin-val__code">
													{pairItem?.code.replace('_', '/')?.toUpperCase()}
												</span>
											</button>
										</div>
									))
								) : (
									<span className="coin-val__name">{L.translate('Global.nothing')}</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default SearchInputPairs;
