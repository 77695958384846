import { ChangeEvent, FC, useMemo, useState, useEffect } from 'react';
import L from 'i18n-react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { Link } from 'react-router-dom';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getHideBalanceSetting } from 'redux/reducers/tradingSettings/selectors';
import { setHideBalanceSetting } from 'redux/reducers/tradingSettings/reducer';
import TransferModal from 'components/TransferModal';
import { isStablecoin } from 'services/utils/wallets';
import { dataLayer } from 'services/gtm';
import SpotAndFiatBalances from './SpotAndFiatBalances';
import SpotAndFiatSortingTable from './SpotAndFiatSortingTable';
import Withdrawal2FAPopup from './Withdrawal2FAPopup';

// ==========================================:
const SpotAndFiat: FC = () => {
	const walletsList = useSelector(getWalletsList);
	const userData = useSelector(getUserSettingsData);
	const hideBalance = useSelector(getHideBalanceSetting);

	const dispatch = useDispatch();

	const history = useHistory();

	const [searchCoins, setSearchCoins] = useState('');
	const [smallBalancesIsHide, setSmallBalancesIsHide] = useState(false);
	const [isShowTransferModal, setIsShowTransferModal] = useState(false);
	const [isWithdrawal2FaShow, setIsWithdrawal2FaShow] = useState(false);

	const handleSetBalancesIsHide = () => {
		dispatch(setHideBalanceSetting(!hideBalance));
	};

	const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setSearchCoins(value);
	};

	const handleChangeSmallBalancesIsHide = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;

		setSmallBalancesIsHide(checked);
	};

	const stableWalletsList = useMemo(() => {
		if (!walletsList?.length) {
			return [];
		}

		const stableWallets = walletsList.filter(isStablecoin);

		if (smallBalancesIsHide && searchCoins) {
			return stableWallets.filter(
				(wallet) =>
					Number(wallet.btc_value) > 0.0003 &&
					wallet.asset.code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		if (smallBalancesIsHide) {
			return stableWallets.filter((wallet) => Number(wallet.usd_value) >= 1);
		}

		if (searchCoins) {
			return stableWallets.filter((wallet) =>
				wallet.asset.code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		return stableWallets;
	}, [walletsList, searchCoins, smallBalancesIsHide]);

	const fiatWalletsList = useMemo(() => {
		if (!walletsList?.length) {
			return [];
		}

		const fiatWallets = walletsList.filter((wallet) => wallet.asset.type === 'fiat');

		if (smallBalancesIsHide && searchCoins) {
			return fiatWallets.filter(
				(wallet) =>
					Number(wallet.btc_value) > 0.0003 &&
					wallet.asset.code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		if (smallBalancesIsHide) {
			return fiatWallets.filter((wallet) => Number(wallet.btc_value) > 0.0003);
		}

		if (searchCoins) {
			return fiatWallets.filter((wallet) =>
				wallet.asset.code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		return fiatWallets;
	}, [walletsList, searchCoins, smallBalancesIsHide]);

	const cryptoWalletsList = useMemo(() => {
		if (!walletsList?.length) {
			return [];
		}

		const cryptoWallets = walletsList.filter(
			(wallet) => wallet.asset.type !== 'fiat' && !isStablecoin(wallet),
		);

		if (smallBalancesIsHide && searchCoins) {
			return cryptoWallets.filter(
				(wallet) =>
					Number(wallet.btc_value) > 0.0003 &&
					wallet.asset.code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		if (smallBalancesIsHide) {
			return cryptoWallets.filter((wallet) => Number(wallet.usd_value) >= 1);
		}

		if (searchCoins) {
			return cryptoWallets.filter((wallet) =>
				wallet.asset.code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		return cryptoWallets;
	}, [walletsList, searchCoins, smallBalancesIsHide]);

	const handleWithdrawal = () => {
		if (Number(userData?.status?.id) !== 2) {
			notificationContainer(String(L.translate('SpotAndFiat.verify_acc_notification')), 'info');
			return;
		}
		if (
			userData?.google2fa_enabled === 0 &&
			userData?.phone_2fa_enabled === 0 &&
			userData?.email_2fa_enabled === 0
		) {
			setIsWithdrawal2FaShow(true);
			return;
		}

		dataLayer.push({
			event: 'start_withdraw',
		});

		history.push({
			pathname: '/withdraw',
		});
	};

	const handleDeposit = () => {
		if (Number(userData?.status?.id) !== 2) {
			notificationContainer(
				String(L.translate('SpotAndFiat.deposit_verify_acc_notification')),
				'info',
			);
			return;
		}

		dataLayer.push({
			event: 'start_deposit',
		});

		history.push({
			pathname: '/deposit',
		});
	};

	useEffect(() => {
		if (isShowTransferModal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
		return () => {
			document.body.style.overflow = '';
		};
	}, [isShowTransferModal]);

	return (
		<>
			<div className="main-content__header">
				<h2 className="section-title">{String(L.translate('SpotAndFiat.fiat_and_spot'))}</h2>
				<button type="button" className="hide-btn" onClick={handleSetBalancesIsHide}>
					<span className={`hide-btn__icon text-type ${hideBalance ? 'icon-eye' : 'icon-eye2'}`} />
					<span className="hide-btn__text">
						{hideBalance
							? String(L.translate('P2P.Balances.show_balance'))
							: String(L.translate('P2P.Balances.hide_balance'))}
					</span>
				</button>
				<div className="section-nav">
					<button type="button" onClick={handleDeposit} className="button button--nav">
						{String(L.translate('SpotAndFiat.deposit'))}
					</button>
					<button
						type="button"
						onClick={handleWithdrawal}
						className="button button--third-grey button--nav"
					>
						{String(L.translate('SpotAndFiat.withdraw'))}
					</button>
					<button
						type="button"
						onClick={() => setIsShowTransferModal(true)}
						className="button button--third-grey button--nav"
					>
						{String(L.translate('SpotAndFiat.transfer'))}
					</button>
				</div>
			</div>
			<SpotAndFiatBalances balancesIsHide={hideBalance} />
			<div className="content-block">
				<div className="balance-section">
					<div className="balance-section__header">
						<div className="search search--big">
							<div className="input input--margin-none">
								<div className="input-wrapper">
									<input
										className="input-item input-item--sort input-item--right-icon"
										type="text"
										placeholder={String(L.translate('P2P.search_coin'))}
										value={searchCoins}
										onChange={handleChangeSearch}
									/>
									<button type="button" className="search-btn">
										<span className="search-btn__icon icon-search-normal" />
									</button>
								</div>
							</div>
						</div>
						<div className="checkbox checkbox--margin-none">
							<label className="checkbox__label">
								<input
									type="checkbox"
									className="hidden"
									checked={smallBalancesIsHide}
									onChange={handleChangeSmallBalancesIsHide}
								/>
								<span className=" checkbox__item">
									<span className="checkbox__item-icon">
										<span className="icon-Checkbox" />
									</span>
								</span>
								<span className="checkbox__text checkbox__text--grey">
									{String(L.translate('SpotAndFiat.hide_small_balances'))}
								</span>
							</label>
						</div>
					</div>
					<div className="balance-block">
						{/* <span className="balance-block__name">
							{String(L.translate('SpotAndFiat.fiat_balance'))}
						</span>
						<SpotAndFiatSortingTable
							data={fiatWalletsList}
							balancesIsHide={hideBalance}
							currencyType="fiat"
						/> */}

						<span className="balance-block__name">
							{String(L.translate('SpotAndFiat.stablecoin_balance'))}
						</span>
						<SpotAndFiatSortingTable
							data={stableWalletsList}
							balancesIsHide={hideBalance}
							currencyType="stable"
						/>

						<div className="balance-block">
							<span className="balance-block__name">
								{String(L.translate('SpotAndFiat.crypto_balance'))}
							</span>

							<SpotAndFiatSortingTable
								data={cryptoWalletsList}
								balancesIsHide={hideBalance}
								currencyType="crypto"
							/>
						</div>
					</div>
				</div>
			</div>
			<TransferModal isOpen={isShowTransferModal} onClose={() => setIsShowTransferModal(false)} />
			<Withdrawal2FAPopup
				isOpen={isWithdrawal2FaShow}
				onClose={() => setIsWithdrawal2FaShow(false)}
			/>
		</>
	);
};

export default SpotAndFiat;
