/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getWalletsCryptoHistoryIsLoad } from 'redux/reducers/wallets/selectors';
import Loader from 'ui/Loader';
import DepositTableItem from '../DepositTableItem';
import { IDepositTableProps } from './types';

// ==========================================:
const DepositTable: FC<IDepositTableProps> = ({ data }) => {
	const walletsDepositHistoryListLoader = useSelector(getWalletsCryptoHistoryIsLoad);

	return (
		<>
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Deposit.time'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Deposit.type'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Deposit.currency'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Deposit.payment_method'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Deposit.indicated_amount'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Deposit.fee'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Deposit.amount'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Deposit.status'))}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{walletsDepositHistoryListLoader && (
					<div className="transaction-history-loader-wrapper">
						<Loader />
					</div>
				)}

				{data?.length && !walletsDepositHistoryListLoader
					? data?.map((item, idx) => <DepositTableItem key={idx} data={item} />)
					: null}

				{!data?.length && !walletsDepositHistoryListLoader && (
					<div className="transaction-history-loader-wrapper">
						{String(L.translate('Deposit.no_deposit_transactions'))}
					</div>
				)}
			</div>
		</>
	);
};

export default DepositTable;
