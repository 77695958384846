import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { setColorTheme } from 'redux/reducers/app/reducer';
import { getColorTheme } from 'redux/reducers/app/selectors';

const ThemeSwitch: FC = () => {
	const dispatch = useDispatch();
	const theme = useSelector(getColorTheme);

	useEffect(() => {
		if (localStorage.getItem('theme') !== theme) {
			localStorage.setItem('theme', theme);
		}
	}, [theme]);

	const handleChange = () => {
		// window.Intercom('update', {
		// 	background_color: theme === 'dark' ? '#019eb1' : '#0000FF',
		// });

		dispatch(setColorTheme(theme === 'dark' ? 'light' : 'dark'));
	};

	return (
		<div className="theme-switch">
			<button type="button" className="d-flex theme-switch__btn" onClick={handleChange}>
				<span className="theme-switch__icon theme-switch__icon--for-light">
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1.52232 9.31473C1.79232 13.1772 5.06982 16.3197 8.99232 16.4922C11.7598 16.6122 14.2348 15.3222 15.7198 13.2897C16.3348 12.4572 16.0048 11.9022 14.9773 12.0897C14.4748 12.1797 13.9573 12.2172 13.4173 12.1947C9.74982 12.0447 6.74982 8.97723 6.73482 5.35473C6.72732 4.37973 6.92982 3.45723 7.29732 2.61723C7.70232 1.68723 7.21482 1.24473 6.27732 1.64223C3.30732 2.89473 1.27482 5.88723 1.52232 9.31473Z"
							stroke="#011E26"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				<span className="theme-switch__icon theme-switch__icon--for-dark">
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.99967 15.4173C12.9912 15.4173 15.4163 12.9922 15.4163 10.0007C15.4163 7.00911 12.9912 4.58398 9.99967 4.58398C7.00813 4.58398 4.58301 7.00911 4.58301 10.0007C4.58301 12.9922 7.00813 15.4173 9.99967 15.4173Z"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M15.9503 15.9493L15.842 15.841M15.842 4.15768L15.9503 4.04935L15.842 4.15768ZM4.05033 15.9493L4.15866 15.841L4.05033 15.9493ZM10.0003 1.73268V1.66602V1.73268ZM10.0003 18.3327V18.266V18.3327ZM1.73366 9.99935H1.66699H1.73366ZM18.3337 9.99935H18.267H18.3337ZM4.15866 4.15768L4.05033 4.04935L4.15866 4.15768Z"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
			</button>
		</div>
	);
};

export default ThemeSwitch;
