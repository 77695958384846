import { FC, useState, MouseEvent, useEffect } from 'react';
import Popup from 'reactjs-popup';
import L from 'i18n-react';
import { IIsolatedModalProps } from './types';
import IsolatedBorrow from './IsolatedBorrow';
import IsolatedRepay from './IsolatedRepay';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

const IsolatedModal: FC<IIsolatedModalProps> = ({
	active,
	assetCode,
	assetPair,
	openModal,
	closeModal,
}) => {
	const [modal, setModal] = useState('');
	useEffect(() => {
		setModal(active);
	}, [active]);
	const handleTabChange = (e: MouseEvent<HTMLButtonElement>) => {
		const { value } = e.currentTarget;
		setModal(value);
	};
	const content: any = {
		borrow: <IsolatedBorrow assetCode={assetCode} assetPair={assetPair} closeModal={closeModal} />,
		repay: <IsolatedRepay assetCode={assetCode} assetPair={assetPair} closeModal={closeModal} />,
	};

	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small">
						<button type="button" onClick={closeModal} className="popup-close">
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<div className="modal-tab">
								<button
									type="button"
									value="borrow"
									className={modal === 'borrow' ? 'modal-tab__item active' : 'modal-tab__item'}
									onClick={handleTabChange}
								>
									{String(L.translate('Trade.Margin.MarginPopup.button_borrow'))}
								</button>
								<button
									type="button"
									value="repay"
									className={modal === 'repay' ? 'modal-tab__item active' : 'modal-tab__item'}
									onClick={handleTabChange}
								>
									{L.translate('Trade.Margin.MarginPopup.button_repay')}
								</button>
							</div>
						</div>
						{content[modal]}
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default IsolatedModal;
