import { FC } from 'react';
import L from 'i18n-react';
import Dashboard from 'layouts/Dashboard';
import MarginCall from 'components/MarginWalletHistory/MarginCall';

// ==========================================:
const CallHistory: FC = () => {
	return (
		<Dashboard title={String(L.translate('PageTitles.margin_call'))}>
			<MarginCall />
		</Dashboard>
	);
};

export default CallHistory;
