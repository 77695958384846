/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC } from 'react';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { convertMessage } from 'services/utils/trimComment';
// import { notificationContainer } from 'services/utils/notificationContainer';
import { IDepositTableItemProps } from './types';

const CryptoTableItem: FC<IDepositTableItemProps> = ({ data }) => {
	const statusClasses = {
		processed: 'status-completed',
		confirmed: 'status-completed',
		rejected: 'status-failed',
		unconfirmed: 'status-pending',
		pending: 'status-pending',
		'in progress': 'status-pending',
	};
	const statusFunc = () => {
		if (data?.status === 'processed' || data?.status === 'confirmed') {
			return 'success';
		}
		if (data?.status === 'unconfirmed') {
			return 'pending';
		}
		return data?.status;
	};

	return (
		<div className="tr" key={data?.id}>
			<div className="td">
				<p className="td-hidden-name">Period</p>
				<p>
					{getLocaleDateFromTimestamp(data?.created_at)}{' '}
					{getLocaleTimeFromTimestamp(data?.created_at)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{convertMessage(data?.type)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>
					{data?.asset.code?.toUpperCase()}
					{data?.type === 'deposit' ? '' : 'b'}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Payment Method</p>
				<p>{convertMessage(data?.payment_system)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Indicated Amount</p>
				<p>{Number(data?.indicated_amount)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<div className="permissions">
					<p>{Number(data?.amount_fee)}</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<div className="permissions">
					<p>
						{Number(data?.total_amount)}{' '}
						{data?.type === 'deposit'
							? `${data?.asset.code?.toUpperCase()}b`
							: data?.asset.code?.toUpperCase()}
					</p>
				</div>
			</div>
			<div className="td td-status">
				<p className="td-hidden-name">Status</p>
				<p className={statusClasses[data?.status as keyof typeof statusClasses]}>{statusFunc()}</p>
			</div>
		</div>
	);
};

export default CryptoTableItem;
