import { FC, useState, ChangeEvent } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { ITotpPopup } from './types';

const TotpPopup: FC<ITotpPopup> = ({ isOpen, onClose, onSubmit }) => {
	const [totpCode, setTotpCode] = useState('');

	const handleChangeTotp = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (!/^\d*$/.test(value)) {
			return;
		}
		setTotpCode(e.target.value);
	};

	return (
		<Popup open={isOpen} closeOnDocumentClick onClose={onClose} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small">
						<button className="popup-close" type="button" onClick={onClose}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title">{L.translate('P2P.paid_confirmation')}</p>
						</div>
						<div className="popup-body popup-body--type3">
							<div className="withdrawal-code">
								<div className="form-group withdrawal-code__group">
									<div className="withdrawal-code__field">
										<div className="input input--margin-none">
											<div className="input-wrapper">
												<input
													className="input-item"
													type="text"
													placeholder={String(L.translate('WithdrawStable.Placeholders.2fa_code'))}
													value={totpCode}
													onChange={handleChangeTotp}
													maxLength={6}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="input-notify">
									<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
										{String(L.translate('WithdrawFiat.enter_google_code'))}
									</span>
								</div>
							</div>
						</div>
						<div className="popup-submit ">
							<button
								type="submit"
								className="button button--full-width "
								onClick={() => onSubmit(totpCode)}
								disabled={totpCode.length < 6}
							>
								{L.translate('Global.confirm')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default TotpPopup;
