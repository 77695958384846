import { FC } from 'react';
import L from 'i18n-react';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import { ISuccessfullyPosted } from './types';

const SuccessfullyPosted: FC<ISuccessfullyPosted> = ({ data, onConfirm }) => {
	const {
		id,
		amount,
		amount_min,
		amount_max,
		asset: { code: assetCode },
		currency: { code: currencyCode },
		type,
	} = data;
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--medium">
					<button type="button" className="popup-close" onClick={onConfirm}>
						<span className="popup-close__icon icon-close" />
					</button>
					<div className="popup-header">
						<div className="popup-icon">
							<svg
								width="25"
								height="19"
								viewBox="0 0 25 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M22.5 2.5L8.75 16.25L2.5 10"
									stroke="white"
									strokeWidth="4"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<p className="popup-header__title">
							{String(L.translate('Advertisement.SuccessfullyPosted.title'))}
						</p>
					</div>
					<div className="popup-body popup-body--type2">
						<div className="popup-text popup-text--center popup-text--width-410">
							<p>{String(L.translate('Advertisement.SuccessfullyPosted.descr'))}</p>
						</div>
						<div className="withdrawal-options  withdrawal-options--type2  withdrawal-options--mt-24">
							<div className="withdrawal-option ">
								<span className="withdrawal-option__title">
									{String(L.translate('Advertisement.SuccessfullyPosted.ad_number'))}
								</span>
								<span className="withdrawal-option__desc">{id}</span>
							</div>
						</div>
						<div className="withdrawal-options withdrawal-options--mt-24">
							<div className="p2p-post-details">
								<div className="p2p-post-details__group">
									<span
										className={`p2p-post-details__type p2p-post-details__type--${
											type === 'buy' ? 'green' : 'red'
										}`}
									>
										{transformFirstLetterToCapitalize(type)}
									</span>
									<span>{assetCode.toUpperCase()}</span>
									<span className="p2p-post-details__extra">
										{String(L.translate('Advertisement.SuccessfullyPosted.with'))}
									</span>
									<span>{currencyCode.toUpperCase()}</span>
								</div>
							</div>
							<div className="spent-value  spent-value--mt-0">
								<p className="spent-value__item spent-value__item--fw-600">
									{amount} {assetCode.toUpperCase()}
								</p>
							</div>
							<div className="withdrawal-option ">
								<span className="withdrawal-option__title">
									{String(L.translate('Advertisement.SuccessfullyPosted.amount'))}
								</span>
								<span className="withdrawal-option__desc">
									{amount} {assetCode.toUpperCase()}
								</span>
							</div>
							<div className="withdrawal-option ">
								<span className="withdrawal-option__title">
									{String(L.translate('Advertisement.SuccessfullyPosted.limit'))}
								</span>
								<span className="withdrawal-option__desc">
									{amount_min} - {amount_max} {assetCode.toUpperCase()}
								</span>
							</div>
						</div>
					</div>
					<div className="popup-submit popup-submit--sb">
						<button type="button" className="button button--full-width" onClick={onConfirm}>
							{String(L.translate('Global.done'))}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SuccessfullyPosted;
