import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import L from 'i18n-react';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import { getAdvertisementRequest } from 'redux/reducers/p2p/reducer';
import { getP2PBalancesLoad, getTradePaySell, getUserAdvert } from 'redux/reducers/p2p/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import ChatTrade from './ChatTrade';
import NavTrade from './NavTrade';
import SectionTrade from './SectionTrade';
/* eslint-disable no-debugger */

const Trade: FC = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const p2pIsLoad = useSelector(getP2PBalancesLoad);
	const { search } = location;
	const advertId = queryString.parse(search).id || null;
	const userAdvert = useSelector(getUserAdvert);
	const trade = useSelector(getTradePaySell);
	const user = useSelector(getUserSettingsData);
	const closePage = String(location.pathname) === '/trade-pay-close';
	const userId = user && user.id;
	const tradId = trade && trade.trader && trade.trader.id;
	const userTrader = tradId === userId ? 'trader' : 'not_trader';

	const coin =
		(userAdvert && userAdvert.asset && userAdvert.asset.code) || (trade && trade.asset_code);

	const currentType = (value: string): string => {
		if (value === 'sell') {
			return 'buy';
		}
		if (value === 'buy') {
			return 'sell';
		}
		if (value === 'some') {
			if (trade && user && user.id && trade.seller_id && user.id === trade.seller_id) {
				return 'sell';
			}
			return 'buy';
		}
		return 'buy';
	};

	const type2 = userAdvert && userAdvert.type ? currentType(userAdvert.type) : currentType('some');

	const hidenChat =
		!(location.pathname === '/p2p-trading/details' || location.pathname === '/p2p-open-trades') &&
		trade &&
		trade.id;
	const tradeId = trade && trade.id ? trade.id : false;

	// const userName =
	// 	userTrader === 'trader' ? trade?.advertisement?.user?.username : trade?.trader?.username;

	const userName = userAdvert?.user.username;

	useEffect(() => {
		if (!advertId) return;
		dispatch(getAdvertisementRequest({ id: Number(advertId), history }));
	}, [dispatch, advertId, history]);

	return (
		<SocketWithPrivateTopicConnectWrapper topicName="trade">
			<div
				className={`content-inner content-inner--padding ${
					location.pathname === '/trade-pay-close' ? 'main-close' : ''
				}`}
			>
				<div className="content-block content-block--flex content-block--padding-none content-block--border content-block--min-height">
					{p2pIsLoad ? (
						<div className="spot-and-fiat-wallets-table-loader-wrapper">
							<Loader />
						</div>
					) : null}
					{!p2pIsLoad ? (
						<div className={`create-offer ${closePage ? 'create-offer--leave-review' : ''}`}>
							{closePage ? null : (
								<div className="create-offer__header">
									<p className="back-step">
										<span className="back-step__text">
											{L.translate(`P2P.omg.tradeDetails.title_${type2}`, {
												coin: coin?.toLocaleUpperCase(),
												user_name: type2 === 'buy' ? trade?.seller_username : trade?.buyer_username,
											})}
										</span>
									</p>
									<NavTrade coin={coin || ''} />
								</div>
							)}

							<div
								className={`create-offer__main ${
									location.pathname === '/trade-pay-close' ? 'main-close' : ''
								}`}
							>
								<div className={`create-offer__row ${hidenChat ? 'create-offer__row--gap' : ''}`}>
									<SectionTrade />
									{hidenChat && !closePage ? (
										<SocketWithPrivateTopicConnectWrapper topicName="chat">
											<ChatTrade id={tradeId} />
										</SocketWithPrivateTopicConnectWrapper>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</SocketWithPrivateTopicConnectWrapper>
	);
};

export default Trade;
