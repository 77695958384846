import { FC } from 'react';
import L from 'i18n-react';
import { useDispatch } from 'react-redux';
import { setSocialLogin } from 'redux/reducers/auth/reducer';
import { INVITE_KEY } from 'services/constants/storageKeys';
import { ISocialLogin } from './types';

const SocialLogin: FC<ISocialLogin> = ({ fromRegister }) => {
	const dispatch = useDispatch();

	return (
		<div className="social-authorization">
			<div className="social-authorization__info">
				<span className="social-authorization__line" />
				<span className="social-authorization__text">
					{String(L.translate('LoginPage.continue_with'))}
				</span>
			</div>
			<div className="social-authorization__buttons">
				{/* <a
					href={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/aredirect`}
					className="social-authorization__btn"
					onClick={() => {
						dispatch(setSocialLogin('apple'));
					}}
					type="button"
				>
					<span>
						<svg
							width="21"
							height="24"
							viewBox="0 0 21 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.5544 12.6832C17.5429 10.7159 18.427 9.2333 20.2125 8.14007C19.2139 6.69782 17.7032 5.90458 15.7117 5.7517C13.8262 5.60171 11.7632 6.85935 11.0079 6.85935C10.2096 6.85935 8.38413 5.80362 6.94779 5.80362C3.98356 5.84977 0.833344 8.18622 0.833344 12.9399C0.833344 14.3446 1.08799 15.7955 1.59729 17.2897C2.27826 19.2569 4.7332 24.0769 7.294 23.9991C8.63306 23.9673 9.58012 23.0414 11.3226 23.0414C13.0136 23.0414 13.8891 23.9991 15.3827 23.9991C17.9664 23.9616 20.1867 19.58 20.8333 17.607C17.3684 15.96 17.5544 12.7841 17.5544 12.6832V12.6832ZM14.5472 3.88543C15.9979 2.14895 15.8662 0.568247 15.8233 0C14.5415 0.0749971 13.0594 0.879774 12.2153 1.86916C11.2854 2.93066 10.7389 4.2431 10.8562 5.72285C12.2411 5.82958 13.5057 5.11134 14.5472 3.88543V3.88543Z"
								fill="#019EB1"
							/>
						</svg>
					</span>
				</a> */}
				<a
					href={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/fredirect`}
					className="social-authorization__btn"
					type="button"
					aria-label="facebook authorization button"
					onClick={() => {
						if (fromRegister) {
							localStorage.removeItem(INVITE_KEY);
						}
						dispatch(setSocialLogin('facebook'));
					}}
				>
					<span>
						<svg
							width="25"
							height="24"
							viewBox="0 0 25 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#clip0_4_7048)">
								<path
									d="M24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 17.9895 4.8882 22.954 10.625 23.8542V15.4688H7.57812V12H10.625V9.35625C10.625 6.34875 12.4166 4.6875 15.1576 4.6875C16.4701 4.6875 17.8438 4.92188 17.8438 4.92188V7.875H16.3306C14.84 7.875 14.375 8.80008 14.375 9.75V12H17.7031L17.1711 15.4688H14.375V23.8542C20.1118 22.954 24.5 17.9895 24.5 12Z"
									fill="#019EB1"
								/>
								<path
									d="M17.1711 15.4688L17.7031 12H14.375V9.75C14.375 8.80102 14.84 7.875 16.3306 7.875H17.8438V4.92188C17.8438 4.92188 16.4705 4.6875 15.1576 4.6875C12.4166 4.6875 10.625 6.34875 10.625 9.35625V12H7.57812V15.4688H10.625V23.8542C11.8674 24.0486 13.1326 24.0486 14.375 23.8542V15.4688H17.1711Z"
									fill="#DDEDEF"
								/>
							</g>
							<defs>
								<clipPath id="clip0_4_7048">
									<rect width="24" height="24" fill="white" transform="translate(0.5)" />
								</clipPath>
							</defs>
						</svg>
					</span>
				</a>
				<a
					href={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/gredirect`}
					className="social-authorization__btn"
					aria-label="google authorization button"
					type="button"
					onClick={() => {
						if (fromRegister) {
							localStorage.removeItem(INVITE_KEY);
						}
						dispatch(setSocialLogin('google'));
					}}
				>
					<span>
						<svg
							width="25"
							height="24"
							viewBox="0 0 25 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#clip0_4_7051)">
								<path
									d="M23.9327 12.2765C23.9327 11.4608 23.8666 10.6406 23.7255 9.83813H12.4067V14.4591H18.8884C18.6194 15.9495 17.7552 17.2679 16.4897 18.1056V21.104H20.3567C22.6275 19.014 23.9327 15.9274 23.9327 12.2765Z"
									fill="#019EB1"
								/>
								<path
									d="M12.4067 24.0008C15.6431 24.0008 18.3725 22.9382 20.3611 21.1039L16.4941 18.1055C15.4182 18.8375 14.0293 19.252 12.4111 19.252C9.28045 19.252 6.62603 17.1399 5.67361 14.3003H1.68317V17.3912C3.72028 21.4434 7.86946 24.0008 12.4067 24.0008Z"
									fill="#019EB1"
								/>
								<path
									d="M5.66922 14.3002C5.16655 12.8099 5.16655 11.196 5.66922 9.70569V6.61475H1.68318C-0.0188234 10.0055 -0.0188234 14.0004 1.68318 17.3912L5.66922 14.3002Z"
									fill="#019EB1"
								/>
								<path
									d="M12.4067 4.74966C14.1175 4.7232 15.771 5.36697 17.01 6.54867L20.4361 3.12262C18.2667 1.0855 15.3874 -0.034466 12.4067 0.000808666C7.86946 0.000808666 3.72028 2.55822 1.68317 6.61481L5.6692 9.70575C6.61721 6.86173 9.27604 4.74966 12.4067 4.74966Z"
									fill="#019EB1"
								/>
							</g>
							<defs>
								<clipPath id="clip0_4_7051">
									<rect width="24" height="24" fill="white" transform="translate(0.166687)" />
								</clipPath>
							</defs>
						</svg>
					</span>
				</a>
			</div>
		</div>
	);
};

export default SocialLogin;
