/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import L from 'i18n-react';
import { IConfirmDeletePopupProps } from './types';

const ConfirmModal: FC<IConfirmDeletePopupProps> = ({
	title = '',
	bodyMessage = '',
	closeModal,
	handleSubmit,
}) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--medium">
					<button className="popup-close" type="button" onClick={closeModal}>
						<span className="popup-close__icon icon-close" />
					</button>
					<div className="popup-header">
						<p className="popup-header__title">{title}</p>
					</div>
					<div className="popup-body popup-body--margin-none">
						<div className="popup-text popup-text--center">
							<p>{bodyMessage}</p>
						</div>
					</div>
					<div className="popup-submit popup-submit--sb">
						<button className="button button--full-width" type="button" onClick={handleSubmit}>
							{String(L.translate('Global.confirm'))}
						</button>
						<button
							className="button button--second-grey button--full-width"
							type="button"
							onClick={closeModal}
						>
							{String(L.translate('Base.Modals.ConfirmDeletePopup.no_button'))}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConfirmModal;
