import { FC } from 'react';
import L from 'i18n-react';
import { displayChannelName } from '../../utils';
import BankTransferTooltip from '../../../shared/BankTransferTooltip';
import { ICryptoTableRow } from './types';

const TableRow: FC<ICryptoTableRow> = ({ item, activeBtnSt }) => {
	const {
		asset_code,
		channel_name,
		deposit_max,
		deposit_min,
		withdraw_min,
		withdraw_max,
		vip_withdraw_min,
		vip_withdraw_max,
		vip_deposit_min,
		vip_deposit_max,
		deposit_no_limit,
		vip_deposit_no_limit,
		vip_withdraw_no_limit,
		withdraw_no_limit,
	} = item;
	const minWith = activeBtnSt === 'General' ? withdraw_min : vip_withdraw_min;
	const maxWith = activeBtnSt === 'General' ? withdraw_max : vip_withdraw_max;
	const minDept = activeBtnSt === 'General' ? deposit_min : vip_deposit_min;
	const maxDept = activeBtnSt === 'General' ? deposit_max : vip_deposit_max;
	const withNolimit = activeBtnSt === 'General' ? withdraw_no_limit : vip_withdraw_no_limit;
	const deptNolimit = activeBtnSt === 'General' ? deposit_no_limit : vip_deposit_no_limit;

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.currency')}</p>
				<p className="td-name">{asset_code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.channel_name')}</p>
				{['bank_transfer', 'bank_transfer_other'].includes(channel_name) ? (
					<div className="td-flex">
						<p className="td-name">{displayChannelName(channel_name)}</p>
						<BankTransferTooltip />
					</div>
				) : (
					<p className="td-name">{displayChannelName(channel_name)}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.min_withdrawal')}</p>
				<p className="td-name">{withNolimit ? 'no limit' : minWith}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.max_withdrawal')}</p>
				<p className="td-name">{withNolimit ? 'no limit' : maxWith}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.min_deposit')}</p>
				<p className="td-name">{deptNolimit ? 'no limit' : minDept}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.max_deposit')}</p>
				<p className="td-name">{deptNolimit ? 'no limit' : maxDept}</p>
			</div>
		</div>
	);
};

export default TableRow;
