import { FC, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { getTransferHistory } from 'redux/reducers/marginWallets/selectors';
import { ITransferHistoryRequestData } from 'redux/reducers/marginWallets/types';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { IWalletItem } from 'redux/reducers/wallets/types';

import THAssetDropdown from 'ui/THAssetDropdown';

import THTimeDropdown from 'ui/THTimeDropdown';
import Pagination from 'ui/Pagination';
import { getTransferHistoryRequest } from 'redux/reducers/marginWallets/reducer';

import TransferTable from './TransferTable';

const TransferHistory: FC = () => {
	const walletsList = useSelector(getWalletsList);
	const walletsFilter = walletsList?.filter((item) => String(item?.asset.type) !== 'fiat');

	const [selectTime, setSelectTime] = useState<number | string>('All');
	const timeOptions = ['All', 7, 30, 90];

	const [selectAsset, setSelectAsset] = useState<IWalletItem | string>('All');

	const dispatch = useDispatch();

	const walletsTransferHistoryList = useSelector(getTransferHistory);

	const [currentPage, setCurrentPage] = useState<number>(1);

	const totalPages = walletsTransferHistoryList?.last_page || 1;

	const handleTimeSelect = (value: number) => {
		setSelectTime(value);
		setCurrentPage(1);
	};

	const handleAssetSelect = (value: IWalletItem | string) => {
		setSelectAsset(value);
		setCurrentPage(1);
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	const handleResetClick = () => {
		setSelectTime('All');
		setSelectAsset('All');
	};

	const handleExportHistoryClick = () => {
		if (walletsTransferHistoryList?.data?.length) {
			const requestWalletsTransferHistoryData: ITransferHistoryRequestData = {
				params: {
					per_page: 10,
					current_page: currentPage,
					export: 1,
				},
			};

			if (typeof selectAsset === 'object' && selectAsset.id) {
				requestWalletsTransferHistoryData.params.asset_id = selectAsset.asset.id;
			}

			if (selectTime !== 'All') {
				requestWalletsTransferHistoryData.params.past_days = selectTime;
			}

			dispatch(getTransferHistoryRequest(requestWalletsTransferHistoryData));
		}
	};

	useEffect(() => {
		const requestWalletsTransferHistoryData: ITransferHistoryRequestData = {
			params: {
				per_page: 10,
				current_page: currentPage,
			},
		};

		if (typeof selectAsset === 'object' && selectAsset.id) {
			requestWalletsTransferHistoryData.params.asset_id = selectAsset?.asset.id;
		}

		if (selectTime !== 'All') {
			requestWalletsTransferHistoryData.params.past_days = selectTime;
		}

		dispatch(getTransferHistoryRequest(requestWalletsTransferHistoryData));
	}, [dispatch, selectAsset, selectTime, currentPage]);

	return (
		<>
			<div className="transactions__filter transactions-history-filter">
				<div className="transactions__filter-right">
					<div className="transactions__select-block select-block">
						<p className="select-block__name">{String(L.translate('Transfer.period'))}</p>
						<THTimeDropdown value={selectTime} onChange={handleTimeSelect} options={timeOptions} />
					</div>
					<div className="transactions__select-block select-block">
						<p className="select-block__name">{String(L.translate('Transfer.asset'))}</p>
						<THAssetDropdown
							value={selectAsset}
							onChange={handleAssetSelect}
							options={walletsFilter || []}
						/>
					</div>
				</div>

				<div className="transactions__filter-left transactions__filter-left-wrapper">
					<button
						className="transactions__filter-btn reset-btn"
						type="button"
						onClick={handleResetClick}
					>
						{String(L.translate('Transfer.reset_filter'))}
					</button>
					<button
						className="transactions__filter-btn transactions__filter-btn--convert"
						type="button"
						onClick={handleExportHistoryClick}
					>
						<span className="btn-cover-icon icon-arrow" />
						{String(L.translate('Transfer.export_transfer_history'))}
					</button>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="transactions-table table transactions-table--transfer">
						<TransferTable data={walletsTransferHistoryList?.data || []} />

						{totalPages > 1 && (
							<Pagination
								pageCount={totalPages}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default TransferHistory;
