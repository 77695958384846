/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { IConfirmDeletePopupProps } from './types';

const Modal: FC<IConfirmDeletePopupProps> = ({ children, openModal, closeModal }) => {
	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small">
						<button className="popup-close" type="button" onClick={closeModal}>
							<span className="popup-close__icon icon-close" />
						</button>
						{children}
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default Modal;
