import { FC, ChangeEvent, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationRequest } from 'redux/reducers/settings/reducer';
import Loader from 'ui/Loader';
import {
	getCheckNotification,
	getCheckNotificationLoader,
	getUserSettingsData,
} from 'redux/reducers/settings/selectors';
import { notificationContainer } from '../../../services/utils/notificationContainer';

const UserNotifications: FC = () => {
	const dispatch = useDispatch();
	const notifiactions = useSelector(getCheckNotification);
	const notifiactionsLoader = useSelector(getCheckNotificationLoader);
	const userData = useSelector(getUserSettingsData);
	const instialState = {
		email_enabled: false,
		sms_enabled: false,
	};
	const [check, setCheck] = useState(instialState);

	useEffect(() => {
		if (notifiactions) {
			setCheck(notifiactions);
		}
	}, [notifiactions]);

	const toggleNotification = (e: ChangeEvent<HTMLInputElement>, name: string) => {
		setCheck({ ...check, [name]: e.currentTarget.checked });

		dispatch(updateNotificationRequest({ ...check, [name]: e.currentTarget.checked }));
	};

	return (
		<>
			<div className="main-content__header">
				<h2 className="section-title">
					{String(L.translate('Account.Notifications.notifications'))}
				</h2>
			</div>
			<div className="content-block content-block--medium">
				<div className="content-block__header">
					<div className="content-block__title">
						<span className="content-block__title-icon icon-directbox-notif" />
						<span className="content-block__title-text">
							{String(L.translate('Account.Notifications.notifications'))}
						</span>
					</div>
				</div>
				{notifiactionsLoader ? (
					<div className="check-2fa-enable-wrapper">
						<Loader />
					</div>
				) : (
					<div className="content-block__main">
						<div className="push-list">
							<div className="push-notify">
								<span className="push-notify__title">
									{String(L.translate('Account.Notifications.email_notifications'))}
								</span>
								<div className="push-notify__row">
									<p className="push-notify__desc">
										{String(L.translate('Account.Notifications.email_notify_text'))}
									</p>
									<div className="switch switch--type2">
										<label className="switch__label">
											<input
												onChange={(e) => toggleNotification(e, 'email_enabled')}
												type="checkbox"
												className="hidden"
												checked={check?.email_enabled}
											/>
											<span className="switch__toggler" />
										</label>
									</div>
								</div>
							</div>
							<div className="push-notify">
								<span className="push-notify__title">
									{String(L.translate('Account.Notifications.sms_notification'))}
								</span>
								<div className="push-notify__row">
									<p className="push-notify__desc">
										{String(L.translate('Account.Notifications.sms_notify_text'))}
									</p>
									<div className="switch switch--type2">
										<label className="switch__label">
											<input
												onChange={(e) => {
													if (!userData?.data?.phone) {
														notificationContainer(
															String(L.translate('Account.Notifications.add_phone')),
															'error',
														);
													} else {
														toggleNotification(e, 'sms_enabled');
													}
												}}
												type="checkbox"
												className="hidden"
												checked={check?.sms_enabled}
											/>
											<span className="switch__toggler" />
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default UserNotifications;
