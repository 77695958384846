import { FC, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { getWalletsList, getWalletsDepositHistoryList } from 'redux/reducers/wallets/selectors';
import { IWalletItem, IWalletsDepositHistoryRequestData } from 'redux/reducers/wallets/types';
import THAssetDropdown from 'ui/THAssetDropdown';
import THTimeDropdown from 'ui/THTimeDropdown';
import Pagination from 'ui/Pagination';
import { getWalletsDepositHistoryRequest } from 'redux/reducers/wallets/reducer';

import DepositTable from './DepositTable';

const StablecoinHistory: FC = () => {
	const walletsList = useSelector(getWalletsList);

	const [selectTime, setSelectTime] = useState<number | string>('All');
	const timeOptions = ['All', 7, 30, 90];

	const [selectAsset, setSelectAsset] = useState<IWalletItem | string>('All');

	const dispatch = useDispatch();

	const walletsDepositHistoryList = useSelector(getWalletsDepositHistoryList);

	const [currentPage, setCurrentPage] = useState<number>(1);

	const totalPages = walletsDepositHistoryList?.last_page || 1;

	const handleTimeSelect = (value: number) => {
		setSelectTime(value);
		setCurrentPage(1);
	};

	const handleAssetSelect = (value: IWalletItem | string) => {
		setSelectAsset(value);
		setCurrentPage(1);
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	const handleResetClick = () => {
		setSelectTime('All');
		setSelectAsset('All');
	};

	const handleExportHistoryClick = () => {
		if (walletsDepositHistoryList?.data?.length) {
			const requestWalletsDepositHistoryData: IWalletsDepositHistoryRequestData = {
				params: {
					per_page: 10,
					current_page: currentPage,
					export: true,
				},
			};

			if (typeof selectAsset === 'object' && selectAsset.id) {
				requestWalletsDepositHistoryData.params.asset_id = selectAsset.asset.id;
			}

			if (selectTime !== 'All') {
				requestWalletsDepositHistoryData.params.past_days = selectTime;
			}

			dispatch(getWalletsDepositHistoryRequest(requestWalletsDepositHistoryData));
		}
	};

	useEffect(() => {
		const requestWalletsDepositHistoryData: IWalletsDepositHistoryRequestData = {
			params: {
				per_page: 10,
				current_page: currentPage,
			},
		};

		if (typeof selectAsset === 'object' && selectAsset.id) {
			requestWalletsDepositHistoryData.params.asset_id = selectAsset.asset.id;
		}

		if (selectTime !== 'All') {
			requestWalletsDepositHistoryData.params.past_days = selectTime;
		}

		dispatch(getWalletsDepositHistoryRequest(requestWalletsDepositHistoryData));
	}, [dispatch, selectAsset, selectTime, currentPage]);

	return (
		<>
			<div className="transactions__filter transactions-history-filter">
				<div className="transactions__filter-right">
					<div className="transactions__select-block select-block">
						<p className="select-block__name">{String(L.translate('Deposit.period'))}</p>
						<THTimeDropdown value={selectTime} onChange={handleTimeSelect} options={timeOptions} />
					</div>
					<div className="transactions__select-block select-block">
						<p className="select-block__name">{String(L.translate('Deposit.asset'))}</p>
						<THAssetDropdown
							value={selectAsset}
							onChange={handleAssetSelect}
							options={walletsList?.filter((wallet) => wallet.asset.type === 'fiat') || []}
						/>
					</div>
				</div>

				<div className="transactions__filter-left transactions__filter-left-wrapper">
					<button
						className="transactions__filter-btn reset-btn"
						type="button"
						onClick={handleResetClick}
					>
						{String(L.translate('Deposit.reset_filter'))}
					</button>
					<button
						className="transactions__filter-btn transactions__filter-btn--convert"
						type="button"
						onClick={handleExportHistoryClick}
					>
						<span className="btn-cover-icon icon-arrow" />
						{String(L.translate('Deposit.export_fiat_history'))}
					</button>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="transactions-table table transactions-table--fiat">
						<DepositTable data={walletsDepositHistoryList?.data || []} />

						{totalPages > 1 && (
							<Pagination
								pageCount={totalPages}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default StablecoinHistory;
