/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import L from 'i18n-react';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import { sendWithdrawCryptoEmailCodeRequest } from 'redux/reducers/withdrawCrypto/reducer';
import { useSelector, useDispatch } from 'react-redux';
import { IModalProps } from '../types';

export const SicurityVerify: FC<IModalProps> = ({
	openModal,
	closeModal,
	handleSubmit,
	disableButton,
	verify,
	setVerify,
	emailCodeIsError,
	smsCodeIsError,
	totpCodeIsError,
	setEmailCodeIsError,
	setSmsCodeIsError,
	setTotpCodeIsError,
}) => {
	const userData = useSelector(getUserSettingsData);
	const dispatch = useDispatch();
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.currentTarget;
		if (value.length > 6) return;
		setVerify?.({ ...verify, [name]: value });
	};
	const [secondsEmail, setSecondsEmail] = useState(0);
	const [secondsSms, setSecondsSms] = useState(0);
	const [isEmailSendCode, setIsEmailSendCode] = useState(false);
	const [isSmsSendCode, setIsSmsSendCode] = useState(false);
	const handleGetEmailCode = () => {
		dispatch(sendWithdrawCryptoEmailCodeRequest());

		setSecondsEmail(60);
	};

	const handleGetSmsCode = () => {
		if (userData?.data?.phone) {
			dispatch(generateSmsRequest({ phone: userData?.data?.phone }));

			setSecondsSms(60);
		}
	};

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;

		if (secondsEmail > 0) {
			timer = setTimeout(() => setSecondsEmail(secondsEmail - 1), 1000);
			setIsEmailSendCode(true);
		} else {
			setSecondsEmail(0);
			setIsEmailSendCode(false);
		}

		return () => {
			clearTimeout(timer);
			setIsEmailSendCode(false);
		};
	}, [secondsEmail]);

	const isGoogle = !!(userData?.google2fa_enabled && !verify.google);
	const isPhone = !!(userData?.phone_2fa_enabled && !verify.phone);
	const isEmail = !!(userData?.email_2fa_enabled && !verify.email);

	const disabled = isGoogle || isPhone || isEmail;

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;

		if (secondsSms > 0) {
			timer = setTimeout(() => setSecondsSms(secondsSms - 1), 1000);
			setIsSmsSendCode(true);
		} else {
			setSecondsSms(0);
			setIsSmsSendCode(false);
		}

		return () => {
			clearTimeout(timer);
			setIsSmsSendCode(false);
		};
	}, [secondsSms]);

	useEffect(() => {
		setEmailCodeIsError?.(false);
	}, [verify.email]);

	useEffect(() => {
		setSmsCodeIsError?.(false);
	}, [verify.phone]);

	useEffect(() => {
		setTotpCodeIsError?.(false);
	}, [verify.google]);

	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<button onClick={closeModal} type="button" className="popup-close">
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header popup-header--padding">
							<p className="popup-header__title">
								{' '}
								{L.translate('WithdrawFiat.Popups.SecurityVerify.title')}{' '}
							</p>
						</div>

						<div className="popup-body ">
							{userData?.email_2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className={`input-wrapper ${emailCodeIsError ? 'input--error' : ''}`}>
													<input
														className="input-item"
														type="email"
														name="email"
														placeholder={String(
															L.translate(
																'WithdrawFiat.Popups.SecurityVerify.Placeholders.email_verif_code',
															),
														)}
														onChange={handleChange}
														value={verify.email || ''}
													/>
												</div>
											</div>
										</div>
										{isEmailSendCode && secondsEmail > 0 ? (
											<p className="timer-wrapper">{secondsEmail}s</p>
										) : (
											<button
												className="button button--small"
												type="button"
												onClick={handleGetEmailCode}
											>
												{String(L.translate('WithdrawCrypto.get_code'))}
											</button>
										)}
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium">
											{L.translate('WithdrawFiat.Popups.SecurityVerify.enter_code_sent_to', {
												to: userData?.email,
											})}
										</span>
									</div>
								</div>
							) : (
								''
							)}
							{userData?.phone_2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className={`input-wrapper ${smsCodeIsError ? 'input--error' : ''}`}>
													<input
														className="input-item"
														type="text"
														name="phone"
														value={verify.phone || ''}
														onChange={handleChange}
														placeholder={String(
															L.translate(
																'WithdrawFiat.Popups.SecurityVerify.Placeholders.phone_verif_code',
															),
														)}
													/>
												</div>
											</div>
										</div>
										{isSmsSendCode && secondsSms > 0 ? (
											<p className="timer-wrapper">{secondsSms}s</p>
										) : (
											<button
												className="button button--small"
												type="button"
												onClick={handleGetSmsCode}
											>
												{String(L.translate('WithdrawCrypto.get_code'))}
											</button>
										)}
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium">
											{L.translate('WithdrawFiat.Popups.SecurityVerify.enter_code_sent_to', {
												to: userData?.data?.phone,
											})}
										</span>
									</div>
								</div>
							) : (
								''
							)}
							{userData?.google2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className={`input-wrapper ${totpCodeIsError ? 'input--error' : ''}`}>
													<input
														className="input-item"
														type="text"
														name="google"
														value={verify.google || ''}
														onChange={handleChange}
														placeholder={String(
															L.translate(
																'WithdrawFiat.Popups.SecurityVerify.Placeholders.google_verif_code',
															),
														)}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium">
											{L.translate('WithdrawFiat.Popups.SecurityVerify.enter_code_from_google')}
										</span>
									</div>
								</div>
							) : (
								''
							)}
						</div>
						<div className="popup-submit popup-submit--sb">
							<button
								onClick={handleSubmit}
								type="button"
								className="button button--full-width"
								disabled={disabled || disableButton}
							>
								{L.translate('Global.confirm')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
