// TODO: Переводы
import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getReferralBalanceLoad } from 'redux/reducers/referral/selectors';
import { PaymentMethod } from 'assets/svg-icon';
import Loader from 'ui/Loader';
import Item from './Item';
import { ITableType } from './types';

export const Table: FC<ITableType> = ({ data, setAction }) => {
	const loaderPayment = useSelector(getReferralBalanceLoad);
	return (
		<div className="table table--balance table--invites-earn">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<button
							type="button"
							className="table-header__name table-header__name--btn "
							name="code"
						>
							Coin{' '}
							<div className="td-sort">
								<span className="td-sort__icon td-sort__icon--rotate icon-arrow2 " />
								<span className="td-sort__icon icon-arrow2 " />
							</div>
						</button>
					</div>
					<div className="td">
						<button
							type="button"
							className="table-header__name table-header__name--btn "
							name="total"
						>
							Total assets{' '}
							<div className="td-sort">
								<span className="td-sort__icon td-sort__icon--rotate icon-arrow2 " />
								<span className="td-sort__icon icon-arrow2 " />
							</div>
						</button>
					</div>
					<div className="td td--right">
						<span className="table-header__name">Action</span>
					</div>
				</div>
			</div>
			<div className="table-body">
				{loaderPayment && (
					<div className="open-orders-history-loader-wrapper">
						<Loader />
					</div>
				)}
				{!loaderPayment && data?.length
					? data.map((card) => <Item key={card.id} {...card} setAction={setAction} />)
					: null}
				{/* {!loaderPayment && data?.length === 0 && (
					<div className="p2p-center-banner">
						<div className="p2p-center-banner__icon">
							<PaymentMethod />
						</div>
						<p className="p2p-center-banner__descr">
							{L.translate('Account.PaymentMethods.you_have_no_payments')}
						</p>
					</div>
				)} */}
			</div>
		</div>
	);
};
