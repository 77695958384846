import { FC, MouseEvent, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';

import { ISelect } from './types';

export const Select: FC<ISelect> = ({ className, placeholder, arr, onChange, activeElement }) => {
	const [openedDropdown, setOpenedDropdown] = useState(false);
	const closeDropdown = () => setOpenedDropdown(false);
	const openDropdown = () => setOpenedDropdown(true);
	const dropdown = useRef<HTMLDivElement | null>(null);
	useClickOutside(dropdown, closeDropdown);

	const handleOnChange = (e: MouseEvent<HTMLButtonElement>) => {
		const element = e.target as HTMLElement;
		onChange(element.innerText);
		closeDropdown();
	};

	return (
		<div
			className={`select select--regular select--regular2 
				${openedDropdown ? 'active' : ''} 
				${className || ''}
			`}
		>
			<button
				type="button"
				onClick={openDropdown}
				className={`select__current ${!activeElement ? 'select__current--placeholder' : ''}`}
			>
				{activeElement || placeholder || ''}
				<span className="select__arrow icon-arrow2" />
			</button>

			<div className="select__drop" ref={dropdown}>
				<div className="select__drop-scroll">
					{arr.map((el) => (
						<div key={el} className="select__drop-item">
							<button type="button" className="select__drop-link" onClick={handleOnChange}>
								<span className="select__drop-text">{el}</span>
							</button>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
