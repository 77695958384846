import { FC } from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { getExchangeRate, getExchangeRateIsLoad } from 'redux/reducers/convert/selectors';
import { IExchangeRate } from './types';

const ExchangeRate: FC<IExchangeRate> = ({ fromCode, toCode }) => {
	const exchangeRate = useSelector(getExchangeRate);
	const exchangeRateIsLoad = useSelector(getExchangeRateIsLoad);

	return (
		<>
			<div className="convert-calc convert-calc--new">
				<div className="convert-calc__block">
					{!exchangeRateIsLoad && (
						<span className="convert-calc__text">
							1 {fromCode.toUpperCase()} ≈ {exchangeRate?.rate || 0} {toCode.toUpperCase()}
						</span>
					)}
				</div>
			</div>
		</>
	);
};

export default ExchangeRate;
