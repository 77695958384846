import { FC } from 'react';
import L from 'i18n-react';
import { Success as Check } from 'assets/svg-icon';
import { Link } from 'react-router-dom';

export const WithdrawalError: FC = () => {
	return (
		<div className="content-inner content-inner--padding">
			<div className="content-block content-block--flex content-block--padding-none content-block--border content-block--full-heigh">
				<div className="create-offer create-offer--operation-status">
					<div className="leave-review">
						<div className="leave-review__icon leave-review__icon--error">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M17.5 2.5L2.5 17.5"
									stroke="white"
									strokeWidth="4"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M2.5 2.5L17.5 17.5"
									stroke="white"
									strokeWidth="4"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<h4 className="leave-review__title">
							{L.translate('WithdrawFiat.Popups.WithdrawalError.title')}
						</h4>
						<div className="leave-review__text leave-review__text--left">
							<p>{L.translate('WithdrawFiat.Popups.WithdrawalError.descr_1')}</p>
							<p>{L.translate('WithdrawFiat.Popups.WithdrawalError.descr_2')}</p>
						</div>
						<div className="form-footer">
							<Link to="/withdraw" className="button button--big ">
								{L.translate('WithdrawFiat.Popups.WithdrawalError.back')}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
