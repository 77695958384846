import React, { FC, useEffect, useState, ChangeEvent, useMemo } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getMarginWallets } from 'redux/reducers/marginWallets/selectors';
import { IWalletsDataType } from 'redux/reducers/marginWallets/types';
import MarginBalances from '../MarginBalances';
import MarginTable from './MarginTable/index';
import { IMarginCossProps } from './types';

const MarginCross: FC<IMarginCossProps> = ({
	handleSetBalancesIsHide,
	balancesIsHide,
	currentTab,
}) => {
	const [walletsMarginCross, setWalletsMarginCross] = useState<IWalletsDataType>([]);
	const [searchCoins, setSearchCoins] = useState('');
	const [smallBalancesIsHide, setSmallBalancesIsHide] = useState(false);

	const marginWallets = useSelector(getMarginWallets);

	const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setSearchCoins(value);
	};

	const handleChangeSmallBalancesIsHide = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;

		setSmallBalancesIsHide(checked);
	};

	useEffect(() => {
		if (marginWallets?.marginCrossWallets) {
			setWalletsMarginCross(marginWallets?.marginCrossWallets?.data?.cross);
		}
	}, [marginWallets?.marginCrossWallets]);

	const cryptoWalletsList = useMemo(() => {
		if (!walletsMarginCross?.length) {
			return [];
		}

		if (smallBalancesIsHide && searchCoins) {
			return walletsMarginCross?.filter(
				(wallet) =>
					Number(wallet.total) > 1.0003 &&
					wallet.asset.code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		if (smallBalancesIsHide) {
			return walletsMarginCross.filter((wallet) => Number(wallet.total) > 1.0003);
		}

		if (searchCoins) {
			return walletsMarginCross.filter((wallet) =>
				wallet.asset.code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		return walletsMarginCross;
	}, [walletsMarginCross, searchCoins, smallBalancesIsHide]);

	return (
		<>
			<MarginBalances
				handleSetBalancesIsHide={handleSetBalancesIsHide}
				balancesIsHide={balancesIsHide}
				data={marginWallets.marginCrossWallets?.data}
				currentTab={currentTab}
			/>
			<div className="content-block">
				<div className="balance-section">
					<div className="balance-section__header">
						<div className="search search--big">
							<div className="input input--margin-none">
								<div className="input-wrapper">
									<input
										className="input-item input-item--sort input-item--right-icon"
										type="text"
										placeholder={String(L.translate('P2P.search_coin'))}
										value={searchCoins}
										onChange={handleChangeSearch}
									/>
									<button type="button" className="search-btn">
										<span className="search-btn__icon icon-search-normal" />
									</button>
								</div>
							</div>
						</div>
						<div className="checkbox checkbox--margin-none">
							<label className="checkbox__label">
								<input
									type="checkbox"
									className="hidden"
									checked={smallBalancesIsHide}
									onChange={handleChangeSmallBalancesIsHide}
								/>
								<span className=" checkbox__item">
									<span className="checkbox__item-icon">
										<span className="icon-Checkbox" />
									</span>
								</span>
								<span className="checkbox__text checkbox__text--grey">
									{String(L.translate('Margin.hide_small_balances'))}
								</span>
							</label>
						</div>
					</div>
					<div className="balance-block">
						<div className="balance-block">
							<MarginTable data={cryptoWalletsList} balancesIsHide={balancesIsHide} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MarginCross;
