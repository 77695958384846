import { FC } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { IWithdrawal2FAPopup } from './types';

const Withdrawal2FAPopup: FC<IWithdrawal2FAPopup> = ({ isOpen, onClose }) => {
	const history = useHistory();

	const handleSubmit = () => {
		history.push('/auth-2fa');
		onClose();
	};

	return (
		<Popup open={isOpen} closeOnDocumentClick onClose={onClose} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small">
						<button className="popup-close" type="button" onClick={onClose}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title">
								{String(L.translate('SpotAndFiat.Withdrawal2FAPopup.title'))}
							</p>
						</div>
						<div className="popup-body popup-body--type3">
							<div className="popup-text popup-text--width-350 popup-text--center">
								<p>{String(L.translate('SpotAndFiat.Withdrawal2FAPopup.text'))}</p>
							</div>
						</div>
						<div className="popup-submit ">
							<button type="submit" className="button button--full-width " onClick={handleSubmit}>
								{String(L.translate('SpotAndFiat.Withdrawal2FAPopup.submit_btn'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default Withdrawal2FAPopup;
