import { FC, useState } from 'react';
import L from 'i18n-react';
import * as yup from 'yup';
import { CheckIcon, CloseIcon, TrashIcon } from 'assets/svg-icon';
import { trimAddress } from 'services/utils/trimComment';
import { IListMethodItem } from 'redux/reducers/p2p/types';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { IAction } from '../../PaymentCenter';

interface IPaymentItemProps {
	setAction: (data: IAction) => void;
}

const PaymentItem: FC<IListMethodItem & IPaymentItemProps> = ({
	requisite,
	payment_method,
	id,
	setAction,
}) => {
	const dispatch = useDispatch();
	const [edit, setEdit] = useState(false);

	const handleToggleEdit = () => {
		setEdit(!edit);
	};

	const handleDelete = () => {
		setAction({
			action: 'delete',
			category: 'bank',
			data: {
				id,
			},
		});
	};
	const handleEditCard = (values: typeof initialValues) => {
		// dispatch(
		// 	updateCardRequest({
		// 		...values,
		// 		card_number: values.card_number.replace(/ /g, ''),
		// 		bank_name: values.name || '-',
		// 		id,
		// 	}),
		// );
		setEdit(false);
	};

	const initialValues = {
		holder_name: '',
		name: requisite.name,
		card_number: requisite.card_number?.replace(/(\d{4})/g, '$1 ') || '',
	};

	const validationSchema = yup.object().shape({
		name: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.holder_error')))
			.min(3, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
			.max(50, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		card_number: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.card_number_error')))
			.min(19, String(L.translate('Account.PaymentMethods.AddCard.card_number_error_2'))),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);
				handleEditCard(values);
			}}
		>
			{({ values, errors, submitForm }) => (
				<Form>
					<div className="tr">
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.name')}
							</p>
							<p className="td-name">{requisite?.name}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.bank_account')}
							</p>
							<p className="td-name">{payment_method?.payment_name_eng}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.swift')}
							</p>
							<p className="td-name">{requisite?.swift}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.iban')}
							</p>
							<p className="td-name">{trimAddress(String(requisite?.iban))}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.address')}
							</p>
							<p className="td-name">{requisite?.bank_address || '---'}</p>
						</div>
						<div className="td td--right">
							<div className="payment-method-p2p__action">
								{edit ? (
									<>
										<button
											type="button"
											className="payment-method-p2p__button"
											onClick={submitForm}
										>
											<CheckIcon />
										</button>
										<button
											onClick={handleToggleEdit}
											type="button"
											className="payment-method-p2p__button"
										>
											<CloseIcon />
										</button>
									</>
								) : (
									<>
										{/* <button
											onClick={handleToggleEdit}
											type="button"
											className="payment-method-p2p__button"
										>
											<EditIcon />
										</button> */}
										<button
											onClick={handleDelete}
											type="button"
											className="payment-method-p2p__button"
										>
											<TrashIcon />
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default PaymentItem;
