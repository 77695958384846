import { FC } from 'react';
import { trimFileName } from 'services/utils/trimComment';
import { IUploadFile } from './types';

const UploadedFileInput: FC<IUploadFile> = (props) => {
	const { file, handleDeleteFile } = props;
	return (
		<div className="loaded-file reset-form reset-form--succes authorization__field">
			<div className="reset-form__input reset-form__input--file">
				<div className="input-file-btn">
					<span className="input-file-descr input-file-descr--success">
						<span className="authorization_overflow">{trimFileName(file?.name)}</span>
					</span>
					<button type="button" className="loaded-file__remove" onClick={handleDeleteFile}>
						<i className="fas fa-trash" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default UploadedFileInput;
