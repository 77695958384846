import { FC } from 'react';
import L from 'i18n-react';
import Dashboard from 'layouts/Dashboard';

import SpotAndFiatHistory from 'components/SpotAndFiatHistory';

// ==========================================:
const OrderHistory: FC = () => {
	return (
		<Dashboard title={String(L.translate('PageTitles.spot_and_fiat_history'))}>
			<SpotAndFiatHistory title={String(L.translate('Base.order_history'))} tab="closed" />
		</Dashboard>
	);
};

export default OrderHistory;
