import { FC } from 'react';
import L from 'i18n-react';

// ==========================================:
const FooterCopy: FC = () => {
	return (
		<div className="copy">
			<span className="copy__item">© {String(L.translate('Footer.copyright'))}</span>
			<span className="copy__item">{String(L.translate('Footer.trading_company'))}</span>
			<span className="copy__item">{String(L.translate('Footer.all_rights_reserved'))}</span>
		</div>
	);
};

export default FooterCopy;
