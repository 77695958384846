import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import {
	confirmCryptoWithdrawRequest,
	sendWithdrawCryptoEmailCodeRequest,
} from 'redux/reducers/withdrawCrypto/reducer';
import { IConfyrmCryptoWithdrawalPayloadData } from 'redux/reducers/withdrawCrypto/types';
import { IWitdrawCryptoConfirmPopupProps } from './types';

const WitdrawCryptoConfirmPopup: FC<IWitdrawCryptoConfirmPopupProps> = ({
	openModal,
	closeModal,
	address,
	recieveAmount,
	amount,
	currentWallet,
	currentWalletNetwork,
}) => {
	const [secondsEmail, setSecondsEmail] = useState(0);
	const [secondsSms, setSecondsSms] = useState(0);
	const [isEmailSendCode, setIsEmailSendCode] = useState(false);
	const [isSmsSendCode, setIsSmsSendCode] = useState(false);
	const [isConfirmDesabled, setIsConfirmDesabled] = useState(false);
	const [emailCode, setEmeilCode] = useState('');
	const [smsCode, setSmsCode] = useState('');
	const [totpCode, setTotpCode] = useState('');

	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserSettingsData);
	const userSettigsData = useSelector(getUserSettingsData);

	const handleGetEmailCode = () => {
		dispatch(sendWithdrawCryptoEmailCodeRequest());

		setSecondsEmail(60);
	};

	const handleGetSmsCode = () => {
		if (userSettigsData?.data?.phone) {
			dispatch(generateSmsRequest({ phone: userSettigsData?.data?.phone }));

			setSecondsSms(60);
		}
	};

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;

		if (secondsEmail > 0) {
			timer = setTimeout(() => setSecondsEmail(secondsEmail - 1), 1000);
			setIsEmailSendCode(true);
		} else {
			setSecondsEmail(0);
			setIsEmailSendCode(false);
		}

		return () => {
			clearTimeout(timer);
			setIsEmailSendCode(false);
		};
	}, [secondsEmail]);

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;

		if (secondsSms > 0) {
			timer = setTimeout(() => setSecondsSms(secondsSms - 1), 1000);
			setIsSmsSendCode(true);
		} else {
			setSecondsSms(0);
			setIsSmsSendCode(false);
		}

		return () => {
			clearTimeout(timer);
			setIsSmsSendCode(false);
		};
	}, [secondsSms]);

	const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setEmeilCode(value);
		}
	};

	const handleChangeSms = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setSmsCode(value);
		}
	};

	const handleChangeTotp = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setTotpCode(value);
		}
	};

	const handleCloseModal = () => {
		closeModal();
		setEmeilCode('');
		setSmsCode('');
		setTotpCode('');
	};

	const isGoogle = !!(userSettigsData?.google2fa_enabled && !totpCode);
	const isPhone = !!(userSettigsData?.phone_2fa_enabled && !smsCode);
	const isEmail = !!(userSettigsData?.email_2fa_enabled && !emailCode);

	const disabled = isGoogle || isPhone || isEmail;

	const handleConfirmCryptoWithdrawal = () => {
		setIsConfirmDesabled(true);

		const withdrawalPayloadData: IConfyrmCryptoWithdrawalPayloadData = {
			data: {
				wallet_id: currentWallet?.id,
				amount,
				address,
				network: currentWalletNetwork?.network_id,
				email_code: emailCode.trim(),
			},
			history,
			closeModal: handleCloseModal,
			setIsConfirmDesabled,
		};

		if (smsCode) {
			withdrawalPayloadData.data.sms_code = smsCode.trim();
		}

		if (totpCode) {
			withdrawalPayloadData.data.totp = totpCode.trim();
		}

		dispatch(confirmCryptoWithdrawRequest(withdrawalPayloadData));
	};

	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={handleCloseModal}
			closeOnEscape
			lockScroll
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<button className="popup-close" type="button" onClick={handleCloseModal}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header popup-header--padding">
							<p className="popup-header__title">
								{String(L.translate('WithdrawCrypto.withdraw_popup_title'))}
							</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="withdrawal-options">
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{String(L.translate('WithdrawCrypto.amount'))}
									</span>
									<span className="withdrawal-option__desc">
										{String(L.translate('WithdrawCrypto.receive'))} {recieveAmount}{' '}
										{currentWallet?.asset?.code?.toUpperCase()} (
										{String(L.translate('WithdrawCrypto.fee'))}{' '}
										{userData?.is_vip
											? currentWalletNetwork?.vip_withdraw_fee
											: currentWalletNetwork?.withdraw_fee}
										%)
									</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{String(L.translate('WithdrawCrypto.address'))}
									</span>
									<span className="withdrawal-option__desc">{address}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{String(L.translate('WithdrawCrypto.network'))}
									</span>
									<span className="withdrawal-option__desc">
										{currentWalletNetwork?.network_name?.toUpperCase()}
									</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{String(L.translate('WithdrawCrypto.source'))}
									</span>
									<span className="withdrawal-option__desc">
										{String(L.translate('WithdrawCrypto.spot_wallet'))}
									</span>
								</div>
							</div>

							{userSettigsData?.email_2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className="input-wrapper">
													<input
														className="input-item"
														type="text"
														placeholder={String(
															L.translate('WithdrawCrypto.Create.Placeholders.email_verify'),
														)}
														value={emailCode}
														onChange={handleChangeEmail}
													/>
												</div>
											</div>
										</div>
										{isEmailSendCode && secondsEmail > 0 ? (
											<p className="timer-wrapper">{secondsEmail}s</p>
										) : (
											<button
												className="button button--small"
												type="button"
												onClick={handleGetEmailCode}
											>
												{String(L.translate('WithdrawCrypto.get_code'))}
											</button>
										)}
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
											{String(L.translate('WithdrawCrypto.enter_code'))} {userSettigsData?.email}
										</span>
									</div>
								</div>
							) : null}
							{userSettigsData?.phone_2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className="input-wrapper">
													<input
														className="input-item"
														type="text"
														placeholder={String(
															L.translate('WithdrawCrypto.Create.Placeholders.sms_verify'),
														)}
														value={smsCode}
														onChange={handleChangeSms}
													/>
												</div>
											</div>
										</div>
										{isSmsSendCode && secondsSms > 0 ? (
											<p className="timer-wrapper">{secondsSms}s</p>
										) : (
											<button
												className="button button--small"
												type="button"
												onClick={handleGetSmsCode}
											>
												{String(L.translate('WithdrawCrypto.get_code'))}
											</button>
										)}
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
											{String(L.translate('WithdrawCrypto.sms_sent'))}{' '}
											{userSettigsData?.data?.phone}
										</span>
									</div>
								</div>
							) : null}

							{userSettigsData?.google2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className="input-wrapper">
													<input
														className="input-item"
														type="text"
														placeholder={String(
															L.translate('WithdrawCrypto.Create.Placeholders.2fa_code'),
														)}
														value={totpCode}
														onChange={handleChangeTotp}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
											{String(L.translate('WithdrawCrypto.enter_google_code'))}
										</span>
									</div>
								</div>
							) : null}
							<ul className="withdrawal-list">
								<li className="withdrawal-list__item">
									{String(L.translate('WithdrawCrypto.address_is_correct'))}
								</li>
								<li className="withdrawal-list__item">
									{String(L.translate('WithdrawCrypto.transaction_cancel_error'))}
								</li>
							</ul>
						</div>
						<div className="popup-submit popup-submit--sb">
							<button
								className="button button--full-width"
								type="button"
								disabled={disabled || isConfirmDesabled}
								onClick={handleConfirmCryptoWithdrawal}
							>
								{String(L.translate('WithdrawCrypto.confirm_button'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default memo(WitdrawCryptoConfirmPopup);
