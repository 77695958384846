import { PayloadAction } from '@reduxjs/toolkit';
import L from 'i18n-react';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	getKycOndatoURLRequest,
	getKycOndatoURLSuccess,
	getKycOndatoUserDataRequest,
	getKycOndatoUserDataSuccess,
	kycInitState,
} from './reducer';
import {
	IKycOndatoURLRequestPayload,
	IKycOndatoUserDataResponsePayload,
	IKysResponse,
} from './types';

// =============================================================:
function* getKycOndatoURLWorker({ payload }: PayloadAction<IKycOndatoURLRequestPayload>) {
	const { setAccess, onSuccess } = payload;

	try {
		yield put(showLoading());
		const response: IKysResponse = yield call(api.kyc.getKycOndatoUrl, payload);

		yield put(getKycOndatoURLSuccess(response));

		if (response?.apiUrl) {
			setAccess?.(response);
			onSuccess?.();
			notificationContainer(String(L.translate('Notification.open_ondato')), 'info', {
				autoClose: 2000,
			});
		} else {
			notificationContainer(String(L.translate('Notification.open_ondato_error')), 'error');
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(kycInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getKycOndatoUserDataWorker() {
	try {
		yield put(showLoading());
		const response: IKycOndatoUserDataResponsePayload = yield call(api.kyc.getKycOndatoUserData);

		yield put(getKycOndatoUserDataSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(kycInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* kycSaga() {
	yield takeEvery(getKycOndatoURLRequest.type, getKycOndatoURLWorker);
	yield takeEvery(getKycOndatoUserDataRequest.type, getKycOndatoUserDataWorker);
}
