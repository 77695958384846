import { IApi } from './types';
import { app } from './app';
import { assetPairs } from './assetPairs';
import { assets } from './assets';
import { auth } from './auth';
import { settings } from './settings';
import { spotTrade } from './spotTrade';
import { tradingChart } from './tradingChart';
import { wallets } from './wallets';
import { orders } from './orders';
import { withdrawCrypto } from './withdrawCrypto';
import { convert } from './convert';
import { cards } from './cards';
import { kyc } from './kyc';
import { marginWallets } from './marginWallets';
import { payments } from './payments';
import { p2p } from './p2p';
import { withdrawFiat } from './withdrawFiat';
import { ourPrices } from './ourPrices';
import { referral } from './referral';

const api: IApi = {
	app,
	assetPairs,
	assets,
	auth,
	settings,
	spotTrade,
	tradingChart,
	wallets,
	orders,
	payments,
	withdrawCrypto,
	convert,
	kyc,
	marginWallets,
	p2p,
	withdrawFiat,
	cards,
	ourPrices,
	referral,
};

export { api };
