import Convert from 'layouts/Convert';
import { APP_NAME } from 'services/constants/env';
import L from 'i18n-react';

const PrivacyPolicy = () => {
	return (
		<Convert
			title={`${String(APP_NAME)}.com | ${String(L.translate('PageTitles.privacy_policy'))}`}
			description={String(L.translate('MetaTags.Description.privacy_policy'))}
		>
			<div className="content-wrapper content-wrapper--padding-all ">
				<div className="container">
					<div className="page-info">
						<h1 className="page-info__title">{L.translate('PrivacyPolicy.title')}</h1>
					</div>
					<div style={{ textAlign: 'right', marginBottom: '40px' }}>
						<p>{L.translate('PrivacyPolicy.title_desc.text_1')} </p>
						<p>{L.translate('PrivacyPolicy.title_desc.text_2')} </p>
						<p>{L.translate('PrivacyPolicy.title_desc.text_3')} </p>
						<p>{L.translate('PrivacyPolicy.title_desc.text_4')} </p>
						<p>
							<a href="bitanica.com" target="_blank">
								bitanica.com
							</a>{' '}
							<a href="info@bitanica.com" target="_blank">
								info@bitanica.com
							</a>{' '}
							<a href="support@bitanica.com" target="_blank">
								support@bitanica.com
							</a>
						</p>
						{/* 					
						<a href="mailto:support@bitanica.com">support@bitanica.com </a> */}
					</div>

					<div className="static-content">
						<h2>1. {L.translate('PrivacyPolicy.Block_1.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_1.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_1.text_2')}</p>
						<p>{L.translate('PrivacyPolicy.Block_1.text_3')}</p>
						<h2>2. {L.translate('PrivacyPolicy.Block_2.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_2.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_2.text_2')}</p>
						<p>
							{L.translate('PrivacyPolicy.Block_2.text_3')}
							{/* <a
								href={`${window.location.origin}/storage/docs/aml.pdf`}
								target="_blank"
								rel="noreferrer"
							>
								AML
							</a> */}
						</p>
						<p>{L.translate('PrivacyPolicy.Block_2.text_4')}</p>
						<p>{L.translate('PrivacyPolicy.Block_2.text_5')}</p>
						<p>{L.translate('PrivacyPolicy.Block_2.text_6')}</p>
						<p>{L.translate('PrivacyPolicy.Block_2.text_7')}</p>
						<p>{L.translate('PrivacyPolicy.Block_2.text_8')}</p>

						<h2>3. {L.translate('PrivacyPolicy.Block_3.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_3.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_3.text_2')}</p>
						<p>{L.translate('PrivacyPolicy.Block_3.text_3')}</p>
						<p>{L.translate('PrivacyPolicy.Block_3.text_4')}</p>
						<p>{L.translate('PrivacyPolicy.Block_3.text_5')}</p>
						<p>{L.translate('PrivacyPolicy.Block_3.text_6')}</p>
						<ol>
							<li>{L.translate('PrivacyPolicy.Block_3.text_61')}</li>
							<li>{L.translate('PrivacyPolicy.Block_3.text_62')}</li>
							<li>{L.translate('PrivacyPolicy.Block_3.text_63')}</li>
						</ol>
						<p>{L.translate('PrivacyPolicy.Block_3.text_7')}</p>
						<p>{L.translate('PrivacyPolicy.Block_3.text_8')}</p>
						<p>{L.translate('PrivacyPolicy.Block_3.text_9')}</p>
						<p>{L.translate('PrivacyPolicy.Block_3.text_10')}</p>
						<p>{L.translate('PrivacyPolicy.Block_3.text_11')}</p>
						<h2>4. {L.translate('PrivacyPolicy.Block_4.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_4.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_4.text_2')}</p>
						<p>{L.translate('PrivacyPolicy.Block_4.text_3')}</p>
						<p>{L.translate('PrivacyPolicy.Block_4.text_4')}</p>
						<p>{L.translate('PrivacyPolicy.Block_4.text_5')}</p>
						<h2>5. {L.translate('PrivacyPolicy.Block_5.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_5.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_5.text_2')}</p>
						<p>{L.translate('PrivacyPolicy.Block_5.text_3')}</p>
						<h2>6. {L.translate('PrivacyPolicy.Block_6.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_6.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_2')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_3')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_4')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_5')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_6')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_7')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_8')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_9')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_10')}</p>
						<p>{L.translate('PrivacyPolicy.Block_6.text_11')}</p>

						<h2>7. {L.translate('PrivacyPolicy.Block_7.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_7.text_1')}</p>
						<ol>
							<li>{L.translate('PrivacyPolicy.Block_7.text_11')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_12')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_13')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_14')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_15')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_16')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_17')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_18')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_19')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_110')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_111')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_112')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_113')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_114')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_115')}</li>
							<li>{L.translate('PrivacyPolicy.Block_7.text_116')}</li>
						</ol>
						<p>{L.translate('PrivacyPolicy.Block_7.text_2')}</p>
						<h2>8. {L.translate('PrivacyPolicy.Block_8.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_8.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_8.text_2')}</p>
						<h2>9. {L.translate('PrivacyPolicy.Block_9.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_9.text_1')}</p>
						<ol>
							<li>{L.translate('PrivacyPolicy.Block_9.text_11')}</li>
							<li>{L.translate('PrivacyPolicy.Block_9.text_12')}</li>
							<li>
								{L.translate('PrivacyPolicy.Block_9.text_13')}
								<ul>
									<li>{L.translate('PrivacyPolicy.Block_9.text_131')}</li>
									<li>{L.translate('PrivacyPolicy.Block_9.text_132')}</li>
									<li>{L.translate('PrivacyPolicy.Block_9.text_133')}</li>
								</ul>
							</li>
							<li>{L.translate('PrivacyPolicy.Block_9.text_14')}</li>
							<li>{L.translate('PrivacyPolicy.Block_9.text_15')}</li>
						</ol>
						<p>{L.translate('PrivacyPolicy.Block_9.text_2')}</p>
						<ol>
							<li>{L.translate('PrivacyPolicy.Block_9.text_21')}</li>
							<li>{L.translate('PrivacyPolicy.Block_9.text_22')}</li>
							<li>{L.translate('PrivacyPolicy.Block_9.text_23')}</li>
							<li>{L.translate('PrivacyPolicy.Block_9.text_24')}</li>
							<li>{L.translate('PrivacyPolicy.Block_9.text_25')}</li>
						</ol>
						<p>{L.translate('PrivacyPolicy.Block_9.text_3')}</p>
						<p>{L.translate('PrivacyPolicy.Block_9.text_4')}</p>
						<h2>10. {L.translate('PrivacyPolicy.Block_10.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_10.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_10.text_2')}</p>
						<p>
							{L.translate('PrivacyPolicy.Block_10.text_3')}
							{/* <a href="mailto:support@bitanica.com">support@bitanica.com.</a> */}
						</p>

						<h2>11. {L.translate('PrivacyPolicy.Block_11.title')}</h2>
						<p>{L.translate('PrivacyPolicy.Block_11.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_11.text_2')}</p>
						<p>{L.translate('PrivacyPolicy.Block_11.text_3')}</p>
						<p>12. {L.translate('PrivacyPolicy.Block_12.title')}</p>
						<p>{L.translate('PrivacyPolicy.Block_12.text_1')}</p>
						<p>{L.translate('PrivacyPolicy.Block_12.text_2')}</p>
						<ul>
							<li>{L.translate('PrivacyPolicy.Block_12.text_21')}</li>
							<li>{L.translate('PrivacyPolicy.Block_12.text_22')}</li>
							<li>{L.translate('PrivacyPolicy.Block_12.text_23')}</li>
							<li>{L.translate('PrivacyPolicy.Block_12.text_24')}</li>
							<li>{L.translate('PrivacyPolicy.Block_12.text_25')}</li>
							<li>{L.translate('PrivacyPolicy.Block_12.text_26')}</li>
							<li>{L.translate('PrivacyPolicy.Block_12.text_27')}</li>
							<li>{L.translate('PrivacyPolicy.Block_12.text_28')}</li>
						</ul>
						<p>{L.translate('PrivacyPolicy.Block_12.text_3')}</p>
						<p>{L.translate('PrivacyPolicy.Block_12.text_4')}</p>
						<p>{L.translate('PrivacyPolicy.Block_12.text_5')}</p>
						<p>{L.translate('PrivacyPolicy.Block_12.text_6')}</p>
						<p>{L.translate('PrivacyPolicy.Block_12.text_7')}</p>
						<p>{L.translate('PrivacyPolicy.Block_12.text_8')}</p>
						<p>{L.translate('PrivacyPolicy.Block_12.text_9')}</p>
						{/* <h2>13. {L.translate('PrivacyPolicy.Block_13.title')}</h2>
						<p>
							{L.translate('PrivacyPolicy.Block_13.text_1')}
							<a href="mailto:support@bitanica.com">support@bitanica.com.</a>
						</p> */}
					</div>
				</div>
			</div>
		</Convert>
	);
};

export default PrivacyPolicy;
