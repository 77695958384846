/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { useState, FC, ChangeEvent, useEffect, useMemo } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { notificationContainer } from 'services/utils/notificationContainer';
import { numberValidation } from 'services/utils/numberValidation';
import {
	getIsolatedWalletsList,
	getMarginCrossWalletsList,
	getMarginIndexPrices,
	getMarginWallets,
} from 'redux/reducers/marginWallets/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import {
	getMarginCrossWalletsRequest,
	getMarginIsolatedWalletsRequest,
	postMarginTransferRequest,
} from 'redux/reducers/marginWallets/reducer';
import SearchInput from 'ui/SearchInput';
import { calculateTransform, calculateTransformIsolate } from 'services/utils/calculateTransfer';
import { getP2PBalancesList, getP2PTransferActsLoading } from 'redux/reducers/p2p/selectors';
import {
	getP2PBalancesListRequest,
	transferCrossToP2PRequest,
	transferIsolatedToP2PRequest,
	transferP2PToCrossRequest,
	transferP2PToIsolatedRequest,
	transferP2PToSpotRequest,
	transferSpotToP2PRequest,
} from 'redux/reducers/p2p/reducer';
import SearchInputPairs from 'ui/SearchInputPairs';
import { IP2PIsolatedTransferPayload } from 'redux/reducers/p2p/types';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { getAccountP2P } from 'redux/reducers/auth/selectors';
import { ITransferModalProps, TUnsafeTransferIsolatedCoinList } from './types';
import {
	CROSS_OPTION,
	P2P_OPTION_WITHOUT,
	P2P_OPTION,
	CROSS_OPTION_ID,
	filterCoinListByPair,
	getCompatibleCoinList,
	getPairList,
	getSelectOptions,
	ISOLATED_OPTION_ID,
	normalizeIsolatedCoinList,
	P2P_OPTION_ID,
	SPOT_OPTION,
	SPOT_OPTION_ID,
} from './utils';
import WalletSelect from './WalletSelect';
import BalanceSockets from './BalanceSockets';

const TransferModal: FC<ITransferModalProps> = ({
	isOpen,
	onClose,
	initFrom,
	initTo,
	initPairCode,
	initCoinCode,
	spotSocket,
	crossSocket,
	isolatedSocket,
	p2pSocket,
	marginIndexSocket,
}) => {
	const dispatch = useDispatch();
	const spotCoinList = useSelector(getWalletsList);
	const crossWallet = useSelector(getMarginCrossWalletsList);
	const isolatedCoinList = useSelector(getIsolatedWalletsList) as TUnsafeTransferIsolatedCoinList;
	const p2pBalances = useSelector(getP2PBalancesList);
	const p2pActsLoading = useSelector(getP2PTransferActsLoading);
	const marginIndexPrice: any = useSelector(getMarginIndexPrices);
	const userData = useSelector(getUserSettingsData);
	const marginWalletsData = useSelector(getMarginWallets);
	const accountP2P = useSelector(getAccountP2P);

	const [fromSelected, setFromSelected] = useState(initFrom || SPOT_OPTION);
	// const [toSelected, setToSelected] = useState(initTo || CROSS_OPTION);  // if enable cross uncoment this field
	const [toSelected, setToSelected] = useState(
		initTo || accountP2P ? P2P_OPTION : P2P_OPTION_WITHOUT,
	); // if enable cross deleted this field
	const [selectedPairCode, setSelectedPairCode] = useState(initPairCode || 'eth_usdt');
	const [selectedCoinCode, setSelectedCoinCode] = useState(initCoinCode || 'btc');
	const [amount, setAmount] = useState('');
	const [marginActsLoading, setMarginActsLoading] = useState(false);

	const fromOptions = getSelectOptions(toSelected, userData?.status_id, accountP2P);
	const toOptions = getSelectOptions(fromSelected, userData?.status_id, accountP2P);
	const isShowPairList = [fromSelected.id, toSelected.id].includes(ISOLATED_OPTION_ID);
	const crossCoinList = crossWallet?.data?.cross;
	const p2pCoinList = p2pBalances?.balances;
	const actionsLoading = marginActsLoading || p2pActsLoading;
	const normalizedIsolatedCoinList = useMemo(() => {
		return normalizeIsolatedCoinList(isolatedCoinList);
	}, [isolatedCoinList]);

	const pairList = useMemo(() => {
		return getPairList(normalizedIsolatedCoinList);
	}, [normalizedIsolatedCoinList]);
	const selectedPair = pairList?.find((pair) => pair.code === selectedPairCode);

	const isolatedCoinListByPair = useMemo(() => {
		return filterCoinListByPair(normalizedIsolatedCoinList, selectedPair?.id);
	}, [normalizedIsolatedCoinList, selectedPair]);

	const coinList = useMemo(() => {
		// Spot -> Cross
		if (fromSelected.id === SPOT_OPTION_ID && toSelected.id === CROSS_OPTION_ID) {
			return getCompatibleCoinList(spotCoinList, crossCoinList);
		}
		// Spot -> P2P
		if (fromSelected.id === SPOT_OPTION_ID && toSelected.id === P2P_OPTION_ID) {
			return getCompatibleCoinList(spotCoinList, p2pCoinList);
		}
		// Spot -> Isolated
		if (fromSelected.id === SPOT_OPTION_ID && toSelected.id === ISOLATED_OPTION_ID) {
			return getCompatibleCoinList(spotCoinList, isolatedCoinListByPair);
		}
		// Cross -> Spot
		if (fromSelected.id === CROSS_OPTION_ID && toSelected.id === SPOT_OPTION_ID) {
			return getCompatibleCoinList(crossCoinList, spotCoinList);
		}
		// Cross -> P2P
		if (fromSelected.id === CROSS_OPTION_ID && toSelected.id === P2P_OPTION_ID) {
			return getCompatibleCoinList(crossCoinList, p2pCoinList);
		}
		// Isolated -> Spot
		if (fromSelected.id === ISOLATED_OPTION_ID && toSelected.id === SPOT_OPTION_ID) {
			return getCompatibleCoinList(isolatedCoinListByPair, spotCoinList);
		}
		// Isolated -> P2P
		if (fromSelected.id === ISOLATED_OPTION_ID && toSelected.id === P2P_OPTION_ID) {
			return getCompatibleCoinList(isolatedCoinListByPair, p2pCoinList);
		}
		// P2P -> Spot
		if (fromSelected.id === P2P_OPTION_ID && toSelected.id === SPOT_OPTION_ID) {
			return getCompatibleCoinList(p2pCoinList, spotCoinList);
		}
		// P2P -> Cross
		if (fromSelected.id === P2P_OPTION_ID && toSelected.id === CROSS_OPTION_ID) {
			return getCompatibleCoinList(p2pCoinList, crossCoinList);
		}
		// P2P -> Isolated
		if (fromSelected.id === P2P_OPTION_ID && toSelected.id === ISOLATED_OPTION_ID) {
			return getCompatibleCoinList(p2pCoinList, isolatedCoinListByPair);
		}

		return null;
	}, [fromSelected, toSelected, spotCoinList, crossCoinList, p2pCoinList, isolatedCoinListByPair]);
	const selectedCoin = coinList?.find((coin) => coin.asset.code === selectedCoinCode);

	const selectedCoinBalance = useMemo(() => {
		if (!selectedCoin) return '0';

		let { own_balance, balance } = selectedCoin;
		balance = own_balance || balance;
		// Cross -> Any
		if (fromSelected.id === CROSS_OPTION_ID) {
			if (!crossWallet || !marginIndexPrice) {
				return '0';
			}
			const crossBalance = calculateTransform(
				balance,
				crossWallet,
				marginIndexPrice,
				selectedCoin.asset.code,
			);
			return String(crossBalance); // return string in all cases
		}

		// Isolated -> Any
		if (fromSelected.id === ISOLATED_OPTION_ID) {
			if (!isolatedCoinList || !marginIndexPrice || !selectedPair) {
				return '0';
			}
			const isolatedBalance = calculateTransformIsolate(
				balance,
				isolatedCoinList as any,
				marginIndexPrice,
				selectedCoin.asset.code,
				selectedPair.code,
			);
			return Number(isolatedBalance || 0).toFixed(10);
		}

		return balance;
	}, [selectedCoin, fromSelected, crossWallet, marginIndexPrice, isolatedCoinList, selectedPair]);

	const dispatchTransferRequest = () => {
		if (!selectedCoin) return;

		const asset_id = selectedCoin.asset.id; //eslint-disable-line
		const oldPayloadCross = {
			closeModal: onClose,
			setIsDisabled: setMarginActsLoading,
			params: { asset_id, amount },
		};
		const oldPayloadIsolated = {
			closeModal: onClose,
			setIsDisabled: setMarginActsLoading,
			params: {
				asset_id,
				amount,
				pair_id: selectedPair?.id,
				transfer: selectedPair?.code.split('_')[0] === selectedCoin.asset.code ? 'base' : 'quote',
			},
		};
		const transferPayload = {
			apiParams: { asset_id, amount },
			onSuccess: onClose,
		};
		const p2pIsolatedPayload = {
			apiParams: { asset_id, amount, pair_id: selectedPair?.id },
			onSuccess: onClose,
		} as IP2PIsolatedTransferPayload;

		// Spot -> Cross
		if (fromSelected.id === SPOT_OPTION_ID && toSelected.id === CROSS_OPTION_ID) {
			dispatch(postMarginTransferRequest({ ...oldPayloadCross, type: true }));
			setMarginActsLoading(true);
		}
		// Spot -> Isolated
		if (
			fromSelected.id === SPOT_OPTION_ID &&
			toSelected.id === ISOLATED_OPTION_ID &&
			selectedPair
		) {
			dispatch(postMarginTransferRequest({ ...oldPayloadIsolated, type: true }));
			setMarginActsLoading(true);
		}
		// Spot -> P2P
		if (fromSelected.id === SPOT_OPTION_ID && toSelected.id === P2P_OPTION_ID) {
			dispatch(transferSpotToP2PRequest(transferPayload));
		}
		// Cross -> Spot
		if (fromSelected.id === CROSS_OPTION_ID && toSelected.id === SPOT_OPTION_ID) {
			dispatch(postMarginTransferRequest({ ...oldPayloadCross, type: false }));
			setMarginActsLoading(true);
		}
		// Cross -> P2P
		if (fromSelected.id === CROSS_OPTION_ID && toSelected.id === P2P_OPTION_ID) {
			dispatch(transferCrossToP2PRequest(transferPayload));
		}
		// Isolated -> Spot
		if (
			fromSelected.id === ISOLATED_OPTION_ID &&
			toSelected.id === SPOT_OPTION_ID &&
			selectedPair
		) {
			dispatch(postMarginTransferRequest({ ...oldPayloadIsolated, type: false }));
			setMarginActsLoading(true);
		}
		// Isolated -> P2P
		if (fromSelected.id === ISOLATED_OPTION_ID && toSelected.id === P2P_OPTION_ID && selectedPair) {
			dispatch(transferIsolatedToP2PRequest(p2pIsolatedPayload));
		}
		// P2P -> Spot
		if (fromSelected.id === P2P_OPTION_ID && toSelected.id === SPOT_OPTION_ID) {
			dispatch(transferP2PToSpotRequest(transferPayload));
		}
		// P2P -> Cross
		if (fromSelected.id === P2P_OPTION_ID && toSelected.id === CROSS_OPTION_ID) {
			dispatch(transferP2PToCrossRequest(transferPayload));
		}
		// P2P -> Isolated
		if (fromSelected.id === P2P_OPTION_ID && toSelected.id === ISOLATED_OPTION_ID && selectedPair) {
			dispatch(transferP2PToIsolatedRequest(p2pIsolatedPayload));
		}
	};

	const handleSelectCoin = (event: React.MouseEvent<HTMLButtonElement>) => {
		const coinCode = event.currentTarget.dataset.code;
		if (coinCode) {
			setSelectedCoinCode(coinCode);
			setAmount('');
		}
	};

	const handleSelectPair = (e: React.MouseEvent<HTMLButtonElement>) => {
		const pairCode = e.currentTarget.dataset.code;
		if (pairCode) {
			setSelectedPairCode(pairCode);
			setAmount('');
		}
	};

	const handleReverse = () => {
		setFromSelected(toSelected);
		setToSelected(fromSelected);
	};

	const changeAmount = (e: ChangeEvent<HTMLInputElement>) => {
		if (numberValidation(e.target.value)) {
			setAmount(e.target.value);
		}
	};

	const handleMaxAmount = () => {
		setAmount(selectedCoinBalance);
	};

	const handleSubmit = () => {
		if (+amount > +selectedCoinBalance) {
			notificationContainer(
				String(L.translate('Errors.amount_is_more_than_the_available_balance')),
				'error',
			);
			return;
		}
		if (!amount) {
			notificationContainer(String(L.translate('Base.Modals.enter_amount')), 'error');
			return;
		}

		dispatchTransferRequest();
		setAmount('');
	};

	useEffect(() => {
		setAmount('');
	}, [fromSelected, toSelected, isOpen]);

	useEffect(() => {
		if (pairList?.length && !selectedPair) {
			setSelectedPairCode(pairList[0].code);
		}
	}, [pairList, selectedPair]);

	useEffect(() => {
		if (coinList?.length && !selectedCoin) {
			setSelectedCoinCode(coinList[0].asset.code);
		}
	}, [coinList, selectedCoin]);

	// Initial values revert
	useEffect(() => {
		if (!isOpen) return;

		if (initFrom) {
			setFromSelected(initFrom);
		}
		if (initTo) {
			setToSelected(initTo);
		}
		if (initPairCode) {
			setSelectedPairCode(initPairCode);
		}
		if (initCoinCode) {
			setSelectedCoinCode(initCoinCode);
		}
	}, [isOpen, initFrom, initTo, initPairCode, initCoinCode]);

	// Missing data requests
	useEffect(() => {
		if (!isOpen) return;

		if (!spotCoinList) {
			dispatch(getWalletsRequest({ disableLoading: true }));
		}
		if (!crossWallet) {
			dispatch(getMarginCrossWalletsRequest());
		}
		if (!isolatedCoinList) {
			dispatch(getMarginIsolatedWalletsRequest());
		}

		if (!p2pBalances && accountP2P) {
			dispatch(getP2PBalancesListRequest());
		}
	}, [isOpen]); // eslint-disable-line

	return (
		<Popup open={isOpen} closeOnDocumentClick onClose={onClose} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small">
						<button className="popup-close" type="button" onClick={onClose}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title">
								{String(L.translate('Trade.Margin.MarginPopup.Transfer.modal_title'))}
							</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="popup-text popup-text--center">
								<p>{String(L.translate('Trade.Margin.MarginPopup.Transfer.modal_subtitle'))}</p>
							</div>
						</div>
						<div className="transfer-form">
							<div className="transfer-form__select select-block">
								<p className="select-block__name">{String(L.translate('Base.Modals.from'))}</p>
								<WalletSelect
									options={fromOptions}
									selected={fromSelected}
									onChange={setFromSelected}
								/>
							</div>
							<div className="transfer-form__border">
								<span className="transfer-form__line" />
								<span onClick={handleReverse} className="transfer-form__icon" />
							</div>
							<div className="transfer-form__select select-block">
								<p className="select-block__name">{String(L.translate('Base.Modals.to'))}</p>
								<WalletSelect options={toOptions} selected={toSelected} onChange={setToSelected} />
							</div>
							<div className="transfer-form__border">
								<span className="transfer-form__line" />
							</div>

							{isShowPairList && (
								<SearchInputPairs
									listCoins={pairList as any}
									onSelect={handleSelectPair}
									pair={selectedPair}
								/>
							)}

							<SearchInput listCoins={coinList} onSelect={handleSelectCoin} coin={selectedCoin} />
							{fromSelected.id === CROSS_OPTION_ID &&
							Number(marginWalletsData.marginCrossWallets?.data.margin_level) < 2 ? (
								<div className="info-hint">
									<span className="info-hint__icon info-hint__icon--svg">
										<svg
											className="fill"
											width="14"
											height="20"
											viewBox="0 0 14 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.82057 17.7477C3.82097 17.9934 3.89494 18.234 4.03375 18.4387L4.71347 19.4422C4.82958 19.6137 4.98707 19.7544 5.17193 19.8518C5.35679 19.9491 5.56327 20 5.77301 20H8.22739C8.43713 20 8.64361 19.9491 8.82847 19.8518C9.01332 19.7544 9.17082 19.6137 9.28693 19.4422L9.96665 18.4387C10.1054 18.234 10.1796 17.9936 10.1798 17.7477L10.1814 16.2497H3.81858L3.82057 17.7477ZM0 6.87489C0 8.60805 0.654261 10.1893 1.7325 11.3974C2.38955 12.1338 3.41727 13.672 3.80903 14.9696C3.81062 14.9798 3.81182 14.99 3.81341 15.0001H10.1866C10.1882 14.99 10.1894 14.9802 10.191 14.9696C10.5827 13.672 11.6105 12.1338 12.2675 11.3974C13.3457 10.1893 14 8.60805 14 6.87489C14 3.07067 10.8544 -0.0116852 6.97812 3.33031e-05C2.92091 0.0121424 0 3.24098 0 6.87489ZM7 3.74995C5.24563 3.74995 3.81818 5.15188 3.81818 6.87489C3.81818 7.22019 3.53341 7.49987 3.18182 7.49987C2.83023 7.49987 2.54545 7.22019 2.54545 6.87489C2.54545 4.46244 4.54364 2.49998 7 2.49998C7.35159 2.49998 7.63636 2.77966 7.63636 3.12497C7.63636 3.47027 7.35159 3.74995 7 3.74995Z"
												fill="#E84545"
											/>
										</svg>
									</span>
									<p className="info-hint__text">
										{L.translate('Trade.Margin.MarginPopup.hint_text')}
									</p>
								</div>
							) : null}
							<div className="transfer-form__select select-block transfer-form__select--box">
								<div className="transfer-form__textbox">
									<p className="select-block__name">{String(L.translate('Base.Modals.amount'))}</p>

									<p className="transfer-form__amount">
										<span className="transfer-form__count">
											{Number(selectedCoinBalance).toFixed(8)}{' '}
										</span>
										<span>{selectedCoinCode.toUpperCase()}</span>
									</p>
								</div>
								<div className="select select--regular details-list__select">
									<div className="select__current select__current--no-padding">
										<input
											type="text"
											className="coin-val select__current"
											value={amount}
											onChange={changeAmount}
											placeholder="0"
										/>
										<button type="button" onClick={handleMaxAmount} className="select-custom-max">
											{String(L.translate('Base.Modals.max_button'))}
										</button>
									</div>
								</div>
							</div>

							<button
								type="button"
								className="button button--full-width"
								onClick={handleSubmit}
								disabled={actionsLoading || !Number(amount)}
							>
								{String(L.translate('Base.Modals.transfer_amount'))}
							</button>
						</div>
					</div>
				</div>
			</div>

			<BalanceSockets
				spot={spotSocket}
				cross={crossSocket}
				isolated={isolatedSocket}
				p2p={p2pSocket}
				marginIndex={marginIndexSocket}
			/>
		</Popup>
	);
};

export default TransferModal;
