import { FC, useState } from 'react';
import L from 'i18n-react';
import * as yup from 'yup';
import { ICardItem } from 'redux/reducers/cards/types';
import { CheckIcon, CloseIcon, EditIcon, TrashIcon } from 'assets/svg-icon';
import { IListMethodItem } from 'redux/reducers/p2p/types';
import { Field, Form, Formik } from 'formik';
import { cardHideNumber } from 'services/utils/cardHelper';
import { updateCardRequest } from 'redux/reducers/cards/reducer';
import { useDispatch } from 'react-redux';
import { IAction } from '../../PaymentCenter';

interface IPaymentItemProps {
	setAction: (data: IAction) => void;
}

const PaymentItem: FC<IListMethodItem & IPaymentItemProps> = ({
	requisite,
	payment_method,
	id,
	setAction,
}) => {
	const dispatch = useDispatch();
	const [edit, setEdit] = useState(false);

	const handleToggleEdit = () => {
		setEdit(!edit);
	};

	const handleDelete = () => {
		setAction({
			action: 'delete',
			category: 'card',
			data: {
				id,
				card_number_last4: cardHideNumber(requisite?.card_number, 12, 16),
			},
		});
	};
	const handleEditCard = (values: typeof initialValues) => {
		// dispatch(
		// 	updateCardRequest({
		// 		...values,
		// 		card_number: values.card_number.replace(/ /g, ''),
		// 		bank_name: values.name || '-',
		// 		id,
		// 	}),
		// );
		setEdit(false);
	};

	const initialValues = {
		holder_name: '',
		name: requisite.name,
		card_number: requisite.card_number?.replace(/(\d{4})/g, '$1 ') || '',
	};

	const validationSchema = yup.object().shape({
		name: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.holder_error')))
			.min(3, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
			.max(50, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		card_number: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.card_number_error')))
			.min(19, String(L.translate('Account.PaymentMethods.AddCard.card_number_error_2'))),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);
				handleEditCard(values);
			}}
		>
			{({ values, errors, submitForm }) => (
				<Form>
					<div className="tr">
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.name')}
							</p>
							{edit ? (
								<div className="p2p-edit-value">
									<div className="input-wrapper">
										<Field
											name="holder_name"
											className="input-item input-item--smaller"
											placeholder={L.translate('Account.PaymentMethods.tableNames.name')}
										/>
									</div>
								</div>
							) : (
								<p className="td-name">{requisite?.name}</p>
							)}
						</div>
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.bank_account')}
							</p>
							{edit ? (
								<div className="p2p-edit-value">
									<div className={`input-wrapper ${errors.card_number ? 'input--error' : ''}`}>
										<Field
											name="card_number"
											className="input-item input-item--smaller"
											placeholder={L.translate('Account.PaymentMethods.tableNames.bank_account')}
											value={requisite?.card_number
												.replace(/[^0-9]/g, '')
												.replace(/(\d{4})/g, '$1 ')
												.trim()}
											maxLength={19}
										/>
									</div>
								</div>
							) : (
								<p className="td-name">
									{requisite?.card_number
										? cardHideNumber(String(requisite?.card_number), 12, 16)
										: '---'}
								</p>
							)}
						</div>
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.bank_name')}
							</p>
							{edit ? (
								<div className="p2p-edit-value">
									<div className="input-wrapper">
										<Field
											name="name"
											className="input-item input-item--smaller"
											placeholder={L.translate('Account.PaymentMethods.tableNames.bank_name')}
										/>
									</div>
								</div>
							) : (
								<p className="td-name">{payment_method?.payment_name_eng}</p>
							)}
						</div>
						<div className="td td--right">
							<div className="payment-method-p2p__action">
								{edit ? (
									<>
										<button
											type="button"
											className="payment-method-p2p__button"
											onClick={submitForm}
										>
											<CheckIcon />
										</button>
										<button
											onClick={handleToggleEdit}
											type="button"
											className="payment-method-p2p__button"
										>
											<CloseIcon />
										</button>
									</>
								) : (
									<>
										{/* <button
											onClick={handleToggleEdit}
											type="button"
											className="payment-method-p2p__button"
										>
											<EditIcon />
										</button> */}
										<button
											onClick={handleDelete}
											type="button"
											className="payment-method-p2p__button"
										>
											<TrashIcon />
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default PaymentItem;
