import { notificationContainer } from 'services/utils/notificationContainer';
import L from 'i18n-react';
/* eslint-disable  @typescript-eslint/no-explicit-any */
// ==========================================:
const customResponseErrors = (errorMessage: string, type: string, response: any) => {
	const urlPath = response.config.url;
	const params: string = urlPath?.split('/').pop()?.replace('_', '/').toUpperCase() || ' ';
	if (errorMessage === 'Pair_not_available') {
		notificationContainer(String(L.translate('Notification.pair_not_available', { params })), type);
	} else {
		notificationContainer(errorMessage, type);
	}
};
export { customResponseErrors };
