import L from 'i18n-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import accessFigure1 from 'assets/images/content/access-figure1.webp';
import accessFigure2 from 'assets/images/content/access-figure2.webp';
import accessFigure1Dark from 'assets/images/content/access-figure1__dark.png';
import accessFigure2Dark from 'assets/images/content/access-figure2__dark.png';
import accessImg from 'assets/images/content/access-img.webp';
import accessImgDark from 'assets/images/content/access-img__dark.png';

const Access = () => {
	const isAuth = useSelector(getAuthIsAuthenticated);
	return (
		<div className="section section--access">
			<div className="access-figure access-figure--type1">
				<img src={accessFigure1} alt="figure" className="for-light" loading="lazy" />
				<img src={accessFigure1Dark} alt="figure" className="for-dark" loading="lazy" />
			</div>
			<div className="access-figure access-figure--type2">
				<img src={accessFigure2} alt="figure" className="for-light" loading="lazy" />
				<img src={accessFigure2Dark} alt="figure" className="for-dark" loading="lazy" />
			</div>
			<div className="container">
				<div className="access-img">
					<img src={accessImg} className="for-light" alt="graph" loading="lazy" />
					<img src={accessImgDark} className="for-dark" alt="graph" loading="lazy" />
				</div>
				<div className="info-content info-content--access ">
					<h2 className="section-title section-title--big">{L.translate('Access.title')}</h2>
					<p className="info-content__desc ">{L.translate('Access.description')}</p>
					{!isAuth && (
						<Link to="/registration" className="button button--new button--new-type2">
							{L.translate('Access.sign_up')}
							<span className="btn-icon">
								<svg
									width="15"
									height="14"
									viewBox="0 0 15 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.9766 6.1664L6.50658 1.6964L7.68492 0.518066L14.1666 6.99973L7.68492 13.4814L6.50658 12.3031L10.9766 7.83307H0.833252V6.1664H10.9766Z"
										fill="white"
									/>
								</svg>
							</span>
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default Access;
