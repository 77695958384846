import { FC } from 'react';
import L from 'i18n-react';

// ==========================================:
const UserPayment: FC = () => {
	return (
		<>
			<div className="main-content__header">
				<h2 className="section-title">{String(L.translate('Account.UserPayment.payment'))}</h2>
			</div>
			<div className="content-block content-block--padding-none">
				<nav className="tabs-nav">
					<button type="button" className="tabs-nav__item tabs-nav__item--active">
						{String(L.translate('Account.UserPayment.p2p'))}
					</button>
					<button type="button" className="tabs-nav__item">
						{String(L.translate('Account.UserPayment.buy_crypto'))}
					</button>
					<button type="button" className="tabs-nav__item">
						{String(L.translate('Account.UserPayment.withdraw'))}
					</button>
				</nav>
				<div className="content-block__main content-block__main--padding">
					<p className="desc">{String(L.translate('Account.UserPayment.desc_text'))}</p>
					<div className="payment-empty">
						<span className="payment-empty__icon icon-card-add2" />
						<span className="payment-empty__title">
							{String(L.translate('Account.UserPayment.no_payment_methods'))}
						</span>
						<button type="button" className="button button--second-type payment-empty__btn">
							{String(L.translate('Account.UserPayment.add_method'))}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default UserPayment;
