import Chart from 'components/Chart';
import L from 'i18n-react';
import useTradeBox from 'hooks/useTradeBox';
import SpotHistoryTables from '../SpotHistoryTables';
import SpotMarketHeader from '../SpotMarketHeader';
import SpotPairs from '../SpotPairs';
import SpotRecentTrades from '../SpotRecentTrades';
import TradesBox from '../TradesBox';

const SpotTradeWithTradingPairs = () => {
	const { mobileSwitchType, setMobileSwitchType, handleCloseClick, handleMobileTradeClick } =
		useTradeBox();

	return (
		<div className="content-inner">
			<div className="market">
				<div className="market-trade-outer" onClick={handleCloseClick} />
				<div className="market-block">
					<SpotMarketHeader />
				</div>
				<div className="market-content">
					<div className="market-block market-block--small">
						<SpotPairs />
						<SpotRecentTrades />
					</div>
					<div className="market-main">
						<div className="market-block">
							<div className="market-chart">
								<Chart />
							</div>
						</div>
					</div>
					<TradesBox
						mobileSwitchType={mobileSwitchType}
						setMobileSwitchType={setMobileSwitchType}
					/>
				</div>

				<SpotHistoryTables />

				<div className="market-nav-mobile">
					<button
						type="button"
						className="button button--normal-height button--full-width market-nav-mobile__btn"
						onClick={handleMobileTradeClick}
						name="buy"
					>
						{L.translate('Global.buy')}
					</button>
					<button
						type="button"
						className="button button--normal-height button--full-width button--red market-nav-mobile__btn"
						onClick={handleMobileTradeClick}
						name="sell"
					>
						{L.translate('Global.sell')}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SpotTradeWithTradingPairs;
