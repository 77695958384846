import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ICardsApi } from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
// ==========================================:
export const cards: ICardsApi = {
	getCardList: () => http.get(endpoint.cards.GET_CARDS_LIST).then((response) => response.data),
	addCard: (payload) =>
		http.post(endpoint.cards.ADD_CARD, payload).then((response) => response.data),
	updateCard: (payload) =>
		http.put(endpoint.cards.EDIT_CARD, payload).then((response) => response.data),
	deleteCard: (payload) =>
		http.delete(endpoint.cards.DELETE_CARD(String(payload))).then((response) => response.data),
	addCardFiat: async (payload) => {
		return http
			.post(
				payload.type === 'online' ? endpoint.cards.SAVED_CARD : endpoint.cards.DEPOSIT_CARD_SAVED,
				payload,
			)
			.then((response) => response.data);
	},
	getDepositFiatFee: (params) =>
		http.get(endpoint.cards.DEPOSIT_FEE, { params }).then((response) => response.data),
	createCardSaved: async (payload) => {
		return http.post(endpoint.cards.DEPOSIT_CARD_SAVED, payload).then((response) => response.data);
	},
	getWithdrawalFiatFee: (params) =>
		http.get(endpoint.cards.WITHDRAWAL_FEE, { params }).then((response) => response.data),
	getCardStatus: (params) =>
		http.get(endpoint.cards.CARD_STATUS, { params }).then((response) => response.data),
	createCardWithdrawalSaved: async (payload) => {
		return http
			.post(endpoint.cards.WITHDRAWAL_CARD_SAVED, payload)
			.then((response) => response.data);
	},
	getAccountList: () =>
		http.get(endpoint.cards.GET_ACCOUNTS_LIST).then((response) => response.data),
	deleteBank: (payload) =>
		http.delete(endpoint.cards.DELETE_ACCOUNT(String(payload))).then((response) => response.data),
	verifyCardWithdrawal: async (payload) => {
		return http
			.post(endpoint.cards.WITHDRAWAL_CARD_VERIFY, payload)
			.then((response) => response.data);
	},
	verifyCard: async (payload) => {
		return http.post(endpoint.cards.VERIFY_VARD, payload).then((response) => response.data);
	},
};
