import { FC, memo, useMemo } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import {
	getCrossWalletsList,
	getIsolatedWalletsList,
} from 'redux/reducers/marginWallets/selectors';
import { getFirstPartPairCode, getLastPartPairCode } from 'services/utils/tradingPairHelpers';
import { IWalletsDataType } from 'redux/reducers/marginWallets/types';
import { IWalletsData } from 'redux/reducers/wallets/types';
import { transformMarginsIsolatedData } from 'services/utils/transformMarginsIsolatedData';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import SwitchOrderType from '../SwitchOrderType';
import { IBuySellWrapperProps, ICurrentPairBalanceData, ISpotData, ICrossData } from './types';
import { getAuthIsAuthenticated } from '../../../../redux/reducers/auth/selectors';
// import AuthHeader from '../../../../layouts-elements/Header/AuthHeader';
// import StaticHeader from '../../../../layouts-elements/Header/StaticHeader';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

const getCurrentPairBalance = (
	spotDataValue: ISpotData | ICrossData | 0 | undefined,
	buyCode: string,
	sellCode: string,
	pair: string,
): ICurrentPairBalanceData => {
	if (pair && spotDataValue && spotDataValue[buyCode] && spotDataValue[sellCode]) {
		return {
			buy: { code: buyCode, balance: Number(spotDataValue[buyCode].balance) },
			sell: { code: sellCode, balance: Number(spotDataValue[sellCode].balance) },
		};
	}

	return {
		buy: { code: buyCode, balance: 0 },
		sell: { code: sellCode, balance: 0 },
	};
};

const currentPairBalanceIsolated = (isolated: any, pair: string) => {
	const buyCode = pair?.split('_')[1];
	const sellCode = pair?.split('_')[0];

	const currentIsolatedPair = isolated?.find((el: any) => el?.base?.pair?.[0].code === pair);

	if (pair && !!isolated?.length && currentIsolatedPair) {
		return {
			buy: { code: buyCode, balance: currentIsolatedPair?.quote?.balance },
			sell: { code: sellCode, balance: currentIsolatedPair?.base?.balance },
		};
	}
	return {
		buy: { code: buyCode, balance: 0 },
		sell: { code: sellCode, balance: 0 },
	};
};

const BuySellWrapper: FC<IBuySellWrapperProps> = ({ orderType, mode, marketType, mobOpened }) => {
	const walletsList = useSelector(getWalletsList);
	const currentPair = useSelector(getCurrentPair);
	const marginWallets = useSelector(getCrossWalletsList);
	const isolatedWallets = useSelector(getIsolatedWalletsList);
	const isAuth = useSelector(getAuthIsAuthenticated);
	const isolated = transformMarginsIsolatedData(isolatedWallets);

	const reduceWallet = (list: any) => {
		return (
			list?.length &&
			list.reduce((acc: any, el: any) => {
				acc[el.asset.code] = el;

				return acc;
			}, {} as ISpotData | ICrossData)
		);
	};

	const walletsData = useMemo(() => {
		const wallets: IWalletsData[] | IWalletsDataType[] | Array<any> | null | undefined | false =
			(marketType === 'spot' && walletsList) || (marketType === 'cross' && marginWallets);
		return reduceWallet(wallets);
	}, [walletsList, marginWallets, marketType]);

	const buyAssetCode = getLastPartPairCode(currentPair)?.toLowerCase();
	const sellAssetCode = getFirstPartPairCode(currentPair)?.toLowerCase();

	const currentPairBalance =
		marketType === 'isolated'
			? currentPairBalanceIsolated(isolated, currentPair)
			: getCurrentPairBalance(walletsData, buyAssetCode, sellAssetCode, currentPair);
	const isAuthBalance = () => {
		return (
			<>
				{mode === 'Buy'
					? convertExponentialToDecimal(currentPairBalance?.buy?.balance)
					: convertExponentialToDecimal(currentPairBalance?.sell?.balance)}{' '}
				{mode === 'Buy'
					? currentPairBalance?.buy?.code?.toUpperCase()
					: currentPairBalance?.sell?.code?.toUpperCase()}
			</>
		);
	};

	return (
		<div className={`trade-form__block ${mobOpened ? 'trade-form__block--opened' : ''}`}>
			<div className="trade-form__header">
				<span className="trade-form__text">
					{L.translate('Trade.Spot.SpotTradeBox.BuySellWrapper.available_balance')}
				</span>
				<span className="trade-form__text">{isAuth ? isAuthBalance() : 0}</span>
			</div>
			<SwitchOrderType
				orderType={orderType}
				mode={mode}
				asset={currentPairBalance}
				marketType={marketType}
			/>
		</div>
	);
};

export default BuySellWrapper;
