import { FC } from 'react';
import { IHover } from './types';

const Hover: FC<IHover> = ({ onHover, children, classHover }) => {
	return (
		<>
			<span className={`hover ${String(classHover)}`}>
				<span className="hover__no-hover table-value">{children}</span>
				<span className="hover__hover table-value">{onHover}</span>
			</span>
		</>
	);
};

export default Hover;
