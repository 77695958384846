import { FC } from 'react';
import L from 'i18n-react';
import Checkbox from 'ui/Checkbox';
import {
	getTimeParserDateFromTimestamp,
	getTimeParserTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { P2PItemProps } from './types';
import ItemType from './ItemType';

const P2PItem: FC<P2PItemProps> = ({ data, handleCheck, isCheck }) => {
	const { created_at, type, id, read, username } = data;

	return (
		<div className="notification-card">
			<div className="notification-card__header">
				<div className="notification-card__item">
					<div className="checkbox checkbox--margin-none">
						<label className="checkbox__label">
							<Checkbox
								type="checkbox"
								name={username}
								id={String(id)}
								handleClick={handleCheck}
								isChecked={isCheck.includes(id)}
							/>
							<span className=" checkbox__item">
								<span className="checkbox__item-icon">
									<span className="icon-Checkbox" />
								</span>
							</span>
						</label>
					</div>
					<span className={`notification-card__name ${read ? 'card__name-thin' : ''}`}>
						{String(L.translate(`NotificationType.${type}`))}
					</span>
					<div
						className={`notification-card__status ${
							read ? '' : 'notification-card__status--active'
						}`}
					/>
				</div>
				<span className="notification-card__date">
					{getTimeParserDateFromTimestamp(created_at)} {getTimeParserTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="notification-card__content">
				<ItemType data={data} />
			</div>
		</div>
	);
};

export default P2PItem;
