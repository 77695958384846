/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import L from 'i18n-react';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
// import { IUserSettingsData } from 'redux/reducers/settings/types';
import UserKycOndatoPopup from 'components/UserAccount/UserKycOndatoPopup';
import { Link } from 'react-router-dom';
import UserPhoneEditPopup from 'components/UserAccount/UserPhoneEditPopup';
import InfoIcon from 'ui/component-icons/InfoIcon';

// ==========================================:
const UserData: FC = () => {
	const userData = useSelector(getUserSettingsData);
	const { state } = useLocation<{ startVerification?: boolean }>();
	const history = useHistory();

	const [iosLink, setIosLink] = useState<{ url: string; params: any }>({ url: '', params: {} });
	const [openModal, setOpenModal] = useState(state?.startVerification || false);
	const closeModal = () => {
		setOpenModal(false);
		history.replace({
			pathname: '/general-settings',
			state: {},
		});
	};

	const [openModalPhone, setOpenPhoneModal] = useState(false);
	const closeModalPhone = () => setOpenPhoneModal(false);

	const showVerifyBtn = userData?.status?.name === 'unverified';

	const getKycOndatoStatusClasses = (status: string): string => {
		switch (status) {
			case 'approved':
				return 'user-data__td-desc user-data__td-desc--verify kyc';
			case 'pending':
				return 'user-data__td-desc user-data__td-desc--pending kyc';
			case 'rejected':
				return 'user-data__td-desc user-data__td-desc--not-verify kyc';
			case 'unverified':
				return 'user-data__td-desc user-data__td-desc--not-verify kyc';
			case 'on_hold':
				return 'user-data__td-desc user-data__td-desc--on-hold kyc';

			default:
				return 'user-data__td-desc';
		}
	};

	// const fullAdress = (data: IUserSettingsData | undefined) => {
	// 	if (
	// 		data?.data?.country ||
	// 		data?.data?.state ||
	// 		data?.data?.city ||
	// 		data?.data?.street ||
	// 		data?.data?.post_code
	// 	) {
	// 		const adressArrey: Array<string | null> = [
	// 			data?.data?.country,
	// 			data?.data?.state,
	// 			data?.data?.city,
	// 			data?.data?.street,
	// 			data?.data?.post_code,
	// 		];

	// 		return adressArrey.filter((adrs) => adrs).toString();
	// 	}
	// 	return '-----------------------';
	// };

	return (
		<div className="content-block content-block--flex">
			<div className="content-block__header">
				<div className="content-block__title">
					<span className="content-block__title-icon icon-profile-circle" />
					<span className="content-block__title-text">
						{String(L.translate('Account.AccountSettings.changing_user_data'))}
					</span>
				</div>
				<div className="acc-settings-type">
					<p>
						{L.translate('Account.AccountSettings.account_type')}:{' '}
						<span>
							{userData &&
								(userData.is_business === 1
									? String(L.translate('Account.AccountSettings.buisiness'))
									: String(L.translate('Account.AccountSettings.personal')))}
						</span>
					</p>
				</div>
			</div>
			<div className="content-block__main">
				<div className="user-data">
					<div className="user-data__tr">
						<div className="user-data__td">
							<span className="user-data__td-title">
								{String(L.translate('Account.AccountSettings.first_name'))}
							</span>
						</div>
						<div className="user-data__td">
							<span className="user-data__td-desc">
								{userData?.data?.first_name || '-----------------------'}
							</span>
						</div>
					</div>
					<div className="user-data__tr">
						<div className="user-data__td">
							<span className="user-data__td-title">
								{String(L.translate('Account.AccountSettings.last_name'))}
							</span>
						</div>
						<div className="user-data__td">
							<span className="user-data__td-desc">
								{userData?.data?.last_name || '-----------------------'}
							</span>
						</div>
					</div>
					{userData?.is_business === 1 && (
						<div className="user-data__tr">
							<div className="user-data__td">
								<span className="user-data__td-title">
									{String(L.translate('Account.AccountSettings.company_name'))}
								</span>
							</div>
							<div className="user-data__td">
								<span className="user-data__td-desc">
									{userData?.data?.company_name || '-----------------------'}
								</span>
							</div>
						</div>
					)}
					<div className="user-data__tr">
						<div className="user-data__td">
							<span className="user-data__td-title">
								{String(L.translate('Account.AccountSettings.country'))}
							</span>
						</div>
						<div className="user-data__td">
							<span className="user-data__td-desc">
								{userData?.data?.country || '-----------------------'}
							</span>
						</div>
					</div>
					<div className="user-data__tr">
						<div className="user-data__td">
							<span className="user-data__td-title">
								{String(L.translate('Account.AccountSettings.address'))}
							</span>
						</div>
						<div className="user-data__td">
							<span className="user-data__td-desc">
								{userData?.data?.city || '-----------------------'}
							</span>
						</div>
					</div>
					<div className="user-data__tr">
						<div className="user-data__td">
							<span className="user-data__td-title">
								{String(L.translate('Account.AccountSettings.phone'))}
							</span>
						</div>
						<div className="user-data__td">
							<span className="user-data__td-desc">
								{userData?.data?.phone || '-----------------------'}
							</span>
							{userData?.data && (
								<>
									<button
										type="button"
										className="button button--type2 button--small-height"
										onClick={() => setOpenPhoneModal((prevOpenModal) => !prevOpenModal)}
									>
										{userData?.data?.phone
											? String(L.translate('Account.AccountSettings.change_phone'))
											: String(L.translate('Account.AccountSettings.add_phone'))}
									</button>
									<UserPhoneEditPopup openModal={openModalPhone} closeModal={closeModalPhone} />
								</>
							)}
						</div>
					</div>
					<div className="user-data__tr">
						<div className="user-data__td">
							<span className="user-data__td-title">
								{String(L.translate('Account.AccountSettings.birth_date'))}
							</span>
						</div>
						<div className="user-data__td">
							<span className="user-data__td-desc">
								{(userData?.data?.dob && userData.data.dob.replaceAll('-', '/')) ||
									'-----------------------'}
							</span>
						</div>
					</div>
					<div className="user-data__tr">
						<div className="user-data__td">
							<span className="user-data__td-title">
								{String(L.translate('Account.AccountSettings.email'))}
							</span>
						</div>
						<div className="user-data__td">
							<span className="user-data__td-desc">{userData?.email}</span>
						</div>
					</div>
					<div className="user-data__tr">
						<div className="user-data__td user-data__td--start ">
							<span className="user-data__td-title">
								{String(L.translate('Account.AccountSettings.reference_code'))}
							</span>
							<span className="tooltip-item ">
								<span className="tooltip-item__icon">
									<InfoIcon color="#F9A000" />
								</span>
								<span className="tooltip tooltip--width-320 tooltip--bottom tooltip--center ">
									<span className="tooltip__text">
										<span>
											{String(L.translate('Account.AccountSettings.reference_code_tooltip'))}
										</span>
									</span>
								</span>
							</span>
						</div>
						<div className="user-data__td">
							<span className="user-data__td-desc">{userData?.payment_reference}</span>
						</div>
					</div>
					<div className="user-data__tr">
						<div className="user-data__td">
							<span className="user-data__td-title">
								{String(L.translate('Account.AccountSettings.kyc'))}
							</span>
						</div>
						<div className="user-data__td">
							<span className={getKycOndatoStatusClasses(userData?.status?.name || '')}>
								{String(L.translate(`UserKycStatus.${userData?.status?.name || 'status'}`))}
							</span>
							{showVerifyBtn && (
								<button
									type="button"
									className="button button--type2 button--small-height"
									onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
								>
									{String(
										L.translate(
											userData.kyc_applicant_id
												? 'Account.AccountSettings.update_documents'
												: 'Account.AccountSettings.start_verification',
										),
									)}
								</button>
							)}
							<UserKycOndatoPopup
								openModal={openModal}
								closeModal={closeModal}
								setIosLink={setIosLink}
								onConfirm={() => {
									history.replace({
										pathname: '/general-settings',
										state: {},
									});
								}}
							/>
							{/* {kysOndatoStartVerify &&
								(iosLink?.url?.length ? (
									<Link
										to={{ pathname: iosLink?.url }}
										target="_blank"
										className="button button--type2 button--small-height kyc-ios-btn-link"
									>
										{String(L.translate('Account.AccountSettings.go_to_ondato'))}
									</Link>
								) : (
									<>
										<button
											type="button"
											className="button button--type2 button--small-height"
											onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
										>
											{String(L.translate('Account.AccountSettings.start_verification'))}
										</button>
										<UserKycOndatoPopup
											openModal={openModal}
											closeModal={closeModal}
											setIosLink={setIosLink}
											onConfirm={() => {
												history.replace({
													pathname: '/general-settings',
													state: {},
												});
											}}
										/>
									</>
								))} */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserData;
