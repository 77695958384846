import { FC } from 'react';
import useSelect from 'hooks/useSelect';
import { IAdvrtPaymnetMethods } from 'redux/reducers/p2p/types';
import { IPaymentMethodSelect } from './types';

const PaymentMethodSelect: FC<IPaymentMethodSelect> = ({ options, value, onChange }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (item: IAdvrtPaymnetMethods) => {
		setOpen(false);
		onChange(item);
	};

	return (
		<div
			className={`select select--regular select--regular2 select--no-mt ${open ? 'active' : ''}`}
		>
			<button type="button" className="select__current" ref={triggerRef} onClick={toggleOpen}>
				{' '}
				{value?.payment_name_eng} <span className="select__arrow icon-arrow2" />
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-wrap">
					{options.map((opt) => (
						<div className="select__drop-item" onClick={() => handleSelect(opt)}>
							<div className="select__drop-link">
								<span className="select__drop-text">{opt.payment_name_eng}</span>
								{value?.id === opt.id && (
									<div className="select-check">
										<span className="select-check__icon icon-Checkbox" />
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PaymentMethodSelect;
