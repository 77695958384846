import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import L from 'i18n-react';
import { PlusIcon } from 'assets/svg-icon';
import { getWithdrawalFee } from 'redux/reducers/cards/selectors';
import { getBankTransfer } from 'redux/reducers/payments/selectors';
import Popup from 'reactjs-popup';
import { MessageSuccess } from 'components/CreateWithdrawFiat/Popups/MessageSuccess';
import { BankTransfer } from '../../../UserAccount/PaymentMethods/Popups/BankTransfer';
import { CardItem } from '../CardItem/CardItem';
import { ISelectProps, ICardCheck } from '../types';
import { ConfirmPopup } from '../Popups/ConfirmPopup';
import { MessageError } from '../Popups/MessageError';

export const AddCard: FC<ISelectProps> = ({
	setActiveView,
	selectWallet,
	amountField,
	list,
	setCheckCard,
	checkCard,
	handleSubmit,
	title,
	goBack,
}) => {
	const [openPopUp, setOpenPopUp] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [errorPopup, errorSetPopup] = useState(false);
	const [successPopup, successSetPopup] = useState(false);
	const [assetId, setAssetId] = useState<string | undefined>();
	const [message, setMessage] = useState('');
	const withdrawalFee = useSelector(getWithdrawalFee);
	const bankAccountTransfer = useSelector(getBankTransfer);
	const [accountStatus, setAccountStatus] = useState('');
	const history = useHistory();

	const [selectedBank, setSelectedBank] = useState<ICardCheck | null | undefined>(checkCard);

	const disableButton = !selectedBank;

	const handlePopUpClose = () => {
		setOpenPopUp(false);
	};

	const handleStatus = (value: string, status: string, options?: { asset_id?: string }) => {
		// setPaymentModal(false);
		setAccountStatus(status);
		setAssetId(options?.asset_id);
		status === 'success' && successSetPopup(true);
		status === 'error' && errorSetPopup(true);
	};

	const openConfirm = () => {
		successSetPopup(false);
		setShowSuccessModal(true);
	};

	const onContinue = () => {
		if (!selectedBank) {
			return;
		}
		setCheckCard?.(selectedBank);
		setActiveView('currency');
	};

	return (
		<div className="create-offer">
			<div className="create-offer__header">
				<button type="button" onClick={goBack} className="back-step">
					<div className="back-step__img">
						<span className="back-step__arrow icon-arrow" />
					</div>
					<span className="back-step__text">{L.translate('Global.back')}</span>
				</button>
			</div>
			<div className="create-offer__main create-offer__main--border-none create-offer__main--padding-big">
				<div className="create-offer__form">
					<h2 className="section-title ">{title}</h2>
					<div className="form-block">
						<div className="add-card-list add-card-list--type2">
							{list?.length
								? list.map((item) => (
										<CardItem
											key={item?.id}
											account={item}
											checked={selectedBank}
											setChecked={setSelectedBank}
										/>
								  ))
								: null}
							<div className="add-card-item">
								<button
									// onClick={() => history.push('/payment-methods')}
									onClick={() => setOpenPopUp(true)}
									type="button"
									className="add-card-new"
								>
									<span className="add-card-new__icon">
										<PlusIcon />
									</span>
									{L.translate('WithdrawStable.add_bank_account')}
								</button>
							</div>
						</div>
					</div>
					<div className="form-submit">
						<button
							// onClick={() => setShowSuccessModal(true)}
							onClick={onContinue}
							type="button"
							disabled={disableButton}
							className="button button--full-width"
						>
							{L.translate('Global.choose')}
						</button>
					</div>
				</div>
			</div>
			{/* <ConfirmPopup
				openModal={showSuccessModal}
				closeModal={() => setShowSuccessModal(false)}
				message={message}
				data={accountStatus === 'success' ? bankAccountTransfer : checkCard}
				fee={withdrawalFee}
				amount={Number(amountField)}
				selectWallet={selectWallet}
				handleSubmit={handleSubmit}
			/> */}
			<Popup open={openPopUp} onClose={handlePopUpClose} closeOnEscape lockScroll>
				<BankTransfer closeModal={handlePopUpClose} handleStatus={handleStatus} />;
			</Popup>
			<MessageSuccess
				openModal={successPopup}
				closeModal={() => successSetPopup(false)}
				text={String(bankAccountTransfer?.bank_name)}
				handleSubmit={openConfirm}
				status={accountStatus}
				assetId={assetId}
			/>
			<MessageError
				openModal={errorPopup}
				closeModal={() => errorSetPopup(false)}
				status={accountStatus}
			/>
		</div>
	);
};
