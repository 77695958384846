import { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import L from 'i18n-react';
import ForgotTwoFaFormInner from './FormInner';
import { IForgotTwofaSubmitValue, IForgotTwofaForm } from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
// ==========================================:
const ForgotTwoFaForm: FC<IForgotTwofaForm> = ({
	twoFaSubmit,
	captchaLoading,
	setCaptchaLoading,
}) => {
	const [file, setFile] = useState<null | string>(null);

	const initialValues = {
		email: '',
		message: '',
		captcha: '',
		captcha_generated: false,
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(String(L.translate('Errors.required_email')))
			.email(String(L.translate('Errors.email_format')))
			.max(60, String(L.translate('Errors.email_max_characters'))),
		message: yup
			.string()
			.required(String(L.translate('Errors.required_text')))
			.max(500, String(L.translate('Errors.text_max_characters'))),
		captcha_generated: yup.bool(),
		captcha: yup.string().when('captcha_generated', (captcha_generated, schema) => {
			if (captcha_generated) {
				return schema.required(`${String(L.translate('Errors.password_required'))}`);
			}
			return schema;
		}),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IForgotTwofaSubmitValue, { resetForm, setSubmitting }) => {
				twoFaSubmit({ ...values, file });
				setSubmitting(false);
				resetForm();
				setFile(null);
			}}
			validateOnBlur
			enableReinitialize
		>
			<Form>
				<ForgotTwoFaFormInner
					file={file}
					setFile={setFile}
					captchaLoading={captchaLoading}
					setCaptchaLoading={setCaptchaLoading}
				/>
			</Form>
		</Formik>
	);
};

export default ForgotTwoFaForm;
