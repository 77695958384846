import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import L from 'i18n-react';

import { useDispatch } from 'react-redux';
import { deleteCardRequest } from 'redux/reducers/cards/reducer';
import { deletePaymentMethodRequest } from 'redux/reducers/p2p/reducer';
import { IDelCardProps } from './type';

const DeleteCardPopUp: FC<IDelCardProps> = ({
	popUpOpen,
	popUpClose,
	category,
	data,
	typeP = '',
	method,
}) => {
	const dispatch = useDispatch();
	const handleDelete = () => {
		dispatch(
			typeP === 'p2p'
				? deletePaymentMethodRequest({ id: String(data?.id), type: method })
				: deleteCardRequest({ id: data?.id }),
		);
		popUpClose();
	};

	return (
		<Popup open={popUpOpen} onClose={popUpClose} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small ">
						<button onClick={popUpClose} type="button" className="popup-close">
							<span className="popup-close__icon icon-close" />
						</button>
						{category === 'card' &&
							(data?.card_number_last4 ? (
								<div className="popup-header">
									<p className="popup-header__title popup-header__title--w346">
										{L.translate('Account.PaymentMethods.Card.notify_delete')} &quot;
										{data?.card_number_last4}&quot;?
									</p>
								</div>
							) : (
								L.translate('Account.PaymentMethods.notifi_delete')
							))}
						{category === 'bank' && L.translate('Account.PaymentMethods.Bank.notify_delete')}
						{category === 'web' && L.translate('Account.PaymentMethods.Web.notify_delete')}
						<div className="popup-submit popup-submit--sb">
							<button
								className="button button--second-grey button--full-width"
								type="button"
								onClick={handleDelete}
							>
								{L.translate('Base.Modals.ConfirmDeletePopup.yes_button')}
							</button>
							<button className="button button--full-width" type="button" onClick={popUpClose}>
								{L.translate('Base.Modals.ConfirmDeletePopup.no_button')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default DeleteCardPopUp;
