import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import Dashboard from 'layouts/Dashboard';
import CreateDeposit from 'components/CreateDeposit';
import { getWalletsList } from 'redux/reducers/wallets/selectors';

// ==========================================:
const Deposit: FC = () => {
	const dispatch = useDispatch();
	const walletsList = useSelector(getWalletsList);

	useEffect(() => {
		if (!walletsList) {
			dispatch(getWalletsRequest());
		}
	}, [dispatch, walletsList]);

	return (
		<Dashboard title={String(L.translate('DepositCrypto.deposit'))}>
			<CreateDeposit />
		</Dashboard>
	);
};

export default Deposit;
