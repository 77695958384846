import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IConvertStore, IExchangeRateData, IConvertHistoryData } from './types';

// ==========================================:
const getConvertState = (state: IStoreState): IConvertStore => state.convert;
export const getConvert = createSelector([getConvertState], (convert: IConvertStore) => convert);

// ====================================================:
export const getExchangeRateIsLoad = createSelector(
	[getConvert],
	(convert: IConvertStore): boolean => convert.exchangeRateLoader,
);

// ====================================================:
export const getExchangeRate = createSelector(
	[getConvert],
	(convert: IConvertStore): IExchangeRateData | null => convert.exchangeRate,
);

// ====================================================:
export const getMakeExchangeIsLoad = createSelector(
	[getConvert],
	(convert: IConvertStore): boolean => convert.makeExchangeLoader,
);

// ====================================================:
export const getConvertHistory = createSelector(
	[getConvert],
	(convert: IConvertStore): IConvertHistoryData => convert.convertHistory,
);

// ====================================================:
export const getConvertHistoryIsLoad = createSelector(
	[getConvert],
	(convert: IConvertStore): boolean => convert.convertHistoryLoader,
);

export const getConvertResponseData = createSelector(
	[getConvert],
	(convert: IConvertStore) => convert.convertResponseData,
);

// ====================================================:
export const getConvertFee = createSelector(
	[getConvert],
	(convert: IConvertStore) => convert.convertFee,
);
// ====================================================:
export const checkCurrency = createSelector(
	[getConvert],
	(convert: IConvertStore) => convert.checkCurrency,
);
