/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ITransferPair,
	IWalletOption,
	TFilterCoinListByPair,
	TGetCompatibleCoinList,
	TGetPairList,
	TNormalizedCoinList,
	TNormalizeIsolatedCoinList,
	TWalletOptions,
} from './types';

export const SPOT_OPTION_ID = 1;
export const CROSS_OPTION_ID = 2;
export const ISOLATED_OPTION_ID = 3;
export const P2P_OPTION_ID = 4;
export const P2P_OPTION_WITHOUT_ID = 5;

export const walletOptions: TWalletOptions = [
	{
		id: SPOT_OPTION_ID,
		label: 'Spot',
	},
	// {
	// 	id: CROSS_OPTION_ID,                          // if enable cross uncoment this field
	// 	label: 'Cross Margin',
	// 	notAcceptIds: [3],
	// },
	// {
	// 	id: ISOLATED_OPTION_ID,
	// 	label: 'Isolated Margin',
	// 	notAcceptIds: [2],
	// },
	{
		id: P2P_OPTION_ID,
		label: 'P2P',
	},
];

const getWalletOptionById = (findId: number) => {
	return walletOptions.find(({ id }) => id === findId);
};

export const SPOT_OPTION = getWalletOptionById(SPOT_OPTION_ID) as IWalletOption;
export const CROSS_OPTION = getWalletOptionById(CROSS_OPTION_ID) as IWalletOption;
export const ISOLATED_OPTION = getWalletOptionById(ISOLATED_OPTION_ID) as IWalletOption;
export const P2P_OPTION = getWalletOptionById(P2P_OPTION_ID) as IWalletOption;
export const P2P_OPTION_WITHOUT = {
	id: P2P_OPTION_WITHOUT_ID,
	label: 'P2P',
};

const withoutP2P = (options: TWalletOptions, p2pStatus: boolean) => {
	return p2pStatus ? options : options.filter(({ id }) => id !== P2P_OPTION_ID);
};
export const getSelectOptions = (
	otherSelected: IWalletOption,
	statusId: number | undefined,
	p2p: boolean,
) => {
	if (statusId === 2) {
		return withoutP2P(walletOptions, p2p).filter(
			({ id }) => id !== otherSelected.id && !otherSelected.notAcceptIds?.includes(id),
		);
	}

	return withoutP2P(walletOptions, p2p).filter(
		({ id }) => id !== 4 && id !== otherSelected.id && !otherSelected.notAcceptIds?.includes(id),
	);
};

export const getCompatibleCoinList: TGetCompatibleCoinList = (fromCoinList, toCoinList) => {
	if (!fromCoinList || !toCoinList) {
		return null;
	}

	return fromCoinList.reduce<TNormalizedCoinList>((acc, fromCoin: any) => {
		const fromAssetId = fromCoin.asset?.id || fromCoin.asset_id;

		const sameToCoin: any = toCoinList.find((toCoin: any) => {
			const toAssetId = toCoin.asset?.id || toCoin.asset_id;
			return fromAssetId === toAssetId;
		});

		const asset = fromCoin.asset || sameToCoin?.asset;

		if (sameToCoin && asset) {
			return [...acc, { ...fromCoin, asset }];
		}
		return acc;
	}, []);
};

export const normalizeIsolatedCoinList: TNormalizeIsolatedCoinList = (isolatedCoinList) => {
	if (!isolatedCoinList) return null;

	return isolatedCoinList.map((coin) => ({
		...coin,
		pair: coin.pair[0],
		asset: coin.asset[0],
	}));
};

export const getPairList: TGetPairList = (coinList) => {
	if (!coinList) return null;

	return coinList.reduce<ITransferPair[]>((acc, coin: any) => {
		const newPair = coin.pair;
		const isInAcc = acc.some(({ id }) => id === newPair?.id);

		if (newPair && !isInAcc) {
			return [...acc, newPair];
		}
		return acc;
	}, []);
};

export const filterCoinListByPair: TFilterCoinListByPair = (coinList, pairId) => {
	if (!coinList || !pairId) {
		return null;
	}
	return coinList.filter((coin: any) => coin?.pair?.id === pairId);
};
