import { PayloadAction } from '@reduxjs/toolkit';
import L from 'i18n-react';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	getReferralStatisticRequest,
	getReferralStatisticSuccess,
	getReferralBalanceRequest,
	getReferralBalanceSuccess,
	getReferralBalanceError,
	getTransferRequest,
} from './reducer';
import {
	IReferralResponsePayload,
	IBalanceResponsePayload,
	IReferralRequestPayload,
} from './types';
/* eslint-disable */
// =============================================================:
function* getReferralStatisticWorker() {
	try {
		yield put(showLoading());
		const response: IReferralResponsePayload = yield call(api.referral.getReferralStatistic);

		yield put(getReferralStatisticSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getReferralBalanceWorker() {
	try {
		yield put(showLoading());
		const response: IBalanceResponsePayload = yield call(api.referral.getReferralBalance);

		yield put(getReferralBalanceSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getReferralBalanceError());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getTransferWorker({ payload }: PayloadAction<IReferralRequestPayload>) {
	try {
		const { asset_id, onSuccess } = payload;
		yield put(showLoading());
		yield call(api.referral.getReferralClaim, asset_id);
		onSuccess();
		yield put(getReferralBalanceRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* referralSaga() {
	yield takeEvery(getReferralStatisticRequest.type, getReferralStatisticWorker);
	yield takeEvery(getReferralBalanceRequest.type, getReferralBalanceWorker);
	yield takeEvery(getTransferRequest.type, getTransferWorker);
}
