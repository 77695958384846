import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import AddCardPopUp from 'layouts-elements/popups/AddCardPopUp';
import { PlusIcon } from 'assets/svg-icon';
import { addDepositCardRequest } from 'redux/reducers/cards/reducer';
import { IAddCardPayload, IAddCardRequest } from 'redux/reducers/cards/types';
import { useHistory } from 'react-router';
import { CardItem } from '../CardItem/CardItem';
import { IAddCardProps } from '../types';
import { ConfirmPopup } from '../Popups/ConfirmPopup';
import { FormCard } from '../FormCard/FormCard';
import { IValueProps } from '../FormCard/types';

export const AddCard: FC<IAddCardProps> = ({
	setActiveView,
	selectWallet,
	amountField,
	cardList,
	setShowModal,
	setValueSubmit,
	setCheckCard,
	checkCard,
	title,
	setChouse,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [openPopUp, setOpenPopUp] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [message, setMessage] = useState('');
	// const [valueSubmit, setValueSubmit] = useState<IAddCardPayload>({
	// 	holder_name: '',
	// 	bank_name: '',
	// 	card_number: '',
	// 	expiry_date: '',
	// 	expiry_year: '',
	// 	expiry_month: '',
	// 	cvv: '',
	// 	asset_id: '',
	// 	amount: '',
	// });

	const handleOlineCard = (values: IValueProps) => {
		setShowModal && setShowModal(true);

		const data = {
			...values,
			card_number: values.card_number.replace(/ /g, ''),
			expiry_month: values.expiry_date.split('/')[0],
			expiry_year: values.expiry_date.split('/')[1],
			amount: Number(amountField),
			asset_id: selectWallet?.asset?.id,
		};
		setValueSubmit && setValueSubmit(data);
	};

	// const handleSubmit = () => {
	// 	const params: IAddCardRequest = {
	// 		payload: {
	// 			card_number: valueSubmit?.card_number,
	// 			expiry_month: valueSubmit?.expiry_month,
	// 			expiry_year: valueSubmit?.expiry_year,
	// 			amount: Number(amountField),
	// 			cvc: valueSubmit?.cvv,
	// 			asset_id: selectWallet?.asset?.id,
	// 		},
	// 		onSuccess: () => {
	// 			history.push(`/message?type=success`);
	// 		},
	// 		onError: () => {
	// 			history.push(`/message?type=failed`);
	// 		},
	// 	};
	// 	if (valueSubmit) dispatch(addDepositCardRequest(params));
	// };

	const handlePopUpClose = () => {
		setOpenPopUp(false);
	};

	return (
		<div className="create-offer">
			<div className="create-offer__header">
				<button type="button" onClick={() => setActiveView('currency')} className="back-step">
					<div className="back-step__img">
						<span className="back-step__arrow icon-arrow" />
					</div>
					<span className="back-step__text">{L.translate('Global.back')}</span>
				</button>
			</div>
			<div className="create-offer__main create-offer__main--border-none create-offer__main--padding-big">
				<div className="create-offer__form">
					<h2 className="section-title ">{title}</h2>
					<div className="form-block">
						<div className="add-card-list add-card-list--type2">
							<div className="add-card-item">
								<button onClick={() => setOpenPopUp(true)} type="button" className="add-card-new">
									<span className="add-card-new__icon">
										<PlusIcon />
									</span>
									{L.translate('DepositFiat.AddCard.title')}
								</button>
							</div>
							{cardList?.length &&
								cardList.map((item) => (
									<CardItem
										key={item?.id}
										card={item}
										checked={checkCard}
										setChecked={setCheckCard}
									/>
								))}
							{/* <div className="add-card-item">
								<div className="radio radio--type2 radio--m0">
									<label className="radio__label">
										<input type="radio" className="hidden" name="tokens-group" checked />
										<span className="radio__item" />
										<span className="radio__text-group">
											<span className="radio__text radio__text--regular">Card online</span>
										</span>
									</label>
								</div>
							</div> */}
						</div>
					</div>
					{checkCard?.holder_name === 'online' && <FormCard onSubmit={handleOlineCard} />}
					{checkCard?.holder_name !== 'online' && cardList?.length && (
						<div className="form-submit">
							<button
								onClick={() => {
									setChouse && setChouse(true);
									setActiveView('currency');
								}}
								type="button"
								className="button button--full-width"
							>
								{L.translate('Global.choose')}
							</button>
						</div>
					)}
				</div>
			</div>

			{/* <ConfirmPopup
				openModal={showSuccessModal}
				closeModal={() => setShowSuccessModal(false)}
				message={message}
				valueSubmit={valueSubmit}
				selectWallet={selectWallet}
				handleSubmit={handleSubmit}
			/> */}

			<AddCardPopUp
				popUpOpen={openPopUp}
				popUpClose={handlePopUpClose}
				disable={false}
				name="deposit-fiat"
			/>
		</div>
	);
};
