import { FC, useEffect } from 'react';
import Account from 'layouts/Account';
import L from 'i18n-react';
import { useDispatch } from 'react-redux';
import { checkNotificationRequest } from 'redux/reducers/settings/reducer';
import UserNotifications from 'components/UserAccount/UserNotifications';

// ==========================================:
const Notifications: FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(checkNotificationRequest());
	}, [dispatch]);
	return (
		<Account title={String(L.translate('Notification.notification_title'))}>
			<UserNotifications />
		</Account>
	);
};

export default Notifications;
