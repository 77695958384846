import { FC, MouseEvent, useState } from 'react';
import L from 'i18n-react';
import P2PSortingTable from './P2PSortingTable';
import P2PSortingTableSell from './P2PSortingTableSell';

// ==========================================:
const P2POpenTrades: FC = () => {
	const [currentTab, setCurrentTab] = useState('buy');

	const tabs = {
		buy: <P2PSortingTable />,
		sell: <P2PSortingTableSell />,
	};

	const handleTab = (e: MouseEvent<HTMLButtonElement>) => {
		setCurrentTab(e.currentTarget.name);
	};

	return (
		<>
			<div className="main-content__header">
				<h2 className="section-title">{String(L.translate('P2P.OpenTrades.open_trades_title'))}</h2>
				<div className="type-switcher">
					<button
						name="buy"
						onClick={handleTab}
						className={`type-switcher__btn ${
							currentTab === 'buy' ? 'type-switcher__btn--active ' : 'type-switcher__btn--sell'
						}`}
						type="button"
					>
						{String(L.translate('P2P.OpenTrades.buy'))}
					</button>
					<button
						name="sell"
						onClick={handleTab}
						className={`type-switcher__btn ${
							currentTab === 'sell' ? 'type-switcher__btn--active ' : 'type-switcher__btn--sell'
						}`}
						type="button"
					>
						{String(L.translate('P2P.OpenTrades.sell'))}
					</button>
				</div>
			</div>

			<div className="content-block">
				<div className="balance-section">
					<div className="balance-block">{tabs[currentTab as keyof typeof tabs]}</div>
				</div>
			</div>
		</>
	);
};

export default P2POpenTrades;
