import { FC } from 'react';
import L from 'i18n-react';
import Dashboard from 'layouts/Dashboard';
import Interested from 'components/MarginWalletHistory/Interested';

// ==========================================:
const InterestedHistory: FC = () => {
	return (
		<Dashboard title={String(L.translate('PageTitles.interest'))}>
			<Interested />
		</Dashboard>
	);
};

export default InterestedHistory;
