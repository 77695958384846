// TODO: Переводы
import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getPaymentMethodLoader } from 'redux/reducers/p2p/selectors';
import { PaymentMethod } from 'assets/svg-icon';
import Loader from 'ui/Loader';
import PaymentItem from './PaymentItem';
import { ITableType } from './types';

export const CardTable: FC<ITableType> = ({ list, setAction }) => {
	const loaderPayment = useSelector(getPaymentMethodLoader);
	return (
		<div className="table table--p2p-payment table--p2p-payment-card">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p className="table-header__name">
							{L.translate('Account.PaymentMethods.tableNames.name')}
						</p>
					</div>
					<div className="td">
						<p className="table-header__name">
							{L.translate('Account.PaymentMethods.tableNames.bank_account')}
						</p>
					</div>
					<div className="td">
						<p className="table-header__name">
							{L.translate('Account.PaymentMethods.tableNames.bank_name')}
						</p>
					</div>
					<div className="td">
						<p className="table-header__name">
							{L.translate('Account.PaymentMethods.tableNames.status')}
						</p>
					</div>
					<div className="td td--right">
						<p className="table-header__name">
							{L.translate('Account.PaymentMethods.tableNames.action')}
						</p>
					</div>
				</div>
			</div>
			<div className="table-body">
				{loaderPayment && (
					<div className="open-orders-history-loader-wrapper">
						<Loader />
					</div>
				)}
				{!loaderPayment && list?.length
					? list.map((card) => <PaymentItem key={card.id} {...card} setAction={setAction} />)
					: null}
				{!loaderPayment && list?.length === 0 && (
					<div className="p2p-center-banner">
						<div className="p2p-center-banner__icon">
							<PaymentMethod />
						</div>
						<p className="p2p-center-banner__descr">
							{L.translate('Account.PaymentMethods.you_have_no_payments')}
						</p>
					</div>
				)}
			</div>
		</div>
	);
};
