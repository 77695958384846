import { FC, useState, useMemo } from 'react';
import L from 'i18n-react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import TextError from 'ui/Formik/TextError';
import { getPayments, getProfile } from 'redux/reducers/p2p/selectors';
import Input from 'ui/Formik/Input';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import DCDropdown from 'ui/DCDropdown';
import { tryOnEmpty } from 'services/utils/cardHelper';
import { IPostBankAccountPayload } from 'redux/reducers/payments/types';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { postBankAccounts } from 'redux/reducers/payments/reducer';
import { DCDropdownField } from 'ui/Formik/DCDropdownField';
import { IPropsModal } from './types';

export const BankTransfer: FC<IPropsModal> = ({ closeModal, handleStatus }) => {
	const dispatch = useDispatch();
	const user = useSelector(getProfile);
	const walletsList = useSelector(getWalletsList);

	const initialValues = {
		asset_id: '',
		bank_name: '',
		beneficiary_name: '',
		reference: '',
		iban: '',
		address: '',
		swift: '',
	};

	const fiatWalletsList = useMemo(() => {
		if (!walletsList) return [];

		return walletsList.filter((wallet) => {
			return wallet.asset.type === 'fiat' && wallet.asset.depositable;
		});
	}, [walletsList]);

	const gelAssetId = fiatWalletsList.find((item) => item.asset.code.toLocaleLowerCase() === 'gel')
		?.asset.id;

	const validationSchema = yup.object().shape({
		asset_id: yup.string().required(String(L.translate('Account.PaymentMethods.currency_error'))),
		bank_name: yup
			.string()
			.matches(
				/^[A-Za-z\s]*$/,
				String(L.translate('Account.PaymentMethods.AddCard.bank_invalid_format')),
			)
			.required(String(L.translate('Account.PaymentMethods.AddCard.bank_error')))
			.min(4, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
			.max(30, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		beneficiary_name: yup
			.string()
			.matches(
				/^[A-Za-z\s]*$/,
				String(L.translate('Account.PaymentMethods.AddCard.beneficiary_invalid_format')),
			)
			.required(String(L.translate('Account.PaymentMethods.name_error')))
			.min(4, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
			.max(30, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		iban: yup.string().required(String(L.translate('Account.PaymentMethods.iban_error'))),
		// .min(16, String(L.translate('Account.PaymentMethods.AddCard.card_number_error_2'))),
		swift: yup.string().required(String(L.translate('Account.PaymentMethods.swift_error'))),
		// .when('asset_id', (asset_id, schema) => {
		// 	if (asset_id && String(asset_id) !== String(gelAssetId)) {
		// 		return schema.required(String(L.translate('Account.PaymentMethods.swift_error')));
		// 	}
		// 	return schema;
		// })
		address: yup.string().required(String(L.translate('Account.PaymentMethods.address_error'))),
		// .when('asset_id', (asset_id, schema) => {
		// 	if (asset_id && String(asset_id) !== String(gelAssetId)) {
		// 		return schema.required(String(L.translate('Account.PaymentMethods.address_error')));
		// 	}
		// 	return schema;
		// }),

		// reference: yup.string().required(String(L.translate('Account.PaymentMethods.reference_error'))),
	});

	const handleSubmit = (value: IPostBankAccountPayload) => {
		const params = {
			payload: {
				asset_id: value.asset_id,
				...tryOnEmpty(value),
			},
			onSuccess: () => {
				handleStatus && handleStatus('account', 'success', { asset_id: value.asset_id });
				closeModal();
			},
			onError: () => {
				handleStatus && handleStatus('account', 'error');
				closeModal();
			},
		};
		dispatch(postBankAccounts(params));
	};

	return (
		<div className="popup-window popup--account">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					// const isGel = String(values.asset_id) === String(gelAssetId);
					setSubmitting(false);
					handleSubmit?.({
						...values,
						// swift: !isGel ? values.swift : undefined,
						// address: !isGel ? values.address : undefined,
					});
					resetForm();
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, values }) => {
					return (
						<Form>
							<div className="popup-window__inside">
								<div className="popup popup--medium">
									<button type="button" onClick={closeModal} className="popup-close">
										<span className="popup-close__icon icon-close" />
									</button>
									<div className="popup-header">
										<p className="popup-header__title">
											{String(L.translate('Account.PaymentMethods.BankTransfer.title'))}
										</p>
										<div className="popup-text popup-text--center">
											<p>
												{String(L.translate('Account.PaymentMethods.BankTransfer.description'))}
											</p>
										</div>
									</div>
									<div className="popup-body">
										<Field
											name="beneficiary_name"
											title={String(
												L.translate('Account.PaymentMethods.BankTransfer.beneficiary_name'),
											)}
											placeholder={String(
												L.translate('Account.PaymentMethods.BankTransfer.enter_name'),
											)}
											component={Input}
										/>
										<Field
											name="bank_name"
											title={String(L.translate('Account.PaymentMethods.BankTransfer.bank_name'))}
											placeholder={String(
												L.translate('Account.PaymentMethods.BankTransfer.enter_bank'),
											)}
											component={Input}
										/>
										<DCDropdownField
											name="asset_id"
											label={String(L.translate('Account.PaymentMethods.BankTransfer.currency'))}
											options={fiatWalletsList}
											placeholder={String(L.translate('Base.select_currency_placeholder'))}
											disabled={!fiatWalletsList.length}
										/>
										<Field
											name="iban"
											title={String(L.translate('Account.PaymentMethods.BankTransfer.iban'))}
											placeholder={String(
												L.translate('Account.PaymentMethods.BankTransfer.enter_iban'),
											)}
											component={Input}
										/>
										{/* {values.asset_id && String(values.asset_id) !== String(gelAssetId) && ( */}
										<Field
											name="swift"
											title={String(L.translate('Account.PaymentMethods.BankTransfer.swift'))}
											placeholder={String(
												L.translate('Account.PaymentMethods.BankTransfer.enter_swift'),
											)}
											component={Input}
										/>
										{/* )} */}
										<Field
											name="reference"
											title={String(L.translate('Account.PaymentMethods.BankTransfer.reference'))}
											placeholder={String(
												L.translate('Account.PaymentMethods.BankTransfer.enter_reference'),
											)}
											component={Input}
										/>
										{/* {values.asset_id && String(values.asset_id) !== String(gelAssetId) && ( */}
										<Field
											name="address"
											title={String(L.translate('Account.PaymentMethods.BankTransfer.address'))}
											placeholder={String(
												L.translate('Account.PaymentMethods.BankTransfer.enter_address'),
											)}
											component={Input}
										/>
										{/* )} */}
									</div>
									<div className="popup-submit popup-submit--more-mt popup-submit--sb">
										<button
											type="submit"
											disabled={isSubmitting}
											className="button button--full-width "
										>
											{String(L.translate('Account.PaymentMethods.BankTransfer.submit_btn'))}
										</button>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
