import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IOurPricesApi } from './types';

export const ourPrices: IOurPricesApi = {
	getFiatFees: () => http.get(endpoint.ourPrices.FIAT_FEES).then((response) => response.data),
	getCryptoFees: () => http.get(endpoint.ourPrices.CRYPTO_FEES).then((response) => response.data),
	getP2PLimitations: () => http.get(endpoint.ourPrices.P2P_FEES).then((response) => response.data),
	getFiatLimits: () => http.get(endpoint.ourPrices.FIAT_LIMITS).then((response) => response.data),
	getCryptoLimits: () =>
		http.get(endpoint.ourPrices.CRYPTO_LIMITS).then((response) => response.data),
	getConvertFees: () => http.get(endpoint.ourPrices.CONVERT_FEES).then((response) => response.data),
	getSpotFees: () => http.get(endpoint.ourPrices.SPOT_FEES).then((response) => response.data),
};
