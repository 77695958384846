import { useState, FC, ChangeEvent, useEffect, useMemo } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { numberValidation } from 'services/utils/numberValidation';
import { getIsolatedWallet, getMarginIndexPrices } from 'redux/reducers/marginWallets/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { postMarginTransferRequest } from 'redux/reducers/marginWallets/reducer';
import { calculateTransformIsolate } from 'services/utils/calculateTransfer';
import { IIsolatedWalletsData } from 'redux/reducers/marginWallets/types';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { transformMarginsIsolatedData } from 'services/utils/transformMarginsIsolatedData';
import SearchInput from 'ui/SearchInput';
import SearchInputPairs from 'ui/SearchInputPairs';
import { IChangeIsolatedTransferProps } from './types';
import IsolatedSpotSelect from './IsolatedSpotSelect';
import IsolatedMarginSelect from './IsolatedMarginSelect';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

const IsolatedTransferModal: FC<IChangeIsolatedTransferProps> = ({
	openModal,
	closeModal,
	assetCode,
	assetPair,
}) => {
	const dispatch = useDispatch();
	const [code, setCode] = useState(assetCode || 'btc');
	const [pair, setPair] = useState(assetPair || 'btc_usd');
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const marginIndexPrice: any = useSelector(getMarginIndexPrices);
	const [direction, setDirection] = useState(true);
	const [isDisabled, setIsDisabled] = useState(false);
	const [amount, setAmount] = useState('');

	const getIsolated = useSelector(getIsolatedWallet);
	const isolatedWallets = authIsAuthenticated ? getIsolated : null;

	const spotWallet: Array<IWalletItem> | null = useSelector(getWalletsList);
	const spot = authIsAuthenticated ? spotWallet : null;
	const isol: Array<IIsolatedWalletsData> | undefined | null = isolatedWallets?.data?.isolated;
	const transformIsolated = transformMarginsIsolatedData(isol);

	const handleSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
		const codeCoin = event?.currentTarget?.dataset?.code;
		if (codeCoin) {
			setCode(codeCoin);
			setAmount('');
		}
	};

	const handleSelectPair = (e: React.MouseEvent<HTMLButtonElement>) => {
		const codePair = e?.currentTarget?.dataset?.code;
		if (codePair) {
			setPair(codePair);
			setCode(codePair.split('_')[0]);
			setAmount('');
		}
	};

	const coinsIsolated = transformIsolated
		? transformIsolated?.filter(
				(el: any) =>
					el?.base?.asset[0].code === pair?.split('_')[0] &&
					el?.quote?.asset[0].code === pair?.split('_')[1],
		  )
		: [];

	const listCoins = direction
		? spot
		: [
				{ asset: { ...coinsIsolated[0]?.base?.asset[0] }, ...coinsIsolated[0] },
				{
					asset: { ...coinsIsolated[0]?.quote?.asset[0] },
					...coinsIsolated[0],
				},
		  ];

	const listMarginCoins: Array<any> | null | undefined = listCoins;
	const pairsIsolated = transformIsolated?.map((el: any) => el?.base?.pair[0]);

	const balance = listMarginCoins?.find((el) => el?.asset?.code === code)
		? listMarginCoins?.find((el) => el.asset.code === code)?.balance ||
		  listMarginCoins?.find(
				(el) => el.asset.code === code && coinsIsolated[0]?.base?.asset[0]?.code === code,
		  )?.base?.balance ||
		  listMarginCoins?.find(
				(el) => el.asset.code === code && coinsIsolated[0]?.quote?.asset[0]?.code === code,
		  )?.quote.balance
		: 0;
	const handleDirection = () => {
		setDirection((prevState) => !prevState);
	};

	const changeAmount = (e: ChangeEvent<HTMLInputElement>) => {
		if (numberValidation(e.target.value)) {
			setAmount(e.target.value);
			setIsDisabled(e.target.value !== '' && false);
		}
	};
	const pricesAll = useMemo(() => {
		const actualPrice = calculateTransformIsolate(balance, isol, marginIndexPrice, code, pair);
		return actualPrice || 0;
	}, [balance, isol, marginIndexPrice, code, pair]);

	const handleMaxAmount = () => {
		if (direction) {
			setAmount(balance);
			return;
		}
		if (isol) {
			setAmount(String(pricesAll));
		}
	};

	const handleSubmit = (): void => {
		if (+balance < +amount) {
			notificationContainer(
				String(L.translate('Errors.amount_is_more_than_the_available_balance')),
				'error',
			);
			return;
		}
		if (!amount) {
			notificationContainer(String(L.translate('Base.Modals.enter_amount')), 'error');
			return;
		}

		setIsDisabled(true);

		const payload = {
			type: Boolean(direction),
			closeModal,
			setIsDisabled,
			params: {
				asset_id: coinsIsolated?.find((el: any) => el.base.asset[0].code === code)
					? coinsIsolated?.find((el: any) => el.base.asset[0].code === code).base.asset[0].id
					: coinsIsolated?.find((el: any) => el.quote.asset[0].code === code).quote.asset[0].id,
				amount: String(amount),
				pair_id: pairsIsolated?.find((el: any) => el.code === pair).id,
				transfer: pair.split('_')[0] === code ? 'base' : 'quote',
			},
		};
		dispatch(postMarginTransferRequest(payload));
		setAmount('');
	};
	useEffect(() => {
		setCode(assetCode);
	}, [assetCode]);
	useEffect(() => {
		setAmount('');
	}, [direction]);
	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small">
						<button className="popup-close" type="button" onClick={closeModal}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title">
								{String(L.translate('Trade.Margin.MarginPopup.Transfer.modal_title'))}
							</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="popup-text popup-text--center">
								<p>{String(L.translate('Trade.Margin.MarginPopup.Transfer.modal_subtitle'))}</p>
							</div>
						</div>
						<div className="transfer-form">
							<div className="transfer-form__select select-block">
								<p className="select-block__name">{String(L.translate('Base.Modals.from'))}</p>
								<div className="select select--regular details-list__select">
									{direction ? <IsolatedSpotSelect /> : <IsolatedMarginSelect />}
								</div>
							</div>
							<div className="transfer-form__border">
								<span className="transfer-form__line" />
								<span onClick={handleDirection} className="transfer-form__icon" />
							</div>
							<div className="transfer-form__select select-block">
								<p className="select-block__name">{String(L.translate('Base.Modals.to'))}</p>
								<div className="select select--regular details-list__select">
									{direction ? <IsolatedMarginSelect /> : <IsolatedSpotSelect />}
								</div>
							</div>
							<div className="transfer-form__border">
								<span className="transfer-form__line" />
							</div>
							dfd
							<SearchInputPairs
								listCoins={pairsIsolated}
								onSelect={handleSelectPair}
								pair={pairsIsolated?.find((el: any) => el.code === pair)}
							/>
							<SearchInput
								listCoins={
									coinsIsolated && [
										{ asset: { ...coinsIsolated[0]?.base?.asset[0] } },
										{ asset: { ...coinsIsolated[0]?.quote?.asset[0] } },
									]
								}
								onSelect={handleSelect}
								coin={listMarginCoins?.find((el) => el.asset.code === code)}
							/>
							<div className="transfer-form__select select-block transfer-form__select--box">
								<div className="transfer-form__textbox">
									<p className="select-block__name">{String(L.translate('Base.Modals.amount'))}</p>

									<p className="transfer-form__amount">
										<span className="transfer-form__count">
											{direction ? balance : Number(pricesAll).toFixed(10)}{' '}
										</span>

										<span>{code.toUpperCase()}</span>
									</p>
								</div>
								<div className="select select--regular details-list__select">
									<div className="select__current">
										<input
											type="text"
											className="coin-val select__current"
											value={amount}
											onChange={changeAmount}
											placeholder="0"
										/>
										<button type="button" onClick={handleMaxAmount} className="select-custom-max">
											{String(L.translate('Base.Modals.max_button'))}
										</button>
									</div>
								</div>
							</div>
							<button
								type="button"
								className="button button--full-width"
								onClick={handleSubmit}
								disabled={isDisabled || !Number(amount)}
							>
								{String(L.translate('Base.Modals.transfer_amount'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default IsolatedTransferModal;
