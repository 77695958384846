import { FC, useState } from 'react';
import L from 'i18n-react';
import DatePicker from 'react-datepicker';
import DatepickerSearch from 'ui/DatepickerSearch';
import { IFilteredBarProps } from './types';
import 'react-datepicker/dist/react-datepicker.css';

const FilteredBar: FC<IFilteredBarProps> = ({
	period,
	setCurrentPeriod,
	handleSearch,
	handleSearchPerPeriod,
	currentClass,
}) => {
	const PERIOD = [
		String(L.translate('Period.all')),
		String(L.translate('Period.day')),
		String(L.translate('Period.week')),
		String(L.translate('Period.month')),
		String(L.translate('Period.three_months')),
	];

	const defaultData: [Date | null, Date | null] = [null, null];

	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(defaultData);
	const [startDateV, endDateV] = dateRange;

	const handlePeriodClick = (indexValue: number) => {
		handleSearchPerPeriod(indexValue);
		setCurrentPeriod(indexValue);

		setDateRange(defaultData);
	};

	return (
		<div className={currentClass}>
			<div className="date-filter">
				{PERIOD.map((element, index) => (
					<button
						key={element}
						onClick={() => handlePeriodClick(index)}
						className={`
              date-filter__btn ${period === index ? 'date-filter__btn--active' : ''}
              `}
						type="button"
					>
						{element}
					</button>
				))}
			</div>
			<div className="date-form">
				<div className="input input--margin-none date-form__input">
					<div className="input-wrapper">
						<DatePicker
							customInput={<DatepickerSearch />}
							selectsRange
							startDate={startDateV}
							endDate={endDateV}
							placeholderText={String(L.translate('SpotAndFiat.select_start_end'))}
							onChange={(update) => {
								if (Array.isArray(update)) {
									setDateRange(update);
									if (update[0] === null && update[1] === null) {
										handleSearch(update);
									}
								}
							}}
							isClearable
							dateFormat="yyyy.MM.dd"
							selectsStart
							maxDate={new Date()}
						/>
						<div className="input-icon">
							<span className="date-form__icon icon-Calendar" />
						</div>
					</div>
				</div>
				<button className="date-form__btn" type="button" onClick={() => handleSearch(dateRange)}>
					{String(L.translate('SpotAndFiat.search'))}
				</button>
			</div>
		</div>
	);
};

export default FilteredBar;
