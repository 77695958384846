import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';

// Layouts elements:
import Header from 'layouts-elements/Header';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';

// Home blocks:
import { PopusModal } from 'components/CreateWithdrawStable/Popups';

// ==========================================:
export const Withdrawal: FC = () => {
	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)}.com`}</title>
			</Helmet>

			<div className="wrapper">
				<Header />
				<Content>
					<PopusModal />
				</Content>
				<Footer />
			</div>
		</>
	);
};
