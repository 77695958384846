import { FC, ChangeEvent } from 'react';
import { cardHideNumber } from 'services/utils/cardHelper';
import visaIcon from 'assets/images/general/visa.webp';
import { TItemProps } from './type';
/// * eslint-disable */
export const CardItem: FC<TItemProps> = ({ checked, account, setChecked }) => {
	const handleClick = (e: ChangeEvent<HTMLInputElement>, item: any) => {
		const { value } = e.currentTarget;
		setChecked(value && item);
	};
	return (
		<div className="add-card-item">
			<div className="radio radio--type2 radio--m0">
				<label className="radio__label">
					<input
						type="radio"
						className="hidden"
						onChange={(e) => handleClick(e, account)}
						checked={checked?.id === account.id}
						name="card"
					/>
					<span className="radio__item" />
					<span className="radio__text-group">
						<span className="radio__text radio__text--regular">{account?.bank_name}</span>
						<span className="radio__text radio__text--small">IBAN {account.iban}</span>
					</span>
				</label>
			</div>
			{account.bank_icon && (
				<div className="add-card-item__logo add-card-item__logo--bank ">
					<img src={account.bank_icon} alt="visa" />
				</div>
			)}
		</div>
	);
};
