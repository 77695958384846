import {
	ICreateNewPasswordFormData,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IForgotPasswordPayload,
	IForgotPasswordResponse,
	IGenerateSmsResponsePayload,
	IipConfirmPayloadData,
	ILoginPayload,
	IResetTwoFaPayload,
	ILoginResponse,
	IRegistrationPayload,
	IRegistrResponse,
} from 'redux/reducers/auth/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAuthApi } from './types';

// ==========================================:
export const auth: IAuthApi = {
	login: (payload) =>
		http
			.post<ILoginPayload, { data: ILoginResponse }>(endpoint.auth.LOGIN, payload)
			.then((response) => {
				return response.data;
			}),

	registration: (payload) =>
		http
			.post<IRegistrationPayload, { data: IRegistrResponse }>(endpoint.auth.REGISTRATION, payload)
			.then((response) => response.data),

	forgotPassword: (payload) =>
		http
			.post<IForgotPasswordPayload, { data: IForgotPasswordResponse }>(
				endpoint.auth.RESET_PASSWORD,
				payload,
			)
			.then((response) => response.data),

	emailConfirm: (payload) => http.put<IEmailConfirmPayload>(endpoint.auth.EMAIL_CONFIRM, payload),

	emailResetConfirmToken: (payload) =>
		http.post<IEmailResetConfirmTokenPayload>(endpoint.auth.RESET_EMAIL_CONFIRM, {
			token: payload.token,
		}),

	ipConfirm: (payload) => http.put<IipConfirmPayloadData>(endpoint.auth.IP_CONFIRM, payload),

	newPassword: (payload) =>
		http
			.put<ICreateNewPasswordFormData, { data: IRegistrResponse }>(
				endpoint.auth.NEW_PASSWORD,
				payload,
			)
			.then((response) => response.data),

	logout: () => http.post(endpoint.auth.LOGOUT),

	generateSms: (payload) =>
		http
			.post<IGenerateSmsResponsePayload>(endpoint.auth.GENERATE_SMS, payload)
			.then((response) => response.data),
	resetTwoFa: (payload) =>
		http.post<IResetTwoFaPayload>(endpoint.auth.RESET_TWOFA, { ...payload?.data }),
	loginViaGoogle: (payload) =>
		http.get(endpoint.auth.AUTH_WITH_GOOGLE_RESPONSE(payload)).then((response) => response.data),
	loginViaFacebook: (payload) =>
		http.get(endpoint.auth.AUTH_WITH_FACEBOOK_RESPONSE(payload)).then((response) => response.data),
	loginViaApple: (payload) =>
		http.get(endpoint.auth.AUTH_WITH_APPLE_RESPONSE(payload)).then((response) => response.data),
	refreshToken: () => http.get(endpoint.auth.REFRESH_TOKEN).then((response) => response.data),
	resendRegister: (payload) =>
		http.post(endpoint.auth.RESEND_REGISTER, payload).then((response) => response.data),
};
