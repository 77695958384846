const INVALID_TOTP_CODE = 'invalid_totp_code';
const THE_TOTP_FIELD_IS_REQUIRED = 'the_totp_field_is_required';
const SMS_CODE_WRONG_OR_EXPIRED = 'sms_code_wrong_or_expired';
const THE_EMAIL_CODE_FIELD_IS_REQUIRED = 'the_email_code_field_is_required';
const EMAIL_CODE_WRONG_OR_EXPIRED = 'email_code_wrong_or_expired';

export const smsCodeErrors = [SMS_CODE_WRONG_OR_EXPIRED];

export const emailCodeErrors = [THE_EMAIL_CODE_FIELD_IS_REQUIRED, EMAIL_CODE_WRONG_OR_EXPIRED];

export const totpErrors = [THE_TOTP_FIELD_IS_REQUIRED, INVALID_TOTP_CODE];
