import { FC } from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import {
	getCurrentType,
	getCurrentCoin,
	getCurrentPayments,
	getPaymentWindow,
	getCurrencyItem,
	getProposal,
} from 'redux/reducers/p2p/selectors';
import { IConfirmToPost } from './types';

const ConfirmToPost: FC<IConfirmToPost> = ({
	onClose,
	onConfirm,
	amount,
	amountMin,
	amountMax,
}) => {
	const type = useSelector(getCurrentType);
	const currency = useSelector(getCurrentCoin);
	const payments = useSelector(getCurrentPayments);
	const paymentWindow = useSelector(getPaymentWindow);
	const currencyItem = useSelector(getCurrencyItem);
	const price = useSelector(getProposal);

	const askedPrice = price && price.asked_price ? price && price.asked_price : currency;

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--medium">
					<button type="button" className="popup-close" onClick={onClose}>
						<span className="popup-close__icon icon-close" />
					</button>
					<div className="popup-header">
						<p className="popup-header__title">
							{String(L.translate('Advertisement.ConfirmToPost.title'))}
						</p>
					</div>
					<div className="popup-body popup-body--type2">
						<div className="withdrawal-options withdrawal-options--mb16">
							<div className="withdrawal-option-row">
								<div className="withdrawal-option withdrawal-option--column">
									<span className="withdrawal-option__title">
										{String(L.translate('Advertisement.ConfirmToPost.type'))}
									</span>
									<span className="withdrawal-option__desc">{type.toUpperCase()}</span>
								</div>
								<div className="withdrawal-option withdrawal-option--column">
									<span className="withdrawal-option__title">
										{String(L.translate('Advertisement.ConfirmToPost.asset'))}
									</span>
									<span className="withdrawal-option__desc">{currency.toUpperCase()}</span>
								</div>
								<div className="withdrawal-option withdrawal-option--column withdrawal-option--right">
									<span className="withdrawal-option__title">
										{String(L.translate('Advertisement.ConfirmToPost.currency'))}
									</span>
									<span className="withdrawal-option__desc">
										{currencyItem?.code.toUpperCase()}
									</span>
								</div>
							</div>
							<div className="withdrawal-option-row">
								<div className="withdrawal-option withdrawal-option--column">
									<span className="withdrawal-option__title">
										{String(L.translate('Advertisement.ConfirmToPost.price_type'))}
									</span>
									<span className="withdrawal-option__desc">Floating</span>
								</div>
								<div className="withdrawal-option withdrawal-option--column withdrawal-option--right">
									<span className="withdrawal-option__title">
										{String(L.translate('Advertisement.ConfirmToPost.floating'))}
									</span>
									<span className="withdrawal-option__desc">
										{askedPrice} {currencyItem?.code.toUpperCase()}
									</span>
								</div>
							</div>

							<div className="withdrawal-option withdrawal-option--column">
								<span className="withdrawal-option__title">
									{String(L.translate('Advertisement.ConfirmToPost.order_limit'))}
								</span>
								<span className="withdrawal-option__desc">
									{amountMin} {currency.toUpperCase()} - <br /> {amountMax} {currency.toUpperCase()}
								</span>
							</div>
							<div className="withdrawal-option-row">
								<div className="withdrawal-option withdrawal-option--column">
									<span className="withdrawal-option__title">
										{String(L.translate('Advertisement.ConfirmToPost.payment_method'))}
									</span>
									<span className="withdrawal-option__desc">
										{payments?.map((item) => item.payment_method.payment_name_eng)?.join(', ')}
									</span>
								</div>
								<div className="withdrawal-option withdrawal-option--column withdrawal-option--right">
									<span className="withdrawal-option__title">
										{String(L.translate('Advertisement.ConfirmToPost.payment_time_limit'))}
									</span>
									<span className="withdrawal-option__desc">{paymentWindow} min</span>
								</div>
							</div>
						</div>
					</div>
					<div className="popup-submit popup-submit--sb">
						<button
							type="button"
							className="button button--second-grey button--full-width"
							onClick={onClose}
						>
							{String(L.translate('Global.cancel'))}
						</button>
						<button type="button" className="button button--full-width" onClick={onConfirm}>
							{String(L.translate('Advertisement.ConfirmToPost.confirm'))}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConfirmToPost;
