import OndatoKycStartForm from 'components/Forms/OndatoKycStartForm';
import L from 'i18n-react';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { getKycOndatoURLRequest } from 'redux/reducers/kyc/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { IIKycOndatoURLRequestData, IKysResponse } from 'redux/reducers/kyc/types';
import { getCountriesRequest } from 'redux/reducers/app/reducer';
import { getCountries } from 'redux/reducers/app/selectors';
import { dataLayer } from 'services/gtm';
import { IUserKycOndatoPopupProps } from './types';
import ModalIframe from '../ModalIframe';

const UserKycOndatoPopup: FC<IUserKycOndatoPopupProps> = ({
	openModal,
	closeModal,
	setIosLink,
	onConfirm,
}) => {
	const dispatch = useDispatch();
	const userData = useSelector(getUserSettingsData);
	const countries = useSelector(getCountries);
	const [accessData, setAccessData] = useState<IKysResponse>();

	const ondatoKycStart = (data: IIKycOndatoURLRequestData) => {
		dispatch(
			getKycOndatoURLRequest({
				...data,
				setIosLink,
				setAccess: (value) => {
					setAccessData(value);
				},
				onSuccess: () => {
					const countryName = countries?.find((item) => item.id === data.country_id)?.name;
					dataLayer.push({
						event: 'start_verification',
						// firstname: data.first_name,
						// Lastname: data.last_name,
						email: userData?.email,
						country: countryName,
						// address: data.address,
						// zip: data.post_code,
						phone: userData?.data?.phone,
						// dateofbirth: data.date_birth,
					});
				},
			}),
		);

		// closeModal();
	};

	useEffect(() => {
		dispatch(getCountriesRequest());
	}, [dispatch]);

	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup">
						<button className="popup-close" type="button" onClick={closeModal}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title">
								{String(L.translate('Account.IdentityVerification.identity_title'))}
							</p>
						</div>
						{accessData ? (
							<ModalIframe
								email={accessData.email}
								accessToken={String(accessData.accessToken.token)}
								phone={String(userData?.data?.phone)}
							/>
						) : (
							<>
								<div className="popup-body popup-body--margin-none">
									<div className="popup-text popup-text--center">
										<p>{String(L.translate('Account.IdentityVerification.fill_fields'))}</p>
									</div>
								</div>

								<OndatoKycStartForm startOndatoKycSubmit={ondatoKycStart} onConfirm={onConfirm} />
							</>
						)}
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default UserKycOndatoPopup;
