import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import queryString from 'query-string';
import L from 'i18n-react';
import { numbersAfterPoint } from 'services/utils/numberHelpers';

import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { useLocation, useHistory } from 'react-router-dom';
import { getTradePaySellRequest, setP2PTradeInitState } from 'redux/reducers/p2p/reducer';
import { getTradeStatus, getTradePaySell, getCurrentType } from 'redux/reducers/p2p/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { ILocationState } from './types';
/* eslint-disable no-debugger */
const TradePayClose: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation<ILocationState>();
	const tradeStatus = useSelector(getTradeStatus);
	const { search, state } = location;
	const user = useSelector(getUserSettingsData);
	const tradeId = queryString.parse(search).trade_id || null;
	const trade = useSelector(getTradePaySell);

	const coin = (trade && trade.asset_code && trade.asset_code.toUpperCase()) || '';
	const userId = user && user.id;
	const tradId = trade && trade.trader && trade.trader.id;
	const type =
		trade && user && user.id && trade.seller_id && user.id === trade.seller_id ? 'sell' : 'buy';
	const userTrader = tradId === userId ? 'trader' : 'not_trader';
	const closePage = String(location.pathname) === '/trade-pay-close';

	useEffect(() => {
		if (!tradeId) return;
		dispatch(getTradePaySellRequest({ trade_id: String(tradeId) }));
	}, [tradeId, dispatch]);

	useEffect(() => {
		if (state?.hasToBeCancelled) {
			return;
		}
		if (tradeStatus === 'canceled') {
			history.replace(`/trade-pay-close${search}`);
		}
		if (tradeStatus === 'waiting_for_payment') {
			history.replace(`/p2p-trading/release-tether${String(search)}`);
		}
		if (tradeStatus === 'waiting_for_confirmation') {
			history.replace(`/p2p-trading/release-tether${search}`);
		}
		if (tradeStatus === 'reviews' || tradeStatus === 'done') {
			history.replace(`/trade-pay-close${search}`);
		}
	}, [tradeStatus, history, search, state?.hasToBeCancelled]);

	const handaleClick = () => {
		dispatch(setP2PTradeInitState());
		history.push({ pathname: `/p2p-trade` });
	};

	return (
		<>
			{closePage ? (
				<>
					{tradeStatus === 'reviews' || tradeStatus === 'done' ? (
						<div className="leave-review">
							<div className="leave-review__icon">
								<svg
									width="24"
									height="19"
									viewBox="0 0 24 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M22 2.5L8.25 16.25L2 10"
										stroke="white"
										strokeWidth="4"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<h4 className="leave-review__title">
								{type === 'buy'
									? L.translate('P2P.TradePayClose.buy_success')
									: L.translate('P2P.TradePayClose.sell_success')}{' '}
							</h4>
							<p className="leave-review__text">
								<span className="leave-review__text-bold">
									{type === 'buy'
										? L.translate('P2P.TradePayClose.are_you_buying')
										: L.translate('P2P.TradePayClose.are_you_selling')}{' '}
								</span>
								<span className="leave-review__text-thin">
									{fixedCropNumber(Number(trade?.amount), numbersAfterPoint(String(coin)) || 8) ||
										0}{' '}
									{coin}
								</span>{' '}
								{type === 'buy' ? L.translate('P2P.t_0222') : L.translate('P2P.t_0221')}{' '}
								<span className="leave-review__text-bold">
									{userTrader === 'trader'
										? trade?.advertisement?.user?.username
										: trade?.trader?.username}
								</span>
							</p>

							<button
								data-name={
									userTrader === 'trader'
										? trade?.advertisement?.user?.username
										: trade?.trader?.username
								}
								onClick={handaleClick}
								className="button button--full-width"
								type="button"
							>
								{String(L.translate('P2P.back_to_p2p_center'))}
							</button>
						</div>
					) : null}
					{tradeStatus === 'canceled' || state?.hasToBeCancelled ? (
						<div className="leave-review">
							<div className="leave-review__icon leave-review__icon--error">
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M17.5 2.5L2.5 17.5"
										stroke="white"
										strokeWidth="4"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M2.5 2.5L17.5 17.5"
										stroke="white"
										strokeWidth="4"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<h4 className="leave-review__title">
								{type === 'buy'
									? L.translate('P2P.TradePayClose.buy_title')
									: L.translate('P2P.TradePayClose.sel_title')}{' '}
							</h4>
							<p className="leave-review__text">
								{type === 'buy'
									? String(L.translate('P2P.the_buyer'))
									: String(L.translate('P2P.the_seller'))}{' '}
								<span className="leave-review__text-bold">
									{String(L.translate('P2P.canceled_this_transaction'))}
								</span>
								{String(L.translate('P2P.your'))}{' '}
								<span className="leave-review__text-thin">
									{fixedCropNumber(Number(trade?.amount), numbersAfterPoint(String(coin)) || 8) ||
										0}{' '}
									{coin}{' '}
								</span>
								{String(L.translate('P2P.funds_have_been_refunded'))}
							</p>
							<button
								data-name={
									userTrader === 'trader'
										? trade?.advertisement?.user?.username
										: trade?.trader?.username
								}
								onClick={handaleClick}
								className="button button--p2p"
								type="button"
							>
								{String(L.translate('P2P.back_to_p2p_center'))}
							</button>
						</div>
					) : null}
				</>
			) : (
				''
			)}
		</>
	);
};

export default TradePayClose;
