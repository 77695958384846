/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getWalletsCryptoHistoryIsLoad } from 'redux/reducers/wallets/selectors';
import Loader from 'ui/Loader';
import CryptoTableItem from '../CryptoTableItem';
import { ICryptoTableProps } from './types';

// ==========================================:
const CryptoTable: FC<ICryptoTableProps> = ({ data }) => {
	const walletsCryptoHistoryListLoader = useSelector(getWalletsCryptoHistoryIsLoad);

	return (
		<>
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.time')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.type')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.asset')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.network')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.fee')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.amount')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.destination')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.status')}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{L.translate('Transaction.comment')}</p>
						</div>
					</div>
					<div className="td td--id">
						<div className="td-name td-name--action">
							<p className="table-body_right">{L.translate('Transaction.txlink')}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{walletsCryptoHistoryListLoader && (
					<div className="transaction-history-loader-wrapper">
						<Loader />
					</div>
				)}

				{data?.length && !walletsCryptoHistoryListLoader
					? data?.map((item, idx) => <CryptoTableItem key={idx} data={item} />)
					: null}

				{!data?.length && !walletsCryptoHistoryListLoader && (
					<div className="transaction-history-loader-wrapper">
						{L.translate('Transaction.no_crypto_transactions')}
					</div>
				)}
			</div>
		</>
	);
};

export default CryptoTable;
