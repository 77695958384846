import L from 'i18n-react';
import { FC, useCallback, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import { IHeaderDropdownList } from 'layouts-elements/Header/types';
import { HeaderDropdownItem } from './HeaderDropdownItem/HeaderDropdownItem';
import { IHeaderDropdown } from './types';

// ==================================:

const HeaderDropdown: FC<IHeaderDropdown> = (props) => {
	const {
		value,
		options,
		placeholderIcon,
		placeholder = `${String(L.translate('Menu.Select'))}`,
		onChange,
		currentClass,
		closeAfterClickOutside = true,
		selectBtnClass = '',
	} = props;

	const [open, setOpen] = useState(false);
	const node = useRef<HTMLDivElement | null>(null);

	const handleClick = () => {
		setOpen(false);
	};

	useClickOutside(node, handleClick);

	const handleChange = (selectedValue: IHeaderDropdownList): void => {
		onChange(selectedValue);
		setOpen(false);
	};

	const handleSelectToggle = useCallback((): void => {
		setOpen(!open);
	}, [open]);

	return (
		<div
			className={`select ${String(currentClass)} ${open ? 'active' : ''}`}
			ref={closeAfterClickOutside ? node : null}
		>
			<button
				type="button"
				className={`select__current ${String(selectBtnClass)}`}
				onClick={handleSelectToggle}
			>
				<span className={`select__icon ${placeholderIcon || ''}`} />
				{value || <span>{placeholder}</span>}
				<span className="select__arrow icon-arrow2" />
			</button>
			{open && (
				<div className="select__drop">
					<div className="select__drop-scroll">
						{options.map((opt: IHeaderDropdownList) => (
							<HeaderDropdownItem
								opt={opt}
								handleChange={handleChange}
								key={opt.order.toString() + String(opt.name)}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default HeaderDropdown;
