import Convert from 'layouts/Convert';
import { APP_NAME } from 'services/constants/env';
import L from 'i18n-react';
import { navList } from 'routes/routesList';

const UserAgreement = () => {
	return (
		<Convert
			title={`${String(APP_NAME)}.com ${String(L.translate('PageTitles.user_agreement'))}`}
			description={String(L.translate('MetaTags.Description.user_agreement'))}
		>
			<div className="content-wrapper content-wrapper--padding-all ">
				<div className="container">
					<div className="page-info">
						<h1 className="page-info__title">{L.translate('UserAgreement.title')}</h1>
					</div>
					<div className="static-content">
						<h2>1. {L.translate('UserAgreement.Block_1.title')}</h2>
						<p>1.1 {L.translate('UserAgreement.Block_1.text_1')}</p>
						<p>1.2 {L.translate('UserAgreement.Block_1.text_2')}</p>
						<p>1.3 {L.translate('UserAgreement.Block_1.text_3')}</p>
						<h2>2. {L.translate('UserAgreement.Block_2.title')}</h2>
						<p>
							2.1 {L.translate('UserAgreement.Block_2.text_1')}{' '}
							{/* <a href="bitanica.com" target="_blank">
								www.Bitanica.com
							</a>{' '}
							{L.translate('UserAgreement.Block_2.Text_1.1')} (
							{L.translate('UserAgreement.hereinafter')} <strong>“Bitanica,”</strong>{' '}
							<strong> ”{L.translate('UserAgreement.we')},”</strong>{' '}
							<strong> “{L.translate('UserAgreement.us')},”</strong>{' '}
							{L.translate('UserAgreement.or')}{' '}
							<strong> “{L.translate('UserAgreement.our')}”</strong>
							): */}
						</p>
						<p>
							2.2 {L.translate('UserAgreement.Block_2.Text_2.1')}
							{/* {L.translate('UserAgreement.hereinafter')} {L.translate('UserAgreement.the')}{' '}
							<strong>“{L.translate('UserAgreement.agreement')}”</strong>){' '}
							{L.translate('UserAgreement.Block_2.Text_2.2')} (
							{L.translate('UserAgreement.hereinafter')} {L.translate('UserAgreement.the')}{' '}
							<strong>“{L.translate('UserAgreement.product')}”</strong>{' '}
							{L.translate('UserAgreement.or')}{' '}
							<strong>“{L.translate('UserAgreement.website')}”</strong>). */}
						</p>
						<p>
							2.3 {L.translate('UserAgreement.Block_2.text_3')}
							{/* <strong> “{L.translate('UserAgreement.you')},”</strong>
							<strong> “{L.translate('UserAgreement.your')}”</strong>{' '}
							{L.translate('UserAgreement.or')}{' '}
							<strong> “{L.translate('UserAgreement.users')}”</strong>). */}
						</p>
						<p>
							2.4 {L.translate('UserAgreement.Block_2.Text_4.1')}{' '}
							{/* <strong>“{L.translate('UserAgreement.i_gree')},” </strong>
							{L.translate('UserAgreement.Block_2.Text_4.2')}
							<a href={navList.privacyPolicy.path} target="_blank" rel="noreferrer">
								{' '}
								{L.translate('UserAgreement.Block_2.Text_4.3')}
							</a>
							{L.translate('UserAgreement.Block_2.Text_4.4')} */}
						</p>
						<p>2.5 {L.translate('UserAgreement.Block_2.text_5')}</p>
						<p>2.6 {L.translate('UserAgreement.Block_2.text_6')}</p>
						<p>2.7 {L.translate('UserAgreement.Block_2.text_7')}</p>
						{/* <p>
							<strong>{L.translate('UserAgreement.Block_2.Text_6.1')}</strong>
						</p>
						<p>{L.translate('UserAgreement.Block_2.Text_6.2')}</p> */}
						<h2>3. {L.translate('UserAgreement.Block_3.title')}</h2>
						<p>{L.translate('UserAgreement.Block_3.text_0')}</p>
						<p>3.1 {L.translate('UserAgreement.Block_3.text_1')}</p>
						<p>3.2 {L.translate('UserAgreement.Block_3.text_2')}</p>
						<p>3.2.1 {L.translate('UserAgreement.Block_3.text_3')}</p>
						<p>3.2.2 {L.translate('UserAgreement.Block_3.text_4')}</p>
						<p>3.2.3 {L.translate('UserAgreement.Block_3.text_5')}</p>
						<p>3.3 {L.translate('UserAgreement.Block_3.text_6')}</p>
						<p>3.4 {L.translate('UserAgreement.Block_3.text_7')}</p>
						{/* <p>3.8 {L.translate('UserAgreement.Block_3.text_8')}</p> */}
						<h2>4. {L.translate('UserAgreement.Block_4.title')}</h2>
						<p>4.1 {L.translate('UserAgreement.Block_4.text_1')}</p>
						<p>4.2 {L.translate('UserAgreement.Block_4.text_2')}</p>
						<p>4.3 {L.translate('UserAgreement.Block_4.text_3')}</p>
						<p>4.4 {L.translate('UserAgreement.Block_4.text_4')}</p>
						<p>4.5 {L.translate('UserAgreement.Block_4.text_5')}</p>
						{/* <p>4.6 {L.translate('UserAgreement.Block_4.text_6')}</p>
						<p>
							({L.translate('Alphabet.a')}) {L.translate('UserAgreement.Block_4.Text_6.a')}
						</p>
						<p>
							({L.translate('Alphabet.b')}) {L.translate('UserAgreement.Block_4.Text_6.b')}
						</p>
						<p>
							({L.translate('Alphabet.c')}) {L.translate('UserAgreement.Block_4.Text_6.c')}
						</p>
						<p>
							({L.translate('Alphabet.d')}) {L.translate('UserAgreement.Block_4.Text_6.d')}
						</p>
						<p>
							({L.translate('Alphabet.e')}) {L.translate('UserAgreement.Block_4.Text_6.e')}
						</p>
						<p>
							({L.translate('Alphabet.f')}) {L.translate('UserAgreement.Block_4.Text_6.f')}
						</p>
						<p>
							({L.translate('Alphabet.g')}) {L.translate('UserAgreement.Block_4.Text_6.g')}
						</p>
						<p>
							({L.translate('Alphabet.h')}) {L.translate('UserAgreement.Block_4.Text_6.h')}
						</p> */}
						<h2>5. {L.translate('UserAgreement.Block_5.title')}</h2>
						<p>5.1 {L.translate('UserAgreement.Block_5.text_1')}</p>
						{/* <p>5.2.1 {L.translate('UserAgreement.Block_5.Text_2.1')}</p> */}
						{/* <p>5.2.2 {L.translate('UserAgreement.Block_5.Text_2.2')}</p> */}
						<p>5.2 {L.translate('UserAgreement.Block_5.text_3')}</p>
						<p>5.3 {L.translate('UserAgreement.Block_5.text_4')}</p>
						{/* <p>
							5.5 {L.translate('UserAgreement.Block_5.Text_5.1')}{' '}
							<a href="mailto:info@Bitanica.com">info@Bitanica.com </a>of
							{L.translate('UserAgreement.Block_5.Text_5.2')}
						</p> */}
						<p>5.4 {L.translate('UserAgreement.Block_5.text_6')}</p>
						<p>5.5 {L.translate('UserAgreement.Block_5.text_7')}</p>
						<p>5.6 {L.translate('UserAgreement.Block_5.text_8')}</p>
						{/* <p>5.9 {L.translate('UserAgreement.Block_5.text_9')}</p>
						<p>5.10 {L.translate('UserAgreement.Block_5.text_10')}</p>
						<p>5.11 {L.translate('UserAgreement.Block_5.text_11')}</p> */}
						<h2>6. {L.translate('UserAgreement.Block_6.title')}</h2>
						<p>6‍.1 {L.translate('UserAgreement.Block_6.text_1')}</p>
						<p>6.2 {L.translate('UserAgreement.Block_6.text_2')}</p>
						<p>6.3 {L.translate('UserAgreement.Block_6.text_3')}</p>
						<p>6.4 {L.translate('UserAgreement.Block_6.text_4')}</p>
						<p>6.5 {L.translate('UserAgreement.Block_6.text_5')}</p>
						<p>6.6 {L.translate('UserAgreement.Block_6.text_6')}</p>
						<p>6.7 {L.translate('UserAgreement.Block_6.text_7')}</p>
						<p>6.8 {L.translate('UserAgreement.Block_6.text_8')}</p>
						<p>6.9 {L.translate('UserAgreement.Block_6.text_9')}</p>
						<p>6.10 {L.translate('UserAgreement.Block_6.text_10')}</p>
						<h2>7. {L.translate('UserAgreement.Block_7.title')}</h2>
						<p>7.1 {L.translate('UserAgreement.Block_7.text_1')}</p>
						<p>7.2 {L.translate('UserAgreement.Block_7.text_2')}</p>
						<p>7.3 {L.translate('UserAgreement.Block_7.text_3')}</p>
						<p>7.4 {L.translate('UserAgreement.Block_7.text_4')}</p>
						<p>7.5 {L.translate('UserAgreement.Block_7.text_5')}</p>
						<p>7.6 {L.translate('UserAgreement.Block_7.text_6')}</p>
						<p>7.7 {L.translate('UserAgreement.Block_7.text_7')}</p>
						<p>7.8 {L.translate('UserAgreement.Block_7.text_8')}</p>
						<p>7.9 {L.translate('UserAgreement.Block_7.text_9')}</p>
						<p>7.9.1 {L.translate('UserAgreement.Block_7.text_91')}</p>
						<p>7.9.2 {L.translate('UserAgreement.Block_7.text_92')}</p>
						<p>7.10 {L.translate('UserAgreement.Block_7.text_10')}</p>
						<p>7.11 {L.translate('UserAgreement.Block_7.text_11')}</p>
						<p>7.12 {L.translate('UserAgreement.Block_7.text_12')}</p>
						<p>7.13 {L.translate('UserAgreement.Block_7.text_13')}</p>
						<p>7.14 {L.translate('UserAgreement.Block_7.text_14')}</p>
						<p>7.15 {L.translate('UserAgreement.Block_7.text_15')}</p>
						<p>7.16 {L.translate('UserAgreement.Block_7.text_16')}</p>
						<p>7.17 {L.translate('UserAgreement.Block_7.text_17')}</p>
						<h2>8. {L.translate('UserAgreement.Block_8.title')}</h2>
						<p>8.1 {L.translate('UserAgreement.Block_8.text_1')}</p>
						<p>8.2 {L.translate('UserAgreement.Block_8.text_2')}</p>
						<p>8.3 {L.translate('UserAgreement.Block_8.text_3')}</p>
						<p>8.4 {L.translate('UserAgreement.Block_8.text_4')}</p>
						<p>8.5 {L.translate('UserAgreement.Block_8.text_5')}</p>
						<p>8.6 {L.translate('UserAgreement.Block_8.text_6')}</p>
						<p>8.6.1 {L.translate('UserAgreement.Block_8.text_61')}</p>
						<p>8.6.2 {L.translate('UserAgreement.Block_8.text_62')}</p>
						<p>8.7 {L.translate('UserAgreement.Block_8.text_7')}</p>
						<h2>9. {L.translate('UserAgreement.Block_9.title')}</h2>
						<p>9.1 {L.translate('UserAgreement.Block_9.text_1')}</p>
						<p>9.2 {L.translate('UserAgreement.Block_9.text_2')}</p>
						<p>9.3 {L.translate('UserAgreement.Block_9.text_3')}</p>
						<p>9.4 {L.translate('UserAgreement.Block_9.text_4')}</p>
						<p>9.5 {L.translate('UserAgreement.Block_9.text_5')}</p>
						<p>9.6 {L.translate('UserAgreement.Block_9.text_6')}</p>
						<p>9.7 {L.translate('UserAgreement.Block_9.text_7')}</p>
						<p>9.8 {L.translate('UserAgreement.Block_9.text_8')}</p>
						<p>9.9 {L.translate('UserAgreement.Block_9.text_9')}</p>
						<p>9.10 {L.translate('UserAgreement.Block_9.text_10')}</p>
						<p>9.11 {L.translate('UserAgreement.Block_9.text_11')}</p>
						<p>9.12 {L.translate('UserAgreement.Block_9.text_12')}</p>
						<p>9.13 {L.translate('UserAgreement.Block_9.text_13')}</p>
						<h2>10. {L.translate('UserAgreement.Block_10.title')}</h2>
						<p>10.1. {L.translate('UserAgreement.Block_10.text_1')}</p>
						<p>10.1.1 {L.translate('UserAgreement.Block_10.text_11')}</p>
						<p>10.1.2 {L.translate('UserAgreement.Block_10.text_12')}</p>
						<p>10.2. {L.translate('UserAgreement.Block_10.text_2')}</p>
						<p>10.3. {L.translate('UserAgreement.Block_10.text_3')}</p>
						<p>10.4. {L.translate('UserAgreement.Block_10.text_4')}</p>
						<p>10.5. {L.translate('UserAgreement.Block_10.text_5')}</p>
						<p>10.6. {L.translate('UserAgreement.Block_10.text_6')}</p>
						<p>10.7. {L.translate('UserAgreement.Block_10.text_7')}</p>

						<strong>{L.translate('UserAgreement.llc')}</strong>
						<p>{L.translate('UserAgreement.legal_address')}</p>
						<p>{L.translate('UserAgreement.actual_address')}</p>
						<p>{L.translate('UserAgreement.contuct_information')}</p>
						<p>{L.translate('UserAgreement.website')}</p>
						<strong>{L.translate('UserAgreement.bank')}</strong>
						<p>
							<i>{L.translate('UserAgreement.supervisury')}</i>
						</p>
					</div>
				</div>
			</div>
		</Convert>
	);
};

export default UserAgreement;
