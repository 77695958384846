/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getMarginCallHistoryRequest } from 'redux/reducers/marginWallets/reducer';
import Pagination from 'ui/Pagination';
import {
	getMarginCallHistory,
	getMarginHistoryIsLoader,
} from 'redux/reducers/marginWallets/selectors';
/* eslint-disable no-debugger */
import Loader from 'ui/Loader';
import MarginCallItem from '../MarginCallItem';

const MarginCallIsolatedTable: FC = () => {
	const callList = useSelector(getMarginCallHistory);
	const marginHistoryLoad = useSelector(getMarginHistoryIsLoader);
	const totalPages = callList?.last_page || 1;

	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		if (authIsAuthenticated) {
			dispatch(
				getMarginCallHistoryRequest({
					params: {
						per_page: 10,
						current_page: currentPage,
					},
					isolated: true,
				}),
			);
		}
	}, [dispatch, authIsAuthenticated, currentPage]);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<div className="table table--margin-calls-iso table--wallet-type">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.MarginCalls.pair')}</span>
						</div>
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.MarginCalls.date')}</span>
						</div>
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.MarginCalls.type')}</span>
						</div>
						<div className="td">
							<span className="td-title">
								{L.translate('Margin.Tables.MarginCalls.send_status')}
							</span>
						</div>
					</div>
				</div>
				<div className="table-body">
					{marginHistoryLoad && (
						<div className="open-orders-history-loader-wrapper">
							<Loader />
						</div>
					)}
					{!marginHistoryLoad && callList?.data?.length
						? callList?.data?.map((item) => <MarginCallItem key={item.id} data={item} />)
						: null}

					{!marginHistoryLoad && callList?.data?.length === 0 && (
						<span className="table-empty">
							{String(L.translate('Margin.Tables.MarginCalls.no_orders_text'))}
						</span>
					)}
				</div>
			</div>
			{totalPages > 1 && (
				<Pagination
					pageCount={totalPages}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default MarginCallIsolatedTable;
