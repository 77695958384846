import { FC, ChangeEvent, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
	getPaymentMethodList,
	getCurrentPayments,
	getUserAdvert,
} from 'redux/reducers/p2p/selectors';

import {
	setPaymentWindow,
	getPaymentMethodRequest,
	setCurrentPayments,
} from 'redux/reducers/p2p/reducer';
import { IListMethodItem } from 'redux/reducers/p2p/types';
import Chips from 'ui/Chips';

import AddPaymentMethod from './AddPaymentMethod';
import TimeLimitSelect from './TimeLimitSelect';
import { IPaymentBox } from './type';
/* eslint-disable no-debugger */
const PAFPaymentBox: FC<IPaymentBox> = ({
	paymentWindow,
	setDescription,
	description,
	dataLoaded,
	setDataLoaded,
}) => {
	const dispatch = useDispatch();

	const advertData = useSelector(getUserAdvert);

	const params = useParams<{ id?: string }>();

	const payments = useSelector(getCurrentPayments);

	const paymentsList = useSelector(getPaymentMethodList);

	console.log('paymentsList', paymentsList);
	const togglePayments = (newItem: IListMethodItem) => {
		const newPayments = payments.map((item) => item.id).includes(newItem.id)
			? payments.filter((item) => item.id !== newItem.id)
			: [...payments, newItem];

		dispatch(setCurrentPayments(newPayments));
	};

	const popPayMethId = (id: number) => {
		const newPayments = payments.filter((item) => item.id !== id);

		dispatch(setCurrentPayments(newPayments));
	};

	const handlePaymentWindow = (value: number) => {
		if (value === paymentWindow) return;
		dispatch(setPaymentWindow(Number(value)));
	};

	const changeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target;
		if (value.length < 1023) {
			setDescription(value);
		}
	};

	const paymentMethodChips =
		payments?.map(({ id, payment_method }) => ({
			id,
			name: payment_method.payment_name_eng,
		})) || [];

	useEffect(() => {
		dispatch(getPaymentMethodRequest({}));
	}, [dispatch]);

	useEffect(() => {
		const notFoundIds =
			paymentsList &&
			payments
				.filter((item) => !paymentsList.find((lstItem) => lstItem.id === item.id))
				.map((item) => item.id);
		if (notFoundIds && notFoundIds.length > 0) {
			const newPayments = payments.filter((item) => !notFoundIds.includes(item.id));
			dispatch(setCurrentPayments(newPayments));
		}
	}, [dispatch, payments, paymentsList]);

	useEffect(() => {
		if (
			advertData &&
			params?.id &&
			params?.id === String(advertData?.id) &&
			!!paymentsList &&
			!dataLoaded
		) {
			const selectedPayments = advertData.payment_details.reduce((acc: IListMethodItem[], curr) => {
				const currentPayment = paymentsList?.find((item2) => item2.payment_method_id === curr.id);
				if (currentPayment) {
					return [...acc, currentPayment];
				}
				return acc;
			}, []);
			dispatch(setCurrentPayments(selectedPayments));
			if (advertData.payment_window) {
				dispatch(setPaymentWindow(advertData.payment_window));
			}
			if (advertData.description) {
				setDescription(advertData.description);
			}
			setDataLoaded?.(true);
		}
	}, [advertData, dataLoaded, dispatch, params?.id, paymentsList, setDataLoaded, setDescription]);

	return (
		<div className="create-offer__form create-offer__form--bigger">
			<div className="form-block">
				<span className="block-label">
					{' '}
					{String(L.translate('Advertisement.payment_details'))}{' '}
					<div className="tooltip-wrap">
						<div className="tooltip-item tooltip-item--static">
							<span className="block-label__icon icon-info-circle" />
							<span className="tooltip tooltip--bottom tooltip--left tooltip--arrow-top-left">
								<span className="tooltip__text">
									<span>{String(L.translate('Advertisement.paragraph_1'))}</span>
									<span>{String(L.translate('Advertisement.paragraph_2'))}</span>
									<span>{String(L.translate('Advertisement.paragraph_3'))}</span>
									<span>{String(L.translate('Advertisement.paragraph_4'))}</span>
								</span>
							</span>
						</div>
					</div>
				</span>
				<div className="details-list">
					<div className="details-list__row details-list__row--type2  details-list__row--extra-align ">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.payment_method'))}
							</span>
						</div>
						<div className="details-list__right">
							<div className="payment-bank-list">
								<Chips items={paymentMethodChips} onRemove={popPayMethId} />
								{payments.length < 3 && (
									<AddPaymentMethod
										items={paymentsList || []}
										selectedItems={payments}
										onToggle={togglePayments}
									/>
								)}
							</div>
							<p className="details-info">
								{String(L.translate('Advertisement.payment_method_descr'))}
							</p>
						</div>
					</div>
					<div className="details-list__row details-list__row--type2 details-list__row--center">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.payment_title'))}
							</span>
						</div>
						<div className="details-list__right">
							<TimeLimitSelect value={paymentWindow} onChange={handlePaymentWindow} />
						</div>
					</div>
					<div className="details-list__row details-list__row--column">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.ad_description'))}
							</span>
						</div>
						<div className="details-list__right">
							<div className="input">
								<div className="input-wrapper">
									<div className="textarea">
										<textarea
											className="input-item input-item--textarea"
											placeholder={String(L.translate('Advertisement.ad_desc_placeholder'))}
											value={description}
											onChange={changeDescription}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PAFPaymentBox;
