import L from 'i18n-react';
import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import useWindowResize from 'hooks/useWindowResize';
import MainNav from 'layouts-elements/MainNav';
import AuthHeader from 'layouts-elements/Header/AuthHeader';
import StaticHeader from 'layouts-elements/Header/StaticHeader';
import AppLogo from 'ui/AppLogo';
import MobileHeader from 'layouts-elements/Header/MobileHeader';
import LangSwitcher from './LangSwitcher/LangSwitcher';
import { IHeader } from './types';
import ThemeSwitch from './ThemeSwitch';

// ==========================================:
const Header: FC<IHeader> = ({ isNew }) => {
	const isAuth = useSelector(getAuthIsAuthenticated);
	const currentPair = useSelector(getCurrentPair);
	const userData = useSelector(getUserSettingsData);
	const transformCurrentPair = currentPair?.toUpperCase();

	const isUnverified =
		userData?.status?.name === 'unverified' || userData?.status?.name === 'rejected';

	const { width } = useWindowResize();
	const mobileBreakPoint = !!width && width <= 1379;

	const accountList = [
		{
			order: 1,
			name: `${String(L.translate('Menu.Account.account_settings'))}`,
			icon: 'icon-settings',
			link: '/general-settings',
		},
		{
			order: 2,
			name: `Partners & Benefits`,
			icon: 'svg',
			link: '/invite-earn',
		},
		{
			order: 3,
			name: `${String(L.translate('Menu.Account.log_out'))}`,
			icon: 'icon-Logout',
			link: '/logout',
		},
	];

	const walletList = [
		{
			order: 1,
			name: `${String(L.translate('Menu.Wallet.fiat_and_spot'))}`,
			icon: 'icon-money-recive',
			link: '/fiat-and-spot',
		},
		// {
		// 	order: 3,
		// 	name: `${String(L.translate('Menu.Wallet.margin'))}`,
		// 	icon: 'icon-trade',
		// 	link: `/margin`,
		// },
		{
			order: 2,
			name: 'P2P',
			icon: 'icon-user',
			link: '/p2p',
		},
		{
			order: 4,
			name: `${String(L.translate('Menu.Wallet.transaction_history'))}`,
			icon: 'icon-Paper',
			link: '/transaction-history',
		},
	];

	const ordersList = [
		{
			order: 1,
			name: `${String(L.translate('Menu.Orders.spot_order'))}`,
			icon: 'icon-money-recive',
			link: '/open-orders',
		},
		// {
		// 	order: 2,
		// 	name: `${String(L.translate('Menu.Orders.margin_order'))}`,
		// 	icon: 'icon-trade',
		// 	link: `/margin-open-orders`,
		// },
		{
			order: 3,
			name: `${String(L.translate('Menu.Orders.p2p_order'))}`,
			icon: 'icon-user',
			link: `/p2p-open-trades`,
		},
	];

	const mainNavList = [
		{
			order: 1,
			name: String(L.translate('Menu.Trade.convert')),
			icon: 'icon-trade',
			link: '/convert',
		},
		{
			order: 2,
			name: String(L.translate('Menu.Trade.spot')),
			icon: 'icon-money-recive',
			link: `/spot/${String(transformCurrentPair)}`,
		},
		// {
		// 	order: 2,
		// 	name: String(L.translate('Menu.Trade.margin')),
		// 	icon: 'icon-trade',
		// 	link: `/MarginTrading/${String(transformCurrentPair)}?type=cross`,
		// },
		{
			order: 4,
			name: String(L.translate('Menu.Trade.p2p')),
			icon: 'icon-user',
			link: '/p2p-trade',
		},
	];

	return (
		<header className={`header ${isNew ? 'header--type2' : ''}`}>
			{!mobileBreakPoint && (
				<>
					<div className="header__side">
						<AppLogo isNew={isNew} />
						<MainNav mainNavList={mainNavList} />
					</div>
					<div className="header__side header__side--right">
						{isAuth ? (
							<AuthHeader
								walletList={walletList}
								ordersList={ordersList}
								accountList={accountList}
							/>
						) : (
							<StaticHeader />
						)}
						<ThemeSwitch />
						<LangSwitcher />
					</div>
				</>
			)}

			{mobileBreakPoint && (
				<>
					<AppLogo isNew={isNew} />
					<div className="header__side">
						{isAuth && (
							<Link
								to="/general-settings"
								className={isUnverified ? 'select__current--unverified' : ''}
							>
								<span className="select__icon icon-profile-circle" />
							</Link>
						)}
						<MobileHeader
							walletList={walletList}
							mainNavList={mainNavList}
							ordersList={ordersList}
						/>
					</div>
				</>
			)}
		</header>
	);
};

export default Header;
