import { useState, useEffect, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { getAssetPairs } from 'redux/reducers/assetPairs/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';

const useTradeBox = () => {
	const [mobileSwitchType, setMobileSwitchType] = useState('buy');
	const [tradeBoxOpened, setTradeBoxOpened] = useState(false);
	const tradeBlock = document.querySelector('.trade-form-box');
	const wrapper = document.querySelector('.market-trade-outer');
	const handleMobileTradeClick = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setMobileSwitchType(name);
		setTradeBoxOpened(true);
	};

	const handleCloseClick = () => {
		setTradeBoxOpened(false);
	};

	useEffect(() => {
		if (tradeBoxOpened) {
			tradeBlock?.classList.add('trade-form-box--opened');
			wrapper?.classList.add('market-trade-outer--active');
			document.body.style.overflow = 'hidden';
		} else {
			tradeBlock?.classList.remove('trade-form-box--opened');
			wrapper?.classList.remove('market-trade-outer--active');
			document.body.style.overflow = '';
		}
		return () => {
			tradeBlock?.classList.remove('trade-form-box--opened');
			wrapper?.classList.remove('market-trade-outer--active');
			document.body.style.overflow = '';
		};
	}, [tradeBlock?.classList, tradeBoxOpened, wrapper?.classList]);

	return {
		mobileSwitchType,
		setMobileSwitchType,
		handleMobileTradeClick,
		handleCloseClick,
	};
};

export default useTradeBox;
