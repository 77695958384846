/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import L from 'i18n-react';
// import L from 'i18n-react';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';

import { IBorrowedItemProps } from './types';
/* eslint-disable no-debugger */
const MarginCallItem: FC<IBorrowedItemProps> = ({ data }) => {
	const { created_at, pair, status, type } = data;

	const getClassByStatus = (typeValue: string): string => {
		if (typeValue?.includes('canseled')) return 'td-name td-name--regular td-name--red';

		if (typeValue?.includes('sent')) return 'td-name td-name--regular td-name--green';

		return 'td-name';
	};

	console.log(data);

	return (
		<div className="tr">
			{pair && (
				<div className="td">
					<p className="td-hidden-name">Pair</p>
					<span className="td-name td-name--regular td-name--fw500">
						{transformPairCode(pair?.code)}
					</span>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<span className="td-name td-name--regular td-name--light">
					{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<span className="td-name td-name--regular">
					{type?.includes('email') ? String(L.translate('CallType.email')) : type}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<span className={getClassByStatus(String(status))}>
					{status?.includes('sent')
						? String(L.translate('CallStatus.sent'))
						: String(L.translate('CallStatus.canceled'))}
				</span>
			</div>
		</div>
	);
};

export default MarginCallItem;
