import L from 'i18n-react';
import { ChangeEvent, FC } from 'react';
import Popup from 'reactjs-popup';
import { IModalPropsCVC } from './types';

const CvcVerify: FC<IModalPropsCVC> = ({
	openModal,
	closeModal,
	cardNumber,
	cvc,
	setCvc,
	subtitle,
	onSubmit,
	disableBtn,
}) => {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		if ((value && value?.length > 3) || !/^\d*$/.test(value)) return;
		setCvc(value);
	};
	const getFormattedCardNumber = () => {
		if (!cardNumber) return '';
		const firstPart = cardNumber.slice(0, 4);
		const lastPart = cardNumber.slice(cardNumber.length - 4, cardNumber.length);
		return `${firstPart} **** ***** ${lastPart}`;
	};
	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<button type="button" className="popup-close" onClick={closeModal}>
					<span className="popup-close__icon icon-close" />
				</button>
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<div className="popup-header">
							<p className="popup-header__title">{L.translate('DepositFiat.CvcVerify.title')}</p>
							<div className="popup-text popup-text--center">
								<p>{subtitle}</p>
							</div>
						</div>
						<div className="popup-body popup-body--type3 ">
							<div className="input">
								<p className="input__name">
									{L.translate('DepositFiat.CvcVerify.Fields.card_number')}
								</p>
								<div className="input-wrapper">
									<input
										className="input-item input-item--right-double-icon"
										type="text"
										placeholder={String(
											L.translate('DepositFiat.CvcVerify.Placeholders.card_number'),
										)}
										value={getFormattedCardNumber()}
										disabled
									/>
								</div>
							</div>
							<div className="input input--margin-none">
								<p className="input__name">
									{L.translate('DepositFiat.CvcVerify.Fields.security_code')}
								</p>
								<div className="input-wrapper">
									<input
										onChange={handleChange}
										value={cvc}
										className="input-item"
										type="text"
										placeholder={String(
											L.translate('DepositFiat.CvcVerify.Placeholders.security_code'),
										)}
									/>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--sb">
							<button
								disabled={
									cvc === '' || !/^\d*$/.test(cvc) || cvc.length < 3 || !cardNumber || disableBtn
								}
								onClick={onSubmit}
								type="button"
								className="button button--full-width "
							>
								{L.translate('Account.PaymentMethods.verify')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default CvcVerify;
