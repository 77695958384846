import { FC } from 'react';
import L from 'i18n-react';
import { Link } from 'react-router-dom';

const LoginOrRegister: FC = () => {
	return (
		<div className="login-register-wrapper">
			<Link to="/login" className="link login-register-link">
				{String(L.translate('LogOrReg.login'))}
			</Link>
			<p> {String(L.translate('LogOrReg.or'))} </p>
			<Link to="/registration" className="link login-register-link">
				{' '}
				{String(L.translate('LogOrReg.register_now'))}
			</Link>
			<p> {String(L.translate('LogOrReg.to_trade'))}</p>
		</div>
	);
};

export default LoginOrRegister;
