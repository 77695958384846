import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IMarginWalletsStore,
	IMarginCrossWallets,
	IMarginIsolatedWallets,
	IMaximumCrossBorrowAmount,
	IBorrowAvailable,
	IWalletsDataType,
	IWalletsIsolatedDataType,
	IIndexPrices,
	ITransferHistoryData,
	IUserMarginWalletData,
	IUserLiquidationWalletData,
} from './types';

// ==========================================:
const getWalletsState = (state: IStoreState): IMarginWalletsStore => state.marginWallets;
export const getMarginWallets = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore) => marginWallets,
);

// ====================================================:
export const getMarginCrossWalletsList = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IMarginCrossWallets =>
		marginWallets.marginCrossWallets,
);
// ====================================================:
export const getCrossWalletsList = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IWalletsDataType =>
		marginWallets.marginCrossWallets?.data.cross,
);
// ====================================================:
export const getIsolatedWalletsList = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IWalletsIsolatedDataType =>
		marginWallets.marginIsolatedWallets?.data.isolated,
);
// ====================================================:
export const getIsolatedWallet = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IMarginIsolatedWallets =>
		marginWallets.marginIsolatedWallets,
);
// ====================================================:
export const getMaximumCrossBorrowAmount = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IMaximumCrossBorrowAmount =>
		marginWallets.marginMaximumCrossBorrowAmount,
);
// ====================================================:
export const getMaximumIsolatedBorrowAmount = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IBorrowAvailable =>
		marginWallets.marginMaximumIsolatedBorrowAmount,
);
// ====================================================:
export const getMarginIndexPrices = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IIndexPrices => marginWallets.marginIndexPrices,
);

// ====================================================:
export const getTransferHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): ITransferHistoryData => marginWallets.transferHistory,
);

// ====================================================:
export const getTransferHistoryIsLoader = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): boolean => marginWallets.transferHistoryLoader,
);

// ====================================================:
export const getBorrowingHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IUserMarginWalletData | null =>
		marginWallets.marginCrossBorrowList,
);

// ====================================================:
export const getRepaymentHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IUserMarginWalletData | null =>
		marginWallets.marginCrossRepayList,
);
// ====================================================:
export const getInterestedHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IUserMarginWalletData | null =>
		marginWallets.marginCrossInterestList,
);
// ====================================================:
export const getMarginCallHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IUserMarginWalletData | null =>
		marginWallets.marginCrossMarginCallsList,
);
// ====================================================:
export const getLiquidationHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IUserLiquidationWalletData | null =>
		marginWallets.marginLiquidationList,
);

// ====================================================:
export const getMarginHistoryIsLoader = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): boolean => marginWallets.marginWalletsLoader,
);

export const getBorrowSuccessState = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore) => marginWallets.borrowSuccess,
);

export const getMakeTransferLoader = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore) => marginWallets.makeTransferLoader,
);
