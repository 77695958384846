/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router';
import { Redirect, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import L from 'i18n-react';
import Auth from 'layouts/Auth';
import { APP_NAME } from 'services/constants/env';
import { resendRegisterRequest } from 'redux/reducers/auth/reducer';

interface ILocationState {
	email?: string;
}

// ==========================================:
const RegistrationDone: FC = () => {
	const { state } = useLocation<ILocationState>();
	const dispatch = useDispatch();
	const intervalId = useRef<NodeJS.Timer | number>();
	const [count, setCount] = useState<number>(0);
	const email = state?.email;

	useEffect(() => {
		intervalId.current = setInterval(() => {
			if (count > 0) {
				setCount((prev) => prev - 1);
			}
		}, 1000);
		return () => {
			clearInterval(intervalId.current as number);
		};
	}, [count]);

	const startInterval = () => {
		email && dispatch(resendRegisterRequest({ email }));
		setCount(60);
	};

	return (
		<>
			<Auth
				title={`${String(APP_NAME)}.com | ${String(L.translate('RegistrPage.registr_page_title'))}`}
			>
				<div className="authorization">
					<Link to="/registration" className="back-btn">
						<span className="back-btn__arrow icon-arrow" />
						<span className="back-btn__text">{L.translate('RegistrDonePage.back_btn')}</span>
					</Link>
					<div className="authorization__body">
						<p className="authorization__title"> {L.translate('RegistrDonePage.almost_done')} </p>
						<div className="authorization__details authorization__details--mt ">
							<p>{L.translate('RegistrDonePage.check_email')}</p>
						</div>
						{email ? (
							<a
								href={`mailto:${email}`}
								className="authorization__link authorization__link--type3"
							>
								{email}
							</a>
						) : (
							<Redirect to="/registration" />
						)}
					</div>
					<div className="form-submit form-submit--big-mt form-submit--row">
						<button
							type="button"
							disabled={count > 0}
							className="button button--width-auto-2 button--size3"
							onClick={startInterval}
						>
							{' '}
							{L.translate('RegistrDonePage.send_again')}
							{count > 0 && (
								<>
									:{' '}
									<span className="button__timer">
										{count === 60 ? '01' : '00'}:
										{String(count).length === 2 ? String(count) : `0${count}`}
									</span>
								</>
							)}
						</button>
					</div>
				</div>
			</Auth>
		</>
	);
};

export default RegistrationDone;
