import React from 'react';
import L from 'i18n-react';

const CrossMarginSelect = () => {
	return (
		<>
			<button type="button" className="select__current">
				{String(L.translate('CrossMargin.cross_title'))}
			</button>
			<div className="select__drop">
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<span className="select__drop-link">
							<span className="select__drop-text">
								{String(L.translate('CrossMargin.cross_2'))}
							</span>
						</span>
					</div>
					<div className="select__drop-item">
						<span className="select__drop-link">
							<span className="select__drop-text">
								{String(L.translate('CrossMargin.cross_3'))}
							</span>
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default CrossMarginSelect;
