/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
	getTradePaySellRequest,
	putCancelTradeRequest,
	putHavePaidSellerRequest,
	putRecivedPaymentRequest,
} from 'redux/reducers/p2p/reducer';
import { getTradePaySell } from 'redux/reducers/p2p/selectors';
import { renameCoin } from 'services/utils/renameCoin';
import moment from 'moment';
import { numbersAfterPoint } from 'services/utils/numberHelpers';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { notificationContainer } from 'services/utils/notificationContainer';
import L from 'i18n-react';
import Loader from 'ui/Loader';
import queryString from 'query-string';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import useTimer from 'hooks/useTimer';
import { navList } from 'routes/routesList';
import InfoTradeSection from '../InfoTradeSection/InfoTradeSection';
import TotpPopup from '../TotpPopup';

/* eslint-disable no-debugger */

const TradePaySell: FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const { search } = location;
	const tradeId = queryString.parse(search).trade_id || null;
	const [isTotpOpen, setIsTotpOpen] = useState(false);
	const trade = useSelector(getTradePaySell);
	const user = useSelector(getUserSettingsData);
	const coin = (trade && trade.asset_code && trade.asset_code.toUpperCase()) || '';
	const type =
		trade && user && user.id && trade.seller_id && user.id === trade.seller_id ? 'sell' : 'buy';

	const userId = user && user.id;
	const tradId = trade && trade.trader && trade.trader.id;
	const userTrader = tradId === userId ? 'trader' : 'not_trader';
	const createAt = trade && trade.created_at;
	// useEffect(() => {
	// 	if (location.pathname === navList.p2pTradePay.path && !tradeId) {
	// 		history.replace(navList.home.path);
	// 	}
	// }, [location, history, tradeId]);
	// debugger;

	useEffect(() => {
		if (!tradeId) return;
		dispatch(getTradePaySellRequest({ trade_id: String(tradeId) }));
	}, [tradeId, dispatch]);

	const { hours, minutes, seconds, refHours, refMinutes, refSeconds } = useTimer(
		createAt,
		(trade && trade.advertisement && trade.advertisement.payment_window) || 20,
	);

	useEffect(() => {
		if (
			hours === 0 &&
			minutes === 0 &&
			seconds === 0 &&
			trade?.id &&
			trade?.status !== 'canceled'
		) {
			history.replace({
				pathname: '/trade-pay-close',
				search,
				state: { hasToBeCancelled: true },
			});
		}
	}, [dispatch, history, hours, minutes, search, seconds, trade]);

	const handleBuyNow = () => {
		if (!tradeId) return;

		if (type === 'buy') {
			if (
				user?.google2fa_enabled === 0 &&
				user?.phone_2fa_enabled === 0 &&
				user?.email_2fa_enabled === 0
			) {
				dispatch(putHavePaidSellerRequest({ trade_id: String(tradeId) }));
				return;
			}
			setIsTotpOpen(true);
		} else if (type === 'sell') {
			if (String(trade?.status) === 'waiting_for_payment') {
				notificationContainer(String(L.translate('P2P.t_0407', { coin })), 'info');
			}
			if (
				trade &&
				String(trade.status) === 'waiting_for_confirmation' &&
				String(trade.status) !== 'under_dispute'
			) {
				dispatch(putRecivedPaymentRequest({ trade_id: String(tradeId) }));
			}
		}
	};

	const fnProfile = (e: MouseEvent<HTMLAnchorElement>) => {
		const { name } = e.currentTarget.dataset;
		history.push({ pathname: `/p2p-user-center`, search: `profilename=${String(name || '')}` });
	};

	useEffect(() => {
		if (trade?.status === 'canceled') {
			history.replace(`/trade-pay-close${search}`);
		}
		if (trade?.status === 'waiting_for_confirmation') {
			console.log('waiting for confirmation');
			// history.replace(`/p2p-trading/release-tether${search}`);
			history.replace({
				pathname: `/p2p-trading/release-tether`,
				search,
				state: 2,
			});
		}
		if (trade?.status === 'under_dispute') {
			// history.replace(`/p2p-trading/release-tether${search}`);
			history.replace({
				pathname: `/p2p-trading/release-tether`,
				search,
				state: 2,
			});
		}
		if (trade?.status === 'reviews' || trade?.status === 'done') {
			history.replace(`/trade-pay-close${search}`);
		}
	}, [trade, history, search, type]);

	const handleCancelTrade = () => {
		if (!tradeId) return;
		dispatch(putCancelTradeRequest({ trade_id: String(tradeId) }));
	};

	return (
		<>
			{trade ? (
				<div className="create-offer__left">
					<div className="payment-box">
						<div className="payment-info">
							<h4 className="payment-info__title">
								{renameCoin(coin)} {L.translate('P2P.t_0218')}
							</h4>
							<div className="payment-info__text">
								<p>
									<span className="payment-info__text-bold">
										{type === 'buy' ? L.translate('P2P.t_0219') : L.translate('P2P.t_0220')}
									</span>
									<span className="payment-info__text-thin">
										{' '}
										{fixedCropNumber(Number(trade?.amount), numbersAfterPoint(coin)) || 0}{' '}
										{renameCoin(coin)}
									</span>{' '}
									<span className="payment-info__text-bold">
										{type === 'buy' ? L.translate('P2P.t_0222') : L.translate('P2P.t_0221')}
									</span>{' '}
									<span
										data-name={
											userTrader === 'trader'
												? trade?.advertisement?.user?.username
												: trade?.trader?.username
										}
										role="presentation"
										className="payment-info__text-border"
										onClick={fnProfile}
										style={{ cursor: 'pointer' }}
									>
										{userTrader === 'trader'
											? trade?.advertisement?.user?.username
											: trade?.trader?.username}
									</span>
								</p>
								{type === 'sell' ? (
									<span
										role="presentation"
										data-name={
											userTrader === 'trader'
												? trade?.advertisement?.user?.username
												: trade?.trader?.username
										}
									>
										<span className="payment-info__text-bold">
											{L.translate('P2P.pay-sell-text_1')}{' '}
										</span>
										<span className="payment-info__name">{trade?.buyer_full_name}</span>
										<span className="payment-info__text-bold">
											{L.translate('P2P.pay-sell-text_2')}
										</span>
									</span>
								) : (
									<>
										<p>
											{type === 'buy'
												? L.translate('P2P.omg.trade.paySell.info1', { who: 'seller' })
												: L.translate('P2P.omg.trade.paySell.info1', { who: 'buyer' })}
										</p>
										<p>{L.translate('P2P.t_0226', { coin })}</p>
									</>
								)}
								{/* <p>Please send paymet to the seller then confirm below.</p>
									<p>After the trade is completed, your USDT will be sent to your USDT wallet.</p> */}
							</div>
						</div>
						<div className="payment-descr">
							<h4 className="payment-descr-title">
								{String(L.translate('P2P.omg.trade.paySell.payment_to_be_made'))}{' '}
								<span className="payment-descr-timer">
									<span className="timer-number" ref={refHours}>
										00
									</span>
									:
									<span className="timer-number" ref={refMinutes}>
										00
									</span>
									:
									<span className="timer-number" ref={refSeconds}>
										00
									</span>
								</span>
							</h4>

							{type === 'buy' ? (
								<p className="payment-descr__text">
									{(hours === 0 && minutes === 0 && seconds === 0) ||
									(refHours.current?.innerHTML === '00' &&
										refMinutes.current?.innerHTML === '00' &&
										refSeconds.current?.innerHTML === '00') ? (
										<>
											{String(
												L.translate('P2P.omg.trade.paySell.please_make_payment_zero', {
													number: `${
														fixedCropNumber(Number(trade?.amount), numbersAfterPoint(coin)) || 0
													} ${renameCoin(coin)}`,
												}),
											)}

											{/* {renameCoin(coin)} */}
										</>
									) : (
										<>
											{String(L.translate('P2P.omg.trade.paySell.please_make_payment'))}
											{trade?.advertisement?.payment_window}{' '}
											{String(L.translate('P2P.omg.trade.paySell.otherwise'))}
										</>
									)}{' '}
								</p>
							) : null}
							{type === 'sell' ? (
								<p className="payment-descr__text">
									{(hours === 0 && minutes === 0 && seconds === 0) ||
									(refHours.current?.innerHTML === '00' &&
										refMinutes.current?.innerHTML === '00' &&
										refSeconds.current?.innerHTML === '00') ? (
										<>
											{String(
												L.translate('P2P.omg.trade.paySell.please_make_payment_sell_zero', {
													number: `${
														fixedCropNumber(Number(trade?.amount), numbersAfterPoint(coin)) || 0
													} ${renameCoin(coin)}`,
												}),
											)}
										</>
									) : (
										''
									)}{' '}
								</p>
							) : null}

							<div className="payment-descr-attention">
								<div className="payment-descr-attention__icon">
									<svg
										width="18"
										height="18"
										viewBox="0 0 18 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z"
											stroke="#F44336"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9 6V9.75"
											stroke="#F44336"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8.99609 12H9.00283"
											stroke="#F44336"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</div>
								<p className="payment-descr-attention__text">
									{type === 'buy'
										? String(L.translate('P2P.omg.trade.paySell.attention'))
										: String(L.translate('P2P.omg.trade.paySell.attentionSell'))}
								</p>
							</div>
							<div className="payment-buttons">
								<button type="button" onClick={handleBuyNow} className="button">
									{type === 'buy' ? L.translate('P2P.t_0232') : L.translate('P2P.t_0233')}
								</button>
								{type === 'buy' ? (
									<button
										onClick={handleCancelTrade}
										type="button"
										className="button button--transparent"
									>
										{L.translate('P2P.t_0234')}
									</button>
								) : (
									''
								)}
							</div>
						</div>
					</div>
					<InfoTradeSection trade={trade} userTrader={userTrader} type={type} coin={coin} />
				</div>
			) : (
				<div className="spot-and-fiat-wallets-table-loader-wrapper">
					<Loader />
				</div>
			)}
			<TotpPopup
				isOpen={isTotpOpen}
				onClose={() => setIsTotpOpen(false)}
				onSubmit={(totp: string) => {
					if (!tradeId) return;
					dispatch(putHavePaidSellerRequest({ trade_id: String(tradeId), totp }));
				}}
			/>
		</>
	);
};

export default TradePaySell;
