/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import L from 'i18n-react';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { IBorrowedItemProps } from './types';
/* eslint-disable no-debugger */
const BorrowItem: FC<IBorrowedItemProps> = ({ data }) => {
	const { created_at, asset, amount, pair } = data;

	const getClassByStatus = (typeValue: string): string => {
		if (typeValue?.includes('cancelled')) return 'td-name td-name--regular td-name--red';

		if (typeValue?.includes('filled')) return 'td-name td-name--regular td-name--green';

		return 'td-name';
	};

	return (
		<div className="tr">
			{pair && (
				<div className="td">
					<p className="td-hidden-name">Pair</p>
					<span className="td-name td-name--regular td-name--fw500">
						{transformPairCode(pair?.code)}
					</span>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Coin</p>
				<span className="td-name td-name--regular td-name--fw500">{asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<span className="td-name td-name--regular td-name--light">
					{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<span className="td-name td-name--regular">{amount}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<span className="td-name td-name--regular">
					{String(L.translate(`CoinType.${asset.type}`))}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<span className={getClassByStatus(String('filled'))}>
					{String(L.translate('BorrowStatusType.filled')) || '---'}
				</span>
			</div>
		</div>
	);
};

export default BorrowItem;
