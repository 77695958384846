import { FC } from 'react';
import L from 'i18n-react';
import { HIDE_VIP } from 'components/OurPrices/constants';
import { displayChannelName } from '../../utils';
import BankTransferTooltip from '../../../shared/BankTransferTooltip';
import { ICryptoTableRow } from './types';

const TableRow: FC<ICryptoTableRow> = ({ item, activeBtnSt }) => {
	const {
		asset_code,
		channel_name,
		withdraw_max,
		vip_withdraw_max,
		withdraw_no_limit,
		vip_withdraw_no_limit,
	} = item;

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.currency')}</p>
				<p className="td-name">{asset_code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.channel_name')}</p>
				{['bank_transfer', 'bank_transfer_other'].includes(channel_name) ? (
					<div className="td-flex">
						<p className="td-name">{displayChannelName(channel_name)}</p>
						<BankTransferTooltip />
					</div>
				) : (
					<p className="td-name">{displayChannelName(channel_name)}</p>
				)}
			</div>
			<div className={`td ${HIDE_VIP ? 'td--right' : ''}`}>
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.withdrawal1')}</p>
				<p className="td-name">{withdraw_no_limit ? 'no limit' : withdraw_max}</p>
			</div>
			{!HIDE_VIP && (
				<div className="td td--right">
					<p className="td-hidden-name">{L.translate('OurPrices.Columns.vip_withdrawal1')}</p>
					<p className="td-name">{vip_withdraw_no_limit ? 'no limit' : vip_withdraw_max}</p>
				</div>
			)}
		</div>
	);
};

export default TableRow;
