import { FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentRequest, getPaymentMethodRequest } from 'redux/reducers/p2p/reducer';
import { getPaymentMethodList } from 'redux/reducers/p2p/selectors';
import SuccessPopup from 'layouts-elements/popups/SuccessPopup';
import { getAccountP2P } from 'redux/reducers/auth/selectors';
import { PaymentCenter } from './PaymentCenter/PaymentCenter';
import { SelectPayment } from '../Popups/SelectPayment';
import { PopusModal } from '../Popups';

export const PaymentMethods: FC = () => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showPayment, setPaymentModal] = useState(false);
	const payementList = useSelector(getPaymentMethodList);
	const accountP2P = useSelector(getAccountP2P);
	const [type, setType] = useState('');
	const [method, setMethod] = useState('card');

	useEffect(() => {
		if (accountP2P) {
			dispatch(getPaymentRequest());
			dispatch(getPaymentMethodRequest({ type: method }));
		}
	}, [dispatch, method, accountP2P]);

	const selectPayment = (val: string) => {
		setType(val);
		setShowModal(false);
		setPaymentModal(true);
	};

	const handleStatus = (value: string, status: string) => {
		setPaymentModal(false);
		status === 'success' && setShowSuccessModal(true);
	};

	return (
		<>
			{accountP2P ? (
				<div
					className={`content-block ${
						payementList?.length
							? 'content-block--p2p-center-payments'
							: 'content-block--p2p-center'
					}`}
				>
					<PaymentCenter
						setShowModal={setShowModal}
						payementList={payementList}
						method={method}
						setMethod={setMethod}
					/>
					{/* ) : (
			 	<PaymentsInfo setShowModal={setShowModal} />
			 )} */}
					<SelectPayment
						openModal={showModal}
						closeModal={() => setShowModal(false)}
						choosePayment={selectPayment}
					/>
					<SuccessPopup
						openModal={showSuccessModal}
						closeModal={() => setShowSuccessModal(false)}
						message="success"
					/>
					<PopusModal
						openModal={showPayment}
						closeModal={() => setPaymentModal(false)}
						type={type}
						handleStatus={handleStatus}
					/>
				</div>
			) : (
				<div className="table table--p2p-details-type table--p2p-not-enabled">
					{String(L.translate('P2P.p2p_not_enabled'))}
				</div>
			)}
		</>
	);
};
