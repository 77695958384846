import { FC } from 'react';
import L from 'i18n-react';
import Dashboard from 'layouts/Dashboard';
import Borrowed from 'components/MarginWalletHistory/Borrowed/index';

// ==========================================:
const BorrowHistory: FC = () => {
	return (
		<Dashboard title={String(L.translate('PageTitles.borrowing'))}>
			<Borrowed />
		</Dashboard>
	);
};

export default BorrowHistory;
