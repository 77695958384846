import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch } from 'react-redux';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';

import Trade from 'components/P2P/Trade';
import TradeP2P from 'layouts/TradeP2P';

// ==========================================:
const P2PTrade: FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		// dispatch(getP2PCurrenciesRequest());
		// dispatch(getP2PAssetsRequest());
		// dispatch(getPaymentRequest());
		dispatch(userSettingsRequest());
	}, [dispatch]);

	return (
		<TradeP2P title={String(L.translate('PageTitles.p2p_trade'))}>
			<Trade />
		</TradeP2P>
	);
};

export default P2PTrade;
