import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { APP_NAME } from 'services/constants/env';
import { getTopPairsWithChartRequest } from 'redux/reducers/assetPairs/reducer';
import { appLanguage } from 'redux/reducers/app/selectors';
import ogImage from 'assets/images/content/binatica-og.png';

// Layouts elements:
import Header from 'layouts-elements/Header';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';

// Home blocks:
import BuySellCrypto from 'components/HomeBlocks/BuySellCrypto';
import TopCryptocurrencies from 'components/HomeBlocks/TopCryptocurrencies';
import BitanicaTextTrage from 'components/HomeBlocks/BitanicaTextTrage';
import Advantages from 'components/HomeBlocks/Advantages';
import Access from 'components/HomeBlocks/Access';
import FAQ from 'components/HomeBlocks/FAQ';
import Convert from 'components/HomeBlocks/Convert';
import { getLocale } from 'services/utils/language';

// ==========================================:
const Home: FC = () => {
	const dispatch = useDispatch();
	const language = useSelector(appLanguage);

	useEffect(() => {
		dispatch(getTopPairsWithChartRequest());
	}, [dispatch]);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)}.com | ${String(L.translate('PageTitles.home'))}`}</title>
				<meta name="description" content={String(L.translate('MetaTags.Description.home'))} />

				{/* Open Graph Meta Tags */}
				<meta property="og:title" content="Bitanica.com | Buy, Sell & Trade Cryptocurrencies" />
				<meta property="og:type" content="website" />
				<meta property="og:url" content={window.location.host} />
				<meta property="og:image" content={ogImage} />
				<meta
					property="og:description"
					content="Experience the best crypto exchange with our secure and user-friendly platform. Trade Bitcoin, Ethereum, and other cryptocurrencies with confidence. Sign up now."
				/>
				<meta property="og:site_name" content="Bitanica.com" />
				<meta property="og:locale" content={getLocale(language)} />

				{/* Twitter Card Meta Tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content="Bitanica.com | Buy, Sell & Trade Cryptocurrencies" />
				<meta
					name="twitter:description"
					content="Experience the best crypto exchange with our secure and user-friendly platform. Trade Bitcoin, Ethereum, and other cryptocurrencies with confidence. Sign up now."
				/>
				<meta name="twitter:image" content={ogImage} />
				<meta name="twitter:site" content="@YourTwitterHandle" />
			</Helmet>

			{/* Временный вариант, без этого почему то файл og:image не подгружается */}
			<img src={ogImage} alt="" style={{ display: 'none' }} />
			{/* ---------------------- */}

			<div className="wrapper">
				<Header isNew />
				<Content>
					<div className="content-wrapper">
						<BuySellCrypto />
						<div className="content-box content-box--landing">
							<TopCryptocurrencies />
							<Convert />
							<Advantages />
							<BitanicaTextTrage />
							<Access />
							<FAQ />
						</div>
					</div>
				</Content>
				<Footer isNew />
			</div>
		</>
	);
};

export default Home;
