import { FC } from 'react';
import { IDetailsBlock } from './types';

const DetailsBlock: FC<IDetailsBlock> = ({ title, value }) => {
	return (
		<div className="details-item">
			<span className="details-item__title">{title}:</span>
			<span className="details-item__desc">{value}</span>
		</div>
	);
};

export default DetailsBlock;
