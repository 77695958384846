import { useState, FC, KeyboardEvent } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import L from 'i18n-react';
import Input from 'ui/Formik/Input';
import TextError from 'ui/Formik/TextError';
import yup from 'services/utils/capsLockValidate';
import { Link } from 'react-router-dom';
import { PrivateAccountIcon, BuisinessAccountIcon } from 'assets/svg-icon';
import RegistrFormInner from './FormInner';
import { IRegistrValues, IRegistrForm } from './types';

// ==========================================:
const RegistrForm: FC<IRegistrForm> = ({
	registrSubmit,
	captchaLoading,
	setCaptchaLoading,
	captcha,
	setCaptcha,
}) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
	const [isCapsLockOn, setIsCapsLockOn] = useState(false);

	const initialValues = {
		email: '',
		password: '',
		confirmPassword: '',
		acceptTerms: false,
		cookiePolicy: false,
		is_business: 0,
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.capsLockValidate(isCapsLockOn, 'CapsLock is on')
			.required(String(L.translate('Errors.required_email')))
			.email(String(L.translate('Errors.email_format')))
			.max(60, String(L.translate('Errors.email_max_characters'))),
		company_name: yup.string().when('is_business', (is_business, schema) => {
			if (is_business === 1) {
				return schema.required(String(L.translate('Forms.RegistrForm.company_name_required_err')));
			}
			return schema;
		}),
		password: yup
			.string()
			.required(String(L.translate('Errors.password_required')))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(L.translate('Errors.password_validation')),
			)
			.max(25, String(L.translate('Errors.password_max_length'))),
		confirmPassword: yup
			.string()
			.required(String(L.translate('Errors.confirm_password_required')))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(L.translate('Errors.password_validation')),
			)
			.oneOf([yup.ref('password'), null], String(L.translate('Errors.passwords_match')))
			.max(25, String(L.translate('Errors.password_max_length'))),
		acceptTerms: yup.bool().oneOf([true], String(L.translate('Errors.accept_terms'))),
		cookiePolicy: yup.bool().oneOf([true], String(L.translate('Errors.cookie_policy'))),
		is_business: yup.number(),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleConfirmPassDisplay = (): void => {
		setIsShowConfirmPass(!isShowConfirmPass);
	};

	const handleCapsLockOn = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.getModifierState('CapsLock')) {
			setIsCapsLockOn(true);
		} else {
			setIsCapsLockOn(false);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IRegistrValues, { resetForm, setSubmitting }) => {
				registrSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			<Form>
				<RegistrFormInner
					setIsCapsLockOn={setIsCapsLockOn}
					captchaLoading={captchaLoading}
					setCaptchaLoading={setCaptchaLoading}
					captcha={captcha}
					setCaptcha={setCaptcha}
				/>
			</Form>
		</Formik>
	);
};

export default RegistrForm;
