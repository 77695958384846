import { FC } from 'react';
import { Link } from 'react-router-dom';
import L from 'i18n-react';

// ==========================================:
const StaticHeader: FC = () => {
	return (
		<>
			<div className="user-nav">
				<Link to="/login" className="button button--new button--new-type1 Click_Log_In">
					{String(L.translate('LoginPage.login_btn'))}
				</Link>
				<Link to="/registration" className="button button--new button--new-type2">
					{String(L.translate('RegistrPage.registr_btn'))}
				</Link>
			</div>
		</>
	);
};

export default StaticHeader;
