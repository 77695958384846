import L from 'i18n-react';
import { FC } from 'react';
import { ITabs } from './types';

const TabsLimits: FC<ITabs> = ({ active, setActive }) => {
	return (
		<div className="line-tab">
			<button
				type="button"
				onClick={() => setActive('payment_methods')}
				className={`line-tab__item ${active === 'payment_methods' ? 'active' : ''}`}
			>
				{L.translate('OurPrices.payment_methods')}
			</button>
			<button
				type="button"
				onClick={() => setActive('cryptocurrency')}
				className={`line-tab__item ${active === 'cryptocurrency' ? 'active' : ''}`}
			>
				{L.translate('OurPrices.cryptocurrency')}
			</button>
		</div>
	);
};

export default TabsLimits;
