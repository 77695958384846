/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';

import { IBorrowedItemProps } from './types';

const InterestedItem: FC<IBorrowedItemProps> = ({ data }) => {
	const { created_at, asset, amount, pair, interest } = data;

	return (
		<div className="tr">
			{pair && (
				<div className="td">
					<p className="td-hidden-name">Pair</p>
					<span className="td-name td-name--regular td-name--fw500">
						{transformPairCode(pair?.code)}
					</span>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Coin</p>

				<span className="td-name td-name--regular td-name--fw500">{asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<span className="td-name td-name--regular td-name--light">
					{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<span className="td-name td-name--regular">{Number(amount).toFixed(10)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Interest</p>
				<span className="td-name td-name--regular">{Number(interest).toFixed(10)}</span>
			</div>
		</div>
	);
};

export default InterestedItem;
