import { FC } from 'react';
import { HIDE_VIP } from 'components/OurPrices/constants';
import { ICryptoTableRow } from './types';

const TableRow: FC<ICryptoTableRow> = ({ item }) => {
	const { asset_code, network_name, deposit_fee, withdraw_fee, vip_deposit_fee, vip_withdraw_fee } =
		item;

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name">
					{asset_code.toUpperCase()} ({network_name.toUpperCase()})
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Deposit fixed amount</p>
				<p className="td-name">{deposit_fee}</p>
			</div>
			<div className={`td ${HIDE_VIP ? 'td--right' : ''}`}>
				<p className="td-hidden-name">Withdrawal fixed amount</p>
				<p className="td-name">{withdraw_fee}</p>
			</div>
			{!HIDE_VIP && (
				<>
					<div className="td">
						<p className="td-hidden-name">VIP Deposit fixed amount</p>
						<p className="td-name">{vip_deposit_fee}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">VIP Withdrawal fixed amount</p>
						<p className="td-name">{vip_withdraw_fee}</p>
					</div>
				</>
			)}
		</div>
	);
};

export default TableRow;
