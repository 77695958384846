import { FC } from 'react';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import { IBalanceSocketProps, ISocketWithOptionalChildren } from './types';

const BalanceSockets: FC<IBalanceSocketProps> = ({ spot, cross, isolated, p2p, marginIndex }) => {
	const Socket = SocketWithPrivateTopicConnectWrapper as FC<ISocketWithOptionalChildren>;

	return (
		<>
			{spot && <Socket topicName="balances" />}
			{cross && <Socket topicName="balances:cross" />}
			{isolated && <Socket topicName="balances:isolated" />}
			{p2p && <Socket topicName="balances:p2p" />}
			{marginIndex && <Socket topicName="index_prices" />}
		</>
	);
};

export default BalanceSockets;
