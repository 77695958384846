import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSocketToken } from 'redux/reducers/auth/selectors';
import { socketClosedConnection, socketOpenConnection } from 'redux/reducers/sockets/reducer';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { IThemeProvider } from './types';

// ================================================:
const ThemeProvider: FC<IThemeProvider> = ({ children }) => {
	const theme = useSelector(getColorTheme);

	useEffect(() => {
		const body = document.querySelector('body');
		body?.classList[theme === 'dark' ? 'add' : 'remove']('dark-theme');
	}, [theme]);

	return <>{children}</>;
};

export default ThemeProvider;
