/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import L from 'i18n-react';
import { useLocation } from 'react-router';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { getFirstCurrency, transformPairCode } from 'services/utils/tradingPairHelpers';
import { IOrdersHistoryItemProps } from './types';

const OrdersHistoryItem: FC<IOrdersHistoryItemProps> = ({ data }) => {
	const { created_at, pair, type, price, average, quantity, total, status, filling } = data;
	const location = useLocation<any>();

	const getType = (typeValue: string): string =>
		typeValue?.includes('market')
			? String(L.translate('OrderType.market'))
			: String(L.translate('OrderType.limit'));

	const getMarketType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return String(L.translate('MarketType.sell'));

		if (typeValue?.includes('buy')) return String(L.translate('MarketType.buy'));

		return '-';
	};

	const getClassByType = (statusValue: string): string => {
		if (statusValue?.includes('sell')) return 'td-name td-name--red';

		if (statusValue?.includes('buy')) return 'td-name td-name--green';

		return 'td-name';
	};

	const getClassByStatus = (typeValue: string): string => {
		if (typeValue?.includes('cancelled')) return 'td-name td-name--regular td-name--red';

		if (typeValue?.includes('filled')) return 'td-name td-name--regular td-name--green';

		return 'td-name';
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<span className="td-name td-name--regular td-name--light">
					{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<span className="td-name td-name--regular">
					{location.state?.margin === 'cross'
						? getFirstCurrency(transformPairCode(pair))
						: transformPairCode(pair)}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<span className="td-name td-name--regular">{getType(type)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Side</p>
				<span className={getClassByType(type)}>{getMarketType(type)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Average</p>
				<span className="td-name td-name--regular">{Number(average).toFixed(6) || '-'}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Price</p>
				<span className="td-name td-name--regular">{Number(price).toFixed(6) || '-'}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Executed</p>
				<span className="td-name td-name--regular">{Number(quantity).toFixed(6) || '-'}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<span className="td-name td-name--regular">{filling ? `${String(filling)} %` : '-'}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<div>
					<span className="td-name td-name--regular">{Number(total).toFixed(6)}</span>
					<span className="td-name td-name--regular">USDT</span>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<span className={getClassByStatus(String(status))}>
					{status ? String(L.translate(`StatusType.${status}`)) : ''}
				</span>
			</div>
		</div>
	);
};

export default OrdersHistoryItem;
