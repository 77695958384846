export const numberExists = (value: number | null | undefined | string) => {
	return value || value === 0;
};

export const trancDecimal = (value: number, limit: number) => {
	const decPartIndex = String(value).indexOf('.');
	if (decPartIndex === -1) {
		return `${Math.trunc(value)}.${'0'.repeat(limit)}`;
	}
	const decPart = String(value).slice(decPartIndex + 1, decPartIndex + 1 + limit);
	let zeroFill = '';
	if (decPart.length < limit) {
		zeroFill = '0'.repeat(limit - decPart.length);
	}
	return `${String(Math.trunc(value))}.${decPart}${zeroFill}`;
};
