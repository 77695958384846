import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	getCountriesRequest,
	getCountriesSuccess,
	getTranslatesRequest,
	getTranslatesSuccess,
} from './reducer';
import { TCounties, ITranslateParams, ITranslates } from './types';

// =============================================================:
function* getCountriesWorker(action: PayloadAction) {
	try {
		yield put(showLoading());
		const response: TCounties = yield call(api.app.getCountries);

		yield put(getCountriesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getTranslatesWorker({ payload }: PayloadAction<ITranslateParams>) {
	try {
		yield put(showLoading());
		const response: ITranslates[] = yield call(api.app.getTranslates, payload);

		const responseNew: ITranslates = response.reduce((acc, curr) => {
			const result: ITranslates = {};
			const keys = Object.keys(curr);
			keys.forEach((key) => {
				result[key] = curr[key];
			});
			return {
				...acc,
				...result,
			};
		}, {});
		yield put(getTranslatesSuccess(responseNew));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* appSaga() {
	yield takeEvery(getCountriesRequest.type, getCountriesWorker);
	yield takeEvery(getTranslatesRequest.type, getTranslatesWorker);
}
