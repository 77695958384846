import { FC, MouseEvent, useEffect } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import CSearchInput from 'ui/CSearchInput';
import HeaderDropdown from 'ui/HeaderDropdown';
import CDropdown from 'ui/CDropdown';
import { getCurrencies, getPayments, getAcvtivePayments } from 'redux/reducers/p2p/selectors';
import { getAccountP2P, getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { LinkIcon, OrdersIcon } from 'assets/svg-icon';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { IP2PNavigationProps, IHeaderDropdownList } from './type';

/* eslint-disable no-debugger */
const TableNav: FC<IP2PNavigationProps> = ({
	amount,
	handleSearch,
	selectItem,
	handleSelectCoin,
	selectPaymnet,
	handleSelectPaymnets,
	setOpenModal,
}) => {
	// const [amount, setAmount] = useState('');
	// const location = useLocation<any>();
	const history = useHistory();
	const accountP2P = useSelector(getAccountP2P);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const currenciesList = useSelector(getCurrencies);
	const paymentsMethod = useSelector(getPayments);
	const paymentsAcvtiveMethod = useSelector(getAcvtivePayments);

	// const newPaymentsList = [
	// 	{ id: 0, payment_name: 'All payments', payment_name_eng: 'All payments' },
	// 	...paymentsAcvtiveMethod,
	// ];

	const handleSelect = (value: IHeaderDropdownList): void => {
		if (value.link === '/logout') {
			// dispatch(logoutRequest({ history }));
		}
		if (!accountP2P) {
			setOpenModal(true);
			return;
		}
		history.push(value.link);
	};

	// debugger;
	// const handleSelectCoin = (e: React.MouseEvent<HTMLButtonElement>): void => {
	// const handleSelectCoin = (value: any): void => {
	// 	// const codeCoin = e?.currentTarget?.dataset?.code;
	// 	// const codeCoin = value.code;
	// 	// if (codeCoin) {
	// 	setSelectItem(value);
	// 	history.push({ state: { currency: value } });
	// 	dispatch(setCurrency(value));
	// 	// }
	// 	// setAmount('');
	// 	// setSelectWallet(value);
	// 	// // setSelectWalletNetworks(null);
	// 	// // setWalletNetworks(value?.networks);
	// 	// setAmount('');
	// };

	// useEffect(() => {
	// 	if (location.state) {
	// 		const coincidences = currenciesList?.find(
	// 			(item: IGetCurrenciesItem) => item?.code === location?.state?.fiat,
	// 		);
	// 		setSelectItem(location?.state?.fiat);
	// 		if (coincidences) {
	// 			dispatch(setCurrency({ ...coincidences }));
	// 		}
	// 	}
	// }, [dispatch, currenciesList, location]);

	const linkList = [
		{
			order: 1,
			name: String(L.translate('P2P.Marketplace.post_new_ad')),
			icon: 'icon-plus',
			link: '/advertisement',
		},
		{
			order: 2,
			name: String(L.translate('P2P.Marketplace.my_ads')),
			icon: 'icon-Logout',
			link: '/p2p',
		},
	];

	const handleAdPostClick = () => {
		if (!authIsAuthenticated) {
			history.push({
				pathname: `/login`,
				search: `redirect=/p2p-trade`,
			});
			return;
		}
		if (!accountP2P) {
			setOpenModal(true);
			return;
		}
		history.push('/advertisement');
	};
	return (
		<div className="table-wrapper">
			<div className="table-nav">
				<div className="sort">
					<div className="sort__group sort-item sort-item--amount">
						<div className="sort-item__title">{String(L.translate('P2P.Marketplace.amount'))}</div>
						<div className="sort-item__content">
							<div className="input input--margin-none">
								<div className="input-wrapper">
									<input
										className="input-item input-item--sort input-item--right-icon"
										type="text"
										placeholder={String(L.translate('P2P.enter_amount'))}
										value={amount}
										onChange={handleSearch}
									/>
									<button type="button" className="search-btn">
										<span className="search-btn__icon icon-search-normal" />
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="sort__group sort-item">
						<div className="sort-item__title">{String(L.translate('P2P.Marketplace.fiat'))}</div>
						<div className="sort-item__content">
							<CSearchInput
								listData={currenciesList}
								onSelect={handleSelectCoin}
								dataItem={selectItem?.code}
								currenClass="market-select"
								start="0"
								nameInput="full"
							/>
						</div>
					</div>
					<div className="sort__group sort-item">
						<div className="sort-item__title">
							{String(L.translate('P2P.Marketplace.payment_title'))}
						</div>
						<div className="sort-item__content">
							<CSearchInput
								listData={paymentsAcvtiveMethod}
								onSelect={handleSelectPaymnets}
								dataItem={selectPaymnet?.id}
								placeholder={String(L.translate('P2P.all_payments'))}
								currenClass="market-select"
								valueProp="id"
								nameProp="payment_name_eng"
								start="0"
								showValue={false}
								allOptionText={String(L.translate('P2P.all_payments'))}
								onAllOptClick={() => handleSelectPaymnets(null)}
							/>
						</div>
					</div>
				</div>
				<div className="table-nav__right">
					<button
						className="button  button--small-height2 button--small-width"
						type="button"
						onClick={handleAdPostClick}
					>
						{String(L.translate('P2P.Marketplace.ad_post'))}
					</button>
					<NavLink
						to={{ pathname: '/p2p-user-center', state: { currency: selectItem } }}
						className="filter-link"
					>
						<span className="filter-link__icon">
							<LinkIcon />
						</span>
						{String(L.translate('P2P.Marketplace.p2p_user_center'))}
					</NavLink>
					<NavLink to="/p2p-open-trades" className="filter-link">
						<span className="filter-link__icon">
							<OrdersIcon />
						</span>
						{String(L.translate('P2P.Marketplace.orders_button'))}
					</NavLink>
					<NavLink to="/p2p-advertisement" className="filter-link">
						<span className="select__drop-icon icon-Logout" />
						{String(L.translate('P2P.Marketplace.my_ads'))}
					</NavLink>
					{/* <HeaderDropdown
						currentClass="select--default"
						placeholder={String(L.translate('P2P.Marketplace.more'))}
						onChange={handleSelect}
						options={linkList}
					/> */}
				</div>
			</div>
		</div>
	);
};

export default TableNav;
