import { FC, useEffect } from 'react';
import L from 'i18n-react';
import Dashboard from 'layouts/Dashboard';
import { useDispatch } from 'react-redux';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import MarginWallet from 'components/MarginWallet';
import { getAssetPairsRequest } from 'redux/reducers/assetPairs/reducer';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import {
	getMarginCrossWalletsRequest,
	getMarginIsolatedWalletsRequest,
} from 'redux/reducers/marginWallets/reducer';

// ==========================================:
const Margin: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(getMarginCrossWalletsRequest());
		dispatch(getMarginIsolatedWalletsRequest());
		dispatch(getAssetPairsRequest());
		dispatch(userSettingsRequest());
	}, [dispatch]);

	return (
		<Dashboard title={String(L.translate('PageTitles.margin'))}>
			<MarginWallet />
		</Dashboard>
	);
};

export default Margin;
