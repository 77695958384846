import axios from 'axios';
import L from 'i18n-react';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { responseErrors } from 'services/http/responseErrors';
import { api } from 'services';
import { dataLayer } from 'services/gtm';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	withdrawFiatRequest,
	withdrawFiatSuccess,
	calculateFiatWithdrawalRequest,
	calculateFiatWithdrawalSuccess,
	withdrawFiatInitState,
} from './reducer';
import {
	IWithdrawPaypalPayload,
	ICalculateFiatWithdrawalPayload,
	ICalculateFiatWithdrawalResponse,
	IWithdrawPaypalResponse,
} from './types';

// =============================================================:
function* withdrawFiatRequestWorker({ payload }: PayloadAction<IWithdrawPaypalPayload>) {
	const { params, onSuccess, onError } = payload;
	try {
		yield put(showLoading());
		const response: IWithdrawPaypalResponse = yield call(
			api.withdrawFiat.withdrawPaypalRequest,
			params,
		);
		if (response.status === 'waiting') {
			notificationContainer(String(L.translate('Notification.withdrawal_wait')), 'info');
		}

		yield put(withdrawFiatSuccess());

		const { id, amount, amount_fee, type } = response;
		dataLayer.push({
			event: 'transaction',
			transaction_id: id,
			transaction_fee: amount_fee,
			transaction_fee_name: type,
			value: amount,
			currency: params.asset_code,
		});
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			onError?.(error);
		}
		yield put(withdrawFiatInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* calculateFiatWithdrawalWorker({
	payload,
}: PayloadAction<ICalculateFiatWithdrawalPayload>) {
	try {
		yield put(showLoading());
		const response: ICalculateFiatWithdrawalResponse = yield call(
			api.withdrawFiat.calculateFiatWithdrawal,
			payload,
		);

		yield put(calculateFiatWithdrawalSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawFiatInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* withdrawFiatSaga() {
	yield takeEvery(withdrawFiatRequest.type, withdrawFiatRequestWorker);
	yield takeEvery(calculateFiatWithdrawalRequest.type, calculateFiatWithdrawalWorker);
}
