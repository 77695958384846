/* eslint-disable @typescript-eslint/naming-convention */
import { FC, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { getCurrentType } from 'redux/reducers/p2p/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { NavLink } from 'react-router-dom';
import { numberExists, trancDecimal } from 'services/utils/number';
import P2PTradeModal from '../P2PTradeModal';
import { P2PItemProps } from './types';

/* eslint-disable no-debugger */
const P2PItem: FC<P2PItemProps> = ({ data, selectItem, handleAction, activeId, setActiveId }) => {
	// const dispatch = useDispatch();
	const {
		id,
		username,
		new_payment_method_eng,
		payment_method_id,
		current_fiat_price,
		current_fiat_code,
		code,
		amount_max,
		amount_min,
		amount_left,
		status,
		total_trades,
		successful_trades,
		is_active,
		payment_details,
		user_id,
	} = data;

	const type = useSelector(getCurrentType);
	const userData = useSelector(getUserSettingsData);
	// const handleRemoveAdvertisement = () => {
	// 	dispatch(getP2PDeletedRequest({ id }));
	// };
	// const handleClick = (e:MouseEvent<HTMLAnchorElement>) =>{
	// 	if()
	// 	e.preventDefault();
	// 	handleAction()
	// }

	const getClassByType = (typeValue: string): string => {
		if (typeValue === 'sell') return 'button button--buy button--red';
		if (typeValue === 'buy') return 'button button--buy';

		return 'td-name';
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">{L.translate('P2P.advertisers')}</p>
				<div className="p2p-provider-info">
					<div className="p2p-provider">
						<div className="p2p-provider__letter">{username[0].toUpperCase()}</div>
						<NavLink
							onClick={handleAction}
							to={{
								pathname: '/p2p-user-center',
								state: { currency: selectItem },
								search: `profilename=${username}`,
							}}
							className="p2p-provider__name"
						>
							<span className="advertiser__title">{username}</span>
						</NavLink>
						<span className="p2p-provider__check">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="8"
								height="6"
								viewBox="0 0 8 6"
								fill="none"
							>
								<path
									d="M1.16699 2.99995L3.05366 4.88661L6.83366 1.11328"
									stroke="white"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</div>
					<div className="p2p-provider-details">
						<p>
							{total_trades} {String(L.translate('P2P.Marketplace.orders'))}
						</p>
						<p>
							{total_trades === 0 ? 0 : ((successful_trades * 100) / total_trades).toFixed(2)}%{' '}
							{String(L.translate('P2P.Marketplace.completion'))}
						</p>
					</div>
				</div>
			</div>
			<div className="td">
				<span className="td-name td-name--medium">
					{numberExists(current_fiat_price) && trancDecimal(current_fiat_price, 3)}{' '}
					{current_fiat_code.toUpperCase()}
				</span>
			</div>
			<div className="td">
				<div className="td-stats">
					<div className="td-stats__row">
						<span className="td-stats__title">
							{String(L.translate('P2P.Marketplace.available'))}:
						</span>
						<span className="td-stats__desc">
							{numberExists(status?.amount_left || amount_left) &&
								(status?.amount_left || +amount_left).toFixed(8)}{' '}
							{code.toUpperCase()}
						</span>
					</div>
					<div className="td-stats__row">
						<span className="td-stats__title">{String(L.translate('P2P.Marketplace.limit'))}:</span>
						<span className="td-stats__desc">
							{numberExists(amount_min) && (+amount_min).toFixed(8)}-
							{(numberExists(status?.amount_left) && status?.amount_left.toFixed(8)) ||
								(numberExists(amount_max) && (+amount_max).toFixed(8))}
						</span>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Payment</p>
				<div className="payment-method-list">
					{payment_details?.map((item) => (
						<div className="payment-method payment-method--citybank">{item.payment_name_eng}</div>
					))}
				</div>
			</div>
			<div className="td td--right">
				{userData?.id !== user_id && (
					<button onClick={() => setActiveId(id)} type="button" className={getClassByType(type)}>
						{type === 'buy' ? String(L.translate('P2P.buy')) : String(L.translate('P2P.sell'))}{' '}
						{code?.toUpperCase()}
					</button>
				)}
			</div>
			{activeId === id && (
				<P2PTradeModal
					item={data}
					handleAction={handleAction}
					onClose={() => setActiveId(null)}
					selectItem={selectItem}
				/>
			)}
		</div>
	);
};

export default P2PItem;
