/* eslint-disable react/require-default-props */
import { FC, useState, MouseEvent, useEffect, useMemo } from 'react';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { getWorkspaceSettings, getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getBorrowSuccessState, getMarginWallets } from 'redux/reducers/marginWallets/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { getAssetPairsList } from 'redux/reducers/assetPairs/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { setBorrowSuccess } from 'redux/reducers/marginWallets/reducer';
import BuySellWrapper from './BuySellWrapper';
import MarginTransferModal from '../MarginModal/CrossModal/MarginTransferModal';
import IsolatedTransferModal from '../MarginModal/IsolatedModal/IsolatedTransferModal';
import CrossMargin from '../MarginModal/CrossModal';
import IsolatedModal from '../MarginModal/IsolatedModal';
import RiskMargin from './RiskMargin';
import SuccessModal from '../MarginModal/SuccessPopup';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

interface ITradeBox {
	mobileSwitchType?: string;
	setMobileSwitchType?: (value: string) => void;
}

const SpotTradesBox: FC<ITradeBox> = ({ mobileSwitchType, setMobileSwitchType }) => {
	const dispatch = useDispatch();

	const workspaceSettings = useSelector(getWorkspaceSettings);
	const currentPair = useSelector(getCurrentPair);
	const [orderType, setOrderType] = useState('limit_order');
	const [currentOrderTypeTab, setCurrentOrderTypeTab] = useState('buy');
	const [marketType, setMarketType] = useState('spot');
	const [openModal, setOpenModal] = useState(false);
	const [openBorrowModal, setOpenBorrowModal] = useState(false);
	const [tradeBoxOpened, setTradeBoxOpened] = useState(false);
	const marginData = useSelector(getMarginWallets);
	const userData = useSelector(getUserSettingsData);
	const isAuth = useSelector(getAuthIsAuthenticated);

	const assetPair = useSelector(getAssetPairsList);
	const assetPairData = assetPair || [];
	const borrowState = useSelector(getBorrowSuccessState);

	const closeModal = (): void => {
		setOpenModal(false);
		setOpenBorrowModal(false);
	};

	const shoulder = useMemo(() => {
		const currentAssetPair = assetPairData.find(({ code }) => code === currentPair);
		return {
			isolated: currentAssetPair?.shoulder_isolate,
			cross: currentAssetPair?.shoulder_cross,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetPairData.length, currentPair]);

	const location = useLocation();
	/* eslint-disable no-debugger */
	const marginTypeSearch = new URLSearchParams(location.search).get('type');

	useEffect(() => {
		if (marginTypeSearch) {
			setMarketType(marginTypeSearch);
		}
	}, [marginTypeSearch]);

	const handleChangeTrade = (name: string): void => {
		setMarketType(name);
	};

	const handleChangeOrderType = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setOrderType(name);
	};

	const handleChangeCurrentOrderTypeTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setMobileSwitchType?.(name);
		setCurrentOrderTypeTab(name);
	};

	const handleMobileSwitchClick = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setMobileSwitchType?.(name);
		if (!workspaceSettings?.orderBookSection || !workspaceSettings?.tradingPairsSection) {
			setCurrentOrderTypeTab(name);
		}
	};

	useEffect(() => {
		if (
			(!workspaceSettings?.orderBookSection || !workspaceSettings?.tradingPairsSection) &&
			mobileSwitchType &&
			currentOrderTypeTab !== mobileSwitchType &&
			!tradeBoxOpened
		) {
			setCurrentOrderTypeTab(mobileSwitchType);
			setTradeBoxOpened(true);
		}
		return () => {
			setTradeBoxOpened(false);
		};
	}, [
		currentOrderTypeTab,
		mobileSwitchType,
		tradeBoxOpened,
		workspaceSettings?.orderBookSection,
		workspaceSettings?.tradingPairsSection,
	]);

	// useEffect(() => {
	// 	if (marketType === 'cross') {
	// 		if (Number(marginData.marginCrossWallets?.data.total_in_btc) <= 0.0001) {
	// 			notificationContainer(
	// 				`Please complete the transfer transaction in your ${marketType} margin account first`,
	// 				'error',
	// 			);
	// 		}
	// 	} else if (marketType === 'isolated') {
	// 		if (Number(marginData.marginIsolatedWallets?.data.total_in_btc) <= 0.0001) {
	// 			notificationContainer(
	// 				`Please complete the transfer transaction in your ${marketType} margin account first`,
	// 				'error',
	// 			);
	// 		}
	// 	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [marketType]);

	useEffect(() => {
		if (isAuth) {
			dispatch(userSettingsRequest());
			if (marketType !== 'spot') {
				if (userData?.status_id !== 2) {
					notificationContainer(
						String(L.translate('Trade.Spot.SpotTradeBox.complete_kyc')),
						'error',
					);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData?.status_id]);

	useEffect(() => {
		if (openModal || openBorrowModal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
		return () => {
			document.body.style.overflow = '';
		};
	}, [openModal, openBorrowModal]);

	const oldLayout = (
		<div className="trade-form-box">
			{marketType === 'spot' ? (
				<div className="trade-switch">
					<div
						className={`trade-switch__item ${
							marketType === 'spot' ? 'trade-switch__item--active' : ''
						}`}
					>
						{L.translate('Trade.Spot.SpotTradeBox.spot_trading')}
					</div>
					{/* <NavLink
						to={{
							pathname: `/MarginTrading/${currentPair?.toUpperCase()}`,
							search: '?type=cross',
						}}
						onClick={() => handleChangeTrade('cross')}
						className="trade-switch__item"
					>
						{L.translate('Trade.Spot.SpotTradeBox.margin_trading')}
					</NavLink> */}
				</div>
			) : (
				<div className="trade-switch">
					<NavLink
						to={`${currentPair?.toUpperCase()}?type=cross`}
						onClick={() => handleChangeTrade('cross')}
						className={`trade-switch__item ${
							marketType === 'cross' ? 'trade-switch__item--active' : ''
						}`}
					>
						{L.translate('Trade.Margin.TradeBox.cross_trading')} {shoulder.cross}x
					</NavLink>
					<NavLink
						to={`${currentPair?.toUpperCase()}?type=isolated`}
						onClick={() => handleChangeTrade('isolated')}
						className={`trade-switch__item ${
							marketType === 'isolated' ? 'trade-switch__item--active' : ''
						}`}
					>
						{L.translate('Trade.Margin.TradeBox.isolated_trading')} {shoulder.isolated}x
					</NavLink>
				</div>
			)}
			<div className="trade-form-box__content">
				<div className="tabs-type tabs-type--mobile">
					<button
						type="button"
						className={`tabs-type__item ${
							mobileSwitchType === 'buy' ? 'tabs-type__item--active' : ''
						}`}
						name="buy"
						onClick={handleMobileSwitchClick}
					>
						{L.translate('Trade.Spot.SpotTradeBox.buy_button')}
					</button>
					<button
						type="button"
						className={`tabs-type__item ${
							mobileSwitchType === 'sell' ? 'tabs-type__item--active' : ''
						}`}
						name="sell"
						onClick={handleMobileSwitchClick}
					>
						{L.translate('Trade.Spot.SpotTradeBox.sell_button')}
					</button>
				</div>
				{(!workspaceSettings?.orderBookSection || !workspaceSettings?.tradingPairsSection) && (
					<div className="tabs-type tabs-type--desktop">
						<button
							className={`tabs-type__item ${
								currentOrderTypeTab === 'buy' ? 'tabs-type__item--active' : ''
							}`}
							type="button"
							name="buy"
							onClick={handleChangeCurrentOrderTypeTab}
						>
							{L.translate('Trade.Spot.SpotTradeBox.buy_button')}
						</button>
						<button
							className={`tabs-type__item ${
								currentOrderTypeTab === 'sell' ? 'tabs-type__item--active' : ''
							}`}
							type="button"
							name="sell"
							onClick={handleChangeCurrentOrderTypeTab}
						>
							{L.translate('Trade.Spot.SpotTradeBox.sell_button')}
						</button>
					</div>
				)}

				<div className="trade-tabs-wrapper">
					<div
						className={`trade-tabs ${
							!workspaceSettings?.orderBookSection || !workspaceSettings?.tradingPairsSection
								? 'trade-tabs--small'
								: ''
						}`}
					>
						<button
							className={`trade-tabs__item ${
								orderType === 'limit_order' ? 'trade-tabs__item--active' : ''
							}`}
							type="button"
							name="limit_order"
							onClick={handleChangeOrderType}
						>
							{L.translate('Trade.Spot.SpotTradeBox.limit_order_name')}
						</button>
						<button
							className={`trade-tabs__item ${
								orderType === 'market_order' ? 'trade-tabs__item--active' : ''
							}`}
							type="button"
							name="market_order"
							onClick={handleChangeOrderType}
						>
							{L.translate('Trade.Spot.SpotTradeBox.market_order_name')}
						</button>
						<button
							className={`trade-tabs__item ${
								orderType === 'stop_limit_order' ? 'trade-tabs__item--active' : ''
							}`}
							type="button"
							name="stop_limit_order"
							onClick={handleChangeOrderType}
						>
							{L.translate('Trade.Spot.SpotTradeBox.stop_limit_order_name')}
							<span className="icon-question" />
						</button>
					</div>
					{marketType !== 'spot' && (
						<div className="trade-tabs">
							<RiskMargin type={marketType} pair={currentPair} />
							{isAuth && (
								<>
									<button
										onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
										type="button"
										className="trade-tabs__item"
									>
										{L.translate('Trade.Spot.SpotTradeBox.transfer')}
									</button>
									<button
										onClick={() => setOpenBorrowModal((prevOpenModal) => !prevOpenModal)}
										type="button"
										className="trade-tabs__item"
									>
										{L.translate('Trade.Spot.SpotTradeBox.borrow')}
									</button>
								</>
							)}
							{marketType === 'cross' ? (
								<>
									<MarginTransferModal
										openModal={openModal}
										closeModal={closeModal}
										assetCode={openModal ? currentPair?.split('_')[0] : ''}
									/>
									<CrossMargin
										openModal={openBorrowModal}
										closeModal={closeModal}
										active="borrow"
										assetCode={openModal ? currentPair?.split('_')[0] : ''}
									/>
								</>
							) : (
								<>
									<IsolatedTransferModal
										openModal={openModal}
										closeModal={closeModal}
										assetCode={openModal ? currentPair?.split('_')[0] || 'btc' : ''}
										assetPair={openModal ? currentPair : ''}
									/>
									<IsolatedModal
										openModal={openBorrowModal}
										closeModal={closeModal}
										assetPair={openModal ? currentPair : ''}
										active="borrow"
										assetCode={openModal ? currentPair?.split('_')[0] || 'btc' : ''}
									/>
								</>
							)}
						</div>
					)}
				</div>
				<div className="trade-form">
					<div className="trade-form__group">
						{currentOrderTypeTab === 'buy' ? (
							<BuySellWrapper
								orderType={orderType}
								mode="Buy"
								marketType={marketType}
								mobOpened={mobileSwitchType === 'buy'}
							/>
						) : (
							<BuySellWrapper
								orderType={orderType}
								mode="Sell"
								marketType={marketType}
								mobOpened={mobileSwitchType === 'sell'}
							/>
						)}

						{workspaceSettings?.orderBookSection && workspaceSettings?.tradingPairsSection && (
							<BuySellWrapper
								orderType={orderType}
								mode="Sell"
								marketType={marketType}
								mobOpened={mobileSwitchType === 'sell'}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<SocketWithPrivateTopicConnectWrapper
			topicName={`${marketType === 'spot' ? 'balances' : `balances:${String(marketType)}`}`}
		>
			<SocketWithPrivateTopicConnectWrapper topicName="index_prices">
				{borrowState && (
					<SuccessModal
						type={borrowState.type}
						closeModal={() => dispatch(setBorrowSuccess(null))}
					/>
				)}
				<div className="market-block market-block--padding-none">{oldLayout}</div>
			</SocketWithPrivateTopicConnectWrapper>
		</SocketWithPrivateTopicConnectWrapper>
	);
};

export default SpotTradesBox;
