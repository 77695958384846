import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { WithdrawalError } from './WithdrawalError';
import { WithdrawalSuccess } from './WithdrawalSuccess';

export const PopusModal = () => {
	const location = useLocation();
	const type = location.pathname;

	const currentPopup = (val: string) => {
		switch (val) {
			case '/submited':
				return <WithdrawalSuccess />;
			case '/failure':
				return <WithdrawalError />;
			default:
				return <WithdrawalError />;
		}
	};

	return <>{type && currentPopup(type)}</>;
};
