import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import { getConvertFeesRequest } from 'redux/reducers/ourPrices/reducer';
import { getConvertFees, getConvertFeesLoading } from 'redux/reducers/ourPrices/selectors';

const ConvertFeesTable = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getConvertFeesLoading);
	const convertFees = useSelector(getConvertFees);

	useEffect(() => {
		const fetchData = () => {
			dispatch(getConvertFeesRequest());
		};
		fetchData();
		const intervalId = setInterval(() => {
			fetchData();
		}, 10000);
		return () => {
			clearInterval(intervalId);
		};
	}, [dispatch]);

	return (
		<div className="table table--prices-type table--prices-crypto-convert">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.currency')}</p>
					</div>
					<div className="td td--right">
						<p className="td-title">{L.translate('OurPrices.Columns.regular_exchange')}</p>
					</div>
				</div>
			</div>
			<div className="table-body">
				{convertFees?.map((item) => (
					<div key={item.id} className="tr">
						<div className="td">
							<p className="td-hidden-name">Currency</p>
							<p className="td-name">{item.code?.toUpperCase()}</p>
						</div>
						<div className="td td--right">
							<p className="td-hidden-name">Regular exchange %</p>
							<p className="td-name">{item.exchange_fee}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ConvertFeesTable;
