import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import L from 'i18n-react';
import { getEmailConfirm, getEmailConfirmIsLoad } from 'redux/reducers/auth/selectors';
import Loader from 'ui/Loader';
import CheckGreenIcon from 'assets/img/icons/check_green_icon.svg';
import { IErrorProps } from './types';

// ==========================================:
const EmailConfirmSuccess: FC<IErrorProps> = ({ error }) => {
	const emailConfirmLoader = useSelector(getEmailConfirmIsLoad);
	const emailConfirm = useSelector(getEmailConfirm);
	console.log(error);
	return (
		<div className="additional">
			{emailConfirm ? (
				<>
					{emailConfirmLoader ? (
						<div className="additional__content">
							<img src={CheckGreenIcon} alt="check green icon" className="additional__icon" />
							<p className="additional__desc">
								{String(
									L.translate('EmailConfirm.EmailConfirmSuccess.email_confirm_success_title'),
								)}
							</p>
							<div className="additional__footer">
								<Link to="/login" className="button button--full-width">
									{String(
										L.translate('EmailConfirm.EmailConfirmSuccess.email_confirm_success_btn'),
									)}
								</Link>
							</div>
						</div>
					) : (
						<Loader />
					)}
				</>
			) : (
				<>
					{emailConfirmLoader ? (
						<Loader />
					) : (
						<div className="additional__content">
							<h3 className="additional__title">
								{error === 'the_selected_token_is_invalid'
									? String(
											L.translate('EmailConfirm.EmailConfirmError.email_confirm_error_title_text'),
									  )
									: String(L.translate('EmailConfirm.EmailConfirmSuccess.email_confirm_completed'))}
							</h3>
							<p className="additional__desc">
								{error === 'the_selected_token_is_invalid'
									? String(L.translate('EmailConfirm.EmailConfirmError.email_confirm_error_text'))
									: String(
											L.translate('EmailConfirm.EmailConfirmSuccess.email_confirm_completed_text'),
									  )}
							</p>
							<div className="additional__footer">
								{error === 'the_selected_token_is_invalid' ? (
									<Link to="/login" className="button button--full-width">
										{String(
											L.translate('EmailConfirm.EmailConfirmSuccess.email_confirm_success_btn'),
										)}
									</Link>
								) : (
									<Link to="/login" className="button button--full-width">
										{String(
											L.translate('EmailConfirm.EmailConfirmSuccess.email_confirm_success_btn'),
										)}
									</Link>
								)}
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default EmailConfirmSuccess;
