/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetPairsFeesAndLimitsList } from 'redux/reducers/assetPairs/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { calculateLimitOrderRequest, createOrderRequest } from 'redux/reducers/orders/reducer';
import { getTempOrderPrice } from 'redux/reducers/orders/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getSpotRecentTrades } from 'redux/reducers/spotTrade/selectors';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { numberValidation } from 'services/utils/numberValidation';
import { dataLayer } from 'services/gtm';
import { notificationContainer } from 'services/utils/notificationContainer';
import TextError from 'ui/Formik/TextError';
import LoginOrRegister from 'ui/LoginOrRegister';
import useDecimals from 'hooks/useDecimals';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import PercentRadioButtons from '../PercentRadioButtons';
import { ILimitOrderProps } from './types';

const LimitOrder: FC<ILimitOrderProps> = ({
	mode,
	assetToTradeCode,
	assetBalanceCode,
	balance,
	marketType,
}) => {
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const userData = useSelector(getUserSettingsData);
	const userIsVip = !!userData?.is_vip;
	const currentPair = useSelector(getCurrentPair);
	const assetPairsFeesAndLimit = useSelector(getAssetPairsFeesAndLimitsList);
	const tempOrderPrice = useSelector(getTempOrderPrice);
	const recentTrades = useSelector(getSpotRecentTrades);

	const dispatch = useDispatch();

	const [priceDecimal] = useDecimals();

	const firstRecentTradeItem = recentTrades?.length && recentTrades[0]?.price;

	const initPrice = String(
		fixedCropNumber(Number(firstRecentTradeItem), priceDecimal) || firstRecentTradeItem || '',
	);

	const currentAssetPairLimits = useMemo(() => {
		return assetPairsFeesAndLimit?.length
			? assetPairsFeesAndLimit.find((pair) => pair.code === currentPair)
			: null;
	}, [assetPairsFeesAndLimit, currentPair]);

	const FunAmountLimitDeleteE = () => {
		if (userIsVip) {
			if (currentAssetPairLimits?.vip_amount_min.toString().includes('e') === true) {
				const numberFix = Number(currentAssetPairLimits?.vip_amount_min.toString().split('-')[1]);
				currentAssetPairLimits?.vip_amount_min.toFixed(Number(numberFix));
				return currentAssetPairLimits?.vip_amount_min.toFixed(Number(numberFix));
			}
			return currentAssetPairLimits?.vip_amount_min || 0;
		}
		if (currentAssetPairLimits?.amount_min.toString().includes('e') === true) {
			const numberFix = Number(currentAssetPairLimits?.amount_min.toString().split('-')[1]);
			currentAssetPairLimits?.amount_min.toFixed(Number(numberFix));
			return currentAssetPairLimits?.amount_min.toFixed(Number(numberFix));
		}
		return currentAssetPairLimits?.amount_min || 0;
	};
	const currentAssetPairLimitsAmountMin = FunAmountLimitDeleteE();
	const currentAssetPairLimitsAmountMax = userIsVip
		? currentAssetPairLimits?.vip_amount_max || 0
		: currentAssetPairLimits?.amount_max || 0;
	const initState = {
		pair_code: currentPair,
		quantity: '',
		price: initPrice,
		type: mode.toLowerCase(),
	};

	const [limitOrderState, setLimitOrderState] = useState(initState);
	const [total, setTotal] = useState('');
	const [isInitPrice, setIsInitPrice] = useState(false);
	const [newPair, setNewPair] = useState(false);

	const isDisabled =
		!Number(limitOrderState.price) ||
		!Number(limitOrderState.quantity) ||
		Number(limitOrderState.quantity) > currentAssetPairLimitsAmountMax ||
		Number(limitOrderState.quantity) < +currentAssetPairLimitsAmountMin;

	const isMaxAmountError =
		authIsAuthenticated &&
		Number(limitOrderState.quantity) &&
		Number(limitOrderState.quantity) > currentAssetPairLimitsAmountMax;

	const isMaxAmountSellError =
		authIsAuthenticated &&
		Number(limitOrderState.quantity) &&
		mode === 'Sell' &&
		Number(limitOrderState.quantity) > balance;

	// const isMaxAmountBuyError =
	// 	authIsAuthenticated &&
	// 	Number(limitOrderState.quantity) &&
	// 	mode === 'Buy' &&
	// 	Number(limitOrderState.quantity) > balance;
	const isMinAmountError =
		authIsAuthenticated &&
		Number(limitOrderState.quantity) &&
		Number(limitOrderState.quantity) < +currentAssetPairLimitsAmountMin;

	const isBuyTotalError =
		authIsAuthenticated && mode === 'Buy' && Number(total) && Number(total) > balance;

	useEffect(() => {
		if (currentPair && currentPair !== limitOrderState.pair_code) {
			setLimitOrderState({
				...initState,
				pair_code: currentPair,
				price: initPrice,
			});
			setNewPair(true);
		}
		// setTotal('');
	}, [currentPair, initPrice]);

	useEffect(() => {
		if (newPair && initPrice !== limitOrderState.price) {
			setLimitOrderState({
				...initState,
				price: initPrice,
			});
			setNewPair(false);
		}
		// setTotal('');
	}, [newPair, initPrice]);

	useEffect(() => {
		if (tempOrderPrice !== null) {
			setLimitOrderState({
				...initState,
				price: String(tempOrderPrice || ''),
			});
		}
	}, [tempOrderPrice]);

	useEffect(() => {
		if (initPrice && !limitOrderState.price && !isInitPrice) {
			setLimitOrderState((prev) => ({ ...prev, price: initPrice }));
			setIsInitPrice(true);
		}
	}, [initPrice, limitOrderState.price, isInitPrice]);

	useEffect(() => {
		const { quantity, price } = limitOrderState;

		if (
			authIsAuthenticated &&
			currentPair &&
			Number(quantity) &&
			Number(price) &&
			mode &&
			!isDisabled &&
			!isBuyTotalError &&
			!isMaxAmountSellError
		) {
			const caclulateRequestData = {
				pair_code: initState.pair_code,
				quantity: Number(quantity),
				price: Number(price),
				type: mode.toLowerCase(),
				market_type: marketType,
			};

			dispatch(calculateLimitOrderRequest(caclulateRequestData));
		}
	}, [currentPair, limitOrderState, mode]);

	const handleChangeTotal = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (numberValidation(value) && limitOrderState.price) {
			setTotal(value);

			const amount = Number(value) / Number(limitOrderState.price);

			setLimitOrderState({
				...limitOrderState,
				quantity: String(fixedCropNumber(amount, 6) || 0),
			});
		}
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;

		if (numberValidation(value)) {
			setLimitOrderState({
				...limitOrderState,
				[name]: value,
			});

			if (name === 'price' && limitOrderState.quantity) {
				setTotal((Number(limitOrderState.quantity) * Number(value)).toFixed(6));
			}

			if (name === 'quantity' && limitOrderState.price) {
				if (+value > currentAssetPairLimitsAmountMax || +value < +currentAssetPairLimitsAmountMin) {
					setTotal('');
				} else {
					setTotal((Number(limitOrderState.price) * +value).toFixed(6));
				}
			}
		}
	};

	const handleTradeClick = () => {
		const { quantity, price } = limitOrderState;

		if (userData?.status.name === 'unverified') {
			notificationContainer(String(L.translate('Trade.need_verify')), 'info');
			return;
		}

		if (quantity && price) {
			dataLayer.push({
				event: mode === 'Buy' ? 'buy_BTC' : 'sell_BTC',
			});
			if (marketType === 'cross' || marketType === 'isolated') {
				const createOrderData = {
					...limitOrderState,
					quantity: Number(quantity),
					price: Number(price),
					type: mode.toLowerCase(),
					[`${marketType === 'cross' ? 'is_margin' : 'is_isolated'}`]: true,
				};
				dispatch(createOrderRequest(createOrderData));
				setLimitOrderState({
					...initState,
				});
				setTotal('');
				return;
			}
			const createOrderData = {
				...limitOrderState,
				type: mode.toLowerCase(),
				quantity: Number(quantity),
				price: Number(price),
			};

			dispatch(createOrderRequest(createOrderData));
		}

		setLimitOrderState({
			...initState,
		});
		setTotal('');
	};

	const countOrder = (value: number) => {
		if (limitOrderState.price && value >= 0) {
			setLimitOrderState({
				...limitOrderState,
				quantity: String(fixedCropNumber(Number(value), 6)),
			});

			setTotal(String(fixedCropNumber(Number(limitOrderState.price) * value, 6)));
		} else if (mode === 'Sell') {
			setLimitOrderState({
				...limitOrderState,
				quantity: String(fixedCropNumber(Number(value), 6)),
			});
		}
	};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!balance || !Number(percentValue)) {
			return 0;
		}

		if (mode === 'Buy' && Number(limitOrderState?.price)) {
			return (balance / Number(limitOrderState?.price)) * percentValue;
		}

		return balance * percentValue;
	};

	const getFieldClass = (fieldName: string): string => {
		switch (fieldName) {
			case 'amount':
				return isMaxAmountError || isMinAmountError || isMaxAmountSellError
					? 'trade-form__input input-form__item--error'
					: 'trade-form__input';

			case 'total':
				return isBuyTotalError && mode === 'Buy'
					? 'trade-form__input input-form__item--error'
					: 'trade-form__input';

			default:
				return 'trade-form__input';
		}
	};

	return (
		<>
			<div className="trade-form__item">
				<div className="input input-order-item">
					<div className="input-wrapper">
						<label className="trade-form__input">
							<p className="input__name">
								{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.price_field_name')}
							</p>
							<input
								className="input-item input-item--transparent input-item--right"
								type="text"
								placeholder="0.00"
								autoComplete="off"
								value={limitOrderState.price}
								name="price"
								onChange={handleChangeInput}
							/>
							<p className="input__name">{assetBalanceCode?.toUpperCase()}</p>
						</label>
					</div>
				</div>
			</div>
			<div className="trade-form__item">
				<div className="input">
					<div className="input-wrapper">
						<label className={getFieldClass('amount')}>
							<p className="input__name">
								{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.amount_field_name')}
							</p>
							<input
								className="input-item input-item--transparent input-item--right"
								type="text"
								placeholder="0.00"
								autoComplete="off"
								value={limitOrderState.quantity}
								name="quantity"
								onChange={handleChangeInput}
							/>
							<p className="input__name">{assetToTradeCode?.toUpperCase()}</p>
						</label>
						{isMaxAmountError ? (
							<TextError>
								{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.max_amount_error_text')}{' '}
								{currentAssetPairLimitsAmountMax} {assetToTradeCode?.toUpperCase()}
							</TextError>
						) : null}

						{isMinAmountError ? (
							<TextError>
								{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.min_amount_error_text')}{' '}
								{currentAssetPairLimitsAmountMin} {assetToTradeCode?.toUpperCase()}
							</TextError>
						) : null}

						{isMaxAmountSellError ? (
							<TextError>
								{L.translate(
									'Trade.Spot.SpotTradeBox.LimitOrder.amount_available_balance_error_text',
								)}
							</TextError>
						) : null}
						{/* {isMaxAmountBuyError ? (
							<TextError>
								{L.translate(
									'Trade.Spot.SpotTradeBox.LimitOrder.amount_available_balance_error_text',
								)}
							</TextError>
						) : null} */}
					</div>
				</div>
			</div>
			<PercentRadioButtons
				countOrder={countOrder}
				percentButtonCountValue={percentButtonCountValue}
				mode={mode}
				amount={limitOrderState.quantity}
				precents={[0.25, 0.5, 0.75, 1]}
			/>
			<div className="trade-form__item">
				<div className="input input-order-item">
					<div className="input-wrapper">
						<label className={getFieldClass('total')}>
							<p className="input__name">
								{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.total_field_name')}
							</p>
							<input
								className="input-item input-item--transparent input-item--right"
								type="text"
								placeholder="0.00"
								autoComplete="off"
								name="total"
								onChange={handleChangeTotal}
								value={total}
							/>
							<p className="input__name">{assetBalanceCode?.toUpperCase()}</p>
						</label>
						{authIsAuthenticated && isBuyTotalError && mode === 'Buy' ? (
							<TextError>
								{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.total_error_text')}
							</TextError>
						) : null}
					</div>
				</div>
			</div>
			{authIsAuthenticated ? (
				<button
					className={`button button--normal-height button--full-width ${
						mode === 'Buy' ? 'button--green' : 'button--red'
					} trade-form__btn`}
					type="button"
					onClick={handleTradeClick}
					disabled={isDisabled || !!isBuyTotalError || !!isMaxAmountSellError}
				>
					{mode === 'Buy'
						? L.translate('Trade.Spot.SpotTradeBox.OrderButtons.buy_button')
						: L.translate('Trade.Spot.SpotTradeBox.OrderButtons.sell_button')}{' '}
					{assetToTradeCode?.toUpperCase()}
				</button>
			) : (
				<div className="login-to-trade">
					<LoginOrRegister />
				</div>
			)}
		</>
	);
};

export default LimitOrder;
