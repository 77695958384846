/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect, ChangeEvent } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CSearchInput from 'ui/CSearchInput';
import CSearchInputCoin from 'ui/CSearchInputCoin';
import {
	getCurrencies,
	getP2PAssets,
	getCurrentCoin,
	getCurrencyItem,
	getProposal,
	getCurrentType,
	getCountry,
	getUserAdvert,
} from 'redux/reducers/p2p/selectors';
import { IGetAssets, IGetCurrenciesItem, IAssetsData } from 'redux/reducers/p2p/types';
import { setCurrency, setCurrentCoin, setCountry } from 'redux/reducers/p2p/reducer';
import { getCountriesRequest } from 'redux/reducers/app/reducer';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { getCountries } from 'redux/reducers/app/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { ICountry } from 'redux/reducers/app/types';
import { addImges } from 'services/utils/changeArrey';
import { IPriceBox } from './type';

const PAFPriceBox: FC<IPriceBox> = ({
	margin,
	marginChange,
	marginError,
	countryError,
	dataLoaded,
	setDataLoaded,
}) => {
	const dispatch = useDispatch();
	const currenciesList = useSelector(getCurrencies);
	const currencyItem = useSelector(getCurrencyItem);
	const persistedCountry = useSelector(getCountry);
	const type = useSelector(getCurrentType);
	const currency = useSelector(getCurrentCoin);
	const asset = useSelector(getAssetsList);
	const p2pAssets = useSelector(getP2PAssets);
	const price = useSelector(getProposal);
	const countries = useSelector(getCountries);
	const userData = useSelector(getUserSettingsData);
	const advertData = useSelector(getUserAdvert);

	const params = useParams<{ id: string }>();

	const [selectItem, setSelectItem] = useState<IGetCurrenciesItem | null>(currencyItem || null);
	const [selectAsset, setSelectAsset] = useState<IAssetsData | null>(null);
	const [selectCountry, setSelectCountry] = useState<ICountry | null>(persistedCountry);
	const marketPrice = price && price.market_price ? price && price.market_price : currency;

	const askedPrice = price && price.asked_price ? price && price.asked_price : currency;

	const pricePaidUSDIfPercent = Number(marketPrice) * (margin / 100);
	const pricePaidCurrencyIfPercent = Number(askedPrice) * (margin / 100);
	const currentAsset = p2pAssets?.find((item) => item.code === currency);

	const buyerFee = userData?.is_vip ? currentAsset?.vip_buyer_fee : currentAsset?.buyer_fee;
	const sellerFee = userData?.is_vip ? currentAsset?.vip_seller_fee : currentAsset?.seller_fee;

	const fee = type === 'buy' ? buyerFee : sellerFee;

	useEffect(() => {
		if (asset && p2pAssets) setSelectAsset(addImges(p2pAssets, asset));
	}, [asset, p2pAssets]);

	useEffect(() => {
		if (countries) {
			return;
		}
		dispatch(getCountriesRequest());
	}, [countries, dispatch]);

	useEffect(() => {
		if (advertData && params?.id && params?.id === String(advertData?.id) && !dataLoaded) {
			dispatch(setCurrentCoin(advertData.asset.code));
			const currencyFiat = currenciesList?.find(
				(item) => item.code === advertData.current_fiat_code,
			);
			if (currencyFiat) {
				setSelectItem(currencyFiat);
				dispatch(setCurrency(currencyFiat));
			}
			advertData.margin && marginChange(advertData.margin);
			if (advertData.country_id) {
				const currentCountry = countries?.find((item) => item.id === advertData.country_id);
				if (currentCountry) {
					setSelectCountry(currentCountry);
					dispatch(setCountry(currentCountry));
				}
			}
			setDataLoaded?.(true);
		}
	}, [
		advertData,
		countries,
		currenciesList,
		dataLoaded,
		dispatch,
		marginChange,
		params?.id,
		setDataLoaded,
	]);

	const handleSelectCoin = (value: any): void => {
		setSelectItem(value);
		dispatch(setCurrency(value));
	};

	const handleSelectCountry = (value: ICountry) => {
		setSelectCountry(value);
		dispatch(setCountry(value));
	};

	const coinClick = (value: IGetAssets) => {
		dispatch(setCurrentCoin(value.code));
	};

	const handleMarginChange = (e: ChangeEvent<HTMLInputElement>): any => {
		const { value } = e.currentTarget;

		if (Number(value) < 0) return;

		marginChange(Number(value));
	};

	return (
		<div className="create-offer__form">
			<div className="form-block">
				<div className="enter-value">
					<span className="block-label">
						{String(L.translate('Advertisement.select_currency'))}
						<div className="tooltip-wrap">
							<div className="tooltip-item tooltip-item--static">
								<span className="block-label__icon icon-info-circle" />
								<span className="tooltip tooltip--bottom tooltip--left tooltip--arrow-top-left">
									<span className="tooltip__text">
										<span>{String(L.translate('Advertisement.paragraph_1'))}</span>
										<span>{String(L.translate('Advertisement.paragraph_2'))}</span>
										<span>{String(L.translate('Advertisement.paragraph_3'))}</span>
										<span>{String(L.translate('Advertisement.paragraph_4'))}</span>
									</span>
								</span>
							</div>
						</div>
					</span>
					<div className="enter-value__field select-wrapper--p2p-coin">
						<CSearchInputCoin listData={selectAsset} onSelect={coinClick} dataItem={currency} />
					</div>
				</div>
			</div>
			<div className="form-block">
				<span className="block-label">
					{String(L.translate('Advertisement.price_details'))}
					<div className="tooltip-wrap">
						<div className="tooltip-item tooltip-item--static">
							<span className="block-label__icon icon-info-circle" />
							<span className="tooltip tooltip--bottom tooltip--left tooltip--arrow-top-left">
								<span className="tooltip__text">
									<span>{String(L.translate('Advertisement.paragraph_1'))}</span>
									<span>{String(L.translate('Advertisement.paragraph_2'))}</span>
									<span>{String(L.translate('Advertisement.paragraph_3'))}</span>
									<span>{String(L.translate('Advertisement.paragraph_4'))}</span>
								</span>
							</span>
						</div>
					</div>
				</span>
				<div className="details-list">
					<div className="details-list__row">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.market_price'))}
							</span>
						</div>
						<div className="details-list__right">
							<span className="details-list__desc">
								{marketPrice || 0} USD/{currency.toLocaleUpperCase()}
							</span>
							<span className="details-list__desc">
								{askedPrice || 0} {currencyItem?.code}/{currency.toLocaleUpperCase()}
							</span>
						</div>
					</div>
					<div className="details-list__row details-list__row--center">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.margin'))}, %:
							</span>
						</div>
						<div className="details-list__right">
							<div className={`input input--margin-none ${marginError ? 'input--error' : ''}`}>
								<div className="input-wrapper">
									<input
										className="input-item input-item--error input-item--sort"
										type="number"
										value={margin || ''}
										onChange={handleMarginChange}
										placeholder={String(L.translate('Base.margin_placeholder'))}
									/>
									{marginError && (
										<span className="input-notify--error input-notify__text">{marginError}</span>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="details-list__row details-list__row--center">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.currency'))}
							</span>
						</div>
						<div className="details-list__right">
							<CSearchInput
								currenClass='select--full-width details-list__select"'
								listData={currenciesList}
								onSelect={handleSelectCoin}
								dataItem={selectItem?.code}
								start="0"
							/>
						</div>
					</div>

					<div className="details-list__row details-list__row--center">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.country'))}
							</span>
						</div>
						<div className="details-list__right">
							<CSearchInput
								currenClass={`select--full-width details-list__select ${
									countryError ? 'select--error' : ''
								}`}
								listData={countries}
								onSelect={handleSelectCountry}
								dataItem={selectCountry?.code}
								start="0"
							/>
							{countryError && (
								<span className="input-notify--error input-notify__text">
									{L.translate('Advertisement.country_required')}
								</span>
							)}
						</div>
					</div>

					<div className="details-list__row">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.fee'))}
							</span>
						</div>
						<div className="details-list__right">
							<span className="details-list__desc">{fee} %</span>
						</div>
					</div>
					<div className="details-list__row">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.paid_by_seller'))}
							</span>
						</div>
						<div className="details-list__right">
							<span className="details-list__desc">
								{pricePaidUSDIfPercent} USD/{currency.toLocaleUpperCase()}
							</span>
							<span className="details-list__desc">
								{pricePaidCurrencyIfPercent} {currencyItem?.code}/{currency.toLocaleUpperCase()}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PAFPriceBox;
