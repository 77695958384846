import { ChangeEvent, FC } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import L from 'i18n-react';
import { postUploadEvidenceRequest } from 'redux/reducers/p2p/reducer';
import { toBase64 } from 'services/utils/toBase64';
import { notificationContainer } from 'services/utils/notificationContainer';
import evidancePhotosConst from '../constants';
import { IUploadEvidance } from './type';
/* eslint-disable no-debugger */

const checkFileSizes = (maxFileSize: any, filesArray: any) => {
	return filesArray.find((file: any) => {
		return file.size > maxFileSize;
	});
};

const filesArrayToBase64Array = async (filesArray: any) => {
	const arr = filesArray.map(async (item: any) => {
		const b64 = await toBase64(item);
		return { file: b64 };
	});

	return Promise.all(arr);
};

const { acceptTypeFiles, maxFileAmountMb, maxFileAmount, maxFileSize } = evidancePhotosConst;

const UploadEvidance: FC<IUploadEvidance> = ({
	trade,
	existedPhotos,
	getExistedPhotos,
	setLoading,
}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { search } = location;

	const tradeId = queryString.parse(search).trade_id || null;

	const handleFile = async (event: ChangeEvent<HTMLInputElement>) => {
		const allFiles: FileList | null | any = event.currentTarget.files;
		const filesArray = [...allFiles];

		const base64Array = await filesArrayToBase64Array(filesArray);

		if (Number(existedPhotos?.length) + Number(filesArray?.length) > maxFileAmount) {
			return notificationContainer(
				`${String(L.translate('P2P.t_0471'))} ${maxFileAmount}`,
				'error',
			);
		}

		if (checkFileSizes(maxFileSize, filesArray)) {
			return notificationContainer(
				`${String(L.translate('P2P.t_0472'))} ${maxFileAmountMb}`,
				'error',
			);
		}

		const body = {
			trade_id: trade?.id,
			files: base64Array,
		};

		return dispatch(postUploadEvidenceRequest({ body, callback: getExistedPhotos }));
	};
	return (
		<div className="file-upload">
			<label>
				<input
					type="file"
					className="hidden"
					onChange={handleFile}
					accept={String(acceptTypeFiles)}
					multiple
				/>
				<span className="file-upload__item">{L.translate('P2P.upload_evidence')}</span>
			</label>
		</div>
	);
};

export default UploadEvidance;
// eslint-disable-next-line
