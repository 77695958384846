import { FC } from 'react';
import L from 'i18n-react';
import FooterNav from 'layouts-elements/FooterNav';
import FooterInfo from 'layouts-elements/FooterInfo';
// import FooterStaticNav from 'layouts-elements/FooterStaticNav';
import FooterCopy from 'layouts-elements/FooterCopy';
import SocialList from 'ui/SocialList';
import AppFooterLogo from 'ui/AppFooterLogo';
import footerFigure1 from 'assets/images/content/footer-figure1.webp';
import footerFigure1Dark from 'assets/images/content/footer-figure1__dark.png';
import { IFooter } from './types';

// ==========================================:
const Footer: FC<IFooter> = ({ isNew }) => {
	return (
		<footer className={`${isNew ? 'new-footer' : 'footer'}`}>
			{isNew ? (
				<>
					<div className="container">
						<div className="footer-box">
							<div className="footer-left">
								<AppFooterLogo isNew />
								<FooterNav isNew />
							</div>
							<div className="footer-right">
								<SocialList isNew />
								<FooterInfo isNew />
							</div>
						</div>
						<div className="footer-contact">
							<p>{L.translate('Footer.contact_us')}</p>
							<a href="mailto:support@bitanica.com" className="footer-link">
								support@bitanica.com
							</a>
						</div>
					</div>
					<div className="footer-figure footer-figure--type1">
						<img src={footerFigure1} className="for-light" alt="figure" loading="lazy" />
						<img src={footerFigure1Dark} className="for-dark" alt="figure" loading="lazy" />
					</div>
					<div className="footer-figure footer-figure--type2">
						<img src={footerFigure1} className="for-light" alt="figure" loading="lazy" />
						<img src={footerFigure1Dark} className="for-dark" alt="figure" loading="lazy" />
					</div>
					<div className="footer-figure footer-figure--type3">
						<img src={footerFigure1} className="for-light" alt="figure" loading="lazy" />
						<img src={footerFigure1Dark} className="for-dark" alt="figure" loading="lazy" />
					</div>
				</>
			) : (
				<>
					<div className="footer__main">
						<div className="footer-main-left">
							<FooterCopy />
							<FooterNav />
						</div>
						<FooterInfo />
					</div>
					<div className="footer__bottom">
						<AppFooterLogo />
						<SocialList />
					</div>
				</>
			)}
		</footer>
	);
};

export default Footer;
