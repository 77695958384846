import { toast, ToastOptions, Theme } from 'react-toastify';
import ToastifyComponent from 'components/ToastifyComponent';
import { toastifyConfig } from './toastifyConfig';

// ==========================================:
export const notificationContainer = (
	message: string,
	type: string,
	options?: Partial<ToastOptions>,
): void => {
	const customOptions = options || {};
	const config = {
		...toastifyConfig,
		...customOptions,
		theme: localStorage.getItem('theme') as Theme,
	};
	switch (type) {
		case 'success':
			toast.success(<ToastifyComponent message={message} />, config);
			break;
		case 'error':
			toast.error(<ToastifyComponent message={message} />, config);
			break;
		case 'info':
			toast.info(<ToastifyComponent message={message} />, config);
			break;
		default:
			break;
	}
};
