import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IReferralStore, IStatisticData, IBalancerData } from './types';

// ==========================================:
export const getReferralState = (state: IStoreState): IReferralStore => state.referral;

export const getReferral = createSelector(
	[getReferralState],
	(referral: IReferralStore) => referral,
);

// ====================================================:
export const getStatistics = createSelector(
	[getReferral],
	(referral: IReferralStore): IStatisticData | null => referral.statistic,
);
// ====================================================:
export const getBalances = createSelector(
	[getReferral],
	(referral: IReferralStore): IBalancerData | null => referral.balance,
);
// ====================================================:
export const getReferralBalanceLoad = createSelector(
	[getReferral],
	(referral: IReferralStore): boolean => referral.balanceLoader,
);
