import EN from './EN.json';
// import GE from './GE.json';
import RU from './RU.json';
import UA from './UA.json';
import { ILanguages } from './types';

export const languages: ILanguages = {
	EN,
	// GE,
	RU,
	UA,
};
