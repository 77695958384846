import { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import TextError from 'ui/Formik/TextError';
import DCDropdown from 'ui/DCDropdown';
import { IDCDropdownField } from './types';

export const DCDropdownField: FC<IDCDropdownField> = ({ name, label, options, ...rest }) => {
	const [field, meta] = useField(name);
	const { submitCount, setFieldValue, setFieldTouched } = useFormikContext();
	const isShowError = (meta.touched || submitCount > 0) && meta.error;

	const { value } = field;

	const selectedValue = options?.find((item) => item.asset.id === value) || null;

	return (
		<div className="input">
			<div className="input__name">{label}</div>
			<DCDropdown
				{...rest}
				onChange={(coin) => {
					setFieldTouched(name, true);
					setFieldValue(name, coin.asset.id);
				}}
				value={selectedValue}
				options={options}
				onBloor={() => {
					setFieldTouched(name, true);
				}}
				error={!!isShowError}
			/>
			{isShowError ? <TextError>{meta.error}</TextError> : null}
		</div>
	);
};
