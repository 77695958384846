import { FC, useState } from 'react';
import L from 'i18n-react';
import * as yup from 'yup';
import { ICardItem } from 'redux/reducers/cards/types';
import { CheckIcon, CloseIcon, TrashIcon } from 'assets/svg-icon';
import { Field, Form, Formik } from 'formik';
import {
	updateCardRequest,
	verifyCardRequest,
	getCardsRequest,
} from 'redux/reducers/cards/reducer';
import { getVerifyLoading } from 'redux/reducers/cards/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import VerifyPopup from 'ui/VerifyCardPopup';
import VerifyConfirmation from './VerifyConfirmation';

export interface IAction {
	action: string;
	data: ICardItem;
}

interface IPaymentItemProps {
	setAction: (data: IAction) => void;
}

const PaymentItem: FC<ICardItem & IPaymentItemProps> = ({
	bank_name,
	card_number_last4,
	card_number,
	holder_name,
	status,
	id,
	setAction,
}) => {
	const dispatch = useDispatch();
	const [edit, setEdit] = useState(false);
	const [verifyConfirmOpen, setVerifyConfirmOpen] = useState(false);
	const [verifyOpen, setVerifyOpen] = useState(false);
	const [cvc, setCvc] = useState('');
	const verifyLoading = useSelector(getVerifyLoading);

	const handleToggleEdit = () => {
		setEdit(!edit);
	};

	const handleDelete = () => {
		setAction({
			action: 'delete',
			data: {
				id,
				card_number_last4,
			},
		});
	};

	const handleEditCard = (values: typeof initialValues) => {
		dispatch(
			updateCardRequest({
				...values,
				card_number: values.card_number.replace(/ /g, ''),
				bank_name: values.bank_name || '-',
				id,
			}),
		);
		setEdit(false);
	};

	const initialValues = {
		holder_name,
		bank_name,
		card_number: card_number?.replace(/(\d{4})/g, '$1 ') || '',
	};

	const validationSchema = yup.object().shape({
		holder_name: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.holder_error')))
			.min(3, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
			.max(50, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		card_number: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.card_number_error')))
			.min(19, String(L.translate('Account.PaymentMethods.AddCard.card_number_error_2'))),
	});

	const getStatusClass = () => {
		switch (status) {
			case 'verified': {
				return 'table-status--verified';
			}
			case 'unverified': {
				return 'table-status--unverified';
			}
			case 'on_verification': {
				return 'table-status--in-process';
			}
			default: {
				return '';
			}
		}
	};

	const handleVerify = () => {
		dispatch(
			verifyCardRequest({
				apiParams: { card_id: id, cvc: +cvc },
				onSubmit: () => {
					dispatch(getCardsRequest());
					setVerifyOpen(false);
				},
			}),
		);
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);
					handleEditCard(values);
				}}
			>
				{({ values, errors, submitForm }) => (
					<Form>
						<div className="tr">
							<div className="td">
								<p className="td-hidden-name">
									{L.translate('Account.PaymentMethods.tableNames.name')}
								</p>
								{edit ? (
									<div className="p2p-edit-value">
										<div className="input-wrapper">
											<Field
												name="holder_name"
												className="input-item input-item--smaller"
												placeholder={L.translate('Account.PaymentMethods.tableNames.name')}
											/>
										</div>
									</div>
								) : (
									<p className="td-name">{holder_name}</p>
								)}
							</div>
							<div className="td">
								<p className="td-hidden-name">
									{L.translate('Account.PaymentMethods.tableNames.bank_account')}
								</p>
								{edit ? (
									<div className="p2p-edit-value">
										<div className={`input-wrapper ${errors.card_number ? 'input--error' : ''}`}>
											<Field
												name="card_number"
												className="input-item input-item--smaller"
												placeholder={L.translate('Account.PaymentMethods.tableNames.bank_account')}
												value={values.card_number
													.replace(/[^0-9]/g, '')
													.replace(/(\d{4})/g, '$1 ')
													.trim()}
												maxLength={19}
											/>
										</div>
									</div>
								) : (
									<p className="td-name">**** **** **** {card_number_last4}</p>
								)}
							</div>
							<div className="td">
								<p className="td-hidden-name">
									{L.translate('Account.PaymentMethods.tableNames.bank_name')}
								</p>
								{edit ? (
									<div className="p2p-edit-value">
										<div className="input-wrapper">
											<Field
												name="bank_name"
												className="input-item input-item--smaller"
												placeholder={L.translate('Account.PaymentMethods.tableNames.bank_name')}
											/>
										</div>
									</div>
								) : (
									<p className="td-name">{bank_name}</p>
								)}
							</div>
							<div className="td">
								<p className="td-hidden-name">Status</p>
								<div className="table-status-block">
									<p className={`table-status ${getStatusClass()}`}>
										{status === 'on_verification'
											? 'In process'
											: transformFirstLetterToCapitalize(String(status).replaceAll('-', ' '))}
									</p>
									{status === 'unverified' && (
										<button
											type="button"
											className="button button--type2 button--small-height button--width-auto"
											onClick={() => setVerifyConfirmOpen(true)}
										>
											{L.translate('Account.PaymentMethods.verify')}
										</button>
									)}
								</div>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">
									{L.translate('Account.PaymentMethods.tableNames.action')}
								</p>
								<div className="td-actions">
									{edit ? (
										<>
											<button type="button" className="td-actions-btn" onClick={submitForm}>
												<CheckIcon />
											</button>
											<button
												onClick={handleToggleEdit}
												type="button"
												className="td-actions-btn td-actions-btn--red"
											>
												<CloseIcon />
											</button>
										</>
									) : (
										<>
											{/* <button onClick={handleToggleEdit} type="button" className="td-actions-btn">
											<EditIcon />
										</button> */}
											<button onClick={handleDelete} type="button" className="td-actions-btn">
												<TrashIcon />
											</button>
										</>
									)}
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			<VerifyConfirmation
				open={verifyConfirmOpen}
				onClose={() => setVerifyConfirmOpen(false)}
				holderName={holder_name}
				last4Numbers={card_number_last4}
				onConfirm={() => {
					setVerifyConfirmOpen(false);
					setVerifyOpen(true);
					setCvc('');
				}}
			/>
			<VerifyPopup
				openModal={verifyOpen}
				closeModal={() => {
					setVerifyOpen(false);
					setCvc('');
				}}
				cvc={cvc}
				setCvc={setCvc}
				cardNumber={card_number}
				subtitle={String(L.translate('Account.PaymentMethods.confirm_verify_subtitle'))}
				onSubmit={handleVerify}
				disableBtn={verifyLoading}
			/>
		</>
	);
};

export default PaymentItem;
