import { useState, FC, ChangeEvent, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { numberValidation } from 'services/utils/numberValidation';
import {
	getMarginCrossWalletsList,
	getMarginIndexPrices,
	getMarginWallets,
} from 'redux/reducers/marginWallets/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { postMarginTransferRequest } from 'redux/reducers/marginWallets/reducer';
import SearchInput from 'ui/SearchInput';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { calculateTransform } from 'services/utils/calculateTransfer';
import { IChangeMarginTransferProps } from './types';
import CrossSpotSelect from './CrossSpotSelect';
import CrossMarginSelect from './CrossMarginSelect';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

const MarginTransferModal: FC<IChangeMarginTransferProps> = ({
	openModal,
	closeModal,
	assetCode,
}) => {
	const dispatch = useDispatch();
	const currentPair = useSelector(getCurrentPair);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const marginIndexPrice: any = useSelector(getMarginIndexPrices);
	const marginWalletsData = useSelector(getMarginWallets);
	const [code, setCode] = useState(assetCode || 'btc');
	const [direction, setDirection] = useState(true);
	const [isDisabled, setIsDisabled] = useState(false);
	const [amount, setAmount] = useState('');

	const crossWallet = useSelector(getMarginCrossWalletsList);
	const marginWallets = authIsAuthenticated ? crossWallet : null;
	const spotWallet: Array<IWalletItem> | null = useSelector(getWalletsList);
	const spot = authIsAuthenticated ? spotWallet : null;

	const handleSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
		const codeCoin = event?.currentTarget?.dataset?.code;
		if (codeCoin) {
			setCode(codeCoin);
			setAmount('');
		}
	};

	const listCoins = direction ? spot : marginWallets?.data?.cross;
	const listMarginCoins: Array<any> | null | undefined = listCoins;

	const balance = listMarginCoins?.find((el) => el.asset.code === code)
		? listMarginCoins?.find((el) => el.asset.code === code)?.balance
		: 0;

	const handleDirection = () => {
		setDirection((prevState) => !prevState);
	};

	const changeAmount = (e: ChangeEvent<HTMLInputElement>) => {
		if (numberValidation(e.target.value)) {
			setAmount(e.target.value);
			setIsDisabled(e.target.value !== '' && false);
		}
	};

	const handleMaxAmount = () => {
		if (direction) {
			setAmount(balance);
			return;
		}
		if (marginWallets)
			setAmount(String(calculateTransform(balance, marginWallets, marginIndexPrice, code)));
	};

	const handleSubmit = (): void => {
		if (+balance < +amount) {
			notificationContainer(String(L.translate('Errors.amount_bigger_than_balance')), 'error');
			return;
		}
		if (!amount) {
			notificationContainer(String(L.translate('Base.Modals.enter_amount')), 'error');
			return;
		}

		setIsDisabled(true);

		const payload = {
			type: Boolean(direction),
			closeModal,
			setIsDisabled,
			params: {
				asset_id: Number(listMarginCoins?.find((el) => el.asset.code === code).asset.id),
				amount: String(amount),
			},
		};
		dispatch(postMarginTransferRequest(payload));
		setAmount('');
	};
	useEffect(() => {
		setAmount('');
	}, [direction]);
	// useEffect(() => {
	// 	setCode(currentPair?.split('_')[0]);
	// }, [currentPair]);
	useEffect(() => {
		setCode(assetCode);
	}, [assetCode]);

	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small">
						<button className="popup-close" type="button" onClick={closeModal}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title">
								{String(L.translate('Trade.Margin.MarginPopup.Transfer.modal_title'))}
							</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="popup-text popup-text--center">
								<p>{String(L.translate('Trade.Margin.MarginPopup.Transfer.modal_subtitle'))}</p>
							</div>
						</div>
						<div className="transfer-form">
							<div className="transfer-form__select select-block">
								<p className="select-block__name">{String(L.translate('Base.Modals.from'))}</p>
								<div className="select select--regular details-list__select">
									{direction ? <CrossSpotSelect /> : <CrossMarginSelect />}
								</div>
							</div>
							<div className="transfer-form__border">
								<span className="transfer-form__line" />
								<span onClick={handleDirection} className="transfer-form__icon" />
							</div>
							<div className="transfer-form__select select-block">
								<p className="select-block__name">{String(L.translate('Base.Modals.to'))}</p>
								<div className="select select--regular details-list__select">
									{direction ? <CrossMarginSelect /> : <CrossSpotSelect />}
								</div>
							</div>
							<div className="transfer-form__border">
								<span className="transfer-form__line" />
							</div>
							<SearchInput
								listCoins={marginWallets?.data?.cross}
								onSelect={handleSelect}
								coin={listMarginCoins?.find((el) => el.asset.code === code)}
							/>
							{!direction && Number(marginWalletsData.marginCrossWallets?.data.margin_level) < 2 ? (
								<div className="info-hint">
									<span className="info-hint__icon info-hint__icon--svg">
										<svg
											className="fill"
											width="14"
											height="20"
											viewBox="0 0 14 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.82057 17.7477C3.82097 17.9934 3.89494 18.234 4.03375 18.4387L4.71347 19.4422C4.82958 19.6137 4.98707 19.7544 5.17193 19.8518C5.35679 19.9491 5.56327 20 5.77301 20H8.22739C8.43713 20 8.64361 19.9491 8.82847 19.8518C9.01332 19.7544 9.17082 19.6137 9.28693 19.4422L9.96665 18.4387C10.1054 18.234 10.1796 17.9936 10.1798 17.7477L10.1814 16.2497H3.81858L3.82057 17.7477ZM0 6.87489C0 8.60805 0.654261 10.1893 1.7325 11.3974C2.38955 12.1338 3.41727 13.672 3.80903 14.9696C3.81062 14.9798 3.81182 14.99 3.81341 15.0001H10.1866C10.1882 14.99 10.1894 14.9802 10.191 14.9696C10.5827 13.672 11.6105 12.1338 12.2675 11.3974C13.3457 10.1893 14 8.60805 14 6.87489C14 3.07067 10.8544 -0.0116852 6.97812 3.33031e-05C2.92091 0.0121424 0 3.24098 0 6.87489ZM7 3.74995C5.24563 3.74995 3.81818 5.15188 3.81818 6.87489C3.81818 7.22019 3.53341 7.49987 3.18182 7.49987C2.83023 7.49987 2.54545 7.22019 2.54545 6.87489C2.54545 4.46244 4.54364 2.49998 7 2.49998C7.35159 2.49998 7.63636 2.77966 7.63636 3.12497C7.63636 3.47027 7.35159 3.74995 7 3.74995Z"
												fill="#E84545"
											/>
										</svg>
									</span>
									<p className="info-hint__text">
										{L.translate('Trade.Margin.MarginPopup.hint_text')}
									</p>
								</div>
							) : null}
							<div className="transfer-form__select select-block transfer-form__select--box">
								<div className="transfer-form__textbox">
									<p className="select-block__name">{String(L.translate('Base.Modals.amount'))}</p>

									<p className="transfer-form__amount">
										<span className="transfer-form__count">
											{direction
												? balance
												: calculateTransform(balance, marginWallets, marginIndexPrice, code) ||
												  0}{' '}
										</span>
										<span>{code.toUpperCase()}</span>
									</p>
								</div>
								<div className="select select--regular details-list__select">
									<div className="select__current">
										<input
											type="text"
											className="coin-val select__current"
											value={amount}
											onChange={changeAmount}
											placeholder="0"
										/>
										<button type="button" onClick={handleMaxAmount} className="select-custom-max">
											{String(L.translate('Base.Modals.max_button'))}
										</button>
									</div>
								</div>
							</div>
							<button
								type="button"
								className="button button--full-width"
								onClick={handleSubmit}
								disabled={isDisabled || !Number(amount)}
							>
								{String(L.translate('Base.Modals.transfer_amount'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default MarginTransferModal;
