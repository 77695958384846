import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAppStore,
	IMobileMenu,
	TCounties,
	ColorTheme,
	ITranslateParams,
	ITranslates,
} from './types';

// ==========================================:
const initialState: IAppStore = {
	mobileMenu: false,
	lang: 'EN',
	init: false,
	countries: null,
	theme: 'dark',
	translates: null,
};

// ==========================================:
const app = createSlice({
	name: '@@app',
	initialState,
	reducers: {
		changeAppLanguage: (state, action: PayloadAction<string>) => {
			const { payload } = action;
			const appLanguageState = state;

			appLanguageState.lang = payload;
		},
		mobileMenu: (state, action: PayloadAction<IMobileMenu>) => {
			const { payload } = action;
			const mobileMenuState = state;

			mobileMenuState.mobileMenu = payload.open;
		},
		setInit: (state, action: PayloadAction<boolean>) => {
			const { payload } = action;
			const mobileMenuState = state;

			mobileMenuState.init = payload;
		},
		getCountriesRequest: (state, action: PayloadAction) => {},
		getCountriesSuccess: (state, action: PayloadAction<TCounties>) => {
			const { payload } = action;
			const countriesState = state;

			countriesState.countries = payload;
		},
		setColorTheme: (state, { payload }: PayloadAction<ColorTheme>) => {
			const themeState = state;

			themeState.theme = payload;
		},
		getTranslatesRequest: (state, action: PayloadAction<ITranslateParams>) => {},
		getTranslatesSuccess: (state, { payload }: PayloadAction<ITranslates>) => {
			const appState = state;

			appState.translates = payload;
		},
	},
});

export default app.reducer;
export const {
	mobileMenu,
	changeAppLanguage,
	setInit,
	getCountriesRequest,
	getCountriesSuccess,
	setColorTheme,
	getTranslatesRequest,
	getTranslatesSuccess,
} = app.actions;
