import { FC, ChangeEvent } from 'react';
import { cardHideNumber } from 'services/utils/cardHelper';
import visaIcon from 'assets/images/general/visa.webp';
import { TItemProps } from './type';
/// * eslint-disable */
export const CardItem: FC<TItemProps> = ({ checked, card, setChecked }) => {
	const handleClick = (e: ChangeEvent<HTMLInputElement>, item: any) => {
		const { value } = e.currentTarget;
		setChecked(value && item);
	};
	return (
		<div className="add-card-item">
			<div className="radio radio--type2 radio--m0">
				<label className="radio__label">
					<input
						type="radio"
						className="hidden"
						onChange={(e) => handleClick(e, card)}
						checked={checked?.id === card.id}
						name="card"
					/>
					<span className="radio__item" />
					<span className="radio__text-group">
						<span className="radio__text radio__text--regular">{card?.bank_name}</span>
						{card?.holder_name !== 'online' && (
							<span className="radio__text radio__text--small">
								<img className="radio__icon" src={visaIcon} alt="visa" />
								{cardHideNumber(String(card?.card_number), 12, 16)}
							</span>
						)}
					</span>
				</label>
			</div>
		</div>
	);
};
