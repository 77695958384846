import { FC, useState } from 'react';
import TransferModal from 'components/TransferModal';
import { CROSS_OPTION, ISOLATED_OPTION, SPOT_OPTION } from 'components/TransferModal/utils';
import { IMarginModalsProps } from './types';
import CrossMargin from '../../Trade/MarginModal/CrossModal';
import IsolatedModal from '../../Trade/MarginModal/IsolatedModal';

// ==========================================:
const MarginModals: FC<IMarginModalsProps> = ({
	currentTab,
	openModal,
	closeModal,
	activeType,
	currentPair,
}) => {
	const isShowTransferModal = openModal && activeType === 'transfer';
	const currentPairBaseCode = currentPair?.split('_')[0];

	return (
		<>
			{currentTab === 'cross' ? (
				<>
					<TransferModal
						isOpen={isShowTransferModal}
						onClose={closeModal}
						initFrom={CROSS_OPTION}
						initTo={SPOT_OPTION}
						initCoinCode={currentPairBaseCode}
						spotSocket
						isolatedSocket
						p2pSocket
					/>
					<CrossMargin
						openModal={activeType !== 'transfer' && openModal}
						closeModal={closeModal}
						active={activeType}
						assetCode={currentPairBaseCode}
					/>
				</>
			) : (
				<>
					<TransferModal
						isOpen={isShowTransferModal}
						onClose={closeModal}
						initFrom={ISOLATED_OPTION}
						initTo={SPOT_OPTION}
						initPairCode={currentPair}
						initCoinCode={currentPairBaseCode}
						spotSocket
						crossSocket
						p2pSocket
					/>
					<IsolatedModal
						openModal={activeType !== 'transfer' && openModal}
						closeModal={closeModal}
						assetPair={currentPair}
						active={activeType}
						assetCode={currentPairBaseCode}
					/>
				</>
			)}
		</>
	);
};

export default MarginModals;
