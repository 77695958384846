import { ChangeEvent, FC, memo, useEffect, useMemo, useState } from 'react';
import L from 'i18n-react';
import { Link, useLocation } from 'react-router-dom';
import DCDropdown from 'ui/DCDropdown';
import { IWalletItem, IWalletNetworkItem } from 'redux/reducers/wallets/types';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { useDispatch, useSelector } from 'react-redux';
import DCNDropdown from 'ui/DCNDropdown';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import {
	calculateCryptoWithdrawAmountRequest,
	checkWithdrawalAddressRequest,
	checkAddressIsInternalRequest,
} from 'redux/reducers/withdrawCrypto/reducer';
import { ICheckWithdrawalAddressRequestData } from 'redux/reducers/withdrawCrypto/types';
import {
	getCalculatekWithdrawalCryptoAmount,
	getToWithdrawalAddressIsValid,
	getCheckWithdrawalAddressLoader,
	getAddressIsInternal,
} from 'redux/reducers/withdrawCrypto/selectors';
import TextError from 'ui/Formik/TextError';
import { DebounceInput } from 'react-debounce-input';
import { numberValidation } from 'services/utils/numberValidation';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { dataLayer } from 'services/gtm';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import WitdrawCryptoConfirmPopup from './WitdrawCryptoConfirmPopup';
import NetworkFeesTooltip from '../shared/NetworkFeesTooltip';
import { IStateWithdrawLocation, ICreateWithdrawCrypto } from './types';

const CreateWithdrawCrypto: FC<ICreateWithdrawCrypto> = ({ selectWallet, setSelectWallet }) => {
	const { state } = useLocation<IStateWithdrawLocation>();

	const [walletNetworks, setWalletNetworks] = useState<Array<IWalletNetworkItem> | null>(
		state?.currentWallet?.networks || null,
	);
	const [selectWalletNetworks, setSelectWalletNetworks] = useState<IWalletNetworkItem | null>(null);
	const [walletAddressTo, setWalletAddressTo] = useState('');
	const [amount, setAmount] = useState('');

	const [visibleWithdrawalInfo, setVisibleWithdrawalInfo] = useState(false);

	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);

	const dispatch = useDispatch();

	const userData = useSelector(getUserSettingsData);
	const walletsList = useSelector(getWalletsList);
	const walletAddressToIsValid = useSelector(getToWithdrawalAddressIsValid);
	const checkWithdrawalAddressLoader = useSelector(getCheckWithdrawalAddressLoader);
	const calculateWithdrawalCryptoAmount = useSelector(getCalculatekWithdrawalCryptoAmount);
	const addressIsInternal = useSelector(getAddressIsInternal);

	const { withdraw_no_limit = 0, vip_withdraw_no_limit = 0 } = selectWalletNetworks || {};

	const noLimit = userData?.is_vip ? vip_withdraw_no_limit === 1 : withdraw_no_limit === 1;

	const fixedWithdrawFee = userData?.is_vip
		? Number(selectWalletNetworks?.vip_fixed_withdraw_fee)
		: Number(selectWalletNetworks?.fixed_withdraw_fee);

	const relatedWithdrawFee = userData?.is_vip
		? Number(selectWalletNetworks?.vip_withdraw_fee)
		: Number(selectWalletNetworks?.withdraw_fee);

	const withdrawFee = fixedWithdrawFee + (fixedWithdrawFee * relatedWithdrawFee) / 100;

	const withdrawMin = userData?.is_vip
		? Number(selectWalletNetworks?.vip_withdraw_min)
		: Number(selectWalletNetworks?.withdraw_min);

	const withdrawMax = userData?.is_vip
		? Number(selectWalletNetworks?.vip_withdraw_max)
		: Number(selectWalletNetworks?.withdraw_max);

	const cryptoWalletsList = useMemo(
		() => (walletsList?.length ? walletsList.filter((wallet) => wallet.asset.type !== 'fiat') : []),
		[walletsList],
	);

	useEffect(() => {
		dispatch(userSettingsRequest());
	}, [dispatch]);

	useEffect(() => {
		const assetCode = selectWallet?.asset?.code || state?.currentWallet.asset.code;
		if (!selectWalletNetworks?.network_id || !assetCode) return;
		const networkExists = selectWallet?.networks
			?.filter((item) => !!item.withdrawable)
			?.find((item) => item.network_id === selectWalletNetworks?.network_id);
		if (!networkExists) return;
		dispatch(
			calculateCryptoWithdrawAmountRequest({
				asset_code: assetCode,
				network: selectWalletNetworks?.network_id,
			}),
		);
	}, [
		dispatch,
		selectWallet?.asset?.code,
		selectWallet?.networks,
		selectWalletNetworks?.network_id,
		state?.currentWallet,
	]);

	useEffect(() => {
		setSelectWalletNetworks(null);
		selectWallet &&
			setWalletNetworks(selectWallet?.networks?.filter((item) => !!item.withdrawable));
		setWalletAddressTo('');
		setAmount('');
	}, [selectWallet]);

	const handleNetworkSelect = (value: IWalletNetworkItem) => {
		dataLayer.push({
			event: 'select_network',
		});
		setSelectWalletNetworks(value);
		setWalletAddressTo('');
		setAmount('');
	};

	const handleWalletAddressToChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (selectWallet?.asset?.code && value.trim() && selectWalletNetworks?.network_id) {
			const requestData: ICheckWithdrawalAddressRequestData = {
				asset_code: selectWallet?.asset?.code,
				address: value.trim(),
				network: selectWalletNetworks?.network_id,
			};

			dispatch(checkWithdrawalAddressRequest(requestData));
			dispatch(checkAddressIsInternalRequest(requestData));
		}

		setWalletAddressTo(value);
	};

	const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (numberValidation(value)) {
			setAmount(value);
		}
	};

	const handleClickWithdrawalMax = () => {
		if (
			withdrawMax &&
			calculateWithdrawalCryptoAmount &&
			calculateWithdrawalCryptoAmount.residual_amount
		) {
			setAmount(
				String(
					fixedCropNumber(Number(calculateWithdrawalCryptoAmount.residual_amount), 8) > withdrawMax
						? withdrawMax
						: fixedCropNumber(Number(calculateWithdrawalCryptoAmount.residual_amount), 8),
				),
			);
			return;
		}

		if (withdrawMax && selectWallet?.balance) {
			setAmount(
				String(
					Number(selectWallet?.balance) > Number(withdrawMax) ? withdrawMax : selectWallet?.balance,
				),
			);
		}
	};

	const getRecieveAmount = () => {
		if (addressIsInternal) {
			return amount;
		}
		return fixedCropNumber(Number(convertExponentialToDecimal(Number(amount) - withdrawFee)), 8);
	};
	const recieveAmount = getRecieveAmount();

	const isInvalidFormatAddressChecked = walletAddressTo && !walletAddressToIsValid;

	const amountNumber = amount === '.' ? 0 : Number(amount);

	const isAmountLessWithdrawMin = !noLimit && amount && withdrawMin && amountNumber < withdrawMin;

	const isAmountMoreBalance =
		amount && selectWallet?.balance && Number(amount) > Number(selectWallet?.balance);

	const isAmountMoreWithdrawMax = !noLimit && amount && withdrawMin && amountNumber > withdrawMax;

	const isMoreDailyLimitAmount =
		calculateWithdrawalCryptoAmount &&
		calculateWithdrawalCryptoAmount?.residual_amount &&
		Number(calculateWithdrawalCryptoAmount.residual_amount) < Number(amount);

	const withdrawalInfoIsVisible =
		selectWallet && selectWalletNetworks && walletAddressTo && !isInvalidFormatAddressChecked;

	const receiveAmountIsVisible =
		selectWallet &&
		selectWalletNetworks &&
		walletAddressTo &&
		!!amountNumber &&
		visibleWithdrawalInfo &&
		!isAmountLessWithdrawMin &&
		!isAmountMoreBalance &&
		!isAmountMoreWithdrawMax &&
		!isMoreDailyLimitAmount;

	const withdrawIsDisable =
		isInvalidFormatAddressChecked ||
		!selectWallet ||
		!withdrawalInfoIsVisible ||
		!recieveAmount ||
		Number(recieveAmount) <= 0;
	// isAmountLessWithdrawMin ||
	// !receiveAmountIsVisible ||
	// !!isAmountMoreBalance ||
	// !!isMoreDailyLimitAmount;

	useEffect(() => {
		if (!checkWithdrawalAddressLoader) {
			setVisibleWithdrawalInfo(!!withdrawalInfoIsVisible);
		}
	}, [withdrawalInfoIsVisible, checkWithdrawalAddressLoader]);

	return (
		<>
			{selectWallet ? (
				<div className="form-block">
					<div className="enter-value">
						<span className="block-label block-label--grey">
							{String(L.translate('WithdrawCrypto.withdraw_to'))}
						</span>

						<div className="enter-value__group">
							<span className="enter-value__label enter-value__label--padding">
								{String(L.translate('WithdrawCrypto.network'))}
							</span>
							<div className="enter-value__field">
								<DCNDropdown
									value={selectWalletNetworks}
									onChange={handleNetworkSelect}
									options={walletNetworks}
								/>
							</div>
						</div>
					</div>
				</div>
			) : null}
			{selectWalletNetworks ? (
				<div className="crypto-stats">
					<div className="crypto-stats__side">
						<span className="crypto-stats__title">
							{selectWallet?.asset?.code?.toUpperCase()}{' '}
							{String(L.translate('WithdrawCrypto.spot_balance'))}
						</span>
						<span className="crypto-stats__desc">
							{String(fixedCropNumber(Number(selectWallet?.balance), 8))}{' '}
							{selectWallet?.asset?.code?.toUpperCase()}
						</span>
					</div>
					<div className="crypto-stats__side">
						<span className="crypto-stats__title">
							{String(L.translate('WithdrawCrypto.minimum_withdrawal'))}
						</span>
						<span className="crypto-stats__desc">
							{noLimit
								? String(L.translate('WithdrawFiat.no_limit'))
								: `${convertExponentialToDecimal(withdrawMin)} ${String(
										selectWallet?.asset?.code?.toUpperCase(),
								  )}`}
						</span>
					</div>
					<div className="crypto-stats__side">
						<span className="crypto-stats__title">
							{String(L.translate('WithdrawCrypto.network_fees'))}
							<NetworkFeesTooltip />
						</span>
						<span className="crypto-stats__desc">{addressIsInternal ? '0' : fixedWithdrawFee}</span>
					</div>
				</div>
			) : null}
			{selectWalletNetworks && selectWallet ? (
				<div className="form-block">
					<div className="enter-value">
						<div className="enter-value__group">
							<span className="enter-value__label enter-value__label--padding">
								{String(L.translate('WithdrawCrypto.address'))}
							</span>
							<div
								className={`enter-value__field ${
									isInvalidFormatAddressChecked ? 'enter-value__field--error' : ''
								}`}
							>
								<DebounceInput
									debounceTimeout={200}
									type="text"
									placeholder="Enter address here"
									className={`input-item ${
										isInvalidFormatAddressChecked ? 'input-item--error' : ''
									}`}
									name="address"
									value={walletAddressTo}
									onChange={handleWalletAddressToChange}
								/>
							</div>
						</div>
						{isInvalidFormatAddressChecked ? (
							<TextError>{String(L.translate('WithdrawCrypto.invalid_format'))}</TextError>
						) : null}
					</div>
				</div>
			) : null}
			{visibleWithdrawalInfo ? (
				<div className="form-block form-block--padding">
					<div className="enter-value">
						<span className="block-label block-label--grey">
							{String(L.translate('WithdrawCrypto.withdrawal_info'))}
						</span>
						{calculateWithdrawalCryptoAmount &&
							calculateWithdrawalCryptoAmount.residual_amount &&
							calculateWithdrawalCryptoAmount.residual_amount_usd && (
								<p className="withdraw-daily-limit">{`Your daily withdrawal limit is ${String(
									fixedCropNumber(Number(calculateWithdrawalCryptoAmount.residual_amount), 8),
								)} ${String(selectWallet?.asset.code.toUpperCase())} - ${String(
									calculateWithdrawalCryptoAmount.residual_amount_usd,
								)}$`}</p>
							)}
						<div className="enter-value__group">
							<span className="enter-value__label enter-value__label--padding">
								{String(L.translate('WithdrawCrypto.amount'))}
							</span>
							<div
								className={`enter-value__field ${
									isAmountLessWithdrawMin || isAmountMoreBalance || isAmountMoreWithdrawMax
										? 'enter-value__field--error'
										: ''
								}`}
							>
								<input
									className={`input-item input-item--right-icon-and-action ${
										isAmountLessWithdrawMin || isAmountMoreBalance || isAmountMoreWithdrawMax
											? 'input-item--error'
											: ''
									}`}
									type="text"
									placeholder="Amount"
									value={amount}
									name="amount"
									onChange={handleAmountChange}
								/>
								<div className="input-icon withdraw-amount-input-icon input-icon--auto input-icon--right ">
									<span className="enter-value__coin">
										{selectWallet?.asset?.code?.toUpperCase()}
									</span>
								</div>
								<div className="input-icon withdraw-amount-input-icon--more-right input-icon--auto input-icon--more-right ">
									<button type="button" className="input__link" onClick={handleClickWithdrawalMax}>
										{String(L.translate('WithdrawCrypto.max_button'))}
									</button>
								</div>
							</div>
							{isAmountLessWithdrawMin ? (
								<TextError>{String(L.translate('WithdrawCrypto.amount_min_error'))}</TextError>
							) : null}
							{isAmountMoreBalance ? (
								<TextError>
									{String(L.translate('WithdrawCrypto.amount_more_than_balance_error'))}
								</TextError>
							) : null}
							{isAmountMoreWithdrawMax ? (
								<TextError>{String(L.translate('WithdrawCrypto.amount_max_error'))}</TextError>
							) : null}
						</div>
					</div>
				</div>
			) : null}
			{receiveAmountIsVisible ? (
				<>
					<div className="form-block">
						<div className="enter-value">
							<span className="block-label block-label--grey">
								{String(L.translate('WithdrawCrypto.receive_amount'))}
							</span>
							<div className="enter-value__group">
								<span className="enter-value__sum">
									{recieveAmount} {selectWallet?.asset?.code?.toUpperCase()}
								</span>
								<span className="enter-value__label enter-value__label--padding">
									{addressIsInternal ? 0 : withdrawFee} {selectWallet?.asset?.code?.toUpperCase()}{' '}
									{String(L.translate('WithdrawCrypto.fee_included'))}
								</span>
							</div>
						</div>
					</div>
					<div className="form-submit">
						<button
							className="button button--full-width"
							type="button"
							disabled={withdrawIsDisable}
							onClick={() => {
								dataLayer.push({
									event: 'withdraw_crypto',
								});
								setOpenModal((prevOpenModal) => !prevOpenModal);
							}}
						>
							{String(L.translate('WithdrawCrypto.withdraw_button'))}
						</button>
						<WitdrawCryptoConfirmPopup
							openModal={openModal}
							closeModal={closeModal}
							address={walletAddressTo}
							recieveAmount={recieveAmount}
							amount={Number(amount)}
							currentWallet={selectWallet}
							currentWalletNetwork={selectWalletNetworks}
						/>
					</div>
				</>
			) : null}
		</>
	);
};

export default memo(CreateWithdrawCrypto);
