/* eslint-disable react/no-danger */
import { FC } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import bankIcon from 'assets/images/general/bank.webp';
import { INextProps } from '../types';

export const MessageSuccess: FC<INextProps> = ({
	openModal,
	closeModal,
	text,
	status,
	assetId,
}) => {
	const history = useHistory();
	const walletsList = useSelector(getWalletsList);
	const walletItem = assetId && walletsList?.find((item) => item.asset.id === Number(assetId));
	const fiatCode = walletItem && walletItem.asset.code;
	const stableWalletItem =
		fiatCode && walletsList?.find((item) => item.asset.code === `${String(fiatCode)}b`);

	const handleSubmit = () => {
		if (!stableWalletItem) {
			return;
		}
		history.push({
			pathname: '/deposit',
			state: {
				currentWallet: stableWalletItem,
				paymentMethod: 'bank_transfer',
				verifyBankTransfer: true,
			},
		});
	};

	if (openModal) {
		return (
			<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
				<div className="popup-window">
					<div className="popup-window__inside">
						<div className="popup popup--smaller">
							<button className="popup-close" type="button">
								<span className="popup-close__icon icon-close" onClick={closeModal} />
							</button>
							<div className="popup-icon popup-icon--only-img popup-icon--mb">
								<img src={bankIcon} alt="bank" loading="lazy" />
							</div>
							<div className="popup-header popup-header">
								<p className="popup-header__title">
									{L.translate('WithdrawFiat.Popups.MessageSuccess.title')}
								</p>
							</div>
							<div className="popup-body popup-body--margin-none">
								<div className="popup-text popup-text--center">
									<p
										dangerouslySetInnerHTML={{
											__html: String(
												L.translate('WithdrawFiat.Popups.MessageSuccess.text', {
													currency: fiatCode?.toUpperCase(),
												}),
											),
										}}
									/>
								</div>
							</div>
							<div className="popup-submit popup-submit--sb">
								<button
									onClick={handleSubmit}
									type="button"
									className="button button button--full-width"
								>
									{L.translate('WithdrawFiat.Popups.MessageSuccess.next')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		);
	}
	return null;
};
