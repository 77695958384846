import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IPaymentsStore } from './types';

// ==========================================:
const getPaymentsState = (state: IStoreState): IPaymentsStore => state.payments;
export const getPayments = createSelector(
	[getPaymentsState],
	(payments: IPaymentsStore) => payments,
);

// ====================================================:
export const getCurrencyPaymentsList = createSelector(
	[getPayments],
	(payments: IPaymentsStore): string[] => payments.paymentsList,
);
export const getCurrencyPaymentsListLoader = createSelector(
	[getPayments],
	(payments: IPaymentsStore): boolean => payments.paymentsListLoader,
);
export const getBankAccounts = createSelector(
	[getPayments],
	(payments: IPaymentsStore) => payments.accountBank,
);

export const getBankDepositTransfer = createSelector(
	[getPayments],
	(payments: IPaymentsStore) => payments.dataBankTransferDeposit,
);

export const getBankTransfer = createSelector(
	[getPayments],
	(payments: IPaymentsStore) => payments.accountData,
);

export const getFiatLimitations = createSelector(
	[getPayments],
	(payments: IPaymentsStore) => payments.fiatLimitations,
);

export const getPayPalLoading = createSelector(
	[getPayments],
	(payments: IPaymentsStore) => payments.postPayPalLoading,
);
