import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	IConvertStore,
	IExchangeRateRequestPayload,
	IExchangeRateResponsePayload,
	ICreateExchangeRequestPayload,
	IConvertHistoryResponsePayload,
	IConvertHistoryRequestPayload,
	IConvertFeePayload,
	IConvertFeeResponse,
} from './types';

// ==========================================:
export const initialState: IConvertStore = {
	exchangeRate: null,
	exchangeRateLoader: false,
	makeExchangeLoader: false,
	convertHistory: null,
	convertHistoryLoader: false,
	convertResponseData: null,
	confirmLoader: false,
	checkCurrency: '',
	convertFee: null,
};

// ==========================================:
const convert = createSlice({
	name: '@@convert',
	initialState,
	reducers: {
		getExchangeRateRequest: (state, action: PayloadAction<IExchangeRateRequestPayload>) => {
			const exchangeRateRequestState = state;

			exchangeRateRequestState.exchangeRateLoader = true;
		},

		getExchangeRateSuccess: (state, action: PayloadAction<IExchangeRateResponsePayload>) => {
			const { payload } = action;
			const exchangeRateSuccessState = state;

			exchangeRateSuccessState.exchangeRate = payload;
			exchangeRateSuccessState.exchangeRateLoader = false;
		},

		checkCurrencySuccess: (state, action: PayloadAction<string>) => {
			const { payload } = action;
			const exchangeRateSuccessState = state;

			exchangeRateSuccessState.checkCurrency = payload;
		},

		makeExchangeRequest: (state, action: PayloadAction<ICreateExchangeRequestPayload>) => {
			const makeExchangeRequestState = state;

			makeExchangeRequestState.makeExchangeLoader = true;
		},

		makeExchangeSuccess: (state, action) => {
			const makeExchangeSuccessState = state;
			const { payload } = action;

			makeExchangeSuccessState.makeExchangeLoader = false;
			makeExchangeSuccessState.convertResponseData = payload;
		},
		getConvertHistoryRequest: (state, action: PayloadAction<IConvertHistoryRequestPayload>) => {
			const getConvertSuccessState = state;
			if (action.payload?.params.export) {
				return;
			}
			getConvertSuccessState.convertHistoryLoader = true;
		},

		getConvertHistorySuccess: (state, action: PayloadAction<IConvertHistoryResponsePayload>) => {
			const ConvertSuccessState = state;

			const { payload } = action;

			ConvertSuccessState.convertHistory = payload;
			ConvertSuccessState.convertHistoryLoader = false;
		},

		closeConvertModal: (state) => {
			const ConvertState = state;
			ConvertState.convertResponseData = null;
		},

		getConvertFeeRequest: (state, action: PayloadAction<IConvertFeePayload>) => {
			const convertRequestState = state;

			convertRequestState.confirmLoader = true;
		},
		getConvertFeeSuccess: (state, action: PayloadAction<IConvertFeeResponse>) => {
			const convertRequestState = state;

			convertRequestState.confirmLoader = false;
			convertRequestState.convertFee = action.payload;
		},

		convertInitState: () => initialState,
	},
});

export default convert.reducer;
export const {
	getExchangeRateRequest,
	getExchangeRateSuccess,
	makeExchangeRequest,
	makeExchangeSuccess,
	getConvertHistoryRequest,
	getConvertHistorySuccess,
	convertInitState,
	getConvertFeeRequest,
	getConvertFeeSuccess,
	closeConvertModal,
	checkCurrencySuccess,
} = convert.actions;
