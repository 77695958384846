import { FC, useEffect, useState, ChangeEvent, useRef } from 'react';
import L from 'i18n-react';
import { IGetAssets } from 'redux/reducers/p2p/types';
import { textSearchValid } from 'services/utils/textSearch';
import { ISearchInputProps, ICurrentSelect } from './types';
/* eslint-disable no-debugger */
const CSearchInputCoin: FC<ISearchInputProps> = (props) => {
	const { listData, onSelect, placeholder = 'select currency in list', dataItem } = props;
	const [dropdown, setDropdown] = useState(false);
	const [search, setSearch] = useState('');
	const [currentCrypto, setCurrentCrypto] = useState<ICurrentSelect | null>(null);
	const node = useRef<HTMLDivElement | null>(null);

	const changeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length < 15) {
			setSearch(e.target.value);
		}
	};
	useEffect(() => {
		const coincidences = listData?.find((item: IGetAssets) => item?.code === dataItem);
		if (!dropdown && dataItem && coincidences) {
			const nameCode: string = coincidences?.code?.toUpperCase();
			const image: string = coincidences?.img_path;
			setCurrentCrypto({ code: nameCode, img: image });
		}
	}, [dataItem, dropdown, listData]);

	const handleBlur = () => {
		// without setTimeout the dropdown will close before we select asset
		setTimeout(() => {
			setDropdown(false);
		}, 300);
	};
	const filterData = (searchCoin: string, data = []) => {
		return data?.filter((wallet: IGetAssets) =>
			textSearchValid(wallet?.code, 'crypto', searchCoin, '', ''),
		);
	};
	const handleSelectToggel = (): void => {
		setDropdown(!dropdown);
	};
	const handleChoose = (item: IGetAssets) => {
		onSelect(item);
		setDropdown(false);
	};

	const data = filterData(search, listData);

	return (
		<>
			<div
				className={`select select select--regular select--regular2 ${dropdown ? 'active' : ''}`}
				ref={node}
			>
				<div className="select__current">
					<button type="button" className="select__current" onClick={handleSelectToggel}>
						{currentCrypto ? (
							<>
								<div className="select__icon">
									<img src={currentCrypto.img} alt="" width="24" height="24" />
								</div>
								<span className="select__current-currency">{currentCrypto.code}</span>
							</>
						) : (
							<span className="select__current-currency">{placeholder}</span>
						)}
						<span className="select__arrow icon-arrow2" />
					</button>
				</div>

				{dropdown && (
					<div className="select__drop">
						<div className="select__drop-wrap">
							<div className="search search--big">
								<div className="input">
									<div className="input-wrapper">
										<input
											className="input-item input-item--small-height input-item--right-icon"
											placeholder={String(L.translate('ConvertForm.enter_token_name'))}
											value={search}
											onChange={changeSearch}
											onBlur={handleBlur}
										/>

										<button type="button" className="search-btn">
											<span className="search-btn__icon icon-search-normal" />
										</button>
									</div>
								</div>
							</div>
							{data?.length ? (
								data?.map((item: IGetAssets) => (
									<div key={item.id} className="select__drop-item">
										<button
											type="button"
											className="select__drop-link"
											onClick={() => handleChoose(item)}
										>
											<span className="select-coin coin_image">
												<img src={item?.img_path} alt="" />
											</span>
											{item?.code?.toUpperCase()}
										</button>
									</div>
								))
							) : (
								<span className="coin-val__name">{L.translate('Global.nothing')}</span>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default CSearchInputCoin;
