/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { toFixedNoZeros } from 'services/utils/numberHelpers';
import { numberExists } from 'services/utils/number';
import { IItemProps } from './types';
/* eslint-disable no-debugger */
const UserItem: FC<IItemProps> = ({ data }) => {
	const {
		amount,
		code,
		current_fiat_code,
		current_fiat_price,
		amount_max,
		amount_min,
		payment_details,
		type,
		id,
		user_id,
	} = data;
	const history = useHistory();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const userId = useSelector(getUserSettingsData)?.id;

	const getClassByType = (typeValue: string): string => {
		if (typeValue === 'buy') return 'button button--buy button--red';

		if (typeValue === 'sell') return 'button button--buy';

		return 'td-name';
	};
	const handleAction = (): void => {
		if (!authIsAuthenticated) return;
		// if (!checkTrading(user)) return;
		history.push({
			pathname: `/p2p-trading/details`,
			search: `id=${id}`,
			state: 0,
		});
	};

	return (
		<div className="tr">
			{/* {pair && (
				<div className="td">
					<p className="td-hidden-name">Pair</p>
					<span className="td-name td-name--regular td-name--fw500">
						{transformPairCode(pair?.code)}
					</span>
				</div>
			)} */}
			<div className="td">
				<p className="td-hidden-name">Coin</p>
				<span className="td-name td-name--regular td-name--fw500">{code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Price</p>
				<span className="td-name td-name--regular td-name--light">
					{numberExists(current_fiat_price) && toFixedNoZeros(current_fiat_price, 8)}{' '}
					{current_fiat_code.toUpperCase()}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Limit/Available</p>
				<div className="td-stats">
					<div className="td-stats__row">
						<span className="td-stats__title">{String(L.translate('P2P.available'))}</span>
						<span className="td-stats__desc">
							{(+amount).toFixed(8)} {code.toUpperCase()}
						</span>
					</div>
					<div className="td-stats__row">
						<span className="td-stats__title">{String(L.translate('P2P.limit'))}</span>
						<span className="td-stats__desc">
							{(+amount_min)?.toFixed(8)}-{(+amount_max)?.toFixed(8)}
						</span>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Payment</p>
				{payment_details?.map((item) => (
					<div className="payment-method payment-method--citybank">{item.payment_name_eng}</div>
				))}
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<button
					name={String(id)}
					type="button"
					className={getClassByType(type)}
					onClick={handleAction}
					disabled={userId === user_id}
				>
					{type === 'buy' ? String(L.translate('P2P.sell')) : String(L.translate('P2P.buy'))}{' '}
					{code?.toUpperCase()}
				</button>
			</div>
		</div>
	);
};

export default UserItem;
