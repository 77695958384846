/* eslint-disable react/no-array-index-key */
import L from 'i18n-react';
import { useEffect, useState, useMemo } from 'react';
import { ISingleLimits } from 'redux/reducers/ourPrices/types';
import { useSelector } from 'react-redux';
import { getFiatLimits, getLoadingLimits } from 'redux/reducers/ourPrices/selectors';
import { HIDE_VIP } from 'components/OurPrices/constants';
import TableRow from './TableRow';
import TabsButton from '../TabsButton';
import { fiatArray, fiatVipGen } from '../utils';

const LimitsDailyTable = () => {
	const loading = useSelector(getLoadingLimits);
	const data = useSelector(getFiatLimits);
	const [activeBtn, setActiveBtn] = useState('USD');
	const [activeBtnSt, setActiveBtnSt] = useState('General');

	const [limits, setLimits] = useState<Array<ISingleLimits>>([]);

	const limitList: any = useMemo(() => {
		if (!data) return [];

		return data.daily.filter((item) => {
			return item.asset_code === String(activeBtn?.toLocaleLowerCase());
		});
	}, [data, activeBtn]);

	useEffect(() => {
		if (limitList) {
			setLimits(limitList);
		}
	}, [activeBtn, limitList]);
	return (
		<>
			<div className="table-tabs">
				<div className="table-tab">
					{fiatArray.map((item) => (
						<TabsButton
							key={item.id}
							activeBtn={activeBtn}
							setActiveBtn={setActiveBtn}
							text={item.text}
						/>
					))}
				</div>
			</div>
			<div
				className={`table table--prices-type table--daily-limit-fiat ${
					HIDE_VIP ? 'table--daily-limit-fiat--hide-vip' : ''
				}`}
			>
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<p className="td-title">{L.translate('OurPrices.Columns.currency')}</p>
						</div>
						<div className="td">
							<p className="td-title">{L.translate('OurPrices.Columns.channel_name')}</p>
						</div>
						<div className={`td ${HIDE_VIP ? 'td--right' : ''}`}>
							<p className="td-title">{L.translate('OurPrices.Columns.withdrawal1')}</p>
						</div>
						{!HIDE_VIP && (
							<div className="td td--right">
								<p className="td-title">{L.translate('OurPrices.Columns.vip_withdrawal1')}</p>
							</div>
						)}
					</div>
				</div>
				<div className="table-body">
					{limits &&
						limits?.map((item, idx) => (
							<TableRow key={idx} item={item} activeBtnSt={activeBtnSt} />
						))}
				</div>
			</div>
		</>
	);
};

export default LimitsDailyTable;
