import { FC } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { IVerifyConfirmation } from './types';

const VerifyConfirmation: FC<IVerifyConfirmation> = ({
	open,
	onClose,
	holderName,
	last4Numbers,
	onConfirm,
}) => {
	return (
		<Popup open={open} onClose={onClose} lockScroll>
			<div className="popup-window">
				<button type="button" className="popup-close" onClick={onClose}>
					<span className="popup-close__icon icon-close" />
				</button>
				<div className="popup-window__inside">
					<div className="popup popup--width-560">
						<div className="popup-header">
							<p className="popup-header__title">
								{L.translate('Account.PaymentMethods.verify_confirmation')}
							</p>
						</div>
						<div className="popup-body popup-body--type2">
							<div className="withdrawal-options ">
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('Account.PaymentMethods.holder_name')}
									</span>
									<span className="withdrawal-option__desc">{holderName}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('Account.PaymentMethods.card_number')}
									</span>
									<span className="withdrawal-option__desc">**** **** **** {last4Numbers}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('Account.PaymentMethods.amount')}
									</span>
									<span className="withdrawal-option__desc">0.01 GEL</span>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--type2">
							<button
								type="button"
								className="button button--check button--size-60 button--full-width "
								onClick={onConfirm}
							>
								{L.translate('Account.PaymentMethods.verify')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default VerifyConfirmation;
