// TODO: Переводы
import { FC } from 'react';
import L from 'i18n-react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { getCountries } from 'redux/reducers/app/selectors';
import Input from 'ui/Formik/Input';
import DateOfBirthDropdown from 'ui/DateOfBirthDropdown';
import MonthOfBirthDropdown from 'ui/MonthOfBirthDropdown';
import YearOfBirthDropdown from 'ui/YearOfBirthDropdown';
import CSearchInput from 'ui/CSearchInput';
import { ICountry } from 'redux/reducers/app/types';
import { IOndatoKycStartForm, IOndatoKycStartFormValue } from './types';

// ==========================================:
const OndatoKycStartForm: FC<IOndatoKycStartForm> = ({ startOndatoKycSubmit, onConfirm }) => {
	const countries = useSelector(getCountries);
	const initialValues = {
		country_id: undefined,
	};

	const validationSchema = yup.object().shape({
		firstName: yup
			.string()
			.required(String(L.translate('KYC.required_first_name')))
			.min(2, String(L.translate('KYC.min')))
			.max(30, String(L.translate('KYC.max')))
			.matches(/^[A-Za-z]+$/, String(L.translate('KYC.matches'))),
		lastName: yup
			.string()
			.required(String(L.translate('KYC.required_last_name')))
			.min(4, String(L.translate('KYC.min_last_name')))
			.max(30, String(L.translate('KYC.max_last_name')))
			.matches(/^[A-Za-z]+$/, String(L.translate('KYC.matches_last_name'))),
		address: yup
			.string()
			.required(String(L.translate('KYC.required_address')))
			.min(3, String(L.translate('KYC.min')))
			.max(30, String(L.translate('KYC.max'))),
	});

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values: IOndatoKycStartFormValue, { resetForm, setSubmitting }) => {
				if (!values.country_id && values.country_id !== 0) {
					return;
				}
				startOndatoKycSubmit({
					country_id: values.country_id,
				});

				onConfirm?.();

				setSubmitting(false);
				resetForm();
			}}
			enableReinitialize
		>
			{({ isSubmitting, values, setFieldValue }) => (
				<Form className="transfer-form">
					<CSearchInput
						listData={countries || []}
						onSelect={(value: ICountry) => {
							setFieldValue('country_id', value.id);
						}}
						showValue={false}
						dataItem={values.country_id}
						currenClass="select-block--mb-16 select--height-60"
						classInput="select--height-60"
						placeholder={String(L.translate('KYC.select_country'))}
						valueProp="id"
						nameProp="name"
						start="0"
					/>
					<button
						disabled={isSubmitting || (!values.country_id && values.country_id !== 0)}
						aria-label="form submit"
						type="submit"
						className={`button button--full-width ${
							isSubmitting || (!values.country_id && values.country_id !== 0)
								? 'button--not-verificate'
								: ''
						} `}
					>
						{String(L.translate('KYC.start_verification_button'))}
					</button>
				</Form>
			)}
		</Formik>
	);
};

export default OndatoKycStartForm;
