/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getTransferHistoryIsLoader } from 'redux/reducers/marginWallets/selectors';
import Loader from 'ui/Loader';
import TransferTableItem from '../TransferTableItem';
import { ITransferTableProps } from './types';

// ==========================================:
const TrasnferTable: FC<ITransferTableProps> = ({ data }) => {
	const transferHistoryListLoader = useSelector(getTransferHistoryIsLoader);

	return (
		<>
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Transfer.date'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Transfer.asset'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Transfer.from_wallet'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Transfer.to_wallet'))}</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{String(L.translate('Transfer.amount'))}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{transferHistoryListLoader && (
					<div className="transaction-history-loader-wrapper">
						<Loader />
					</div>
				)}

				{data?.length && !transferHistoryListLoader
					? data?.map((item, idx) => <TransferTableItem key={idx} data={item} />)
					: null}

				{!data?.length && !transferHistoryListLoader && (
					<div className="transaction-history-loader-wrapper">
						{String(L.translate('Transfer.no_transfer_transactions'))}
					</div>
				)}
			</div>
		</>
	);
};

export default TrasnferTable;
