import { FC, useState, ChangeEvent, useEffect } from 'react';
import L from 'i18n-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getUserAdvert, getPaymentAmount } from 'redux/reducers/p2p/selectors';
import { postTradeDetailsRequest, setPaymentAmount } from 'redux/reducers/p2p/reducer';
import { IAdvrtPaymnetMethods } from 'redux/reducers/p2p/types';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { renameCoin } from 'services/utils/renameCoin';
import { numberValidation } from 'services/utils/numberValidation';
import DetailsRow from './DetailsRow';
import DetailsBlock from './DetaisBlock';
import PaymentMethodSelect from './PaymentMethodSelect';
/* eslint-disable no-debugger */
const TradeDetails: FC = () => {
	const location = useLocation();
	const history = useHistory();
	const userAdvert = useSelector(getUserAdvert);
	const amount = useSelector(getPaymentAmount);
	const dispatch = useDispatch();
	const [paymentMethod, setPaymentMethod] = useState<IAdvrtPaymnetMethods | null>();

	const { search } = location;
	const advertId = queryString.parse(search).id || null;

	const type = userAdvert && userAdvert.type === 'sell' ? 'buy' : 'sell';
	// const coin = userAdvert && userAdvert.asset && userAdvert.asset.code;
	// || (trade && trade.asset_code);

	const handleSubmit = () => {
		if (Number(amount) === 0 || amount === '') {
			notificationContainer(String(L.translate('P2P.t_0398')), 'error');
			return;
		}
		if (
			Number(amount) < Number(userAdvert?.amount_min) ||
			Number(amount) > Number(userAdvert?.amount_left)
		) {
			notificationContainer(String(L.translate('P2P.t_0399')), 'error');
			return;
		}
		paymentMethod &&
			dispatch(
				postTradeDetailsRequest({
					data: {
						advertisement_id: Number(advertId),
						amount: Number(amount),
						payment_method_id: paymentMethod?.id,
					},
					history,
					setAmount: (v: string) => dispatch(setPaymentAmount(v)),
				}),
			);
		// history.replace('/p2p-trading/pay-sell');
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (!numberValidation(e.target.value)) {
			return;
		}
		if (
			Number(userAdvert?.amount_max) > Number(userAdvert?.amount_left) &&
			Number(e.target.value) > Number(userAdvert?.amount_left)
		) {
			return;
		}
		if (
			Number(userAdvert?.amount_max) <= Number(userAdvert?.amount_left) &&
			Number(e.target.value) > Number(userAdvert?.amount_max)
		) {
			return;
		}
		if (Number(e.target.value) < 0) {
			return;
		}
		dispatch(setPaymentAmount(e.target.value));
	};

	useEffect(() => {
		if (userAdvert && userAdvert.payment_details.length > 0 && !paymentMethod) {
			setPaymentMethod(userAdvert.payment_details[0]);
		}
	}, [userAdvert, paymentMethod, setPaymentMethod]);

	return (
		<>
			{userAdvert ? (
				<>
					<div className="create-offer__left">
						<div className="payment-form">
							<div className="input input--margin-big">
								<p className="input__name">
									{String(L.translate('P2P.total_amount_you_are'))}{' '}
									{type === 'buy'
										? String(L.translate('P2P.buying'))
										: String(L.translate('P2P.selling'))}
									:
								</p>
								<div className="input-wrapper">
									<input
										className="input-item"
										// type="number"
										placeholder={String(L.translate('P2P.t_0135'))}
										onChange={handleChange}
										value={amount}
									/>
								</div>
							</div>
							<div className="details-list">
								<DetailsRow
									title={`${String(L.translate('P2P.price'))} ${userAdvert?.current_fiat_code}`}
									value={`${convertExponentialToDecimal(userAdvert.current_fiat_price)} ${
										userAdvert?.current_fiat_code
									}/${renameCoin(userAdvert.asset.code)}`}
								/>
								<DetailsRow
									title={String(L.translate('P2P.you_payment'))}
									value={
										amount &&
										(type === 'sell'
											? `${amount} ${renameCoin(userAdvert.asset.code)}`
											: `${Number(amount) * userAdvert.current_fiat_price} ${renameCoin(
													userAdvert.current_fiat_code,
											  )}`)
									}
								/>
								<DetailsRow
									title={String(L.translate('P2P.you_will_receive'))}
									value={
										amount &&
										(type === 'sell'
											? `${Number(amount) * userAdvert.current_fiat_price} ${renameCoin(
													userAdvert.current_fiat_code,
											  )}`
											: `${amount} ${renameCoin(userAdvert.asset.code)}`)
									}
								/>
							</div>
							<div className="payment-group">
								<div className="input input--margin-big">
									<p className="input__name">{L.translate('P2P.advertisers')}</p>
									<div className="input-wrapper">
										<input className="input-item" value={userAdvert.user.username} readOnly />
									</div>
								</div>
								<div className="input input--margin-big">
									<p className="input__name">{L.translate('P2P.payment_method')}</p>
									<PaymentMethodSelect
										value={paymentMethod}
										onChange={setPaymentMethod}
										options={userAdvert.payment_details}
									/>
								</div>
							</div>

							<div className="payment-form__footer">
								<button
									type="button"
									className="button button--full-width button--second-grey"
									onClick={() => history.push('/p2p-trade')}
								>
									{String(L.translate('Global.cancel'))}
								</button>
								<button
									type="button"
									className={`button button--full-width button--${
										type === 'sell' ? 'orange' : 'green'
									}`}
									onClick={handleSubmit}
									disabled={false}
								>
									{type === 'sell' ? L.translate('P2P.t_0079') : L.translate('P2P.t_0078')}
								</button>
							</div>
						</div>
					</div>
					<div className="create-offer__right">
						<div className="details-block">
							<span className="details-block__title">
								{String(L.translate('P2P.payment_details'))}
							</span>
							<div className="details-block__main">
								<DetailsBlock
									title={String(L.translate(`P2P.omg.tradeDetails.paymentType_${userAdvert.type}`))}
									value={userAdvert.user.username}
								/>
								<DetailsBlock
									title={String(L.translate('P2P.t_0093'))}
									value={`${convertExponentialToDecimal(userAdvert.current_fiat_price)} ${
										userAdvert?.in_usd
											? userAdvert?.current_fiat_code
											: renameCoin(userAdvert?.currency.code)
									}/${renameCoin(userAdvert.asset.code)}`}
								/>

								<div className="details-item">
									<span className="details-item__title">{L.translate('P2P.id_verification')}:</span>
									<span className="verified-label">{L.translate('P2P.verified')}</span>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

export default TradeDetails;
