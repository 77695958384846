import { FC } from 'react';
import L from 'i18n-react';
import OurPricesLayout from 'layouts/OurPrices';
import OurPrices from 'components/OurPrices';

// ==========================================:
const OurPricesPage: FC = () => {
	return (
		<OurPricesLayout>
			<OurPrices />
		</OurPricesLayout>
	);
};

export default OurPricesPage;
