/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IWithdrawFiatStore,
	IWithdrawPaypalPayload,
	ICalculateFiatWithdrawalPayload,
	ICalculateFiatWithdrawalResponse,
} from './types';

// ==========================================:
export const initialState: IWithdrawFiatStore = {
	withdrawalFiatLoader: true,
	residual: {
		daily: null,
		monthly: null,
	},
};

// ==========================================:
const withdrawCrypto = createSlice({
	name: '@@withdrawCrypto',
	initialState,
	reducers: {
		withdrawFiatRequest: (state, action: PayloadAction<IWithdrawPaypalPayload>) => {
			const withdrawFiatRequestState = state;
			withdrawFiatRequestState.withdrawalFiatLoader = true;
		},
		withdrawFiatSuccess: (state) => {
			const withdrawFiatSuccessState = state;
			withdrawFiatSuccessState.withdrawalFiatLoader = false;
		},
		calculateFiatWithdrawalRequest: (
			state,
			action: PayloadAction<ICalculateFiatWithdrawalPayload>,
		) => {},
		calculateFiatWithdrawalSuccess: (
			state,
			{ payload }: PayloadAction<ICalculateFiatWithdrawalResponse>,
		) => {
			const { residual_amount_daily: daily, residual_amount_monthly: monthly } = payload;
			const withdrawFiatSuccessState = state;
			withdrawFiatSuccessState.residual.daily = daily;
			withdrawFiatSuccessState.residual.monthly = monthly;
		},

		withdrawFiatInitState: () => initialState,
	},
});

export default withdrawCrypto.reducer;
export const {
	withdrawFiatRequest,
	withdrawFiatSuccess,
	calculateFiatWithdrawalRequest,
	calculateFiatWithdrawalSuccess,
	withdrawFiatInitState,
} = withdrawCrypto.actions;
