import L from 'i18n-react';
import { InfoIcon } from 'assets/svg-icon';

const TransactionFeeTooltip = () => {
	return (
		<span className="tooltip-item ">
			<span className="tooltip-item__icon">
				<InfoIcon />
			</span>
			<span className="tooltip tooltip--width-320 tooltip--bottom tooltip--center ">
				<span className="tooltip__text">
					<span>{L.translate('WithdrawStable.TransactionFeeTooltip.text')}</span>
				</span>
			</span>
		</span>
	);
};

export default TransactionFeeTooltip;
