import L from 'i18n-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { navList } from 'routes/routesList';
import FigureSvg from 'assets/images/content/hero-figure2-type2.svg';
import FigureSvgDark from 'assets/images/content/hero-figure2-type2__dark.png';
import { getColorTheme } from 'redux/reducers/app/selectors';
import Tabs from './Tabs';
import PaymentMethods from './PaymentMethods';
import Crypto from './Crypto';
import Limits from './Limits';

const OurPrices = () => {
	const theme = useSelector(getColorTheme);

	const isDark = theme === 'dark';

	return (
		<div className="content-wrapper">
			<div className="intro-section intro-section--prices">
				<div className="tutorial-figure tutorial-figure--first">
					<img src={isDark ? FigureSvgDark : FigureSvg} alt="figure" />
				</div>
				<div className="tutorial-figure tutorial-figure--second">
					<img src={isDark ? FigureSvgDark : FigureSvg} alt="figure" />
				</div>
				<div className="container">
					<div className="intro-header intro-header--tutorial">
						<h1 className="section-title section-title--big">
							{L.translate('OurPrices.title_1')} & <br />
							{L.translate('OurPrices.title_2')}
						</h1>
					</div>
				</div>
			</div>
			<div className="content-box content-box--prices">
				<div className="section section--prices">
					<div className="container">
						<h2 className="section-title section-title--medium">
							{L.translate('OurPrices.our_prices')}
						</h2>
						<Tabs />
						<Switch>
							<Route path={navList.ourPrices.path} component={PaymentMethods} exact />
							<Route path={navList.ourPricesCrypto.path} component={Crypto} exact />
							<Route path={navList.ourPricesLimits.path} component={Limits} exact />
							<Redirect to={navList.ourPricesCrypto.path} />
						</Switch>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OurPrices;
