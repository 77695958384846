import { FC } from 'react';
import L from 'i18n-react';

type TInfoProps = {
	setShowModal: (value: boolean) => void;
	classNames: string;
};

export const PaymentButtons: FC<TInfoProps> = ({ setShowModal, classNames }) => {
	return (
		<button onClick={() => setShowModal(true)} type="button" className={classNames}>
			{L.translate('Account.PaymentButtons.add_payment_method')}
		</button>
	);
};
