import { useEffect, useState, useRef, ChangeEvent } from 'react';
import L from 'i18n-react';
import { textSearchValid } from 'services/utils/textSearch';
import { ISearchInputProps } from './types';

const CSearchInput = <T extends { id: number }>(props: ISearchInputProps<T>) => {
	const {
		listData,
		onSelect,
		onBlur,
		dataItem,
		currenClass = '',
		placeholder,
		valueProp = 'code' as keyof T,
		nameProp = 'name' as keyof T,
		showValue = true,
		classInput = '',
		start = '',
		nameInput = '',
		error,
		allOptionText,
		onAllOptClick,
	} = props;
	const [dropdown, setDropdown] = useState(false);
	const [search, setSearch] = useState('');
	const inputRef = useRef<HTMLInputElement | null>(null);

	const changeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length < 15) {
			setSearch(e.target.value);
		}
	};
	useEffect(() => {
		const coincidences = listData?.find((item: T) => item?.[valueProp] === dataItem);
		if (!dropdown && dataItem && coincidences) {
			const nameCode = String(coincidences?.[valueProp])?.toUpperCase();
			const name = String(coincidences?.[nameProp]);
			setSearch(showValue ? `${nameCode} ${name}` : name);
		}
	}, [dataItem, dropdown, listData, valueProp, nameProp, showValue]);

	useEffect(() => {
		if (search && !dataItem) {
			const findItem = listData?.find((item) => String(item[nameProp]) === search);
			if (findItem && findItem.id !== dataItem) {
				onSelect(findItem);
			}
		}
	}, [search, listData, nameProp, onSelect, dataItem, inputRef]);

	const handleFocus = () => {
		setDropdown(true);
		setSearch('');
	};

	const handleBlur = () => {
		// without setTimeout the dropdown will close before we select asset
		setTimeout(() => {
			setDropdown(false);
		}, 300);
		onBlur?.();
	};
	const filterData = (searchLetter: string, data: T[] | null | undefined = []) => {
		if (showValue) {
			return data?.filter((item: T) =>
				textSearchValid(
					String(item?.[valueProp]),
					String(item?.[nameProp]),
					searchLetter,
					start,
					nameInput,
				),
			);
		}

		if (start === '0') {
			return data?.filter((item: T) =>
				searchLetter === ''
					? item
					: String(item[nameProp]).toLowerCase().startsWith(searchLetter.toLowerCase()),
			);
		}

		return data?.filter((item: T) =>
			searchLetter === ''
				? item
				: String(item[nameProp]).toLowerCase().includes(searchLetter.toLowerCase()),
		);
	};

	const data = filterData(search, listData);

	return (
		<>
			<div
				className={`select select--regular ${error ? 'select--error' : ''} ${String(currenClass)} ${
					dropdown ? 'active' : ''
				}`}
			>
				<div className={`select__current ${String(classInput)}`}>
					<input
						ref={inputRef}
						className="coin-val"
						placeholder={placeholder || String(L.translate('P2P.select_asset'))}
						value={search}
						onChange={changeSearch}
						onFocus={handleFocus}
						onBlur={handleBlur}
					/>
					<span className="select__arrow icon-arrow2" />
				</div>

				{dropdown && (
					<div className="select__drop">
						<div className="select__drop-scroll">
							<div className="select__drop-item">
								{allOptionText && (
									<button type="button" className="select__drop-link" onClick={onAllOptClick}>
										{showValue && 'dfsfsdfsdfsdf'}
										<span className="select__drop-text">{allOptionText}</span>
									</button>
								)}
							</div>
							{data?.length ? (
								data?.map((item: T) => (
									<div key={item.id} className="select__drop-item">
										<button
											type="button"
											className="select__drop-link"
											// data-code={item.code}
											// data-name={item.name}
											onClick={() => onSelect(item)}
										>
											{showValue && String(item?.[valueProp])?.toUpperCase()}
											<span className="select__drop-text">{item?.[nameProp]}</span>
										</button>
									</div>
								))
							) : (
								<span className="coin-val__name">{L.translate('Global.nothing')}</span>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default CSearchInput;
