import { FC, useState, useRef, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import {
	getTradeStatus,
	getTradePaySell,
	getChatListAdmin,
	getChatListUser,
} from 'redux/reducers/p2p/selectors';
import {
	postChatMessageSendRequest,
	postChatMessageAdminSendRequest,
} from 'redux/reducers/p2p/reducer';

import { ACCEPT_TYPE_FILES, MAX_FILE_SIZE } from 'services/constants/chat';
import { toBase64 } from 'services/utils/toBase64';
import { ITradeChatCommon } from 'redux/reducers/p2p/types';
import {
	getTimeParserDateFromTimestamp,
	getTimeParserTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import PhotosBox from '../SectionTrade/EvidancePhotos/PhotosBox';
import { IChatData } from './type';
/* eslint-disable no-debugger */

const Chat: FC<IChatData> = ({ type, id }) => {
	const dispatch = useDispatch();
	// debugger;

	const [message, setMessage] = useState('');
	const [files, setFiles] = useState<any>([]);
	const wrapperRef = useRef<null | any>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const user = useSelector(getUserSettingsData);
	const userID = user?.id;
	const listMessages = useSelector(type === 'admin' ? getChatListAdmin : getChatListUser);
	const trade = useSelector(getTradePaySell);
	const tradeStatus = useSelector(getTradeStatus);

	const userId = user && user.id;
	const tradId = trade && trade.trader && trade.trader.id;
	const userTrader = tradId === userId ? 'trader' : 'not_trader';
	const userName =
		userTrader === 'trader' ? trade?.advertisement?.user?.username : trade?.trader?.username;

	const loaded = useRef<boolean>(false);

	useEffect(() => {
		if (!loaded.current && Number(listMessages?.length) > 0) {
			loaded.current = true;
			// eslint-disable-next-line no-unused-expressions
			wrapperRef?.current?.scroll({
				top: wrapperRef.current.scrollHeight,
				left: 0,
			});
		}
	}, [loaded, listMessages]);

	const scrollToBottom = () => {
		const { current } = wrapperRef;
		if (current.scrollHeight - current.scrollTop < 1050 && Number(listMessages?.length) > 0) {
			// eslint-disable-next-line no-unused-expressions

			wrapperRef?.current?.scroll({
				top: wrapperRef.current.scrollHeight,
				left: 0,
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {
		if (listMessages) {
			scrollToBottom();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listMessages]);

	const handleSend = () => {
		const send = type === 'admin' ? postChatMessageAdminSendRequest : postChatMessageSendRequest;
		if (message.trim() !== '' || files.length > 0) {
			if (files.length > 0 && message.trim() !== '') {
				dispatch(send({ trade_id: String(id), message, files }));
			}
			if (files.length === 0 && message.trim() !== '') {
				dispatch(send({ trade_id: String(id), message }));
			}
			if (files.length > 0 && message.trim() === '') {
				dispatch(send({ trade_id: String(id), files }));
			}
			setMessage('');
			setFiles([]);
			if (fileInputRef.current) {
				fileInputRef.current.value = '';
			}
		}
	};

	const handleFile = async (event: ChangeEvent<HTMLInputElement>) => {
		const filesList: FileList | null = event?.currentTarget?.files;
		if (filesList) {
			const file = filesList[0];
			if (ACCEPT_TYPE_FILES.every((fileType) => file.type !== fileType)) {
				return;
			}
			if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
				return;
			}
			const base64 = await toBase64(file);
			setFiles((prev: any) => [...prev, { file: base64 }]);
		}
	};

	const deleteFile = (fileId: any) => {
		setFiles(() => {
			const newFiles = files.filter((_: any, index: any) => index !== fileId);
			if (newFiles?.length === 0 && fileInputRef.current) {
				fileInputRef.current.value = '';
			}
			return newFiles;
		});
	};

	return (
		<div className={`chat ${tradeStatus === 'under_dispute' ? '' : 'chat--nom'}`}>
			<div className="chat__header">
				<div className="chat-user">
					<span className="chat-user__name">
						{type === 'user' ? userName : String(L.translate('Chat.admin'))}
					</span>
				</div>
				<div
					className={`chat-status ${
						trade?.advertisement?.user?.is_active || type !== 'user' ? '' : 'active'
					}`}
				>
					<span className="chat-status__text">
						{trade?.advertisement?.user?.is_active || type !== 'user'
							? String(L.translate('Chat.online'))
							: String(L.translate('Chat.offline'))}
					</span>
				</div>
			</div>

			<div className="chat__messages" ref={wrapperRef}>
				{listMessages?.map((item: ITradeChatCommon) => (
					<div
						className={`chat-message ${
							userID === item?.from_id && item?.from_username !== 'admin'
								? 'chat-message--reply'
								: ''
						}`}
						key={item.trade_id + String(item.created_at)}
					>
						<div className="chat-message__block">
							<span className="chat-message__text">{item.text}</span>
						</div>
						{!!item?.files && (
							<div style={{ display: 'flex', paddingTop: '5px' }}>
								{item?.files.map((photo: any, index) => {
									return (
										<div key={photo?.id || index}>
											<div
												style={{
													width: '80px',
													height: '75px',
													padding: '3px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													position: 'relative',
													flexWrap: 'wrap',
												}}
											>
												<embed
													src={photo?.url || photo?.file}
													// frameBorder="0"
													// scrolling="no"
													height="100%"
													width="100%"
												/>
											</div>
											<a href={photo?.url || photo?.file} target="_blank" rel="noreferrer">
												<span>{String(L.translate('P2P.open'))}</span>
											</a>
										</div>
									);
								})}
							</div>
						)}
						<span className="chat-message__date">
							{' '}
							{getTimeParserDateFromTimestamp(item.created_at)}{' '}
							{getTimeParserTimeFromTimestamp(item.created_at)} UTC
						</span>
					</div>
				))}
			</div>
			<div className="chat__footer">
				<div className="input input--chat-message">
					<div className="input-wrapper">
						<textarea
							className="input-item input-item--left-icon input-item--right-icon"
							placeholder={String(L.translate('Chat.placeholder'))}
							value={message}
							onChange={(e) => {
								const lastChar = e.target.value[0];
								if (lastChar === '\n') {
									return;
								}
								if (e.target.value.length < 255) {
									setMessage(e.target.value);
								}
							}}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									if (e.ctrlKey) {
										setMessage((prev) => `${prev}\n`);
										return;
									}
									handleSend();
								}
							}}
						/>
						<label className="input-icon input-icon--left input-icon--inputfile">
							<input
								type="file"
								onChange={handleFile}
								accept={String(ACCEPT_TYPE_FILES)}
								className="hidden"
								ref={fileInputRef}
							/>
							<span className="icon-inputfile" />
						</label>

						<button
							type="button"
							onClick={handleSend}
							className="input-icon input-icon--right input-icon--send"
						>
							<span className="icon-send2" />
						</button>
					</div>
					<PhotosBox photos={files} deleteFile={deleteFile} />
				</div>
			</div>
		</div>
	);
};

export default Chat;
