/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useMemo, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { getAssetPairsFeesAndLimitsList } from 'redux/reducers/assetPairs/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { calculateMarketOrderRequest, createOrderRequest } from 'redux/reducers/orders/reducer';
import { getMarginIndexPrices } from 'redux/reducers/marginWallets/selectors';
import {
	getCalculateMarketOrder,
	getCalculateMarketOrderBuy,
	getCalculateMarketOrderSell,
	getCalculateMarketLoader,
} from 'redux/reducers/orders/selectors';
import debounce from 'lodash.debounce';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { numberValidation, numberValidationNext } from 'services/utils/numberValidation';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getSpotOrderBook } from 'redux/reducers/spotTrade/selectors';
import TextError from 'ui/Formik/TextError';
import LoginOrRegister from 'ui/LoginOrRegister';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import PercentRadioButtons from '../PercentRadioButtons';
import { IMarketOrderProps } from './types';

const MarketOrder: FC<IMarketOrderProps> = ({
	mode,
	assetToTradeCode,
	assetBalanceCode,
	balance,
	marketType,
}) => {
	const orderBookList = useSelector(getSpotOrderBook);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const currentPair = useSelector(getCurrentPair);
	const userData = useSelector(getUserSettingsData);
	const userIsVip = !!userData?.is_vip;
	const calculateMarketOrderData = useSelector(getCalculateMarketOrder);
	const calculateMarketOrderBuyData = useSelector(getCalculateMarketOrderBuy);
	const calculateMarketOrderSellData = useSelector(getCalculateMarketOrderSell);
	const assetPairsFeesAndLimit = useSelector(getAssetPairsFeesAndLimitsList);
	const marginIndexPrice = useSelector(getMarginIndexPrices);
	const calculateLoader = useSelector(getCalculateMarketLoader);

	// sconst assetPairsList = useSelector(getAssetPairsList);

	const dispatch = useDispatch();
	const initState = {
		pair_code: currentPair,
		quantity: '',
		type: `market_${mode.toLowerCase()}`,
		total: '',
		loader: calculateLoader,
	};

	const [marketOrderState, setMarketOrderState] = useState({ ...initState });
	const [pairPrice, setPairPrice] = useState<number>(
		marginIndexPrice
			? Number(
					Object.entries(marginIndexPrice)?.filter(
						(pair) => pair[0] === currentPair.toLocaleUpperCase(),
					)[0][1],
			  )
			: 43186.931928,
	);

	const currentAssetPairLimits = useMemo(() => {
		return assetPairsFeesAndLimit?.length
			? assetPairsFeesAndLimit.find((pair) => pair.code === currentPair)
			: null;
	}, [assetPairsFeesAndLimit, currentPair]);

	const currentAssetPairLimitsAmountMin = userIsVip
		? currentAssetPairLimits?.vip_amount_min || 0
		: currentAssetPairLimits?.amount_min || 0;
	const currentAssetPairLimitsAmountMax = userIsVip
		? currentAssetPairLimits?.vip_amount_max || 0
		: currentAssetPairLimits?.amount_max || 0;

	useEffect(() => {
		if (numberValidationNext(marketOrderState.quantity)) {
			setMarketOrderState({ ...marketOrderState, total: '', loader: calculateLoader });
			return;
		}
		if (marketOrderState.type === calculateMarketOrderData?.type) {
			setMarketOrderState({
				...marketOrderState,
				total: calculateMarketOrderData?.total,
				loader: calculateLoader,
			});
		}
	}, [calculateMarketOrderData, marketOrderState.quantity]);

	// const isMaxAmountBuyError =
	// 	authIsAuthenticated &&
	// 	Number(marketOrderState.quantity) &&
	// 	calculateMarketOrderData?.total &&
	// 	Number(calculateMarketOrderData.type === 'market_buy' ? calculateMarketOrderData?.total : 0) >
	// 		currentAssetPairLimitsAmountMax;

	const isMaxAmountBuyError =
		authIsAuthenticated &&
		Number(marketOrderState.quantity) &&
		currentAssetPairLimitsAmountMax * pairPrice < Number(marketOrderState.quantity) &&
		marketOrderState.type === 'market_buy';

	const calcMarketOrderTotal =
		mode?.toLowerCase() === 'sell'
			? calculateMarketOrderSellData?.total
			: calculateMarketOrderBuyData?.total;

	const isMinAmountBuyError =
		authIsAuthenticated &&
		marketOrderState.quantity &&
		Number(marketOrderState.quantity) &&
		calcMarketOrderTotal &&
		Number(Number(calcMarketOrderTotal).toFixed(10)) <
			Number(Number(currentAssetPairLimitsAmountMin).toFixed(10));

	const isMaxAmountSellError =
		authIsAuthenticated &&
		Number(marketOrderState.quantity) &&
		Number(marketOrderState.quantity) > currentAssetPairLimitsAmountMax;

	const isMinAmountSellError =
		authIsAuthenticated &&
		Number(marketOrderState.quantity) &&
		Number(marketOrderState.quantity) < currentAssetPairLimitsAmountMin;

	const isMaxAmountBuyBalanceError =
		authIsAuthenticated &&
		Number(marketOrderState.quantity) &&
		// Number(balance) &&
		Number(marketOrderState.quantity) > balance;

	const isMaxAmountSellBalanceError =
		authIsAuthenticated &&
		Number(marketOrderState.quantity) &&
		String(balance) &&
		Number(marketOrderState.quantity) > Number(balance);

	const isDisabled =
		!Number(marketOrderState.quantity) ||
		(mode === 'Buy'
			? isMaxAmountBuyBalanceError ||
			  isMinAmountBuyError ||
			  isMaxAmountBuyError ||
			  !orderBookList?.ask_amount
			: isMaxAmountSellBalanceError ||
			  isMinAmountSellError ||
			  isMaxAmountSellError ||
			  !orderBookList?.bid_amount);

	useEffect(() => {
		setMarketOrderState({
			...initState,
			pair_code: currentPair,
		});
	}, [currentPair]);
	useEffect(() => {
		if (marginIndexPrice) {
			const currentPairPrice = Object.entries(marginIndexPrice)?.filter(
				(pair) => pair[0] === currentPair.toLocaleUpperCase(),
			);
			setPairPrice(() => Number(currentPairPrice[0][1]));
		}
	}, [marginIndexPrice]);

	const calcMarketDebounce = useMemo(
		() =>
			debounce((payload: any) => {
				dispatch(calculateMarketOrderRequest(payload));
			}, 200),
		[],
	);

	useEffect(() => {
		const { quantity } = marketOrderState;

		if (
			authIsAuthenticated &&
			currentPair &&
			Number(quantity) &&
			mode &&
			!isMaxAmountBuyBalanceError &&
			!isMaxAmountSellBalanceError
		) {
			const caclulateRequestData = {
				pair_code: initState.pair_code,
				quantity,
				type: `market_${mode.toLowerCase()}`,
				market_type: marketType,
			};

			calcMarketDebounce(caclulateRequestData);
			setMarketOrderState({
				...marketOrderState,
				loader: true,
			});
		}
	}, [currentPair, marketOrderState.quantity, mode]);

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;

		if (numberValidation(value)) {
			setMarketOrderState({
				...marketOrderState,
				[name]: value,
			});
		}
	};

	const handleTradeClick = () => {
		if (userData?.status.name === 'unverified') {
			notificationContainer(String(L.translate('Trade.need_verify')), 'info');
			return;
		}
		if (marketType === 'cross' || marketType === 'isolated') {
			if (mode === 'Buy') {
				if (calculateMarketOrderData?.total) {
					const createOrderData = {
						...marketOrderState,
						quantity: calculateMarketOrderData?.total,
						[`${marketType === 'cross' ? 'is_margin' : 'is_isolated'}`]: true,
					};
					dispatch(createOrderRequest(createOrderData));
				}
			} else {
				const { quantity } = marketOrderState;

				if (quantity) {
					const createOrderData = {
						...marketOrderState,
						quantity,
						[`${marketType === 'cross' ? 'is_margin' : 'is_isolated'}`]: true,
					};

					dispatch(createOrderRequest(createOrderData));
				}
			}
			setMarketOrderState({
				...initState,
			});
			return;
		}
		if (mode === 'Buy') {
			if (calculateMarketOrderData?.total) {
				const createOrderData = {
					...marketOrderState,
					quantity: calculateMarketOrderData?.total,
				};

				dispatch(createOrderRequest(createOrderData));
			}
		} else {
			const { quantity } = marketOrderState;

			if (quantity) {
				const createOrderData = {
					...marketOrderState,
					quantity,
				};

				dispatch(createOrderRequest(createOrderData));
			}
		}
		setMarketOrderState({
			...initState,
		});
	};

	const countOrder = (value: number) => {
		setMarketOrderState({
			...marketOrderState,
			quantity: String(fixedCropNumber(Number(value), 8)),
		});
	};
	const percentButtonCountValue = (percentValue: number): number => {
		if (!balance || !Number(percentValue)) {
			return 0;
		}

		return balance * percentValue;
	};
	const getFieldClass = (fieldName: string): string => {
		switch (fieldName) {
			case 'amount':
				if (mode === 'Buy') {
					return isMaxAmountBuyError || isMinAmountBuyError || isMaxAmountBuyBalanceError
						? 'trade-form__input input-form__item--error'
						: 'trade-form__input';
				}
				return isMaxAmountSellError || isMinAmountSellError || isMaxAmountSellBalanceError
					? 'trade-form__input input-form__item--error'
					: 'trade-form__input';

			default:
				return 'trade-form__input';
		}
	};

	// const bitCoinPrice = 43186.931928;
	// const bitCoinPrice = assetPairsList?.find((item: any) => item.code === initState.pair_code)?.last_price || 0;

	const errorLimitMax = (valueCoin: string, amountLimit: number) => {
		// if (
		// 	Number(marketOrderState.quantity) > Number(currentAssetPairLimitsAmountMax) * bitCoinPrice &&
		// 	mode === 'Buy'
		// ) {
		// 	return (
		// 		<TextError>
		// 			{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.max_amount_error_text')}{' '}
		// 			{amountLimit * bitCoinPrice} {valueCoin}
		// 		</TextError>
		// 	);
		// }

		if (
			currentAssetPairLimitsAmountMax * pairPrice < Number(marketOrderState.quantity) &&
			marketOrderState.type === 'market_buy'
		) {
			return (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.max_amount_error_text')}{' '}
					{convertExponentialToDecimal(amountLimit * pairPrice)} {valueCoin}
				</TextError>
			);
		}
		return null;
	};
	const errorLimitMin = (valueCoin: string, amountLimit: number) => {
		if (
			currentAssetPairLimitsAmountMin * pairPrice > Number(marketOrderState.quantity) &&
			marketOrderState.type === 'market_buy'
		) {
			return (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.min_amount_error_text')}{' '}
					{convertExponentialToDecimal(amountLimit * pairPrice)} {valueCoin}
				</TextError>
			);
		}
		return null;
	};
	return (
		<>
			<div className="trade-form__item">
				<div className="input">
					<div className="input-wrapper">
						<label className={getFieldClass('amount')}>
							<p className="input__name">
								{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.amount_field_name')}
							</p>
							<input
								type="text"
								placeholder="0.00"
								className="input-item input-item--transparent input-item--right"
								name="quantity"
								value={marketOrderState.quantity}
								onChange={handleChangeInput}
								autoComplete="off"
							/>
							<p className="input__name">
								{mode === 'Buy' ? assetBalanceCode?.toUpperCase() : assetToTradeCode?.toUpperCase()}
							</p>
						</label>
					</div>
				</div>
			</div>
			<div className="trade-form__item">
				<div className="input input-order-item">
					<div className="input-wrapper">
						<label className={getFieldClass('total')}>
							<p className="input__name">
								{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.total_field_name')}
							</p>
							<input
								className="input-item input-item--transparent input-item--right"
								type="text"
								placeholder="0.00"
								autoComplete="off"
								name="total"
								readOnly
								value={
									marketOrderState.loader
										? String(L.translate('Trade.Spot.calculating'))
										: marketOrderState?.total
								}
							/>
							<p className="input__name">
								{mode === 'Buy' ? assetToTradeCode?.toUpperCase() : assetBalanceCode?.toUpperCase()}
							</p>
						</label>
					</div>
				</div>
			</div>
			<PercentRadioButtons
				countOrder={countOrder}
				percentButtonCountValue={percentButtonCountValue}
				mode={mode}
				amount={marketOrderState.quantity}
				precents={[0.25, 0.5, 0.75, 1]}
			/>
			{mode === 'Buy' && isMinAmountBuyError
				? errorLimitMin(
						mode === 'Buy' ? assetBalanceCode?.toUpperCase() : assetToTradeCode?.toUpperCase(),
						currentAssetPairLimitsAmountMin,
				  )
				: null}
			{mode === 'Buy'
				? errorLimitMax(
						mode === 'Buy' ? assetBalanceCode?.toUpperCase() : assetToTradeCode?.toUpperCase(),
						currentAssetPairLimitsAmountMax,
				  )
				: null}
			{mode === 'Buy' && isMaxAmountBuyBalanceError ? (
				<>
					<TextError>{String(L.translate('Trade.Spot.amount_less_than_balance'))}</TextError>
				</>
			) : null}
			{mode === 'Buy' && !orderBookList?.ask.length ? (
				<>
					<TextError>{String(L.translate('Trade.Spot.empty_orderbook'))}</TextError>
				</>
			) : null}
			{mode === 'Sell' && !orderBookList?.bid.length ? (
				<>
					<TextError>{String(L.translate('Trade.Spot.empty_orderbook'))}</TextError>
				</>
			) : null}
			{mode === 'Sell' && isMaxAmountSellError ? (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.max_amount_error_text')}{' '}
					{currentAssetPairLimitsAmountMax} {assetToTradeCode?.toUpperCase()}
				</TextError>
			) : null}
			{mode === 'Sell' && isMinAmountSellError ? (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.min_amount_error_text')}{' '}
					{convertExponentialToDecimal(currentAssetPairLimitsAmountMin)}{' '}
					{assetToTradeCode?.toUpperCase()}
				</TextError>
			) : null}
			{mode === 'Sell' && isMaxAmountSellBalanceError ? (
				<TextError>{String(L.translate('Trade.Spot.amount_less_than_balance'))}</TextError>
			) : null}
			{authIsAuthenticated ? (
				<button
					className={`button button--normal-height button--full-width ${
						mode === 'Buy' ? 'button--green' : 'button--red'
					} trade-form__btn`}
					type="button"
					onClick={handleTradeClick}
					disabled={!!isDisabled}
				>
					{mode === 'Buy'
						? L.translate('Trade.Spot.SpotTradeBox.OrderButtons.buy_button')
						: L.translate('Trade.Spot.SpotTradeBox.OrderButtons.sell_button')}{' '}
					{assetToTradeCode?.toUpperCase()}
				</button>
			) : (
				<div className="create-order-btn-login-register-wrapper">
					<LoginOrRegister />
				</div>
			)}
		</>
	);
};

export default MarketOrder;
