import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import NotificationBell from 'components/NotificationMessage/NotificationBell';
import L from 'i18n-react';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import HeaderDropdown from 'ui/HeaderDropdown';
import { IHeaderDropdownList } from 'layouts-elements/Header/types';

// ==========================================:

interface IAuthHeader {
	walletList: IHeaderDropdownList[];
	ordersList: IHeaderDropdownList[];
	accountList: IHeaderDropdownList[];
}

const AuthHeader: FC<IAuthHeader> = ({ walletList, ordersList, accountList }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const userData = useSelector(getUserSettingsData);

	const isUnverified =
		userData?.status?.name === 'unverified' || userData?.status?.name === 'rejected';

	const handleSelect = (value: IHeaderDropdownList): void => {
		if (value.link === '/logout') {
			dispatch(logoutRequest({ history }));
		}

		value.link && history.push(value.link);
	};

	return (
		<>
			{' '}
			<NotificationBell />
			<HeaderDropdown
				currentClass="header__select"
				placeholder={`${String(L.translate('Menu.Wallet.wallet'))}`}
				placeholderIcon="icon-wallet"
				onChange={handleSelect}
				options={walletList}
			/>
			<HeaderDropdown
				currentClass="header__select"
				placeholder={`${String(L.translate('Menu.Orders.orders'))}`}
				placeholderIcon="icon-document"
				onChange={handleSelect}
				options={ordersList}
			/>
			<HeaderDropdown
				currentClass="header__select"
				placeholder={`${String(L.translate('Menu.Account.account'))}`}
				placeholderIcon="icon-profile-circle"
				onChange={handleSelect}
				options={accountList}
				selectBtnClass={isUnverified ? 'select__current--unverified' : ''}
			/>
		</>
	);
};

export default AuthHeader;
