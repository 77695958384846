import { FC } from 'react';
import L from 'i18n-react';
import { Success as Check } from 'assets/svg-icon';
import { Link } from 'react-router-dom';

export const WithdrawalSuccess: FC = () => {
	return (
		<div className="content-inner content-inner--padding">
			<div className="content-block content-block--flex content-block--padding-none content-block--border content-block--full-heigh">
				<div className="create-offer create-offer--operation-status">
					<div className="leave-review">
						<div className="leave-review__icon">
							<Check />
						</div>
						<h4 className="leave-review__title">
							{L.translate('WithdrawFiat.Popups.WithdrawalSuccess.title')}
						</h4>
						<div className="leave-review__text leave-review__text--grey leave-review__text--fw-500">
							<p>{L.translate('WithdrawFiat.Popups.WithdrawalSuccess.description')}</p>
						</div>
						<div className="form-footer">
							<Link to="/fiat-and-spot" className="button button--big button--second-grey">
								{L.translate('WithdrawFiat.Popups.WithdrawalSuccess.view_wallet')}
							</Link>
							<Link to="transaction-history" className="button button--big ">
								{L.translate('WithdrawFiat.Popups.WithdrawalSuccess.view_history')}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
