/* eslint-disable react/no-unescaped-entities */
import L from 'i18n-react';
import { InfoIcon } from 'assets/svg-icon';

const BankTransferTooltip = () => {
	return (
		<span className="tooltip-item ">
			<span className="tooltip-item__icon">
				<InfoIcon />
			</span>
			<span className="tooltip tooltip--width-320 tooltip--bottom tooltip--center ">
				<span className="tooltip__text">
					<span className="tooltip__text-title">
						{L.translate('OurPrices.BankTransferTooltip.title')}
					</span>
					<span>{L.translate('OurPrices.BankTransferTooltip.text')}</span>
				</span>
			</span>
		</span>
	);
};

export default BankTransferTooltip;
