/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { useLocation } from 'react-router-dom';
import {
	getAssetPairsFeesAndLimitsIsLoad,
	getAssetPairsFeesAndLimitsList,
	getAssetPairsIsLoad,
	getAssetPairsList,
} from 'redux/reducers/assetPairs/selectors';
import {
	closeConvertModal,
	getExchangeRateRequest,
	makeExchangeRequest,
} from 'redux/reducers/convert/reducer';
import {
	getConvertResponseData,
	getExchangeRate,
	getExchangeRateIsLoad,
	checkCurrency,
} from 'redux/reducers/convert/selectors';
import { getWalletsIsLoad, getWalletsList } from 'redux/reducers/wallets/selectors';
import { IWalletItem } from 'redux/reducers/wallets/types';
import TextError from 'ui/Formik/TextError';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { numberValidation } from 'services/utils/numberValidation';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';

import ConvertDropdown from 'ui/ConvertDropdown';
import ConvertAssetDropdown from 'ui/ConvertDropdownAsset';
import ConvertPercentButtons from 'ui/ConvertPercentButtons';
import LoginOrRegister from 'ui/LoginOrRegister';
import { convertWalletsFiltered } from 'services/utils/convertHelpers';
import Loader from 'ui/Loader';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { IAssetsItem } from 'redux/reducers/assets/types';
import { dataLayer } from 'services/gtm';
import { notificationContainer } from 'services/utils/notificationContainer';
import { trancDecimal } from 'services/utils/number';
import ConvertConfirmPopup from './ConvertConfirmPopup';
import ConfirmPopup from './ConfirmPopup';

let searchParams = new URLSearchParams();

const ConvertForm: FC = () => {
	const location = useLocation();
	searchParams = new URLSearchParams(location.search);
	const amountFromSearch = searchParams.get('amountFrom') || null;
	const amountToSearch = searchParams.get('amountTo') || null;
	const fromSearch = searchParams.get('from');
	const toSearch = searchParams.get('to');
	const initWalletsList = useSelector(getWalletsList);
	const asssetPairs = useSelector(getAssetPairsList);
	const exchangeRate = useSelector(getExchangeRate);
	const initAssetsList = useSelector(getAssetsList);
	const assetPairsFeesAndLimit = useSelector(getAssetPairsFeesAndLimitsList);
	const isAuth = useSelector(getAuthIsAuthenticated);

	const walletsList = useMemo(
		() => initWalletsList?.filter((item) => item.asset.type !== 'fiat') || [],
		[initWalletsList],
	);

	const assetsList = useMemo(
		() => initAssetsList?.filter((item) => item.type !== 'fiat') || [],
		[initAssetsList],
	);

	const walletsListIsLoad = useSelector(getWalletsIsLoad);
	const asssetPairsIsLoad = useSelector(getAssetPairsIsLoad);
	const exchangeRateIsLoad = useSelector(getExchangeRateIsLoad);
	const assetPairsFeesAndLimitIsLoad = useSelector(getAssetPairsFeesAndLimitsIsLoad);
	const responseData = useSelector(getConvertResponseData);
	const userData = useSelector(getUserSettingsData);
	const userIsVip = !!userData?.is_vip;
	const checkCurrencyMessage = useSelector(checkCurrency);

	const dispatch = useDispatch();

	const [selectFromWallet, setSelectFromWallet] = useState<IWalletItem | null>(null);
	const [selectToWallet, setSelectToWallet] = useState<IWalletItem | null>(null);
	const [selectFromAsset, setSelectFromAsset] = useState<IAssetsItem | null>(null);
	const [selectToAsset, setSelectToAsset] = useState<IAssetsItem | null>(null);
	const [isReverse, setIsReverse] = useState(false);
	const [timeLimit, setTimeLimit] = useState(0);
	const [from, setFrom] = useState('');
	const [to, setTo] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);
	const [procentBut, setProcent] = useState(false);

	const selectFromCode = isAuth ? selectFromWallet?.asset?.code : selectFromAsset?.code;
	const selectToCode = isAuth ? selectToWallet?.asset?.code : selectToAsset?.code;
	const selectFromAssetId = isAuth ? selectFromWallet?.asset?.id : selectFromAsset?.id;
	const selectToAssetId = isAuth ? selectToWallet?.asset?.id : selectToAsset?.id;

	const autoReloadExchangeRate = (fromAssetId: number, toAssetId: number) => {
		const exchangeRateRequestData = {
			from_asset_id: fromAssetId,
			to_asset_id: toAssetId,
		};
		dispatch(getExchangeRateRequest(exchangeRateRequestData));
	};

	// useEffect(() => {
	// 	if (checkCurrencyMessage) {
	// 		clearTimeout(timeout);
	// 	}
	// }, [checkCurrencyMessage]);

	const handleFromWalletSelect = (value: IWalletItem): void => {
		setSelectFromWallet(value);

		if (selectToWallet) {
			// clearTimeout(timeout);
			autoReloadExchangeRate(value.asset.id, selectToWallet.asset.id);
		}

		setFrom('');
		setTo('');
	};

	const handleFromAssetSelect = (value: IAssetsItem): void => {
		setSelectFromAsset(value);

		searchParams.set('from', value.code);

		if (selectToAsset) {
			// clearTimeout(timeout);
			autoReloadExchangeRate(value.id, selectToAsset.id);
		}

		setFrom('');
		setTo('');
	};

	const handleToWalletSelect = (value: IWalletItem): void => {
		setSelectToWallet(value);
		searchParams.set('to', value.asset.code);

		if (selectFromWallet) {
			// clearTimeout(timeout);
			autoReloadExchangeRate(selectFromWallet.asset.id, value.asset.id);
		}

		setFrom('');
		setTo('');
	};

	const handleToAssetSelect = (value: IAssetsItem): void => {
		setSelectToAsset(value);
		searchParams.set('to', value.code);

		if (selectFromAsset) {
			// clearTimeout(timeout);
			autoReloadExchangeRate(selectFromAsset.id, value.id);
		}

		setFrom('');
		setTo('');
	};

	const currentAssetPairLimits = useMemo(() => {
		const currentPair = `${String(selectFromCode)}_${String(selectToCode)}`;
		const currentPairReverse = `${String(selectToCode)}_${String(selectFromCode)}`;

		if (assetPairsFeesAndLimit?.length) {
			setIsReverse(() => {
				const result = assetPairsFeesAndLimit.find((pair) => pair.code === currentPair);
				return !result;
			});
		}

		return assetPairsFeesAndLimit?.length
			? assetPairsFeesAndLimit.find(
					(pair) => pair.code === currentPair || pair.code === currentPairReverse,
			  )
			: null;
	}, [assetPairsFeesAndLimit, selectFromWallet, selectToWallet]);

	const currentAssetPairLimitsAmountMin = userIsVip
		? currentAssetPairLimits?.vip_amount_min || 0
		: currentAssetPairLimits?.amount_min || 0;
	const currentAssetPairLimitsAmountMax = userIsVip
		? currentAssetPairLimits?.vip_amount_max || 0
		: currentAssetPairLimits?.amount_max || 0;
	const isCheckExchangeRateAvailable = selectFromAssetId && selectToAssetId;

	const handleCheckExchangeRate = (): void => {
		if (isCheckExchangeRateAvailable) {
			const exchangeRateRequestData = {
				from_asset_id: selectFromAssetId,
				to_asset_id: selectToAssetId,
			};

			setTimeLimit(15);
			dataLayer.push({
				event: 'exchange_check',
			});
			dispatch(getExchangeRateRequest(exchangeRateRequestData));
		}
	};

	const calculateExchangeAmount = () => {
		if ((from && !to && exchangeRate) || (procentBut && exchangeRate && from)) {
			const toValue = convertExponentialToDecimal(+from * +exchangeRate.rate);
			setTo(trancDecimal(Number(toValue), 8));
		} else if (!from && to && exchangeRate) {
			const fromValue = convertExponentialToDecimal(+to / +exchangeRate.rate);
			setFrom(trancDecimal(Number(fromValue), 8));
		}
	};

	useEffect(() => {
		calculateExchangeAmount();
	}, [from, to, exchangeRate]);

	useEffect(() => {
		const timeoutCheck = setTimeout(() => {
			if (timeLimit > 0) {
				setTimeLimit(timeLimit - 1);
			} // else {
			// 	clearTimeout(timeout);
			// }
		}, 1000);

		return () => clearTimeout(timeoutCheck);
	}, [timeLimit]);

	// useEffect(() => {
	// 	if (isCheckExchangeRateAvailable) {
	// 		console.log('what avalible');
	// 		const exchangeRateRequestData = {
	// 			from_asset_id: selectFromWallet.asset.id,
	// 			to_asset_id: selectToWallet.asset.id,
	// 		};

	// 		dispatch(getExchangeRateRequest(exchangeRateRequestData));
	// 	}
	// }, [selectFromWallet, selectToWallet, dispatch]);

	useEffect(() => {
		if (isCheckExchangeRateAvailable) {
			const exchangeRateRequestData = {
				from_asset_id: selectFromAssetId,
				to_asset_id: selectToAssetId,
			};

			dispatch(getExchangeRateRequest(exchangeRateRequestData));
		}
	}, [isReverse]);

	const fromWalletsList = useMemo(
		() => convertWalletsFiltered(walletsList, asssetPairs, selectToWallet),
		[walletsList, selectToWallet, asssetPairs],
	);

	const toWalletsList = useMemo(
		() => convertWalletsFiltered(walletsList, asssetPairs, selectFromWallet),
		[walletsList, selectFromWallet, asssetPairs],
	);

	const fromAssetsList = assetsList?.filter(
		(item) => item.type !== 'fiat' && item.code !== selectFromAsset?.code,
	);
	const toAssetsList = assetsList?.filter(
		(item) => item.type !== 'fiat' && item.code !== selectToAsset?.code,
	);

	const codeIsInAssetPairs = (code: string) => {
		return asssetPairs?.some(
			(item) => item.code.split('_')[0] === code || item.code.split('_')[1] === code,
		);
	};

	useEffect(() => {
		if (isAuth) {
			if (walletsList?.length && asssetPairs?.length) {
				const defaultFrom = fromSearch && codeIsInAssetPairs(fromSearch) ? fromSearch : 'btc';
				const fromWallet =
					walletsList?.find(
						(wallet) =>
							wallet?.asset?.code ===
							(selectFromWallet ? selectFromWallet?.asset?.code : defaultFrom),
					) || null;
				const defaultTo = toSearch && codeIsInAssetPairs(toSearch) ? toSearch : 'usdb';
				const toWallet =
					walletsList?.find(
						(wallet) =>
							wallet?.asset?.code === (selectToWallet ? selectToWallet?.asset?.code : defaultTo),
					) || null;

				setSelectFromWallet(fromWallet);
				setSelectToWallet(toWallet);
				fromWallet && searchParams.set('from', fromWallet.asset.code);
				toWallet && searchParams.set('to', toWallet.asset.code);

				if (fromWallet && toWallet) {
					autoReloadExchangeRate(fromWallet.asset.id, toWallet.asset.id);
				}
			}
		} else {
			const defaultFrom =
				assetsList?.find(
					(item) =>
						item.code === (fromSearch && codeIsInAssetPairs(fromSearch) ? fromSearch : 'btc'),
				) || null;
			const defaultTo =
				assetsList?.find(
					(item) => item.code === (toSearch && codeIsInAssetPairs(toSearch) ? toSearch : 'usdb'),
				) || null;

			setSelectFromAsset(defaultFrom);
			setSelectToAsset(defaultTo);

			defaultFrom && searchParams.set('from', defaultFrom.code);
			defaultTo && searchParams.set('to', defaultTo.code);

			if (defaultFrom && defaultTo) {
				autoReloadExchangeRate(defaultFrom.id, defaultTo.id);
			}
		}
		// return () => {
		// 	clearTimeout(timeout);
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletsList, asssetPairs, toWalletsList, isAuth, assetsList, fromSearch, toSearch]);

	useEffect(() => {
		if (amountFromSearch) {
			setFrom(amountFromSearch);
		}

		if (amountToSearch) {
			setTo(amountToSearch);
		}
	}, [amountFromSearch, amountToSearch]);

	useEffect(() => {
		if (responseData) {
			dispatch(closeConvertModal());
		}
	}, []);

	const handleWalletsSwap = () => {
		if (isAuth) {
			setSelectFromWallet(selectToWallet);
			setSelectToWallet(selectFromWallet);
		} else {
			setSelectFromAsset(selectToAsset);
			setSelectToAsset(selectFromAsset);
		}

		setIsReverse(!isReverse);

		setFrom('');
		setTo('');
	};

	const handleChangeFrom = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setProcent(false);
		if (numberValidation(value)) {
			setTo('');
			setFrom(value);
		}
	};

	const handleChangeTo = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setProcent(false);
		if (numberValidation(value)) {
			setFrom('');
			setTo(value);
		}
	};

	const handleConvert = () => {
		if (
			selectFromWallet &&
			selectToWallet &&
			selectFromWallet?.asset?.id &&
			selectToWallet?.asset?.id
		) {
			const testMakeExhangeRequestData = {
				from_asset_id: selectFromWallet.asset.id,
				to_asset_id: selectToWallet.asset.id,
				quantity: Number(from),
			};

			dataLayer.push({
				event: 'confirm_convert',
			});
			dispatch(makeExchangeRequest(testMakeExhangeRequestData));
			setOpenModal(false);
			setFrom('');
			setTo('');
		}
	};

	const handleMaxBalance = () => {
		const maxFrom = Number(selectFromWallet?.balance || 0);
		if (exchangeRate) {
			setFrom(trancDecimal(Number(maxFrom), 8));
			const toValue = convertExponentialToDecimal(maxFrom * +exchangeRate.rate);
			setTo(trancDecimal(Number(toValue), 8));
		}
	};

	const countFrom = (value: number) => {
		setFrom(trancDecimal(Number(convertExponentialToDecimal(value)), 8));
		// setTo('');
		setProcent(true);
	};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!selectFromWallet?.balance || !Number(percentValue)) {
			return 0;
		}

		return Number(selectFromWallet?.balance) * percentValue;
	};

	const getPlaceHolderTextValue = (type: string) => {
		if (type === 'from') {
			return !isReverse
				? `${String(L.translate('ConvertForm.please_enter'))} ${
						convertExponentialToDecimal(currentAssetPairLimitsAmountMin) || '0.00000001'
				  } - ${convertExponentialToDecimal(currentAssetPairLimitsAmountMax) || '99999999'}`
				: `${String(L.translate('ConvertForm.please_enter'))} ${
						convertExponentialToDecimal(
							fixedCropNumber(
								Number(currentAssetPairLimitsAmountMin) / Number(exchangeRate?.rate),
								8,
							),
						) || '0.00000001'
				  } - ${
						convertExponentialToDecimal(
							fixedCropNumber(
								Number(currentAssetPairLimitsAmountMax) / Number(exchangeRate?.rate),
								8,
							),
						) || '99999999'
				  }`;
		}

		if (type === 'to') {
			return !isReverse
				? `${String(L.translate('ConvertForm.please_enter'))} ${
						convertExponentialToDecimal(
							fixedCropNumber(
								Number(currentAssetPairLimitsAmountMin || 0) * Number(exchangeRate?.rate || 0),
								8,
							),
						) || '0.00000001'
				  } - ${
						convertExponentialToDecimal(
							fixedCropNumber(
								Number(convertExponentialToDecimal(currentAssetPairLimitsAmountMax) || 0) *
									Number(exchangeRate?.rate || 0),
								8,
							),
						) || '99999999'
				  }`
				: `${String(L.translate('ConvertForm.please_enter'))} ${convertExponentialToDecimal(
						currentAssetPairLimitsAmountMin,
				  )} - ${convertExponentialToDecimal(currentAssetPairLimitsAmountMax)}`;
		}

		return '';
	};

	const isMinAmountFromError = !isReverse
		? Number(from) && Number(from) < currentAssetPairLimitsAmountMin
		: Number(from) &&
		  Number(currentAssetPairLimitsAmountMin) / Number(exchangeRate?.rate) > Number(from);

	const isMaxAmountFromError = !isReverse
		? Number(from) && Number(from) > currentAssetPairLimitsAmountMax
		: Number(from) &&
		  Number(currentAssetPairLimitsAmountMax) / Number(exchangeRate?.rate) < Number(from);

	const isMinAmountToError = isReverse
		? Number(to) && Number(to) < currentAssetPairLimitsAmountMin
		: Number(to) &&
		  Number(currentAssetPairLimitsAmountMin) * Number(exchangeRate?.rate) > Number(to);

	const isMaxAmountToError = isReverse
		? Number(to) && Number(to) > currentAssetPairLimitsAmountMax
		: Number(to) &&
		  Number(currentAssetPairLimitsAmountMax) * Number(exchangeRate?.rate) < Number(to);

	const isAmountBiggerBalanceFromError =
		Number(from) && Number(from) > Number(selectFromWallet?.balance);

	const isOperationAvailable =
		(!Number(from) && !Number(to)) ||
		!!isAmountBiggerBalanceFromError ||
		!!isMinAmountFromError ||
		!!isMaxAmountFromError ||
		!!isMinAmountToError ||
		!!isMaxAmountToError ||
		exchangeRateIsLoad ||
		!!timeLimit;

	const getFieldClass = (fieldName: string): string => {
		switch (fieldName) {
			case 'from':
				return isMinAmountFromError || isMaxAmountFromError
					? 'input-item input-item--enter-value input-item--right-double-icon convert-input-item--enter-value-error'
					: 'input-item input-item--enter-value input-item--right-double-icon';
			case 'to':
				return isMinAmountToError || isMaxAmountToError
					? 'input-item input-item--enter-value input-item--right-double-icon convert-input-item--enter-value-error'
					: 'input-item input-item--enter-value input-item--right-double-icon';

			default:
				return 'input-item input-item--enter-value';
		}
	};
	const dataConfirm = {
		fromAmount: from,
		toAmount: to,
		from: selectFromWallet,
		to: selectToWallet,
		receive: 'df',
		rate: String(exchangeRate?.rate),
		fee: 'dfd',
	};

	const [isButtonDisabled, setButtonDisabled] = useState(false);

	const handleClick = () => {
		setButtonDisabled(true);
		setTimeout(() => {
			setButtonDisabled(false);
		}, 1000);
		handleWalletsSwap();
	};

	return (
		<>
			<ConfirmPopup
				openModal={openModal}
				closeModal={closeModal}
				handleConfirm={handleConvert}
				data={dataConfirm}
			/>
			{responseData && <ConvertConfirmPopup />}
			<div className="content-wrapper content-wrapper--padding-all content-wrapper--bg">
				<div className="container">
					<div className="page-info">
						<h1 className="page-info__title">
							{String(L.translate('ConvertForm.convert_title'))}{' '}
						</h1>
						<p className="page-info__desc">
							{String(L.translate('ConvertForm.section_description'))}
							{/* <br />
							{String(L.translate('ConvertForm.section_description_2'))} */}
						</p>
					</div>
					<div className="convert">
						{walletsListIsLoad || asssetPairsIsLoad || assetPairsFeesAndLimitIsLoad ? (
							<div className="convert-loader-wrapper">
								<Loader />
							</div>
						) : (
							<>
								<div className="enter-value">
									<div className="enter-value__header">
										<span className="enter-value__label">
											{String(L.translate('ConvertForm.from'))}
										</span>
										<span className="enter-value__label">
											{String(L.translate('ConvertForm.available'))}:{' '}
											{trancDecimal(
												Number(convertExponentialToDecimal(Number(selectFromWallet?.balance)) || 0),
												8,
											)}{' '}
											{selectFromCode?.toUpperCase()}
										</span>
									</div>
									<div className="enter-value__field convert-enter-value__field">
										<div className="convert-enter-value-field-box">
											<div className="input input--margin-none">
												<div className="input-wrapper">
													<input
														className={getFieldClass('from')}
														type="text"
														placeholder={getPlaceHolderTextValue('from')}
														onChange={handleChangeFrom}
														value={from}
													/>

													<div className="input-icon input-icon--auto input-icon--right convert-input-icon">
														<button
															type="button"
															className="input__link"
															onClick={handleMaxBalance}
														>
															{String(L.translate('ConvertForm.max_button'))}
														</button>
													</div>
												</div>
											</div>
											{isAuth ? (
												<ConvertDropdown
													value={selectFromWallet}
													onChange={handleFromWalletSelect}
													options={fromWalletsList}
												/>
											) : (
												<ConvertAssetDropdown
													value={selectFromAsset}
													onChange={handleFromAssetSelect}
													options={fromAssetsList || []}
												/>
											)}
										</div>
										{isMaxAmountFromError && !isAmountBiggerBalanceFromError ? (
											<TextError>
												{' '}
												{String(L.translate('ConvertForm.max_amount'))}{' '}
												{isReverse
													? convertExponentialToDecimal(
															fixedCropNumber(
																currentAssetPairLimitsAmountMax / Number(exchangeRate?.rate),
																8,
															),
													  )
													: convertExponentialToDecimal(currentAssetPairLimitsAmountMax)}
											</TextError>
										) : null}
										{isMinAmountFromError ? (
											<TextError>
												{String(L.translate('ConvertForm.min_amount'))}{' '}
												{isReverse
													? convertExponentialToDecimal(
															fixedCropNumber(
																currentAssetPairLimitsAmountMin / Number(exchangeRate?.rate),
																selectFromCode?.toUpperCase() ===
																	('USDT' || 'USD' || 'EUR' || 'GEL' || 'GBP' || 'UAH')
																	? 4
																	: 8,
															),
													  )
													: currentAssetPairLimitsAmountMin.toFixed(
															selectFromCode?.toUpperCase() ===
																('USDT' || 'USD' || 'EUR' || 'GEL' || 'GBP' || 'UAH')
																? 4
																: 8,
													  )}
											</TextError>
										) : null}
										{isAmountBiggerBalanceFromError ? (
											<TextError>{String(L.translate('ConvertForm.amount_error'))}</TextError>
										) : null}
									</div>
								</div>
								<div className="switch-img">
									<button
										className="switch-img__circle"
										type="button"
										// onClick={handleWalletsSwap}
										onClick={handleClick}
										disabled={isButtonDisabled}
									>
										<div className="switch-img__wrap">
											<span className="switch-img__icon icon-arrow-3" />
										</div>
									</button>
								</div>
								<div className="enter-value">
									<div className="enter-value__header">
										<span className="enter-value__label">
											{String(L.translate('ConvertForm.to'))}
										</span>
									</div>
									<div className="enter-value__field convert-enter-value__field">
										<div className="convert-enter-value-field-box">
											<div className="input input--margin-none">
												<div className="input-wrapper">
													<input
														className={getFieldClass('to')}
														type="text"
														placeholder={getPlaceHolderTextValue('to')}
														onChange={handleChangeTo}
														value={to}
													/>
												</div>
											</div>
											{isAuth ? (
												<ConvertDropdown
													value={selectToWallet}
													onChange={handleToWalletSelect}
													options={toWalletsList}
												/>
											) : (
												<ConvertAssetDropdown
													value={selectToAsset}
													onChange={handleToAssetSelect}
													options={toAssetsList || []}
												/>
											)}
										</div>
										{isMaxAmountToError ? (
											<TextError>
												{' '}
												{String(L.translate('ConvertForm.max_amount'))}{' '}
												{!isReverse
													? convertExponentialToDecimal(
															fixedCropNumber(
																currentAssetPairLimitsAmountMax * Number(exchangeRate?.rate),
																8,
															) || 0,
													  )
													: convertExponentialToDecimal(currentAssetPairLimitsAmountMax)}
											</TextError>
										) : null}
										{isMinAmountToError ? (
											<TextError>
												{String(L.translate('ConvertForm.min_amount'))}{' '}
												{!isReverse
													? convertExponentialToDecimal(
															fixedCropNumber(
																currentAssetPairLimitsAmountMin * Number(exchangeRate?.rate),
																selectToCode?.toUpperCase() ===
																	('USDT' || 'USD' || 'EUR' || 'GEL' || 'GBP' || 'UAH')
																	? 4
																	: 8,
															),
													  )
													: convertExponentialToDecimal(currentAssetPairLimitsAmountMin)}
											</TextError>
										) : null}
									</div>
								</div>
								<div className="convert-calc">
									<div className="convert-calc__block">
										<div className="convert-calc__btn-container">
											<button
												className="button button--full-width"
												type="button"
												onClick={handleCheckExchangeRate}
												disabled={!isCheckExchangeRateAvailable || !!timeLimit}
											>
												{String(L.translate('ConvertForm.check'))} {!!timeLimit && `(${timeLimit})`}
											</button>
										</div>
										<span className="convert-calc__text">
											{!exchangeRateIsLoad ? (
												<>
													1 {selectFromCode?.toUpperCase()} ≈ {exchangeRate?.rate || 0}{' '}
													{selectToCode?.toUpperCase()}
												</>
											) : (
												`${String(L.translate('ConvertForm.loading'))}`
											)}
										</span>
									</div>
								</div>
								{isAuth && (
									<ConvertPercentButtons
										countFrom={countFrom}
										percentButtonCountValue={percentButtonCountValue}
										amount={from}
									/>
								)}

								<div className="convert__footer">
									{isAuth ? (
										<button
											className="button button--full-width"
											type="button"
											onClick={() => {
												if (userData?.status.name === 'unverified') {
													notificationContainer(
														String(L.translate('ConvertForm.need_verify')),
														'info',
													);
													return;
												}
												dataLayer.push({
													event: 'convert',
												});
												setOpenModal(true);
											}}
											disabled={isOperationAvailable}
										>
											{String(L.translate('ConvertForm.convert_button'))}
										</button>
									) : (
										<LoginOrRegister />
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(ConvertForm);
