import L from 'i18n-react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';

const VerificationBanner = () => {
	const history = useHistory();
	const userData = useSelector(getUserSettingsData);

	const isUnverified = userData?.status?.name === 'unverified';
	const goToVerification = () => {
		history.push({
			pathname: '/general-settings',
			state: {
				startVerification: true,
			},
		});
	};

	if (!userData || !isUnverified) {
		return null;
	}

	return (
		<div className="verify-banner">
			<p className="verify-banner__info">
				<span className="verify-banner__icon">
					<svg
						width="19"
						height="20"
						viewBox="0 0 19 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.50065 17.9163C13.8548 17.9163 17.4173 14.3538 17.4173 9.99967C17.4173 5.64551 13.8548 2.08301 9.50065 2.08301C5.14648 2.08301 1.58398 5.64551 1.58398 9.99967C1.58398 14.3538 5.14648 17.9163 9.50065 17.9163Z"
							stroke="#011E26"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M9.5 6.83301V10.7913"
							stroke="#011E26"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M9.49609 13.167H9.5032"
							stroke="#011E26"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				{String(
					L.translate(
						userData.kyc_applicant_id ? 'SpotAndFiat.please_update' : 'SpotAndFiat.please_verify',
					),
				)}
			</p>
			<button
				type="button"
				className="button button--size2 button--red-type button--width-auto"
				onClick={goToVerification}
			>
				{String(
					L.translate(
						userData.kyc_applicant_id ? 'SpotAndFiat.update_now' : 'SpotAndFiat.verify_now',
					),
				)}
			</button>
		</div>
	);
};

export default VerificationBanner;
