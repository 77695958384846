import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import L from 'i18n-react';
import {
	getP2PAssetsRequest,
	getP2PCurrenciesRequest,
	getPaymentRequest,
} from 'redux/reducers/p2p/reducer';

import P2PMarketplace from 'components/P2PMarketplace';
import MarketplaceP2P from 'layouts/MarketplaceP2P';

// ==========================================:
const Marketplace: FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getP2PCurrenciesRequest());
		dispatch(getP2PAssetsRequest());
		dispatch(getPaymentRequest());
	}, [dispatch]);

	return (
		<MarketplaceP2P title={String(L.translate('PageTitles.p2p_marketplace'))} showVerificBanner>
			<P2PMarketplace />
		</MarketplaceP2P>
	);
};

export default Marketplace;
