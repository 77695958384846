import { FC, useEffect } from 'react';
import { getTradeStatus } from 'redux/reducers/p2p/selectors';
import { getChatRequest } from 'redux/reducers/p2p/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { IChatData } from './type';
import Chat from './Chat';

const ChatTrade: FC<IChatData> = ({ id }) => {
	const dispatch = useDispatch();
	const tradeStatus = useSelector(getTradeStatus);

	useEffect(() => {
		dispatch(getChatRequest({ trade_id: String(id) }));
	}, [id, dispatch]);

	return (
		<div className="create-offer__right">
			<Chat type="user" id={id} />
			{tradeStatus === 'under_dispute' ? <Chat type="admin" id={id} /> : ''}
		</div>
	);
};

export default ChatTrade;
