/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useState } from 'react';
import L from 'i18n-react';
import { useDispatch } from 'react-redux';
import { removeOpenOrdersRequest } from 'redux/reducers/spotTrade/reducer';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import ConfirmDeletePopup from 'ui/ConfirmDeletePopup';
import { IOpenOrdersItemProps } from './types';

const OpenOrdersItem: FC<IOpenOrdersItemProps> = ({ data }) => {
	const { created_at, pair, type, price, quantity, filled, total, trigger_conditions, id } = data;

	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);

	const getType = (triggerValue: string | number | null, typeValue: string): string => {
		if (triggerValue) return 'Stop-Limit';

		return typeValue?.includes('market') ? 'Market' : 'Limit';
	};

	const getMarketType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return 'Sell';

		if (typeValue?.includes('buy')) return 'Buy';

		return '-';
	};

	const getClassByType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return 'td-name td-name--regular td-name--red';

		if (typeValue?.includes('buy')) return 'td-name td-name--regular td-name--green';

		return 'td-name';
	};

	const getTriggerConditions = () => {
		if (!trigger_conditions) return '-';

		const sign = type?.includes('sell') ? '<=' : '>=';
		const value = Number(trigger_conditions).toFixed(6);

		return `${sign} ${value}`;
	};

	const handleRemoveUserOpenOrder = () => {
		dispatch(removeOpenOrdersRequest({ id }));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<span className="td-name td-name--regular td-name--light">
					{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<span className="td-name td-name--regular  td-name--fw500">{transformPairCode(pair)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<span className="td-name td-name--regular">{getType(trigger_conditions, type)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Side</p>
				<span className={getClassByType(type)}>{getMarketType(type)}</span>
			</div>

			<div className="td">
				<p className="td-hidden-name">Price</p>
				<span className="td-name td-name--regular">{Number(price).toFixed(6) || '-'}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<span className="td-name td-name--regular">{Number(quantity).toFixed(6) || '-'}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Filled</p>
				<span className="td-name td-name--regular">{filled ? `${String(filled)} %` : '-'}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<div>
					<span className="td-name td-name--regular">{Number(total).toFixed(6)}</span>
					<span className="td-name td-name--regular">USDT</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Trigger Conditions</p>
				<span className="td-name td-name--regular">
					{getTriggerConditions()}
					{/* {Number(trigger_conditions).toFixed(6) || '-'} */}
				</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<button
					type="button"
					className="link td-name--regular"
					onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
				>
					{String(L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.cancel_button'))}
				</button>
				<ConfirmDeletePopup
					title={String(
						L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_order_title'),
					)}
					bodyMessage={String(
						L.translate(
							'Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_order_body_message',
						),
					)}
					openModal={openModal}
					closeModal={closeModal}
					handleDelete={handleRemoveUserOpenOrder}
				/>
			</div>
		</div>
	);
};

export default OpenOrdersItem;
