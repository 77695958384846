import { FC } from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { getWalletsIsLoad } from 'redux/reducers/wallets/selectors';
import Loader from 'ui/Loader';
import IsolatedItem from '../IsolatedItem';
import { ISpotAndFiatSortingTableProps } from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

const IsolatedTable: FC<ISpotAndFiatSortingTableProps> = ({ data, balancesIsHide }) => {
	const waletsIsLoad = useSelector(getWalletsIsLoad);

	return (
		<div className="table table--balance table--balance-isolate">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Margin.Isolated.Funds.Table.pair'))}
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Margin.Isolated.Funds.Table.risk_ration'))}
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Margin.Isolated.Funds.Table.effective_multiple'))}
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Margin.Isolated.Funds.Table.coin'))}
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Margin.Isolated.Funds.Table.total_bal'))}
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Margin.Isolated.Funds.Table.available_balance'))}
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Margin.Isolated.Funds.Table.borrowed'))}
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Margin.Isolated.Funds.Table.interest'))}
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{L.translate('Margin.Isolated.Funds.Table.equity_value')} (BTC)
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{L.translate('Margin.Isolated.Funds.Table.action')}
						</span>
					</div>
				</div>
			</div>
			<div className="table-body">
				{waletsIsLoad ? (
					<div className="spot-and-fiat-wallets-table-loader-wrapper">
						<Loader />
					</div>
				) : null}

				{!waletsIsLoad && data?.length
					? data?.map((item: any) => (
							<IsolatedItem
								key={Number(item?.base?.pair[0]?.id)}
								data={item}
								balancesIsHide={balancesIsHide}
							/>
					  ))
					: null}

				{!waletsIsLoad && data?.length === 0 && (
					<span className="table-empty">{String(L.translate('MarginTable.no_wallets_found'))}</span>
				)}
			</div>
			<div className="table-footer" />
		</div>
	);
};

export default IsolatedTable;
