import { FC, useMemo, useState } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getWalletsIsLoad } from 'redux/reducers/wallets/selectors';
import { ICrossWalletsData } from 'redux/reducers/marginWallets/types';
import Loader from 'ui/Loader';
import SortedButton from 'ui/SortedButton';
import MarginItem from '../MarginItem';
import { ISpotAndFiatSortingTableProps } from './types';

const MarginTable: FC<ISpotAndFiatSortingTableProps> = ({ data, balancesIsHide }) => {
	const [sortMagnitude, setSortMagnitude] = useState('');
	const [sortType, setSortType] = useState('');

	const waletsIsLoad = useSelector(getWalletsIsLoad);

	const sortedData = useMemo(() => {
		if (!data) return [];
		if (sortMagnitude === 'desc') {
			return [...data].sort((a, b) => {
				const sortFirstItem =
					a.asset[sortType as keyof typeof a.asset] || Number(a[sortType as keyof typeof a]);
				const sortSecondItem =
					b.asset[sortType as keyof typeof b.asset] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem > sortSecondItem ? 1 : -1;
			});
		}

		if (sortMagnitude === 'asc') {
			return [...data].sort((a, b) => {
				const sortFirstItem =
					a.asset[sortType as keyof typeof a.asset] || Number(a[sortType as keyof typeof a]);
				const sortSecondItem =
					b.asset[sortType as keyof typeof b.asset] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem < sortSecondItem ? 1 : -1;
			});
		}

		return data;
	}, [data, sortMagnitude, sortType]);

	return (
		<div className="table table--balance table--balance-cross">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<SortedButton
							title={String(L.translate('MarginTable.coin'))}
							sortType={sortType}
							sortOrderType="code"
							setSortType={setSortType}
							sortMagnitude={sortMagnitude}
							setSortMagnitude={setSortMagnitude}
						/>
					</div>
					<div className="td">
						<SortedButton
							title={String(L.translate('MarginTable.total_balance'))}
							sortType={sortType}
							sortOrderType="total"
							setSortType={setSortType}
							sortMagnitude={sortMagnitude}
							setSortMagnitude={setSortMagnitude}
						/>
					</div>
					<div className="td">
						<SortedButton
							title={String(L.translate('MarginTable.available_balance'))}
							sortType={sortType}
							sortOrderType="balance"
							setSortType={setSortType}
							sortMagnitude={sortMagnitude}
							setSortMagnitude={setSortMagnitude}
						/>
					</div>
					<div className="td">
						<SortedButton
							title={String(L.translate('MarginTable.borrowed'))}
							sortType={sortType}
							sortOrderType="borrow"
							setSortType={setSortType}
							sortMagnitude={sortMagnitude}
							setSortMagnitude={setSortMagnitude}
						/>
					</div>
					<div className="td">
						<SortedButton
							title={String(L.translate('MarginTable.interest'))}
							sortType={sortType}
							sortOrderType="interest_amount"
							setSortType={setSortType}
							sortMagnitude={sortMagnitude}
							setSortMagnitude={setSortMagnitude}
						/>
					</div>
					<div className="td">
						<SortedButton
							title={String(L.translate('MarginTable.equity_value'))}
							sortType={sortType}
							sortOrderType="own_balance"
							setSortType={setSortType}
							sortMagnitude={sortMagnitude}
							setSortMagnitude={setSortMagnitude}
						/>
					</div>
					<div className="td">
						<span className="table-header__name">{String(L.translate('MarginTable.action'))}</span>
					</div>
				</div>
			</div>
			<div className="table-body">
				{waletsIsLoad ? (
					<div className="spot-and-fiat-wallets-table-loader-wrapper">
						<Loader />
					</div>
				) : null}

				{!waletsIsLoad && sortedData?.length
					? sortedData?.map((item: ICrossWalletsData) => (
							<MarginItem key={item.id} data={item} balancesIsHide={balancesIsHide} />
					  ))
					: null}

				{!waletsIsLoad && sortedData?.length === 0 && (
					<span className="table-empty">{String(L.translate('MarginTable.no_wallets_found'))}</span>
				)}
			</div>
			<div className="table-footer" />
		</div>
	);
};

export default MarginTable;
