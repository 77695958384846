/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useEffect, useState, useRef } from 'react';
import L from 'i18n-react';
import {
	getTimeParserDateFromTimestamp,
	getTimeParserTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { notificationContainer } from 'services/utils/notificationContainer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useTimer from 'hooks/useTimer';
import { P2PItemProps } from './types';
/* eslint-disable no-debugger */
const P2PItem: FC<P2PItemProps> = ({ data, type }) => {
	const {
		created_at,
		asset_code,
		id,
		advertisement,
		amount,
		img_path,
		status,
		seller,
		buyer,
		seller_id,
		price,
	} = data;

	const { payment_window } = advertisement;

	const user = useSelector(getUserSettingsData);
	const { refHours, refMinutes, refSeconds } = useTimer(created_at, payment_window);

	const createAt = created_at;
	const getMarketType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return String(L.translate('P2P.OpenTrades.sell'));

		if (typeValue?.includes('buy')) return String(L.translate('P2P.OpenTrades.buy'));

		return '-';
	};

	const getStatus = (statusValue: string): string => {
		if (statusValue?.includes('waiting_for_confirmation'))
			return String(L.translate('P2P.OpenTrades.waiting_for_confirmation'));

		if (statusValue?.includes('under_dispute'))
			return String(L.translate('P2P.OpenTrades.under_dispute'));

		if (statusValue?.includes('waiting_for_payment'))
			return String(L.translate('P2P.OpenTrades.make_a_transaction'));

		if (statusValue?.includes('closed')) return String(L.translate('P2P.OpenTrades.closed'));

		if (statusValue?.includes('canceled  ')) return String(L.translate('P2P.OpenTrades.canceled'));

		return '-';
	};
	const getClassByType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return 'table-info__type table-info__type--red';

		if (typeValue?.includes('buy')) return 'table-info__type table-info__type--green';

		return 'td-name';
	};

	const userId = user && user.id ? user.id : '';

	const counterpartyEmail = seller_id === userId ? buyer?.username : seller?.username;

	const handleIdCopy = () => {
		notificationContainer(String(L.translate('P2P.OpenTrades.trade_is_copied')), 'info');
	};
	// if (!tradeId) return;
	// 	dispatch(getTradePaySellRequest({ trade_id: String(tradeId) }));
	return (
		<div className="tr">
			<div className="table-info">
				<div className="table-info__group">
					<p className={getClassByType(type)}>{getMarketType(type)}</p>
					<p className="table-info__date">
						{getTimeParserDateFromTimestamp(created_at)}{' '}
						{getTimeParserTimeFromTimestamp(created_at)}
					</p>
				</div>
				<div className="copy-value">
					<p className="copy-value__text">{id}</p>
					<CopyToClipboard text={String(id)} onCopy={handleIdCopy}>
						<button type="button" className="copy-value__btn">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.33203 9.99967H2.66536C2.31174 9.99967 1.9726 9.8592 1.72256 9.60915C1.47251 9.3591 1.33203 9.01996 1.33203 8.66634V2.66634C1.33203 2.31272 1.47251 1.97358 1.72256 1.72353C1.9726 1.47348 2.31174 1.33301 2.66536 1.33301H8.66536C9.01899 1.33301 9.35813 1.47348 9.60817 1.72353C9.85822 1.97358 9.9987 2.31272 9.9987 2.66634V3.33301"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
			</div>

			<div className="td">
				<p className="td-hidden-name">Type/Coin</p>
				<div className="balance-currency">
					<div className="balance-currency__img">
						<img src={img_path} alt="" />
					</div>
					<div className="balance-currency__info">
						<span className="balance-currency__title">{asset_code.toLocaleUpperCase()}</span>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fiat amount</p>
				<span className="td-name td-name--regular td-name--fw500 td--no-break-words">
					{price} {advertisement.current_fiat_code.toLocaleUpperCase()}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Crypto amount</p>
				<span className="td-name td-name--regular td--no-break-words">{`${
					data.type === 'token' ? Number(amount).toFixed(2) : amount
				}  ${asset_code.toLocaleUpperCase()}`}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Counterparty</p>
				<span className="td-name td-name--regular ">{counterpartyEmail}</span>
				{/* <p>
					<Link to="/" className="table-link">
						KHMER COIN®
					</Link>
				</p> */}
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<div>
					<p className="td-name td-name--regular td--no-break-words">{getStatus(status)}</p>
					{status.includes('waiting_for_payment') && (
						<div className="table-time">
							<span className="table-time__icon">
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M14.9375 8C14.9375 11.8317 11.8317 14.9375 8 14.9375C4.16825 14.9375 1.0625 11.8317 1.0625 8C1.0625 4.16825 4.16825 1.0625 8 1.0625C11.8317 1.0625 14.9375 4.16825 14.9375 8Z"
										stroke="#03BC70"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M10.5736 10.2068L7.74609 8.52002V4.88477"
										stroke="#03BC70"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							<span className="descr-timer">
								<span className="timer" ref={refHours}>
									00
								</span>
								:
								<span className="timer" ref={refMinutes}>
									00
								</span>
								:
								<span className="timer" ref={refSeconds}>
									00
								</span>
							</span>
							{/* 13:24 */}
						</div>
					)}
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Operation</p>
				<div className="link-group">
					<NavLink
						to={{
							pathname: `/p2p-trading/pay-sell`,
							search: `?trade_id=${String(id)}`,
							state: 1,
						}}
						className="link link--regular"
					>
						{String(L.translate('P2P.OpenTrades.trade'))}
					</NavLink>
				</div>
			</div>
		</div>
	);
};

export default P2PItem;
