import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import Popup from 'reactjs-popup';
import { Card } from './Card';
import { BankTransfer } from './BankTransfer';
import { WebWallet } from './WebWallet';
import { IPropsModal } from './types';

export const PopusModal: FC<IPropsModal> = ({ openModal, closeModal, type, handleStatus }) => {
	const currentPopup = (val: string) => {
		switch (val) {
			case 'Card':
				return <Card closeModal={closeModal} handleStatus={handleStatus} />;
			case 'Bank transfer':
				return <BankTransfer closeModal={closeModal} handleStatus={handleStatus} />;
			case 'Web wallet':
				return <WebWallet closeModal={closeModal} handleStatus={handleStatus} />;
			default:
				return 'check';
		}
	};

	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
			{type && currentPopup(type)}
		</Popup>
	);
};
