import { intervals } from '../helpers/interval';
import historyProvider from './historyProvider';
import { makeApiRequest, generateSymbol, parseFullSymbol } from './helpers.js';
import stream, { updateBarBySockets } from './stream';

export const addZeroAfterPoint = (n) => {
	if (n > 1) {
		return `0${addZeroAfterPoint(n - 1)}`;
	}
	return '0';
};

const supportedResolutions = Object.keys(intervals);
const configurationData = {
	supported_resolutions: supportedResolutions,
};

const hosting = { resolution: '1D', decimal: 8 };
export const setChartDecimal = (dec) => {
	hosting.decimal = dec;
};

export default {
	onReady: (callback) => {
		setTimeout(() => callback(configurationData));
	},
	searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {},
	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
		extension,
	) => {
		const symbol_stub = {
			name: symbolName || 'BTCUSD',
			description: symbolName,
			type: 'crypto',
			session: '24x7',
			// timezone: 'Eth/UTC+2',
			// ticker: symbolName,
			// exchange: split_data[0],
			minmov: 1,
			pointvalue: 8,
			pricescale: +`1${addZeroAfterPoint(hosting.decimal)}`,
			has_intraday: true,
			// intraday_multipliers: ['1', '60'],
			supported_resolution: supportedResolutions,
			volume_precision: 8,
			// data_status: 'streaming',
		};
		onSymbolResolvedCallback(symbol_stub);
	},
	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest } = periodParams;
		historyProvider
			.getBars(symbolInfo, resolution, from, to, firstDataRequest)
			.then((bars) => {
				if (bars.length) {
					onHistoryCallback(bars, { noData: false });
				} else {
					onHistoryCallback(bars, { noData: true });
				}
			})
			.catch((err) => {
				onErrorCallback(err);
			});
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscriberUID,
		onResetCacheNeededCallback,
	) => {
		stream.subscribeBars(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
		);
		updateBarBySockets(historyProvider.current);
	},
	unsubscribeBars: (subscriberUID) => {
		stream.unsubscribeBars(subscriberUID);
	},
};
