import { FC } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import L from 'i18n-react';
import LoginFormInner from './LoginForm';
import { ILoginForm, ILoginSubmitValue } from './types';

// ==========================================:
const LoginForm: FC<ILoginForm> = ({
	loginSubmit,
	showTotp,
	setShowTotp,
	totpIsError,
	setTotpIsError,
	captchaLoading,
	setCaptchaLoading,
	captcha,
	setCaptcha,
}) => {
	const initialValues = {
		email: '',
		password: '',
		totp: '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(String(L.translate('Errors.required_email')))
			.email(String(L.translate('Errors.email_format')))
			.max(60, String(L.translate('Errors.email_max_characters'))),
		password: yup.string().required(String(L.translate('Errors.password_required'))),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ILoginSubmitValue, { setSubmitting }) => {
				loginSubmit(values);
				setSubmitting(false);
			}}
			enableReinitialize
		>
			<Form>
				<LoginFormInner
					showTotp={showTotp}
					setShowTotp={setShowTotp}
					totpIsError={totpIsError}
					setTotpIsError={setTotpIsError}
					captchaLoading={captchaLoading}
					setCaptchaLoading={setCaptchaLoading}
					captcha={captcha}
					setCaptcha={setCaptcha}
				/>
			</Form>
		</Formik>
	);
};

export default LoginForm;
