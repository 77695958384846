export const getLocale = (lang: string) => {
	switch (lang) {
		case 'EN':
			return 'en_US';
		case 'GE':
			return 'ka_GE';
		case 'UA':
			return 'uk_UA';
		case 'RU':
			return 'ru_RU';
		default:
			return '';
	}
};
