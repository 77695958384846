/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC } from 'react';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
// import { notificationContainer } from 'services/utils/notificationContainer';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { IConvertTableItemProps } from './types';

const ConvertTableItem: FC<IConvertTableItemProps> = ({ data }) => {
	return (
		<div className="tr" key={data?.user_id}>
			<div className="td">
				<p className="td-hidden-name">Period</p>
				<p>
					{getLocaleDateFromTimestamp(data?.created_at)}{' '}
					{getLocaleTimeFromTimestamp(data?.created_at)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{convertExponentialToDecimal(Number(data?.fee))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">From</p>
				<p>{data?.from_asset?.toLocaleUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">To</p>
				<p>{data?.to_asset?.toLocaleUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount from</p>
				<p>{convertExponentialToDecimal(Number(data?.quantity))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount to</p>
				<p>{convertExponentialToDecimal(Number(data?.amount))}</p>
			</div>
		</div>
	);
};

export default ConvertTableItem;
