import { FC } from 'react';
import L from 'i18n-react';
import { Link } from 'react-router-dom';

export const CheckKYC: FC = () => {
	return (
		<div className="content-block">
			<div className="content-block__inner">
				<div className="content-form">
					<div className="alert-to-verify">
						<div className="d-flex alert-to-verify__icon">
							<svg
								width="188"
								height="168"
								viewBox="0 0 188 168"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle
									opacity="0.1"
									cx="94.0152"
									cy="93.5152"
									r="93.5152"
									fill="url(#paint0_linear_5123_104049)"
								/>
								<circle
									opacity="0.3"
									cx="94"
									cy="93.5"
									r="74.5"
									fill="url(#paint1_linear_5123_104049)"
								/>
								<circle cx="93.5" cy="93.5" r="55" fill="url(#paint2_linear_5123_104049)" />
								<path
									d="M76.0006 111.741H112.909C116.957 111.741 119.409 108.962 119.409 105.359C119.409 104.253 119.076 103.099 118.481 102.063L100.003 70.2261C98.7644 68.0832 96.6453 67 94.4545 67C92.2638 67 90.1208 68.0832 88.9065 70.2261L70.4287 102.063C69.7857 103.123 69.5 104.253 69.5 105.359C69.5 108.962 71.9526 111.741 76.0006 111.741ZM76.0244 108.044C74.3576 108.044 73.3575 106.772 73.3575 105.336C73.3575 104.889 73.4527 104.323 73.7147 103.829L92.1685 72.0157C92.6686 71.1444 93.5736 70.7677 94.4545 70.7677C95.3355 70.7677 96.2165 71.1444 96.7166 72.0157L115.171 103.852C115.433 104.347 115.552 104.889 115.552 105.336C115.552 106.772 114.504 108.044 112.861 108.044H76.0244Z"
									fill="white"
								/>
								<path
									d="M94.4544 95.8698C95.5976 95.8698 96.2642 95.2105 96.288 93.986L96.6215 81.5762C96.6452 80.3753 95.6927 79.4805 94.4306 79.4805C93.1448 79.4805 92.24 80.3517 92.2638 81.5527L92.5735 93.986C92.5972 95.1868 93.2639 95.8698 94.4544 95.8698ZM94.4544 103.523C95.8355 103.523 97.0262 102.44 97.0262 101.074C97.0262 99.6845 95.8595 98.625 94.4544 98.625C93.0495 98.625 91.8828 99.708 91.8828 101.074C91.8828 102.416 93.0735 103.523 94.4544 103.523Z"
									fill="white"
								/>
								<defs>
									<linearGradient
										id="paint0_linear_5123_104049"
										x1="94.0152"
										y1="0"
										x2="94.0152"
										y2="187.03"
										gradientUnits="userSpaceOnUse"
									>
										<stop stopColor="#F9A000" />
										<stop offset="1" stopColor="#F9A000" stopOpacity="0" />
									</linearGradient>
									<linearGradient
										id="paint1_linear_5123_104049"
										x1="94"
										y1="19"
										x2="94"
										y2="168"
										gradientUnits="userSpaceOnUse"
									>
										<stop stopColor="#F9A000" />
										<stop offset="1" stopColor="#F9A000" stopOpacity="0" />
									</linearGradient>
									<linearGradient
										id="paint2_linear_5123_104049"
										x1="93.5"
										y1="38.5"
										x2="93.5"
										y2="147.583"
										gradientUnits="userSpaceOnUse"
									>
										<stop stopColor="#F9A000" />
										<stop offset="1" stopColor="#F9A000" stopOpacity="0" />
									</linearGradient>
								</defs>
							</svg>
						</div>
						<p>
							In order to use `Partners & Benefits`, you need to go through the Verification
							process. After verification, you can return to this section
						</p>
					</div>
					<div className="form-submit form-submit--more-mt">
						<Link to="/general-settings" className="button button--full-width">
							Start verification
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
