import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import {
	getAssetPairsRequest,
	getAssetPairsFeesAndLimitsRequest,
} from 'redux/reducers/assetPairs/reducer';
import ConvertForm from '../ConvertForm';

const ConvertFormGuest = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAssetsRequest());
		dispatch(getAssetPairsRequest());
		dispatch(getAssetPairsFeesAndLimitsRequest());
	}, [dispatch]);

	return <ConvertForm />;
};

export default ConvertFormGuest;
