import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import L from 'i18n-react';
import queryString from 'query-string';
import { isAfter } from 'date-fns';
import { emailConfirmRequest } from 'redux/reducers/auth/reducer';
import Auth from 'layouts/Auth';
import { APP_NAME } from 'services/constants/env';
import EmailConfirmSuccess from 'components/EmailConfirmSuccess';
import EmailConfirmError from 'components/EmailConfirmError';
/* eslint-disable */
// ==========================================:
const EmailConfirm: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const queryPar = queryString.parse(location.search);
	const tokenTime = new Date(Number(queryPar.timestamp) * 1000);
	const currentTime = new Date();

	const tokenTimeExpired = isAfter(currentTime, tokenTime);
	const [tokenStatus, setTokenStatus] = useState('');

	useEffect(() => {
		if (!tokenTimeExpired) {
			dispatch(
				emailConfirmRequest({
					token: queryPar.token,
					email: queryPar.email,
					onError(value) {
						setTokenStatus(value);
					},
				}),
			);
		}
	}, [dispatch, queryPar.email, queryPar.token, tokenTimeExpired]);

	return (
		<Auth title={`${String(APP_NAME)}.com | ${String(L.translate('Auth.email_confirm'))}`}>
			{tokenTimeExpired ? <EmailConfirmError /> : <EmailConfirmSuccess error={tokenStatus} />}
		</Auth>
	);
};

export default EmailConfirm;
