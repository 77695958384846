import {
	IIndexPrices,
	IMarginCrossWallets,
	IWalletsIsolatedDataType,
} from 'redux/reducers/marginWallets/types';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

export const calculateTransform = (
	balance: string,
	marginBtc: IMarginCrossWallets | null,
	coinPrices: IIndexPrices,
	code: string,
) => {
	const ownBtc =
		Number(marginBtc?.data.total_in_btc) -
		(Number(marginBtc?.data.borrow_in_btc) + Number(marginBtc?.data.interest_in_btc)) * 2;

	let currentPrice;
	if (coinPrices) {
		currentPrice = Object?.entries(coinPrices)?.find((item) => {
			return item[0] === `${code?.toLocaleUpperCase()}_BTC`;
		});
	}

	const currentCoin = currentPrice ? ownBtc / Number(currentPrice?.[1]) : undefined;

	if (Number(balance) <= Number(currentCoin)) {
		return balance;
	}

	return Number(currentCoin) <= 0 ? 0 : currentCoin;
};

export const calculateTransformIsolate = (
	balance: string,
	isolatedBtc: IWalletsIsolatedDataType | null,
	coinPrices: IIndexPrices,
	code: string,
	pair: string,
) => {
	interface ItotalBtc {
		total_in_btc: string;
		borrow_in_btc: string;
		interest_in_btc: string;
	}
	const totalBtc: ItotalBtc | any = {
		total_in_btc: '',
		borrow_in_btc: '',
		interest_in_btc: '',
	};
	const arreyPair = isolatedBtc?.filter((item: any) => {
		const regex = new RegExp(`${String(item?.pair[0]?.code)}`, 'i');

		if (regex.test(pair)) {
			return item;
		}
		return false;
	});
	const sum = (acc: any, obj: any) => {
		Object?.keys(totalBtc).forEach((item) => {
			totalBtc[item] = Number(acc[item]) + Number(obj[item]);
		});
		return totalBtc;
	};
	let currentPrice;
	let currentCoin;
	if (arreyPair?.length !== 0) {
		const totalObj: any = arreyPair?.reduce((item1, item2) => sum(item1, item2));
		const ownBtc =
			Number(totalObj?.total_in_btc.toFixed(10)) -
			(Number(totalObj?.borrow_in_btc.toFixed(10)) +
				Number(totalObj?.interest_in_btc.toFixed(10))) *
				2;
		if (coinPrices) {
			currentPrice = Object?.entries(coinPrices)?.find((item) => {
				return item[0] === `${code?.toLocaleUpperCase()}_BTC`;
			});
		}

		currentCoin = currentPrice ? ownBtc / Number(currentPrice?.[1]) : undefined;
	}

	if (Number(balance) <= Number(currentCoin)) {
		return balance;
	}

	return Number(currentCoin) <= 0 ? 0 : currentCoin;
};
