/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';

import { IBorrowedItemProps } from './types';

const MarginItem: FC<IBorrowedItemProps> = ({ data }) => {
	const { created_at, price, quantity, pair } = data;

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<span className="td-name td-name--regular td-name--light">
					{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<span className="td-name td-name--regular td-name--fw500">{transformPairCode(pair)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Price</p>
				<span className="td-name td-name--regular">{Number(price).toFixed(10)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<span className="td-name td-name--regular">{Number(quantity).toFixed(10)}</span>
			</div>

			<div className="td">
				<p className="td-hidden-name">Total</p>
				<span className="td-name td-name--regular">
					{(Number(quantity) * Number(price)).toFixed(10)}
				</span>
			</div>
		</div>
	);
};

export default MarginItem;
