import { FC, ChangeEvent } from 'react';
import { TItemProps } from './type';

export const PaymentItem: FC<TItemProps> = ({ checked, card, setChecked }) => {
	const handleClick = (e: ChangeEvent<HTMLInputElement>, item: any) => {
		const { value } = e.currentTarget;
		setChecked(value && item);
	};
	return (
		<div className="add-card-item add-card-item--type2">
			<div className="radio radio--type2 radio--m0">
				<label className="radio__label">
					<input
						type="radio"
						className="hidden"
						onChange={(e) => handleClick(e, card)}
						checked={checked === card}
						name="card"
					/>
					<span className="radio__item" />
					<span className="radio__text">{card}</span>
				</label>
			</div>
		</div>
	);
};
