import L from 'i18n-react';
import { ChangeEvent, FC } from 'react';
import Popup from 'reactjs-popup';
import { cardHideNumber } from 'services/utils/cardHelper';
import { getDepositFee } from 'redux/reducers/cards/selectors';
import { useSelector } from 'react-redux';
import { IModalPropsCVC } from '../FormCard/types';

export const CvcVerify: FC<IModalPropsCVC> = ({
	openModal,
	closeModal,
	message,
	valueSubmit,
	handleSubmit,
	checkCard,
	disableButton,
	selectWallet,
	cvc,
	setCvc,
}) => {
	const depositFee = useSelector(getDepositFee);
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		if (value && value?.length > 3) return;
		setCvc(value);
	};
	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<button type="button" onClick={closeModal} className="popup-close">
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title">{L.translate('DepositFiat.CvcVerify.title')}</p>
							<div className="popup-text popup-text--center">
								<p>{L.translate('DepositFiat.CvcVerify.title')}</p>
							</div>
						</div>
						<div className="popup-body popup-body--type3 ">
							<div className="input">
								<p className="input__name">
									{L.translate('DepositFiat.CvcVerify.Fields.card_number')}
								</p>
								<div className="input-wrapper">
									<input
										className="input-item input-item--right-double-icon"
										type="text"
										placeholder={String(
											L.translate('DepositFiat.CvcVerify.Placeholders.card_number'),
										)}
										value={checkCard?.card_number}
										disabled
									/>
								</div>
							</div>
							<div className="input input--margin-none">
								<p className="input__name">
									{L.translate('DepositFiat.CvcVerify.Fields.security_code')}
								</p>
								<div className="input-wrapper">
									<input
										onChange={handleChange}
										value={cvc}
										className="input-item"
										type="text"
										placeholder={String(
											L.translate('DepositFiat.CvcVerify.Placeholders.security_code'),
										)}
									/>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--sb">
							<button
								disabled={cvc === '' || cvc.length < 3 || disableButton}
								onClick={handleSubmit}
								type="button"
								className="button button--full-width "
							>
								{L.translate('Global.confirm')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
