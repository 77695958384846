import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import VaspLogo from 'assets/img/content/vasp-logo.png';
import VaspLogoDark from 'assets/img/content/vasp-logo-dark.png';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { IFooterInfo } from './types';

// ==========================================:
const FooterInfo: FC<IFooterInfo> = ({ isNew }) => {
	const theme = useSelector(getColorTheme);

	return (
		<div className="footer-info">
			<p>Bitanica VASP</p>
			<p>
				{L.translate('Footer.registration_number')}: <span>0003-9404</span>
				<br />
				{L.translate('Footer.granted_by')}: <span>{L.translate('Footer.national_bank_of_ge')}</span>
			</p>
			<div className="d-flex footer-info__logo">
				<img src={isNew || theme === 'dark' ? VaspLogo : VaspLogoDark} alt="logo" />
			</div>
			<a
				href="https://nbg.gov.ge/en/page/virtual-asset-service-providers-vasps"
				target="blank"
				className="footer-link "
			>
				{L.translate('Footer.check_register')}
			</a>
		</div>
	);
};

export default FooterInfo;
