import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { appLanguage } from 'redux/reducers/app/selectors';
import ogImage from 'assets/images/content/binatica-og.png';
import { getLocale } from 'services/utils/language';
import Header from 'layouts-elements/Header';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import VerificationBanner from 'ui/VerificationBanner';
import { IConvert } from './types';

// ==========================================:
const Convert: FC<IConvert> = ({ title, children, showVerificBanner, description, showMeta }) => {
	const language = useSelector(appLanguage);

	return (
		<>
			{showMeta ? (
				<Helmet>
					<title>{title}</title>
					{description && <meta name="description" content={description} />}

					{/* Open Graph Meta Tags */}
					<meta property="og:title" content="Bitanica.com | Convert Bitcoin, Ethereum & More" />
					<meta property="og:type" content="website" />
					<meta property="og:url" content={`${window.location.host}/convert`} />
					<meta property="og:image" content={ogImage} />
					<meta
						property="og:description"
						content="Easily convert your cryptocurrency with our fast and secure crypto converter. Swap Bitcoin, Ethereum, and other altcoins in just a few clicks. Try it now."
					/>
					<meta property="og:site_name" content="Bitanica.com" />
					<meta property="og:locale" content={getLocale(language)} />

					{/* Twitter Card Meta Tags */}
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:title" content="Bitanica.com | Convert Bitcoin, Ethereum & More" />
					<meta
						name="twitter:description"
						content="Easily convert your cryptocurrency with our fast and secure crypto converter. Swap Bitcoin, Ethereum, and other altcoins in just a few clicks. Try it now."
					/>
					<meta name="twitter:image" content={ogImage} />
					<meta name="twitter:site" content="@YourTwitterHandle" />
				</Helmet>
			) : (
				<Helmet>
					<title>{title}</title>
					{description && <meta name="description" content={description} />}
				</Helmet>
			)}
			{/* Временный вариант, без этого почему то файл og:image не подгружается */}
			<img src={ogImage} alt="" style={{ display: 'none' }} />
			<div className="wrapper">
				<Header />
				<Content>
					{showVerificBanner && <VerificationBanner />}
					{children}
				</Content>
				<Footer />
			</div>
		</>
	);
};

export default Convert;
