/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useState, ChangeEvent } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
	getP2PDeletedRequest,
	changeAdvertActiveStatusRequest,
	checkAdvertActiveOrderRequest,
} from 'redux/reducers/p2p/reducer';
import { getCheckAdvertActiveLoader } from 'redux/reducers/p2p/selectors';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import moment from 'moment';
import { EditIcon } from 'assets/svg-icon';
import { P2PItemProps } from './types';
import ConfirmDeletePopup from '../../../../../ui/ConfirmDeletePopup';
import NoEditPopup from './NoEditPopup';

const P2PItem: FC<P2PItemProps> = ({ data, type }) => {
	const dispatch = useDispatch();
	const {
		current_fiat_price,
		current_fiat_code,
		code,
		amount_max,
		amount_min,
		amount_left,
		status,
		id,
		created_at,
	} = data;

	const history = useHistory();

	const checkLoader = useSelector(getCheckAdvertActiveLoader);

	const [openModal, setOpenModal] = useState(false);
	const [noEditOpen, setNoEditOpen] = useState(false);
	const closeModalHandler = () => setOpenModal(false);
	const openModalHandler = () => setOpenModal(true);

	const handleRemoveAdvertisement = () => {
		dispatch(getP2PDeletedRequest({ id, type }));
	};

	const handleActiveChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;

		dispatch(
			changeAdvertActiveStatusRequest({
				advertisement_id: id,
				active_status: checked ? 1 : 0,
			}),
		);
	};

	const handleEditClick = () => {
		dispatch(
			checkAdvertActiveOrderRequest({
				apiParams: {
					advertisement_id: id,
				},
				onSuccess: (hasActive) => {
					if (hasActive) {
						setNoEditOpen(true);
						return;
					}
					history.push({
						pathname: `/advertisement/${id}`,
						state: { data },
					});
				},
			}),
		);
	};

	return (
		<>
			<div className={`tr ${status?.status === 'non_active' ? 'tr--non-active' : ''}`}>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.price'))}</p>
					<div>
						<span className="td-name td-name--regular td-name--fw500">
							{current_fiat_price} {current_fiat_code.toUpperCase()}
						</span>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.created_at'))}</p>
					<div>
						<span className="td-name td-name--regular td-name--fw500">
							{moment.utc(created_at).local().format('DD.MM.YYYY HH:mm:ss')}
						</span>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.pair'))}</p>
					<div>
						<span className="td-name td-name--regular td-name--fw500">
							{current_fiat_code?.toLocaleUpperCase()}/{code?.toLocaleUpperCase()}
						</span>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.max'))}</p>
					<div>
						<span className="td-name td-name--regular td-name--fw500">{amount_max}</span>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.min'))}</p>
					<div>
						<span className="td-name td-name--regular td-name--fw500">{amount_min}</span>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.coin'))}</p>
					<div>
						<span className="td-name td-name--regular td-name--fw500">
							{code?.toLocaleUpperCase()}
						</span>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.total'))}</p>
					<span className="td-name td-name--regular td-name--fw500">{amount_left}</span>
				</div>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.status'))}</p>
					<div>
						<span className="td-name td-name--regular td-name--fw500">
							{status?.status &&
								(status?.status === 'non_active'
									? 'Active'
									: transformFirstLetterToCapitalize(status?.status))}
						</span>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.on_off'))}</p>
					<div className="switch switch--type2">
						<label className="switch__label">
							<input
								type="checkbox"
								className="hidden"
								checked={status?.status === 'active'}
								onChange={handleActiveChange}
							/>
							<span className="switch__toggler" />
						</label>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.edit'))}</p>
					<div className="td-actions">
						<button
							type="button"
							className="td-actions-btn"
							onClick={handleEditClick}
							disabled={checkLoader}
						>
							<EditIcon />
						</button>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">{String(L.translate('P2P.Advertisement.action'))}</p>
					<div className="link-group">
						<button
							type="button"
							onClick={openModalHandler}
							className="link link--delete link--regular"
						>
							{String(L.translate('P2P.Advertisement.delete_button'))}
						</button>
					</div>
				</div>
			</div>
			<ConfirmDeletePopup
				title={String(L.translate('P2P.Advertisement.delete_popup_title'))}
				bodyMessage={String(L.translate('P2P.Advertisement.delete_popup_description'))}
				handleDelete={handleRemoveAdvertisement}
				closeModal={closeModalHandler}
				openModal={openModal}
			/>
			<NoEditPopup isOpen={noEditOpen} onClose={() => setNoEditOpen(false)} />
		</>
	);
};

export default P2PItem;
