import { FC, useEffect } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as yup from 'yup';
import TextError from 'ui/Formik/TextError';
import { useDispatch, useSelector } from 'react-redux';
import { addCardRequest } from 'redux/reducers/cards/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import Input from 'ui/Formik/Input';
import { ICardProps } from './types';

export const FormCard: FC<ICardProps> = ({ onSubmit }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getUserSettingsData);

	const initialValues = {
		holder_name:
			userData?.data.first_name || userData?.data.last_name
				? `${userData?.data.first_name ? userData.data.first_name : ''} ${
						userData?.data.last_name ? userData.data.last_name : ''
				  }`
				: '',
		bank_name: '-',
		card_number: '',
		expiry_date: '',
		cvv: '',
	};

	const validationSchema = yup.object().shape({
		// bank_name: yup
		// 	.string()
		// 	.required(String(L.translate('Account.PaymentMethods.AddCard.bank_error')))
		// 	.min(3, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
		// 	.max(50, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		card_number: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.card_number_error')))
			.min(16, String(L.translate('Account.PaymentMethods.AddCard.card_number_error_2'))),
		expiry_date: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.expiration_error')))
			.matches(
				/^(0?[1-9]|1[0-2])[/][0-9]{2}$/,
				String(L.translate('Account.PaymentMethods.AddCard.expiration_error_2')),
			),
		cvv: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.cvv_error')))
			.matches(/^[0-9]{3}$/, String(L.translate('Account.PaymentMethods.AddCard.cvv_error_2'))),
	});

	const clearNumber = (value: number | string) => {
		return String(value).replace(/\D+/g, '');
	};

	const expiryDateFormat = (value: string) => {
		const clearValue = clearNumber(value);

		if (clearValue.length >= 3) {
			return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
		}
		return clearValue;
	};

	useEffect(() => {
		dispatch(userSettingsRequest());
	}, [dispatch]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				setSubmitting(false);
				onSubmit(values);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ values, errors, touched, isSubmitting }) => {
				const isError = (field: keyof typeof values) => touched[field] && errors[field];
				return (
					<Form>
						<div className="form-block form-block--padding">
							<Field name="holder_name" title="Holder Name" component={Input} />
							<div className="input">
								<p className="input__name">
									{String(L.translate('Account.PaymentMethods.card_number'))}
								</p>
								<div className={`input-wrapper ${isError('card_number') ? 'input--error' : ''}`}>
									<Field
										name="card_number"
										className="input-item input-item--right-double-icon"
										placeholder="Card number"
										value={values.card_number
											.replace(/[^0-9]/g, '')
											.replace(/(\d{4})/g, '$1 ')
											.trim()}
										maxLength={19}
									/>
									{/* <span className="input-icons">
										<img className="input-icons__visa" src={visaLogo} alt="" />
										<img className="input-icons__mastercard" src={mastercardLogo} alt="" />
									</span> */}
								</div>
								<ErrorMessage name="card_number" component={TextError} />
							</div>
							<div className="two-inputs">
								<div className="input input--margin-none">
									<p className="input__name">
										{String(L.translate('Account.PaymentMethods.expiry_date'))}
									</p>
									<div className={`input-wrapper ${isError('expiry_date') ? 'input--error' : ''}`}>
										<Field
											name="expiry_date"
											className="input-item"
											placeholder="MM/YY"
											value={expiryDateFormat(values.expiry_date)}
											maxLength={5}
										/>
									</div>
									<ErrorMessage name="expiry_date" component={TextError} />
								</div>

								<div className="input input--margin-none">
									<p className="input__name">
										{String(L.translate('Account.PaymentMethods.AddCard.security_code'))}
									</p>
									<div className={`input-wrapper ${isError('cvv') ? 'input--error' : ''}`}>
										<Field
											name="cvv"
											className="input-item"
											type="text"
											placeholder="cvv"
											value={values.cvv.replace(/[^0-9]/g, '').trim()}
											maxLength={3}
										/>
									</div>
									<ErrorMessage name="cvv" component={TextError} />
								</div>
							</div>
						</div>
						<div className="form-submit">
							<button type="submit" className="button button--full-width" disabled={isSubmitting}>
								{String(L.translate('DepositFiat.confirm'))}
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
