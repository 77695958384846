import { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roundNumberToFixed } from 'services/utils/numberHelpers';
import L from 'i18n-react';
import { useHistory } from 'react-router';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { riskSwitcher } from 'services/utils/riskSwitcher';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getAssetPairsList } from 'redux/reducers/assetPairs/selectors';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { getAccountStatus } from 'redux/reducers/auth/selectors';
import { getMarginWallets } from 'redux/reducers/marginWallets/selectors';
import useClickOutside from 'hooks/useClickOutside';
import TransferModal from 'components/TransferModal';
import { ISOLATED_OPTION, SPOT_OPTION } from 'components/TransferModal/utils';
import IsolatedModal from 'components/Trade/MarginModal/IsolatedModal';
import { ISpotAndFiatItemProps } from './types';

const IsolatedItem: FC<ISpotAndFiatItemProps> = ({ data, balancesIsHide }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [openBorrowModal, setOpenBorrowModal] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [activeType, setActiveType] = useState('borrow');

	const assetPairs = useSelector(getAssetPairsList);
	const accountStatus = useSelector(getAccountStatus);
	const marginWallets = useSelector(getMarginWallets);

	const pairCode = data.base?.pair?.[0]?.code;

	const handleBorrow = (event: string) => {
		setOpenBorrowModal((prevOpenModal) => !prevOpenModal);
		setActiveType(event);
	};

	const closeModal = (): void => {
		setOpenModal(false);
		setOpenBorrowModal(false);
	};

	const handleTrade = () => {
		if (!pairCode) return;

		if (accountStatus?.name === 'approved') {
			if (
				Number(marginWallets.marginIsolatedWallets?.data?.total_in_usd) <= 0 ||
				Number(data?.base?.balance) <= 0 ||
				Number(data?.quote?.balance) <= 0
			) {
				notificationContainer(
					String(L.translate('MarginTable.MarginItem.isolated_transfer_notification')),
					'info',
				);
			}
		} else {
			notificationContainer(String(L.translate('MarginTable.MarginItem.kyc_notification')), 'info');
		}

		// To avoid crash
		const isInAllPairs = assetPairs?.some(({ code }) => code === pairCode);
		if (!isInAllPairs) return;

		dispatch(setCurrentPair(pairCode));
		const pairCodeUpper = pairCode.toUpperCase() as string;
		history.push(`/MarginTrading/${pairCodeUpper}?type=isolated`);
	};

	const colorEquityValue = (value: number): string => {
		if (value > 0) return 'green';
		if (value < 0) return 'red';

		return '#0F0F0F';
	};

	const dropdownNode = useRef<HTMLDivElement | null>(null);
	const [dropOpened, setDropOpened] = useState(false);
	const [actionsOpened, setActionsOpened] = useState(false);

	const toggleDropHandler = () => {
		setDropOpened(!dropOpened);
	};
	const toggleActionsDropdown = () => {
		setActionsOpened(!actionsOpened);
	};
	const closeActionsDropdown = () => {
		setActionsOpened(false);
	};
	useClickOutside(dropdownNode, closeActionsDropdown);

	return (
		<div data-tr={data?.base?.pair[0]?.code} className="tr">
			<div data-label={L.translate('Margin.Isolated.Funds.Table.pair')} className="td">
				<div className="balance-currency">
					<div className="balance-currency__info">
						<span className="balance-currency__title">
							{data?.base?.pair[0]?.code?.replace('_', '/').toUpperCase()}
						</span>
					</div>
				</div>
			</div>
			<div
				data-label={L.translate('Margin.Isolated.Funds.Table.risk_ration')}
				className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}
			>
				<span className="td-hidden-name">
					{String(L.translate('Margin.Isolated.Funds.Table.risk_ration'))}
				</span>
				<div className="td-name td-name--regular">
					<p
						style={{
							color: riskSwitcher(data?.base?.margin_level).color,
						}}
					>
						{data?.base?.margin_level}
					</p>
					<p
						style={{
							color: riskSwitcher(data?.base?.margin_level).color,
						}}
					>
						{riskSwitcher(data?.base?.margin_level).label}
					</p>
				</div>
			</div>
			<div
				data-label={L.translate('Margin.Isolated.Funds.Table.effective_multiple')}
				className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}
			>
				<span className="td-hidden-name">
					{String(L.translate('Margin.Isolated.Funds.Table.effective_multiple'))}
				</span>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : `${String(data?.base?.pair[0]?.shoulder_isolate)}x`}
				</span>
			</div>
			<div
				data-label={L.translate('Margin.Isolated.Funds.Table.coin')}
				className="td td--left-auto td--dropdown td-name--center balance"
			>
				<div className="td-name td-name--regular">
					{balancesIsHide ? (
						'********'
					) : (
						<>
							<p>{data?.base?.asset[0]?.code.toUpperCase()}</p>
							<p>{data?.quote?.asset[0]?.code.toUpperCase()}</p>
						</>
					)}
				</div>
			</div>
			<div
				data-label={L.translate('Margin.Isolated.Funds.Table.total_bal')}
				className={`td td-name--center ${dropOpened ? 'td--dropdown-active' : ''}`}
			>
				<div className="td-name td-name--regular">
					{balancesIsHide ? (
						'********'
					) : (
						<>
							<p>{roundNumberToFixed(Number(data?.base?.total), 8)}</p>
							<p>{roundNumberToFixed(Number(data?.quote?.total), 8)}</p>
						</>
					)}
				</div>
				<span className="td-arrow icon-arrow2" onClick={toggleDropHandler} />
			</div>
			<div
				data-label={L.translate('Margin.Isolated.Funds.Table.available_balance')}
				className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}
			>
				<span className="td-hidden-name">
					{String(L.translate('Margin.Isolated.Funds.Table.available_balance'))}
				</span>
				<div className="td-name td-name--regular">
					{balancesIsHide ? (
						'********'
					) : (
						<>
							<p>{roundNumberToFixed(Number(data?.base?.balance), 8)}</p>
							<p>{roundNumberToFixed(Number(data?.quote?.balance), 8)}</p>
						</>
					)}
				</div>
			</div>
			<div
				data-label={L.translate('Margin.Isolated.Funds.Table.borrowed')}
				className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}
			>
				<span className="td-hidden-name">
					{String(L.translate('Margin.Isolated.Funds.Table.borrowed'))}
				</span>
				<div className="td-name td-name--regular">
					{balancesIsHide ? (
						'********'
					) : (
						<>
							<p>{roundNumberToFixed(Number(data?.base?.borrow), 8)}</p>
							<p>{roundNumberToFixed(Number(data?.quote?.borrow), 8)}</p>
						</>
					)}
				</div>
			</div>
			<div
				data-label={L.translate('Margin.Isolated.Funds.Table.interest')}
				className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}
			>
				<span className="td-hidden-name">
					{String(L.translate('Margin.Isolated.Funds.Table.interest'))}
				</span>
				<div className="td-name td-name--regular">
					{balancesIsHide ? (
						'********'
					) : (
						<>
							<p>{roundNumberToFixed(Number(data?.base?.interest_amount), 8)}</p>
							<p className="td-name td-name--regular td-name--subtext">
								{roundNumberToFixed(Number(data?.quote?.interest_amount), 8)}
							</p>
						</>
					)}
				</div>
			</div>
			<div
				data-label={L.translate('Margin.Isolated.Funds.Table.equity_value')}
				className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}
			>
				<span className="td-hidden-name">
					{String(L.translate('Margin.Isolated.Funds.Table.equity_value'))}
				</span>
				<div className="td-name td-name--regular">
					{balancesIsHide ? (
						'********'
					) : (
						<>
							<p
								className={`${colorEquityValue(
									Number(data?.base?.own_balance) + Number(data?.base?.frozen_balance),
								)}`}
							>
								{fixedCropNumber(
									Number(data?.base?.own_balance) + Number(data?.base?.frozen_balance),
									8,
								).toFixed(4)}{' '}
								/{' '}
								{fixedCropNumber(
									Number(data?.base?.own_balance_in_btc) + Number(data?.base?.frozen_in_btc),
									8,
								).toFixed(4)}
							</p>
							<p
								className={`td-name td-name--regular td-name--subtext ${colorEquityValue(
									Number(data?.quote?.own_balance) + Number(data?.quote?.frozen_balance),
								)}`}
							>
								{fixedCropNumber(
									Number(data?.quote?.own_balance) + Number(data?.quote?.frozen_balance),
									8,
								).toFixed(4)}{' '}
								/{' '}
								{fixedCropNumber(
									Number(data?.quote?.own_balance_in_btc) + Number(data?.quote?.frozen_in_btc),
									8,
								).toFixed(4)}
							</p>
						</>
					)}
				</div>
			</div>
			<div
				data-label={L.translate('Margin.Isolated.Funds.Table.action')}
				className="td td-name--center"
				ref={dropdownNode}
			>
				<div className={`select select--links	 ${actionsOpened ? 'active' : ''}`}>
					<button type="button" className="select__current" onClick={toggleActionsDropdown}>
						<span className="select__arrow icon-more" />
					</button>
					<div className="select__drop">
						<button
							className="link link--regular"
							onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
							type="button"
						>
							{L.translate('Margin.Transfer.title')}
						</button>
						{/* <button
							className="link link--regular"
							onClick={() => setOpenBorrowModal((prevOpenModal) => !prevOpenModal)}
							type="button"
						>
							{L.translate('Margin.Borrow.title')}/{L.translate('Margin.Repay.title')}
						</button> */}
						<button
							onClick={() => handleBorrow('borrow')}
							className="link link--regular"
							type="button"
						>
							{L.translate('Margin.Borrow.title')}
						</button>
						<button
							onClick={() => handleBorrow('repay')}
							className="link link--regular"
							type="button"
						>
							{L.translate('Margin.Repay.title')}
						</button>

						<button type="button" onClick={handleTrade} className="link link--regular">
							{L.translate('Margin.Cross.Funds.Table.trade_button')}
						</button>

						<TransferModal
							isOpen={openModal}
							onClose={closeModal}
							initFrom={ISOLATED_OPTION}
							initTo={SPOT_OPTION}
							initPairCode={data?.base?.pair[0]?.code}
							initCoinCode={data?.base?.asset[0]?.code}
							spotSocket
							crossSocket
							p2pSocket
						/>

						<IsolatedModal
							openModal={openBorrowModal}
							closeModal={closeModal}
							active={activeType}
							assetCode={data?.base?.asset[0]?.code}
							assetPair={data?.base?.pair[0]?.code}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IsolatedItem;
