import { FC, useState, useEffect, useRef } from 'react';
import L from 'i18n-react';
import { navList } from 'routes/routesList';
import { NavLink, useLocation } from 'react-router-dom';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import P2PModal from 'components/P2PMarketplace/P2PModal';
import bgImage from 'assets/images/general/sidebar-bg2.webp';
import useWindowResize from 'hooks/useWindowResize';

/* eslint-disable no-debugger */
// ==========================================:
const WalletSidebar: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const userP2Pname = useSelector(getUserSettingsData)?.p2p_username;
	const { width } = useWindowResize();
	const mobileBreakPoint = !!width && width <= 992;

	const isFiatSpotPathname = (pathname: string): boolean =>
		pathname === navList.fiatAndSpot.path ||
		pathname === navList.openOrders.path ||
		pathname === navList.tradeHistory.path ||
		pathname === navList.orderHistory.path;
	const isP2pPathname = (pathname: string): boolean =>
		pathname === navList.p2p.path ||
		pathname === navList.p2pAdvertisement.path ||
		pathname === navList.p2pOpenTrades.path ||
		pathname === navList.p2pClosedTrades.path;

	// const isMarginFiatSpotPathname = (pathname: string): boolean =>
	// 	pathname === `/${navList.marginWallets.path.split('/')[1]}/` ||
	// 	pathname === navList.marginOpenOrders.path ||
	// 	pathname === navList.marginTradeHistory.path ||
	// 	pathname === navList.marginOrderHistory.path ||
	// 	pathname === navList.borrowedHistory.path ||
	// 	pathname === navList.repayHistory.path ||
	// 	pathname === navList.interestedHistory.path ||
	// 	pathname === navList.callHistory.path ||
	// 	pathname === navList.liquidationHistory.path;

	// const tab = { spotHistory: true, marginHistory: false };
	// const [openTab, setOpenTab] = useState(tab);

	// useEffect(() => {
	// 	if (location.pathname) {
	// 		debugger;
	// 		setOpenTab({ ...openTab, spotHistory: true });
	// 	}
	// }, [location.pathname]);

	const [openTab, setOpenTab] = useState<boolean>(
		!!width && !mobileBreakPoint && isFiatSpotPathname(location.pathname),
	);
	// const [openMarginTab, setOpenMarginTab] = useState<boolean>(
	// 	!!width && !mobileBreakPoint && isMarginFiatSpotPathname(location.pathname),
	// );
	const [openP2pTab, setOpenP2pab] = useState<boolean>(
		!!width && !mobileBreakPoint && isP2pPathname(location.pathname),
	);
	const [showNickNamePopup, setShowNickNamePopup] = useState<boolean>(false);

	const toggleFiatAndSpotTab = () => {
		setOpenTab(!openTab);
	};
	// const toggleMarginTab = () => {
	// 	setOpenMarginTab(!openMarginTab);
	// };
	const toggleP2PTab = () => {
		if (userP2Pname === null) {
			setShowNickNamePopup(true);
		}
		setOpenP2pab(!openP2pTab);
	};

	const fiatRef = useRef(null);
	const marginRef = useRef(null);
	const p2pRef = useRef(null);
	const fiatTabRef = useRef(null);
	const marginTabRef = useRef(null);
	const p2pTabRef = useRef(null);

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			const composed = e.composedPath();
			if (
				(fiatRef.current && composed.includes(fiatRef.current)) ||
				(fiatTabRef.current && composed.includes(fiatTabRef.current)) ||
				(marginRef.current && composed.includes(marginRef.current)) ||
				(marginTabRef.current && composed.includes(marginTabRef.current)) ||
				(p2pRef.current && composed.includes(p2pRef.current)) ||
				(p2pTabRef.current && composed.includes(p2pTabRef.current))
			) {
				return;
			}

			if (mobileBreakPoint) {
				openTab && setOpenTab(false);
				// openMarginTab && setOpenMarginTab(false);
				openP2pTab && setOpenP2pab(false);
			}
		};
		window.addEventListener('click', handleClick, true);

		return () => window.removeEventListener('click', handleClick, true);
	}, [openP2pTab, openTab, mobileBreakPoint]);

	useEffect(() => {
		dispatch(userSettingsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (width) {
			setOpenTab(!mobileBreakPoint && isFiatSpotPathname(location.pathname));
			// setOpenMarginTab(!mobileBreakPoint && isMarginFiatSpotPathname(location.pathname));
			setOpenP2pab(!mobileBreakPoint && isP2pPathname(location.pathname));
		}
	}, [location.pathname, mobileBreakPoint, width]);

	return (
		<>
			{showNickNamePopup && (
				<P2PModal openModal={showNickNamePopup} closeModal={() => setShowNickNamePopup(false)} />
			)}
			<aside className="sidebar">
				<nav className="sidebar-nav">
					<ul className="sidebar-nav__list">
						<li className="sidebar-nav__item">
							<NavLink
								data-name="spotHistory"
								to={{
									pathname: '/fiat-and-spot',
									state: 'spotHistory',
								}}
								type="spot"
								className="sidebar-nav__link"
								activeClassName="sidebar-nav__link--active"
								exact
								onClick={toggleFiatAndSpotTab}
								innerRef={fiatRef}
							>
								<span className="sidebar-nav__icon">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9.5 13.7502C9.5 14.7202 10.25 15.5002 11.17 15.5002H13.05C13.85 15.5002 14.5 14.8202 14.5 13.9702C14.5 13.0602 14.1 12.7302 13.51 12.5202L10.5 11.4702C9.91 11.2602 9.51001 10.9402 9.51001 10.0202C9.51001 9.18023 10.16 8.49023 10.96 8.49023H12.84C13.76 8.49023 14.51 9.27023 14.51 10.2402"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M12 7.5V16.5"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<circle cx="12" cy="12" r="9.25" stroke="#777E91" strokeWidth="1.5" />
									</svg>
								</span>
								<span
									className={`sidebar-nav__text ${openTab ? 'sidebar-nav__text--active ' : ''}`}
								>
									{String(L.translate('Sidebar.fiat_spot'))}
								</span>
							</NavLink>
							<ul
								className={`sidebar-nav__sub-list ${
									openTab ? 'sidebar-nav__sub-list--active' : ''
								}`}
								ref={fiatTabRef}
							>
								<li className="sidebar-nav__sub-item ">
									<NavLink
										to={{ pathname: '/open-orders', state: 'spotHistory' }}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.open_orders'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{ pathname: '/order-history', state: 'spotHistory' }}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.order_history'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{ pathname: '/trade-history', state: 'spotHistory' }}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.trade_history'))}
										</span>
									</NavLink>
								</li>
							</ul>
						</li>
						{/* <li className="sidebar-nav__item">
							<NavLink
								to={{ pathname: '/margin/', state: 'marginHistory' }}
								className="sidebar-nav__link"
								activeClassName="sidebar-nav__link--active"
								onClick={toggleMarginTab}
								innerRef={marginRef}
							>
								<span className="sidebar-nav__icon">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M22 8.5C22 12.09 19.09 15 15.5 15C15.33 15 15.15 14.99 14.98 14.98C14.73 11.81 12.19 9.26999 9.01999 9.01999C9.00999 8.84999 9 8.67 9 8.5C9 4.91 11.91 2 15.5 2C19.09 2 22 4.91 22 8.5Z"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M15 15.5C15 19.09 12.09 22 8.5 22C4.91 22 2 19.09 2 15.5C2 11.91 4.91 9 8.5 9C8.67 9 8.84999 9.00999 9.01999 9.01999C12.19 9.26999 14.73 11.81 14.98 14.98C14.99 15.15 15 15.33 15 15.5Z"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M5.59 2H3C2.45 2 2 2.45 2 3V5.59C2 6.48 3.07999 6.92999 3.70999 6.29999L6.29999 3.70999C6.91999 3.07999 6.48 2 5.59 2Z"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M18.4097 22.0003H20.9997C21.5497 22.0003 21.9997 21.5503 21.9997 21.0003V18.4103C21.9997 17.5203 20.9197 17.0703 20.2897 17.7003L17.6997 20.2903C17.0797 20.9203 17.5197 22.0003 18.4097 22.0003Z"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span
									className={`sidebar-nav__text ${
										openMarginTab ? 'sidebar-nav__text--active ' : ''
									}`}
								>
									{String(L.translate('Sidebar.margin'))}
								</span>
							</NavLink>
							<ul
								className={`sidebar-nav__sub-list ${
									openMarginTab ? 'sidebar-nav__sub-list--active' : ''
								}`}
								ref={marginTabRef}
							>
								<li className="sidebar-nav__sub-item ">
									<NavLink
										to={{
											pathname: '/margin-open-orders',
											state: { linkName: 'marginHistory', margin: 'cross' },
										}}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.open_orders'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{
											pathname: '/margin-order-history',
											state: { linkName: 'marginHistory', margin: 'cross' },
										}}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.order_history'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{
											pathname: '/margin-trade-history',
											state: { linkName: 'marginHistory', margin: 'cross' },
										}}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.trade_history'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{
											pathname: '/margin-borrow',
											state: { linkName: 'marginHistory', margin: 'cross' },
										}}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.borrowing'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{
											pathname: '/margin-repayment',
											state: { linkName: 'marginHistory', margin: 'cross' },
										}}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.repayment'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{
											pathname: '/margin-interest',
											state: { linkName: 'marginHistory', margin: 'cross' },
										}}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.interest'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{
											pathname: '/margin-margincall',
											state: { linkName: 'marginHistory', margin: 'cross' },
										}}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.margin_calls'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{
											pathname: '/margin-liquidation-orders',
											state: { linkName: 'marginHistory', margin: 'cross' },
										}}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.liquidation_orders'))}
										</span>
									</NavLink>
								</li>
							</ul>
						</li> */}
						<li className="sidebar-nav__item ">
							<NavLink
								to="/p2p"
								className="sidebar-nav__link"
								activeClassName="sidebar-nav__link--active"
								exact
								onClick={toggleP2PTab}
								innerRef={p2pRef}
							>
								<span className="sidebar-nav__icon">
									{' '}
									<svg
										width="23"
										height="18"
										viewBox="0 0 23 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M17.1367 7.64673C18.5317 7.45073 19.6057 6.25473 19.6087 4.80573C19.6087 3.37773 18.5677 2.19373 17.2027 1.96973"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M18.9775 11C20.3285 11.202 21.2715 11.675 21.2715 12.65C21.2715 13.321 20.8275 13.757 20.1095 14.031"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M11.1358 11.4141C7.92176 11.4141 5.17676 11.9011 5.17676 13.8461C5.17676 15.7901 7.90476 16.2911 11.1358 16.2911C14.3498 16.2911 17.0938 15.8091 17.0938 13.8631C17.0938 11.9171 14.3668 11.4141 11.1358 11.4141Z"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M11.1354 8.638C13.2444 8.638 14.9544 6.929 14.9544 4.819C14.9544 2.71 13.2444 1 11.1354 1C9.02643 1 7.31643 2.71 7.31643 4.819C7.30843 6.921 9.00543 8.631 11.1074 8.638H11.1354Z"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M5.13411 7.64673C3.73811 7.45073 2.66511 6.25473 2.66211 4.80573C2.66211 3.37773 3.70311 2.19373 5.06811 1.96973"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M3.29302 11C1.94202 11.202 0.999023 11.675 0.999023 12.65C0.999023 13.321 1.44302 13.757 2.16102 14.031"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span
									className={`sidebar-nav__text ${openP2pTab ? 'sidebar-nav__text--active ' : ''}`}
								>
									{String(L.translate('Sidebar.p2p'))}
								</span>
							</NavLink>
							<ul
								className={`sidebar-nav__sub-list ${
									openP2pTab ? 'sidebar-nav__sub-list--active' : ''
								}`}
								ref={p2pTabRef}
							>
								<li className="sidebar-nav__sub-item ">
									<NavLink
										to={{ pathname: '/p2p-open-trades', state: 'p2p' }}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.open_trades'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item">
									<NavLink
										to={{ pathname: '/p2p-closed-trades', state: 'p2p' }}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.closed_trades'))}
										</span>
									</NavLink>
								</li>
								<li className="sidebar-nav__sub-item ">
									<NavLink
										to={{ pathname: '/p2p-advertisement', state: 'p2p' }}
										className="sidebar-nav__sub-link"
										activeClassName="sidebar-nav__sub-link--active"
										exact
									>
										<span className="sidebar-nav__sub-text">
											{String(L.translate('Sidebar.advertisement'))}
										</span>
									</NavLink>
								</li>
							</ul>
						</li>
						<li className="sidebar-nav__item ">
							<NavLink
								to="/transaction-history"
								className="sidebar-nav__link"
								activeClassName="sidebar-nav__link--active"
								exact
							>
								<span className="sidebar-nav__icon">
									<svg
										width="18"
										height="20"
										viewBox="0 0 18 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M11.7379 0.761748H5.08493C3.00493 0.753748 1.29993 2.41175 1.25093 4.49075V15.2037C1.20493 17.3167 2.87993 19.0677 4.99293 19.1147C5.02393 19.1147 5.05393 19.1157 5.08493 19.1147H13.0739C15.1679 19.0297 16.8179 17.2997 16.8029 15.2037V6.03775L11.7379 0.761748Z"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.4756 0.75V3.659C11.4756 5.079 12.6236 6.23 14.0436 6.234H16.7986"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.2887 13.3584H5.88867"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9.2437 9.60645H5.8877"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav__text">
									{String(L.translate('Sidebar.transaction_history'))}
								</span>
							</NavLink>
						</li>
					</ul>
				</nav>
				<div className="sidebar__figure">
					<img src={bgImage} alt="figure" loading="lazy" />
				</div>
			</aside>
		</>
	);
};

export default WalletSidebar;
