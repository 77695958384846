import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAssetPairsData, IAssetPairsFeesAndLimitsData, IAssetPairsStore } from './types';

// ==========================================:
const getAssetPairsState = (state: IStoreState): IAssetPairsStore => state.assetPairs;
export const getAssetPairs = createSelector(
	[getAssetPairsState],
	(assetPairs: IAssetPairsStore) => assetPairs,
);
// ====================================================:
export const getAssetPairsIsLoad = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): boolean => assetPairs.assetPairsLoader,
);

// ====================================================:
export const getAssetPairsList = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): IAssetPairsData => assetPairs.assetPairs,
);

export const getAssetPairListActiveIsolate = createSelector(
	[getAssetPairsState],
	({ assetPairs }) => assetPairs?.filter(({ active_isolate }) => active_isolate) || [],
);

export const getAssetPairsListFavorite = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): IAssetPairsData => assetPairs.assetPairsFavorite,
);

// ====================================================:
export const getAssetPairsFeesAndLimitsIsLoad = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): boolean => assetPairs.assetPairsFeesAndLimitsLoader,
);

// ====================================================:
export const getAssetPairsFeesAndLimitsList = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): IAssetPairsFeesAndLimitsData =>
		assetPairs.assetPairsFeesAndLimits,
);

export const getTopPairsWithChart = (state: IStoreState) => {
	return getAssetPairsState(state).topPairsWithChart;
};

export const getTopPairsWithChartLoader = (state: IStoreState) => {
	return getAssetPairsState(state).topPairsWithChartLoader;
};

export const getMoreTopPairsWithChartLoader = (state: IStoreState) => {
	return getAssetPairsState(state).topMorePairsWithChartLoader;
};
