import { FC } from 'react';
import L from 'i18n-react';
import tradeAppFigure1 from 'assets/images/content/trade-app-figure1.webp';
import tradeAppFigure2 from 'assets/images/content/trade-app-figure2.webp';
import tradeAppFigure3 from 'assets/images/content/trade-app-figure3.webp';
import appStoreImg from 'assets/images/content/app-store.svg';
import tradeAppImgMobile from 'assets/images/content/trade-app-img-mobile.webp';
import tradeAppImgTablet from 'assets/images/content/trade-app-img-tablet.webp';
import tradeAppImgDesctop from 'assets/images/content/trade-app-img-desctop.webp';
import tradeAppImgDefult from 'assets/images/content/trade-app-img.webp';

import tradeAppFigure1Dark from 'assets/images/content/trade-app-figure1__dark.png';
import tradeAppFigure2Dark from 'assets/images/content/trade-app-figure2__dark.png';
import tradeAppFigure3Dark from 'assets/images/content/trade-app-figure3__dark.png';
import tradeAppImgDefultDark from 'assets/images/content/trade-app-img__dark.png';

// ==========================================:
const BitanicaTextTrage: FC = () => {
	return (
		<div className="section section--app">
			<div className="trade-app">
				<div className="trade-app-figure trade-app-figure--type1">
					<img src={tradeAppFigure1} className="for-light" alt="figure" loading="lazy" />
					<img src={tradeAppFigure1Dark} className="for-dark" alt="figure" loading="lazy" />
				</div>
				<div className="trade-app-figure trade-app-figure--type2">
					<img src={tradeAppFigure2} className="for-light" alt="figure" loading="lazy" />
					<img src={tradeAppFigure2Dark} className="for-dark" alt="figure" loading="lazy" />
				</div>
				<div className="trade-app-figure trade-app-figure--type3">
					<img src={tradeAppFigure3} className="for-light" alt="figure" loading="lazy" />
					<img src={tradeAppFigure3Dark} className="for-dark" alt="figure" loading="lazy" />
				</div>
				<div className="container">
					<div className="info-content info-content--trade ">
						<h2 className="section-title section-title--big">
							{L.translate('TextTrade.title_1')}
							<br />
							{L.translate('TextTrade.title_2')}
						</h2>
						<p className="info-content__desc info-content__desc--white">
							{L.translate('TextTrade.title')}
						</p>
						<div className="store-links">
							<a href="/" className="store-link">
								<img src={appStoreImg} alt="app-store" />
							</a>
						</div>
					</div>
				</div>
				<div className="trade-app-img">
					<picture>
						<source
							media="(min-width: 200px) and (max-width: 575px)"
							srcSet={`${tradeAppImgMobile}`}
						/>
						<source media="(min-width: 576px) and (max-width: 820px)" srcSet={tradeAppImgTablet} />
						<source
							media="(min-width: 821px) and (max-width: 1199px)"
							srcSet={tradeAppImgDesctop}
						/>
						<img src={tradeAppImgDefult} className="for-light" alt="figure" />
						<img src={tradeAppImgDefultDark} className="for-dark" alt="figure" />
					</picture>
				</div>
			</div>
		</div>
	);
};

export default BitanicaTextTrage;
