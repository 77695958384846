import { FC, useEffect, useMemo, useState, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getWalletsAddress, getWalletsList } from 'redux/reducers/wallets/selectors';
import { IWalletItem, IWalletNetworkItem } from 'redux/reducers/wallets/types';
import { clearWalletAddress, getGenerateAddressRequest } from 'redux/reducers/wallets/reducer';
import { isStablecoin } from 'services/utils/wallets';
import DCDropdown from 'ui/DCDropdown';
import DCNDropdown from 'ui/DCNDropdown';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { getDepositFee, getCardList } from 'redux/reducers/cards/selectors';
import { getAuth } from 'redux/reducers/auth/selectors';
import { IAddCardPayload, IAddCardRequest } from 'redux/reducers/cards/types';
import {
	getCurrencyPaymentsListRequest,
	postPaymentsPayPal,
} from 'redux/reducers/payments/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { dataLayer } from 'services/gtm';
import { addDepositCardRequest, getCardsRequest } from 'redux/reducers/cards/reducer';
import { getBankDepositTransfer, getPayPalLoading } from 'redux/reducers/payments/selectors';
import {
	IPostPayPalDepositRequset,
	EPaymentsListType,
	IBankPlatform,
} from 'redux/reducers/payments/types';
// import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { DepositPopup } from './CreateDepositStable/Popups/DepositPopup';
import { ConfirmPopup } from './CreateDepositStable/Popups/ConfirmPopup';
import { CvcVerify } from './CreateDepositStable/Popups/CvcVerify';
import { AddCard } from './CreateDepositStable/AddCard/AddCard';
import { ChooseBankAccount } from './CreateDepositStable/ChooseBankAccount';
import SelecetCurrencyPayment from './CreateDepositStable/SelecetCurrencyPayment/SelecetCurrencyPayment';
import CreateDepositCrypto from './CreateDepositCrypto';
import { IStateDepositLocation, ICardCheck } from './types';

/* eslint-disable */ // ==========================================:
const CreateDeposit: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const cardList = useSelector(getCardList);
	const depositFee = useSelector(getDepositFee);
	const userData = useSelector(getUserSettingsData);
	const token = useSelector(getAuth);
	const cards = cardList || [];
	const { state } = useLocation<IStateDepositLocation>();
	const depositTransfer = useSelector(getBankDepositTransfer);
	const payPalLoading = useSelector(getPayPalLoading);
	const [showModal, setShowModal] = useState(false);
	const [tranferModal, setTranferModal] = useState(false);
	const [showModalVerify, setShowModalVerify] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [frame, setFrame] = useState<any>();
	const [cvc, setCvc] = useState('');
	const [message, setMessage] = useState('');
	const [chouse, setChouse] = useState(false);
	const [checkCard, setCheckCard] = useState<ICardCheck | null>({
		id: 0,
		bank_name: 'Card online',
		card_number: '',
		card_number_last4: '',
		holder_name: 'online',
	});
	const [valueSubmit, setValueSubmit] = useState<IAddCardPayload>({
		holder_name: '',
		bank_name: '',
		card_number: '',
		expiry_date: '',
		expiry_year: '',
		expiry_month: '',
		cvv: '',
		asset_id: '',
		amount: '',
	});

	const [selectWallet, setSelectWallet] = useState<IWalletItem | null>(
		state?.currentWallet || null,
	);
	const [walletNetworks, setWalletNetworks] = useState<Array<IWalletNetworkItem> | null>(
		state?.currentWallet?.networks || null,
	);
	const [selectWalletNetworks, setSelectWalletNetworks] = useState<IWalletNetworkItem | null>(null);

	const [selectedBank, setSelectedBank] = useState<IBankPlatform | null>(null);

	const initWalletList = useSelector(getWalletsList);

	const walletsList =
		initWalletList
			?.filter((item) => item.asset.type !== 'fiat')
			?.sort((a, b) => {
				if (isStablecoin(a) && !isStablecoin(b)) {
					return -1;
				}
				if (!isStablecoin(a) && isStablecoin(b)) {
					return 1;
				}
				return 0;
			}) || [];

	const assetCode = selectWallet?.asset.code;
	const fiatCode = assetCode?.slice(0, assetCode.length - 1);
	const fiatWallet = initWalletList?.find((w) => w.asset.code === fiatCode);
	const fiatAssetId = fiatWallet?.asset.id;

	const depositClose = () => {
		dispatch(clearWalletAddress());
		history.push('/fiat-and-spot');
	};

	useEffect(() => {
		dispatch(getCardsRequest());
	}, []);

	useEffect(() => {
		if (state) {
			const { code } = state.currentWallet.asset;
			if (!code.endsWith('b')) {
				return;
			}
			const fiatCode = code.slice(0, code.length - 1);
			dispatch(
				getCurrencyPaymentsListRequest({
					code: fiatCode,
					type: EPaymentsListType.DEPOSIT,
				}),
			);
		}
	}, [state]);

	useEffect(() => {
		if (!selectWallet) {
			return;
		}
		setSelectedPayment && setSelectedPayment('');
		setSelectWalletNetworks(null);
		setWalletNetworks(selectWallet?.networks);
		setSelectedBank(null);
		dispatch(clearWalletAddress());
		if (!isStablecoin(selectWallet)) {
			return;
		}
		const { code } = selectWallet.asset;
		const fiatCode = code.endsWith('b') ? code.slice(0, code.length - 1) : code;
		dispatch(getCurrencyPaymentsListRequest({ code: fiatCode, type: EPaymentsListType.DEPOSIT }));
	}, [selectWallet]);

	const [selectedPayment, setSelectedPayment] = useState('');
	const [amountField, setAmountField] = useState('');

	const [activeView, setActiveView] = useState('currency');

	const backFromChooseBank = () => {
		if (selectedBank) {
			setActiveView('currency');
			return;
		}
		setSelectedPayment('');
		setTimeout(() => {
			setActiveView('currency');
		}, 100);
	};

	useEffect(() => {
		if (selectedPayment === 'bank_transfer' && !selectedBank) {
			setActiveView('chooseBank');
		}
	}, [selectedPayment]);

	useEffect(() => {
		if (cardList?.length && !chouse) {
			setCheckCard(cardList[0]);
			setValueSubmit({
				...valueSubmit,
				holder_name: cardList[0].holder_name,
				bank_name: cardList[0].bank_name,
				card_number: String(cardList[0].card_number),
				id: cardList[0]?.id,
			});
		}
	}, [cardList, chouse]);

	const handleSubmit = () => {
		if (!amountField) return;

		const params: IAddCardRequest = {
			payload:
				checkCard?.holder_name === 'online'
					? {
							card_number: valueSubmit?.card_number,
							expiry_month: valueSubmit?.expiry_month,
							expiry_year: valueSubmit?.expiry_year,
							amount: Number(amountField),
							cvc: valueSubmit?.cvv,
							asset_id: fiatAssetId,
					  }
					: {
							asset_id: fiatAssetId,
							amount: Number(amountField),
							card_id: String(checkCard?.id),
							cvc,
					  },
			onSuccess: (url: string) => {
				setShowModal(false);
				// setActiveView('iframe');
				setDisableButton(false);
				window.open(url);
				// setFrame(url);
				history.push(`/transaction-history`);
			},
			onError: () => {
				setDisableButton(false);
				history.push(`/deposit-failed`);
			},
			type: checkCard?.holder_name === 'online' ? 'online' : 'card',
		};
		if (valueSubmit) {
			setDisableButton(true);
			dispatch(addDepositCardRequest(params));
		}
	};

	const clickFetchPayPal = () => {
		if (selectWallet === null) {
			notificationContainer(
				String(L.translate('DepositFiat.select_currency_notification')),
				'error',
			);
		} else {
			const payload: IPostPayPalDepositRequset = {
				params: { amount: amountField, asset_id: String(fiatAssetId), key: 'value' },
				onSuccess: (response) => {
					notificationContainer(
						String(L.translate('DepositFiat.redirect_notification')),
						'success',
					);
					window.open(response);
					depositClose();
				},
				onError: () => {},
			};

			dispatch(postPaymentsPayPal(payload));
		}
	};

	const handleConifirmSubmit = (value: string) => {
		if (selectedPayment === 'paypal') {
			clickFetchPayPal();
			return;
		}
		if (value === 'online') {
			handleSubmit();
		} else {
			setShowModalVerify(true);
		}
	};
	const handleChangeAmount = (amount: string) => {
		setAmountField(amount);
		setValueSubmit({ ...valueSubmit, amount });
	};

	const handleWalletSelect = (value: IWalletItem): void => {
		dataLayer.push({
			event: 'select_coin'
		});
		dispatch(clearWalletAddress());
		setSelectWallet(value);
		setSelectWalletNetworks(null);
		setWalletNetworks(value?.networks);
	};

	const handleNetworkSelect = (value: IWalletNetworkItem | null) => {
		dataLayer.push({
			event: 'select_network',
		});
		setSelectWalletNetworks(value);
	};

	const activeSelect: Record<string, ReactNode> = {
		currency: (
			<div className="create-offer">
				<div className="create-offer__header">
					<button type="button" onClick={depositClose} className="back-step">
						<div className="back-step__img">
							<span className="back-step__arrow icon-arrow" />
						</div>
						<span className="back-step__text">{String(L.translate('DepositCrypto.deposit'))}</span>
					</button>
				</div>
				<div className="create-offer__main create-offer__main--border-none create-offer__main--padding-big">
					<div className="create-offer__form">
						<div className="form-block">
							<div className="enter-value">
								<span className="block-label block-label--grey">
									{String(L.translate('DepositCrypto.select_coin'))}
								</span>
								<span className="enter-value__label enter-value__label--padding">
									{String(L.translate('DepositCrypto.deposit_coin'))}
								</span>
								<div className="enter-value__field">
									<DCDropdown
										value={selectWallet}
										onChange={handleWalletSelect}
										options={walletsList}
									/>
								</div>
							</div>
						</div>
						{selectWallet &&
							(isStablecoin(selectWallet) ? (
								<SelecetCurrencyPayment
									setActiveView={setActiveView}
									selectWallet={selectWallet}
									setSelectWallet={setSelectWallet}
									selectedPayment={selectedPayment}
									setSelectedPayment={setSelectedPayment}
									setAmountField={handleChangeAmount}
									setShowModal={setShowModal}
									checkCard={checkCard}
									setTranferModal={setTranferModal}
									selectWalletNetworks={selectWalletNetworks}
									setSelectWalletNetworks={handleNetworkSelect}
									walletNetworks={walletNetworks?.filter((wn) => !!wn.depositable) || []}
									setWalletNetworks={setWalletNetworks}
									selectedBank={selectedBank}
								/>
							) : (
								<CreateDepositCrypto
									selectWallet={selectWallet}
									walletNetworks={walletNetworks}
									selectWalletNetworks={selectWalletNetworks}
									setSelectWalletNetworks={handleNetworkSelect}
								/>
							))}
					</div>
				</div>
			</div>
		),
		addCard: (
			<AddCard
				setActiveView={setActiveView}
				selectWallet={selectWallet}
				amountField={amountField}
				setShowModal={setShowModal}
				setValueSubmit={setValueSubmit}
				checkCard={checkCard}
				setCheckCard={setCheckCard}
				title={String(L.translate('DepositCrypto.deposit'))}
				setChouse={setChouse}
				cardList={[
					...cards,
					{
						id: 0,
						bank_name: 'Card online',
						card_number: '',
						card_number_last4: '',
						holder_name: 'online',
					},
				]}
			/>
		),
		chooseBank: (
			<>
				{(fiatAssetId || fiatAssetId === 0) && (
					<ChooseBankAccount
						setActiveView={setActiveView}
						currentBank={selectedBank}
						onChange={setSelectedBank}
						goBack={backFromChooseBank}
						assetId={fiatAssetId}
					/>
				)}
			</>
		),
	};

	return (
		<div className="content-block content-block--flex content-block--padding-none content-block--border">
			{activeSelect[activeView]}
			<ConfirmPopup
				openModal={showModal}
				closeModal={() => setShowModal(false)}
				message={String(checkCard?.holder_name)}
				valueSubmit={valueSubmit}
				checkCard={checkCard}
				title={String(L.translate('DepositFiat.deposit_confirmation'))}
				typePayment={selectedPayment}
				selectWallet={selectWallet}
				handleSubmit={() => handleConifirmSubmit(String(checkCard?.holder_name))}
				submitDisabled={payPalLoading}
			/>
			<CvcVerify
				openModal={showModalVerify}
				closeModal={() => setShowModalVerify(false)}
				message={String(checkCard?.holder_name)}
				valueSubmit={valueSubmit}
				checkCard={checkCard}
				selectWallet={selectWallet}
				handleSubmit={handleSubmit}
				disableButton={disableButton}
				cvc={cvc}
				setCvc={setCvc}
			/>
			{selectedBank && (
				<DepositPopup
					openModal={tranferModal}
					closeModal={() => setTranferModal(false)}
					selectWallet={selectWallet}
					amount={amountField}
					fee={depositFee}
					title={String(L.translate('DepositFiat.deposit_by_bank_transfer'))}
					showVerify={state?.verifyBankTransfer}
					bankAccount={selectedBank}
				/>
			)}
		</div>
	);
};

export default CreateDeposit;
