import { ILoginWithGoogleData } from 'redux/reducers/auth/types';

export const endpoint = {
	app: {
		COUNTRIES: '/countries',
		TRANSLATES: '/content',
	},
	auth: {
		LOGIN: '/login',
		REFRESH_TOKEN: 'token/refresh',
		REGISTRATION: '/register',
		LOGOUT: '/logout',
		RESET_PASSWORD: '/password_reset',
		NEW_PASSWORD: '/user/password',
		RESET_EMAIL_CONFIRM: '/reset-token',
		EMAIL_CONFIRM: '/email_confirmation',
		IP_CONFIRM: '/whitelist_ip',
		GENERATE_SMS: '/generate_sms',
		RESET_TWOFA: '/2fa/reset',
		AUTH_WITH_GOOGLE_REQUEST: '/gredirect',
		AUTH_WITH_GOOGLE_RESPONSE: (data: ILoginWithGoogleData) =>
			`/gcallback?state=${String(data.token)}&code=${String(data.code)}${
				data.referral ? `&referral=${String(data.referral)}` : ''
			}`,
		AUTH_WITH_FACEBOOK_RESPONSE: (data: ILoginWithGoogleData) =>
			`/fcallback?state=${String(data.token)}&code=${String(data.code)}${
				data.referral ? `&referral=${String(data.referral)}` : ''
			}`,
		AUTH_WITH_APPLE_RESPONSE: (data: ILoginWithGoogleData) =>
			`/acallback?state=${String(data.token)}&code=${String(data.code)}`,
		RESEND_REGISTER: '/resend-register',
	},
	settings: {
		USER_SETTINGS: '/user_settings',
		CHANGE_PASSWORD: '/user_settings/change_password',
		ENABLE_2FA: '/settings/2fa/enable',
		DISABLE_2FA: '/settings/2fa/disable',
		GENERATE_2FA_KEY: '/settings/2fa/generate_secret_key',
		CHECK_ENABLED_2FA: '/settings/2fa/check_enabled',
		SET_PHONE: '/user_settings/set_phone',
		CHECK_NOTIFICATION: '/user_settings/notification_settings',
		UPDATE_NOTIFICATION: '/user_settings/notification_settings',
		CHANGE_PHONE_2FA_MODE: (mode: string) => `/settings/2fa/phone_check_${mode}`,
		CHANGE_EMAIL_2FA_MODE: (mode: string) => `/settings/2fa/email_check_${mode}`,
	},
	assetPairs: {
		ASSET_PAIRS: '/assets_pairs',
		ASSET_PAIRS_FAVORITES: '/assets_pairs/favorite',
		ASSET_PAIRS_FEES_AND_LIMITS: '/pairs/fees',
		GET_TOP_PAIRS_WITH_CHART: '/top_pairs_chart',
		GET_MORE_TOP_PAIRS_WITH_CHART: '/more_top_pairs_chart',
	},
	assets: {
		ASSETS: '/assets',
	},
	spotTrade: {
		ORDER_BOOK: (pair: string) => `/order_book/${pair}`,
		RECENT_TRADES: (pair: string) => `/trades/${pair}`,
		USER_TRADES: '/trades_history',
		USER_OPEN_ORDERS: '/my_orders/opened',
		REMOVE_OPEN_ORDER: (id: number) => `/orders/${id}/cancel`,
		REMOVE_ALL_OPEN_ORDERS: '/order/cancel/all',
		USER_CLOSED_ORDERS: '/my_orders/closed',
	},
	marginWallets: {
		MARGIN_CROSS_WALLETS: '/margin/cross/wallets',
		MARGIN_ISOLATED_WALLETS: '/margin/isolated/wallets',
		INDEX_PRICES: '/margin/index_prices',
		TRANSFER_TO_MARGIN: `margin/cross/transfer/to_margin`,
		TRANSFER_TO_SPOT: 'margin/cross/transfer/to_spot',
		TRANSFER_ISOLATED_TO_MARGIN: 'margin/isolated/transfer/to_margin',
		TRANSFER_ISOLATED_TO_SPOT: 'margin/isolated/transfer/to_spot',
		BORROW_MAXIMUM_AMOUT: (assetId: number) => `margin/cross/borrow/available?asset_id=${assetId}`,
		BORROW_TAKE: (assetId: number, amount: string) =>
			`/margin/cross/borrow/take?asset_id=${assetId}&amount=${amount}`,
		BORROW_PAY_TAKE: (assetId: number, amount: string) =>
			`/margin/cross/borrow/pay?asset_id=${assetId}&amount=${amount}`,
		BORROW_AVAILABLE: `margin/isolated/borrow/available`,
		BORROW_ISOLATED_TAKE: '/margin/isolated/borrow/take',
		BORROW_ISOLATED_PAY_TAKE: '/margin/isolated/borrow/pay',
		GET_HISTORY_TRANSFER: 'wallets/transfers',
		BORROW_HISTORY: `/margin/cross/history/take_borrow`,
		BORROW_ISOLATED_HISTORY: '/margin/isolated/history/take_borrow',
		REPAY_HISTORY: '/margin/cross/history/pay_borrow',
		REPAY_HISTORY_ISOLATED: '/margin/isolated/history/pay_borrow',
		INTEREST_HISTORY: '/margin/cross/history/interest',
		INTERSET_HISTORY_ISOLATED: '/margin/isolated/history/interest',
		CALL_HISTORY: '/margin/cross/history/margin_call',
		CALL_HISTORY_ISOLATED: '/margin/isolated/history/margin_call',
		LIQUIDATION_HISTORY: '/margin/cross/history/liquidation',
		LIQUIDATION_HISTORY_ISOLATED: '/margin/isolated/history/liquidation',
		CREATE_EXTERNAL_TRANSFER: '/wallets/transfer',
	},
	p2p: {
		GET_P2p_WALLETS: 'p2p/balance',
		GET_P2p_ADVERTISEMENT: 'p2p/trading/orders',
		GET_P2p_OPEN_TRADES: 'p2p/trading/open_trades',
		POST_CREATE_USER_P2P: 'p2p/create_user',
		GET_P2p_CLOSED_TRADES: 'p2p/trading/closed_trades',
		DELETED_ORDER: (id: number) => `p2p/trading/order/${id}`,
		GET_ASSETS: 'p2p/assets',
		GET_ORDER_QUICK: 'p2p/orders/quick',
		GET_CURRENCIES: 'p2p/currencies',
		GET_PAYMENT_METHODS: `p2p/payment_methods`,
		GET_ALL_ADVERTISEMENT: 'p2p/all_orders',
		GET_PROFILE: (name: string) => `p2p/user/profile?username=${name}`,
		GET_PROPOSAL: `p2p/trading/proposal/price`,
		POST_P2p_ADVERTISEMENT: 'p2p/trading/order',
		GET_P2p_USER_ADVERTISEMENT: (id: number) => `p2p/trading/order/${id}`,
		POST_P2p_TRADE: `p2p/trading/trade`,
		GET_P2p_TRADE: (trade_id: string) => `p2p/trading/trade/${trade_id}`,
		GET_P2p_CHATS: (trade_id: string) => `p2p/trading/chats/${trade_id}`,
		PUT_P2p_CANSELED: (trade_id: string) => `p2p/trading/cancel_trade/${trade_id}`,
		PUT_P2p_RECEIVED: (trade_id: string) => `p2p/trading/have_received_payment/${trade_id}`,
		PUT_P2p_PAID_SELLER: (trade_id: string) => `p2p/trading/have_paid_seller/${trade_id}`,
		TRANSFER_SPOT_TO_P2P: `p2p/transfer/to_p2p`,
		TRANSFER_P2P_TO_SPOT: `p2p/transfer/to_spot`,
		TRANSFER_CROSS_TO_P2P: `p2p/transfer/cross_to_p2p`,
		TRANSFER_P2P_TO_CROSS: `p2p/transfer/p2p_to_cross`,
		TRANSFER_ISOLATED_TO_P2P: `p2p/transfer/isolated_to_p2p`,
		TRANSFER_P2P_TO_ISOLATED: `p2p/transfer/p2p_to_isolated`,
		PUT_P2p_OPEN_DISPUTE: (trade_id: string) => `p2p/trading/open_dispute/${trade_id}`,
		GET_USER_NOTIFICATIONS: (search: string) => `p2p/notifications${search}`,
		GET_NOTIFICATIONS_BELL: `p2p/notifications/bell`,
		POST_NOTIFICATIONS: `p2p/notifications`,
		GET_ACTIVE_PAYMENT: '/p2p/active_payment_methods',
		ADD_PAYMENT_METHOD: '/p2p/user/payment_method',
		GET_PAYMENT_METHOD: '/p2p/user/payment_methods',
		DELETE_PAYMENT_METHOD: (id: string) => `/p2p/user/payment_method/${id}`,
		CHANGE_ACTIVE_STATUS_ORDER: '/p2p/trading/change_active_status_order',
		CHECK_ADVERTISMENT_ACTIVE_ORDERS: '/p2p/trading/check_order?advertisement_id',
		GET_ACTIVE_TRADE: '/p2p/trading/active_trade',
		CHECK_ORDER_AMOUNT: '/p2p/trading/check_order_amount',
	},
	p2pChat: {
		GET_P2p_CHATS: (trade_id: string) => `p2p/trading/chats/${trade_id}`,
		POST_MESSAGE_USER: `p2p/trading/message/user`,
		POST_MESSAGE_ADMIN: `p2p/trading/message/admin`,
		GET_EVIDENCE: (trade_id: string) => `p2p/trading/dispute/evidence/${trade_id}`,
		UPLOAD_EVIDENCE: `p2p/trading/dispute/evidence`,
		DELETE_EVIDENCE: (id: number) => `p2p/trading/dispute/evidence/${id}`,
	},
	tradingChart: {
		CHART_HISTORY: '/history',
	},
	wallets: {
		WALLETS: '/wallets',
		GENERATE_ADDRESS: (id: number) => `/wallets/${id}/generate_address`,
		WITHDRAWALS_HISTORY: '/wallets/withdrawals',
		DEPOSITS_HISTORY: '/wallets/deposits',
		CRYPTO_HISTORY: '/wallets/transactions',
	},
	orders: {
		CALCULATE_LIMIT_ORDER: '/calculate_limit_order',
		CALCULATE_MARKET_ORDER: '/calculate_market_order',
		CREATE_ORDER: '/orders',
		CREATE_STOP_LIMIT_ORDER: '/stop_limit_orders',
	},
	withdrawCrypto: {
		CHECK_WITHDRAWAL_ADDRESS: '/wallets/check_address',
		SEND_WITHDRAWAL_EMAIL_CODE: '/settings/wallets/send_email_code',
		CONFIRM_CRYPTO_WITHDRAWAL: '/wallets/withdrawal_request',
		CALCULATE_CRYPTO_WITHDRAWAL: '/wallets/calculate_crypto_withdrawal_request',
		CHECK_ADDRESS_IS_INTERNAL: '/wallets/address_type',
	},
	withdrawFiat: {
		WITHDRAWAL_PAYPAL: '/payment/paypal/withdraw/new',
		CALCULATE_FIAT_WITHDRAWAL: '/payment/calculate_fiat_withdrawal_request',
	},
	depositFiat: {
		DEPOSIT_TRANSFER_FIAT: '/payment/platform_requisites',
	},
	convert: {
		EXCHANGE_RATE: '/exchange_rate',
		MAKE_EXCHANGE: '/exchange',
		GET_HISTORY_EXCHANGE: '/exchanges',
		POST_FEE: '/convert_fee',
	},
	cards: {
		GET_CARDS_LIST: '/user/bank_card',
		ADD_CARD: '/user/bank_card/create',
		EDIT_CARD: '/user/bank_card/edit',
		DELETE_CARD: (cardId: string) => `/user/bank_card/${cardId}`,
		SAVED_CARD: '/payment/cartubank/deposit/card-online',
		DEPOSIT_FEE: '/payment/new_deposit_fee',
		DEPOSIT_CARD_SAVED: 'payment/cartubank/deposit/card-saved',
		WITHDRAWAL_FEE: 'payment/new_withdrawal_fee',
		CARD_STATUS: 'payment/cartubank/withdrawal/card-status',
		AUTHORIZE_CARD: 'payment/cartubank/withdrawal/authorize-card',
		WITHDRAWAL_CARD_SAVED: 'payment/cartubank/withdrawal/card-saved',
		GET_ACCOUNTS_LIST: '/user/bank_accounts',
		DELETE_ACCOUNT: (id: string) => `/user/bank_accounts/${id}`,
		WITHDRAWAL_CARD_VERIFY: 'payment/cartubank/withdrawal/authorize-card',
		VERIFY_VARD: 'user/bank_card/verify',
	},
	kyc: {
		START_KYC: '/sumsub/kyc-start',
		KYC_USER_DATA: '/kyc',
	},
	referral: {
		STATISTICS: '/referrals',
		BALANCE: '/referrals/balances',
		TRANSFER: 'referrals/balances/transfer',
	},
	payments: {
		GET_CURRENCY_PAYMENTS_LIST: '/payment/available',
		PAYMENTS_PAYPAL: (amount: string, assetId: string) =>
			`/payment/paypal/deposit/new?amount=${amount}&asset_id=${assetId}`,
		PAYMENTS_CARTUBANK: '/payment/cartubank/deposit/new',
		PAYMENTS_HISTORY: `/payment/history`,
		POST_ADD_BANK_ACCOUNT: `/user/bank_accounts/create`,
		UPDATE_ADD_BANK_ACCOUNT: `/user/bank_accounts/edit`,
		CREATE_BANK_ACCOUNT_WITHDRAWAL: 'payment/bank_transfer/withdrawal',
		FIAT_LIMITATIONS: '/fiat_limitations',
	},
	ourPrices: {
		FIAT_FEES: '/fiat_fees',
		CRYPTO_FEES: '/crypto_fees',
		P2P_FEES: '/p2p/limitations',
		FIAT_LIMITS: '/total_fiat_limitations',
		CRYPTO_LIMITS: '/total_crypto_limitations',
		CONVERT_FEES: '/convert_fees',
		SPOT_FEES: '/spot_fees',
	},
};
