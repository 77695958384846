export const numbersAfterPoint = (coin: string): number => {
	const lowerCoin = coin?.toLowerCase();
	switch (lowerCoin) {
		case 'usdt':
		case 'usdc':
			return 2;
		default:
			return 8;
	}
};

export const randomInt = (range: number, min = 0) => {
	return Math.floor(Math.random() * range) + min;
};

export const randomSeveralFromList = (list: number[], count = 1) => {
	const restList = [...list];
	let restCount = count;
	const resultList = [];

	while (restCount && restList.length) {
		const rndIndex = randomInt(restList.length);
		resultList.push(restList[rndIndex]);

		restList.splice(rndIndex, 1);
		restCount -= 1;
	}

	return resultList;
};

export const randomSeveralFromRange = (range: number, count: number, min = 0) => {
	const numberList = [];

	for (let i = min; i < range + min; i += 1) {
		numberList.push(i);
	}

	return randomSeveralFromList(numberList, count);
};

export const roundNumber = (number: number, decimals = 2) => {
	return Math.round(number * 10 ** decimals) / 10 ** decimals;
};

export const roundNumberToFixed = (number: number, decimals = 2) => {
	return roundNumber(number, decimals).toFixed(decimals);
};

// export const numberValidationNumbersAfterDot = (text: string, number: number) => {
// 	if (text === '') return true;
// 	const regExp = new RegExp(`^(?:[0-9]{1,8}[\.][0-9]{1,${number}}|[0-9]{1,8}[\.]|[0-9]{1,8})$`);
// 	if (!regExp.test(text)) return false;
// 	return true;
// };
// const addZeroAfterPoint = (n: number | string): string => {
// 	if (n > 1) {
// 		return `0${addZeroAfterPoint(Number(n) - 1)}`;
// 	}
// 	return '0';
// };

// export const toCrop = (amount: number) => {
// 	const max = amount || 0;
// 	return function (string: string) {
// 		const symb = +string < 0 ? '-' : '';
// 		const value: string = `${
// 			`${string}`.replace(',', '.').split('.')[1] ? `${string}` : `${string}.`
// 		}${addZeroAfterPoint(max)}`.replace(',', '.');
// 		if (max) {
// 			const reg = new RegExp(`\\d+(?:\\.\\d{0,${max)}})?`);
// 			if (reg && value) {
// 				return symb + value.match(reg)[0];
// 			}
// 		}
// 		const reg = new RegExp(`\\d+(?:\\.\\d{0)?`);
// 		return symb + value.match(reg)[0];
// 	};
// };

export function trimLastZeros(num: string): string {
	const dotIdx = num.indexOf('.');
	if (dotIdx === -1) {
		return num;
	}
	const intPart = num.slice(0, dotIdx);
	const decimalPart = num.slice(dotIdx + 1).replace(/0+$/, '');
	if (decimalPart.length === 0) {
		return intPart;
	}
	return `${intPart}.${decimalPart}`;
}

export function toFixedNoZeros(num: number, digits: number): string {
	return trimLastZeros(num.toFixed(digits));
}
