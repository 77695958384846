import { FC } from 'react';
import L from 'i18n-react';
import Account from 'layouts/Account';
import UserPayment from 'components/UserAccount/UserPayment';

// ==========================================:
const Payments: FC = () => {
	return (
		<Account title={String(L.translate('Account.payments'))}>
			<UserPayment />
		</Account>
	);
};

export default Payments;
