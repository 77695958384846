import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWithdrawPaypalParams } from 'redux/reducers/withdrawFiat/types';
import { IWithdrawFiatApi } from './types';

// ==========================================:
export const withdrawFiat: IWithdrawFiatApi = {
	withdrawPaypalRequest: (payload: IWithdrawPaypalParams) =>
		http.post(endpoint.withdrawFiat.WITHDRAWAL_PAYPAL, payload).then((response) => response.data),
	calculateFiatWithdrawal: (payload) =>
		http
			.post(endpoint.withdrawFiat.CALCULATE_FIAT_WITHDRAWAL, payload)
			.then((response) => response.data),
};
