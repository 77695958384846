import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { IHeaderDropdownList } from 'layouts-elements/Header/types';

export const MainNavItem: FC<IHeaderDropdownList> = ({ whenAuth, link, name }) => (
	<>
		{(whenAuth || whenAuth === undefined) && (
			<li className="main-nav__item">
				{link && (
					<NavLink className="main-nav__link" to={link}>
						{name}
					</NavLink>
				)}
			</li>
		)}
	</>
);
