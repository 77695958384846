import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import L from 'i18n-react';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import Account from 'layouts/Account';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import UserGeneralSettings from 'components/UserAccount/UserGeneralSettings';
import PaymentMethodsPage from 'components/UserAccount/PaymentMethods';

// ==========================================:
const PaymentMethods: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(userSettingsRequest());
		dispatch(getWalletsRequest());
	}, [dispatch]);

	return (
		<Account title={String(L.translate('Account.PaymentMethods.title'))}>
			<PaymentMethodsPage />
		</Account>
	);
};

export default PaymentMethods;
