import { FC } from 'react';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { useSelector } from 'react-redux';
import { IHeaderDropdownItem } from './types';

export const HeaderDropdownItem: FC<IHeaderDropdownItem> = ({ opt, handleChange, renderItem }) => {
	const theme = useSelector(getColorTheme);

	const isDark = theme === 'dark';
	const colorTm = isDark ? '#FFFFFF' : '#011E26';
	return (
		<>
			{(opt.whenAuth || opt.whenAuth === undefined) && (
				<div className="select__drop-item">
					{opt.renderItem ? (
						opt.renderItem()
					) : (
						<button type="button" className="select__drop-link" onClick={() => handleChange(opt)}>
							{opt.icon === 'svg' ? (
								<span className="select__drop-icon">
									<svg
										width="16"
										height="22"
										viewBox="0 0 16 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M14.5 18.5H10.5"
											stroke={colorTm}
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M12.5 20.5V16.5"
											stroke={colorTm}
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8.1601 9.87C8.0601 9.86 7.9401 9.86 7.8301 9.87C5.4501 9.79 3.5601 7.84 3.5601 5.44C3.5501 2.99 5.5401 1 7.9901 1C10.4401 1 12.4301 2.99 12.4301 5.44C12.4301 7.84 10.5301 9.79 8.1601 9.87Z"
											stroke={colorTm}
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M7.99004 20.8099C6.17004 20.8099 4.36004 20.3499 2.98004 19.4299C0.560039 17.8099 0.560039 15.1699 2.98004 13.5599C5.73004 11.7199 10.24 11.7199 12.99 13.5599"
											stroke={colorTm}
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
							) : (
								<span className={`select__drop-icon ${opt?.icon || ''}`} />
							)}
							<span className="select__drop-text">{opt?.name}</span>
						</button>
					)}
				</div>
			)}
		</>
	);
};
