import { PayloadAction } from '@reduxjs/toolkit';
import L from 'i18n-react';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { dataLayer } from 'services/gtm';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import {
	getExchangeRateRequest,
	getExchangeRateSuccess,
	makeExchangeRequest,
	makeExchangeSuccess,
	convertInitState,
	getConvertHistoryRequest,
	getConvertHistorySuccess,
	getConvertFeeRequest,
	getConvertFeeSuccess,
} from './reducer';
import {
	ICreateExchangeRequestPayload,
	IExchangeRateRequestPayload,
	IExchangeRateResponsePayload,
	IConvertHistoryResponsePayload,
	IConvertHistoryRequestPayload,
	IConvertFeePayload,
	IExchangeResponseData,
	IConvertFeeResponse,
} from './types';

// =============================================================:
function* exhangeRateRequestWorker(action: PayloadAction<IExchangeRateRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IExchangeRateResponsePayload = yield call(api.convert.getExchangeRate, payload);

		yield put(getExchangeRateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(convertInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* makeExhangeRequestWorker(action: PayloadAction<ICreateExchangeRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IExchangeResponseData = yield call(api.convert.makeExchange, payload);

		dataLayer.push({
			event: 'transaction',
			transaction_id: response.exchange.id,
			transaction_fee: response.exchange.fee,
			transaction_fee_name: 'convert',
			value: response.exchange.amount,
			currency: response.exchange.from_asset,
		});
		yield put(makeExchangeSuccess(response));
		yield put(getWalletsRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:

function* getConvertHistoryRequestWorker(action: PayloadAction<IConvertHistoryRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: IConvertHistoryResponsePayload = yield call(
			api.convert.getConvertHistory,
			payload,
		);

		if (response?.status === 'success') {
			notificationContainer(String(L.translate('Notification.transaction_history_sent')), 'info');
		} else {
			yield put(getConvertHistorySuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getConvertFeeWorker(action: PayloadAction<IConvertFeePayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: IConvertFeeResponse = yield call(api.convert.getFee, payload);

		yield put(getConvertFeeSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* convertSaga() {
	yield takeEvery(getExchangeRateRequest.type, exhangeRateRequestWorker);
	yield takeEvery(makeExchangeRequest.type, makeExhangeRequestWorker);
	yield takeEvery(getConvertHistoryRequest.type, getConvertHistoryRequestWorker);
	yield takeEvery(getConvertFeeRequest.type, getConvertFeeWorker);
}
