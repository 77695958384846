/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import L from 'i18n-react';
import { numberFormat } from 'services/utils/numberFormat';
import { navList } from 'routes/routesList';
import { P2PItemTypeProps } from './types';

const ItemType: FC<P2PItemTypeProps> = ({ data }) => {
	const { trade_id, username } = data;
	const Item: any = {
		cancel_trade: (
			<p className="notification-card__desc">
				{L.translate('P2P.t_0318')}{' '}
				<Link to={`${navList.p2pTradePay.path}trade_id=${trade_id}`}>
					{L.translate('P2P.t_0319')}{' '}
				</Link>
				{L.translate('P2P.t_0320')}{' '}
				<Link to={`${navList.p2pUserCenter.path}profilename=${username}`}>{username}</Link>{' '}
				{L.translate('P2P.t_0321')}
			</p>
		),
		dispute: (
			<p className="notification-card__desc">
				{L.translate('P2P.t_0322')}{' '}
				<Link to={`${navList.p2pTradePay.path}trade_id=${trade_id}`}>
					{L.translate('P2P.t_0319')}
				</Link>{' '}
				{L.translate('P2P.t_0320')}{' '}
				<Link to={`${navList.p2pUserCenter.path}profilename=${username}`}>{username}</Link>{' '}
				{L.translate('P2P.t_0323')}
			</p>
		),
		new_trade: (
			<p className="notification-card__desc">
				{L.translate('P2P.t_0324')}{' '}
				<Link to={`${navList.p2pTradePay.path}trade_id=${trade_id}`}>
					{L.translate('P2P.t_0319')}
				</Link>{' '}
				{L.translate('P2P.t_0320')}{' '}
				<Link to={`${navList.p2pUserCenter.path}profilename=${username}`}>{username}</Link>.
			</p>
		),
		trade_paid: (
			<p className="notification-card__desc">
				{L.translate('P2P.t_0318')}{' '}
				<Link to={`${navList.p2pTradePay.path}trade_id=${trade_id}`}>
					{L.translate('P2P.t_0319')}
				</Link>{' '}
				{L.translate('P2P.t_0320')}{' '}
				<Link to={`${navList.p2pUserCenter.path}profilename=${username}`}>{username}</Link>.
			</p>
		),
		received_payment: (
			<p className="notification-card__desc">
				{L.translate('P2P.t_0318')}{' '}
				<Link to={`${navList.p2pTradePay.path}trade_id=${trade_id}`}>
					{L.translate('P2P.t_0319')}
				</Link>{' '}
				{L.translate('P2P.t_0320')}{' '}
				<Link to={`${navList.p2pUserCenter.path}profilename=${username}`}>{username}</Link>{' '}
				{L.translate('P2P.t_0327')}
			</p>
		),
		review_created: (
			<p className="notification-card__desc">
				{L.translate('P2P.t_0318')}{' '}
				<Link to={`${navList.p2pUserCenter.path}profilename=${username}`}>{username}</Link>{' '}
				{L.translate('P2P.t_0328')}{' '}
				<Link to={`${navList.p2pTradePay.path}trade_id=${trade_id}`}>
					{L.translate('P2P.t_0319')}
				</Link>
				.
			</p>
		),

		cancel_dispute_by_admin: (
			<p className="notification-card__desc">
				{L.translate('P2P.go_to_user_profile')}{' '}
				<Link to={`${navList.p2pUserCenter.path}profilename=${username}`}>{username}</Link>
			</p>
		),
		release_dispute_by_admin: (
			<p className="notification-card__desc">
				{L.translate('P2P.go_to_user_profile')}{' '}
				<Link to={`${navList.p2pUserCenter.path}profilename=${username}`}>{username}</Link>
			</p>
		),
		new_message_chat: (
			<p className="notification-card__desc">
				{L.translate('P2P.t_0414')}{' '}
				<Link to={`${navList.p2pUserCenter.path}profilename=${username}`}>{username}</Link>{' '}
				{L.translate('P2P.t_0415')}{' '}
				<Link to={`${navList.p2pTradePay.path}trade_id=${trade_id}`}>{trade_id}</Link>
			</p>
		),
	};
	return <>{Item[data.type]}</>;
};
export default ItemType;
