import { FC } from 'react';
import L from 'i18n-react';
import Dashboard from 'layouts/Dashboard';
import Liquidation from 'components/MarginWalletHistory/Liquidation';

// ==========================================:
const LiquidationHistory: FC = () => {
	return (
		<Dashboard title={String(L.translate('PageTitles.liquidation'))}>
			<Liquidation />
		</Dashboard>
	);
};

export default LiquidationHistory;
