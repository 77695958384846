import { FC } from 'react';
import L from 'i18n-react';
import { IButtonSwitcherProps } from './types';

// ==================================:
const ButtonSwitcher: FC<IButtonSwitcherProps> = ({ handleClick, type, currentClass }) => {
	return (
		<div className={`sell-switch ${String(currentClass)}`}>
			<button
				name="buy"
				type="button"
				onClick={handleClick}
				className={
					type === 'buy' ? 'sell-switch__item sell-switch__item--active' : 'sell-switch__item'
				}
			>
				{String(L.translate('P2P.t_0087'))}
			</button>
			<button
				name="sell"
				type="button"
				onClick={handleClick}
				className={
					type === 'sell' ? 'sell-switch__item sell-switch__item--active' : 'sell-switch__item'
				}
			>
				{String(L.translate('P2P.t_0088'))}
			</button>
		</div>
	);
};

export default ButtonSwitcher;
