import { FC } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { WarningSircle } from 'assets/svg-icon';
import { INoEditPopup } from './types';

const NoEditPopup: FC<INoEditPopup> = ({ isOpen, onClose }) => {
	return (
		<Popup open={isOpen} closeOnDocumentClick onClose={onClose} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<button className="popup-close" type="button" onClick={onClose}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<div className="popup-icon popup-icon--alert popup-icon--mt-20">
								<WarningSircle />
							</div>
							<p className="popup-header__title">
								{L.translate('P2P.Advertisement.NoEditPopup.title')}
							</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="popup-text  popup-text--center">
								<p>{L.translate('P2P.Advertisement.NoEditPopup.text')}</p>
							</div>
						</div>
						<div className="popup-submit popup-submit--sb popup-submit--big-mt">
							<button className="button  button--full-width " type="button" onClick={onClose}>
								{L.translate('P2P.Advertisement.NoEditPopup.submit_btn')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default NoEditPopup;
