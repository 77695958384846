import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFiatFeesRequest } from 'redux/reducers/ourPrices/reducer';
import { getLoading, getFiatFees } from 'redux/reducers/ourPrices/selectors';
import Table from './Table';
import { groupByAssetCode } from './utils';

const PaymentMethods = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getLoading);
	const fiatFees = useSelector(getFiatFees);
	const groupedFiatFees = groupByAssetCode(fiatFees || []);

	useEffect(() => {
		dispatch(getFiatFeesRequest());
		const intervalId = setInterval(() => {
			dispatch(getFiatFeesRequest());
		}, 10000);
		return () => {
			clearInterval(intervalId);
		};
	}, [dispatch]);

	return (
		<>
			{Object.keys(groupedFiatFees).map((assetCode) => (
				<div key={assetCode} className="price-table">
					<h3 className="price-table__title">{assetCode.toUpperCase()}</h3>
					<Table items={groupedFiatFees[assetCode]} />
				</div>
			))}
		</>
	);
};

export default PaymentMethods;
