import { FC } from 'react';
import L from 'i18n-react';
import { Formik, Form, Field } from 'formik';
import Input from 'ui/Formik/Input';
import * as yup from 'yup';
import { getPayments, getProfile } from 'redux/reducers/p2p/selectors';
import { useDispatch, useSelector } from 'react-redux';
import CSearchInput from 'ui/CSearchInput';
import TextError from 'ui/Formik/TextError';
import { IPaymentItem } from 'redux/reducers/p2p/types';
import { postPaymentMethodRequest } from 'redux/reducers/p2p/reducer';
import { IPropsModal, IInitialValues } from './types';

export const BankTransfer: FC<IPropsModal> = ({ closeModal, handleStatus }) => {
	const dispatch = useDispatch();
	const payments = useSelector(getPayments);
	const cardBank: Array<IPaymentItem> | any =
		(payments && payments?.filter((item: IPaymentItem) => item.type === 'bank')) || [];

	cardBank.sort((item1: IPaymentItem, item2: IPaymentItem) => {
		if (item1.payment_name_eng < item2.payment_name_eng) {
			return -1;
		}
		if (item1.payment_name_eng > item2.payment_name_eng) {
			return 1;
		}
		return 0;
	});

	const initialValues: IInitialValues = {
		name: '',
		iban: '',
		bank_address: '',
		swift: '',
		payment_id: '',
	};

	const handleSubmit = (value: IInitialValues) => {
		const params = {
			payload: {
				payment_method_id: Number(value.payment_id),
				type: 'bank',
				requisite: {
					...value,
				},
			},
			onSuccess: () => {
				handleStatus && handleStatus('bank', 'success');
			},
			onError: () => {
				handleStatus && handleStatus('bank', 'error');
			},
		};
		dispatch(postPaymentMethodRequest(params));
	};

	const validationSchema = yup.object().shape({
		name: yup
			.string()
			.matches(
				/^[A-Za-z\s]*$/,
				String(L.translate('Account.PaymentMethods.AddCard.owner_invalid_format')),
			)
			.required(String(L.translate('Account.PaymentMethods.AddCard.owner_req_error')))
			.min(4, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
			.max(30, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		payment_id: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.payment_method_error'))),
		swift: yup.string().required(String(L.translate('Account.PaymentMethods.swift_error'))),
		iban: yup.string().required(String(L.translate('Account.PaymentMethods.iban_error'))),
		bank_address: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.address_error'))),
	});

	return (
		<div className="popup-window">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(false);
					handleSubmit?.({ ...values });
					resetForm();
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, values, errors, setValues, setErrors }) => {
					const selectedPayment = cardBank?.find(
						(item: IPaymentItem) => item.id === Number(values.payment_id),
					);
					return (
						<Form>
							<div className="popup-window__inside">
								<div className="popup popup--medium">
									<button type="button" onClick={closeModal} className="popup-close">
										<span className="popup-close__icon icon-close" />
									</button>
									<div className="popup-header">
										<p className="popup-header__title">
											{L.translate('P2P.Profile.Popups.BankTransfer.title')}
										</p>
										<div className="popup-text popup-text--center">
											<p>{L.translate('P2P.Profile.Popups.BankTransfer.subtitle')}</p>
										</div>
									</div>
									<div className="popup-body">
										<Field
											name="name"
											title={`${String(
												L.translate('P2P.Profile.Popups.BankTransfer.Titles.account_name'),
											)} *`}
											placeholder={L.translate(
												'P2P.Profile.Popups.BankTransfer.Placeholders.account_name',
											)}
											component={Input}
										/>
										<div className="input">
											<div className="input__name">
												{L.translate('P2P.Profile.Popups.BankTransfer.Titles.bank_name')} *
											</div>
											<CSearchInput
												placeholder={String(L.translate('P2P.all_payments'))}
												listData={cardBank}
												onSelect={(value: IPaymentItem) => {
													setValues({ ...values, payment_id: String(value.id) });
												}}
												onBlur={() => {
													setErrors({
														...errors,
														payment_id: values.payment_id
															? ''
															: String(L.translate('Account.PaymentMethods.payment_method_error')),
													});
												}}
												valueProp="id"
												nameProp="payment_name_eng"
												dataItem={selectedPayment?.id}
												currenClass="select--regular2"
												showValue={false}
												start="0"
												error={!!errors.payment_id}
											/>
											{errors.payment_id ? <TextError>{errors.payment_id}</TextError> : null}
										</div>
										<Field
											name="iban"
											title={`${String(
												L.translate('P2P.Profile.Popups.BankTransfer.Titles.iban'),
											)} *`}
											placeholder={L.translate('P2P.Profile.Popups.BankTransfer.Placeholders.iban')}
											component={Input}
										/>
										<Field
											name="swift"
											title={`${String(
												L.translate('P2P.Profile.Popups.BankTransfer.Titles.swift'),
											)} *`}
											placeholder={L.translate(
												'P2P.Profile.Popups.BankTransfer.Placeholders.swift',
											)}
											component={Input}
										/>
										<Field
											name="bank_address"
											title={`${String(
												L.translate('P2P.Profile.Popups.BankTransfer.Titles.address'),
											)} *`}
											placeholder={L.translate(
												'P2P.Profile.Popups.BankTransfer.Placeholders.address',
											)}
											component={Input}
										/>
									</div>
									<div className="popup-submit popup-submit--more-mt popup-submit--sb">
										<button
											disabled={isSubmitting}
											type="submit"
											className="button button--full-width "
										>
											{L.translate('P2P.Profile.Popups.BankTransfer.confirm_btn')}
										</button>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
