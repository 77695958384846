import { FC } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as yup from 'yup';
import valid from 'card-validator';
import TextError from 'ui/Formik/TextError';
import { useDispatch, useSelector } from 'react-redux';
import { addCardRequest } from 'redux/reducers/cards/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import Input from 'ui/Formik/Input';

interface IAddCardProps {
	popUpOpen: boolean;
	popUpClose: () => void;
	disable: boolean;
	name: string;
}

const AddCardPopUp: FC<IAddCardProps> = ({
	popUpOpen,
	popUpClose,
	disable = true,
	name: NamePopup = '',
}) => {
	const dispatch = useDispatch();
	const userData = useSelector(getUserSettingsData);
	const defaultNameEmail = disable ? userData?.email : '';
	const initialValues = {
		holder_name:
			userData?.data.first_name || userData?.data.last_name
				? `${userData?.data.first_name ? userData.data.first_name : ''} ${
						userData?.data.last_name ? userData.data.last_name : ''
				  }`
				: defaultNameEmail,
		bank_name: '',
		card_number: '',
		expiry_date: '',
	};

	const validationSchema = yup.object().shape({
		card_number: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.card_number_error')))
			.test(
				'test-number',
				String(L.translate('Account.PaymentMethods.AddCard.card_number_error_unvalid')),
				(value) => valid.number(value).isValid,
			)
			.min(16, String(L.translate('Account.PaymentMethods.AddCard.card_number_error_2'))),
		expiry_date: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.expiration_error')))
			.test(
				'test-credit-card-expiration-date', // this is used internally by yup
				String(L.translate('Account.PaymentMethods.AddCard.expiration_wrong')), // validation message
				(value) => valid.expirationDate(value).isValid,
			)
			.matches(
				/^(0?[1-9]|1[0-2])[/][0-9]{2}$/,
				String(L.translate('Account.PaymentMethods.AddCard.expiration_error_2')),
			),
	});

	const clearNumber = (value: number | string) => {
		return String(value).replace(/\D+/g, '');
	};

	const expiryDateFormat = (value: string) => {
		const clearValue = clearNumber(value);

		if (clearValue.length >= 3) {
			return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
		}
		return clearValue;
	};

	const handleAddCard = (values: typeof initialValues) => {
		const data = {
			...values,
			card_number: values.card_number.replace(/ /g, ''),
			expiry_month: values.expiry_date.split('/')[0],
			expiry_year: values.expiry_date.split('/')[1],
		};
		dispatch(addCardRequest(data));
		popUpClose();
	};

	return (
		<Popup open={popUpOpen} onClose={popUpClose} closeOnEscape lockScroll>
			<div className="popup-window">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setSubmitting(false);
						handleAddCard(values);
						resetForm();
					}}
					validateOnBlur
					enableReinitialize
				>
					{({ values, errors, touched, isSubmitting }) => {
						const isError = (field: keyof typeof values) => touched[field] && errors[field];
						return (
							<Form>
								<div className="popup-window__inside">
									<div className="popup popup--medium">
										<button onClick={popUpClose} type="button" className="popup-close">
											<span className="popup-close__icon icon-close" />
										</button>
										<div className="popup-header">
											<p className="popup-header__title">
												{String(L.translate('Account.PaymentMethods.add_new_card'))}
											</p>
										</div>
										<div className="popup-body popup-body--margin-none">
											<div className="popup-text popup-text--center">
												<p>{String(L.translate('Account.PaymentMethods.enter_card_info'))}</p>
											</div>
											<Field
												name="holder_name"
												title={String(L.translate('Account.PaymentMethods.card_owner_name'))}
												component={Input}
												// disabled={disable}
											/>
											<Field
												name="bank_name"
												title={String(L.translate('Account.PaymentMethods.card_bank_name'))}
												component={Input}
												placeholder={String(L.translate('Account.PaymentMethods.card_bank_name'))}
											/>
											<div className="input">
												<p className="input__name">
													{String(L.translate('Account.PaymentMethods.card_number'))}
												</p>
												<div
													className={`input-wrapper ${
														isError('card_number') ? 'input--error' : ''
													}`}
												>
													<Field
														name="card_number"
														className="input-item input-item--right-double-icon"
														placeholder={String(L.translate('Account.PaymentMethods.card_number'))}
														value={values.card_number
															.replace(/[^0-9]/g, '')
															.replace(/(\d{4})/g, '$1 ')
															.trim()}
														maxLength={19}
													/>
													{/* <span className="input-icons">
														<img className="input-icons__visa" src={visaLogo} alt="" />
														<img className="input-icons__mastercard" src={mastercardLogo} alt="" />
													</span> */}
												</div>
												<ErrorMessage name="card_number" component={TextError} />
											</div>
											<div className="two-inputs">
												<div className="input input--margin-none">
													<p className="input__name">
														{String(L.translate('Account.PaymentMethods.expiry_date'))}
													</p>
													<div
														className={`input-wrapper ${
															isError('expiry_date') ? 'input--error' : ''
														}`}
														style={{ width: '50%' }}
													>
														<Field
															name="expiry_date"
															className="input-item"
															placeholder="MM/YY"
															value={expiryDateFormat(values.expiry_date)}
															maxLength={5}
														/>
													</div>
													<ErrorMessage name="expiry_date" component={TextError} />
												</div>
												{/* {NamePopup === 'deposit-fiat' ? null : (
													<div className="input input--margin-none">
														<p className="input__name">
															{String(L.translate('Account.PaymentMethods.AddCard.security_code'))}
														</p>

														<div className={`input-wrapper ${errors.cvv ? 'input--error' : ''}`}>
															<Field
																name="cvv"
																className="input-item"
																type="text"
																placeholder="CVV"
																value={values?.cvv?.replace(/[^0-9]/g, '').trim()}
																maxLength={3}
															/>
														</div>
														<ErrorMessage name="cvv" component={TextError} />
													</div>
												)} */}
											</div>
										</div>
										<div className="popup-submit popup-submit--sb">
											<button
												type="submit"
												className="button button--full-width"
												disabled={isSubmitting}
											>
												{String(L.translate('Account.PaymentMethods.add_card'))}
											</button>
										</div>
									</div>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</Popup>
	);
};

export default AddCardPopUp;
