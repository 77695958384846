import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTokenExpiredAt } from 'redux/reducers/auth/selectors';
import { refreshRequest } from 'redux/reducers/auth/reducer';
import { IRefreshTokenProvider } from './types';

const RefreshTokenProvider: FC<IRefreshTokenProvider> = ({ children }) => {
	const dispatch = useDispatch();
	const tokenExpiredAt = useSelector(getTokenExpiredAt);
	useEffect(() => {
		if (tokenExpiredAt == null) {
			return;
		}
		const currentDate = new Date();
		const diff = tokenExpiredAt * 1000 - currentDate.getTime() - 60000;
		setTimeout(() => {
			dispatch(refreshRequest());
		}, diff);
	}, [dispatch, tokenExpiredAt]);
	return <>{children}</>;
};

export default RefreshTokenProvider;
