/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getCryptoLimits, getLoading } from 'redux/reducers/ourPrices/selectors';
import { HIDE_VIP } from 'components/OurPrices/constants';
import TableRow from './TableRow';
import { IP2PTable } from './types';

const CryptoTable: FC<IP2PTable> = ({ data, limit }) => {
	const loading = useSelector(getLoading);

	return (
		<div
			className={`table table--prices-type ${
				limit
					? `table--daily-limit-crypto${HIDE_VIP ? '--hide-vip' : ''}`
					: `table--single-limit-crypto${HIDE_VIP ? '--hide-vip' : ''}`
			}`}
		>
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.currency')}</p>
					</div>
					{limit ? null : (
						<div className="td">
							<p className="td-title">{L.translate('OurPrices.Columns.min_withdrawal')}</p>
						</div>
					)}
					<div className={`td ${HIDE_VIP ? 'td--right' : ''}`}>
						<p className="td-title">{L.translate('OurPrices.Columns.max_withdrawal')}</p>
					</div>
					{!HIDE_VIP && (
						<>
							{limit ? null : (
								<div className="td">
									<p className="td-title">{L.translate('OurPrices.Columns.vip_min_withdrawal')}</p>
								</div>
							)}
							<div className="td td--right">
								<p className="td-title">{L.translate('OurPrices.Columns.vip_max_withdrawal')}</p>
							</div>
						</>
					)}
				</div>
			</div>
			<div className="table-body">
				{data && data?.map((item, idx) => <TableRow key={idx} item={item} limit={limit} />)}
			</div>
		</div>
	);
};

export default CryptoTable;
