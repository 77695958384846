import { FC, useState, useEffect, useCallback, useRef, KeyboardEvent } from 'react';
import { useFormikContext, Field } from 'formik';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Input from 'ui/Formik/Input';
import { getLoginIsLoad } from 'redux/reducers/auth/selectors';
import arrowUp from 'assets/img/icons/arrow_up.svg';
import { TLoginFormInner, ILoginSubmitValue } from './types';

// ==========================================:
const LoginFormInner: FC<TLoginFormInner> = ({
	showTotp,
	setShowTotp,
	totpIsError,
	setTotpIsError,
	captchaLoading,
	setCaptchaLoading,
	captcha,
	setCaptcha,
}) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();
	const submitRef = useRef<HTMLButtonElement>(null);
	const loading = useSelector(getLoginIsLoad);

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleShowTotp = (): void => {
		setShowTotp(!showTotp);
	};

	const { isValid, dirty, isSubmitting, values, setFieldValue } =
		useFormikContext<ILoginSubmitValue>();

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.error('executeRecaptcha not exist');
			setTimeout(() => {
				// eslint-disable-next-line no-void
				void handleReCaptchaVerify();
			}, 300);
			return;
		}

		const token = await executeRecaptcha('login');
		setCaptchaLoading?.(false);
		setCaptcha(token);
	}, [executeRecaptcha, setCaptchaLoading, setCaptcha]);

	useEffect(() => {
		setTotpIsError(false);
	}, [values.totp, setTotpIsError]);

	useEffect(() => {
		if (captcha && isValid && !isSubmitting && !captchaLoading && !loading) {
			submitRef?.current?.click();
		}
	}, [captcha, captchaLoading, isSubmitting, isValid, loading]);

	const handleGenerate = async () => {
		setCaptchaLoading?.(true);
		await handleReCaptchaVerify();
	};

	const onKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			await handleGenerate();
		}
	};

	return (
		<>
			<p className="authorization__title">
				{String(L.translate('Forms.LoginForm.login_form_title'))}
			</p>
			<div className="authorization__details">
				<p>{String(L.translate('Forms.LoginForm.login_form_subtitle'))}</p>
			</div>
			<div className="authorization__form">
				<div className="authorization__field">
					<Field
						type="email"
						placeholder={String(L.translate('Forms.LoginForm.login_field_email'))}
						name="email"
						required
						component={Input}
						onKeyDown={onKeyDown}
					/>
				</div>
				<div className="authorization__field">
					<Field
						type={isShowPass ? 'text' : 'password'}
						placeholder={String(L.translate('Forms.LoginForm.login_field_password'))}
						name="password"
						required
						component={Input}
						ariaLabel="show-password"
						showPass={isShowPass}
						setIsShowPass={handlePassDisplay}
						onKeyDown={onKeyDown}
					/>
				</div>

				<button type="button" className="google-2fa-button" onClick={handleShowTotp}>
					<span>{String(L.translate('Forms.LoginForm.login_google2fa'))}</span>
					<span className={`google-2fa-icon ${showTotp ? 'google-2fa-icon--rotate' : ''}`}>
						<img src={arrowUp} alt="" />
					</span>
				</button>

				{showTotp && (
					<div
						className={`authorization__field google-2fa-input ${totpIsError ? 'input--error' : ''}`}
					>
						<Field
							type="text"
							placeholder={String(L.translate('Forms.LoginForm.login_google2fa'))}
							name="totp"
							required
							component={Input}
							onKeyDown={onKeyDown}
						/>
					</div>
				)}
				<div className="authorization__options">
					<Link to="/forgot-password" className="authorization__forgot-link">
						{String(L.translate('Forms.LoginForm.login_forgot_password'))}
					</Link>
					<Link to="/forgot-2fa" className="authorization__link">
						{String(L.translate('Forms.Forgot2fa.reset_2fa'))}
					</Link>
				</div>
			</div>
			<div className="form-submit">
				<button
					disabled={!(isValid && dirty) || isSubmitting || captchaLoading}
					aria-label="form submit"
					type="button"
					className="button button--full-width"
					onClick={handleGenerate}
				>
					{String(L.translate('Forms.LoginForm.login_submit_btn'))}
				</button>
				<button type="submit" className="hidden" ref={submitRef}>
					{L.translate('Global.submit')}
				</button>
			</div>
		</>
	);
};

export default LoginFormInner;
