import L from 'i18n-react';
import { FC } from 'react';
import { ITabsBtn } from './types';

const TabsButton: FC<ITabsBtn> = ({ activeBtn, setActiveBtn, text }) => {
	return (
		<button
			type="button"
			onClick={() => setActiveBtn(text)}
			className={`table-tab__item ${activeBtn === text ? 'active' : ''}`}
		>
			{text}
		</button>
	);
};

export default TabsButton;
