import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getAssetPairsFeesAndLimitsRequest,
	getAssetPairsRequest,
} from 'redux/reducers/assetPairs/reducer';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import ConvertForm from '../ConvertForm';

const ConvertFormAuth = () => {
	const dispatch = useDispatch();
	const isAuth = useSelector(getAuthIsAuthenticated);

	useEffect(() => {
		if (!isAuth) return;
		dispatch(getWalletsRequest());
		dispatch(getAssetPairsRequest());
		dispatch(getAssetPairsFeesAndLimitsRequest());
		dispatch(userSettingsRequest());
	}, [isAuth, dispatch]);

	return <ConvertForm />;
};

export default ConvertFormAuth;
