import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import { getSpotFeesRequest } from 'redux/reducers/ourPrices/reducer';
import { getSpotFees, getSpotFeesLoading } from 'redux/reducers/ourPrices/selectors';
import InfoIcon from 'ui/component-icons/InfoIcon';

const SpotFeesTable = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getSpotFeesLoading);
	const spotFees = useSelector(getSpotFees);

	useEffect(() => {
		const fetchData = () => {
			dispatch(getSpotFeesRequest());
		};
		fetchData();
		const intervalId = setInterval(() => {
			fetchData();
		}, 10000);
		return () => {
			clearInterval(intervalId);
		};
	}, [dispatch]);

	return (
		<div className="table table--prices-type table--prices-crypto-spot">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.level')}</p>
					</div>
					<div className="td td--center">
						<p className="td-title">{L.translate('OurPrices.Columns.30_day_volume')}</p>
					</div>
					<div className="td td--center">
						<p className="td-title">{L.translate('OurPrices.Columns.taker_fee')}</p>
					</div>
					<div className="td td--right">
						<p className="td-title">{L.translate('OurPrices.Columns.maker_fee')}</p>
					</div>
				</div>
			</div>
			<div className="table-body">
				{spotFees?.map((item) => (
					<div key={item.id} className="tr">
						<div className="td">
							<p className="td-hidden-name">Level</p>
							<p className="td-name">{item.level}</p>
						</div>
						<div className="td td--center">
							<p className="td-hidden-name">30-Day Volume (USD)</p>
							<div className="td-two-value">
								<p className="td-name">{item.min_amount}$</p>
								<p className="td-name">{item.max_amount}$</p>
							</div>
						</div>
						<div className="td td--center">
							<p className="td-hidden-name">Taker Fee %</p>
							<p className="td-name">{item.taker_fee}</p>
						</div>
						<div className="td td--right">
							<p className="td-hidden-name">Maker Fee %</p>
							<p className="td-name">{item.maker_fee}</p>
						</div>
					</div>
				))}
			</div>
			<div className="table-hint table-hint--start">
				<p className="table-hint__name">{L.translate('OurPrices.CryptoSpotTooltip.title')}</p>
				<span className="tooltip-item ">
					<span className="tooltip-item__icon">
						<InfoIcon />
					</span>
					<span className="tooltip tooltip--width-320 tooltip--bottom tooltip--center ">
						<span className="tooltip__text">
							<span>{L.translate('OurPrices.CryptoSpotTooltip.text')}</span>
						</span>
					</span>
				</span>
			</div>
		</div>
	);
};

export default SpotFeesTable;
