import TransferModal from 'components/TransferModal';
import TransferFiatModal from 'components/TransferModal/TransferFiatModal';
import L from 'i18n-react';
import { CROSS_OPTION, ISOLATED_OPTION, SPOT_OPTION } from 'components/TransferModal/utils';
import { MouseEvent, FC, useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
	getAssetPairListActiveIsolate,
	getAssetPairsList,
} from 'redux/reducers/assetPairs/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { numberFormat } from 'services/utils/numberFormat';
import useClickOutside from 'hooks/useClickOutside';
import { roundNumberToFixed } from 'services/utils/numberHelpers';
import { dataLayer } from 'services/gtm';
import Withdrawal2FAPopup from '../Withdrawal2FAPopup';
import { ISpotAndFiatItemProps } from './types';
import { findPairByAssetCode, TRANSFERS_FROM_SPOT } from './utils';

const SpotAndFiatItem: FC<ISpotAndFiatItemProps> = ({ data, balancesIsHide, currencyType }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const assetPairs = useSelector(getAssetPairsList);
	const currentPair = useSelector(getCurrentPair);
	const userData = useSelector(getUserSettingsData);
	const isolatedPairs = useSelector(getAssetPairListActiveIsolate);

	const [isShowTransferModal, setIsShowTransferModal] = useState(false);
	const [isWithdrawal2FaShow, setIsWithdrawal2FaShow] = useState(false);

	const assetType = data.asset.type;
	const assetCode = data.asset.code;
	// const isFiat = assetType === 'fiat';
	const isStable = currencyType === 'stable';
	const isTransferToIsolated = TRANSFERS_FROM_SPOT[assetCode]?.to === ISOLATED_OPTION;

	const isolatedPair = useMemo(() => {
		return findPairByAssetCode(assetCode, isolatedPairs);
	}, [assetCode, isolatedPairs]);
	const isNowhereToTransfer = isTransferToIsolated && !isolatedPair;

	const isDepositable = useMemo(() => {
		return data.networks?.some(({ depositable }) => depositable);
	}, [data]);

	const isWithdrawable = useMemo(() => {
		return (
			data.networks.some((network) => network.withdrawable) || data.asset.withdrawable || false
		);
	}, [data]);

	const handleTrade = () => {
		if (!assetPairs?.length) {
			return;
		}
		const findPairPerCode = isStable
			? assetPairs.find((pair) => pair.code.split('_')[1] === data?.asset?.code)
			: assetPairs.find((pair) => pair.code === `${data?.asset?.code}_usdt`);
		if (findPairPerCode?.code) {
			dispatch(setCurrentPair(findPairPerCode?.code));
		} else {
			dispatch(setCurrentPair('btc_usdt'));
		}
		history.push(`/spot/${currentPair.toUpperCase()}`);
	};

	const handleWithdrawal = () => {
		if (Number(userData?.status?.id) !== 2) {
			notificationContainer(String(L.translate('SpotAndFiat.verify_acc_notification')), 'info');
			return;
		}
		if (
			userData?.google2fa_enabled === 0 &&
			userData?.phone_2fa_enabled === 0 &&
			userData?.email_2fa_enabled === 0
		) {
			setIsWithdrawal2FaShow(true);
			return;
		}

		if (!Number(data?.balance)) {
			notificationContainer(String(L.translate('SpotAndFiat.min_withdraw_notification')), 'info');
			return;
		}

		dataLayer.push({
			event: 'start_withdraw',
		});

		history.push({
			pathname: 'withdraw',
			state: {
				currentWallet: data,
			},
		});
	};

	const handleDeposit = () => {
		if (Number(userData?.status?.id) !== 2) {
			notificationContainer(
				String(L.translate('SpotAndFiat.deposit_verify_acc_notification')),
				'info',
			);
			return;
		}

		dataLayer.push({
			event: 'start_deposit',
		});

		history.push({
			pathname: '/deposit',
			state: {
				currentWallet: data,
			},
		});
	};

	const dropdownNode = useRef<HTMLDivElement | null>(null);
	const [dropOpened, setDropOpened] = useState(false);
	const [actionsOpened, setActionsOpened] = useState(false);

	const toggleDropHandler = () => {
		setDropOpened(!dropOpened);
	};
	const toggleActionsDropdown = () => {
		setActionsOpened(!actionsOpened);
	};
	const closeActionsDropdown = () => {
		setActionsOpened(false);
	};
	useClickOutside(dropdownNode, closeActionsDropdown);

	useEffect(() => {
		if (isShowTransferModal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
		return () => {
			document.body.style.overflow = '';
		};
	}, [isShowTransferModal]);

	return (
		<div className="tr">
			<div className="td">
				<div className="balance-currency">
					<div className="balance-currency__img">
						<img src={data?.asset?.img_path} alt="" />
					</div>
					<div className="balance-currency__info">
						<span className="balance-currency__title">{data?.asset?.code?.toUpperCase()}</span>
						<span className="balance-currency__desc">{data?.asset?.name}</span>
					</div>
				</div>
			</div>
			<div
				className={`td td--left-auto td--dropdown td-name--center balance ${
					dropOpened ? 'td--dropdown-active' : ''
				}`}
			>
				<span className="td-hidden-name">{String(L.translate('SpotAndFiat.total'))}</span>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : roundNumberToFixed(Number(data?.total), 8)}
				</span>
				<span className="td-arrow icon-arrow2" onClick={toggleDropHandler} />
			</div>
			<div className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}>
				<span className="td-hidden-name">{String(L.translate('SpotAndFiat.available'))}</span>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : roundNumberToFixed(Number(data?.balance), 8)}
				</span>
			</div>
			<div className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}>
				<span className="td-hidden-name">{String(L.translate('SpotAndFiat.in_order'))}</span>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : roundNumberToFixed(Number(data?.frozen_balance), 8)}
				</span>
			</div>
			<div className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}>
				<span className="td-hidden-name">{String(L.translate('SpotAndFiat.btc_value'))}</span>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : roundNumberToFixed(Number(data?.btc_value), 8)}{' '}
					<span className="td-name td-name--subtext">
						{' '}
						≈ $ {balancesIsHide ? '********' : numberFormat(Number(data?.usd_value), 'en-EN')}
					</span>
				</span>
			</div>
			<div className="td td-name--center" ref={dropdownNode}>
				<div className={`select select--links	 ${actionsOpened ? 'active' : ''}`}>
					<button type="button" className="select__current" onClick={toggleActionsDropdown}>
						<span className="select__arrow icon-more" />
					</button>
					<div className="select__drop">
						{isDepositable ? (
							<button type="button" onClick={handleDeposit} className="link link--regular">
								{String(L.translate('SpotAndFiat.deposit'))}
							</button>
						) : null}
						{isWithdrawable ? (
							<button type="button" onClick={handleWithdrawal} className="link link--regular">
								{String(L.translate('SpotAndFiat.withdraw'))}
							</button>
						) : null}
						<button type="button" onClick={handleTrade} className="link link--regular">
							{String(L.translate('SpotAndFiat.trade'))}
						</button>
						<Link to={`/convert?from=${assetCode}`} className="link link--regular">
							{String(L.translate('SpotAndFiat.convert'))}
						</Link>
						{!isNowhereToTransfer && (
							<button
								type="button"
								onClick={() => setIsShowTransferModal(true)}
								className="link link--regular"
							>
								{String(L.translate('SpotAndFiat.transfer'))}
							</button>
						)}
					</div>
				</div>
			</div>

			<TransferModal
				isOpen={isShowTransferModal}
				onClose={() => setIsShowTransferModal(false)}
				initFrom={SPOT_OPTION}
				initTo={TRANSFERS_FROM_SPOT[assetCode]?.to || CROSS_OPTION}
				initPairCode={isTransferToIsolated ? isolatedPair?.code : undefined}
				initCoinCode={assetCode}
				spotSocket
				crossSocket
				isolatedSocket
				p2pSocket
				marginIndexSocket
			/>
			<Withdrawal2FAPopup
				isOpen={isWithdrawal2FaShow}
				onClose={() => setIsWithdrawal2FaShow(false)}
			/>
		</div>
	);
};

export default SpotAndFiatItem;
