import { FC } from 'react';
import L from 'i18n-react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { renameCoin } from 'services/utils/renameCoin';
import { numbersAfterPoint } from 'services/utils/numberHelpers';
import { notificationContainer } from 'services/utils/notificationContainer';
import { CopyIcon } from 'assets/svg-icon';
import { IInfoTrade } from './type';

const InfoTradeSection: FC<IInfoTrade> = ({ coin, trade, type }) => {
	const { user_payment_method, advertisement } = trade || {};

	const { payment_method, requisite, type: paymentMethodType } = user_payment_method || {};

	return (
		<>
			<div className="payment-details">
				<h4 className="payment-details__title">{L.translate('P2P.t_0141')}</h4>
				<div className="payment-details__items">
					<div className="payment-details__item payment-details__item--group-row">
						<div className="payment-details__group">
							<p className="payment-details__item-descr">{L.translate('P2P.t_0486')}:</p>
							<p className="payment-details__item-sum">
								{fixedCropNumber(type === 'buy' ? Number(trade?.price) : Number(trade?.amount), 10)}{' '}
								{type === 'buy'
									? trade?.advertisement?.current_fiat_code
									: trade?.asset_code?.toUpperCase()}
							</p>
						</div>
						<div className="payment-details__group">
							<p className="payment-details__item-descr">{L.translate('P2P.t_0093')}:</p>
							<p className="payment-details__item-sum">
								{Number(trade?.price)}{' '}
								{type === 'buy'
									? trade?.advertisement?.current_fiat_code
									: trade?.asset_code?.toUpperCase()}
							</p>
						</div>
						<div className="payment-details__group">
							<p className="payment-details__item-descr">{L.translate('P2P.quantity')}:</p>
							<p className="payment-details__item-sum">
								{fixedCropNumber(type === 'buy' ? Number(trade?.amount) : Number(trade?.price), 10)}{' '}
								{type === 'buy'
									? trade?.asset_code?.toUpperCase()
									: trade?.advertisement?.current_fiat_code}
							</p>
						</div>
					</div>
					<div className="payment-details__item">
						<p className="payment-details__item-descr">
							{L.translate('P2P.amount_to_be_received')}:
						</p>
						<p className="payment-details__item-sum">
							{fixedCropNumber(type === 'buy' ? Number(trade?.amount) : Number(trade?.price), 10)}{' '}
							{type === 'buy'
								? trade?.asset_code?.toUpperCase()
								: trade?.advertisement?.current_fiat_code}
						</p>
					</div>
					<div className="payment-details__item">
						<p className="payment-details__item-descr">{L.translate('P2P.created_time')}:</p>
						<p className="payment-details__item-sum">
							{moment(trade?.created_at).format('YYYY-MM-DD HH:mm:ss')}
						</p>
					</div>
					<div className="payment-details__item">
						<p className="payment-details__item-descr">{L.translate('P2P.order_number')}:</p>
						<div className="payment-details__item-sum">
							{trade?.id}
							<button type="button" className="copy-icon-btn">
								<CopyToClipboard
									text={trade?.id || ''}
									onCopy={() =>
										notificationContainer(
											String(L.translate(`P2P.Messages.order_number_copied`)),
											'info',
										)
									}
								>
									<CopyIcon />
								</CopyToClipboard>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="payment-descr payment-descr--type3">
				<h4 className="payment-descr-title">{L.translate('P2P.t_0089')}</h4>
				<div className="details-list details-list--mt-type2">
					<div className="details-list__row details-list__row--column">
						<div className="details-list__left">
							<span className="details-list__title">
								{paymentMethodType === 'web'
									? L.translate('P2P.PaymentMethod.web_wallet')
									: L.translate('P2P.PaymentMethod.bank_name')}
							</span>
						</div>
						<div className="details-list__right">
							<span className="details-list__desc">{payment_method?.payment_name_eng}</span>
						</div>
					</div>
					{paymentMethodType !== 'web' && requisite?.name && (
						<div className="details-list__row details-list__row--column">
							<div className="details-list__left">
								<span className="details-list__title">
									{L.translate('P2P.PaymentMethod.owner_name')}
								</span>
							</div>
							<div className="details-list__right">
								<div className="details-list__line">
									<span className="details-list__desc">{requisite?.name}</span>
									<button className="copy-icon-btn" type="button">
										<CopyToClipboard
											text={requisite?.name || ''}
											onCopy={() =>
												notificationContainer(
													String(L.translate(`P2P.Messages.owner_name_copied`)),
													'info',
												)
											}
										>
											<CopyIcon />
										</CopyToClipboard>
									</button>
								</div>
							</div>
						</div>
					)}
					{paymentMethodType === 'web' && requisite?.web_wallet_id && (
						<div className="details-list__row details-list__row--column">
							<div className="details-list__left">
								<span className="details-list__title">
									{L.translate('P2P.PaymentMethod.web_wallet_id')}
								</span>
							</div>
							<div className="details-list__right">
								<div className="details-list__line">
									<span className="details-list__desc">{requisite?.web_wallet_id}</span>
									<button className="copy-icon-btn" type="button">
										<CopyToClipboard
											text={requisite?.web_wallet_id || ''}
											onCopy={() =>
												notificationContainer(
													String(L.translate(`P2P.Messages.web_wallet_id_copied`)),
													'info',
												)
											}
										>
											<CopyIcon />
										</CopyToClipboard>
									</button>
								</div>
							</div>
						</div>
					)}
					{paymentMethodType === 'bank' && (
						<>
							{requisite?.iban && (
								<div className="details-list__row details-list__row--column">
									<div className="details-list__left">
										<span className="details-list__title">
											{L.translate('P2P.PaymentMethod.iban')}
										</span>
									</div>
									<div className="details-list__right">
										<div className="details-list__line">
											<span className="details-list__desc">{requisite?.iban}</span>
											<button className="copy-icon-btn" type="button">
												<CopyToClipboard
													text={requisite?.iban || ''}
													onCopy={() =>
														notificationContainer(
															String(L.translate(`P2P.Messages.iban_copied`)),
															'info',
														)
													}
												>
													<CopyIcon />
												</CopyToClipboard>
											</button>
										</div>
									</div>
								</div>
							)}
							{requisite?.swift && (
								<div className="details-list__row details-list__row--column">
									<div className="details-list__left">
										<span className="details-list__title">
											{L.translate('P2P.PaymentMethod.swift')}
										</span>
									</div>
									<div className="details-list__right">
										<div className="details-list__line">
											<span className="details-list__desc">{requisite?.swift}</span>
											<button className="copy-icon-btn" type="button">
												<CopyToClipboard
													text={requisite?.swift || ''}
													onCopy={() =>
														notificationContainer(
															String(L.translate(`P2P.Messages.swift_copied`)),
															'info',
														)
													}
												>
													<CopyIcon />
												</CopyToClipboard>
											</button>
										</div>
									</div>
								</div>
							)}
						</>
					)}
					{paymentMethodType === 'card' && requisite?.card_number && (
						<div className="details-list__row details-list__row--column">
							<div className="details-list__left">
								<span className="details-list__title">
									{L.translate('P2P.PaymentMethod.card_number')}
								</span>
							</div>
							<div className="details-list__right">
								<div className="details-list__line">
									<span className="details-list__desc">{requisite?.card_number}</span>
									<button className="copy-icon-btn" type="button">
										<CopyToClipboard
											text={requisite?.card_number || ''}
											onCopy={() =>
												notificationContainer(
													String(L.translate(`P2P.Messages.card_number_copied`)),
													'info',
												)
											}
										>
											<CopyIcon />
										</CopyToClipboard>
									</button>
								</div>
							</div>
						</div>
					)}
					<div className="details-list__row details-list__row--column">
						<div className="details-list__left">
							<span className="details-list__title">
								{L.translate('P2P.PaymentMethod.advertisement_description')}:
							</span>
						</div>
						<div className="details-list__right">
							<div className="input">
								<div className="input-wrapper">
									<div className="textarea">
										<textarea
											className="input-item input-item--textarea"
											placeholder="Advertisement description (Optional)"
											readOnly
											value={advertisement?.description}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default InfoTradeSection;
