import {
	setResponseData,
	addCardRequest,
	addCardSuccess,
	deleteCardRequest,
	getCardsRequest,
	getCardsSuccess,
	updateCardRequest,
	updateCardResponse,
	addDepositCardRequest,
	getDepositFiatFeeRequest,
	getWithdrawalFiatFeeRequest,
	getWithdrawalFiatFeeSuccess,
	getDepositFiatFeeSuccess,
	getCardStatusRequest,
	createCardWithdrawalRequest,
	getCardStatusSuccess,
	verifyCardWithdrawalRequest,
	verifyCardRequest,
	verifyCardResponse,
	verifyCardFailed,
} from 'redux/reducers/cards/reducer';
import L from 'i18n-react';
import { notificationContainer } from 'services/utils/notificationContainer';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from 'services';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { responseErrors } from 'services/http/responseErrors';
import { dataLayer } from 'services/gtm';
import {
	IAddCardPayload,
	ICardItem,
	IEditCardPayload,
	IAddCardRequest,
	IFeeDepositResponse,
	IFeeDepoitFiatPayload,
	IFeeWithdrowalFiatPayload,
	IFeeDepoitFiatResponse,
	ICardStatusPayload,
	ICardStatusResponse,
	IFeeWithdrawalFiatRequest,
	IFeeWithdrawalCardFiatRequest,
	IVerifyCardRequest,
	IVerifyCardPayload,
	IVerifyCardResponse,
	IFeeWithdrawalFiatResponse,
} from './types';

// =============================================================:
function* getCardsWorker() {
	try {
		const response: Array<ICardItem> = yield call(api.cards.getCardList);
		yield put(getCardsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* addCardsWorker(action: PayloadAction<IAddCardPayload>) {
	const { payload } = action;
	try {
		const response: ICardItem = yield call(api.cards.addCard, payload);
		yield put(addCardSuccess(response));
		yield put(setResponseData(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* updateCardsWorker(action: PayloadAction<IEditCardPayload>) {
	const { payload } = action;
	try {
		const response: ICardItem = yield call(api.cards.updateCard, payload);
		yield put(updateCardResponse(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* deleteCardsWorker(action: PayloadAction<{ id: number; showSuccess: () => void }>) {
	const { payload } = action;
	try {
		yield call(api.cards.deleteCard, payload.id);
		yield put(getCardsRequest());
		yield payload.showSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* addDepositCardWorker(action: PayloadAction<IAddCardRequest>) {
	const { payload, onError, onSuccess, type } = action.payload;
	try {
		const response: IFeeDepoitFiatResponse = yield call(api.cards.addCardFiat, {
			...payload,
			type,
		});
		// yield put(getCardsRequest());
		if (response) {
			yield onSuccess?.(String(response?.proceedUrl));
			dataLayer.push({
				event: 'transaction',
				transaction_id: response.id,
				transaction_fee: response.fee,
				transaction_fee_name: 'deposit',
				value: response.amount,
				currency: response.asset_code,
			});
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'cof_record_is_disabled') {
				notificationContainer(String(L.translate('Errors.cof_record_is_disabled')), 'error');
				onError?.();
				return;
			}
			if (error?.response?.data?.errors[0] === '3ds_versioning_error') {
				notificationContainer(String(L.translate('Errors.3ds_versioning_error')), 'error');
				onError?.();
				return;
			}
			onError?.();
			responseErrors(error);
		}
	}
}

function* getDepositFiatFeeWorker(action: PayloadAction<IFeeDepoitFiatPayload>) {
	const { payload } = action;
	try {
		const response: IFeeDepositResponse = yield call(api.cards.getDepositFiatFee, payload);
		yield put(getDepositFiatFeeSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getWithdrawalFiatFeeWorker(action: PayloadAction<IFeeWithdrowalFiatPayload>) {
	const { payload } = action;
	try {
		const response: IFeeDepositResponse = yield call(api.cards.getWithdrawalFiatFee, payload);
		yield put(getWithdrawalFiatFeeSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getCardStatusWorker(action: PayloadAction<ICardStatusPayload>) {
	const { payload } = action;
	try {
		const response: ICardStatusResponse = yield call(api.cards.getCardStatus, payload);
		yield put(getCardStatusSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* createCardWithdrawalWorker(action: PayloadAction<IFeeWithdrawalFiatRequest>) {
	const { payload, onError, onSuccess, type } = action.payload;
	try {
		const response: IFeeWithdrawalFiatResponse = yield call(
			api.cards.createCardWithdrawalSaved,
			payload,
		);
		// yield put(getCardsRequest());
		dataLayer.push({
			event: 'transaction',
			transaction_id: response.withdrawal.id,
			transaction_fee: response.withdrawal.amount_fee,
			transaction_fee_name: response.withdrawal.type,
			value: response.withdrawal.amount,
			currency: payload.asset_code,
		});
		if (response) {
			yield onSuccess?.();
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			onError?.(error);
			responseErrors(error);
		}
	}
}

function* verifyCardWithdrawalWorker(action: PayloadAction<IFeeWithdrawalCardFiatRequest>) {
	const { payload, onError, onSuccess } = action.payload;
	try {
		const response: IFeeDepoitFiatResponse = yield call(api.cards.verifyCardWithdrawal, payload);
		if (response) {
			yield onSuccess?.(String(response?.proceedUrl));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			onError?.();
			responseErrors(error);
		}
	}
}

function* verifyCardWorker({ payload }: PayloadAction<IVerifyCardPayload>) {
	const { apiParams, onSubmit } = payload;
	try {
		const response: IVerifyCardResponse = yield call(api.cards.verifyCard, apiParams);
		onSubmit?.();
		yield put(verifyCardResponse(response));
	} catch (error) {
		yield put(verifyCardFailed());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// =============================================================:
export function* cardsSaga() {
	yield takeEvery(getCardsRequest.type, getCardsWorker);
	yield takeEvery(addCardRequest.type, addCardsWorker);
	yield takeEvery(updateCardRequest.type, updateCardsWorker);
	yield takeLatest(deleteCardRequest.type, deleteCardsWorker);
	yield takeLatest(addDepositCardRequest.type, addDepositCardWorker);
	yield takeLatest(getDepositFiatFeeRequest.type, getDepositFiatFeeWorker);
	yield takeLatest(getWithdrawalFiatFeeRequest.type, getWithdrawalFiatFeeWorker);
	yield takeLatest(getCardStatusRequest.type, getCardStatusWorker);
	yield takeLatest(createCardWithdrawalRequest.type, createCardWithdrawalWorker);
	yield takeLatest(verifyCardWithdrawalRequest.type, verifyCardWithdrawalWorker);
	yield takeLatest(verifyCardRequest.type, verifyCardWorker);
}
