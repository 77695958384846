import {
	IP2PStore,
	IP2POpenTradesResponse,
	IP2PClosedTradesResponse,
	IP2PAdevrtisementResponse,
	IAssetsResponse,
	IP2POrderQuickResponse,
	IP2PCurrenciesResponse,
	IP2PAdevertisementResponse,
	IPofileResponse,
	IP2PPaymentResponse,
	IP2PProposalResponse,
	IAdvertisementData,
	IP2PDatailsResponse,
	IP2PTradePaySellResponse,
	IP2PTradeChatResponse,
	IP2PTradeChatMessgeResponse,
	IP2PDocumentResponse,
	IP2PNotificationResponse,
	IP2PNotificationBellResponse,
} from 'redux/reducers/p2p/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IP2PApi } from './types';
// import { initialState } from '../../../redux/reducers/orders/reducer';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
// ==========================================:
export const p2p: IP2PApi = {
	getP2PWallets: () =>
		http.get<IP2PStore>(endpoint.p2p.GET_P2p_WALLETS).then((response) => response.data),
	getP2POpenTrades: ({ params }) =>
		http
			.get<IP2POpenTradesResponse>(endpoint.p2p.GET_P2p_OPEN_TRADES, { params })
			.then((response) => response.data),
	getP2PClosedTrades: ({ params }) =>
		http
			.get<IP2PClosedTradesResponse>(endpoint.p2p.GET_P2p_CLOSED_TRADES, { params })
			.then((response) => response.data),
	postCreateUserP2p: (payload) =>
		http.post<any>(endpoint.p2p.POST_CREATE_USER_P2P, payload).then((response) => response.data),
	getP2PAdvertisement: ({ params }) =>
		http
			.get<IP2PAdevrtisementResponse>(endpoint.p2p.GET_P2p_ADVERTISEMENT, { params })
			.then((response) => response.data),
	getP2PAssets: () =>
		http.get<IAssetsResponse>(endpoint.p2p.GET_ASSETS).then((response) => response.data),
	getP2POrderQuick: ({ params }) =>
		http
			.get<IP2POrderQuickResponse>(endpoint.p2p.GET_ORDER_QUICK, { params })
			.then((response) => response.data),
	deletedOrder: (id) =>
		http.delete<any>(endpoint.p2p.DELETED_ORDER(id)).then((response) => response.data),
	getCurrencies: () =>
		http.get<IP2PCurrenciesResponse>(endpoint.p2p.GET_CURRENCIES).then((response) => response.data),
	getPaymnetsMethod: () =>
		http
			.get<IP2PPaymentResponse>(endpoint.p2p.GET_PAYMENT_METHODS)
			.then((response) => response.data),
	getAllAdvertisement: ({ params }) =>
		http
			.get<IP2PAdevertisementResponse>(endpoint.p2p.GET_ALL_ADVERTISEMENT, { params })
			.then((response) => response.data),
	getProfile: ({ name }) =>
		http.get<IPofileResponse>(endpoint.p2p.GET_PROFILE(name)).then((response) => response.data),
	getProposal: ({ params }) =>
		http
			.get<IP2PProposalResponse>(endpoint.p2p.GET_PROPOSAL, { params })
			.then((response) => response.data),
	postP2PAdvertisement: (payload) =>
		http.post<any>(endpoint.p2p.POST_P2p_ADVERTISEMENT, payload).then((response) => response.data),
	putP2PAdvertisement: (payload) =>
		http.put<any>(endpoint.p2p.POST_P2p_ADVERTISEMENT, payload).then((response) => response.data),
	getP2PAdvertUser: async ({ id }) =>
		http
			.get<IAdvertisementData>(endpoint.p2p.GET_P2p_USER_ADVERTISEMENT(id))
			.then((response) => response.data),
	postP2PTradeDetails: (payload) =>
		http
			.post<IP2PDatailsResponse>(endpoint.p2p.POST_P2p_TRADE, payload)
			.then((response) => response.data),
	getP2PTradePaySell: ({ trade_id }) =>
		http
			.get<IP2PTradePaySellResponse>(endpoint.p2p.GET_P2p_TRADE(trade_id))
			.then((response) => response.data),
	putP2PTradeCanceled: ({ trade_id }) =>
		http.put(endpoint.p2p.PUT_P2p_CANSELED(trade_id)).then((response) => response.data),
	putP2PTradeReceived: ({ trade_id, totp }) =>
		http.put(endpoint.p2p.PUT_P2p_RECEIVED(trade_id), { totp }).then((response) => response.data),
	putP2POpenDispute: ({ trade_id }) =>
		http.put(endpoint.p2p.PUT_P2p_OPEN_DISPUTE(trade_id)).then((response) => response.data),
	putP2PTradePay: ({ trade_id, totp }) =>
		http
			.put<IP2PTradePaySellResponse>(endpoint.p2p.PUT_P2p_PAID_SELLER(trade_id), { totp })
			.then((response) => response.data),
	getP2PChats: ({ trade_id }) =>
		http
			.get<IP2PTradeChatResponse>(endpoint.p2pChat.GET_P2p_CHATS(trade_id))
			.then((response) => response.data),
	postP2PChatsUserSend: (payload) =>
		http
			.post<IP2PTradeChatMessgeResponse>(endpoint.p2pChat.POST_MESSAGE_USER, payload)
			.then((response) => response.data),
	postP2PChatsAdminSend: (payload) =>
		http
			.post<IP2PTradeChatMessgeResponse>(endpoint.p2pChat.POST_MESSAGE_ADMIN, payload)
			.then((response) => response.data),
	getP2PEvidence: ({ trade_id }) =>
		http
			.get<IP2PDocumentResponse>(endpoint.p2pChat.GET_EVIDENCE(trade_id))
			.then((response) => response.data),

	transferSpotToP2P: async (params) => {
		return http.post(endpoint.p2p.TRANSFER_SPOT_TO_P2P, params).then(({ data }) => data);
	},
	transferP2PToSpot: async (params) => {
		return http.post(endpoint.p2p.TRANSFER_P2P_TO_SPOT, params).then(({ data }) => data);
	},
	transferCrossToP2P: async (params) => {
		return http.post(endpoint.p2p.TRANSFER_CROSS_TO_P2P, params).then(({ data }) => data);
	},
	transferP2PToCross: async (params) => {
		return http.post(endpoint.p2p.TRANSFER_P2P_TO_CROSS, params).then(({ data }) => data);
	},
	transferIsolatedToP2P: async (params) => {
		return http.post(endpoint.p2p.TRANSFER_ISOLATED_TO_P2P, params).then(({ data }) => data);
	},
	transferP2PToIsolated: async (params) => {
		return http.post(endpoint.p2p.TRANSFER_P2P_TO_ISOLATED, params).then(({ data }) => data);
	},

	postP2PEvidence: async (payload) =>
		http.post<any>(endpoint.p2pChat.UPLOAD_EVIDENCE, payload).then((response) => response.data),
	deleteP2PEvidence: async ({ id }) =>
		http.delete<any>(endpoint.p2pChat.DELETE_EVIDENCE(id)).then((response) => response.data),

	getNotification: async ({ search }) => {
		// const params = search;
		return http
			.get<IP2PNotificationResponse>(endpoint.p2p.GET_USER_NOTIFICATIONS(search))
			.then((response) => response.data);
	},
	getNotificationBell: async () => {
		return http
			.get<IP2PNotificationBellResponse>(endpoint.p2p.GET_NOTIFICATIONS_BELL)
			.then((response) => response.data);
	},
	postNotificationStatus: async (payload) => {
		// const params = search;
		return http.post(endpoint.p2p.POST_NOTIFICATIONS, payload).then((response) => response.data);
	},
	paymentCurrencyMethod: async (payload) => {
		return http
			.get(endpoint.p2p.GET_ACTIVE_PAYMENT, { params: payload })
			.then((response) => response.data);
	},
	postPaymenthMethod: async (payload) => {
		return http.post(endpoint.p2p.ADD_PAYMENT_METHOD, payload).then((response) => response.data);
	},
	getPaymnetsMethodRequest: async (params) => {
		return http.get(endpoint.p2p.GET_PAYMENT_METHOD, { params }).then((response) => response.data);
	},
	deletePaymnetsMethodRequest: async ({ id }) => {
		return http.delete(endpoint.p2p.DELETE_PAYMENT_METHOD(id)).then((response) => response.data);
	},
	changeAdvertActiveStatus: async (params) => {
		return http.put(endpoint.p2p.CHANGE_ACTIVE_STATUS_ORDER, params).then(({ data }) => data);
	},
	checkAdvertActiveOrders: async (params) => {
		return http
			.get(endpoint.p2p.CHECK_ADVERTISMENT_ACTIVE_ORDERS, { params })
			.then(({ data }) => data);
	},
	getActiveTrade: async (params) => {
		return http.get(endpoint.p2p.GET_ACTIVE_TRADE, { params }).then(({ data }) => data);
	},
	checkOrderAmount: async (params) => {
		return http.get(endpoint.p2p.CHECK_ORDER_AMOUNT, { params }).then(({ data }) => data);
	},
};
