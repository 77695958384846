import axios from 'axios';
import L from 'i18n-react';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { updateStatusP2PUserSuccess } from 'redux/reducers/auth/reducer';
import {
	getP2PBalancesListRequest,
	getP2PBalancesListSuccess,
	getP2POpenTradesRequest,
	getP2POpenTradesSuccess,
	getP2PClosedTradesRequest,
	postNickNameRequest,
	getP2PClosedTradesSuccess,
	getP2PAdevertisementRequest,
	getP2PAdevertisementSuccess,
	getP2PAdevertisementFailure,
	getP2PAssetsRequest,
	getP2PDeletedRequest,
	getP2PAssetsSuccess,
	getP2PDeletedSuccess,
	getP2PQuickRequest,
	getP2PQuickSuccess,
	getP2PCurrenciesRequest,
	getP2PCurrenciesSuccess,
	getAllAdvertisementRequest,
	getAllAdvertisementSuccess,
	getProfileRequest,
	getProfileSuccess,
	getProposalRequest,
	getPaymentRequest,
	getPaymentSuccess,
	getProposalSuccess,
	postAdvertisementRequest,
	postAdvertisementSuccess,
	getAdvertisementRequest,
	getAdvertisementSuccess,
	postTradeDetailsRequest,
	postTradeDetailsSuccess,
	getTradePaySellRequest,
	getTradePaySellSuccess,
	getChatRequest,
	getChatSuccess,
	putCancelTradeRequest,
	putHavePaidSellerRequest,
	putRecivedPaymentRequest,
	postChatMessageSendRequest,
	postChatMessageSendSuccess,
	postChatMessageAdminSendRequest,
	postChatMessageAdminSendSuccess,
	getDocumnetsRequest,
	getDocumnetsSuccess,
	transferSpotToP2PRequest,
	transferSpotToP2PSuccess,
	transferSpotToP2PError,
	transferP2PToSpotSuccess,
	transferP2PToSpotError,
	transferP2PToSpotRequest,
	transferCrossToP2PSuccess,
	transferCrossToP2PError,
	transferP2PToCrossSuccess,
	transferP2PToCrossError,
	transferCrossToP2PRequest,
	transferP2PToCrossRequest,
	transferIsolatedToP2PSuccess,
	transferIsolatedToP2PError,
	transferP2PToIsolatedSuccess,
	transferP2PToIsolatedError,
	transferIsolatedToP2PRequest,
	transferP2PToIsolatedRequest,
	putOpenDisputeRequest,
	postUploadEvidenceRequest,
	putEvidenceRequest,
	postAdvertisementFalure,
	p2pInitState,
	getAllNotificationRequest,
	getAllNotificationSuccess,
	getNotificationBellRequest,
	getNotificationBellSuccess,
	changeNotificationStatusRequest,
	getPaymentMethodsRequest,
	getPaymentMethodsSuccess,
	postPaymentMethodRequest,
	getPaymentMethodRequest,
	getPaymentMethodSuccess,
	deletePaymentMethodRequest,
	deletePaymentMethodError,
	changeAdvertActiveStatusRequest,
	changeAdvertActiveStatusSuccess,
	changeAdvertActiveStatusError,
	checkAdvertActiveOrderRequest,
	checkAdvertActiveOrderFinally,
	getActiveTradeRequest,
	getActiveTradeFinally,
	checkOrderAmountRequest,
	checkOrderAmountFinally,
} from './reducer';
import {
	IP2PBalancesResponse,
	IP2POpenTradesRequest,
	IP2POpenTradesResponse,
	IP2PClosedTradesResponse,
	IP2PClosedTradesRequest,
	IP2PAdevrtisementResponse,
	IP2PAdevrtisementRequest,
	IAssetsResponse,
	IRemoveOrederP2PRequest,
	IP2POrderQuickRequest,
	IP2POrderQuickResponse,
	IStatusResponse,
	IP2PCurrenciesResponse,
	IP2PAdevertisementResponse,
	IP2PAdevertisementRequest,
	IPofileRequest,
	IPofileResponse,
	IP2PPaymentResponse,
	IP2PProposalResponse,
	IP2PProposalRequest,
	IPostAdvertisementDataParams,
	IP2PUserSdvertisementResponse,
	IP2PUserSdvertisementRequest,
	IP2PTradeDetailsRequest,
	IP2PDatailsResponse,
	IP2PTradePaySellRequest,
	IP2PTradePaySellResponse,
	IP2PTradeChatResponse,
	IP2PTradeChatRequest,
	IP2PTradeChatMessgeRequest,
	IP2PTradeChatMessgeResponse,
	IP2PDocumentResponse,
	ICreateP2PUserRequest,
	IP2PTransferPayload,
	IP2PIsolatedTransferPayload,
	IP2PUploadEvidanceRequest,
	IP2PDeleteEvidanceRequest,
	IP2PNotificationRequest,
	IP2PNotificationResponse,
	IP2PNotificationBellResponse,
	INotificationStatusRequest,
	IP2PPaymentActiveMethod,
	IPaymentMethodRequest,
	IPaymentMethodResponse,
	IParamsDeleteRequest,
	IMethodRequest,
	IPostAdvertisementResp,
	IChangeAdvertActiveStatusParams,
	IP2PAdevrtisementBuySell,
	ICheckAdvertActiveOrderPayload,
	ICheckAdvertActiveOrderResponse,
	IGetActiveTradePayload,
	IGetActiveTradeResponse,
	ICheckOrderAmountPayload,
	ICheckoutAmountResponse,
} from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
// =============================================================:
function* p2pBalancesListRequestWorker() {
	try {
		yield put(showLoading());
		const response: IP2PBalancesResponse = yield call(api.p2p.getP2PWallets);

		yield put(getP2PBalancesListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors.user_id[0] === 'the_selected_user_id_is_invalid') {
				yield put(p2pInitState());
				notificationContainer(String(L.translate('Notification.p2p_disabled')), 'error');

				return;
			}
			if (error?.response?.data?.errors.user_id[0] === 'account_not_verified') {
				yield put(p2pInitState());
				notificationContainer(String(L.translate('Notification.fill_kyc_and_phone')), 'error');

				return;
			}

			responseErrors(error);
			yield put(p2pInitState());
		}
	} finally {
		yield put(hideLoading());
	}
}
function* getP2pCreateUserWorker(action: PayloadAction<ICreateP2PUserRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IStatusResponse = yield call(api.p2p.postCreateUserP2p, payload);

		if (response?.status === 'success') {
			notificationContainer(String(L.translate('Notification.user_p2p_created')), 'info');
		}
		yield put(updateStatusP2PUserSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'p2p_account_already_created') {
				notificationContainer(String(L.translate('Notification.p2p_user_exists')), 'error');

				return;
			}
			if (error?.response?.data?.errors[0] === 'account_not_verified') {
				notificationContainer(String(L.translate('Notification.fill_kyc_and_phone')), 'error');

				return;
			}
			if (
				error?.response?.data?.errors?.username[0] === 'the_username_must_be_at_least_3_characters'
			) {
				notificationContainer(String(L.translate('Notification.short_username')), 'error');

				return;
			}

			responseErrors(error);
		}
		// yield put(spotTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getP2pOpenTradesWorker(action: PayloadAction<IP2POpenTradesRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IP2POpenTradesResponse = yield call(api.p2p.getP2POpenTrades, payload);
		yield put(getP2POpenTradesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(spotTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* getP2pClosedTradesWorker(action: PayloadAction<IP2PClosedTradesRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IP2PClosedTradesResponse = yield call(api.p2p.getP2PClosedTrades, payload);

		yield put(getP2PClosedTradesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(spotTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getP2pAdvertisementWorker(action: PayloadAction<IP2PAdevrtisementRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PAdevrtisementResponse = yield call(api.p2p.getP2PAdvertisement, payload);

		yield put(getP2PAdevertisementSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(spotTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* p2pAssetsRequestWorker() {
	try {
		yield put(showLoading());
		const response: IAssetsResponse = yield call(api.p2p.getP2PAssets);

		yield put(getP2PAssetsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getP2pQuickWorker(action: PayloadAction<IP2POrderQuickRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2POrderQuickResponse = yield call(api.p2p.getP2POrderQuick, payload);

		yield put(getP2PQuickSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* p2pDeletedWorker(action: PayloadAction<IRemoveOrederP2PRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());

		yield call(api.p2p.deletedOrder, payload.id);
		const params = {
			per_page: 10,
			current_page: 1,
			type: payload.type,
		};
		yield put(getP2PAdevertisementRequest({ params }));

		yield put(getP2PDeletedSuccess(payload));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'advertisement_has_active_trades') {
				notificationContainer(String(L.translate('Notification.has_active_trades')), 'error');
				yield put(getP2PAdevertisementFailure());
				return;
			}
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getP2PCurrenciesWorker() {
	try {
		yield put(showLoading());

		const response: IP2PCurrenciesResponse = yield call(api.p2p.getCurrencies);

		yield put(getP2PCurrenciesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getAllAdvertisementWorker(action: PayloadAction<IP2PAdevertisementRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());

		const response: IP2PAdevertisementResponse = yield call(api.p2p.getAllAdvertisement, payload);

		if (payload.params.type) {
			const { type } = payload.params;
			yield put(getAllAdvertisementSuccess({ ...response, type }));
			return;
		}
		yield put(getAllAdvertisementSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getProfileWorker(action: PayloadAction<IPofileRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());

		const response: IPofileResponse = yield call(api.p2p.getProfile, payload);

		yield put(getProfileSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getPaymentWorker() {
	try {
		yield put(showLoading());

		const response: IP2PPaymentResponse = yield call(api.p2p.getPaymnetsMethod);

		yield put(getPaymentSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getProposalWorker(action: PayloadAction<IP2PProposalRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());

		const response: IP2PProposalResponse = yield call(api.p2p.getProposal, payload);

		yield put(getProposalSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* postAdvertisementWorker({ payload }: PayloadAction<IPostAdvertisementDataParams>) {
	const { onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IPostAdvertisementResp = yield call(
			payload.data.advertisement_id || payload.data.advertisement_id === 0
				? api.p2p.putP2PAdvertisement
				: api.p2p.postP2PAdvertisement,
			payload.data,
		);

		yield put(postAdvertisementSuccess());
		onSuccess(response);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(postAdvertisementFalure());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getAdvertisementWorker(action: PayloadAction<IP2PUserSdvertisementRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IP2PUserSdvertisementResponse = yield call(api.p2p.getP2PAdvertUser, payload);

		const { history } = payload;
		// history.replace('/p2p-advertisement');

		yield put(getAdvertisementSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* postTradeDetailsWorker(action: PayloadAction<IP2PTradeDetailsRequest>) {
	const { payload } = action;
	const { history, setAmount } = payload;
	try {
		yield put(showLoading());
		const response: IP2PDatailsResponse = yield call(api.p2p.postP2PTradeDetails, payload.data);

		yield put(postTradeDetailsSuccess(response));
		if (response && response?.trade_id) {
			history.replace({
				pathname: `/p2p-trading/pay-sell`,
				search: `?trade_id=${String(response?.trade_id)}`,
				state: 1,
			});
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'not_enough_money') {
				notificationContainer(String(L.translate('P2P.t_0247')), 'error');
				setAmount('');
				return;
			}
			if (error?.response?.data?.errors[0] === 'min_amount_limit_exceeded') {
				notificationContainer(String(L.translate('Notification.min_amount_limit')), 'error');
				setAmount('');
				history.replace('/');
				return;
			}
			if (error?.response?.data?.errors[0] === 'can_not_trade_with_self') {
				notificationContainer(String(L.translate('Notification.self_trade')), 'error');
				yield put(p2pInitState());
				return;
			}
			if (
				error?.response?.data?.errors[0] &&
				error?.response?.data?.errors[0].includes('you_can_buy_only')
			) {
				const newNotify: string = error?.response?.data?.errors[0].replace(
					'you_can_buy_only_',
					`${String(L.translate('Notification.you_can_buy_only'))} `,
				);
				notificationContainer(newNotify, 'error');
				yield put(p2pInitState());
				history.replace('/p2p-trade');
				return;
			}
			if (error?.response?.data?.errors[0] === 'advertisement_on_pause_please_come_back_late') {
				notificationContainer(
					String(L.translate('Notification.advertisement_on_pause_please_come_back_late')),
					'error',
				);
				yield put(p2pInitState());
				return;
			}

			responseErrors(error);
			yield put(p2pInitState());
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getTradePaySellWorker(action: PayloadAction<IP2PTradePaySellRequest>) {
	const { payload } = action;
	// const { history, setAmount } = payload;
	try {
		yield put(showLoading());
		const response: IP2PTradePaySellResponse = yield call(api.p2p.getP2PTradePaySell, payload);

		yield put(getTradePaySellSuccess(response));
		// if (response && response?.trade_id) {
		// 	history.replace(`/p2p-trading/pay-sell?trade_id=${String(response?.trade_id)}`);
		// }
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* putPaidSellerWorker(action: PayloadAction<IP2PDatailsResponse>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IP2PTradePaySellResponse = yield call(api.p2p.putP2PTradePay, payload);

		yield put(getTradePaySellSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* putTradeCancelWorker(action: PayloadAction<IP2PDatailsResponse>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.p2p.putP2PTradeCanceled, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* putRecivedPaymentWorker(action: PayloadAction<IP2PDatailsResponse>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.p2p.putP2PTradeReceived, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:

function* putOpenDisputeWorker(action: PayloadAction<IP2PDatailsResponse>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.p2p.putP2POpenDispute, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:

function* getChatWorker(action: PayloadAction<IP2PTradeChatRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PTradeChatResponse = yield call(api.p2p.getP2PChats, payload);

		yield put(getChatSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getChatMessageWorker(action: PayloadAction<IP2PTradeChatMessgeRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PTradeChatMessgeResponse = yield call(api.p2p.postP2PChatsUserSend, payload);

		yield put(postChatMessageSendSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getChatMessageAdminWorker(action: PayloadAction<IP2PTradeChatMessgeRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PTradeChatMessgeResponse = yield call(
			api.p2p.postP2PChatsAdminSend,
			payload,
		);

		yield put(postChatMessageAdminSendSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getP2PDocumnetsWorker(action: PayloadAction<IP2PDatailsResponse>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PDocumentResponse = yield call(api.p2p.getP2PEvidence, payload);

		yield put(getDocumnetsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* postP2PUploadWorker(action: PayloadAction<IP2PUploadEvidanceRequest>) {
	const { payload } = action;
	const { body, callback } = payload;
	try {
		yield put(showLoading());
		yield call(api.p2p.postP2PEvidence, body);

		yield put(callback());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* putP2PEvidenceWorker(action: PayloadAction<IP2PDeleteEvidanceRequest>) {
	const { payload } = action;
	const { id, getExistedPhotos } = payload;

	try {
		yield put(showLoading());
		yield call(api.p2p.deleteP2PEvidence, payload);

		notificationContainer(String(L.translate('Notification.file_deleted')), 'success');
		yield put(getExistedPhotos());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(L.translate('P2P.t_0274')), 'error');
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:

function* transferSpotToP2PWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IP2PTransferPayload>) {
	try {
		yield put(showLoading());

		yield call(api.p2p.transferSpotToP2P, apiParams);

		yield put(transferSpotToP2PSuccess());
		onSuccess?.();
		notificationContainer(String(L.translate('Notification.transfer_success')), 'success');
	} catch (error) {
		yield put(transferSpotToP2PError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* transferP2PToSpotWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IP2PTransferPayload>) {
	try {
		yield put(showLoading());

		yield call(api.p2p.transferP2PToSpot, apiParams);

		yield put(transferP2PToSpotSuccess());
		onSuccess?.();
		notificationContainer(String(L.translate('Notification.transfer_success')), 'success');
	} catch (error) {
		yield put(transferP2PToSpotError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* transferCrossToP2PWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IP2PTransferPayload>) {
	try {
		yield put(showLoading());

		yield call(api.p2p.transferCrossToP2P, apiParams);

		yield put(transferCrossToP2PSuccess());
		onSuccess?.();
		notificationContainer(String(L.translate('Notification.transfer_success')), 'success');
	} catch (error) {
		yield put(transferCrossToP2PError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* transferP2PToCrossWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IP2PTransferPayload>) {
	try {
		yield put(showLoading());

		yield call(api.p2p.transferP2PToCross, apiParams);

		yield put(transferP2PToCrossSuccess());
		onSuccess?.();
		notificationContainer(String(L.translate('Notification.transfer_success')), 'success');
	} catch (error) {
		yield put(transferP2PToCrossError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* transferIsolatedToP2PWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IP2PIsolatedTransferPayload>) {
	try {
		yield put(showLoading());

		yield call(api.p2p.transferIsolatedToP2P, apiParams);

		yield put(transferIsolatedToP2PSuccess());
		onSuccess?.();
		notificationContainer(String(L.translate('Notification.transfer_success')), 'success');
	} catch (error) {
		yield put(transferIsolatedToP2PError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* transferP2PToIsolatedWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IP2PIsolatedTransferPayload>) {
	try {
		yield put(showLoading());

		yield call(api.p2p.transferP2PToIsolated, apiParams);

		yield put(transferP2PToIsolatedSuccess());
		onSuccess?.();
		notificationContainer(String(L.translate('Notification.transfer_success')), 'success');
	} catch (error) {
		yield put(transferP2PToIsolatedError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getNotificationWorker(action: PayloadAction<IP2PNotificationRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IP2PNotificationResponse = yield call(api.p2p.getNotification, payload?.params);

		// notificationContainer(`The file (id: ${id}) has deleted successfully`, 'error');

		yield put(getAllNotificationSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// notificationContainer(String(L.translate('P2P.t_0274')), 'error');
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getNotificationBellWorker() {
	try {
		yield put(showLoading());
		const response: IP2PNotificationBellResponse = yield call(api.p2p.getNotificationBell);

		// notificationContainer(`The file (id: ${id}) has deleted successfully`, 'error');

		yield put(getNotificationBellSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* changeNotificationStatusWorker(action: PayloadAction<INotificationStatusRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.p2p.postNotificationStatus, { notifications: payload.notifications });

		notificationContainer(String(L.translate('Notification.notification_readed')), 'success');
		yield put(getAllNotificationRequest({ params: { search: payload.search } }));
		// yield put(getNotificationBellSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getPaymentMethodsWorker(action: PayloadAction<IP2PPaymentActiveMethod>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PPaymentResponse = yield call(api.p2p.paymentCurrencyMethod, payload);

		// notificationContainer(String(L.translate('Notification.notification_readed')), 'success');
		// yield put(getAllNotificationRequest({ params: { search: payload.search } }));
		yield put(getPaymentMethodsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* postPaymentMethodWorker(action: PayloadAction<IPaymentMethodRequest>) {
	const { payload, onError, onSuccess } = action.payload;
	try {
		yield put(showLoading());
		const response: string = yield call(api.p2p.postPaymenthMethod, payload);
		yield put(getPaymentMethodRequest({ type: String(payload?.type) }));
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'selected_payment_method_already_set') {
				notificationContainer(
					String(L.translate('Errors.selected_payment_method_already_set')),
					'error',
				);
				onError?.();
				return;
			}
			onError?.();
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getPaymentMethodWorker(action: PayloadAction<IMethodRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IPaymentMethodResponse = yield call(api.p2p.getPaymnetsMethodRequest, payload);

		yield put(getPaymentMethodSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* deletePaymentMethodWorker(action: PayloadAction<IParamsDeleteRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.p2p.deletePaymnetsMethodRequest, payload);
		yield put(getPaymentMethodRequest({ type: String(payload?.type) }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(deletePaymentMethodError());
		yield put(hideLoading());
	}
}

function* changeAdvertActiveStatusWorker(action: PayloadAction<IChangeAdvertActiveStatusParams>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PAdevrtisementBuySell = yield call(
			api.p2p.changeAdvertActiveStatus,
			payload,
		);
		yield put(changeAdvertActiveStatusSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(changeAdvertActiveStatusError());
		yield put(hideLoading());
	}
}

function* checkAdvertActiveOrderWorker({ payload }: PayloadAction<ICheckAdvertActiveOrderPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		yield put(showLoading());
		const response: ICheckAdvertActiveOrderResponse = yield call(
			api.p2p.checkAdvertActiveOrders,
			apiParams,
		);
		onSuccess?.(response.has_active_trade);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		onError?.();
	} finally {
		yield put(checkAdvertActiveOrderFinally());
		yield put(hideLoading());
	}
}

function* getActiveTradeWorker({ payload }: PayloadAction<IGetActiveTradePayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		const response: IGetActiveTradeResponse = yield call(api.p2p.getActiveTrade, apiParams);
		onSuccess?.(response);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(getActiveTradeFinally());
		yield put(hideLoading());
	}
}

// =============================================================:

function* checkOrderAmountWorker(action: PayloadAction<ICheckOrderAmountPayload>) {
	const { payload } = action;

	const { apiParams, onSuccess, onError } = payload;

	try {
		yield put(showLoading());
		const response: ICheckoutAmountResponse = yield call(api.p2p.checkOrderAmount, apiParams);

		// yield put(getAdvertisementSuccess(response));
		onSuccess?.(response);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		onError?.();
	} finally {
		yield put(hideLoading());
		yield put(checkOrderAmountFinally());
	}
}

export function* p2pSaga() {
	yield takeEvery(getP2PBalancesListRequest.type, p2pBalancesListRequestWorker);
	yield takeEvery(getP2POpenTradesRequest.type, getP2pOpenTradesWorker);
	yield takeEvery(getP2PClosedTradesRequest.type, getP2pClosedTradesWorker);
	yield takeEvery(getP2PAdevertisementRequest.type, getP2pAdvertisementWorker);
	yield takeEvery(postNickNameRequest.type, getP2pCreateUserWorker);
	yield takeEvery(getP2PAssetsRequest.type, p2pAssetsRequestWorker);
	yield takeEvery(getP2PDeletedRequest.type, p2pDeletedWorker);
	yield takeEvery(getP2PQuickRequest.type, getP2pQuickWorker);
	yield takeEvery(getP2PCurrenciesRequest.type, getP2PCurrenciesWorker);
	yield takeEvery(getAllAdvertisementRequest.type, getAllAdvertisementWorker);
	yield takeEvery(getProfileRequest.type, getProfileWorker);
	yield takeEvery(getProposalRequest.type, getProposalWorker);
	yield takeEvery(getPaymentRequest.type, getPaymentWorker);
	yield takeEvery(postAdvertisementRequest.type, postAdvertisementWorker);
	yield takeEvery(getAdvertisementRequest.type, getAdvertisementWorker);
	yield takeEvery(postTradeDetailsRequest.type, postTradeDetailsWorker);
	yield takeEvery(getTradePaySellRequest.type, getTradePaySellWorker);
	yield takeEvery(putCancelTradeRequest.type, putTradeCancelWorker);
	yield takeEvery(putHavePaidSellerRequest.type, putPaidSellerWorker);
	yield takeEvery(putRecivedPaymentRequest.type, putRecivedPaymentWorker);
	yield takeEvery(getChatRequest.type, getChatWorker);
	yield takeEvery(postChatMessageSendRequest.type, getChatMessageWorker);
	yield takeEvery(postChatMessageAdminSendRequest.type, getChatMessageAdminWorker);
	yield takeEvery(getDocumnetsRequest.type, getP2PDocumnetsWorker);

	yield takeEvery(transferSpotToP2PRequest.type, transferSpotToP2PWorker);
	yield takeEvery(transferP2PToSpotRequest.type, transferP2PToSpotWorker);
	yield takeEvery(transferCrossToP2PRequest.type, transferCrossToP2PWorker);
	yield takeEvery(transferP2PToCrossRequest.type, transferP2PToCrossWorker);
	yield takeEvery(transferIsolatedToP2PRequest.type, transferIsolatedToP2PWorker);
	yield takeEvery(transferP2PToIsolatedRequest.type, transferP2PToIsolatedWorker);

	yield takeEvery(putOpenDisputeRequest.type, putOpenDisputeWorker);
	yield takeEvery(postUploadEvidenceRequest.type, postP2PUploadWorker);
	yield takeEvery(putEvidenceRequest.type, putP2PEvidenceWorker);

	yield takeEvery(getAllNotificationRequest.type, getNotificationWorker);
	yield takeEvery(getNotificationBellRequest.type, getNotificationBellWorker);
	yield takeEvery(changeNotificationStatusRequest.type, changeNotificationStatusWorker);

	yield takeEvery(getPaymentMethodsRequest.type, getPaymentMethodsWorker);

	yield takeEvery(postPaymentMethodRequest.type, postPaymentMethodWorker);
	yield takeEvery(getPaymentMethodRequest.type, getPaymentMethodWorker);
	yield takeEvery(deletePaymentMethodRequest.type, deletePaymentMethodWorker);
	yield takeEvery(changeAdvertActiveStatusRequest.type, changeAdvertActiveStatusWorker);
	yield takeEvery(checkAdvertActiveOrderRequest.type, checkAdvertActiveOrderWorker);
	yield takeEvery(getActiveTradeRequest.type, getActiveTradeWorker);
	yield takeEvery(checkOrderAmountRequest.type, checkOrderAmountWorker);
}
