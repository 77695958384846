/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import L from 'i18n-react';
import FAQItem from './FAQItem';

const FAQ = () => {
	const [active, setActive] = useState<number | null>(null);

	const onChangeShow = (index: number) => {
		setActive((prev) => (prev === index ? null : index));
	};

	return (
		<div className="faq-list faq-list--inner-type">
			{Array(7)
				.fill(null)
				.map((_, idx) => {
					const props = {
						active,
						index: idx,
						setActive: onChangeShow,
					};
					// eslint-disable-next-line react/no-array-index-key
					return <FAQItem key={idx} {...props} />;
				})}
		</div>
	);
};

export default FAQ;
