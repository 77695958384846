import { PayloadAction } from '@reduxjs/toolkit';
import L from 'i18n-react';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { dataLayer } from 'services/gtm';
import { responseErrors } from 'services/http/responseErrors';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	checkWithdrawalAddressRequest,
	checkWithdrawalAddressSuccess,
	sendWithdrawCryptoEmailCodeRequest,
	confirmCryptoWithdrawRequest,
	confirmCryptoWithdrawSuccess,
	confirmCryptoWithdrawError,
	calculateCryptoWithdrawAmountRequest,
	calculateCryptoWithdrawAmountSuccess,
	checkAddressIsInternalRequest,
	checkAddressIsInternalSuccess,
	withdrawCryptoInitState,
} from './reducer';
import {
	ICheckWithdrawalAddressRequestPayload,
	ICheckWithdrawalAddressResponsePayload,
	IConfyrmCryptoWithdrawalRequestPayload,
	ICheckWithdrawalCryptoAmountRequestPayload,
	ICheckWithdrawalCryptoAmountResponsePayload,
	ICheckAddressIsInternalRequest,
	ICheckAddressIsInternalResponse,
	IConfirmCryptoWithdrawalResponse,
} from './types';

// =============================================================:
function* checkWithdrawAddressRequestWorker(
	action: PayloadAction<ICheckWithdrawalAddressRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ICheckWithdrawalAddressResponsePayload = yield call(
			api.withdrawCrypto.checkWithdrawalAddress,
			payload,
		);

		yield put(checkWithdrawalAddressSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawCryptoInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* sendWithdrawCryptoEmailCodeWorker() {
	try {
		yield put(showLoading());
		yield call(api.withdrawCrypto.sendWithdrawalEmailCode);

		notificationContainer(String(L.translate('Notification.code_sent_to_email')), 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.status === 429) {
				notificationContainer(String(L.translate('Notification.try_later')), 'error');
				return;
			}

			responseErrors(error);
		}
		yield put(withdrawCryptoInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* confirmWithdrawCryptoWorker(
	action: PayloadAction<IConfyrmCryptoWithdrawalRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const data: IConfirmCryptoWithdrawalResponse = yield call(
			api.withdrawCrypto.confirmCryptoWithdrawal,
			payload.data,
		);

		notificationContainer(String(L.translate('Notification.withdrawal_success')), 'success');

		payload.closeModal();

		const { id, transaction_type, amount, fee, asset_code } = data;

		dataLayer.push({
			event: 'transaction',
			transaction_id: id,
			transaction_fee: fee,
			transaction_fee_name: transaction_type,
			value: amount,
			currency: asset_code,
		});
		payload.history.push('/fiat-and-spot');

		yield put(confirmCryptoWithdrawSuccess());
		payload.onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(confirmCryptoWithdrawError());
		payload.onError?.(error);
	} finally {
		yield put(hideLoading());
		payload.setIsConfirmDesabled(false);
	}
}

// =============================================================:
function* calculateWithdrawCryptoAmountWorker(
	action: PayloadAction<ICheckWithdrawalCryptoAmountRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ICheckWithdrawalCryptoAmountResponsePayload = yield call(
			api.withdrawCrypto.calculateWalletsCryptoWithdrawal,
			payload,
		);

		if (response.residual_amount && response.residual_amount_usd) {
			notificationContainer(
				`${String(L.translate('Notification.withdrawal_limit'))}${String(
					fixedCropNumber(Number(response.residual_amount), 8),
				)} ${String(payload.asset_code.toUpperCase())} - ${String(response.residual_amount_usd)} $`,
				'info',
			);
		}

		yield put(calculateCryptoWithdrawAmountSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawCryptoInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* checkAddressIsInternalWorker(action: PayloadAction<ICheckAddressIsInternalRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ICheckAddressIsInternalResponse = yield call(
			api.withdrawCrypto.checkWithdrawalAddressIsInternal,
			payload,
		);

		yield put(checkAddressIsInternalSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawCryptoInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* withdrawCryptoSaga() {
	yield takeEvery(checkWithdrawalAddressRequest.type, checkWithdrawAddressRequestWorker);
	yield takeEvery(sendWithdrawCryptoEmailCodeRequest.type, sendWithdrawCryptoEmailCodeWorker);
	yield takeEvery(confirmCryptoWithdrawRequest.type, confirmWithdrawCryptoWorker);
	yield takeEvery(calculateCryptoWithdrawAmountRequest.type, calculateWithdrawCryptoAmountWorker);
	yield takeEvery(checkAddressIsInternalRequest.type, checkAddressIsInternalWorker);
}
