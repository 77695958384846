import { FC } from 'react';
import L from 'i18n-react';
import Dashboard from 'layouts/Dashboard';
import Repayment from 'components/MarginWalletHistory/Repayment';

// ==========================================:
const RepayHistory: FC = () => {
	return (
		<Dashboard title={String(L.translate('PageTitles.repayment'))}>
			<Repayment />
		</Dashboard>
	);
};

export default RepayHistory;
