import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ErnIcon } from 'assets/svg-icon';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import sidebarBg from 'assets/images/general/sidebar-bg.webp';

// ==========================================:
const AccountSidebar: FC = () => {
	const theme = useSelector(getColorTheme);

	const isDark = theme === 'dark';
	const colorTm = isDark ? '#FFFFFF' : '#011E26';
	return (
		<aside className="sidebar">
			<nav className="sidebar-nav">
				<ul className="sidebar-nav__list">
					<NavLink to="/general-settings" activeClassName="sidebar-nav__item--active" exact>
						<span className="sidebar-nav__link">
							<span className="sidebar-nav__icon icon-settings" />
							<span className="sidebar-nav__text">
								{String(L.translate('Account.AccountSettings.general_account_settings'))}
							</span>
						</span>
					</NavLink>
					<NavLink to="/invite-earn" activeClassName="sidebar-nav__item--active" exact>
						<span className="sidebar-nav__link">
							<span className="sidebar-nav__icon">
								<svg
									width="16"
									height="22"
									viewBox="0 0 16 22"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M14.5 18.5H10.5"
										stroke={colorTm}
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M12.5 20.5V16.5"
										stroke={colorTm}
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M8.1601 9.87C8.0601 9.86 7.9401 9.86 7.8301 9.87C5.4501 9.79 3.5601 7.84 3.5601 5.44C3.5501 2.99 5.5401 1 7.9901 1C10.4401 1 12.4301 2.99 12.4301 5.44C12.4301 7.84 10.5301 9.79 8.1601 9.87Z"
										stroke={colorTm}
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7.99004 20.8099C6.17004 20.8099 4.36004 20.3499 2.98004 19.4299C0.560039 17.8099 0.560039 15.1699 2.98004 13.5599C5.73004 11.7199 10.24 11.7199 12.99 13.5599"
										stroke={colorTm}
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							<span className="sidebar-nav__text">
								{String(L.translate('Account.AccountSettings.invite'))}
							</span>
						</span>
					</NavLink>
					<NavLink to="/auth-2fa" activeClassName="sidebar-nav__item--active" exact>
						<span className="sidebar-nav__link">
							<span className="sidebar-nav__icon icon-scan" />
							<span className="sidebar-nav__text">
								{String(L.translate('Account.TwoFactorAuth.two_factor_auth_page_app_title'))}
							</span>
						</span>
					</NavLink>
					<NavLink to="/notifications" activeClassName="sidebar-nav__item--active" exact>
						<span className="sidebar-nav__link">
							<span className="sidebar-nav__icon icon-directbox-notif" />
							<span className="sidebar-nav__text">
								{String(L.translate('Account.Notifications.notifications'))}
							</span>
						</span>
					</NavLink>
					<NavLink to="/payment-methods" activeClassName="sidebar-nav__item--active" exact>
						<span className="sidebar-nav__link">
							<span className="sidebar-nav__icon icon-card-add" />
							<span className="sidebar-nav__text">
								{String(L.translate('Account.PaymentMethods.sidemenu'))}
							</span>
						</span>
					</NavLink>
				</ul>
			</nav>
			<div className="sidebar__figure">
				<img src={sidebarBg} alt="figure" loading="lazy" />
			</div>
		</aside>
	);
};

export default AccountSidebar;
