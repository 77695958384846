/* eslint-disable react/no-unescaped-entities */
import { useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { IWalletNetworkItem } from 'redux/reducers/wallets/types';
import { getWalletsList } from 'redux/reducers/wallets/selectors';

interface Props {
	// eslint-disable-next-line react/require-default-props
	selectNetwork?: IWalletNetworkItem | null;
}

const StableCoinNotify: FC<Props> = ({ selectNetwork }) => {
	const [hideDetails, setHideDetails] = useState(true);
	const walletsList = useSelector(getWalletsList);

	const gelb = walletsList?.find((w) => w.asset.code === 'gelb');
	const eurb = walletsList?.find((w) => w.asset.code === 'eurb');
	const usdb = walletsList?.find((w) => w.asset.code === 'usdb');

	const gelbLink = gelb?.networks?.find((n) => n.network_id === 'bsc')?.contract_address_url;

	const eurbLink = eurb?.networks?.find((n) => n.network_id === 'bsc')?.contract_address_url;

	const usdbLink = usdb?.networks?.find((n) => n.network_id === 'bsc')?.contract_address_url;

	return (
		<div className="form-block">
			<div className="operation-alert-block active ">
				<div className="operation-alert">
					<div className="operation-alert__header">
						<div className="operation-alert__header-icon">
							<svg
								width="23"
								height="20"
								viewBox="0 0 23 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M3.35715 19.8849H19.5791C21.3582 19.8849 22.4362 18.6499 22.4362 17.0487C22.4362 16.5568 22.2896 16.0439 22.0281 15.5835L13.9066 1.43381C13.3624 0.481423 12.431 0 11.4681 0C10.5052 0 9.5633 0.481423 9.02958 1.43381L0.908164 15.5835C0.625589 16.0545 0.5 16.5568 0.5 17.0487C0.5 18.6499 1.57797 19.8849 3.35715 19.8849ZM3.36761 18.2418C2.63501 18.2418 2.19545 17.6767 2.19545 17.0382C2.19545 16.8394 2.23731 16.5882 2.35244 16.3684L10.4633 2.2292C10.6831 1.84197 11.0809 1.67452 11.4681 1.67452C11.8553 1.67452 12.2425 1.84197 12.4623 2.2292L20.5733 16.3788C20.6884 16.5986 20.7408 16.8394 20.7408 17.0382C20.7408 17.6767 20.2802 18.2418 19.5581 18.2418H3.36761Z"
									fill="#F9A000"
								/>
								<path
									d="M11.4682 12.831C11.9706 12.831 12.2636 12.538 12.2741 11.9938L12.4206 6.47833C12.4311 5.94457 12.0124 5.54688 11.4577 5.54688C10.8926 5.54688 10.4949 5.9341 10.5053 6.46786L10.6414 11.9938C10.6519 12.5275 10.9449 12.831 11.4682 12.831ZM11.4682 16.2324C12.0752 16.2324 12.5985 15.751 12.5985 15.1439C12.5985 14.5265 12.0857 14.0555 11.4682 14.0555C10.8507 14.0555 10.3379 14.5369 10.3379 15.1439C10.3379 15.7405 10.8612 16.2324 11.4682 16.2324Z"
									fill="#F9A000"
								/>
							</svg>
						</div>
						<p>Important Update on GEL, DOLLAR, and EURO Holdings for Bitanica Users</p>
					</div>
					<div className="operation-alert__text">
						<p>
							To our valued Bitanica community, we're announcing a key update regarding the handling
							of your GEL, DOLLAR, and EURO funds on our platform.{' '}
							<button
								type="button"
								onClick={() => setHideDetails((prev) => !prev)}
								className="button button--link"
							>
								{hideDetails ? 'Show' : 'Hide'} details
							</button>
						</p>
					</div>
				</div>

				{!hideDetails && (
					<div className="alert-content-block">
						<div className="alert-content">
							<h3 className="alert-content__title">What's New:</h3>
							<p>
								Funds added to your account in GEL, DOLLAR, and EURO will be automatically converted
								into Bitanica's corresponding stablecoin : GELb, USDb, and EURb. This conversion
								will occur at a consistent 1:1 rate. Our stablecoins, which are digital tokens,
								offer a simplified and efficient way to acquire various cryptocurrencies.
							</p>
						</div>
						<div className="alert-content">
							<h3 className="alert-content__title">Reason for the Update:</h3>
							<p>
								This change is being implemented to ensure compliance with the regulatory framework
								specific to the Georgian financial market. Please be aware that this modification is
								strictly technical.
							</p>
						</div>
						<div className="alert-content">
							<h3 className="alert-content__title">Stablecoin Contract Addresses:</h3>
							<p>For ease of access, here are the contract addresses for each stablecoin:</p>
							<div className="alert-content-extra">
								<p>
									GELb:
									<a
										href={gelbLink}
										target="blank"
										rel="noreferrer"
										className="link link--regular link--fw-500 link--underline"
									>
										Insert Contract Address
									</a>
								</p>
								<p>
									USDb:
									<a
										href={usdbLink}
										target="blank"
										rel="noreferrer"
										className="link link--regular link--fw-500 link--underline"
									>
										Insert Contract Address
									</a>
								</p>
								<p>
									EURb:
									<a
										href={eurbLink}
										target="blank"
										rel="noreferrer"
										className="link link--regular link--fw-500 link--underline"
									>
										Insert Contract Address
									</a>
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default StableCoinNotify;
