import { FC, useState } from 'react';
import L from 'i18n-react';
import * as yup from 'yup';
import { IBankAccount } from 'redux/reducers/payments/types';
import { CheckIcon, CloseIcon, EditIcon, TrashIcon } from 'assets/svg-icon';
import { notificationContainer } from 'services/utils/notificationContainer';
import { trimAddress } from 'services/utils/trimComment';
import { Field, Form, Formik } from 'formik';
import { tryOnEmpty } from 'services/utils/cardHelper';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { updateBankAccountRequest } from 'redux/reducers/payments/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';

export interface IAction {
	action: string;
	data: IBankAccount;
}

interface IPaymentItemProps {
	setAction: (data: IAction) => void;
}

const PaymentItem: FC<IBankAccount & IPaymentItemProps> = ({
	beneficiary_name,
	swift,
	iban,
	address,
	reference,
	bank_name,
	id,
	currency_code,
	status,
	setAction,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [edit, setEdit] = useState(false);
	const walletsList = useSelector(getWalletsList);

	const handleToggleEdit = () => {
		setEdit(!edit);
	};

	const handleDelete = () => {
		setAction({
			action: 'delete',
			data: {
				id,
			},
		});
	};

	const initialValues = {
		beneficiary_name: beneficiary_name || '',
		bank_name: bank_name || '',
		reference: reference || '',
		id: String(id),
		iban: iban || '',
		address: address || '',
		swift: swift || '',
	};

	const handleEditCard = (values: typeof initialValues) => {
		dispatch(
			updateBankAccountRequest({
				payload: {
					...tryOnEmpty(values),
					id: String(id),
				},
				onSuccess: () => {
					notificationContainer(
						String(L.translate('Account.PaymentMethods.edit_success')),
						'success',
					);
				},
				onError: () => {},
			}),
		);
		setEdit(false);
	};

	const validationSchema = yup.object().shape({
		// beneficiary_name: yup
		// 	.string()
		// 	.required(String(L.translate('Account.PaymentMethods.AddCard.holder_error')))
		// 	.min(3, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
		// 	.max(50, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		// card_number: yup
		// 	.string()
		// 	.required(String(L.translate('Account.PaymentMethods.AddCard.card_number_error')))
		// 	.min(19, String(L.translate('Account.PaymentMethods.AddCard.card_number_error_2'))),
	});

	const onVerifyClick = () => {
		const walletItem =
			currency_code && walletsList?.find((item) => item.asset.code === `${currency_code}b`);
		history.push({
			pathname: '/deposit',
			state: {
				currentWallet: walletItem,
				paymentMethod: 'bank_transfer',
				verifyBankTransfer: true,
			},
		});
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);
				handleEditCard(values);
			}}
		>
			{({ submitForm }) => (
				<Form>
					<div className="tr">
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.name')}
							</p>
							{edit ? (
								<div className="p2p-edit-value">
									<div className="input-wrapper">
										<Field
											name="beneficiary_name"
											className="input-item input-item--smaller"
											placeholder={L.translate('Account.PaymentMethods.tableNames.name')}
										/>
									</div>
								</div>
							) : (
								<p className="td-name">{beneficiary_name || '---'}</p>
							)}
						</div>
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.bank_name')}
							</p>
							{edit ? (
								<div className="p2p-edit-value">
									<div className="input-wrapper">
										<Field
											name="bank_name"
											className="input-item input-item--smaller"
											placeholder={L.translate('Account.PaymentMethods.tableNames.bank_name')}
										/>
									</div>
								</div>
							) : (
								<p className="td-name">{bank_name || '---'}</p>
							)}
						</div>
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.swift')}
							</p>
							{edit ? (
								<div className="p2p-edit-value">
									<div className="input-wrapper">
										<Field
											name="swift"
											className="input-item input-item--smaller"
											placeholder={L.translate('Account.PaymentMethods.tableNames.swift')}
										/>
									</div>
								</div>
							) : (
								<p className="td-name">{swift || '---'}</p>
							)}
						</div>

						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.currency')}
							</p>
							<p className="td-name">{currency_code?.toUpperCase() || '---'}</p>
						</div>

						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.iban')}
							</p>
							{edit ? (
								<div className="p2p-edit-value">
									<div className="input-wrapper">
										<Field
											name="iban"
											className="input-item input-item--smaller"
											placeholder={L.translate('Account.PaymentMethods.tableNames.iban')}
										/>
									</div>
								</div>
							) : (
								<p className="td-name">{trimAddress(String(iban)) || '---'}</p>
							)}
						</div>
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.address')}
							</p>
							{edit ? (
								<div className="p2p-edit-value">
									<div className="input-wrapper">
										<Field
											name="address"
											className="input-item input-item--smaller"
											placeholder={L.translate('Account.PaymentMethods.tableNames.address')}
										/>
									</div>
								</div>
							) : (
								<p className="td-name">{address || '---'}</p>
							)}
						</div>
						<div className="td">
							<p className="td-hidden-name">Status</p>
							<div className="table-status-block">
								<p
									className={`table-status ${
										status === 'verified' ? 'table-status--verified' : 'table-status--unverified'
									}`}
								>
									{transformFirstLetterToCapitalize(status || '')}
								</p>
								{status === 'unverified' && (
									<>
										<button
											type="button"
											className="button button--type2 button--small-height button--width-auto"
											onClick={onVerifyClick}
										>
											{L.translate('Account.PaymentMethods.verify')}
										</button>
										<div className="tooltip-item ">
											<span className="tooltip-item__icon">
												<svg
													width="25"
													height="24"
													viewBox="0 0 25 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M12.2622 22C17.7622 22 22.2622 17.5 22.2622 12C22.2622 6.5 17.7622 2 12.2622 2C6.76221 2 2.26221 6.5 2.26221 12C2.26221 17.5 6.76221 22 12.2622 22Z"
														stroke="#777E91"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M12.2622 11V16"
														stroke="#777E91"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M12.2622 8H12.2712"
														stroke="#777E91"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
											<span className="tooltip tooltip--width-320 tooltip--bottom tooltip--center ">
												<span className="tooltip__text">
													<span> {L.translate('Account.PaymentMethods.verify_tooltip')} </span>
												</span>
											</span>
										</div>
									</>
								)}
							</div>
						</div>
						<div className="td td--right">
							<p className="td-hidden-name">
								{L.translate('Account.PaymentMethods.tableNames.action')}
							</p>
							<div className="td-actions">
								{edit ? (
									<>
										<button type="button" className="td-actions-btn" onClick={submitForm}>
											<CheckIcon />
										</button>
										<button
											onClick={handleToggleEdit}
											type="button"
											className="actions-btn td-actions-btn--red"
										>
											<CloseIcon />
										</button>
									</>
								) : (
									<>
										<button onClick={handleToggleEdit} type="button" className="td-actions-btn">
											<EditIcon />
										</button>
										<button onClick={handleDelete} type="button" className="td-actions-btn">
											<TrashIcon />
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default PaymentItem;
