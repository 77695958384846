import { FC, useEffect } from 'react';
import ConvertForm from 'components/Forms/ConvertForm';
import { useDispatch } from 'react-redux';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import {
	getAssetPairsRequest,
	getAssetPairsFeesAndLimitsRequest,
} from 'redux/reducers/assetPairs/reducer';

// ==========================================:
const ConvertPage: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAssetsRequest());
		dispatch(getAssetPairsRequest());
		dispatch(getAssetPairsFeesAndLimitsRequest());
	}, [dispatch]);

	return <ConvertForm />;
};

export default ConvertPage;
