import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	getFiatFeesRequest,
	getFiatFeesSuccess,
	ourPricesInitState,
	getCryptoFeesRequest,
	getCryptoFeesSuccess,
	getP2PLimitationsRequest,
	getP2PLimitationsSuccess,
	getFiatFeesLimitsRequest,
	getFiatFeesLimitsSuccess,
	getCryptoFeesLimitsRequest,
	getCryptoFeesLimitsSuccess,
	getConvertFeesRequest,
	getConvertFeesSuccess,
	getSpotFeesRequest,
	getSpotFeesSuccess,
} from './reducer';
import {
	TFiatFees,
	TCryptoFees,
	TP2PLimitations,
	TFiatLimitsResponse,
	TCryptoLimitsResponse,
	TConvertFees,
	TSpotFees,
} from './types';

// =============================================================:
function* getFiatFeesWorker() {
	try {
		yield put(showLoading());
		const response: TFiatFees = yield call(api.ourPrices.getFiatFees);

		yield put(getFiatFeesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(ourPricesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getCryptoFeesWorker() {
	try {
		yield put(showLoading());
		const response: TCryptoFees = yield call(api.ourPrices.getCryptoFees);

		yield put(getCryptoFeesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(ourPricesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getP2PLimitationsWorker() {
	try {
		yield put(showLoading());
		const response: TP2PLimitations = yield call(api.ourPrices.getP2PLimitations);

		yield put(getP2PLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(ourPricesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getFiatFeesLimitsWorker() {
	try {
		yield put(showLoading());
		const response: TFiatLimitsResponse = yield call(api.ourPrices.getFiatLimits);

		yield put(getFiatFeesLimitsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(ourPricesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getCryptoFeesLimitsWorker() {
	try {
		yield put(showLoading());
		const response: TCryptoLimitsResponse = yield call(api.ourPrices.getCryptoLimits);

		yield put(getCryptoFeesLimitsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(ourPricesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getConvertFeesWorker() {
	try {
		yield put(showLoading());
		const response: TConvertFees = yield call(api.ourPrices.getConvertFees);

		yield put(getConvertFeesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(ourPricesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getSpotFeesWorker() {
	try {
		yield put(showLoading());
		const response: TConvertFees = yield call(api.ourPrices.getSpotFees);

		yield put(getSpotFeesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(ourPricesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* ourPricesSaga() {
	yield takeEvery(getFiatFeesRequest.type, getFiatFeesWorker);
	yield takeEvery(getCryptoFeesRequest.type, getCryptoFeesWorker);
	yield takeEvery(getP2PLimitationsRequest.type, getP2PLimitationsWorker);
	yield takeEvery(getFiatFeesLimitsRequest.type, getFiatFeesLimitsWorker);
	yield takeEvery(getCryptoFeesLimitsRequest.type, getCryptoFeesLimitsWorker);
	yield takeEvery(getConvertFeesRequest.type, getConvertFeesWorker);
	yield takeEvery(getSpotFeesRequest.type, getSpotFeesWorker);
}
