/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getUserTradesRequest } from 'redux/reducers/spotTrade/reducer';
import { useLocation } from 'react-router-dom';
import { getSpotUserTrades, getSpotUserTradesIsLoad } from 'redux/reducers/spotTrade/selectors';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { definePage } from 'services/utils/definePage';
import { navList } from 'routes/routesList';
import FilteredBar from '../../Trade/SpotHistoryTables/FilteredBar';
import TradeHistoryItem from '../TradeHistoryItem';
import { ITradeTableProps } from './types';

const TradeHitoryTable: FC<ITradeTableProps> = ({ currentTab }) => {
	const tradesHistory = useSelector(getSpotUserTrades);
	const tradesHistoryIsLoad = useSelector(getSpotUserTradesIsLoad);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const totalPages = tradesHistory?.last_page || 1;

	const dispatch = useDispatch();
	const location = useLocation<any>();

	const [currentPeriod, setCurrentPeriod] = useState<null | number>(null);
	const [calendarPeriod, setCalendarPeriod] = useState<[Date | null, Date | null]>([null, null]);

	useEffect(() => {
		if (authIsAuthenticated) {
			const [startDate, endDate] = calendarPeriod;
			if (location.state?.margin === 'cross' || location.state?.margin === 'isolated') {
				if (startDate && endDate) {
					dispatch(
						getUserTradesRequest({
							params: {
								current_page: currentPage,
								per_page: 10,
								[`${location.state.margin === 'cross' ? 'margin' : 'isolated'}`]: `${
									location.state.margin === 'cross' ? 'margin' : 'isolated'
								}`,
								period: currentPeriod,
								start_date: format(startDate, 'yyyy-MM-dd'),
								end_date: format(endDate, 'yyyy-MM-dd'),
							},
						}),
					);
					return;
				}
				if (startDate && !endDate) {
					dispatch(
						getUserTradesRequest({
							params: {
								current_page: currentPage,
								per_page: 10,
								[`${location.state.margin === 'cross' ? 'margin' : 'isolated'}`]: `${
									location.state.margin === 'cross' ? 'margin' : 'isolated'
								}`,
								period: currentPeriod,
								start_date: format(startDate, 'yyyy-MM-dd'),
								end_date: !endDate && format(startDate, 'yyyy-MM-dd'),
							},
						}),
					);
					return;
				}
				dispatch(
					getUserTradesRequest({
						params: {
							current_page: currentPage,
							per_page: 10,
							[`${location.state.margin === 'cross' ? 'margin' : 'isolated'}`]: `${
								location.state.margin === 'cross' ? 'margin' : 'isolated'
							}`,
							period: currentPeriod,
						},
					}),
				);
				return;
			}
			if (startDate && endDate) {
				dispatch(
					getUserTradesRequest({
						params: {
							current_page: currentPage,
							per_page: 10,
							period: currentPeriod,
							start_date: format(startDate, 'yyyy-MM-dd'),
							end_date: format(endDate, 'yyyy-MM-dd'),
						},
					}),
				);
				return;
			}
			if (startDate && !endDate) {
				dispatch(
					getUserTradesRequest({
						params: {
							current_page: currentPage,
							per_page: 10,
							period: currentPeriod,
							start_date: format(startDate, 'yyyy-MM-dd'),
							end_date: !endDate && format(startDate, 'yyyy-MM-dd'),
						},
					}),
				);
				return;
			}
			dispatch(
				getUserTradesRequest({
					params: {
						current_page: currentPage,
						per_page: 10,
						period: currentPeriod,
					},
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, authIsAuthenticated, currentPage, location.state]);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	const handleSearchPerPeriod = (periodValue: number | null) => {
		if (currentTab === 'cross' || currentTab === 'isolated') {
			dispatch(
				getUserTradesRequest({
					params: {
						per_page: 10,
						period: periodValue === null ? null : periodValue,
						[`${location.state.margin === 'cross' ? 'margin' : 'isolated'}`]: `${
							location.state.margin === 'cross' ? 'margin' : 'isolated'
						}`,
					},
				}),
			);
		} else {
			dispatch(
				getUserTradesRequest({
					params: {
						per_page: 10,
						period: periodValue === null ? null : periodValue,
					},
				}),
			);
		}
	};

	const hadleSearchDataPerDate = (date: [Date | null, Date | null]) => {
		const [startDate, endDate] = date;

		setCalendarPeriod([startDate, endDate]);

		if (startDate && !endDate) {
			if (currentTab === 'cross' || currentTab === 'isolated') {
				dispatch(
					getUserTradesRequest({
						params: {
							current_page: currentPage,
							per_page: 10,
							start_date: format(startDate, 'yyyy-MM-dd'),
							end_date: !endDate && format(startDate, 'yyyy-MM-dd'),
							[`${currentTab === 'cross' ? 'margin' : 'isolated'}`]: `${
								currentTab === 'cross' ? 'margin' : 'isolated'
							}`,
						},
					}),
				);
				setCurrentPeriod(null);
				return;
			}
			dispatch(
				getUserTradesRequest({
					params: {
						current_page: currentPage,
						per_page: 10,
						start_date: format(startDate, 'yyyy-MM-dd'),
						end_date: !endDate && format(startDate, 'yyyy-MM-dd'),
					},
				}),
			);

			setCurrentPeriod(null);
			return;
		}

		if (startDate && endDate) {
			if (currentTab === 'cross' || currentTab === 'isolated') {
				dispatch(
					getUserTradesRequest({
						params: {
							current_page: currentPage,
							per_page: 10,
							start_date: format(startDate, 'yyyy-MM-dd'),
							end_date: format(endDate, 'yyyy-MM-dd'),
							[`${currentTab === 'cross' ? 'margin' : 'isolated'}`]: `${
								currentTab === 'cross' ? 'margin' : 'isolated'
							}`,
						},
					}),
				);
				setCurrentPeriod(null);
				return;
			}
			dispatch(
				getUserTradesRequest({
					params: {
						current_page: currentPage,
						per_page: 10,
						start_date: format(startDate, 'yyyy-MM-dd'),
						end_date: format(endDate, 'yyyy-MM-dd'),
					},
				}),
			);
		} else {
			if (currentTab === 'cross' || currentTab === 'isolated') {
				dispatch(
					getUserTradesRequest({
						params: {
							per_page: 10,
							[`${currentTab === 'cross' ? 'margin' : 'isolated'}`]: `${
								currentTab === 'cross' ? 'margin' : 'isolated'
							}`,
						},
					}),
				);
				setCurrentPeriod(null);
				return;
			}
			dispatch(
				getUserTradesRequest({
					params: {
						current_page: currentPage,
						per_page: 10,
					},
				}),
			);
		}

		setCurrentPeriod(null);
	};

	return (
		<>
			<FilteredBar
				handleSearch={hadleSearchDataPerDate}
				period={currentPeriod}
				setCurrentPeriod={setCurrentPeriod}
				handleSearchPerPeriod={handleSearchPerPeriod}
				currentClass="table-date-filter"
			/>

			<div className="table table--spot-trade-history table--wallet-type">
				<div className="table-header table-header-td">
					<div className="tr">
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.date_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(L.translate(definePage(navList.marginTradeHistory.path, location)))}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.side_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.price_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate(
										'Trade.Spot.SpotHistoryTables.TradesHitoryTable.executed_column_name',
									),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.fee_column_name'),
								)}
							</span>
						</div>
						<div className="td td--right">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.total_column_name'),
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="table-body">
					{tradesHistoryIsLoad && (
						<div className="open-orders-history-loader-wrapper">
							<Loader />
						</div>
					)}
					{!tradesHistoryIsLoad && tradesHistory?.data?.length
						? tradesHistory?.data?.map((order) => <TradeHistoryItem key={order.id} data={order} />)
						: null}

					{!tradesHistoryIsLoad && tradesHistory?.data?.length === 0 && (
						<span className="table-empty">
							{String(
								L.translate(
									'Trade.Spot.SpotHistoryTables.TradesHitoryTable.no_trades_history_text',
								),
							)}
						</span>
					)}
				</div>
			</div>
			{totalPages > 1 && (
				<Pagination
					pageCount={totalPages}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default TradeHitoryTable;
