import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ICheckWithdrawalCryptoAmountData, IWitdrawCryptoStore } from './types';

// ==========================================:
const getWithdrawCryptoState = (state: IStoreState): IWitdrawCryptoStore => state.withdrawCrypto;
export const getWithdrawCrypto = createSelector(
	[getWithdrawCryptoState],
	(withdrawCrypto: IWitdrawCryptoStore) => withdrawCrypto,
);

// ====================================================:
export const getToWithdrawalAddressIsValid = createSelector(
	[getWithdrawCrypto],
	(withdrawCrypto: IWitdrawCryptoStore): boolean => withdrawCrypto.toWithdrawalAddresIsValid,
);

// ====================================================:
export const getCheckWithdrawalAddressLoader = createSelector(
	[getWithdrawCrypto],
	(withdrawCrypto: IWitdrawCryptoStore): boolean => withdrawCrypto.checkWithdrawalAddressLoader,
);

// ====================================================:
export const getCalculatekWithdrawalCryptoAmount = createSelector(
	[getWithdrawCrypto],
	(withdrawCrypto: IWitdrawCryptoStore): ICheckWithdrawalCryptoAmountData | null =>
		withdrawCrypto.checkWithdrawalCryptoAmount,
);

// ====================================================:
export const getAddressIsInternal = createSelector(
	[getWithdrawCrypto],
	(withdrawCrypto: IWitdrawCryptoStore): boolean => withdrawCrypto.addressIsInternal,
);
