import { FC, useRef, useState } from 'react';
import L from 'i18n-react';
import useClickOutside from 'hooks/useClickOutside';
import { IAddPaymentMethod } from './types';

const AddPaymentMethod: FC<IAddPaymentMethod> = ({ items, selectedItems, onToggle }) => {
	const ref = useRef(null);
	const [active, setActive] = useState(false);

	useClickOutside(ref, () => {
		setActive(false);
	});

	const toggleActive = () => {
		setActive((prev) => !prev);
	};

	return (
		<div className="add-bank" ref={ref}>
			<button className="button button--type2" type="button" onClick={toggleActive}>
				<span className="btn-icon">
					<svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1 7H13"
							stroke="#00DA83"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7 13V1"
							stroke="#00DA83"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				{String(L.translate('Global.add'))}
			</button>
			<div className={`dropdown ${active ? 'active' : ''}`} ref={ref}>
				<ul>
					{items.map((item) => (
						<li>
							<div className="checkbox checkbox--margin-none">
								<label className="checkbox__label">
									<input
										type="checkbox"
										className="hidden"
										checked={selectedItems.map((_item) => _item.id).includes(item.id)}
										onChange={() => onToggle(item)}
									/>
									<span className=" checkbox__item">
										<span className="checkbox__item-icon">
											<span className="icon-Checkbox" />
										</span>
									</span>
									<span className="checkbox__text">{item.payment_method.payment_name_eng}</span>
								</label>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default AddPaymentMethod;
