import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getWalletsAddress, getWalletsList } from 'redux/reducers/wallets/selectors';
import { IWalletItem, IWalletNetworkItem } from 'redux/reducers/wallets/types';
import { clearWalletAddress, getGenerateAddressRequest } from 'redux/reducers/wallets/reducer';
import { isStablecoin } from 'services/utils/wallets';
import { dataLayer } from 'services/gtm';
import DCDropdown from 'ui/DCDropdown';
import DCNDropdown from 'ui/DCNDropdown';
import { IStateDepositLocation, ICreateDepositCrypto } from './types';

// ==========================================:
const CreateDepositCrypto: FC<ICreateDepositCrypto> = ({
	selectWallet,
	walletNetworks,
	selectWalletNetworks,
	setSelectWalletNetworks,
}) => {
	const [copyAddressStatus, setAddressCopyStatus] = useState({ copied: false });
	const [copyMemoStatus, setMemoCopyStatus] = useState({ copied: false });

	const dispatch = useDispatch();
	const history = useHistory();
	const walletsAddress = useSelector(getWalletsAddress);

	let closeCopyMessage: ReturnType<typeof setTimeout>;

	const handleNetworkSelect = (value: IWalletNetworkItem) => {
		setSelectWalletNetworks(value);

		const walletData = {
			id: Number(selectWallet?.id),
			network: String(value?.network_id),
		};

		dispatch(getGenerateAddressRequest(walletData));
	};

	const handleAddressCopy = () => {
		dataLayer.push({
			event: 'deposit_crypto',
		});
		setAddressCopyStatus({ copied: true });

		closeCopyMessage = setTimeout(() => {
			setAddressCopyStatus({ copied: false });
		}, 2000);
	};

	const handleMemoCopy = () => {
		setMemoCopyStatus({ copied: true });

		closeCopyMessage = setTimeout(() => {
			setMemoCopyStatus({ copied: false });
		}, 2000);
	};

	useEffect(() => clearTimeout(closeCopyMessage));

	return (
		<>
			<div className="form-block">
				<div className="enter-value">
					<span className="block-label block-label--grey">
						{String(L.translate('DepositCrypto.deposit_to'))}
					</span>
					<span className="enter-value__label enter-value__label--padding">
						{String(L.translate('DepositCrypto.network'))}
					</span>
					<div className="enter-value__field">
						<DCNDropdown
							value={selectWalletNetworks}
							onChange={handleNetworkSelect}
							options={walletNetworks}
						/>
					</div>
				</div>
			</div>
			{walletsAddress && (
				<>
					<div className="form-block">
						<span className="enter-value__label enter-value__label--padding">
							{String(L.translate('DepositCrypto.address'))}
						</span>
						<div className="enter-value__field enter-value__field--column">
							{copyAddressStatus?.copied ? (
								<>
									<div className="address-copy-wrapper">
										<p className="address-copy-text">
											{String(L.translate('DepositCrypto.address_copied'))}
										</p>
									</div>
								</>
							) : (
								<span className="enter-value__text">{walletsAddress?.address}</span>
							)}
							<CopyToClipboard text={walletsAddress?.address} onCopy={handleAddressCopy}>
								<button type="button" className="copy-btn">
									<span className="copy-btn__icon icon-fi_copy" />
									<span className="copy-btn__text">
										{String(L.translate('DepositCrypto.click_to_copy'))}
									</span>
								</button>
							</CopyToClipboard>
						</div>
					</div>
					<div className="qr-code">
						<div className="qr-code__img qr-code__img--big">
							<img
								src={walletsAddress?.address_qr}
								alt=""
								width="182"
								height="182"
								loading="lazy"
							/>
						</div>
						<div className="qr-code__main qr-code__main--gap">
							{walletsAddress?.tag && (
								<>
									<div className="qr-code__info">
										<span className="qr-code__info-title">
											{String(L.translate('DepositCrypto.memo'))}
										</span>
										<span className="qr-code__info-desc qr-code__info-desc--big">
											{copyMemoStatus?.copied ? (
												<>
													<div className="address-copy-wrapper">
														<p className="address-copy-text">
															{String(L.translate('DepositCrypto.memo_copied'))}
														</p>
													</div>
												</>
											) : (
												<>{walletsAddress?.tag}</>
											)}
											<CopyToClipboard text={walletsAddress?.tag} onCopy={handleMemoCopy}>
												<button type="button" className="copy-btn copy-btn--small">
													<span className="copy-btn__icon icon-fi_copy" />
												</button>
											</CopyToClipboard>
										</span>
									</div>
									<div className="input-notify input-notify--second-type">
										<span className="input-notify__char icon-info-circle" />
										<span className="input-notify__text">
											{String(L.translate('DepositCrypto.memo_is_required'))}
										</span>
									</div>
								</>
							)}
							<div className="qr-code__info">
								<span className="qr-code__info-title">
									{String(L.translate('DepositCrypto.minimum_deposit'))}
								</span>
								<span className="qr-code__info-desc">0.00000001</span>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default CreateDepositCrypto;
