import { FC, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { notificationContainer } from 'services/utils/notificationContainer';
// import types from '../../../../../redux/types';
// import borrow_amount from '../../../../../services/notification';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { numberValidation } from 'services/utils/numberValidation';
import { postTakeBorrowRequest } from 'redux/reducers/marginWallets/reducer';
import { ITakeBorrow } from 'redux/reducers/marginWallets/types';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { getMarginCrossWalletsList } from 'redux/reducers/marginWallets/selectors';
import SearchInput from 'ui/SearchInput';
import PercentRadioButtons from '../../../TradesBox/PercentRadioButtons';
import SuccessPopup from '../../SuccessPopup';
import { PendingModal } from '../../SuccessPopup/Pending';
import { ICrossRepayProps } from './types';

const CrossRepay: FC<ICrossRepayProps> = ({ assetCode, closeModal }) => {
	const [code, setCode] = useState<string>(assetCode || 'btc');
	const [amount, setAmount] = useState(''); // amount actions
	const [isDisabled, setIsDisabled] = useState(false);
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const crossWallet = useSelector(getMarginCrossWalletsList);

	const marginWallets = authIsAuthenticated ? crossWallet : null;
	const listMarginCoins = marginWallets?.data?.cross;
	const wallet = listMarginCoins?.find((el) => el.asset.code === code);

	const changeAmount = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		if (numberValidation(value)) {
			setAmount(value);
		}
	};

	const handleSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
		const codeCoin = e?.currentTarget?.dataset?.code;
		if (codeCoin) {
			setCode(codeCoin);
		}
		setAmount('');
	};
	const handleSubmit = () => {
		if (Number(wallet?.interest_amount) + Number(wallet?.borrow) < +amount) {
			notificationContainer(String(L.translate(`Errors.amount_bigger_than_balance`)), 'error');
			return;
		}
		// if (Number(wallet?.balance) < Number(wallet?.interest_amount) + Number(wallet?.borrow)) {
		// 	notificationContainer(String(L.translate('Debt is bigger than available balance!')), 'error');
		// 	return;
		// }

		if (!amount) {
			notificationContainer(String(L.translate(`Errors.borrow_amount`)), 'error');
			return;
		}

		setIsDisabled(true);

		const payload: ITakeBorrow = {
			closeModal,
			setIsDisabled,
			onSuccess: () => {
				setIsDisabled(false);
				notificationContainer(String(L.translate('Trade.Margin.SuccessMsg.repay')), 'success');
			},
			type: 'repay',
			params: {
				assetId: Number(wallet?.asset?.id),
				amount,
			},
		};

		dispatch(postTakeBorrowRequest(payload));
		setAmount('');
	};
	const countOrder = (value: number) => {
		if (value) {
			setAmount(String(value));
		}
	};
	const percentButtonCountValue = (percentValue: number): number => {
		if (Number(wallet?.balance) < Number(wallet?.interest_amount) + Number(wallet?.borrow)) {
			const fixPrice = Number(wallet?.balance) * percentValue;
			return fixPrice;
		}
		const fixPrice = (Number(wallet?.interest_amount) + Number(wallet?.borrow)) * percentValue;

		return fixPrice < 0 ? 0 : fixPrice;
	};

	return (
		<div className="popup-body popup-body--margin-none">
			<div className="transfer-form">
				<div className="transfer-form__select select-block">
					<SearchInput
						listCoins={listMarginCoins}
						onSelect={handleSelect}
						coin={listMarginCoins?.find((el) => el.asset.code === code)}
					/>
				</div>
				<div className="withdrawal-options withdrawal-options--pd0">
					<div className="withdrawal-option">
						<span className="withdrawal-option__title">
							{String(L.translate(`Trade.Margin.MarginPopup.Repay.margin_balance`))}:
						</span>
						<span className="withdrawal-option__desc">
							{wallet?.balance || 0} {code?.toUpperCase()}
						</span>
					</div>
					<div className="withdrawal-option">
						<span className="withdrawal-option__title">
							{String(L.translate(`Trade.Margin.MarginPopup.Repay.margin_interest`))}:
						</span>
						<span className="withdrawal-option__desc">
							{wallet?.interest_amount} {code?.toUpperCase()}
						</span>
					</div>
					<div className="withdrawal-option">
						<span className="withdrawal-option__title">
							{String(L.translate(`Trade.Margin.MarginPopup.Repay.margin_borrowed`))}:
						</span>
						<span className="withdrawal-option__desc">
							{convertExponentialToDecimal(
								Number(Number(wallet?.borrow) < 0 ? 0 : wallet?.borrow) || 0,
							)}{' '}
							{code.toUpperCase()}
						</span>
					</div>
					<div className="withdrawal-option">
						<span className="withdrawal-option__title">
							{String(L.translate(`Trade.Margin.MarginPopup.Repay.margin_total`))}:
						</span>
						<span className="withdrawal-option__desc">
							{convertExponentialToDecimal(
								Number(
									Number(wallet?.interest_amount || 0) +
										Number(Number(wallet?.borrow) < 0 ? 0 : wallet?.borrow || 0),
								),
							)}{' '}
							{code.toUpperCase()}
						</span>
					</div>
				</div>
				<div className="transfer-form__select select-block transfer-form__select--box">
					<div className="transfer-form__textbox">
						<p className="select-block__name">
							{String(L.translate(`Trade.Margin.MarginPopup.amount`))}
						</p>
						<p className="transfer-form__amount">
							<span className="transfer-form__count">
								{wallet?.balance || 0} {code.toUpperCase()}
							</span>
							<span> {String(L.translate(`Trade.Margin.MarginPopup.available`))}</span>
						</p>
					</div>
					{Number(wallet?.borrow) <= 0 ? (
						<div className="info-hint">
							<span className="info-hint__icon info-hint__icon--svg">
								<svg
									className="fill"
									width="14"
									height="20"
									viewBox="0 0 14 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M3.82057 17.7477C3.82097 17.9934 3.89494 18.234 4.03375 18.4387L4.71347 19.4422C4.82958 19.6137 4.98707 19.7544 5.17193 19.8518C5.35679 19.9491 5.56327 20 5.77301 20H8.22739C8.43713 20 8.64361 19.9491 8.82847 19.8518C9.01332 19.7544 9.17082 19.6137 9.28693 19.4422L9.96665 18.4387C10.1054 18.234 10.1796 17.9936 10.1798 17.7477L10.1814 16.2497H3.81858L3.82057 17.7477ZM0 6.87489C0 8.60805 0.654261 10.1893 1.7325 11.3974C2.38955 12.1338 3.41727 13.672 3.80903 14.9696C3.81062 14.9798 3.81182 14.99 3.81341 15.0001H10.1866C10.1882 14.99 10.1894 14.9802 10.191 14.9696C10.5827 13.672 11.6105 12.1338 12.2675 11.3974C13.3457 10.1893 14 8.60805 14 6.87489C14 3.07067 10.8544 -0.0116852 6.97812 3.33031e-05C2.92091 0.0121424 0 3.24098 0 6.87489ZM7 3.74995C5.24563 3.74995 3.81818 5.15188 3.81818 6.87489C3.81818 7.22019 3.53341 7.49987 3.18182 7.49987C2.83023 7.49987 2.54545 7.22019 2.54545 6.87489C2.54545 4.46244 4.54364 2.49998 7 2.49998C7.35159 2.49998 7.63636 2.77966 7.63636 3.12497C7.63636 3.47027 7.35159 3.74995 7 3.74995Z"
										fill="#E84545"
									/>
								</svg>
							</span>
							<p className="info-hint__text">
								{String(L.translate(`Trade.Margin.MarginPopup.Repay.margin_yet`))}{' '}
								{code?.toUpperCase()}.
							</p>
						</div>
					) : (
						<>
							<div className="input ">
								<label>
									<div className="input-wrapper">
										<input
											className="input-item input-item--right-pd"
											type="text"
											placeholder="0.00000000"
											value={amount}
											onChange={changeAmount}
										/>
										{/* <span onClick={handleMaxAmount} className="input-custom-max">
											MAX
										</span> */}
									</div>
								</label>
							</div>

							<PercentRadioButtons
								countOrder={countOrder}
								percentButtonCountValue={percentButtonCountValue}
								mode="none"
								amount={amount}
								precents={[0.25, 0.5, 0.75, 1]}
							/>
						</>
					)}
				</div>

				<button
					type="button"
					onClick={handleSubmit}
					disabled={isDisabled || !Number(amount)}
					className="button button--full-width"
				>
					{L.translate(`Global.confirm`)}
				</button>
			</div>
		</div>
	);
};

export default CrossRepay;
