import { FC, useState } from 'react';
import L from 'i18n-react';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { IBalancerArrayData } from 'redux/reducers/referral/types';
import {
	updateCardRequest,
	verifyCardRequest,
	getCardsRequest,
} from 'redux/reducers/cards/reducer';
import { getVerifyLoading } from 'redux/reducers/cards/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import VerifyPopup from 'ui/VerifyCardPopup';

export interface IAction {
	action: boolean;
	asset_id: number | string;
}

interface IPaymentItemProps {
	setAction: (data: IAction) => void;
}

const Item: FC<IBalancerArrayData & IPaymentItemProps> = ({
	asset,
	asset_id,
	total_balance,
	current_balance,
	id,
	setAction,
}) => {
	const dispatch = useDispatch();
	const [edit, setEdit] = useState(false);
	const [verifyConfirmOpen, setVerifyConfirmOpen] = useState(false);
	const [verifyOpen, setVerifyOpen] = useState(false);
	const [cvc, setCvc] = useState('');
	const verifyLoading = useSelector(getVerifyLoading);
	const theme = useSelector(getColorTheme);

	const isDark = theme === 'dark';

	const handleClaim = () => {
		setAction({ action: true, asset_id });
	};

	const handleVerify = () => {
		dispatch(
			verifyCardRequest({
				apiParams: { card_id: id, cvc: +cvc },
				onSubmit: () => {
					dispatch(getCardsRequest());
					setVerifyOpen(false);
				},
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Coin</p>
				<div className="balance-currency balance-currency--center">
					<div className="balance-currency__img">
						<img src={isDark ? asset.dark_img_path : asset.img_path} alt="" />
					</div>
					<div className="balance-currency__info">
						<span className="balance-currency__title">{asset?.code.toUpperCase()}</span>
						<span className="balance-currency__desc">{asset.name}</span>
					</div>
				</div>
			</div>
			<div className="td  ">
				<p className="td-hidden-name">Total assets</p>
				<span className="td-name td-name--regular">
					{current_balance}
					{/* <!-- <span className="td-name td-name--subtext"> ≈ $ 0</span> --> */}
				</span>
			</div>
			<div className="td td--right">
				<div className="link-group">
					<button
						type="button"
						onClick={handleClaim}
						className="link link--underline link--regular link--fw-500"
					>
						Claim
					</button>
				</div>
			</div>
		</div>
	);
};

export default Item;
