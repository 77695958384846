/* eslint-disable import/no-unresolved */
import { FC, useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import L from 'i18n-react';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getTopPairsWithChart } from 'redux/reducers/assetPairs/selectors';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { IApiTopPairWithChart } from 'services/api/assetPairs/types';
import { randomSeveralFromRange } from 'services/utils/numberHelpers';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import heroFigure1 from 'assets/images/content/hero-figure1.svg';
import heroFigure2 from 'assets/images/content/hero-figure2.svg';
import heroFigure1Dark from 'assets/images/content/hero-figure1__dark.png';
import heroFigure2Dark from 'assets/images/content/hero-figure2__dark.png';
import mobilePic from 'assets/images/content/mobile-pic.webp';
import hero1PC from 'assets/images/content/hero1-pc.png';
import hero1Mob from 'assets/images/content/hero1-mob.png';
import hero1PCDark from 'assets/images/content/hero1-pc__dark.png';
import hero1MobDark from 'assets/images/content/hero1-mob__dark.png';
import heroFigure1Type2 from 'assets/images/content/hero-figure1-type2.svg';
import heroFigure2Type2 from 'assets/images/content/hero-figure2-type2.svg';
import heroFigure1Type2Dark from 'assets/images/content/hero-figure1-type2__dark.png';
import heroFigure2Type2Dark from 'assets/images/content/hero-figure2-type2__dark.png';
import hero2PC from 'assets/images/content/hero2-pc.png';
import hero2Mob from 'assets/images/content/hero2-mob.png';
import hero2PCDark from 'assets/images/content/hero2-pc__dark.png';
import hero2MobDark from 'assets/images/content/hero2-mob__dark.png';
import hero31 from 'assets/images/content/hero3-1.png';
import hero31Dark from 'assets/images/content/hero3-1__dark.png';
import hero3Mob1 from 'assets/images/content/hero3-mob-1.png';
import hero3Mob2 from 'assets/images/content/hero3-mob-2.png';
import hero3Mob2Dark from 'assets/images/content/hero3-mob-2__dark.png';

const BuySellCrypto: FC = () => {
	const isAuth = useSelector(getAuthIsAuthenticated);
	const topPairs = useSelector(getTopPairsWithChart);

	const prevRef = useRef<HTMLButtonElement | null>(null);
	const nextRef = useRef<HTMLButtonElement | null>(null);

	const [pairIdsToShow, setPairIdsToShow] = useState<number[]>([]);
	const [sliderIdx, setSliderIdx] = useState(0);

	const theme = useSelector(getColorTheme);

	const isDark = theme === 'dark';

	const validPairs = useMemo(() => {
		if (!topPairs) return [];
		return topPairs.filter(({ change24h }, index) => index <= 3 || change24h);
	}, [topPairs]);

	const pairsToShow = useMemo(() => {
		const pairs = pairIdsToShow
			.map((showId) => validPairs.find(({ id }) => showId === id))
			.filter((pair) => pair);

		return pairs as IApiTopPairWithChart[];
	}, [pairIdsToShow, validPairs]);

	useEffect(() => {
		if (pairsToShow.length < 4 && validPairs.length > pairsToShow.length) {
			const rndIndexes = randomSeveralFromRange(validPairs.length, 4);
			const rndPairIds = rndIndexes.map((index) => validPairs[index].id);

			setPairIdsToShow(rndPairIds);
		}
	}, [pairsToShow, validPairs]);

	const getCurrentSlideIdx = useCallback(() => {
		let index = 0;
		const slides = document.querySelectorAll('.mySwiper .swiper-slide');
		slides.forEach((curr, idx) => {
			if (curr.classList.contains('swiper-slide-active')) {
				index = idx;
			}
		});
		return index;
	}, []);
	useEffect(() => {
		const swiper = new Swiper('.mySwiper', {
			modules: [Navigation],
			autoHeight: true,
			navigation: {
				nextEl: '.slider-btn--next',
				prevEl: '.slider-btn--prev',
			},
		});
		swiper.on('slideChangeTransitionEnd', function () {
			setSliderIdx(getCurrentSlideIdx());
		});
	}, [getCurrentSlideIdx]);

	const getPairChange24Class = (change24: number) => {
		const classes = ['item-stats__value'];
		if (change24 >= 0) {
			classes.push('item-stats__value--green');
		}
		return classes.join(' ');
	};

	const formatPairChange24 = (change24: number) => {
		const sign = change24 >= 0 ? '+' : '';
		return `${sign}${change24.toFixed(2)}%`;
	};

	const handleIndicatorClick = (index: number) => {
		if (index < sliderIdx) {
			const count = sliderIdx - index;
			Array(count)
				.fill(count)
				.forEach(() => {
					prevRef.current?.click();
				});
		}
		if (sliderIdx < index) {
			const count = index - sliderIdx;
			Array(count)
				.fill(count)
				.forEach(() => {
					nextRef.current?.click();
				});
		}
	};

	return (
		<div className="home-slider-box">
			<div className="swiper mySwiper swiper-container home-slider">
				<div className="swiper-wrapper">
					<div className="swiper-slide">
						<div className="hero hero--first">
							<div className="hero__content">
								<div className="hero-figure hero-figure--first">
									<img src={heroFigure1} className="for-light" alt="figure" />
									<img src={heroFigure1Dark} className="for-dark" alt="figure" />
								</div>
								<div className="hero-figure hero-figure--second">
									<img src={heroFigure2} className="for-light" alt="figure" />
									<img src={heroFigure2Dark} className="for-dark" alt="figure" />
								</div>
								<div className="container">
									<div className="hero-info">
										<h2 className="hero-info__title ">
											{L.translate('HomePage.BuySellCrypto.Slide_1.title')}
											<span>{L.translate('HomePage.BuySellCrypto.Slide_1.subtitle')}</span>
										</h2>
										{!isAuth && (
											<Link
												to="/registration"
												className="button button--new button--new-type1 hero-info__register-btn Click_Register_now"
												aria-label="registration"
											>
												{L.translate('HomePage.BuySellCrypto.Slide_1.register_btn')}
											</Link>
										)}
									</div>
									<div className="hero-img hero-img--first">
										<div className="hero-img__item hero-img__item--type1">
											<img src={hero1PC} className="for-light" alt="" />
											<img src={hero1PCDark} className="for-dark" alt="" />
										</div>
										<div className="hero-img__item hero-img__item--type2 ">
											<img src={hero1Mob} className="for-light" alt="" />
											<img src={hero1MobDark} className="for-dark" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="swiper-slide">
						<div className="hero hero--second">
							<div className="hero__content">
								<div className="hero-figure hero-figure--first">
									<img src={heroFigure1Type2} className="for-light" alt="figure" />
									<img src={heroFigure1Type2Dark} className="for-dark" alt="figure" />
								</div>
								<div className="hero-figure hero-figure--second">
									<img src={heroFigure2Type2} className="for-light" alt="figure" />
									<img src={heroFigure2Type2Dark} className="for-dark" alt="figure" />
								</div>
								<div className="container">
									<div className="hero-info">
										<h2 className="hero-info__title">
											{L.translate('HomePage.BuySellCrypto.Slide_2.title')}
											<span className="hero-info__title-value">
												{L.translate('HomePage.BuySellCrypto.Slide_2.description_1')}{' '}
											</span>
											<span className="hero-info__title-value">
												{L.translate('HomePage.BuySellCrypto.Slide_2.description_2')}{' '}
											</span>
											<span className="hero-info__title-value">
												{L.translate('HomePage.BuySellCrypto.Slide_2.description_3')}{' '}
											</span>
										</h2>
										{!isAuth && (
											<Link
												to="/registration"
												className="button button button--new button--new-type1 hero-info__register-btn Click_Register_now"
												aria-label="registration"
											>
												{L.translate('HomePage.register_button')}
											</Link>
										)}
									</div>
									<div className="hero-img hero-img--second">
										<div className="hero-img__item hero-img__item--pc">
											<picture>
												<source
													media="(min-width: 576px)"
													srcSet={`${isDark ? hero2PCDark : hero2PC}`}
												/>
												<img src="" alt="hero2PC" />
											</picture>
										</div>
										<div className="hero-img__item hero-img__item--mob ">
											<picture>
												<source
													media="(max-width: 575px)"
													srcSet={`${isDark ? hero2MobDark : hero2Mob}`}
												/>
												<img src="" alt="hero2Mob" />
											</picture>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="swiper-slide">
						<div className="hero hero--third">
							<div className="hero__content">
								<div className="hero-figure hero-figure--first">
									<img src={heroFigure1} className="for-light" alt="figure" />
									<img src={heroFigure1Dark} className="for-dark" alt="figure" />
								</div>
								<div className="hero-figure hero-figure--second">
									<img src={heroFigure2} className="for-light" alt="figure" />
									<img src={heroFigure2Dark} className="for-dark" alt="figure" />
								</div>
								<div className="container">
									<div className="hero-info">
										<h2 className="hero-info__title ">
											{L.translate('HomePage.BuySellCrypto.Slide_3.title')}&nbsp;
											<span>
												{L.translate('HomePage.BuySellCrypto.Slide_3.description_1')}&nbsp;
											</span>
											<span>{L.translate('HomePage.BuySellCrypto.Slide_3.description_2')}</span>
										</h2>
										{!isAuth && (
											<Link
												to="/registration"
												className="button button button--new button--new-type1 hero-info__register-btn Click_Register_now"
												aria-label="registration"
											>
												{L.translate('HomePage.BuySellCrypto.Slide_3.conquer')}
											</Link>
										)}
									</div>
									<div className="hero-img hero-img--third">
										<div className="hero-img__item hero-img__item--pc">
											<img src={hero31} className="for-light" alt="" />
											<img src={hero31Dark} className="for-dark" alt="" />
										</div>
										<div className="hero-img__item hero-img__item--mob hero-img__item--type1 ">
											<img src={hero3Mob1} alt="" />
										</div>
										<div className="hero-img__item hero-img__item--mob hero-img__item--type2 ">
											<img src={hero3Mob2} className="for-light" alt="" />
											<img src={hero3Mob2Dark} className="for-dark" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button
				type="button"
				className="slider-btn slider-btn--prev"
				ref={prevRef}
				aria-label="slider button previous"
			>
				<svg
					width="6"
					height="10"
					viewBox="0 0 6 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6 9.32615L6 0.67383C6 0.0747082 5.23363 -0.22532 4.78543 0.198308L0.20835 4.5245C-0.0694504 4.78707 -0.0694504 5.21293 0.20835 5.4755L4.78543 9.80164C5.23363 10.2254 6 9.92529 6 9.32615Z"
						fill="#01194D"
					/>
				</svg>
			</button>
			<button
				type="button"
				className="slider-btn slider-btn--next"
				ref={nextRef}
				aria-label="slider button next"
			>
				<svg
					width="6"
					height="10"
					viewBox="0 0 6 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 0.673855L0 9.32617C0 9.92529 0.766372 10.2253 1.21457 9.80169L5.79165 5.4755C6.06945 5.21293 6.06945 4.78707 5.79165 4.5245L1.21457 0.198356C0.766372 -0.225358 0 0.0747118 0 0.673855Z"
						fill="#01194D"
					/>
				</svg>
			</button>
			<div className="home-slider-indicators">
				<div className="container">
					<div className="slider-indicators">
						<div className="slider-indicator" onClick={() => handleIndicatorClick(0)}>
							<span
								className="slider-indicator__progress"
								style={{ width: sliderIdx === 0 ? '100%' : 0 }}
							/>
						</div>
						<div className="slider-indicator" onClick={() => handleIndicatorClick(1)}>
							<span
								className="slider-indicator__progress"
								style={{ width: sliderIdx === 1 ? '100%' : 0 }}
							/>
						</div>
						<div className="slider-indicator" onClick={() => handleIndicatorClick(2)}>
							<span
								className="slider-indicator__progress"
								style={{ width: sliderIdx === 2 ? '100%' : 0 }}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BuySellCrypto;
