/* eslint-disable no-debugger */
import { FC, ChangeEvent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
	getCurrentType,
	getPaymentAmount,
	getActiveTradeLoader,
} from 'redux/reducers/p2p/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { setPaymentAmount, getActiveTradeRequest } from 'redux/reducers/p2p/reducer';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import AlreadyTradingPopup from '../AlreadyTradingPopup';
import { IP2PTradeModal } from './types';

const P2PTradeModal: FC<IP2PTradeModal> = ({ item, selectItem, handleAction, onClose }) => {
	const dispatch = useDispatch();
	const {
		id,
		username,
		user_id,
		total_trades,
		successful_trades,
		current_fiat_price,
		current_fiat_code,
		amount_left,
		amount_min,
		amount_max,
		payment_window,
		status,
		code,
	} = item;
	const history = useHistory();
	const type = useSelector(getCurrentType);
	const amount = useSelector(getPaymentAmount);
	const userData = useSelector(getUserSettingsData);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const activeTradeLoader = useSelector(getActiveTradeLoader);
	const [alreadyTradingOpen, setAlreadyTradingOpen] = useState(false);
	const [activeTrade, setActiveTrade] = useState<string | null>(null);

	const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (!/^\d*\.?\d*$/.test(value)) {
			return;
		}
		dispatch(setPaymentAmount(value));
	};

	const handleAllClick = () => {
		dispatch(setPaymentAmount(amount_max));
	};

	const validateAndHandleAction = (e: any) => {
		const { currentTarget } = e;

		if (!authIsAuthenticated) {
			history.push({
				pathname: `/login`,
				search: `redirect=/p2p-trade`,
			});
			return;
		}

		dispatch(
			getActiveTradeRequest({
				apiParams: {
					second_user_id: user_id,
				},
				onSuccess: (response) => {
					if (response.has_active_trade && response.trade_id) {
						setActiveTrade(response.trade_id);
						setAlreadyTradingOpen(true);
						return;
					}
					if (userData && userData?.status.name === 'unverified') {
						notificationContainer(String(L.translate('P2P.need_verify')), 'info');
						return;
					}
					if (Number(amount) === 0 || amount === '') {
						notificationContainer(String(L.translate('P2P.t_0398')), 'error');
						return;
					}
					if (Number(amount) < Number(amount_min) || Number(amount) > Number(amount_max)) {
						notificationContainer(String(L.translate('P2P.t_0399')), 'error');
						return;
					}
					e.currentTarget = currentTarget;
					handleAction(e);
				},
			}),
		);
	};

	return (
		<>
			<div className="p2p-window">
				<div className="p2p-table-order">
					<div className="p2p-table-order__group">
						<div className="p2p-provider-info">
							<div className="p2p-provider">
								<div className="p2p-provider__letter">{username[0].toUpperCase()}</div>
								<NavLink
									onClick={handleAction}
									to={{
										pathname: '/p2p-user-center',
										state: { currency: selectItem },
										search: `profilename=${username}`,
									}}
									className="p2p-provider__name"
								>
									{username}
								</NavLink>
								<span className="p2p-provider__check">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="8"
										height="6"
										viewBox="0 0 8 6"
										fill="none"
									>
										<path
											d="M1.16699 2.99995L3.05366 4.88661L6.83366 1.11328"
											stroke="white"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
							</div>
							<div className="p2p-provider-details">
								<p>
									{total_trades} {String(L.translate('P2P.t_0095'))}
								</p>
								<p>
									{((successful_trades * 100) / total_trades).toFixed(2)}%{' '}
									{String(L.translate('P2P.t_0096'))}
								</p>
							</div>
						</div>
						<div className="p2p-order-info">
							<div className="p2p-order-info__group">
								<span className="p2p-order-info__title">{String(L.translate('P2P.t_0093'))}:</span>
								<span className="p2p-order-info__desc">
									{current_fiat_price} {current_fiat_code.toUpperCase()}
								</span>
							</div>
							<div className="p2p-order-info__group">
								<span className="p2p-order-info__title">
									{String(L.translate('P2P.Marketplace.payment_time_limit'))}:
								</span>
								<span className="p2p-order-info__desc">
									{payment_window} {String(L.translate('P2P.Marketplace.15_minutes'))}
								</span>
							</div>
						</div>
						<div className="p2p-order-info">
							<div className="p2p-order-info__group p2p-order-info__group--type2">
								<span className="p2p-order-info__title">{String(L.translate('P2P.t_0476'))}:</span>
								<span className="p2p-order-info__desc">
									{String(L.translate('P2P.Marketplace.pay_with_your_personal_account'))}
								</span>
							</div>
						</div>
					</div>
					<div className="p2p-table-order__group">
						<div className="p2p-order-info">
							<div className="p2p-order-info__group">
								<span className="p2p-order-info__title">
									{String(L.translate('P2P.Marketplace.available'))}:
								</span>
								<span className="p2p-order-info__desc">
									{status.amount_left || amount_left} USDT
								</span>
							</div>
							<div className="p2p-order-info__group">
								<span className="p2p-order-info__title">
									{String(L.translate('P2P.Marketplace.limit'))}:
								</span>
								<div className="p2p-order-info__desc">
									{amount_min}-{amount_max}
								</div>
							</div>
						</div>
					</div>
					<div className="p2p-order-form">
						<div className="p2p-order-form__body">
							<div className="input input--margin-big">
								<p className="input__name">
									{type === 'buy'
										? String(L.translate('P2P.Marketplace.i_want_to_buy'))
										: String(L.translate('P2P.Marketplace.i_want_to_sell'))}
									:
								</p>
								<div className="input-wrapper">
									<input
										className="input-item input-item--right-icon-and-action"
										placeholder="0"
										value={amount || ''}
										onChange={handleAmountChange}
									/>
									<div className="input-currency-action">
										<button type="button" onClick={handleAllClick}>
											{String(L.translate('Global.all'))}
										</button>
										<span>{code.toUpperCase()}</span>
									</div>
								</div>
							</div>
						</div>
						<div className="form-submit form-submit--row">
							<button type="button" className="button  button--second-grey" onClick={onClose}>
								{String(L.translate('Global.cancel'))}
							</button>
							<button
								name={String(id)}
								type="button"
								className={`button button--full-width ${type === 'sell' ? 'button--red' : ''}`}
								onClick={validateAndHandleAction}
								disabled={activeTradeLoader}
							>
								{type === 'buy' ? 'Buy' : 'Sell'} {code.toUpperCase()}
							</button>
						</div>
					</div>
				</div>
			</div>
			{activeTrade && (
				<AlreadyTradingPopup
					isOpen={alreadyTradingOpen}
					onClose={() => {
						setAlreadyTradingOpen(false);
						setActiveTrade(null);
					}}
					tradeId={activeTrade}
				/>
			)}
		</>
	);
};

export default P2PTradeModal;
