/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
import { FC, useMemo, useState } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getP2PBalancesLoad } from 'redux/reducers/p2p/selectors';
import Loader from 'ui/Loader';
import SortedButton from 'ui/SortedButton';
import { addImges } from 'services/utils/changeArrey';
import { getAccountP2P } from 'redux/reducers/auth/selectors';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import P2PItem from '../P2PItem';
import { ISpotAndFiatSortingTableProps } from './types';

const P2PSortingTable: FC<ISpotAndFiatSortingTableProps> = ({ data, balancesIsHide }) => {
	const accountP2P = useSelector(getAccountP2P);
	const [sortMagnitude, setSortMagnitude] = useState('');
	const [sortType, setSortType] = useState('');

	const p2pIsLoad = useSelector(getP2PBalancesLoad);
	const asset = useSelector(getAssetsList);

	const sortedData = useMemo(() => {
		if (!data) return [];

		if (sortMagnitude === 'desc') {
			return [...data].sort((a, b) => {
				const sortFirstItem =
					a[sortType as keyof typeof a] || Number(a[sortType as keyof typeof a]);
				const sortSecondItem =
					b[sortType as keyof typeof b] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem > sortSecondItem ? 1 : -1;
			});
		}

		if (sortMagnitude === 'asc') {
			return [...data].sort((a, b) => {
				const sortFirstItem =
					a[sortType as keyof typeof a] || Number(a[sortType as keyof typeof a]);
				const sortSecondItem =
					b[sortType as keyof typeof b] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem < sortSecondItem ? 1 : -1;
			});
		}

		return data;
	}, [data, sortMagnitude, sortType]);

	return (
		<>
			<div className="table table--balance table--p2p-wallet">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<SortedButton
								title={String(L.translate('P2P.P2PWallet.coin'))}
								sortType={sortType}
								sortOrderType="code"
								setSortType={setSortType}
								sortMagnitude={sortMagnitude}
								setSortMagnitude={setSortMagnitude}
							/>
						</div>
						<div className="td">
							<SortedButton
								title={String(L.translate('P2P.P2PWallet.available_asset'))}
								sortType={sortType}
								sortOrderType="balance"
								setSortType={setSortType}
								sortMagnitude={sortMagnitude}
								setSortMagnitude={setSortMagnitude}
							/>
						</div>
						<div className="td">
							<SortedButton
								title={String(L.translate('P2P.P2PWallet.frozen'))}
								sortType={sortType}
								sortOrderType="frozen_balance"
								setSortType={setSortType}
								sortMagnitude={sortMagnitude}
								setSortMagnitude={setSortMagnitude}
							/>
						</div>
						<div className="td">
							<SortedButton
								title={String(L.translate('P2P.P2PWallet.total_assets'))}
								sortType={sortType}
								sortOrderType="total"
								setSortType={setSortType}
								sortMagnitude={sortMagnitude}
								setSortMagnitude={setSortMagnitude}
							/>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(L.translate('P2P.P2PWallet.action'))}
							</span>
						</div>
					</div>
				</div>
				<div className="table-body">
					{p2pIsLoad ? (
						<div className="spot-and-fiat-wallets-table-loader-wrapper">
							<Loader />
						</div>
					) : null}

					{!p2pIsLoad && sortedData?.length
						? addImges(sortedData, asset)?.map((item: any) => (
								<P2PItem key={item.id} data={item} balancesIsHide={balancesIsHide} />
						  ))
						: null}

					{!accountP2P ? (
						<span className="table-empty">
							{String(L.translate('P2P.P2PWallet.p2p_not_enabled'))}
						</span>
					) : (
						<>
							{!p2pIsLoad && sortedData?.length === 0 && (
								<span className="table-empty">
									{String(L.translate('P2P.P2PWallet.no_balances'))}
								</span>
							)}
						</>
					)}
				</div>
			</div>
			{/* {totalPages > 1 && (
				<Pagination
					pageCount={totalPages}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)} */}
		</>
	);
};

export default P2PSortingTable;
