/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
// import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getAllAdvertisement,
	getP2PBalancesLoad,
	getCurrencyItem,
} from 'redux/reducers/p2p/selectors';
import { getAllAdvertisementRequest } from 'redux/reducers/p2p/reducer';
import { getAccountP2P } from 'redux/reducers/auth/selectors';
import Pagination from 'ui/Pagination';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import Loader from 'ui/Loader';
import UserItem from '../UserItem';
import { ITableProps } from './type';
/* eslint-disable no-debugger */
const Table: FC<ITableProps> = ({ userName }) => {
	const currencyItem = useSelector(getCurrencyItem);
	const userSettings = useSelector(getUserSettingsData);
	const p2pIsLoad = useSelector(getP2PBalancesLoad);
	const allAdvertisement = useSelector(getAllAdvertisement);
	const accountP2P = useSelector(getAccountP2P);
	const totalPages = allAdvertisement?.advertisements?.last_page || 1;
	// const [currencyItem, setCurrencyItem] = useState<any | null>(null);
	// const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	// const user = useSelector(getProfile);
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState<number>(1);
	// useEffect(() => {
	// 	if (location.state) {
	// 		setCurrencyItem({ ...location.state.currency });
	// 		// dispatch(setCurrency({ ...location.state.currency }));
	// 	}
	// }, [dispatch, location.state]);

	useEffect(() => {
		if (!currencyItem || !accountP2P) return;
		if (userName) {
			dispatch(
				getAllAdvertisementRequest({
					params: {
						per_page: 10,
						current_page: currentPage,
						currency_id: currencyItem.id,
						username: userName,
						type: 'buy',
					},
				}),
			);
			return;
		}
		if (userSettings) {
			dispatch(
				getAllAdvertisementRequest({
					params: {
						per_page: 10,
						current_page: currentPage,
						currency_id: currencyItem.id,
						username: userSettings?.p2p_username,
					},
				}),
			);
		}
	}, [dispatch, currentPage, currencyItem, userName, userSettings, accountP2P]);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			{accountP2P ? (
				<>
					<div className="table table--p2p-details-type table--p2p-user-buy">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<span className="td-title">{String(L.translate('P2P.coin'))}</span>
								</div>
								<div className="td">
									<span className="td-title">{String(L.translate('P2P.price'))}</span>
								</div>
								<div className="td">
									<span className="td-title">{String(L.translate('P2P.limit_available'))}</span>
								</div>
								<div className="td">
									<span className="td-title">{String(L.translate('P2P.payment'))}</span>
								</div>
								<div className="td">
									<span className="td-title">{String(L.translate('P2P.trade'))}</span>
								</div>
							</div>
						</div>
						<div className="table-body">
							{p2pIsLoad && (
								<div className="open-orders-history-loader-wrapper">
									<Loader />
								</div>
							)}
							{!p2pIsLoad && allAdvertisement?.advertisements?.data?.length
								? allAdvertisement?.advertisements?.data?.map((item) => (
										<UserItem key={item.id} data={item} />
								  ))
								: null}

							{!p2pIsLoad && allAdvertisement?.advertisements?.data?.length === 0 && (
								<span className="table-empty">{String(L.translate('P2P.no_ads'))}</span>
							)}
						</div>
					</div>
					{totalPages > 1 && (
						<Pagination
							pageCount={totalPages}
							forcePage={currentPage - 1}
							onPageChange={handlePageChange}
						/>
					)}
				</>
			) : (
				<div className="table table--p2p-details-type table--p2p-not-enabled">
					{String(L.translate('P2P.p2p_not_enabled'))}
				</div>
			)}
		</>
	);
};

export default Table;
