import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { sendWithdrawCryptoEmailCodeRequest } from 'redux/reducers/withdrawCrypto/reducer';
import { useHistory } from 'react-router';
import { ISecurityVerification } from './types';

const SecurityVerification: FC<ISecurityVerification> = ({ openModal, closeModal, onSubmit }) => {
	const [secondsEmail, setSecondsEmail] = useState(0);
	const [secondsSms, setSecondsSms] = useState(0);
	const [isEmailSendCode, setIsEmailSendCode] = useState(false);
	const [isSmsSendCode, setIsSmsSendCode] = useState(false);
	const [isConfirmDesabled, setIsConfirmDesabled] = useState(false);
	const [emailCode, setEmeilCode] = useState('');
	const [smsCode, setSmsCode] = useState('');
	const [totpCode, setTotpCode] = useState('');

	const dispatch = useDispatch();
	const history = useHistory();
	const userSettingsData = useSelector(getUserSettingsData);
	const userData = useSelector(getUserSettingsData);

	const handleGetEmailCode = () => {
		dispatch(sendWithdrawCryptoEmailCodeRequest());

		setSecondsEmail(60);
	};

	const handleGetSmsCode = () => {
		if (userSettingsData?.data?.phone) {
			dispatch(generateSmsRequest({ phone: userSettingsData?.data?.phone }));

			setSecondsSms(60);
		}
	};

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;

		if (secondsEmail > 0) {
			timer = setTimeout(() => setSecondsEmail(secondsEmail - 1), 1000);
			setIsEmailSendCode(true);
		} else {
			setSecondsEmail(0);
			setIsEmailSendCode(false);
		}

		return () => {
			clearTimeout(timer);
			setIsEmailSendCode(false);
		};
	}, [secondsEmail]);

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;

		if (secondsSms > 0) {
			timer = setTimeout(() => setSecondsSms(secondsSms - 1), 1000);
			setIsSmsSendCode(true);
		} else {
			setSecondsSms(0);
			setIsSmsSendCode(false);
		}

		return () => {
			clearTimeout(timer);
			setIsSmsSendCode(false);
		};
	}, [secondsSms]);

	const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setEmeilCode(value);
		}
	};

	const handleChangeSms = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setSmsCode(value);
		}
	};

	const handleChangeTotp = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value.trim().length <= 6 && !Number.isNaN(Number(value))) {
			setTotpCode(value);
		}
	};

	const handleCloseModal = () => {
		closeModal();
		setEmeilCode('');
		setSmsCode('');
		setTotpCode('');
	};

	const handleSubmit = () => {
		onSubmit({
			emailCode: userSettingsData?.email_2fa_enabled ? emailCode.trim() : undefined,
			smsCode: userSettingsData?.phone_2fa_enabled ? smsCode.trim() : undefined,
			totp: userSettingsData?.google2fa_enabled ? totpCode.trim() : undefined,
		});
	};

	const isGoogle = !!(userSettingsData?.google2fa_enabled && !totpCode);
	const isPhone = !!(userSettingsData?.phone_2fa_enabled && !smsCode);
	const isEmail = !!(userSettingsData?.email_2fa_enabled && !emailCode);

	const disabled = isGoogle || isPhone || isEmail;

	console.log('userSettingsData', userSettingsData);

	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={handleCloseModal}
			closeOnEscape
			lockScroll
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<button className="popup-close" type="button" onClick={handleCloseModal}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header popup-header--padding">
							<p className="popup-header__title">
								{L.translate('Advertisement.SecurityVerify.title')}
							</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							{userSettingsData?.email_2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className="input-wrapper">
													<input
														className="input-item"
														type="text"
														placeholder={String(
															L.translate('WithdrawStable.Placeholders.email_verify'),
														)}
														value={emailCode}
														onChange={handleChangeEmail}
													/>
												</div>
											</div>
										</div>
										{isEmailSendCode && secondsEmail > 0 ? (
											<p className="timer-wrapper">{secondsEmail}s</p>
										) : (
											<button
												className="button button--small"
												type="button"
												onClick={handleGetEmailCode}
											>
												{String(L.translate('WithdrawFiat.get_code'))}
											</button>
										)}
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
											{String(L.translate('WithdrawFiat.enter_code'))} {userSettingsData?.email}
										</span>
									</div>
								</div>
							) : null}

							{userSettingsData?.phone_2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className="input-wrapper">
													<input
														className="input-item"
														type="text"
														placeholder={String(
															L.translate('WithdrawStable.Placeholders.sms_verify'),
														)}
														value={smsCode}
														onChange={handleChangeSms}
													/>
												</div>
											</div>
										</div>
										{isSmsSendCode && secondsSms > 0 ? (
											<p className="timer-wrapper">{secondsSms}s</p>
										) : (
											<button
												className="button button--small"
												type="button"
												onClick={handleGetSmsCode}
											>
												{String(L.translate('WithdrawFiat.get_code'))}
											</button>
										)}
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
											{String(L.translate('WithdrawFiat.sms_sent'))} {userSettingsData?.data?.phone}
										</span>
									</div>
								</div>
							) : null}
							{userSettingsData?.google2fa_enabled ? (
								<div className="withdrawal-code">
									<div className="form-group withdrawal-code__group">
										<div className="withdrawal-code__field">
											<div className="input input--margin-none">
												<div className="input-wrapper">
													<input
														className="input-item"
														type="text"
														placeholder={String(
															L.translate('WithdrawStable.Placeholders.2fa_code'),
														)}
														value={totpCode}
														onChange={handleChangeTotp}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="input-notify">
										<span className="input-notify__text input-notify__text--medium withdraw-notify-helper-message">
											{String(L.translate('WithdrawFiat.enter_google_code'))}
										</span>
									</div>
								</div>
							) : null}
						</div>
						<div className="popup-submit popup-submit--sb">
							<button
								className="button button--full-width"
								type="button"
								disabled={disabled || isConfirmDesabled}
								onClick={handleSubmit}
							>
								{String(L.translate('WithdrawFiat.confirm_button'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SecurityVerification;
