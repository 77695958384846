import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Content from 'layouts-elements/Content';
import TestPage1 from 'components/TestPage1';

// ==========================================:
const TestPage: FC = () => {
	return (
		<Content>
			<TestPage1 />
		</Content>
	);
};

export default TestPage;
