import { convertMessage } from 'services/utils/trimComment';

export const fiatArray = [
	{
		id: 0,
		text: 'USD',
	},
	{
		id: 1,
		text: 'EUR',
	},
	{
		id: 2,
		text: 'GEL',
	},
	{
		id: 3,
		text: 'GBR',
	},
	{
		id: 4,
		text: 'UAH',
	},
];

export const fiatVipGen = [
	{
		id: 0,
		text: 'General',
	},
	{
		id: 1,
		text: 'VIP',
	},
];

export const displayChannelName = (value: string) => {
	switch (value) {
		case 'paypal':
			return 'PayPal';
		case 'card_other':
			return 'Other card';
		case 'bank_transfer':
			return 'Partner bank';
		case 'bank_transfer_other':
			return 'Other bank';
		default:
			return convertMessage(value);
	}
};
