import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
	getAssetPairsIsLoad,
	getAssetPairsList,
	getAssetPairsListFavorite,
} from 'redux/reducers/assetPairs/selectors';
import L from 'i18n-react';
import Loader from 'ui/Loader';
import { IAssetPairsItem } from 'redux/reducers/assetPairs/types';

import PairsTableItem from '../PairsTableItem';
import { IPairsTableProps } from './types';

const PairsTable: FC<IPairsTableProps> = ({
	star,
	workspaceSettings,
	searchData,
	currentAssetData,
	marginPairsTab,
}) => {
	const { orderBookSection, tradingPairsSection } = workspaceSettings;
	const assetPairsList = useSelector(getAssetPairsList);
	const assetPairsIsLoad = useSelector(getAssetPairsIsLoad);
	const assetPairsListFavorite = useSelector(getAssetPairsListFavorite);
	const filteredAssetPairsBySearchData = useMemo(() => {
		const pairSearch = (pair: IAssetPairsItem) => {
			return pair.code.toLowerCase().replace('_', '/').includes(searchData.toLowerCase().trim());
		};
		if (currentAssetData === 'margin') {
			if (marginPairsTab === 'cross') {
				return searchData
					? assetPairsList?.filter((pair) => pair.active_cross && pairSearch(pair))
					: assetPairsList?.filter((pair) => pair.active_cross);
			}

			if (marginPairsTab === 'isolated') {
				return searchData
					? assetPairsList?.filter((pair) => {
							return pair.active_isolate && pairSearch(pair);
					  })
					: assetPairsList?.filter((pair) => pair.active_isolate);
			}
			return searchData
				? assetPairsList?.filter((pair) => {
						return (
							(pair.active_cross && pairSearch(pair)) || (pair.active_isolate && pairSearch(pair))
						);
				  })
				: assetPairsList?.filter((pair) => pair.active_cross || pair.active_isolate);
		}
		if (currentAssetData !== 'all' && searchData) {
			return assetPairsList?.filter(
				(pair) => pair.code.toLowerCase().includes(currentAssetData) && pairSearch(pair),
			);
		}

		if (currentAssetData !== 'all') {
			return assetPairsList?.filter((pair) => pair.code.toLowerCase().includes(currentAssetData));
		}

		return assetPairsList?.filter((pair) => {
			return pairSearch(pair);
		});
	}, [assetPairsList, searchData, currentAssetData, marginPairsTab]);

	const starArrayFunc = () => {
		const x = filteredAssetPairsBySearchData?.map((e) => {
			return assetPairsListFavorite?.find((v) => {
				return v?.id === e?.id;
			});
		});
		return star ? x?.filter((element) => element !== undefined) : filteredAssetPairsBySearchData;
	};
	const newArray = starArrayFunc();

	return (
		<div
			className={`table table--stats favorites-trades__table ${
				!orderBookSection && tradingPairsSection ? 'favorites-trades__table--small' : ''
			}`}
		>
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Trade.Spot.SpotPairs.PairsTableColumnTitles.pair_name'))}
						</span>
					</div>
					<div className="td">
						<span className="table-header__name">
							{String(L.translate('Trade.Spot.SpotPairs.PairsTableColumnTitles.price_name'))}
						</span>
					</div>
					<div className="td td--right">
						<span className="table-header__name">
							{String(L.translate('Trade.Spot.SpotPairs.PairsTableColumnTitles.change_name'))}
						</span>
					</div>
				</div>
			</div>
			<div className="table-body spot-pair-table-body">
				{newArray?.length &&
				!assetPairsIsLoad /* eslint-disable  @typescript-eslint/no-explicit-any */
					? newArray.map((pair: any) => (
							<PairsTableItem key={pair.id} pair={pair} marginPairsTab={marginPairsTab} />
					  ))
					: null}

				{!filteredAssetPairsBySearchData?.length && !assetPairsIsLoad && (
					<div className="asset-pairs-loader-wrapper">
						{String(L.translate('Trade.Spot.SpotPairs.no_pairs_data'))}
					</div>
				)}

				{assetPairsIsLoad && !filteredAssetPairsBySearchData?.length && (
					<div className="asset-pairs-loader-wrapper">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

export default PairsTable;
