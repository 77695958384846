import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import logo from 'assets/images/general/new-logo.webp';
import newLogo from 'assets/images/general/new-logo-light.webp';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { IAppFooterLogo } from './types';

// ==========================================:
const AppFooterLogo: FC<IAppFooterLogo> = ({ isNew }) => {
	const location = useLocation();
	const theme = useSelector(getColorTheme);

	if (location.pathname === '/') {
		return (
			<span className={`logo ${isNew ? 'logo--footer' : 'footer__logo'}`}>
				<div className="logo__img">
					<img
						src={isNew || theme === 'dark' ? newLogo : logo}
						alt="logo"
						width="100%"
						height="auto"
					/>
				</div>
			</span>
		);
	}

	return (
		<Link to="/" className={`logo ${isNew ? 'logo--footer' : 'footer__logo'}`}>
			<div className="logo__img">
				<img
					src={isNew || theme === 'dark' ? newLogo : logo}
					alt="logo"
					width="100%"
					height="auto"
				/>
			</div>
		</Link>
	);
};

export default AppFooterLogo;
