import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { dataLayer } from 'services/gtm';
import { responseErrors } from 'services/http/responseErrors';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	IGetPaymentsListPayload,
	IPostPaymentsPayload,
	IPostBankAccountRequest,
	IDeleteBankAccountRequest,
	IBankAccountResponse,
	IGetBankTransferRequest,
	IGetBankTransferResponse,
	IGetPaymentBankAccountRequest,
	IGetPaymentBankAccountWithdrawalResponse,
	IPostBankTransferResponse,
	IPostPayPalDepositRequset,
	IPostPayPalDepositResponse,
	TFiatLimitations,
} from './types';
import {
	getCurrencyPaymentsListError,
	getCurrencyPaymentsListRequest,
	getCurrencyPaymentsListSuccess,
	postPaymentsCartubank,
	postBankAccounts,
	updateBankAccountRequest,
	getBankTransferRequest,
	getBankTransferSuccess,
	deleteBankAccountRequest,
	getBankTransferDepositRequest,
	getBankTransferDepositSuccess,
	getBankTransferWithdrawalRequest,
	getBankTransferWithdrawalSuccess,
	postBankAccountsSuccess,
	postPaymentsPayPal,
	postPaymentsPayPalSuccess,
	postPaymentsPayPalError,
	getFiatLimitationsRequest,
	getFiatLimitationsSuccess,
} from './reducer';

// =============================================================:
function* getCurrencyPaymentsListRequestWorker({
	payload,
}: PayloadAction<IGetPaymentsListPayload>) {
	try {
		yield put(showLoading());
		const response: string[] = yield call(api.payments.getCurrencyPaymentsList, payload);

		yield put(getCurrencyPaymentsListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getCurrencyPaymentsListError());
		}
	} finally {
		yield put(hideLoading());
	}
}
function* postPaymentsCartubankWorker({ payload }: PayloadAction<IPostPaymentsPayload>) {
	try {
		yield put(showLoading());
		const response: { proceedUrl: string } = yield call(api.payments.paymentsCartubank, payload);
		yield call(() => window.open(response.proceedUrl, '_blank'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* postBankAccountsWorker({ payload }: PayloadAction<IPostBankAccountRequest>) {
	try {
		const { payload: Payload, onSuccess } = payload;
		yield put(showLoading());
		const response: IPostBankTransferResponse = yield call(
			api.payments.paymentsBankAccount,
			Payload,
		);
		yield put(postBankAccountsSuccess(response));
		onSuccess?.();
		yield put(getBankTransferRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			payload.onError?.();
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* updateBankAccountWorker({ payload }: PayloadAction<IPostBankAccountRequest>) {
	try {
		const { payload: Payload, onSuccess } = payload;
		yield put(showLoading());
		yield call(api.payments.updateBankAccount, Payload);
		onSuccess?.();
		yield put(getBankTransferRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			payload.onError?.();
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
function* getBankTransferWorker(action: PayloadAction<any>) {
	try {
		const response: IBankAccountResponse = yield call(api.cards.getAccountList);
		// if (response) {
		// 	yield onSuccess?.(String(response?.proceedUrl));
		// }
		yield put(getBankTransferSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* deleteBankAccountWorker({ payload }: PayloadAction<IDeleteBankAccountRequest>) {
	const { id } = payload;
	try {
		yield call(api.cards.deleteBank, id);
		yield put(getBankTransferRequest());
		yield payload.onSuccess();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getBankTransferDepositWorker({ payload }: PayloadAction<IGetBankTransferRequest>) {
	const { params } = payload;
	try {
		const response: IGetBankTransferResponse = yield call(
			api.payments.getBankTransferDepositFiat,
			params,
		);
		yield put(getBankTransferDepositSuccess(response));
		// yield payload.onSuccess();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getBankTransferWithdrawalWorker({
	payload,
}: PayloadAction<IGetPaymentBankAccountRequest>) {
	const { params, onError, onSuccess } = payload;
	try {
		const response: IGetPaymentBankAccountWithdrawalResponse = yield call(
			api.payments.createPaymentsBankAccountWithdrawal,
			params,
		);
		yield put(getBankTransferWithdrawalSuccess(response));
		yield onSuccess?.();
		const {
			id,
			type,
			amount,
			amount_fee,
			asset: { code },
		} = response;

		dataLayer.push({
			event: 'transaction',
			transaction_id: id,
			transaction_fee: amount_fee,
			transaction_fee_name: type,
			value: amount,
			currency: code,
		});
	} catch (error) {
		if (axios.isAxiosError(error)) {
			onError?.(error);
			responseErrors(error);
		}
	}
}

function* getPaymentPayPalWorker({ payload }: PayloadAction<IPostPayPalDepositRequset>) {
	const { params, onError, onSuccess } = payload;
	try {
		const response: IPostPayPalDepositResponse = yield call(api.payments.paymentsPayPal, params);
		// yield put(getBankTransferWithdrawalSuccess(response));
		if (response) {
			yield put(postPaymentsPayPalSuccess(response));
			yield onSuccess?.(response.url.ready);
			dataLayer.push({
				event: 'transaction',
				transaction_id: response.id,
				transaction_fee: response.fee,
				transaction_fee_name: 'deposit',
				value: response.amount,
				currency: response.asset_code,
			});
		}
	} catch (error) {
		yield put(postPaymentsPayPalError());
		if (axios.isAxiosError(error)) {
			onError?.();
			responseErrors(error);
		}
	}
}

function* getFiatLimitationsWorker() {
	try {
		const response: TFiatLimitations = yield call(api.payments.getFiatLimitations);
		yield put(getFiatLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* paymentsSaga() {
	yield takeEvery(getCurrencyPaymentsListRequest.type, getCurrencyPaymentsListRequestWorker);
	yield takeEvery(postPaymentsCartubank.type, postPaymentsCartubankWorker);
	yield takeEvery(getBankTransferRequest.type, getBankTransferWorker);
	yield takeEvery(deleteBankAccountRequest.type, deleteBankAccountWorker);
	yield takeEvery(postBankAccounts.type, postBankAccountsWorker);
	yield takeEvery(updateBankAccountRequest.type, updateBankAccountWorker);
	yield takeEvery(getBankTransferDepositRequest.type, getBankTransferDepositWorker);
	yield takeEvery(getBankTransferWithdrawalRequest.type, getBankTransferWithdrawalWorker);
	yield takeEvery(postPaymentsPayPal.type, getPaymentPayPalWorker);
	yield takeEvery(getFiatLimitationsRequest.type, getFiatLimitationsWorker);
}
