import L from 'i18n-react';
import { IFiatFeesItem } from 'redux/reducers/ourPrices/types';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';

type GroupedFiatFees = Record<string, IFiatFeesItem[]>;

export const groupByAssetCode = (limitations: IFiatFeesItem[]) => {
	return limitations.reduce((acc: GroupedFiatFees, curr: IFiatFeesItem): GroupedFiatFees => {
		const assetCode = curr.asset_code;
		const result = { ...acc };
		if (Object.keys(acc).includes(assetCode)) {
			result[assetCode].push(curr);
			return result;
		}
		result[assetCode] = [curr];
		return result;
	}, {});
};

export const displayChannelName = (value: string) => {
	switch (value) {
		case 'paypal':
			return 'PayPal';
		case 'bank_transfer':
			return L.translate('OurPrices.bank_transfer');
		case 'bank_transfer_other':
			return L.translate('OurPrices.bank_transfer_other');
		case 'card_other':
			return L.translate('OurPrices.card_other');
		default:
			return transformFirstLetterToCapitalize(value.replace('card_', '').replaceAll('_', ' '));
	}
};
