import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getP2PBalancesListRequest } from 'redux/reducers/p2p/reducer';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import Profile from 'components/P2P/Profile';
import P2PUserCenter from 'layouts/P2PUserCenter';
import { getAccountP2P } from 'redux/reducers/auth/selectors';

// ==========================================:
const P2PCenter: FC = () => {
	const dispatch = useDispatch();
	const accountP2P = useSelector(getAccountP2P);

	useEffect(() => {
		dispatch(userSettingsRequest());

		if (accountP2P) {
			dispatch(getP2PBalancesListRequest());
		}
	}, [dispatch, accountP2P]);

	return (
		<P2PUserCenter title={String(L.translate('PageTitles.p2p_user_center'))}>
			<Profile />
		</P2PUserCenter>
	);
};

export default P2PCenter;
