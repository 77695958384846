import { FC, ChangeEvent, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import L from 'i18n-react';
import debounce from 'lodash.debounce';
import {
	getProposal,
	getCurrentType,
	getP2PAssets,
	getUserAdvert,
} from 'redux/reducers/p2p/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { checkOrderAmountRequest } from 'redux/reducers/p2p/reducer';
import { ICheckOrderAmountPayload } from 'redux/reducers/p2p/types';
import { ITradeBox } from './type';

const PAFTradeBox: FC<ITradeBox> = ({
	amountMin,
	amountMax,
	setAmountMin,
	setAmountMax,
	setAmount,
	amount,
	currency,
	margin,
	balance,
	dataLoaded,
	setDataLoaded,
}) => {
	const dispatch = useDispatch();
	const params = useParams<{ id?: string }>();
	const price = useSelector(getProposal);
	const p2pAssets = useSelector(getP2PAssets);
	const userData = useSelector(getUserSettingsData);
	const advertData = useSelector(getUserAdvert);
	const selectedType = useSelector(getCurrentType);
	const [canUpdateAmount, setCanUpdateAmount] = useState(false);

	const type = params.id && advertData ? advertData.type : selectedType;

	const askedPrice = price && price.asked_price ? price && price.asked_price : currency;
	const pricePaidCurrencyIfPercent = Number(askedPrice) * (margin / 100);

	const currentAsset = p2pAssets?.find((item) => item.code === currency);

	const sellerFee = userData?.is_vip ? currentAsset?.vip_seller_fee : currentAsset?.seller_fee;

	const getAmountIsError = () => {
		if (type === 'sell') {
			if (params.id) {
				return !canUpdateAmount;
			}
			return (
				Number(amount) + Number(amount) * (Number(sellerFee || 0) / 100) > Number(balance || 0)
			);
		}
		return false;
	};

	const amountChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.target;
		value === '' && setAmount(value);
		if (Number(value) < 0 || !value) {
			return;
		}
		setAmount(value);
	};

	const amountMinChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.currentTarget;
		value === '' && setAmountMin(0);
		if (Number(value) < 0 || !value) {
			return;
		}
		setAmountMin(value);
	};

	const amountMaxChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.currentTarget;
		value === '' && setAmountMax(0);
		if (Number(value) < 0 || !value) {
			return;
		}
		setAmountMax(Number(value));
	};

	const setAmountToMax = () => {
		const initialAmount = advertData ? Number(advertData?.amount) : 0;
		const newMaxAmount =
			Number(balance || 0) - (Number(balance || 0) * Number(sellerFee || 0)) / 100;
		setAmount(String(params.id ? newMaxAmount + initialAmount : newMaxAmount));
	};

	const checkAmount = useMemo(
		() =>
			debounce((payload: ICheckOrderAmountPayload) => {
				dispatch(checkOrderAmountRequest(payload));
			}, 200),
		[dispatch],
	);

	useEffect(() => {
		if (advertData && params?.id && params?.id === String(advertData?.id) && !dataLoaded) {
			advertData?.amount && setAmount(String(Number(advertData?.amount)));
			advertData?.amount_min && setAmountMin(Number(advertData?.amount_min));
			advertData?.amount_max && setAmountMax(Number(advertData?.amount_max));
			setDataLoaded?.(true);
		}
	}, [advertData, dataLoaded, params?.id, setAmount, setAmountMax, setAmountMin, setDataLoaded]);

	useEffect(() => {
		if (params.id && amount) {
			checkAmount({
				apiParams: {
					advertisement_id: Number(params.id),
					amount,
				},
				onSuccess: (response) => {
					setCanUpdateAmount(response.can_update_amount);
				},
			});
		}
	}, [amount, checkAmount, params.id]);

	const amountInUsdt = Number(amount) * pricePaidCurrencyIfPercent;

	return (
		<div className="create-offer__form">
			<div className="form-block">
				<span className="block-label">
					{String(L.translate('Advertisement.trade_details'))}
					<div className="tooltip-wrap">
						<div className="tooltip-item tooltip-item--static">
							<span className="block-label__icon icon-info-circle" />
							<span className="tooltip tooltip--bottom tooltip--left tooltip--arrow-top-left">
								<span className="tooltip__text">
									<span>{String(L.translate('Advertisement.paragraph_1'))}</span>
									<span>{String(L.translate('Advertisement.paragraph_2'))}</span>
									<span>{String(L.translate('Advertisement.paragraph_3'))}</span>
									<span>{String(L.translate('Advertisement.paragraph_4'))}</span>
								</span>
							</span>
						</div>
					</div>
				</span>
				<div className="details-list">
					<div className="details-list__row details-list__row--center">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(
									L.translate('Advertisement.total_amount', { currency: currency?.toUpperCase() }),
								)}
							</span>
						</div>
						<div className="details-list__right">
							<div className="input input--margin-none">
								<div className={`input-wrapper ${getAmountIsError() ? 'input--error' : ''}`}>
									<input
										className="input-item input-item--sort"
										type="number"
										placeholder="0"
										value={amount || ''}
										onChange={amountChange}
									/>
									{type === 'sell' && (
										<div className="input-currency-action">
											<button type="button" onClick={setAmountToMax}>
												MAX
											</button>
										</div>
									)}
								</div>
								{type === 'sell' && (
									<p className="input-hint">
										≈ {balance} {currency?.toUpperCase()}
									</p>
								)}
							</div>
						</div>
					</div>
					<div className="details-list__row details-list__row--center">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.minimum'))} {currency?.toUpperCase()}{' '}
								{String(L.translate('Advertisement.amount'))}
							</span>
						</div>
						<div className="details-list__right">
							<div className="input input--margin-none">
								<div className="input-wrapper">
									<input
										className="input-item input-item--sort"
										type="number"
										placeholder="0"
										value={amountMin || ''}
										onChange={amountMinChange}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="details-list__row details-list__row--center">
						<div className="details-list__left">
							<span className="details-list__title">
								{String(L.translate('Advertisement.maximum'))} {currency?.toUpperCase()}{' '}
								{String(L.translate('Advertisement.amount'))}
							</span>
						</div>
						<div className="details-list__right">
							{/* <span className="details-list__desc">{amountMax}</span> */}
							<div className="input input--margin-none">
								<div className="input-wrapper">
									<input
										className="input-item input-item--sort"
										type="number"
										placeholder="0"
										value={amountMax || amountMax === 0 ? amountMax : ''}
										onChange={amountMaxChange}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PAFTradeBox;
