/* eslint-disable react/no-unescaped-entities */
import L from 'i18n-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import advantageGroupFigure1 from 'assets/images/content/advantage-group-figure1.webp';
import advantageGroupFigure2 from 'assets/images/content/advantage-group-figure2.webp';
import advantageImg1 from 'assets/images/content/advantage-img1.webp';
import advantageImg1Mobile from 'assets/images/content/advantage-img1-mobile.webp';
import advantageImg1Tablet from 'assets/images/content/advantage-img1-tablet.webp';
import advantageImg2 from 'assets/images/content/advantage-img2.png';
import advantageImg3 from 'assets/images/content/advantage-img3.png';
import advantageImg3Mob from 'assets/images/content/advantage-img3-mob.png';
import advantageGroupFigure1Dark from 'assets/images/content/advantage-group-figure1__dark.png';
import advantageGroupFigure2Dark from 'assets/images/content/advantage-group-figure2__dark.png';
import advantageImg2Dark from 'assets/images/content/advantage-img2__dark.png';
import advantageImg3Dark from 'assets/images/content/advantage-img3__dark.png';

const Advantages = () => {
	const isAuth = useSelector(getAuthIsAuthenticated);
	return (
		<div className="section section--advantages">
			<div className="advantages-figure advantages-figure--type1">
				<img src={advantageGroupFigure1} className="for-light" alt="" />
				<img src={advantageGroupFigure1Dark} className="for-dark" alt="" />
			</div>
			<div className="advantages-figure advantages-figure--type2">
				<img src={advantageGroupFigure2} className="for-light" alt="" />
				<img src={advantageGroupFigure2Dark} className="for-dark" alt="" />
			</div>
			<div className="container">
				<h2 className="section-title section-title--big section-title--centered">
					{L.translate('HomePage.Advantages.title')}
				</h2>
				<div className="advantages-list">
					<div className="advantage advantage--first">
						<div className="advantage__img">
							<picture>
								<source
									media="(min-width: 200px) and (max-width: 575px)"
									srcSet={advantageImg1Mobile}
								/>
								<source
									media="(min-width: 576px) and (max-width: 860px)"
									srcSet={advantageImg1Tablet}
								/>
								<img src={advantageImg1} alt="figure" loading="lazy" />
							</picture>
						</div>
						<div className="advantage__info">
							<h3 className="advantage__title">{L.translate('HomePage.Advantages.title_1')}</h3>
							<div className="info-text advantage__desc">
								<p>{L.translate('HomePage.Advantages.description_1')}</p>
							</div>
							{!isAuth && (
								<Link to="/registration" className="button button--new button--new-type2">
									{L.translate('HomePage.Advantages.get_started')}
								</Link>
							)}
						</div>
					</div>
					<div className="advantage advantage--second">
						<div className="advantage__info">
							<h3 className="advantage__title">
								{L.translate('HomePage.Advantages.title_2_1')} <br />
								{L.translate('HomePage.Advantages.title_2_2')}
								<span>{L.translate('HomePage.Advantages.title_2_3')}</span>
							</h3>
							<div className="info-text advantage__desc">
								<p>{L.translate('HomePage.Advantages.description_2')}</p>
							</div>
						</div>
						<div className="advantage__img">
							<img src={advantageImg2} className="for-light" alt="advantage-img" loading="lazy" />
							<img
								src={advantageImg2Dark}
								className="for-dark"
								alt="advantage-img"
								loading="lazy"
							/>
						</div>
					</div>
					<div className="advantage advantage--third">
						<div className="advantage__info">
							<h3 className="advantage__title">{L.translate('HomePage.Advantages.title_3')}</h3>
							<div className="info-text advantage__desc">
								<p>{L.translate('HomePage.Advantages.description_3')}</p>
							</div>
							<Link to="/p2p-trade" className="button button--new button--new-type2">
								Start P2P
							</Link>
							{/* {!isAuth && (
								<Link to="/registration" className="button button--new button--new-type2">
									{L.translate('HomePage.Advantages.get_started')}
								</Link>
							)} */}
						</div>
						<div className="advantage__img">
							<img
								className="advantage__img-item advantage__img-item--pc for-light"
								src={advantageImg3}
								alt="advantage-img"
							/>
							<img
								className="advantage__img-item advantage__img-item--mob for-light"
								src={advantageImg3Mob}
								alt="advantage-img"
							/>
							<img
								className="advantage__img-item advantage__img-item--pc for-dark"
								src={advantageImg3Dark}
								alt="advantage-img"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Advantages;
