/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import L from 'i18n-react';
import {
	getTimeParserDateFromTimestamp,
	getTimeParserTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { useSelector } from 'react-redux';
import { P2PItemProps } from './types';
/* eslint-disable no-debugger */
const P2PItem: FC<P2PItemProps> = ({ data, type }) => {
	const {
		created_at,
		asset_code,
		seller,
		buyer,
		status,
		amount,
		advertisement,
		price,
		img_path,
		seller_id,
		buyer_username,
		seller_username,
	} = data;
	const user = useSelector(getUserSettingsData);
	const getMarketType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return String(L.translate('MarketType.sell'));

		if (typeValue?.includes('buy')) return String(L.translate('MarketType.buy'));

		return '-';
	};
	const getClassByType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return 'table-info__type table-info__type--red';

		if (typeValue?.includes('buy')) return 'table-info__type table-info__type--green';

		return 'td-name';
	};

	const userId = user && user.id ? user.id : '';

	const counterpartyEmail = seller_id === userId ? buyer?.email : seller?.email;
	return (
		<div className="tr">
			<div className="table-info">
				<div className="table-info__group">
					<p className={getClassByType(type)}>{getMarketType(type)}</p>
					<p className="table-info__date">
						{getTimeParserDateFromTimestamp(created_at)}{' '}
						{getTimeParserTimeFromTimestamp(created_at)}
					</p>
				</div>
				{/* <div className="copy-value">
					<p className="copy-value__text">{id}</p>
					<button type="button" className="copy-value__btn">
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3.33203 9.99967H2.66536C2.31174 9.99967 1.9726 9.8592 1.72256 9.60915C1.47251 9.3591 1.33203 9.01996 1.33203 8.66634V2.66634C1.33203 2.31272 1.47251 1.97358 1.72256 1.72353C1.9726 1.47348 2.31174 1.33301 2.66536 1.33301H8.66536C9.01899 1.33301 9.35813 1.47348 9.60817 1.72353C9.85822 1.97358 9.9987 2.31272 9.9987 2.66634V3.33301"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				</div> */}
			</div>

			<div className="td">
				<p className="td-hidden-name">Type/Coin</p>
				<div className="balance-currency">
					<div className="balance-currency__img">
						<img src={img_path} alt="" />
					</div>
					<div className="balance-currency__info">
						<span className="balance-currency__title">{asset_code.toLocaleUpperCase()}</span>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fiat amount</p>
				<span className="td-name td-name--regular td-name--fw500">
					{price} {advertisement.current_fiat_code.toLocaleUpperCase()}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Crypto amount</p>
				<span className="td-name td-name--regular ">{`${
					data.type === 'token' ? Number(amount).toFixed(2) : amount
				}  ${asset_code.toLocaleUpperCase()}`}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Counterparty</p>

				<span className="td-name td-name--regular ">
					{type === 'buy' ? seller_username : buyer_username}
				</span>
				{/* <p>
					<Link to="/" className="table-link">
						KHMER COIN®
					</Link>
				</p> */}
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<div>
					<p className="td-name td-name--regular">
						{status.includes('canceled')
							? String(L.translate('StatusType.canceled'))
							: String(L.translate('StatusType.closed'))}
					</p>
					{/* <div className="table-time">
						<span className="table-time__icon">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M14.9375 8C14.9375 11.8317 11.8317 14.9375 8 14.9375C4.16825 14.9375 1.0625 11.8317 1.0625 8C1.0625 4.16825 4.16825 1.0625 8 1.0625C11.8317 1.0625 14.9375 4.16825 14.9375 8Z"
									stroke="#03BC70"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10.5736 10.2068L7.74609 8.52002V4.88477"
									stroke="#03BC70"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						13:24
					</div> */}
				</div>
			</div>
			{/* <div className="td td--right">
				<p className="td-hidden-name">Operation</p>
				<div className="link-group">
					<a href="/" className="link link--regular">
						Trade
					</a>
				</div>
			</div> */}
		</div>
	);
};

export default P2PItem;
