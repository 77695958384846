import L from 'i18n-react';

interface ISuccessProps {
	type: string;
	closeModal: () => void;
}

const SuccessModal = ({ closeModal, type }: ISuccessProps) => {
	const handleCloseModal = () => {};

	return (
		<div className="popup-window">
			{/* <button className="popup-close" type="button" onClick={closeModal}>
				<span className="popup-close__icon icon-close" />
			</button> */}
			<div className="popup-window__inside">
				<div className="popup popup--medium ">
					<div className="popup-header">
						<div className="popup-icon">
							<svg
								width="25"
								height="19"
								viewBox="0 0 25 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M22.5 2.5L8.75 16.25L2.5 10"
									stroke="white"
									strokeWidth="4"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<p className="popup-header__title ">{L.translate('Trade.Margin.SuccessMsg.title')}</p>
					</div>
					<div className="popup-body">
						<div className="spent-value spent-value--ta-center">
							<p className="spent-value__text">
								{L.translate('Trade.Margin.SuccessMsg.completed', { type })}
							</p>
						</div>
					</div>
					<div className="popup-submit popup-submit--sb">
						<button className="button button--full-width" type="button" onClick={closeModal}>
							{L.translate('Global.Confirm')}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SuccessModal;
