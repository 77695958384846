import { FC } from 'react';
import { ICheckbox } from './types';

// ==================================:
const Checkbox: FC<ICheckbox> = ({ id, type, name, handleClick, isChecked }) => {
	return (
		<input
			className="hidden"
			id={id}
			name={name}
			type={type}
			onChange={handleClick}
			checked={isChecked}
		/>
	);
};

export default Checkbox;
