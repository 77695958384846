import { useState, FC, KeyboardEvent, useRef, useCallback, useEffect } from 'react';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import L from 'i18n-react';
import Input from 'ui/Formik/Input';
import TextError from 'ui/Formik/TextError';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { PrivateAccountIcon, BuisinessAccountIcon } from 'assets/svg-icon';
import { getRegistrIsLoad } from 'redux/reducers/auth/selectors';
import { IRegistrValues } from '../types';
import { IRegistrFormInner } from './types';

// ==========================================:
const RegistrFormInner: FC<IRegistrFormInner> = ({
	setIsCapsLockOn,
	captchaLoading,
	setCaptchaLoading,
	captcha,
	setCaptcha,
}) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
	const submitRef = useRef<HTMLButtonElement>(null);
	const loading = useSelector(getRegistrIsLoad);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const { errors, touched, isSubmitting, isValid, dirty, values, setFieldValue } =
		useFormikContext<IRegistrValues>();

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleConfirmPassDisplay = (): void => {
		setIsShowConfirmPass(!isShowConfirmPass);
	};

	const handleCapsLockOn = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.getModifierState('CapsLock')) {
			setIsCapsLockOn(true);
		} else {
			setIsCapsLockOn(false);
		}
	};

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.error('executeRecaptcha not exist');
			setTimeout(() => {
				// eslint-disable-next-line no-void
				void handleReCaptchaVerify();
			}, 300);
			return;
		}

		const token = await executeRecaptcha('login');
		setCaptcha(token);
		setCaptchaLoading?.(false);
	}, [executeRecaptcha, setCaptcha, setCaptchaLoading]);

	const handleGenerate = async () => {
		setCaptchaLoading?.(true);
		await handleReCaptchaVerify();
	};

	const onKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			await handleGenerate();
		}
	};

	useEffect(() => {
		if (captcha && isValid && !isSubmitting && !captchaLoading && !loading) {
			submitRef?.current?.click();
		}
	}, [captcha, captchaLoading, isSubmitting, isValid, loading]);

	return (
		<>
			<p className="authorization__title">
				{String(L.translate('Forms.RegistrForm.registr_form_title'))}
			</p>
			<div className="authorization__details">
				<p>{String(L.translate('Forms.RegistrForm.registr_form_subtitle'))}</p>
			</div>
			<div className="authorization__form">
				<div className="account-switcher">
					<button
						type="button"
						className={`account-switcher__item ${values.is_business === 0 ? 'active' : ''}`}
						onClick={() => setFieldValue('is_business', 0)}
					>
						<span className="btn-icon">
							<PrivateAccountIcon />
						</span>
						{String(L.translate('Forms.RegistrForm.personal'))}
					</button>
					<button
						type="button"
						className={`account-switcher__item ${values.is_business === 1 ? 'active' : ''}`}
						onClick={() => setFieldValue('is_business', 1)}
					>
						<span className="btn-icon">
							<BuisinessAccountIcon />
						</span>
						{String(L.translate('Forms.RegistrForm.business'))}
					</button>
				</div>
				<div className="authorization__field">
					<Field
						type="email"
						placeholder={String(L.translate('Forms.RegistrForm.registr_form_field_email'))}
						name="email"
						required
						onKeyUp={handleCapsLockOn}
						onKeyDown={onKeyDown}
						component={Input}
					/>
				</div>
				{values.is_business === 1 && (
					<div className="authorization__field">
						<Field
							type="text"
							placeholder={String(L.translate('Forms.RegistrForm.registr_form_field_company_name'))}
							name="company_name"
							required
							component={Input}
						/>
					</div>
				)}
				<div className="authorization__field">
					<Field
						type={isShowPass ? 'text' : 'password'}
						placeholder={String(L.translate('Forms.RegistrForm.registr_form_field_password'))}
						name="password"
						required
						component={Input}
						ariaLabel="show-password"
						showPass={isShowPass}
						onKeyDown={onKeyDown}
						setIsShowPass={handlePassDisplay}
					/>
				</div>
				<div className="authorization__field">
					<Field
						type={isShowConfirmPass ? 'text' : 'password'}
						placeholder={String(
							L.translate('Forms.RegistrForm.registr_form_field_confirm_password'),
						)}
						name="confirmPassword"
						required
						component={Input}
						ariaLabel="show-confirm-password"
						showPass={isShowPass}
						onKeyDown={onKeyDown}
						setIsShowPass={handleConfirmPassDisplay}
					/>
				</div>
				<div className="authorization__options">
					<div className="checkbox">
						<label htmlFor="terms-conditions" className="checkbox__label">
							<Field
								type="checkbox"
								name="acceptTerms"
								aria-label="accept-terms"
								className="hidden"
								id="terms-conditions"
							/>
							<span className="checkbox__item">
								<span className="checkbox__item-icon">
									<span className="icon-Checkbox" />{' '}
								</span>
							</span>

							<div
								className={`checkbox ${
									errors?.acceptTerms && touched?.acceptTerms ? ' input--error' : ''
								}`}
							/>

							<span className="checkbox__text">
								{String(L.translate('Forms.RegistrForm.registr_form_agree_terms'))}{' '}
								<Link to="/user-agreement" target="blank">
									{String(L.translate('Forms.RegistrForm.registr_form_agree_terms_conditions'))}
								</Link>{' '}
								{String(L.translate('Forms.RegistrForm.registr_form_agree_terms_and'))}{' '}
								<Link to="/privacy-policy" target="blank">
									{String(L.translate('Forms.RegistrForm.registr_form_agree_terms_privat'))}
								</Link>
							</span>
						</label>
						<ErrorMessage name="acceptTerms" component={TextError} />
					</div>
					<div className="authorization__options-full">
						<div className="checkbox checkbox--no-mb">
							<label htmlFor="cookie-policy" className="checkbox__label">
								<Field
									type="checkbox"
									name="cookiePolicy"
									aria-label="cookie-policy"
									className="hidden"
									id="cookie-policy"
								/>
								<div
									className={`checkbox ${
										errors?.cookiePolicy && touched?.cookiePolicy ? ' input--error' : ''
									}`}
								/>
								<span className="checkbox__item">
									<span className="checkbox__item-icon">
										<span className="icon-Checkbox" />
									</span>
								</span>
								<span className="checkbox__text">
									{String(L.translate('Forms.RegistrForm.registr_form_agree_width'))}{' '}
									<Link to="/cookie-policy" target="blank">
										{String(L.translate('Forms.RegistrForm.registr_form_agree_width_cookie'))}
									</Link>
								</span>
							</label>
							<ErrorMessage name="cookiePolicy" component={TextError} />
						</div>
					</div>
				</div>
			</div>
			<div className="form-submit">
				<button
					className="button button--full-width Click_Create_Account"
					disabled={!(isValid && dirty) || isSubmitting || captchaLoading}
					aria-label="form submit"
					type="button"
					onClick={handleGenerate}
				>
					{String(L.translate('Forms.RegistrForm.registr_form_submit_btn'))}
				</button>
				<button type="submit" className="hidden" ref={submitRef}>
					{L.translate('Global.submit')}
				</button>
			</div>
		</>
	);
};

export default RegistrFormInner;
