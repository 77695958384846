/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import L from 'i18n-react';
import SpotTrade from 'components/Trade';
import Trading from 'layouts/Trading';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAssetPairsFeesAndLimitsRequest,
	getAssetPairsRequest,
} from 'redux/reducers/assetPairs/reducer';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import {
	getOrderBookRequest,
	getRecentTradesRequest,
	getUserTradesRequest,
} from 'redux/reducers/spotTrade/reducer';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { useHistory, useLocation } from 'react-router';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import {
	getMarginCrossWalletsRequest,
	getMarginIndexPricesRequest,
	getMarginIsolatedWalletsRequest,
} from 'redux/reducers/marginWallets/reducer';

// ==========================================:
const SpotTradingPage: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const currentPair = useSelector(getCurrentPair);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const titleMargin = location.pathname.includes('MarginTrading');

	useEffect(() => {
		dispatch(getAssetsRequest());
		dispatch(getAssetPairsRequest());
		dispatch(getAssetPairsFeesAndLimitsRequest());
	}, [dispatch]);
	useEffect(() => {
		const transformCurrrentPair = currentPair?.toUpperCase();

		if (location.pathname.includes('MarginTrading')) {
			history.push(`/MarginTrading/${transformCurrrentPair}${location.search}`);
		} else {
			history.push(`/spot/${transformCurrrentPair}${location.search}`);
		}

		dispatch(
			getOrderBookRequest({
				pair: currentPair,
				params: {
					limit: 100,
				},
			}),
		);

		dispatch(
			getRecentTradesRequest({
				pair: currentPair,
				params: {
					limit: 100,
				},
			}),
		);

		if (authIsAuthenticated) {
			dispatch(getWalletsRequest());
			dispatch(getMarginCrossWalletsRequest());
			dispatch(getMarginIndexPricesRequest());
			dispatch(getMarginIsolatedWalletsRequest());

			dispatch(
				getUserTradesRequest({
					params: {
						pair: currentPair,
						current_page: 1,
						per_page: 100,
					},
				}),
			);
		}
		// else if (!authIsAuthenticated) {
		// 	dispatch(resetMarginWalletsRequest());
		// }
	}, [dispatch, currentPair, authIsAuthenticated]);

	return (
		<Trading
			title={
				titleMargin
					? String(L.translate('MetaTags.Description.margin_trade'))
					: String(L.translate('MetaTags.Description.spot_trade'))
			}
			showVerificBanner
		>
			<SpotTrade />
		</Trading>
	);
};

export default SpotTradingPage;
