import { FC } from 'react';
import L from 'i18n-react';
import { IPhotoBox } from './type';

const PhotosBox: FC<IPhotoBox> = ({ photos, deleteFile }) => {
	return (
		<>
			{photos?.map((photo: any, index) => {
				const src = photo?.url || photo?.file;
				const handleOpen = () => {
					const newTab = window.open();

					newTab?.document.write(
						`<!DOCTYPE html><head><title>Document preview</title></head><body><img src="${String(
							src,
						)}"></body></html>`,
					);

					newTab?.document.close();
				};
				return (
					<div className="attachemnt" key={photo?.id || index}>
						<div className="attachemnt-file">
							<embed
								src={src}
								// frameBorder="0"
								// scrolling="auto"
								height="100%"
								width="100%"
							/>
						</div>
						<div className="attachemnt-btns">
							<button
								type="button"
								className="delete-attachment"
								onClick={() => {
									if (photo?.id) {
										deleteFile(photo.id);
									} else {
										deleteFile(index);
									}
								}}
							>
								{String(L.translate('P2P.delete'))}
							</button>
						</div>
						<button type="button" className="open-attachment" onClick={handleOpen}>
							<span>{String(L.translate('P2P.open'))}</span>
						</button>
					</div>
				);
			})}
		</>
	);
};

export default PhotosBox;
// eslint-disable-next-line
