import { FC } from 'react';
import L from 'i18n-react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { navList } from 'routes/routesList';
import { IFooter } from './types';

// ==========================================:
const FooterNav: FC<IFooter> = ({ isNew }) => {
	const currentPair = useSelector(getCurrentPair);
	const isAuth = useSelector(getAuthIsAuthenticated);

	const transformCurrrentPair = currentPair?.toUpperCase();
	return (
		<nav className={`footer-nav ${isNew ? 'footer-nav--new' : ''}`}>
			{!isNew && (
				<ul className="footer-nav__list">
					{isAuth && (
						<li className="footer-nav__item">
							<NavLink to="/convert" className="footer-nav__link">
								{String(L.translate('FooterNav.convert'))}
							</NavLink>
						</li>
					)}
					<li className="footer-nav__item">
						<NavLink
							to={`/spot/${String(transformCurrrentPair)}`}
							className={`footer-nav__link ${isNew ? 'footer-nav__link--new' : ''}`}
						>
							{String(L.translate('FooterNav.spot'))}
						</NavLink>
					</li>
					{/* <li className="footer-nav__item">
					<NavLink
						to={`/MarginTrading/${String(transformCurrrentPair)}?type=cross`}
						className={`footer-nav__link ${isNew ? 'footer-nav__link--new' : ''}`}
					>
						{String(L.translate('FooterNav.margin'))}
					</NavLink>
				</li> */}
					<li className="footer-nav__item">
						<NavLink
							to="/p2p-trade"
							className={`footer-nav__link ${isNew ? 'footer-nav__link--new' : ''}`}
						>
							{String(L.translate('FooterNav.p2p'))}
						</NavLink>
					</li>

					<li className="footer-nav__item">
						<NavLink to="/user-agreement" className="footer-nav__link">
							{String(L.translate('FooterNav.terms'))}
						</NavLink>
					</li>
					<li className="footer-nav__item">
						<NavLink to="/privacy-policy" className="footer-nav__link">
							{String(L.translate('FooterNav.privacy'))}
						</NavLink>
					</li>
				</ul>
			)}
			{isNew && (
				<>
					<ul className="footer-nav__list">
						<li className="footer-nav__item">
							<NavLink to="/our-prices" className="footer-nav__link footer-nav__link--new">
								{String(L.translate('FooterNav.our_prices'))}
							</NavLink>
						</li>
						<li className="footer-nav__item">
							<NavLink to="/privacy-policy" className="footer-nav__link footer-nav__link--new">
								{String(L.translate('FooterNav.privacy_policy'))}
							</NavLink>
						</li>
						<li className="footer-nav__item">
							<NavLink to="/" className="footer-nav__link footer-nav__link--new">
								{String(L.translate('FooterNav.blog'))}
							</NavLink>
						</li>
					</ul>
					<ul className="footer-nav__list">
						<li className="footer-nav__item">
							<NavLink to="/user-agreement" className="footer-nav__link footer-nav__link--new">
								{String(L.translate('FooterNav.terms'))}
							</NavLink>
						</li>
						<li className="footer-nav__item">
							<NavLink to="/cookie-policy" className="footer-nav__link footer-nav__link--new">
								{String(L.translate('FooterNav.privacy'))}
							</NavLink>
						</li>
					</ul>
				</>
			)}
		</nav>
	);
};

export default FooterNav;
