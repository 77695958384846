/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getInterestedHistoryRequest } from 'redux/reducers/marginWallets/reducer';
import Pagination from 'ui/Pagination';
import {
	getInterestedHistory,
	getMarginHistoryIsLoader,
} from 'redux/reducers/marginWallets/selectors';
/* eslint-disable no-debugger */
import Loader from 'ui/Loader';
import InterestedItem from '../InterestedItem';

const InterestedTable: FC = () => {
	const interestedList = useSelector(getInterestedHistory);
	const marginHistoryLoad = useSelector(getMarginHistoryIsLoader);
	const totalPages = interestedList?.last_page || 1;

	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		if (authIsAuthenticated) {
			dispatch(
				getInterestedHistoryRequest({
					params: {
						per_page: 10,
						current_page: currentPage,
					},
				}),
			);
		}
	}, [dispatch, authIsAuthenticated, currentPage]);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<div className="table table--margin-interest table--wallet-type">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.Interest.coin')}</span>
						</div>
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.Interest.date')}</span>
						</div>
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.Interest.amount')}</span>
						</div>
						<div className="td">
							<span className="td-title">
								{L.translate('Margin.Tables.Interest.daily_interest_rate')}
							</span>
						</div>
					</div>
				</div>
				<div className="table-body">
					{marginHistoryLoad && (
						<div className="open-orders-history-loader-wrapper">
							<Loader />
						</div>
					)}
					{!marginHistoryLoad && interestedList?.data?.length
						? interestedList?.data?.map((item) => <InterestedItem key={item.id} data={item} />)
						: null}

					{!marginHistoryLoad && interestedList?.data?.length === 0 && (
						<span className="table-empty">
							{String(L.translate('Margin.Tables.Interest.no_orders_text'))}
						</span>
					)}
				</div>
			</div>
			{totalPages > 1 && (
				<Pagination
					pageCount={totalPages}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default InterestedTable;
