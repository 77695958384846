/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GOOGLE_CAPTCHA, APP_NAME } from 'services/constants/env';
import {
	registrationRequest,
	sendAppleLoginStringRequest,
	sendFacebookLoginStringRequest,
	sendGoogleLoginStringRequest,
} from 'redux/reducers/auth/reducer';
import { getRegistrIsLoad, getSocialLoginData } from 'redux/reducers/auth/selectors';
import Auth from 'layouts/Auth';
import RegistrForm from 'components/Forms/RegistrForm';
import GoogleCaptcha from 'components/GoogleCaptcha';
import { IRegistrValues } from 'components/Forms/RegistrForm/types';
import Loader from 'ui/Loader';
import { dataLayer } from 'services/gtm';
import { INVITE_KEY } from 'services/constants/storageKeys';
import SocialLogin from '../Login/SocialLogin';
// ==========================================:
const Registration: FC = () => {
	const [open, setOpen] = useState(false);
	const [registData, setRegistrData] = useState<null | IRegistrValues>(null);
	const [captchaLoading, setCaptchaLoading] = useState(false);
	const [captcha, setCaptcha] = useState('');
	const history = useHistory();
	const location = useLocation();
	const { search } = location;
	const referral = queryString.parse(search) || null;
	const invite_key = referral && Object.keys(referral)[0];

	const registrIsLoad = useSelector(getRegistrIsLoad);
	const socialLogin = useSelector(getSocialLoginData);
	const dispatch = useDispatch();

	const handleRegistrSubmit = (values: IRegistrValues): void => {
		dataLayer.push({
			event: 'sign_up',
		});
		let payload = {
			...values,
			is_business: values.is_business as 0 | 1,
		};
		if (invite_key) {
			payload = {
				...payload,
				referral: String(invite_key),
			};
		}

		dispatch(
			registrationRequest({
				apiParams: { ...payload, captcha },
				onSuccess: () => {
					history.push({
						pathname: '/registration-done',
						state: {
							email: values.email,
						},
					});
					setCaptcha('');
				},
				onError: () => setCaptcha(''),
			}),
		);
		// setRegistrData(values);
		setOpen(true);
	};

	const hangleCaptchaClose = useCallback((): void => {
		setRegistrData(null);
		setOpen(false);
	}, []);

	useEffect(() => {
		const loginState = new URLSearchParams(location.search).get('state');
		const loginCode = new URLSearchParams(location.search).get('code');
		if (loginState) {
			if (socialLogin === 'google') {
				dispatch(
					sendGoogleLoginStringRequest({
						token: loginState,
						code: loginCode,
						referral: invite_key,
						history,
					}),
				);
			}
			if (socialLogin === 'facebook') {
				dispatch(
					sendFacebookLoginStringRequest({
						token: loginState,
						code: loginCode,
						referral: invite_key,
						history,
					}),
				);
			}
			if (socialLogin === 'apple') {
				dispatch(sendAppleLoginStringRequest({ token: loginState, code: loginCode, history }));
			}
		}
	}, [dispatch, location.search]);

	useEffect(() => {
		const sessionReferralId = sessionStorage.getItem(INVITE_KEY);
		if (invite_key && !sessionReferralId) {
			sessionStorage.setItem(INVITE_KEY, invite_key);
			localStorage.setItem(INVITE_KEY, invite_key);
		}
		if (!invite_key && sessionReferralId) {
			history.replace(`${location.pathname}?${sessionReferralId}`);
			localStorage.setItem(INVITE_KEY, invite_key);
		}
		if (localStorage.getItem(INVITE_KEY) && !sessionReferralId && !invite_key) {
			localStorage.removeItem(INVITE_KEY);
		}
	}, [invite_key]);

	useEffect(() => {
		const sessionReferralId = sessionStorage.getItem(INVITE_KEY);
		if (invite_key && !sessionReferralId) {
			sessionStorage.setItem(INVITE_KEY, invite_key);
			localStorage.setItem(INVITE_KEY, invite_key);
		}
		if (!invite_key && sessionReferralId) {
			history.replace(`${location.pathname}?${sessionReferralId}`);
			localStorage.setItem(INVITE_KEY, invite_key);
		}
		if (localStorage.getItem(INVITE_KEY) && !sessionReferralId && !invite_key) {
			localStorage.removeItem(INVITE_KEY);
		}
	}, [invite_key]);

	return (
		<Auth
			title={`${String(APP_NAME)}.com | ${String(L.translate('RegistrPage.registr_page_title'))}`}
		>
			<GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA}>
				<div className="authorization">
					<RegistrForm
						registrSubmit={handleRegistrSubmit}
						captchaLoading={captchaLoading}
						setCaptchaLoading={setCaptchaLoading}
						captcha={captcha}
						setCaptcha={setCaptcha}
					/>
					<SocialLogin fromRegister />
					{(registrIsLoad || captchaLoading) && (
						<div className="auth-loader-wrapper">
							<Loader />
						</div>
					)}
				</div>
				{/* {open && (
					<div className="popup-window popup-window--captcha">
						<div className="popup-window__inside">
							<GoogleCaptcha setCaptcha={handleCaptcha} captchaClose={hangleCaptchaClose} />
							<div className="popup-window__close" onClick={hangleCaptchaClose} />
						</div>
					</div>
				)} */}
			</GoogleReCaptchaProvider>
		</Auth>
	);
};

export default Registration;
