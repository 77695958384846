import { FC } from 'react';
import L from 'i18n-react';
import CheckGreenIcon from 'assets/img/icons/check_green_icon.svg';

// ==========================================:
const EmailConfirmCheck: FC = () => {
	return (
		<div className="additional">
			<div className="additional__content">
				<img src={CheckGreenIcon} alt="check green icon" className="additional__icon" />
				<h3 className="additional__title email-confirm-check">
					{String(L.translate('EmailConfirm.confirmation_email_was_sent'))}
				</h3>
				<p className="additional__desc">
					{String(L.translate('EmailConfirm.confirmation_repeat'))}
				</p>
			</div>
		</div>
	);
};

export default EmailConfirmCheck;
