/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAuthStore,
	ICreateNewPasswordPayload,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IForgotPasswordPayload,
	IGenerateSmsRequestPayload,
	IipConfirmPayload,
	ILoginPayload,
	ILoginPayloadData,
	ILoginResponse,
	IRefreshResponse,
	ILogoutPayload,
	IRegistrationPayload,
	IResetTwoFaPayload,
	IResendRegisterPayloadData,
} from './types';

// ==========================================:
export const initialState: IAuthStore = {
	accessToken: null,
	socketToken: null,
	tokenExpiredAt: null,
	isAuthenticated: false,
	registrLoader: false,
	logoutLoader: false,
	loginLoader: false,
	forgotPasswordLoader: false,
	createNewPasswordLoader: false,
	ipConfirmLoader: false,
	refreshLoader: false,
	emailConfirm: false,
	emailConfirmLoader: false,
	emailResetConfirmTokenLoader: false,
	emailResetConfirmTokenMessage: false,
	generateSmsLoader: false,
	p2pAccount: false,
	status: null,
	loginData: null,
	socialLogin: null,
	socialLoginPayloadData: null,
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		loginRequest: (state, action: PayloadAction<ILoginPayload>) => {
			const loginRequestState = state;
			loginRequestState.loginLoader = true;
		},
		loginSuccess: (state, action: PayloadAction<ILoginResponse>) => {
			const { payload } = action;
			const loginState = state;
			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.token;
			loginState.socketToken = payload.socket_token;
			loginState.tokenExpiredAt = payload.token_expired_at;
			loginState.p2pAccount = payload.user_data.p2p_created;
			loginState.status = payload.user_data.status;
		},
		loginLoaderOff: (state) => {
			const loginLoaderState = state;
			loginLoaderState.loginLoader = false;
		},

		registrationRequest: (state, action: PayloadAction<IRegistrationPayload>) => {
			const registrationState = state;

			registrationState.registrLoader = true;
		},
		registrationSuccess: (state) => {
			const registrationState = state;

			registrationState.registrLoader = false;
		},

		forgotPasswordRequest: (state, action: PayloadAction<IForgotPasswordPayload>) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = true;
		},
		forgotPasswordSuccess: (state) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = false;
		},

		emailConfirmStatusRequest: (state) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirm = true;
		},
		emailConfirmStatusCallback: (state) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirm = false;
			emailConfirmState.emailResetConfirmTokenMessage = false;
		},

		emailConfirmRequest: (state, action: PayloadAction<IEmailConfirmPayload>) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirmLoader = true;
		},
		emailConfirmSuccess: (state) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirmLoader = false;
		},

		emailResetConfirmTokenRequest: (
			state,
			action: PayloadAction<IEmailResetConfirmTokenPayload>,
		) => {
			const emailResetConfirmTokenState = state;
			emailResetConfirmTokenState.emailResetConfirmTokenLoader = true;
		},
		emailResetConfirmTokenSuccess: (state) => {
			const emailResetConfirmTokenState = state;
			emailResetConfirmTokenState.emailResetConfirmTokenLoader = false;
		},

		createNewPasswordRequest: (state, action: PayloadAction<ICreateNewPasswordPayload>) => {
			const createNewPasswordState = state;

			createNewPasswordState.createNewPasswordLoader = true;
		},
		createNewPasswordSuccess: (state) => {
			const createNewPasswordState = state;

			createNewPasswordState.createNewPasswordLoader = false;
		},

		ipConfirmRequest: (state, action: PayloadAction<IipConfirmPayload>) => {
			const ipConfirmState = state;

			ipConfirmState.ipConfirmLoader = true;
		},
		ipConfirmSuccess: (state) => {
			const ipConfirmState = state;
			ipConfirmState.ipConfirmLoader = false;
		},
		ipConfirmLoaderOff: (state) => {
			const ipConfirmState = state;
			ipConfirmState.ipConfirmLoader = false;
		},

		logoutRequest: (state, action: PayloadAction<ILogoutPayload>) => {
			const logoutState = state;

			logoutState.logoutLoader = true;
		},

		generateSmsRequest: (state, action: PayloadAction<IGenerateSmsRequestPayload>) => {
			const generateSmsState = state;

			generateSmsState.generateSmsLoader = true;
		},
		forgotTwoFaRequest: (state, action: PayloadAction<IResetTwoFaPayload>) => {
			const twoFaState = state;
		},
		updateStatusP2PUserSuccess: (state) => {
			const p2pRequestState = state;
			p2pRequestState.p2pAccount = true;
		},
		updateLoginData: (state, { payload }: PayloadAction<ILoginPayloadData | null>) => {
			const loginDataState = state;
			loginDataState.loginData = payload;
		},

		sendGoogleLoginStringRequest: (state, action) => {
			const socialState = state;
			socialState.socialLogin = 'google';
		},
		sendFacebookLoginStringRequest: (state, action) => {
			const socialState = state;
			socialState.socialLogin = 'facebook';
		},
		setSocialLogin: (state, { payload }) => {
			const socialState = state;
			socialState.socialLogin = payload;
		},
		setSocialLoginData: (state, { payload }) => {
			const socialState = state;
			socialState.socialLoginPayloadData = payload;
		},
		sendAppleLoginStringRequest: (state, action) => {},
		refreshRequest: (state) => {
			const loginRequestState = state;
			loginRequestState.refreshLoader = true;
		},
		refreshSuccess: (state, action: PayloadAction<IRefreshResponse>) => {
			const { payload } = action;
			const loginState = state;
			loginState.isAuthenticated = true;
			loginState.refreshLoader = false;
			loginState.accessToken = payload.token;
			loginState.tokenExpiredAt = payload.token_expired_at;
		},
		resendRegisterRequest: (state, action: PayloadAction<IResendRegisterPayloadData>) => {},
		authInitState: () => initialState,
		authInitLoaderIpState: (state) => {
			const loginState = state;
			loginState.ipConfirmLoader = false;
		},
	},
});

export default auth.reducer;
export const {
	ipConfirmRequest,
	ipConfirmSuccess,
	ipConfirmLoaderOff,
	loginRequest,
	loginSuccess,
	loginLoaderOff,
	registrationRequest,
	registrationSuccess,
	authInitState,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	createNewPasswordRequest,
	createNewPasswordSuccess,
	emailConfirmRequest,
	emailConfirmSuccess,
	forgotTwoFaRequest,
	emailResetConfirmTokenRequest,
	emailResetConfirmTokenSuccess,
	emailConfirmStatusRequest,
	emailConfirmStatusCallback,
	generateSmsRequest,
	updateStatusP2PUserSuccess,
	logoutRequest,
	updateLoginData,
	sendGoogleLoginStringRequest,
	sendFacebookLoginStringRequest,
	setSocialLogin,
	setSocialLoginData,
	sendAppleLoginStringRequest,
	refreshRequest,
	refreshSuccess,
	resendRegisterRequest,
	authInitLoaderIpState,
} = auth.actions;
