import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import L from 'i18n-react';

import { useDispatch } from 'react-redux';
import { deleteBankAccountRequest } from 'redux/reducers/payments/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IDelCardProps } from './type';

const DeleteBankAccountPopUp: FC<IDelCardProps> = ({ popUpOpen, popUpClose, data }) => {
	const dispatch = useDispatch();
	const handleDelete = () => {
		dispatch(
			deleteBankAccountRequest({
				id: String(data?.id),
				onSuccess: () => {
					notificationContainer(
						String(L.translate('P2P.Profile.Popups.BankTransfer.account_was_deleted')),
						'success',
					);
				},
				onError: () => {},
			}),
		);
		popUpClose();
	};
	return (
		<Popup open={popUpOpen} onClose={popUpClose} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small ">
						<button onClick={popUpClose} type="button" className="popup-close">
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title popup-header__title--w346">
								{L.translate('P2P.Profile.Popups.BankTransfer.delete_bank_account')}
							</p>
						</div>

						<div className="popup-submit popup-submit--sb">
							<button
								className="button button--second-grey button--full-width"
								type="button"
								onClick={handleDelete}
							>
								{L.translate('Base.Modals.ConfirmDeletePopup.yes_button')}
							</button>
							<button className="button button--full-width" type="button" onClick={popUpClose}>
								{L.translate('Base.Modals.ConfirmDeletePopup.no_button')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default DeleteBankAccountPopUp;
