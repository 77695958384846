import { useState, useMemo, useEffect, useCallback, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import L from 'i18n-react';
import ConvertDropdown from 'ui/ConvertDropdown';
import { convertWalletsFiltered } from 'services/utils/convertHelpers';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { numberValidation } from 'services/utils/numberValidation';
import { getExchangeRate } from 'redux/reducers/convert/selectors';
import { getExchangeRateRequest } from 'redux/reducers/convert/reducer';
import {
	getAssetPairsList,
	getAssetPairsFeesAndLimitsList,
} from 'redux/reducers/assetPairs/selectors';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { IAssetsItem } from 'redux/reducers/assets/types';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import ExchangeRate from '../ExchangeRate';
import ConvertAssetDropdown from '../ConvertDropdownAsset';

const searchParams = new URLSearchParams();

const ConvertForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const isAuth = useSelector(getAuthIsAuthenticated);
	const userIsVip = !!useSelector(getUserSettingsData)?.is_vip;
	const assetPairs = useSelector(getAssetPairsList);
	const walletsList = useSelector(getWalletsList);
	const exchangeRate = useSelector(getExchangeRate);
	const assetsList = useSelector(getAssetsList);
	const assetPairsFeesAndLimit = useSelector(getAssetPairsFeesAndLimitsList);
	const [selectFromWallet, setSelectFromWallet] = useState<IWalletItem | null>(null);
	const [selectToWallet, setSelectToWallet] = useState<IWalletItem | null>(null);
	const [selectFromAsset, setSelectFromAsset] = useState<IAssetsItem | null>(null);
	const [selectToAsset, setSelectToAsset] = useState<IAssetsItem | null>(null);
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [isReverse, setIsReverse] = useState(false);
	const [from, setFrom] = useState('');
	const [to, setTo] = useState('');

	const fromWalletsList = useMemo(
		() => convertWalletsFiltered(walletsList, assetPairs, selectToWallet),
		[walletsList, selectToWallet, assetPairs],
	);

	const toWalletsList = useMemo(
		() => convertWalletsFiltered(walletsList, assetPairs, selectFromWallet),
		[walletsList, selectFromWallet, assetPairs],
	);

	const fromAssetsList = assetsList?.filter(
		(item) => item.type !== 'fiat' && item.code !== selectFromAsset?.code,
	);
	const toAssetsList = assetsList?.filter(
		(item) => item.type !== 'fiat' && item.code !== selectToAsset?.code,
	);

	const autoReloadExchangeRate = (fromAssetId: number, toAssetId: number) => {
		const exchangeRateRequestData = {
			from_asset_id: fromAssetId,
			to_asset_id: toAssetId,
		};
		dispatch(getExchangeRateRequest(exchangeRateRequestData));
	};

	const handleFromWalletSelect = (value: IWalletItem): void => {
		setSelectFromWallet(value);

		searchParams.set('from', value.asset.code);

		if (selectToWallet) {
			// clearTimeout(timeout);
			autoReloadExchangeRate(value.asset.id, selectToWallet.asset.id);
		}

		setFrom('');
		setTo('');
	};

	const handleFromAssetSelect = (value: IAssetsItem): void => {
		setSelectFromAsset(value);

		searchParams.set('from', value.code);

		if (selectToAsset) {
			// clearTimeout(timeout);
			autoReloadExchangeRate(value.id, selectToAsset.id);
		}

		setFrom('');
		setTo('');
	};

	const handleToWalletSelect = (value: IWalletItem): void => {
		setSelectToWallet(value);
		searchParams.set('to', value.asset.code);

		if (selectFromWallet) {
			// clearTimeout(timeout);
			autoReloadExchangeRate(selectFromWallet.asset.id, value.asset.id);
		}

		setFrom('');
		setTo('');
	};

	const handleToAssetSelect = (value: IAssetsItem): void => {
		setSelectToAsset(value);
		searchParams.set('to', value.code);

		if (selectFromAsset) {
			// clearTimeout(timeout);
			autoReloadExchangeRate(selectFromAsset.id, value.id);
		}

		setFrom('');
		setTo('');
	};

	const calculateExchangeAmount = useCallback(() => {
		if (!exchangeRate) {
			return;
		}
		if (from && !to) {
			const toValue = convertExponentialToDecimal(+from * +exchangeRate.rate);
			setTo(toValue.toString());
		} else if (!from && to) {
			const fromValue = convertExponentialToDecimal(+to / +exchangeRate.rate);
			setFrom(fromValue.toString());
		}
	}, [exchangeRate, from, to]);

	const handleChangeFrom = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (numberValidation(value)) {
			setTo('');
			setFrom(value);
			if (searchParams.has('amountTo')) {
				searchParams.delete('amountTo');
			}
			searchParams.set('amountFrom', value);
		}
	};

	const handleChangeTo = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (numberValidation(value)) {
			setFrom('');
			setTo(value);
			if (searchParams.has('amountFrom')) {
				searchParams.delete('amountFrom');
			}
			searchParams.set('amountTo', value);
		}
	};

	const handleWalletsSwap = () => {
		if (isAuth) {
			setSelectFromWallet(selectToWallet);
			setSelectToWallet(selectFromWallet);
		} else {
			setSelectFromAsset(selectToAsset);
			setSelectToAsset(selectFromAsset);
		}

		setIsReverse(!isReverse);

		setFrom('');
		setTo('');
	};

	const handleReverseClick = () => {
		setButtonDisabled(true);
		setTimeout(() => {
			setButtonDisabled(false);
		}, 1000);
		handleWalletsSwap();
	};

	const currentAssetPairLimits = useMemo(() => {
		const fromCode = isAuth ? selectFromWallet?.asset?.code : selectFromAsset?.code;
		const toCode = isAuth ? selectToWallet?.asset?.code : selectToAsset?.code;
		const currentPair = `${String(fromCode)}_${String(toCode)}`;
		const currentPairReverse = `${String(toCode)}_${String(fromCode)}`;

		if (assetPairsFeesAndLimit?.length) {
			setIsReverse(() => {
				const result = assetPairsFeesAndLimit.find((pair) => pair.code === currentPair);
				return !result;
			});
		}

		return assetPairsFeesAndLimit?.length
			? assetPairsFeesAndLimit.find(
					(pair) => pair.code === currentPair || pair.code === currentPairReverse,
			  )
			: null;
	}, [
		assetPairsFeesAndLimit,
		selectFromWallet,
		selectToWallet,
		selectFromAsset,
		selectToAsset,
		isAuth,
	]);

	const currentAssetPairLimitsAmountMin = userIsVip
		? currentAssetPairLimits?.vip_amount_min || 0
		: currentAssetPairLimits?.amount_min || 0;
	const currentAssetPairLimitsAmountMax = userIsVip
		? currentAssetPairLimits?.vip_amount_max || 0
		: currentAssetPairLimits?.amount_max || 0;

	const getPlaceHolderTextValue = (type: string) => {
		if (type === 'from') {
			return !isReverse
				? `${String(L.translate('ConvertForm.please_enter'))} ${
						convertExponentialToDecimal(currentAssetPairLimitsAmountMin) || '0.00000001'
				  } - ${convertExponentialToDecimal(currentAssetPairLimitsAmountMax) || '99999999'}`
				: `${String(L.translate('ConvertForm.please_enter'))} ${
						convertExponentialToDecimal(
							fixedCropNumber(
								Number(currentAssetPairLimitsAmountMin) / Number(exchangeRate?.rate),
								8,
							),
						) || '0.00000001'
				  } - ${
						convertExponentialToDecimal(
							fixedCropNumber(
								Number(currentAssetPairLimitsAmountMax) / Number(exchangeRate?.rate),
								8,
							),
						) || '99999999'
				  }`;
		}

		if (type === 'to') {
			return !isReverse
				? `${String(L.translate('ConvertForm.please_enter'))} ${
						convertExponentialToDecimal(
							fixedCropNumber(
								Number(currentAssetPairLimitsAmountMin || 0) * Number(exchangeRate?.rate || 0),
								8,
							),
						) || '0.00000001'
				  } - ${
						convertExponentialToDecimal(
							fixedCropNumber(
								Number(convertExponentialToDecimal(currentAssetPairLimitsAmountMax) || 0) *
									Number(exchangeRate?.rate || 0),
								8,
							),
						) || '99999999'
				  }`
				: `${String(L.translate('ConvertForm.please_enter'))} ${convertExponentialToDecimal(
						currentAssetPairLimitsAmountMin,
				  )} - ${convertExponentialToDecimal(currentAssetPairLimitsAmountMax)}`;
		}

		return '';
	};

	useEffect(() => {
		calculateExchangeAmount();
	}, [calculateExchangeAmount]);

	useEffect(() => {
		if (isAuth) {
			if (walletsList?.length && assetPairs?.length) {
				const defaultFrom = assetPairs?.[0]?.code?.split('_')[0];
				const fromWallet =
					walletsList?.find(
						(wallet) =>
							wallet?.asset?.code ===
							(selectFromWallet ? selectFromWallet?.asset?.code : defaultFrom),
					) || null;
				const toWallet =
					walletsList?.find(
						(wallet) =>
							wallet?.asset?.code === (selectToWallet ? selectToWallet?.asset?.code : 'usdb'),
					) || null;

				setSelectFromWallet(fromWallet);
				setSelectToWallet(toWallet);
				fromWallet && searchParams.set('from', fromWallet.asset.code);
				toWallet && searchParams.set('to', toWallet.asset.code);

				if (fromWallet && toWallet) {
					autoReloadExchangeRate(fromWallet.asset.id, toWallet.asset.id);
				}
			}
		} else {
			const defaultFrom =
				assetsList?.find((item) => item.code === assetPairs?.[0]?.code?.split('_')[0]) || null;
			const defaultTo = assetsList?.find((item) => item.code === 'usdb') || null;
			setSelectFromAsset(defaultFrom);
			setSelectToAsset(defaultTo);

			defaultFrom && searchParams.set('from', defaultFrom.code);
			defaultTo && searchParams.set('to', defaultTo.code);

			if (defaultFrom && defaultTo) {
				autoReloadExchangeRate(defaultFrom.id, defaultTo.id);
			}
		}
		// return () => {
		// 	clearTimeout(timeout);
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletsList, assetPairs, toWalletsList, isAuth, assetsList]);

	return (
		<div className="convert convert--new-type">
			<div className="enter-value enter-value--new">
				<div className="enter-value__header">
					<span className="enter-value__label">{String(L.translate('ConvertForm.from'))}</span>
				</div>
				<div className="enter-value__field">
					<div className="input input--margin-none">
						<div className="input-wrapper">
							<input
								className="input-item input-item--enter-value input-item--convert-pc"
								type="text"
								placeholder={getPlaceHolderTextValue('from')}
								onChange={handleChangeFrom}
								value={from}
							/>
							<input
								className="input-item input-item--enter-value input-item--convert-mob "
								type="text"
								placeholder={getPlaceHolderTextValue('from')}
								onChange={handleChangeFrom}
								value={from}
							/>
						</div>
					</div>
					{isAuth ? (
						<ConvertDropdown
							value={selectFromWallet}
							onChange={handleFromWalletSelect}
							options={fromWalletsList}
						/>
					) : (
						<ConvertAssetDropdown
							value={selectFromAsset}
							onChange={handleFromAssetSelect}
							options={fromAssetsList || []}
						/>
					)}
				</div>
			</div>
			<div className="switch-img switch-img--new-type">
				<button
					type="button"
					className="switch-img__circle"
					disabled={isButtonDisabled}
					onClick={handleReverseClick}
				>
					<div className="switch-img__wrap">
						<span className="switch-img__icon icon-arrow-3" />
					</div>
				</button>
			</div>
			<div className="enter-value enter-value--new">
				<div className="enter-value__header">
					<span className="enter-value__label">{String(L.translate('ConvertForm.to'))}</span>
				</div>
				<div className="enter-value__field">
					<div className="input input--margin-none">
						<div className="input-wrapper">
							<input
								className="input-item input-item--enter-value input-item--convert-pc "
								type="text"
								placeholder={getPlaceHolderTextValue('to')}
								onChange={handleChangeTo}
								value={to}
							/>
							<input
								className="input-item input-item--enter-value input-item--convert-mob "
								type="text"
								placeholder={getPlaceHolderTextValue('to')}
								onChange={handleChangeTo}
								value={to}
							/>
						</div>
					</div>
					{isAuth ? (
						<ConvertDropdown
							value={selectToWallet}
							onChange={handleToWalletSelect}
							options={toWalletsList}
						/>
					) : (
						<ConvertAssetDropdown
							value={selectToAsset}
							onChange={handleToAssetSelect}
							options={toAssetsList || []}
						/>
					)}
				</div>
			</div>
			{isAuth && selectFromWallet?.asset?.code && selectToWallet?.asset?.code && (
				<ExchangeRate
					fromCode={selectFromWallet?.asset?.code}
					toCode={selectToWallet?.asset?.code}
				/>
			)}
			{!isAuth && selectFromAsset?.code && selectToAsset?.code && (
				<ExchangeRate fromCode={selectFromAsset.code} toCode={selectToAsset.code} />
			)}
			<div className="convert__footer">
				<button
					type="button"
					className="button button--full-width button--type3"
					disabled={isButtonDisabled}
					onClick={() => history.push(`/convert?${searchParams.toString()}`)}
				>
					{String(L.translate('ConvertForm.convert_button'))}
				</button>
			</div>
		</div>
	);
};

export default ConvertForm;
