import { FC } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { cardHideNumber } from 'services/utils/cardHelper';
import { getDepositFee } from 'redux/reducers/cards/selectors';
import { useSelector } from 'react-redux';
import { IModalProps } from '../FormCard/types';

export const ConfirmPopup: FC<IModalProps> = ({
	openModal,
	closeModal,
	message,
	valueSubmit,
	handleSubmit,
	title,
	checkCard,
	typePayment,
	selectWallet,
}) => {
	const depositFee = useSelector(getDepositFee);

	const assetCode = selectWallet?.asset?.code;
	const fiatCode = assetCode ? assetCode?.slice(0, assetCode.length - 1) : assetCode;

	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<button type="button" onClick={closeModal} className="popup-close">
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header popup-header--padding">
							<p className="popup-header__title">{title}</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="withdrawal-options withdrawal-options--mb16">
								{typePayment !== 'paypal' && (
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">
											{L.translate('DepositFiat.ConfirmPopup.holder_name')}
										</span>
										<span className="withdrawal-option__desc">{valueSubmit?.holder_name}</span>
									</div>
								)}
								{/* <div className="withdrawal-option">
									<span className="withdrawal-option__title">Card Type</span>
									<span className="withdrawal-option__desc">MasterCard</span>
								</div> */}
								{typePayment === 'paypal' ? null : (
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">
											{L.translate('DepositFiat.ConfirmPopup.card_number')}
										</span>
										<span className="withdrawal-option__desc">
											{cardHideNumber(
												String(
													checkCard?.holder_name === 'online'
														? valueSubmit?.card_number || ''
														: checkCard?.card_number || '',
												),
												12,
												16,
											)}
										</span>
									</div>
								)}

								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.ConfirmPopup.indicated_amount')}
									</span>
									<span className="withdrawal-option__desc">
										{valueSubmit?.amount} {fiatCode?.toLocaleUpperCase()}b
									</span>
								</div>
							</div>
							<div className="withdrawal-option withdrawal-option--mb12">
								<span className="withdrawal-option__title">
									{L.translate('DepositFiat.ConfirmPopup.fee')}
								</span>
								<span className="withdrawal-option__desc">
									{depositFee || '0'} {fiatCode?.toLocaleUpperCase()}b
								</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">
									{L.translate('DepositFiat.ConfirmPopup.you_receive')}
								</span>
								<span className="withdrawal-option__desc withdrawal-option__desc--fz20">
									{Number(valueSubmit?.amount) - Number(depositFee)} {fiatCode?.toLocaleUpperCase()}
								</span>
							</div>
						</div>
						<div className="popup-submit popup-submit--sb">
							<button
								type="button"
								onClick={closeModal}
								className="button button--second-grey button--full-width"
							>
								{L.translate('Global.cancel')}
							</button>
							<button type="button" onClick={handleSubmit} className="button button--full-width">
								{L.translate('Global.confirm')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
