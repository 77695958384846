import { FC } from 'react';

interface IModalProps {
	openModal: boolean;
	closeModal: () => void;
	message: string;
}

const SuccessPopup: FC<IModalProps> = ({ openModal, closeModal, message }) => {
	if (openModal) {
		return (
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--width-410 popup--padding-type2">
						<button className="popup-close" type="button">
							<span className="popup-close__icon icon-close" onClick={closeModal} />
						</button>
						<div className="popup-icon--flex">
							<div className="leave-review__icon">
								<svg
									width="24"
									height="19"
									viewBox="0 0 24 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M22 2.5L8.25 16.25L2 10"
										stroke="white"
										strokeWidth="4"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</div>
						<div className="popup-header">
							<p className="popup-header__title">{message}</p>
						</div>
						<div className="popup-submit popup-submit--sb">
							<button className="button button--full-width " type="button" onClick={closeModal}>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return null;
};

export default SuccessPopup;
