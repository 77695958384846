import { FC } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { IModalConfirmProps } from '../types';

export const ConfirmPopup: FC<IModalConfirmProps> = ({
	openModal,
	closeModal,
	message,
	data,
	fee,
	amount,
	selectWallet,
	handleSubmit,
}) => {
	const recive = amount && Number(amount) - Number(fee);
	const assetCode = selectWallet?.asset?.code;
	const fiatCode = assetCode?.slice(0, assetCode.length - 1);

	if (openModal) {
		return (
			<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
				<div className="popup-window">
					<div className="popup-window__inside">
						<div className="popup popup--medium">
							<button className="popup-close" type="button">
								<span className="popup-close__icon icon-close" onClick={closeModal} />
							</button>
							<div className="popup-header">
								<p className="popup-header__title">
									{L.translate('WithdrawFiat.Popups.Confirm.title')}
								</p>
							</div>
							<div className="popup-body popup-body--type2">
								<div className="withdrawal-options ">
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">
											{L.translate('Fields.beneficiary_name')}
										</span>
										<span className="withdrawal-option__desc">{data?.beneficiary_name}</span>
									</div>
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">
											{L.translate('Fields.bank_name')}
										</span>
										<span className="withdrawal-option__desc">{data?.bank_name}</span>
									</div>
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">SWIFT</span>
										<span className="withdrawal-option__desc">{data?.swift}</span>
									</div>
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">IBAN</span>
										<span className="withdrawal-option__desc">{data?.iban}</span>
									</div>
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">
											{L.translate('Fields.reference')}
										</span>
										<span className="withdrawal-option__desc">{data?.reference}</span>
									</div>
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">
											{L.translate('Fields.address')}
										</span>
										<span className="withdrawal-option__desc">{data?.address}</span>
									</div>
								</div>

								<div className="withdrawal-option-group">
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">
											{L.translate('Fields.indicated_amount')}
										</span>
										<span className="withdrawal-option__desc">
											{amount} {fiatCode?.toUpperCase()}b
										</span>
									</div>
									<div className="withdrawal-option ">
										<span className="withdrawal-option__title">{L.translate('Fields.fee')}</span>
										<span className="withdrawal-option__desc">
											{fee} {fiatCode?.toUpperCase()}b
										</span>
									</div>
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">
											{L.translate('Fields.you_receive')}
										</span>
										<span className="withdrawal-option__desc withdrawal-option__desc--bigger">
											{recive} {fiatCode?.toUpperCase()}
										</span>
									</div>
								</div>
							</div>
							<div className="popup-submit popup-submit--sb">
								<button
									onClick={() => closeModal()}
									type="button"
									className="button button--second-grey button--full-width"
								>
									{L.translate('Global.cancel')}
								</button>
								<button
									onClick={() => handleSubmit(undefined)}
									type="button"
									className="button button--full-width"
								>
									{L.translate('Global.confirm')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		);
	}
	return null;
};
