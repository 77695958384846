import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from 'layouts/Dashboard';
import OpenTrades from 'components/P2PWallet/P2Psubpages/OpenTrades';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { getAccountP2P } from 'redux/reducers/auth/selectors';

// ==========================================:
const P2POpenTrades: FC = () => {
	const accountP2P = useSelector(getAccountP2P);
	const dispatch = useDispatch();
	useEffect(() => {
		if (accountP2P) {
			dispatch(userSettingsRequest());
			dispatch(getAssetsRequest());
		}
	}, [dispatch, accountP2P]);
	return (
		<Dashboard title={String(L.translate('PageTitles.p2p_open_trades'))}>
			<OpenTrades />
		</Dashboard>
	);
};

export default P2POpenTrades;
