import { FC, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getLS, setLS } from './localStorage';
import { IConsistNull } from './type';
/* eslint-disable @typescript-eslint/no-explicit-any */
const ConsistNull: FC<IConsistNull> = ({ st, name, setStore }) => {
	const dispatch = useDispatch();
	const value = useSelector((store: any) => store[st][name]);
	useEffect(() => {
		const ls = getLS(name);
		if (!ls) return;
		dispatch(setStore(ls));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		setLS(name, value);
	}, [value, name]);

	return <></>;
};

// ConsistNull.propTypes = {
// 	name: PropTypes.string.isRequired,
// 	setStore: PropTypes.func.isRequired,
// };

export default ConsistNull;
// eslint-disable-next-line
