import L from 'i18n-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import useClickOutside from 'hooks/useClickOutside';
import HeaderDropdown from 'ui/HeaderDropdown';
import { IHeaderDropdownList } from 'layouts-elements/Header/types';
import LangSwitcher from 'layouts-elements/Header/LangSwitcher/LangSwitcher';
import NotificationBell from 'components/NotificationMessage/NotificationBell';
import ThemeSwitch from '../ThemeSwitch';

// ==========================================:

interface IMobileHeader {
	walletList: IHeaderDropdownList[];
	mainNavList: IHeaderDropdownList[];
	ordersList: IHeaderDropdownList[];
}

const MobileHeader: FC<IMobileHeader> = ({ walletList, mainNavList, ordersList }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const isAuth = useSelector(getAuthIsAuthenticated);
	const userData = useSelector(getUserSettingsData);
	const currentPair = useSelector(getCurrentPair);
	const transformCurrentPair = currentPair?.toUpperCase();

	const [open, setOpen] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		dispatch(userSettingsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openMenuHandler = () => {
		setOpen(true);
	};
	const closeMenuHandler = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
		return () => {
			document.body.style.overflow = '';
		};
	}, [open]);

	useClickOutside(ref, closeMenuHandler);

	const handleSelect = (value: IHeaderDropdownList): void => {
		value.link && history.push(value.link);
	};

	const handleLogout = () => {
		dispatch(logoutRequest({ history }));
		closeMenuHandler();
	};

	return (
		<div className="sandwich">
			<button
				type="button"
				onClick={openMenuHandler}
				className="sandwich__open-btn"
				aria-label="sandwich open button"
			>
				<span className="sandwich__line" />
				<span className="sandwich__line" />
				<span className="sandwich__line" />
			</button>
			<div
				data-sandwich="wrapper"
				className={`sandwich__main ${open ? 'sandwich__main--active' : ''}`}
			>
				<div
					className={`sandwich__content ${open ? 'sandwich__content--active' : ''}`}
					style={open ? { right: 0 } : {}}
					ref={ref}
				>
					<div className="sandwich__header">
						<ThemeSwitch />
						<button type="button" onClick={closeMenuHandler} className="sandwich__close-btn">
							<span className="sandwich__close-icon icon-close" />
						</button>
					</div>

					{!isAuth && (
						<div className="user-nav">
							<Link
								to="/login"
								className="button button--buy button--third-grey button--user-nav Click_Log_In"
							>
								{String(L.translate('LoginPage.login_btn'))}
							</Link>
							<Link to="/registration" className="button button--buy button--user-nav">
								{String(L.translate('RegistrPage.registr_btn'))}
							</Link>
						</div>
					)}

					{isAuth && (
						<div className="sandwich-user">
							<span className="sandwich-user__name">{userData?.email}</span>
							<span
								className={`sandwich-user__verify 
									${userData?.status?.name === 'pending' ? 'sandwich-user--pending' : ''}
									${userData?.status?.name === 'approved' ? 'sandwich-user--approved' : ''}
								`}
							>
								{userData?.status?.name.toUpperCase()}
							</span>
						</div>
					)}

					{isAuth && (
						<HeaderDropdown
							placeholder={`${String(L.translate('Menu.Wallet.wallet'))}`}
							placeholderIcon="icon-wallet"
							onChange={handleSelect}
							options={walletList}
							closeAfterClickOutside={false}
						/>
					)}

					{isAuth ? (
						<HeaderDropdown
							placeholder={String(L.translate('Menu.Trade.trade'))}
							placeholderIcon="icon-wallet"
							onChange={handleSelect}
							options={mainNavList}
							closeAfterClickOutside={false}
						/>
					) : (
						<nav className="main-nav sandwich__nav">
							<ul className="main-nav__list">
								<li className="main-nav__item">
									<Link to="/convert" className="main-nav__link">
										<span className="main-nav__icon icon-bitcoin-convert" />
										{String(L.translate('Menu.Trade.convert'))}
									</Link>
								</li>
								<li className="main-nav__item">
									<Link to={`/spot/${String(transformCurrentPair)}`} className="main-nav__link">
										<span className="main-nav__icon icon-money-recive" />
										{String(L.translate('Menu.Trade.spot'))}
									</Link>
								</li>
								{/* <li className="main-nav__item">
									<Link
										to={`/MarginTrading/${String(transformCurrentPair)}?type=cross`}
										className="main-nav__link"
									>
										<span className="main-nav__icon icon-trade" />
										{String(L.translate('Menu.Trade.margin'))}
									</Link>
								</li> */}
								<li className="main-nav__item">
									<Link to="/p2p-trade" className="main-nav__link">
										<span className="main-nav__icon icon-user" />
										{String(L.translate('Menu.Trade.p2p'))}
									</Link>
								</li>
							</ul>
						</nav>
					)}

					{isAuth && (
						<HeaderDropdown
							placeholder={`${String(L.translate('Menu.Orders.orders'))}`}
							placeholderIcon="icon-document"
							onChange={handleSelect}
							options={ordersList}
						/>
					)}

					{isAuth && (
						<div className="select sandwich__select">
							<NotificationBell />
						</div>
					)}

					<div className="sandwich__lang">
						<LangSwitcher />
					</div>

					{/* <div className="select sandwich__select">
						<Link to="/" className="select__current">
							<span className="select__icon icon-receive-square" />
							{String(L.translate('Menu.download'))}
						</Link>
					</div> */}

					{isAuth && (
						<div className="select sandwich__select">
							<button type="button" onClick={handleLogout} className="select__current">
								<span className="select__icon icon-Logout" />
								{String(L.translate('log_out'))}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MobileHeader;
