import { FC } from 'react';
import { IFAQItem } from './types';

const FAQItem: FC<IFAQItem> = ({ question, answer, renderAnswer, active, index, setActive }) => {
	return (
		<div className={`faq ${active === index ? 'active' : ''}`}>
			<div className="faq__header" onClick={() => setActive(index)}>
				<p className="faq__header-title">{question}</p>
				<button type="button" className="faq__header-btn" aria-label="faq header button">
					{active === index ? (
						<svg
							width="10"
							height="7"
							viewBox="0 0 10 7"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.32615 0.5H0.67383C0.0747082 0.5 -0.22532 1.26637 0.198308 1.71457L4.5245 6.29165C4.78707 6.56945 5.21293 6.56945 5.4755 6.29165L9.80164 1.71457C10.2254 1.26637 9.92529 0.5 9.32615 0.5Z"
								fill="#01194D"
							/>
						</svg>
					) : (
						<svg
							width="10"
							height="7"
							viewBox="0 0 10 7"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.32615 0.5H0.67383C0.0747082 0.5 -0.22532 1.26637 0.198308 1.71457L4.5245 6.29165C4.78707 6.56945 5.21293 6.56945 5.4755 6.29165L9.80164 1.71457C10.2254 1.26637 9.92529 0.5 9.32615 0.5Z"
								fill="#01194D"
							/>
						</svg>
					)}
				</button>
			</div>
			<div className="faq__text">
				<p>{renderAnswer?.() || answer}</p>
			</div>
		</div>
	);
};

export default FAQItem;
