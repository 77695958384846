import { FC } from 'react';
import { IInforamtionProps } from './type';

// ==========================================:
const Information: FC<IInforamtionProps> = ({ text }) => {
	return (
		<div className="section">
			<div className="container">
				<div className="inforamtion">{text}</div>
			</div>
		</div>
	);
};

export default Information;
