/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getMarginWalletBorrowHistoryRequest } from 'redux/reducers/marginWallets/reducer';
import Pagination from 'ui/Pagination';
import {
	getBorrowingHistory,
	getMarginHistoryIsLoader,
} from 'redux/reducers/marginWallets/selectors';

import Loader from 'ui/Loader';
import BorrowItem from '../BorrowItem';

const BorrowTable: FC = () => {
	const borrowing = useSelector(getBorrowingHistory);
	const marginHistoryLoad = useSelector(getMarginHistoryIsLoader);
	const totalPages = borrowing?.last_page || 1;

	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		if (authIsAuthenticated) {
			dispatch(
				getMarginWalletBorrowHistoryRequest({
					params: {
						per_page: 10,
						current_page: currentPage,
					},
				}),
			);
		}
	}, [dispatch, authIsAuthenticated, currentPage]);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<div className="table table--margin-borrowing table--wallet-type">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.Borrowing.coin')}</span>
						</div>
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.Borrowing.date')}</span>
						</div>
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.Borrowing.amount')}</span>
						</div>
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.Borrowing.type')}</span>
						</div>
						<div className="td">
							<span className="td-title">{L.translate('Margin.Tables.Borrowing.status')}</span>
						</div>
					</div>
				</div>
				<div className="table-body">
					{marginHistoryLoad && (
						<div className="open-orders-history-loader-wrapper">
							<Loader />
						</div>
					)}
					{!marginHistoryLoad && borrowing?.data?.length
						? borrowing?.data?.map((item) => <BorrowItem key={item.id} data={item} />)
						: null}

					{!marginHistoryLoad && borrowing?.data?.length === 0 && (
						<span className="table-empty">
							{String(L.translate('Margin.Tables.Borrowing.no_orders_text'))}
						</span>
					)}
				</div>
			</div>
			{totalPages > 1 && (
				<Pagination
					pageCount={totalPages}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default BorrowTable;
