import { FC, useEffect, useState, ChangeEvent, useMemo } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { transformMarginsIsolatedData } from 'services/utils/transformMarginsIsolatedData';
import { getMarginWallets } from 'redux/reducers/marginWallets/selectors';
import { textSearchValid } from 'services/utils/textSearch';
import { IWalletsIsolatedDataType } from 'redux/reducers/marginWallets/types';
import MarginBalances from '../MarginBalances';
import IsolatedTable from './IsolatedTable/index';
import { IMarginCossProps } from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

const MarginIsolated: FC<IMarginCossProps> = ({
	handleSetBalancesIsHide,
	balancesIsHide,
	currentTab,
}) => {
	const [walletsMarginIsolated, setWalletsMarginIsolated] = useState<IWalletsIsolatedDataType>([]);
	const [searchCoins, setSearchCoins] = useState('');
	const [smallBalancesIsHide, setSmallBalancesIsHide] = useState(false);

	const marginWallets = useSelector(getMarginWallets);

	const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setSearchCoins(value);
	};

	const handleChangeSmallBalancesIsHide = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;

		setSmallBalancesIsHide(checked);
	};

	useEffect(() => {
		if (marginWallets?.marginIsolatedWallets) {
			setWalletsMarginIsolated(
				transformMarginsIsolatedData(marginWallets?.marginIsolatedWallets?.data?.isolated),
			);
		}
	}, [marginWallets?.marginIsolatedWallets]);

	const cryptoWalletsList = useMemo(() => {
		if (!walletsMarginIsolated?.length) {
			return [];
		}

		if (smallBalancesIsHide && searchCoins) {
			return walletsMarginIsolated?.filter(
				(wallet: any) =>
					(Number(wallet.base?.balance) > 0.0001 &&
						textSearchValid(wallet.base.pair[0].code, 'pairCode', searchCoins, '', '')) ||
					(Number(wallet.quote?.balance) > 0.0001 &&
						textSearchValid(wallet.base.pair[0].code, 'pairCode', searchCoins, '', '')),
			);
		}

		if (smallBalancesIsHide) {
			return walletsMarginIsolated.filter(
				(wallet: any) =>
					Number(wallet.base?.balance) > 0.0001 || Number(wallet.quote?.balance) > 0.0001,
			);
		}

		if (searchCoins) {
			return walletsMarginIsolated.filter((el: any) =>
				textSearchValid(el.base.pair[0].code, 'pairCode', searchCoins, '', ''),
			);
		}

		return walletsMarginIsolated;
	}, [walletsMarginIsolated, smallBalancesIsHide, searchCoins]);

	return (
		<>
			<MarginBalances
				handleSetBalancesIsHide={handleSetBalancesIsHide}
				balancesIsHide={balancesIsHide}
				data={marginWallets.marginIsolatedWallets?.data}
				currentTab={currentTab}
			/>
			<div className="content-block">
				<div className="balance-section">
					<div className="balance-section__header">
						<div className="search search--big">
							<div className="input input--margin-none">
								<div className="input-wrapper">
									<input
										className="input-item input-item--sort input-item--right-icon"
										type="text"
										placeholder={String(L.translate('P2P.search_coin'))}
										value={searchCoins}
										onChange={handleChangeSearch}
									/>
									<button type="button" className="search-btn">
										<span className="search-btn__icon icon-search-normal" />
									</button>
								</div>
							</div>
						</div>
						<div className="checkbox checkbox--margin-none">
							<label className="checkbox__label">
								<input
									type="checkbox"
									className="hidden"
									checked={smallBalancesIsHide}
									onChange={handleChangeSmallBalancesIsHide}
								/>
								<span className=" checkbox__item">
									<span className="checkbox__item-icon">
										<span className="icon-Checkbox" />
									</span>
								</span>
								<span className="checkbox__text checkbox__text--grey">
									{String(L.translate('MarginTable.hide_small_balances'))}
								</span>
							</label>
						</div>
					</div>
					<div className="balance-block">
						<div className="balance-block">
							<IsolatedTable data={cryptoWalletsList} balancesIsHide={balancesIsHide} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MarginIsolated;
