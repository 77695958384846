/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import {
	exportWalletsCryptoHistory,
	getWalletsCryptoHistoryRequest,
} from 'redux/reducers/wallets/reducer';
import { getWalletsCryptoHistoryList, getWalletsList } from 'redux/reducers/wallets/selectors';
import { DebounceInput } from 'react-debounce-input';
import THDropdown from 'ui/THDropdown';
import THAssetDropdown from 'ui/THAssetDropdown';
import THTimeDropdown from 'ui/THTimeDropdown';
import { IWalletItem, IWalletsCryptoHistoryRequestData } from 'redux/reducers/wallets/types';
import Pagination from 'ui/Pagination';
import CryptoTable from './CryptoTable';

// ==========================================:
const CryptoHistory: FC = () => {
	const dispatch = useDispatch();

	const walletsCryptoHistoryList = useSelector(getWalletsCryptoHistoryList);
	const walletsList = useSelector(getWalletsList);

	const [searchTxID, setSearchTxID] = useState<string>('');
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [selectType, setSelectType] = useState<string>('All');
	const typeOptions = {
		All: String(L.translate('WalletsTransactionHistory.all')),
		Withdrawal: String(L.translate('WalletsTransactionHistory.withdrawal')),
		Deposit: String(L.translate('WalletsTransactionHistory.deposit')),
	};
	const [selectTime, setSelectTime] = useState<number | string>('All');
	const timeOptions = [String(L.translate('WalletsTransactionHistory.all')), 7, 30, 90];
	const [selectAsset, setSelectAsset] = useState<IWalletItem | string>('All');
	const [selectStatus, setSelectStatus] = useState<string>('All');
	const statusOptions = {
		All: String(L.translate('WalletsTransactionHistory.all')),
		Success: String(L.translate('WalletsTransactionHistory.success')),
		Pending: String(L.translate('WalletsTransactionHistory.pending')),
		Rejected: String(L.translate('WalletsTransactionHistory.rejected')),
		aml_on_hold: String(L.translate('WalletsTransactionHistory.aml_on_hold')),
		aml_rejected: String(L.translate('WalletsTransactionHistory.aml_rejected')),
	};

	const totalPages = walletsCryptoHistoryList?.last_page || 1;

	const handleTypeSelect = (value: string) => {
		setSelectType(value);
		setCurrentPage(1);
		setSearchTxID('');
	};

	const handleTimeSelect = (value: number) => {
		setSelectTime(value);
		setCurrentPage(1);
		setSearchTxID('');
	};

	const handleAssetSelect = (value: IWalletItem | string) => {
		setSelectAsset(value);
		setCurrentPage(1);
		setSearchTxID('');
	};

	const handleStatusSelect = (value: string) => {
		setSelectStatus(value);
		setCurrentPage(1);
		setSearchTxID('');
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		setSearchTxID('');
	};
	const handleResetClick = () => {
		setSelectType('All');
		setSelectStatus('All');
		setSelectTime('All');
		setSelectAsset('All');
		setSelectStatus('All');
	};
	const handleResetClickTxID = () => {
		setSearchTxID('');
	};
	const handleExportHistoryClick = () => {
		if (walletsCryptoHistoryList?.data?.length) {
			const requestWalletsCryptoHistoryData: IWalletsCryptoHistoryRequestData = {
				params: {
					per_page: 10,
					current_page: currentPage,
					export: true,
				},
			};

			if (typeof selectAsset === 'object' && selectAsset.id) {
				requestWalletsCryptoHistoryData.params.wallet_id = selectAsset.id;
			}

			if (selectTime !== 'All') {
				requestWalletsCryptoHistoryData.params.past_days = selectTime;
			}

			if (selectStatus !== 'All') {
				requestWalletsCryptoHistoryData.params.status = selectStatus?.toLowerCase();
			}

			if (selectType !== 'All') {
				requestWalletsCryptoHistoryData.params.transaction_type = selectType?.toLowerCase();
			}

			if (searchTxID.trim()) {
				requestWalletsCryptoHistoryData.params.tx_id = searchTxID.trim();
			}

			dispatch(exportWalletsCryptoHistory(requestWalletsCryptoHistoryData));
		}
	};

	const handleSearchTransaction = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setSearchTxID(value);

		const requestWalletsCryptoHistoryData: IWalletsCryptoHistoryRequestData = {
			params: {
				per_page: 10,
				current_page: currentPage,
			},
		};

		if (value?.trim()) {
			requestWalletsCryptoHistoryData.params.tx_id = value?.trim();
			handleResetClick();
			if (requestWalletsCryptoHistoryData.params.tx_id.length === 0) {
				dispatch(getWalletsCryptoHistoryRequest(requestWalletsCryptoHistoryData));
			} else {
				const paramsTxId = {
					params: {
						tx_id: requestWalletsCryptoHistoryData.params.tx_id,
					},
				};
				dispatch(getWalletsCryptoHistoryRequest(paramsTxId));
			}
		}
	};

	useEffect(() => {
		setCurrentPage(1);
	}, [selectType]);

	useEffect(() => {
		if (searchTxID) return;

		const requestWalletsCryptoHistoryData: IWalletsCryptoHistoryRequestData = {
			params: {
				per_page: 10,
				current_page: currentPage,
			},
		};

		if (typeof selectAsset === 'object' && selectAsset.id) {
			requestWalletsCryptoHistoryData.params.wallet_id = selectAsset.id;
		}

		if (selectTime !== 'All') {
			requestWalletsCryptoHistoryData.params.past_days = selectTime;
		}

		console.log(Object.entries(statusOptions).find((item) => item[1] === selectStatus)?.[0]);

		if (
			selectStatus !== 'All' &&
			Object.entries(statusOptions).find((item) => item[1] === selectStatus)?.[0] !== 'All'
		) {
			requestWalletsCryptoHistoryData.params.status = Object.entries(statusOptions)
				.find((item) => item[1] === selectStatus)?.[0]
				?.toLowerCase();
		}

		if (
			selectType !== 'All' &&
			Object.entries(typeOptions).find((item) => item[1] === selectType)?.[0] !== 'All'
		) {
			requestWalletsCryptoHistoryData.params.transaction_type = Object.entries(typeOptions)
				.find((item) => item[1] === selectType)?.[0]
				?.toLowerCase();
		}

		dispatch(getWalletsCryptoHistoryRequest(requestWalletsCryptoHistoryData));
	}, [dispatch, selectAsset, selectStatus, selectType, selectTime, currentPage, searchTxID]);

	const foo = () => {
		return Object.entries(statusOptions).find((item) => item[1] === selectStatus)?.[0];
	};
	console.log(foo());

	return (
		<>
			<div className="transactions__filter transactions-history-filter">
				<div className="transactions__filter-right">
					<div className="transactions__select-block select-block">
						<p className="select-block__name">{String(L.translate('CryptoHistory.type'))}</p>
						<THDropdown
							value={selectType}
							onChange={handleTypeSelect}
							options={Object.values(typeOptions)}
						/>
					</div>

					<div className="transactions__select-block select-block">
						<p className="select-block__name">{String(L.translate('CryptoHistory.period'))}</p>
						<THTimeDropdown value={selectTime} onChange={handleTimeSelect} options={timeOptions} />
					</div>
					<div className="transactions__select-block select-block">
						<p className="select-block__name">{String(L.translate('CryptoHistory.asset'))}</p>
						<THAssetDropdown
							value={selectAsset}
							onChange={handleAssetSelect}
							options={walletsList?.filter((wallet) => wallet.asset.type !== 'fiat') || []}
						/>
					</div>
					<div className="transactions__select-block select-block">
						<p className="select-block__name">{String(L.translate('CryptoHistory.status'))}</p>
						<THDropdown
							value={selectStatus}
							onChange={handleStatusSelect}
							options={Object.values(statusOptions)}
						/>
					</div>
					<div className="transactions__input select-block">
						<p className="select-block__name">{String(L.translate('CryptoHistory.txid'))}</p>
						<div className="input-wrapper">
							<DebounceInput
								debounceTimeout={200}
								type="text"
								placeholder={String(L.translate('CryptoHistory.enter_txid'))}
								className="input-item"
								name="tx_id"
								value={searchTxID}
								onChange={handleSearchTransaction}
							/>
						</div>
					</div>
				</div>

				<div className="transactions__filter-left transactions__filter-left-wrapper">
					<button
						className="transactions__filter-btn reset-btn"
						type="button"
						onClick={() => {
							handleResetClick();
							handleResetClickTxID();
						}}
					>
						{String(L.translate('CryptoHistory.reset_filter'))}
					</button>
					<button
						className="transactions__filter-btn transactions__filter-btn--convert"
						type="button"
						onClick={handleExportHistoryClick}
					>
						<span className="btn-cover-icon icon-arrow" />
						{String(L.translate('CryptoHistory.export_history'))}
					</button>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="transactions-table table transactions-table--crypto">
						<CryptoTable data={walletsCryptoHistoryList?.data || []} />

						{totalPages > 1 && (
							<Pagination
								pageCount={totalPages}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default CryptoHistory;
