import { FC } from 'react';
import L from 'i18n-react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { APP_NAME } from 'services/constants/env';
import Header from 'layouts-elements/Header';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import VerificationBanner from 'ui/VerificationBanner';
import { appLanguage } from 'redux/reducers/app/selectors';
import ogImage from 'assets/images/content/binatica-og.png';
import { getLocale } from 'services/utils/language';
import { IMarketplaceP2P } from './types';

// ==========================================:
const MarketplaceP2P: FC<IMarketplaceP2P> = ({ title, children, showVerificBanner }) => {
	const pageTitle = title ? `| ${title}` : '';
	const language = useSelector(appLanguage);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)}.com ${pageTitle}`}</title>
				<meta name="description" content={String(L.translate('MetaTags.Description.p2p_trade'))} />

				{/* Open Graph Meta Tags */}
				<meta
					property="og:title"
					content="Bitanica.com | P2P Crypto Exchange | Direct Crypto Trading"
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content={`${window.location.host}/p2p-trade`} />
				<meta property="og:image" content={ogImage} />
				<meta
					property="og:description"
					content="Join our P2P crypto exchange to trade Bitcoin, Ethereum, and more directly with others on your own terms. Secure and accessible in virtually any country. Start trading today!"
				/>
				<meta property="og:site_name" content="Bitanica.com" />
				<meta property="og:locale" content={getLocale(language)} />

				{/* Twitter Card Meta Tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:title"
					content="Bitanica.com | P2P Crypto Exchange | Direct Crypto Trading"
				/>
				<meta
					name="twitter:description"
					content="Join our P2P crypto exchange to trade Bitcoin, Ethereum, and more directly with others on your own terms. Secure and accessible in virtually any country. Start trading today!"
				/>
				<meta
					name="twitter:image"
					content={`${window.location.host}/p2p-trade/images/your-image.jpg`}
				/>
				<meta name="twitter:site" content="@YourTwitterHandle" />
			</Helmet>
			{/* Временный вариант, без этого почему то файл og:image не подгружается */}
			<img src={ogImage} alt="" style={{ display: 'none' }} />
			{/* ---------------------- */}
			<div className="wrapper">
				<Header />
				<Content>
					{showVerificBanner && <VerificationBanner />}
					{children}
				</Content>
				<Footer />
			</div>
		</>
	);
};

export default MarketplaceP2P;
