/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, MouseEvent, useState, useEffect, ChangeEvent } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import ButtonSwitcher from 'ui/ButtonSwitcher';
import Loader from 'ui/Loader';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	setCurrentType,
	getProposalRequest,
	postAdvertisementRequest,
	getP2PBalancesListRequest,
	getAdvertisementRequest,
} from 'redux/reducers/p2p/reducer';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';

import {
	getCurrentType,
	getCurrencyItem,
	getCountry,
	getP2PAssets,
	getCurrentPayments,
	getCurrentCoin,
	getP2PSateLoader,
	getPaymentWindow,
	getP2PBalancesList,
	getUserAdvert,
	getCheckAmountLoading,
} from 'redux/reducers/p2p/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { IPostAdvertisementResp } from 'redux/reducers/p2p/types';

import PAFPriceBox from './PAFPriceBox';
import PAFTradeBox from './PAFTradeBox';
import PAFPaymentBox from './PAFPaymentBox';
import PAFTymeLine from './PAFTymeLine';
import SecurityVerify from './SecurityVerification';
import ConfirmToPost from './ConfirmToPost';
import SuccessfullyPosted from './SuccessfullyPosted';
import { IState } from './types';

/* eslint-disable no-debugger */
const CreateAdvertisement: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory<History>();
	const location = useLocation<IState>();

	const params = useParams<{ id?: string }>();

	const { state } = location;

	const type = useSelector(getCurrentType);
	const currencyItem = useSelector(getCurrencyItem);
	const country = useSelector(getCountry);
	const p2pAssets = useSelector(getP2PAssets);
	const currency = useSelector(getCurrentCoin);
	const payments = useSelector(getCurrentPayments);
	const p2pLoader = useSelector(getP2PSateLoader);
	const paymentWindow = useSelector(getPaymentWindow);
	const balancesList = useSelector(getP2PBalancesList);
	const userData = useSelector(getUserSettingsData);
	const userAdvert = useSelector(getUserAdvert);
	const checkAmountLoading = useSelector(getCheckAmountLoading);

	const [description, setDescription] = useState('');
	const [margin, setMargin] = useState(100);
	const [amount, setAmount] = useState('');
	const [amountMin, setAmountMin] = useState<string | number>('');
	const [amountMax, setAmountMax] = useState<string | number>('');
	const [tradeBox1DataLoaded, setTradeBox1DataLoaded] = useState(false);
	const [tradeBox2DataLoaded, setTradeBox2DataLoaded] = useState(false);
	const [tradeBox3DataLoaded, setTradeBox3DataLoaded] = useState(false);
	const [typeLoaded, setTypeLoaded] = useState(false);
	const [verifyOpen, setVerifyOpen] = useState(false);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [postedPopup, setPostedPopup] = useState(false);
	const [postedData, setPostedData] = useState<IPostAdvertisementResp>();
	const [marginError, setMarginError] = useState<string | null>(null);
	const [countryError, setCountryError] = useState<string | null>(null);

	// console.log('emailCode', emailCode);
	// console.log('smsCode', smsCode);
	// console.log('totp', totp);

	const [step, setStep] = useState(0);
	const assetItem = p2pAssets?.find((item) => item.code === currency);

	const balance = balancesList?.balances?.find((item) => item.asset_code === currency)?.balance;

	// const minAdvert = assetItem && assetItem.limits && currentWallet[0].currency.advertisement_min;

	useEffect(() => {
		// const assetItem = p2pAssets?.find((item) => item.code === currency);
		if (currencyItem && type && assetItem) {
			dispatch(
				getProposalRequest({
					params: {
						currency_id: currencyItem.id,
						type,
						asset_id: Number(assetItem?.id),
					},
				}),
			);
		}
	}, [dispatch, currencyItem, type, currency, assetItem]);

	useEffect(() => {
		if (balancesList) {
			return;
		}
		dispatch(getP2PBalancesListRequest());
	}, [balancesList, dispatch]);

	useEffect(() => {
		if (margin < 30) {
			setMarginError(String(L.translate('Advertisement.min_margin_error')));
			return;
		}
		if (margin > 500) {
			setMarginError(String(L.translate('Advertisement.max_margin_error')));
			return;
		}
		setMarginError(null);
	}, [margin]);

	useEffect(() => {
		if (confirmPopup || postedPopup) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
		return () => {
			document.body.style.overflow = '';
		};
	}, [confirmPopup, postedPopup]);

	useEffect(() => {
		if (country && countryError) {
			setCountryError(null);
		}
	}, [country, countryError]);

	useEffect(() => {
		if (userAdvert && params?.id === String(userAdvert?.id) && !typeLoaded) {
			setCurrentType(userAdvert.type);
			setTypeLoaded(true);
		}
	}, [params?.id, typeLoaded, userAdvert]);

	const marginChange = (value: number) => {
		setMargin(value);
	};

	useEffect(() => {
		if (!params.id) {
			return;
		}
		dispatch(getAdvertisementRequest({ id: Number(params.id) }));
	}, [dispatch, params.id]);

	useEffect(() => {
		dispatch(userSettingsRequest());
	}, [dispatch]);

	const toInitStates = () => {
		setDescription('');
		setAmountMax('');
		setAmountMin('');
		setAmount('');
		setMargin(100);
	};

	const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
		const { name } = e.currentTarget;
		toInitStates();
		dispatch(setCurrentType(name));
	};
	const backClick = () => {
		history.goBack();
	};

	const handleCreate = (verify: { emailCode?: string; smsCode?: string; totp?: string }) => {
		if (!currencyItem) return;
		if (!country) return;
		if (!assetItem) return;
		if (!type) return;
		// if (user.ban_trading) {
		// 	notification({
		// 		type: 'info',
		// 		message: L.translate('t_0360'),
		// 	});
		// 	return;
		// }
		if (!amount || !amountMax || !amountMin || !description) {
			notificationContainer(String(L.translate('Advertisement.all_fields_required')), 'info');
			setStep(0);
			return;
		}
		// if (+amount > +maxAdvert) {
		// 	notification({
		// 		type: 'info',
		// 		message: L.translate('t_0116', { maxAdvert }),
		// 	});
		// 	return;
		// }
		// if (+amount < +amountMin) {
		// 	notificationContainer('Total amount must be more than minimum amount', 'info');
		// 	return;
		// }
		// if (+amount < +amountMax) {
		// 	notificationContainer('Maximum amount must be less or equal total amount', 'info');
		// 	return;
		// }
		// if (+amountMin > +amountMax) {
		// 	notificationContainer('Minimum amount must be less or equal maximum amount', 'info');
		// 	return;
		// }
		// if (+amount < +minAdvert) {
		// 	notificationContainer('Maximum amount must be less or equal total amount', 'info');
		// 	notification({
		// 		type: 'info',
		// 		message: L.translate('t_0120', { minAdvert }),
		// 	});
		// 	return;
		// }
		// if (+amountMin < +minAdvert) {
		// 	notificationContainer('Maximum amount must be less or equal total amount', 'info');
		// 	notification({
		// 		type: 'info',
		// 		message: L.translate('t_0556', { minAdvert }),
		// 	});
		// 	return;
		// }
		// if (+amountMax > +maxAdvert) {
		// 	notificationContainer('Maximum amount must be less or equal total amount', 'info');
		// 	notification({
		// 		type: 'info',
		// 		message: L.translate('t_0116', { maxAdvert }),
		// 	});
		// 	return;
		// }
		// if (type === 'sell' && +amount > +walletBalance) {
		// 	notificationContainer('Maximum amount must be less or equal total amount', 'info');
		// 	notification({
		// 		type: 'info',
		// 		message: L.translate('t_0121'),
		// 	});
		// 	return;
		// }

		if (!paymentWindow) return;

		if (payments.length === 0) {
			notificationContainer(String(L.translate('Advertisement.choose_payment')), 'info');
			return;
		}

		const newData: any = {
			currency_id: currencyItem?.id,
			country_id: country?.id,
			asset_id: Number(assetItem?.id),
			type: params.id ? undefined : type,
			active: true,
			only_to_verified: true,
			margin,
			min_price: 1,
			max_price: 100000,
			amount,
			amount_min: Number(amountMin),
			amount_max: Number(amountMax),
			payment_methods: payments.map((item) => item.payment_method_id),
			payment_window: +paymentWindow,
			payment_description: description,
			margin_type: 'percent',
			email_code: verify.emailCode,
			sms_code: verify.smsCode,
			totp: verify.totp,
		};

		if (params.id) {
			newData.advertisement_id = Number(params.id);
		}

		dispatch(
			postAdvertisementRequest({
				data: { ...newData },
				onSuccess: (response) => {
					setPostedData(response);
					setPostedPopup(true);
				},
			}),
		);
	};

	const currentAsset = p2pAssets?.find((item) => item.code === currency);

	const sellerFee = userData?.is_vip ? currentAsset?.vip_seller_fee : currentAsset?.seller_fee;

	const sumLessThanBalance =
		type === 'sell'
			? Number(amount) + Number(amount) * (Number(sellerFee || 0) / 100) > Number(balance || 0)
			: false;

	const disabledAmount =
		!amount || !amountMax || !amountMin || sumLessThanBalance || checkAmountLoading;
	const disabledPrice = !currencyItem?.id || !assetItem?.id;
	const disabledDetails = !payments.length || !description;

	const disaledStep = (st: number): boolean => {
		switch (st) {
			case 0:
				return disabledPrice;
			case 1:
				return disabledAmount;
			case 2:
				return disabledDetails;
			default:
				return true;
		}
	};

	const changeStep = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		if (name === 'back' && step !== 0) {
			setStep(step - 1);
			return;
		}

		if (step === 0) {
			if (margin < 30) {
				notificationContainer(String(L.translate('Advertisement.min_margin_error')), 'info');
				return;
			}
			if (margin > 500) {
				notificationContainer(String(L.translate('Advertisement.max_margin_error')), 'info');
				return;
			}
			if (!country) {
				setCountryError(String(L.translate('Advertisement.country_required')));
				return;
			}
		}

		if (step === 1) {
			if (Number(amount) + Number(amount) * (Number(sellerFee || 0) / 100) < +amountMin) {
				notificationContainer(String(L.translate('Advertisement.min_amount_error')), 'info');
				return;
			}
			if (+amount < +amountMax) {
				notificationContainer(String(L.translate('Advertisement.max_amount_error')), 'info');
				return;
			}
			if (+amountMin > +amountMax) {
				notificationContainer(String(L.translate('Advertisement.min_max_error')), 'info');
				return;
			}
			if (type === 'sell' && balance && +amount > +balance) {
				notificationContainer(String(L.translate('Errors.not_enough_money')), 'info');
				return;
			}
		}

		step < 2 && setStep(step + 1);
		if (name === 'next' && step > 1) {
			setConfirmPopup(true);
		}
	};

	const confirmCreate = (verify: { emailCode?: string; smsCode?: string; totp?: string }) => {
		setConfirmPopup(false);
		handleCreate(verify);
	};

	const postedConfirm = () => {
		setPostedPopup(false);
		history.replace('/p2p-advertisement');
	};

	return (
		<>
			<div className="content-inner content-inner--padding">
				<div className="content-block content-block--flex content-block--padding-none content-block--border">
					<div className="create-offer">
						<div className="create-offer__header">
							{step === 0 && (
								<button type="button" onClick={backClick} className="back-step">
									<div className="back-step__img">
										<span className="back-step__arrow icon-arrow" />
									</div>
									<span className="back-step__text">
										{params.id
											? L.translate('Advertisement.edit_offer')
											: L.translate('Advertisement.create_offer')}
									</span>
								</button>
							)}
							<PAFTymeLine step={step} />

							{step === 0 && !params.id && (
								<ButtonSwitcher
									handleClick={handleClick}
									type={type}
									currentClass="sell-switch--type2"
								/>
							)}
						</div>
						<div className="create-offer__main">
							{step === 0 && (
								<PAFPriceBox
									marginChange={marginChange}
									amount={amount}
									margin={margin}
									marginError={marginError}
									countryError={countryError}
									dataLoaded={tradeBox1DataLoaded}
									setDataLoaded={setTradeBox1DataLoaded}
								/>
							)}
							{step === 1 && (
								<PAFTradeBox
									setAmountMin={setAmountMin}
									setAmount={setAmount}
									amountMin={amountMin}
									amount={amount}
									amountMax={amountMax}
									setAmountMax={setAmountMax}
									currency={currency}
									margin={margin}
									balance={balance}
									dataLoaded={tradeBox2DataLoaded}
									setDataLoaded={setTradeBox2DataLoaded}
								/>
							)}
							{step === 2 &&
								(p2pLoader ? (
									<div className="p2p-loader-wrapper">
										<Loader />
									</div>
								) : (
									<PAFPaymentBox
										paymentWindow={paymentWindow}
										setDescription={setDescription}
										description={description}
										dataLoaded={tradeBox3DataLoaded}
										setDataLoaded={setTradeBox3DataLoaded}
									/>
								))}
						</div>
					</div>
				</div>
			</div>
			<div className="step-navigation">
				{step !== 0 && (
					<button
						type="button"
						name="back"
						onClick={changeStep}
						className="button button--second-grey button--bigest"
					>
						{String(L.translate('Advertisement.back'))}
					</button>
				)}
				<button
					disabled={disaledStep(step)}
					type="button"
					name="next"
					onClick={changeStep}
					className="button button--bigest"
				>
					{L.translate('Advertisement.next_button')}
				</button>
			</div>
			{verifyOpen && (
				<SecurityVerify
					openModal={verifyOpen}
					closeModal={() => setVerifyOpen(false)}
					onSubmit={({ emailCode, smsCode, totp }) => {
						setVerifyOpen(false);
						confirmCreate({ emailCode, smsCode, totp });
					}}
				/>
			)}
			{confirmPopup && (
				<ConfirmToPost
					amountMin={amountMin}
					amount={amount}
					amountMax={amountMax}
					onClose={() => setConfirmPopup(false)}
					onConfirm={() => setVerifyOpen(true)}
				/>
			)}
			{postedPopup && postedData && (
				<SuccessfullyPosted data={postedData} onConfirm={postedConfirm} />
			)}
		</>
	);
};

export default CreateAdvertisement;
