import { ISOLATED_OPTION, P2P_OPTION } from 'components/TransferModal/utils';
import { IAssetPairsItem } from 'redux/reducers/assetPairs/types';
import { TTransfersFromSpot } from './types';

export const TRANSFERS_FROM_SPOT: TTransfersFromSpot = {
	btc: { to: P2P_OPTION },
	ltc: { to: P2P_OPTION },
	usdt: { to: P2P_OPTION },
	eth: { to: P2P_OPTION },
	bnb: { to: P2P_OPTION },
	trx: { to: P2P_OPTION },
	bch: { to: P2P_OPTION },
	bitl: { to: ISOLATED_OPTION },
};

export const findPairByAssetCode = (assetCode: string, pairs: IAssetPairsItem[]) => {
	return pairs.find(({ code }) => {
		return code.toLowerCase().includes(assetCode.toLowerCase());
	});
};
