/* eslint-disable @typescript-eslint/no-explicit-any */
import { AES, enc } from 'crypto-ts';

const ababagalamaga = 'vUZFZ35VbP5R76ybm94s5jPx';

const coder = (value: any) => {
	const cryptedText = AES.encrypt(JSON.stringify(value), ababagalamaga);
	return cryptedText.toString();
};
const deCoder = (value: any) => {
	const bytes = AES.decrypt(value, ababagalamaga);
	const decrypted = bytes.toString(enc.Utf8);

	return JSON.parse(decrypted);
};

export const getLS = (key: string): any => {
	try {
		const lsValue: string | null = localStorage.getItem(`concealed_economics_${key}`);
		if (lsValue) {
			return deCoder(lsValue);
		}
		throw new Error(`I do not find concealed_economics_${key}`);
	} catch (error) {
		return null;
	}
};

export const setLS = (key: string, value: any): any => {
	try {
		localStorage.setItem(`concealed_economics_${key}`, coder(value));
	} catch (error) {
		null;
	}
};
// eslint-disable-next-line
