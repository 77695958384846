import { FC } from 'react';
import { Helmet } from 'react-helmet';
import L from 'i18n-react';
import { APP_NAME } from 'services/constants/env';
import Header from 'layouts-elements/Header';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import { IProps } from './types';

// ==========================================:
const OurPrices: FC<IProps> = ({ children }) => {
	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)}.com ${String(L.translate('PageTitles.our_prices'))}`}</title>
				<meta name="description" content={String(L.translate('MetaTags.Description.our_prices'))} />
			</Helmet>
			<div className="wrapper">
				<Header isNew />
				<Content>{children}</Content>
				<Footer />
			</div>
		</>
	);
};

export default OurPrices;
