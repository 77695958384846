import { FC } from 'react';
import Popup from 'reactjs-popup';
import L from 'i18n-react';

import { useDispatch } from 'react-redux';
import { getTransferRequest } from 'redux/reducers/referral/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IDelCardProps } from './type';

const ClaimPopUp: FC<IDelCardProps> = ({ popUpOpen, popUpClose, data }) => {
	const dispatch = useDispatch();
	const handleDelete = () => {
		dispatch(
			getTransferRequest({
				asset_id: String(data),
				onSuccess: () => {
					notificationContainer(String(L.translate('InviteEarn.claim_success')), 'success');
				},
				onError: () => {},
			}),
		);
		popUpClose();
	};

	return (
		<Popup open={Boolean(popUpOpen)} onClose={popUpClose} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--small ">
						<button onClick={popUpClose} type="button" className="popup-close">
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title popup-header__title--w346">
								Are you sure you want to Claim funds to SPOT?
							</p>
						</div>

						<div className="popup-submit popup-submit--sb">
							<button
								className="button button--second-grey button--full-width"
								type="button"
								onClick={handleDelete}
							>
								Yes
							</button>
							<button className="button button--full-width" type="button" onClick={popUpClose}>
								{L.translate('Base.Modals.ConfirmDeletePopup.no_button')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ClaimPopUp;
