import { FC } from 'react';
import { HIDE_VIP } from 'components/OurPrices/constants';
import { IP2PTableRow } from './types';

const TableRow: FC<IP2PTableRow> = ({ item }) => {
	const {
		code,
		advertisement_min,
		advertisement_max,
		vip_advertisement_min,
		vip_advertisement_max,
	} = item;

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Coin</p>
				<p className="td-name">{code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Regular min</p>
				<p className="td-name">{advertisement_min}</p>
			</div>
			<div className={`td ${HIDE_VIP ? 'td--right' : ''}`}>
				<p className="td-hidden-name">Regular max</p>
				<p className="td-name">{advertisement_max}</p>
			</div>
			{!HIDE_VIP && (
				<>
					<div className="td">
						<p className="td-hidden-name">VIP Regular min</p>
						<p className="td-name">{vip_advertisement_min}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">VIP Regular max</p>
						<p className="td-name">{vip_advertisement_max}</p>
					</div>
				</>
			)}
		</div>
	);
};

export default TableRow;
