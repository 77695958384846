import { FC, useState } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getTimeSum } from 'services/utils/dateAndTimeHelpers';
import { getProfile } from 'redux/reducers/p2p/selectors';
import { VerifiedIcon } from 'assets/svg-icon';
import Table from './Table';
import UserTable from './UserTable';
import { PaymentMethods } from './PaymentMethods/PaymentMethods';

import { IAllProfileAdvetisement } from './type';

import P2PBalances from './P2PBalances';
import P2PModal from '../../../P2PMarketplace/P2PModal';
/* eslint-disable no-debugger */
const UserCenter: FC<IAllProfileAdvetisement> = ({ name }) => {
	const user = useSelector(getProfile);
	const history = useHistory();
	const [openModal, setOpenModal] = useState(false);
	const closeModalHandler = () => setOpenModal(false);
	const openModalHandler = () => setOpenModal(true);
	const location = useLocation();

	const hadleClick = () => {
		history.push('/p2p-trade');
	};

	const [tabs, setTabs] = useState('order');

	const tabContent = {
		order: <Table userName={name} />,
		payment: <PaymentMethods />,
	};

	const { total_trades = 0, successful_trades = 0 } = user?.trading_statistic || {};

	return (
		<>
			<div className="p2p-back">
				<button type="button" onClick={hadleClick} className="back-step">
					<span className="back-step__img">
						<span className="back-step__arrow icon-arrow" />
					</span>
				</button>
			</div>
			{name ? (
				<div className="p2p-user-header p2p-user-header--user-only">
					<div className="p2p-provider p2p-provider--bigger">
						<p className="p2p-provider__name">{user?.username}</p>
					</div>
				</div>
			) : null}
			<div className="content-block content-block--p2p-detail">
				{name ? null : (
					<div className="p2p-provider-wrapper">
						<div className="p2p-provider-box">
							<div className="p2p-provider p2p-provider--bigger p2p-provider--mb">
								{user?.username ? (
									<>
										<div className="p2p-provider__letter">
											{String(user?.username)[0].toUpperCase()}
										</div>
										<p className="p2p-provider__name">{user?.username}</p>
									</>
								) : (
									<button type="button" onClick={openModalHandler} className="button button--nav">
										{String(L.translate('P2P.enable_p2p'))}
									</button>
								)}
							</div>
							{user?.username && (
								<div className="p2p-provider p2p-provider--bigger">
									<p className="p2p-provider__status">{L.translate('P2P.verified_user')}</p>
									<span className="p2p-provider__check">
										<VerifiedIcon />
									</span>
								</div>
							)}
						</div>

						<div className="p2p-provider-box">
							<P2PBalances />
							{/* <span className="arrow-right"> */}
							{/*	<svg */}
							{/*		width="20" */}
							{/*		height="20" */}
							{/*		viewBox="0 0 20 20" */}
							{/*		fill="none" */}
							{/*		xmlns="http://www.w3.org/2000/svg" */}
							{/*	> */}
							{/*		<path */}
							{/*			d="M7.08301 4.16683L12.9163 10.0002L7.08301 15.8335" */}
							{/*			stroke="#011E26" */}
							{/*			strokeWidth="1.5" */}
							{/*			strokeLinecap="round" */}
							{/*			strokeLinejoin="round" */}
							{/*		/> */}
							{/*	</svg> */}
							{/* </span> */}
						</div>
					</div>
				)}
				<div className="balance-list balance-list--p2p-details">
					<div className="balance-item">
						<span className="balance-item__title">{String(L.translate('P2P.all_trades'))}</span>
						<span className="balance-item__value">
							<span className="balance-item__value-num">{total_trades}</span>{' '}
							{String(L.translate('P2P.time'))}
						</span>
						<span className="balance-item__value-details">
							({String(L.translate('P2P.buy'))} {user?.trading_statistic?.buy_trades || 0} |{' '}
							{String(L.translate('P2P.sell'))} {user?.trading_statistic?.sell_trades || 0})
						</span>
					</div>
					<div className="balance-item">
						<span className="balance-item__title">
							{String(L.translate('P2P.completion_rate'))}
						</span>
						<span className="balance-item__value">
							<span className="balance-item__value-num">
								{total_trades === 0
									? 0
									: ((successful_trades * 100) / total_trades).toFixed(2) || 0}
							</span>{' '}
							%
						</span>
					</div>
					<div className="balance-item">
						<span className="balance-item__title">{String(L.translate('P2P.release_time'))}</span>
						<span className="balance-item__value">
							<span className="balance-item__value-num">
								{user?.trading_statistic?.average_release_time || 0}
							</span>{' '}
							{String(L.translate('P2P.minute'))}
						</span>
					</div>
					<div className="balance-item">
						<span className="balance-item__title">{String(L.translate('P2P.registered'))}</span>
						<span className="balance-item__value">
							<span className="balance-item__value-num">
								{user ? getTimeSum(user?.trading_statistic?.created_at) : 0}
							</span>{' '}
							{String(L.translate('P2P.day'))}
						</span>
					</div>
				</div>
			</div>
			{name ? null : (
				<div className="content-tabs">
					<button
						type="button"
						onClick={() => setTabs('order')}
						className={`content-tabs__item ${tabs === 'order' ? 'active' : ''}`}
					>
						{String(L.translate('P2P.open_order'))}
					</button>
					<button
						type="button"
						onClick={() => setTabs('payment')}
						className={`content-tabs__item ${tabs === 'order' ? '' : 'active'}`}
					>
						{String(L.translate('P2P.payment_methods'))}
					</button>
					{/* <p className="content-tabs__item">Feedback (3)</p> */}
				</div>
			)}
			{name ? (
				<>
					<div className="p2p-user-header p2p-user-header--title-only">
						<p className="p2p-user-header__title">{String(L.translate('P2P.create_offer'))}</p>
					</div>
					<div className="table-title">
						<p className="table-title__item">{String(L.translate('P2P.buy_from_user'))}</p>
					</div>
				</>
			) : null}
			{/* <div className="content-block content-block--p2p-center"> */}
			{tabContent[tabs as keyof typeof tabContent]}
			{/* <div className="p2p-center table-wrapper">{tabs[currentTab as keyof typeof tabs]}</div> */}
			{/* </div> */}
			{name ? (
				<>
					<div className="table-title table-title--mt">
						<p className="table-title__item">{String(L.translate('P2P.sell_to_user'))}</p>
					</div>
					<UserTable userName={name} />
				</>
			) : null}
			<P2PModal title="Set Nickname" openModal={openModal} closeModal={closeModalHandler} />
		</>
	);
};

export default UserCenter;
