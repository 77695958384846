import { FC, useState } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getP2PBalancesLoad, getP2POrderQuick } from 'redux/reducers/p2p/selectors';
// import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import P2PItem from '../P2PItem';
import { IP2PTableProps } from './types';
/* eslint-disable no-debugger */

const P2PTable: FC<IP2PTableProps> = ({
	currentPage,
	setCurrentPage,
	selectItem,
	handleAction,
}) => {
	// const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	// const dispatch = useDispatch();

	const p2pQuickList = useSelector(getP2POrderQuick);
	const totalPages = p2pQuickList?.last_page || 1;
	const p2pIsLoad = useSelector(getP2PBalancesLoad);

	const [activeId, setActiveId] = useState<number | null>(null);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<div className="table table--p2p">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<span className="table-header__name">
								{String(L.translate('P2P.Marketplace.advertisers'))}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(L.translate('P2P.Marketplace.price'))}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(L.translate('P2P.Marketplace.limit_available'))}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(L.translate('P2P.Marketplace.payment'))}
							</span>
						</div>
						<div className="td" />
					</div>
				</div>
				<div className="table-body">
					{p2pIsLoad ? (
						<div className="spot-and-fiat-wallets-table-loader-wrapper">
							<Loader />
						</div>
					) : null}

					{!p2pIsLoad
						? p2pQuickList?.data?.map((item) => (
								<P2PItem
									key={item.id}
									data={item}
									selectItem={selectItem}
									handleAction={handleAction}
									activeId={activeId}
									setActiveId={setActiveId}
								/>
						  ))
						: null}

					{!p2pIsLoad && p2pQuickList?.data?.length === 0 && (
						<span className="table-empty">{String(L.translate('P2P.Marketplace.no_wallets'))}</span>
					)}
				</div>
			</div>
			{totalPages > 1 && (
				<Pagination
					pageCount={totalPages}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default P2PTable;
