/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWalletsDepositHistoryResponsePayload } from 'redux/reducers/wallets/types';
import {
	IGetPaymentsListPayload,
	IPostPaymentsPayload,
	IPostBankAccountPayload,
	IGetBankTransferPayload,
	IGetPaymentBankAccount,
} from 'redux/reducers/payments/types';
import { IPaymentsApi } from './types';

// ==========================================:
export const payments: IPaymentsApi = {
	getCurrencyPaymentsList: (payload: IGetPaymentsListPayload) =>
		http
			.get(endpoint.payments.GET_CURRENCY_PAYMENTS_LIST, { params: payload })
			.then((response) => response.data),
	paymentsPayPal: ({ amount, asset_id, key }) =>
		http
			.post(endpoint.payments.PAYMENTS_PAYPAL(amount, asset_id), { key })
			.then((response) => response.data),
	paymentsCartubank: (payload: IPostPaymentsPayload) =>
		http.post(endpoint.payments.PAYMENTS_CARTUBANK, payload).then((response) => response.data),
	getWalletsDepositHistory: (payload: any) =>
		http
			.get<IWalletsDepositHistoryResponsePayload>(endpoint.payments.PAYMENTS_HISTORY, payload)
			.then((response) => response.data),
	paymentsBankAccount: (payload: IPostBankAccountPayload) =>
		http.post(endpoint.payments.POST_ADD_BANK_ACCOUNT, payload).then((response) => response.data),
	updateBankAccount: (payload: IPostBankAccountPayload) =>
		http.put(endpoint.payments.UPDATE_ADD_BANK_ACCOUNT, payload).then((response) => response.data),
	getBankTransferDepositFiat: (params: IGetBankTransferPayload) =>
		http
			.get(endpoint.depositFiat.DEPOSIT_TRANSFER_FIAT, { params })
			.then((response) => response.data),
	createPaymentsBankAccountWithdrawal: (payload: IGetPaymentBankAccount) =>
		http
			.post(endpoint.payments.CREATE_BANK_ACCOUNT_WITHDRAWAL, payload)
			.then((response) => response.data),
	getFiatLimitations: () =>
		http.get(endpoint.payments.FIAT_LIMITATIONS).then((response) => response.data),
};
