import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IWithdrawFiatStore } from './types';

// ==========================================:
const getWithdrawCryptoState = (state: IStoreState): IWithdrawFiatStore => state.withdrawFiat;
export const getWithdrawCrypto = createSelector(
	[getWithdrawCryptoState],
	(withdrawCrypto: IWithdrawFiatStore) => withdrawCrypto,
);

// ====================================================:
export const getWithdrawalFiatLoader = createSelector(
	[getWithdrawCrypto],
	(withdrawCrypto: IWithdrawFiatStore): boolean => withdrawCrypto.withdrawalFiatLoader,
);

// ====================================================:
export const getFiatWithdrawResidual = createSelector(
	[getWithdrawCrypto],
	(withdrawFiat: IWithdrawFiatStore) => withdrawFiat.residual,
);
