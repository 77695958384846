import { FC, useEffect, useState, MouseEvent } from 'react';
import L from 'i18n-react';
import { useLocation, useHistory } from 'react-router';
import OpenOrdersTable from './OpenOrdersTable';
import OrdersHistoryTable from './OrderHIstoryTable';
import TradeHistoryTable from './TradeHistoryTable';
import { ISpotAndFiatHostory } from './type';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
// ==========================================:

const SpotAndFiatHistory: FC<ISpotAndFiatHostory> = ({ title, tab }) => {
	const [currentTableTab, setCurrentTableTab] = useState(tab);
	const location = useLocation<any>();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(location.state?.margin);
	useEffect(() => {
		setCurrentTableTab(tab);
	}, [tab]);
	useEffect(() => {
		if (location.state === 'spotHistory') {
			setCurrentTab('');
		}
		if (location?.state?.linkName === 'marginHistory') {
			setCurrentTab(location.state?.margin);
		}
	}, [location.state]);

	const currentTable = {
		open: <OpenOrdersTable currentTab={currentTab} />,
		closed: <OrdersHistoryTable currentTab={currentTab} />,
		trades: <TradeHistoryTable currentTab={currentTab} />,
	};
	const handleTab = (e: MouseEvent<HTMLButtonElement>) => {
		setCurrentTab(e.currentTarget.name);
		const locationState = {
			pathname: location.pathname,
			state: { ...location.state, margin: e.currentTarget.name },
		};
		history.push(locationState);
	};

	return (
		<>
			<div className="main-content__header">
				<h2 className="section-title">{title}</h2>
				{location?.state?.linkName === 'marginHistory' ? (
					<div className="margin-switcher">
						<button
							name="cross"
							onClick={handleTab}
							className={`${
								currentTab === 'cross'
									? 'margin-switcher__btn margin-switcher__btn--active'
									: 'margin-switcher__btn'
							}`}
							type="button"
						>
							{L.translate('Margin.Cross.title')}
						</button>
						<button
							name="isolated"
							onClick={handleTab}
							className={`${
								currentTab === 'isolated'
									? 'margin-switcher__btn margin-switcher__btn--active'
									: 'margin-switcher__btn'
							}`}
							type="button"
						>
							{L.translate('Margin.Isolated.title')}
						</button>
					</div>
				) : null}
			</div>
			<div className="content-block content-block--min-height2">
				<div className="balance-section">
					<div className="balance-block balance-block--wallet-type">
						{currentTable[currentTableTab as keyof typeof currentTable]}
					</div>
				</div>
			</div>
		</>
	);
};

export default SpotAndFiatHistory;
