import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ICountry } from 'redux/reducers/app/types';
import {
	IP2PStore,
	IP2PBalances,
	IP2POpneTradesData,
	IP2PClosedTradesData,
	IP2PAdevrtisementData,
	IAssetsData,
	IGetOrderQuick,
	ICurrenciesData,
	IGetCurrenciesItem,
	IPaymentsArray,
	IPaymentItem,
	IAllAdvertisementData,
	IPofileResponse,
	IGetProposalData,
	IAdvertisementData,
	ITradeUserMessageData,
	ITradeData,
	ITradeDocumnets,
	IP2PNotificationsData,
	INotificationBell,
	IPaymentMethodResponse,
	IListMethodItem,
} from './types';

// ==========================================:
const getP2PState = (state: IStoreState): IP2PStore => state.p2p;
export const getP2P = createSelector([getP2PState], (p2p: IP2PStore) => p2p);

// ====================================================:
export const getP2PBalancesList = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IP2PBalances | null => p2pWallets.p2pBalancesList,
);

// ====================================================:
export const getP2PBalancesLoad = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): boolean => p2pWallets.p2pBalancesListLoader,
);

// ====================================================:
export const getP2PSateLoader = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): boolean => p2pWallets.p2pStateLoader,
);

// ====================================================:
export const getP2POpenTradesList = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IP2POpneTradesData | null => p2pWallets.p2pOpenTradesList,
);

// ====================================================:
export const getP2PClosedTradesList = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IP2PClosedTradesData | null => p2pWallets.p2pClosedTradesList,
);

// ====================================================:
export const getP2PAdvertisementList = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IP2PAdevrtisementData | null => p2pWallets.p2pAdvertisement,
);

// ====================================================:
export const getP2PAssets = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IAssetsData | null => p2pWallets.p2pAssets,
);

// ====================================================:
export const getP2POrderQuick = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IGetOrderQuick | null => p2pWallets.p2pOrderQuick,
);

// ====================================================:
export const getCurrentCoin = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): string => p2pWallets.currentCoin,
);

// ====================================================:
export const getCurrencyItem = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IGetCurrenciesItem | null => p2pWallets.currencyItem,
);

// ====================================================:
export const getCountry = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): ICountry | null => p2pWallets.country,
);

// ====================================================:
export const getCurrencies = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): ICurrenciesData | null => p2pWallets.currencies,
);

// ====================================================:
export const getCurrentType = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): string => p2pWallets.currentType,
);

// ====================================================:
export const getPayments = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IPaymentsArray | null => p2pWallets.paymentsTypes,
);

// ====================================================:
export const getAcvtivePayments = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IPaymentsArray | null => p2pWallets.paymentsActiveTypes,
);

// ====================================================:
export const getPaymentAmount = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): string => p2pWallets.paymentAmount,
);

// ====================================================:
export const getPaymentWindow = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): number => p2pWallets.paymentWindow,
);

// ====================================================:
export const getP2PNotification = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IP2PNotificationsData | null => p2pWallets.p2pNotificaion,
);

// ====================================================:
export const getCurrentPayment = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IPaymentItem | null => p2pWallets.paymentsTypeItem,
);

// ====================================================:
export const getCurrentPayments = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IListMethodItem[] => p2pWallets.paymentTypeItems,
);

// ====================================================:
export const getAllAdvertisement = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IAllAdvertisementData | null => p2pWallets.allAdvertisement,
);

// ====================================================:
export const getAllAdvertisementSell = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IAllAdvertisementData | null => p2pWallets.allAdvertisementSell,
);

// ====================================================:
export const getProfile = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IPofileResponse | null => p2pWallets.userProfile,
);

// ====================================================:
export const getProposal = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IGetProposalData | null => p2pWallets.p2pProposal,
);

// ====================================================:
export const getUserAdvert = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IAdvertisementData | null => p2pWallets.userAdvrtisement,
);

// ====================================================:
export const getTradePaySell = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): ITradeData | undefined => p2pWallets?.tradePaySellData?.trade,
);

// ====================================================:
export const getTradeStatus = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): string | undefined => p2pWallets?.tradePaySellData?.trade?.status,
);

// ====================================================:
export const getChatListUser = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): Array<ITradeUserMessageData> | undefined => p2pWallets?.p2pChat?.common,
);

// ====================================================:
export const getChatListAdmin = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): Array<ITradeUserMessageData> | undefined => p2pWallets?.p2pChat?.admin,
);

// ====================================================:
export const getDocuments = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): ITradeDocumnets | null => p2pWallets.photoDocumnets,
);

export const getP2PTransferActsLoading = (state: IStoreState) => {
	return getP2PState(state).transferActsLoading;
};

// ====================================================:
export const getP2PNotificationBell = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): INotificationBell | null => p2pWallets.notifiactionBell,
);

// ====================================================:
export const getPaymentMethodList = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): IPaymentMethodResponse | null => p2pWallets.lisPaymentsMethod,
);

export const getPaymentMethodLoader = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): boolean => p2pWallets.lisPaymentsLoader,
);

export const getAdvertChangeStatusLoader = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): boolean => p2pWallets.advertChangeStatusLoader,
);

export const getActiveTradeLoader = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): boolean => p2pWallets.activeTradeLoader,
);

export const getCheckAdvertActiveLoader = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): boolean => p2pWallets.checkAdvertActiveLoader,
);

export const getCheckAmountLoading = createSelector(
	[getP2P],
	(p2pWallets: IP2PStore): boolean => p2pWallets.checkAmountLoading,
);
