import { FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import Loader from 'ui/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumnetsRequest } from 'redux/reducers/p2p/reducer';
import { getDocuments } from 'redux/reducers/p2p/selectors';
import { ITradeDocument } from 'redux/reducers/p2p/types';
import ExistedPhotosBoxWrap from './ExistedPhotosBoxWrap';
import UploadEvidance from './UploadEvidance';
import { IEvidencePhoto } from './type';
/* eslint-disable no-debugger */

const EvidancePhotos: FC<IEvidencePhoto> = ({ trade }) => {
	const [existedPhotos, setExistedPhotos] = useState<Array<ITradeDocument> | []>([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const documents = useSelector(getDocuments);

	useEffect(() => {
		if (documents) {
			setExistedPhotos(documents.documents);
			setLoading(false);
		}
	}, [documents]);

	const getExistedPhotos = () => {
		setLoading(true);
		dispatch(getDocumnetsRequest({ trade_id: String(trade.id) }));
	};

	useEffect(getExistedPhotos, [dispatch, trade.id]);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<>
					<div style={{ marginBottom: '15px' }}>
						<UploadEvidance
							trade={trade}
							existedPhotos={existedPhotos}
							getExistedPhotos={getExistedPhotos}
							setLoading={setLoading}
						/>
					</div>

					<p className="proof--paragraph">{L.translate('P2P.t_0244')}</p>

					{!!existedPhotos?.length && (
						<ExistedPhotosBoxWrap photos={existedPhotos} getExistedPhotos={getExistedPhotos} />
					)}
				</>
			)}
		</>
	);
};

export default EvidancePhotos;
// eslint-disable-next-line
