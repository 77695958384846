/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import L from 'i18n-react';
import { useLocation } from 'react-router';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { getFirstCurrency, transformPairCode } from 'services/utils/tradingPairHelpers';
import { ITradesHistoryItemProps } from './types';

const TradeHistoryItem: FC<ITradesHistoryItemProps> = ({ data }) => {
	const { created_at, pair, type, price_filled, price_requested, fee, asset_get_change } = data;
	const location = useLocation<any>();

	const getMarketType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return String(L.translate('MarketType.sell'));

		if (typeValue?.includes('buy')) return String(L.translate('MarketType.buy'));

		return '-';
	};

	const getClassByType = (statusValue: string): string => {
		if (statusValue?.includes('sell')) return 'td-name td-name--regular td-name--red';

		if (statusValue?.includes('buy')) return 'td-name td-name--regular td-name--green';

		return 'td-name';
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<span className="td-name td-name--regular td-name--light">
					{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<span className="td-name td-name--regular">
					{location.state?.margin === 'cross'
						? getFirstCurrency(transformPairCode(pair))
						: transformPairCode(pair)}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Side</p>
				<span className={getClassByType(type)}>{getMarketType(type)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Price</p>
				<span className="td-name td-name--regular">
					{Number(price_requested || price_filled).toFixed(8) || '-'}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Filled</p>
				<span className="td-name td-name--regular">{Number(price_filled).toFixed(8)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<span className="td-name td-name--regular">{Number(fee).toFixed(8) || '-'}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Total</p>
				<span className="td-name td-name--regular">
					{/* {type?.includes('sell') && Number(asset_sold_change).toFixed(8)}
					{type?.includes('buy') && Number(asset_get_change).toFixed(8)} */}
					{Number(asset_get_change).toFixed(8)}
				</span>
			</div>
		</div>
	);
};

export default TradeHistoryItem;
