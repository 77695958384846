import { AxiosError } from 'axios';

export const getErrorMessage = (error: AxiosError) => {
	const { response } = error;
	if (response) {
		const { data } = response;
		const errorList: Array<string> = [];

		if (data?.message) {
			errorList.push(data?.message);
		} else {
			Object.keys(data?.errors).forEach((item) =>
				errorList.push(data?.errors[item as keyof typeof data.errors]),
			);
		}

		return errorList.flat()[0];
	}
	return undefined;
};
