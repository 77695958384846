import { useState, useRef, useEffect, MutableRefObject } from 'react';
import moment from 'moment';
/* eslint-disable */

const useTimer: (
	createAt: string | undefined,
	paymentWindow: number,
) => {
	refHours: MutableRefObject<any>;
	refMinutes: MutableRefObject<any>;
	refSeconds: MutableRefObject<any>;
	hours: number | null;
	minutes: number | null;
	seconds: number | null;
} = (createAt, paymentWindow) => {
	const [hours, setHours] = useState<number | null>(null);
	const [minutes, setMinutes] = useState<number | null>(null);
	const [seconds, setSeconds] = useState<number | null>(null);
	const refHours = useRef<any>(null);
	const refMinutes = useRef<any>(null);
	const refSeconds = useRef<any>(null);

	useEffect(() => {
		let timerId: any;
		if (createAt && refHours?.current && refMinutes?.current && refSeconds?.current) {
			timerId = setInterval(() => {
				const eventTime = moment(Number(moment(createAt)) + paymentWindow * 60 * 1000).unix();
				const currentTime = moment(Date.now()).unix();
				const leftTime = eventTime - currentTime;

				let duration = moment.duration(leftTime, 'seconds');
				if (duration.asSeconds() <= 0 || (!refHours && !refMinutes && !refSeconds)) {
					clearInterval(timerId);
					return;
				}
				duration = moment.duration(duration.asSeconds() - 1, 'seconds');

				const _hours = duration.hours();
				const _minutes = duration.minutes();
				const _seconds = duration.seconds();

				setHours(_hours);
				setMinutes(_minutes);
				setSeconds(_seconds);

				if (
					refHours.current !== null ||
					refMinutes.current !== null ||
					refSeconds.current !== null
				) {
					refHours.current.textContent = String(_hours);
					refMinutes.current.textContent = String(_minutes);
					refSeconds.current.textContent = String(_seconds);
				}
			}, 1000);
		}
		return () => {
			clearInterval(timerId);
		};
	}, [createAt, paymentWindow, refHours.current, refMinutes.current, refSeconds.current]);

	return {
		refHours,
		refMinutes,
		refSeconds,
		hours,
		minutes,
		seconds,
	};
};

export default useTimer;
