// TODO: Переводы
import { FC, useState, useMemo } from 'react';
import L from 'i18n-react';
import { getPayments, getProfile } from 'redux/reducers/p2p/selectors';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import TextError from 'ui/Formik/TextError';
import { getWalletsIsLoad, getWalletsList } from 'redux/reducers/wallets/selectors';
import { addCardRequest } from 'redux/reducers/cards/reducer';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import Input from 'ui/Formik/Input';
import { IPaymentItem } from 'redux/reducers/p2p/types';
import { postPaymentMethodRequest } from 'redux/reducers/p2p/reducer';
import { IPropsModal } from './types';

export const Card: FC<IPropsModal> = ({ closeModal, handleStatus }) => {
	const dispatch = useDispatch();
	const walletsList = useSelector(getWalletsList);

	const initialValues = {
		holder_name: '',
		bank_name: '',
		card_number: '',
		expiry_date: '',
	};

	const validationSchema = yup.object().shape({
		bank_name: yup
			.string()
			.matches(
				/^[A-Za-z\s]*$/,
				String(L.translate('Account.PaymentMethods.AddCard.bank_invalid_format')),
			)
			.required(String(L.translate('Account.PaymentMethods.AddCard.bank_error')))
			.min(4, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
			.max(30, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		holder_name: yup
			.string()
			.matches(
				/^[A-Za-z\s]*$/,
				String(L.translate('Account.PaymentMethods.AddCard.card_holder_invalid_format')),
			)
			.required(String(L.translate('Account.PaymentMethods.AddCard.holder_error')))
			.min(4, String(L.translate('Account.PaymentMethods.AddCard.too_short')))
			.max(30, String(L.translate('Account.PaymentMethods.AddCard.too_long'))),
		card_number: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.card_number_error')))
			.min(16, String(L.translate('Account.PaymentMethods.AddCard.card_number_error_2'))),
		expiry_date: yup
			.string()
			.required(String(L.translate('Account.PaymentMethods.AddCard.expiration_error')))
			.matches(
				/^(0?[1-9]|1[0-2])[/][0-9]{2}$/,
				String(L.translate('Account.PaymentMethods.AddCard.expiration_error_2')),
			),
	});

	const clearNumber = (value: number | string) => {
		return String(value).replace(/\D+/g, '');
	};

	const expiryDateFormat = (value: string) => {
		const clearValue = clearNumber(value);

		if (clearValue.length >= 3) {
			return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
		}
		return clearValue;
	};

	const handleAddCard = (values: typeof initialValues) => {
		const data = {
			...values,
			card_number: values.card_number.replace(/ /g, ''),
			expiry_month: values.expiry_date.split('/')[0],
			expiry_year: values.expiry_date.split('/')[1],
		};
		dispatch(addCardRequest(data));
		closeModal();
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				setSubmitting(false);
				handleAddCard(values);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ values, errors, touched, isSubmitting }) => {
				const isError = (field: keyof typeof values) => touched[field] && errors[field];
				return (
					<Form className="popup-window">
						<button type="button" onClick={closeModal} className="popup-close">
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-window__inside">
							<div className="popup popup--medium">
								<div className="popup-header">
									<p className="popup-header__title">
										{L.translate('Account.PaymentMethods.add_new_card')}
									</p>
									<div className="popup-text popup-text--center">
										<p>{L.translate('Account.PaymentMethods.enter_card_info')}</p>
									</div>
								</div>
								<div className="popup-body">
									<Field
										name="holder_name"
										title={L.translate('Account.PaymentMethods.holder_name_title')}
										placeholder={L.translate('Account.PaymentMethods.holder_name')}
										component={Input}
									/>
									<Field
										name="bank_name"
										title={L.translate('Account.PaymentMethods.bank_name')}
										placeholder={L.translate('Account.PaymentMethods.enter_bank_name')}
										component={Input}
									/>
									<div className="input">
										<p className="input__name">
											{L.translate('Account.PaymentMethods.card_number')}
										</p>
										<div
											className={`input-wrapper ${isError('card_number') ? 'input--error' : ''}`}
										>
											<Field
												name="card_number"
												className="input-item input-item--right-double-icon"
												placeholder={L.translate('Account.PaymentMethods.card_number')}
												value={values.card_number
													.replace(/[^0-9]/g, '')
													.replace(/(\d{4})/g, '$1 ')
													.trim()}
												maxLength={19}
											/>
										</div>
										<ErrorMessage name="card_number" component={TextError} />
									</div>

									<div className="input">
										<p className="input__name">
											{String(L.translate('Account.PaymentMethods.expiry_date'))}
										</p>
										<div
											className={`input-wrapper ${isError('expiry_date') ? 'input--error' : ''}`}
										>
											<Field
												name="expiry_date"
												className="input-item"
												placeholder="MM/YY"
												value={expiryDateFormat(values.expiry_date)}
												maxLength={5}
											/>
										</div>
										<ErrorMessage name="expiry_date" component={TextError} />
									</div>
								</div>
								<div className="popup-submit popup-submit--sb">
									<button
										type="submit"
										className="button button--full-width"
										disabled={isSubmitting}
									>
										{String(L.translate('Account.PaymentMethods.add_card'))}
									</button>
								</div>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
