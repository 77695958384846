import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { getP2PBalancesList } from 'redux/reducers/p2p/selectors';
import { NavLink } from 'react-router-dom';
import { getHideBalanceSetting } from 'redux/reducers/tradingSettings/selectors';
import { setHideBalanceSetting } from 'redux/reducers/tradingSettings/reducer';

import P2PBalances from './P2PBalances';
import P2PSortingTable from './P2PSortingTable';

// ==========================================:
const P2PWallet: FC = () => {
	const p2pList = useSelector(getP2PBalancesList);

	const [searchCoins, setSearchCoins] = useState('');
	const [smallBalancesIsHide, setSmallBalancesIsHide] = useState(false);

	const hideBalance = useSelector(getHideBalanceSetting);

	const dispatch = useDispatch();

	const handleSetBalancesIsHide = () => {
		dispatch(setHideBalanceSetting(!hideBalance));
	};

	const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setSearchCoins(value);
	};

	const handleChangeSmallBalancesIsHide = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;

		setSmallBalancesIsHide(checked);
	};

	const p2pWalletsList = useMemo(() => {
		if (!p2pList?.balances?.length) {
			return [];
		}

		if (smallBalancesIsHide && searchCoins) {
			return p2pList.balances.filter(
				(wallet) =>
					Number(wallet.total) > 0.0003 &&
					wallet?.asset_code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		if (smallBalancesIsHide) {
			return p2pList?.balances.filter((wallet) => Number(wallet.total) > 0.0003);
		}

		if (searchCoins) {
			return p2pList?.balances.filter((wallet) =>
				wallet.asset_code.toLowerCase().includes(searchCoins.toLowerCase()),
			);
		}

		return p2pList?.balances;
	}, [p2pList?.balances, searchCoins, smallBalancesIsHide]);

	return (
		<>
			<div className="main-content__header">
				<h2 className="section-title">P2P</h2>
				<button type="button" className="hide-btn" onClick={handleSetBalancesIsHide}>
					<span className={`hide-btn__icon text-type ${hideBalance ? 'icon-eye' : 'icon-eye2'}`} />
					<span className="hide-btn__text">{String(L.translate('P2P.Balances.hide_balance'))}</span>
				</button>
				<div className="section-nav">
					<NavLink to="/p2p-trade" className="button button--nav">
						{String(L.translate('P2P.Balances.p2p_trading'))}
					</NavLink>
				</div>
			</div>
			<P2PBalances balancesIsHide={hideBalance} />
			<div className="content-block">
				<div className="balance-section">
					{/* <div className="balance-section__header">
						<div className="search search--big">
							<div className="input input--margin-none">
								<div className="input-wrapper">
									<input
										className="input-item input-item--sort input-item--right-icon"
										type="text"
										placeholder={String(L.translate('P2P.search_coin'))}
										value={searchCoins}
										onChange={handleChangeSearch}
									/>
									<button type="button" className="search-btn">
										<span className="search-btn__icon icon-search-normal" />
									</button>
								</div>
							</div>
						</div>
						<div className="checkbox checkbox--margin-none">
							<label className="checkbox__label">
								<input
									type="checkbox"
									className="hidden"
									checked={smallBalancesIsHide}
									onChange={handleChangeSmallBalancesIsHide}
								/>
								<span className=" checkbox__item">
									<span className="checkbox__item-icon">
										<span className="icon-Checkbox" />
									</span>
								</span>
								<span className="checkbox__text checkbox__text--grey">
									{String(L.translate('P2P.Balances.hide_small_balances'))}
								</span>
							</label>
						</div>
					</div> */}
					<div className="balance-block">
						<P2PSortingTable data={p2pWalletsList} balancesIsHide={hideBalance} />
					</div>
				</div>
			</div>
		</>
	);
};

export default P2PWallet;
