import { FC, useEffect, useState, ChangeEvent } from 'react';
import L from 'i18n-react';
import { ICrossWalletsData } from 'redux/reducers/marginWallets/types';
import { textSearchValid } from 'services/utils/textSearch';
import { ISearchInputProps } from './types';

const SearchInput: FC<ISearchInputProps> = (props) => {
	const { listCoins, onSelect, coin } = props;
	const [dropdown, setDropdown] = useState(false);
	const [search, setSearch] = useState('');
	const changeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length < 15) {
			setSearch(e.target.value);
		}
	};
	useEffect(() => {
		const coincidences = listCoins?.find(
			(item: ICrossWalletsData) => item?.asset?.code === coin?.asset?.code,
		);
		if (!dropdown && coin && coincidences) {
			const nameCode: string = coin?.asset?.code?.toUpperCase();
			const name: string = coin?.asset?.name;
			setSearch(`${nameCode} ${name}`);
		}
	}, [coin, dropdown, listCoins]);
	const handleFocus = () => {
		setDropdown(true);
		setSearch('');
	};

	const handleBlur = () => {
		// without setTimeout the dropdown will close before we select asset
		setTimeout(() => {
			setDropdown(false);
		}, 300);
	};
	const filterData = (searchCoin: string, data = []) => {
		return data?.filter((wallet: ICrossWalletsData) =>
			textSearchValid(wallet?.asset?.code, wallet?.asset?.name, searchCoin, '', ''),
		);
	};

	const data = filterData(search, listCoins);

	return (
		<>
			<div className="transfer-form__select select-block transfer-form__select--box">
				<p className="select-block__name">{String(L.translate('Base.Modals.coin'))}</p>
				<div className={`select select--regular details-list__select ${dropdown ? 'active' : ''}`}>
					<div className="select__current">
						{!dropdown && search && (
							<span className="select-coin coin_image">
								<img src={coin?.asset?.img_path} alt="" width={20} />
							</span>
						)}

						<input
							className="coin-val select__current"
							placeholder="select asset"
							value={search}
							onChange={changeSearch}
							onFocus={handleFocus}
							onBlur={handleBlur}
						/>
						<span className="select__arrow icon-arrow2" />
					</div>

					{dropdown && (
						<div className="select__drop">
							<div className="select__drop-scroll">
								{data?.length ? (
									data?.map((wallet: ICrossWalletsData) => (
										<div key={wallet.asset.id} className="select__drop-item">
											<button
												type="button"
												className="select__drop-link"
												data-code={wallet?.asset?.code}
												onClick={onSelect}
											>
												<span className="select-coin coin_image">
													<img src={wallet?.asset?.img_path} alt="" />
												</span>
												<span className="coin-val__code">{wallet?.asset?.code?.toUpperCase()}</span>
												<span className="coin-val__name">{wallet?.asset?.name}</span>
											</button>
										</div>
									))
								) : (
									<span className="coin-val__name">{L.translate('Global.nothing')}</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default SearchInput;
