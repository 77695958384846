import { FC } from 'react';
import L from 'i18n-react';
import Auth from 'layouts/Auth';
import { APP_NAME } from 'services/constants/env';
import EmailConfirmCheck from 'components/EmailConfirmCheck';

// ==========================================:
const CheckEmail: FC = () => {
	return (
		<Auth title={`${String(APP_NAME)}.com | ${String(L.translate('Auth.check_email'))}`}>
			<EmailConfirmCheck />
		</Auth>
	);
};

export default CheckEmail;
