/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICountry } from 'redux/reducers/app/types';

import {
	IP2PStore,
	IP2PBalancesResponse,
	IP2POpenTradesRequest,
	IP2POpenTradesResponse,
	IP2PClosedTradesResponse,
	IP2PClosedTradesRequest,
	IP2PAdevrtisementResponse,
	IP2PAdevrtisementRequest,
	IAssetsResponse,
	IRemoveOrederP2PRequest,
	IP2POrderQuickResponse,
	IP2POrderQuickRequest,
	IP2PCurrenciesResponse,
	IGetCurrenciesItem,
	IPaymentItem,
	IP2PAdevertisementResponse,
	IP2PAdevertisementRequest,
	IPofileRequest,
	IPofileResponse,
	IP2PPaymentResponse,
	IP2PProposalRequest,
	IP2PProposalResponse,
	IPostAdvertisementDataParams,
	IP2PUserSdvertisementRequest,
	IP2PUserSdvertisementResponse,
	IP2PTradeDetailsRequest,
	IP2PDatailsResponse,
	IP2PTradePaySellResponse,
	IP2PTradePaySellRequest,
	IP2PTradeChatResponse,
	IP2PTradeChatMessgeRequest,
	ISocketTrade,
	IP2PTradeChatMessgeResponse,
	ITradeSocketResponse,
	IP2PDocumentResponse,
	IP2PTransferPayload,
	IP2PIsolatedTransferPayload,
	IP2PUploadEvidanceRequest,
	IP2PDeleteEvidanceRequest,
	IP2PNotificationRequest,
	IP2PNotificationResponse,
	IP2PNotificationBellResponse,
	INotificationStatusRequest,
	ICreateP2PUserRequest,
	IP2PPaymentActiveMethod,
	IPaymentMethodRequest,
	IPaymentMethodResponse,
	IParamsDeleteRequest,
	IMethodRequest,
	IListMethodItem,
	IChangeAdvertActiveStatusParams,
	IP2PAdevrtisementBuySell,
	ICheckAdvertActiveOrderPayload,
	IGetActiveTradePayload,
	IGetActiveTradeResponse,
	ICheckOrderAmountPayload,
} from './types';

// ==========================================:
export const initialState: IP2PStore = {
	p2pBalancesList: null,
	p2pBalancesListLoader: false,
	p2pOpenTradesList: null,
	p2pOpenTradesBuy: null,
	p2pClosedTradesList: null,
	p2pClosedTradesSell: null,
	p2pAdvertisement: null,
	p2pAssets: null,
	p2pOrderQuick: null,
	currencies: null,
	currentCoin: 'usdt',
	currencyItem: { code: 'USD', id: 6, name: 'US Dollar' },
	country: null,
	currentType: 'buy',
	paymentsTypes: null,
	paymentsActiveTypes: null,
	paymentsTypeItem: { id: 0, payment_name: 'All payments', payment_name_eng: 'All payments' },
	paymentTypeItems: [],
	paymentAmount: '',
	paymentWindow: 180,
	allAdvertisement: null,
	allAdvertisementSell: null,
	userProfile: null,
	p2pProposal: null,
	userAdvrtisement: null,
	trade_id: '',
	tradePaySellData: null,
	p2pStateLoader: false,
	p2pChat: null,
	photoDocumnets: null,
	transferActsLoading: false,
	p2pNotificaion: null,
	notifiactionBell: null,
	notificationStatus: false,
	lisPaymentsMethod: null,
	lisPaymentsLoader: false,
	advertChangeStatusLoader: false,
	checkAdvertActiveLoader: false,
	activeTradeLoader: false,
	checkAmountLoading: false,
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-debugger */
// ==========================================:
const p2p = createSlice({
	name: '@@p2p',
	initialState,
	reducers: {
		getP2PBalancesListRequest: (state) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},
		getP2PBalancesListSuccess: (state, action: PayloadAction<IP2PBalancesResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.p2pBalancesList = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		getP2POpenTradesRequest: (state, action: PayloadAction<IP2POpenTradesRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},

		getP2POpenTradesSuccess: (state, action: PayloadAction<IP2POpenTradesResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.p2pOpenTradesList = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		getP2PClosedTradesRequest: (state, action: PayloadAction<IP2PClosedTradesRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},

		getP2PClosedTradesSuccess: (state, action: PayloadAction<IP2PClosedTradesResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.p2pClosedTradesList = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		getP2PAdevertisementRequest: (state, action: PayloadAction<IP2PAdevrtisementRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},

		getP2PAdevertisementSuccess: (state, action: PayloadAction<IP2PAdevrtisementResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.p2pAdvertisement = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		getP2PAdevertisementFailure: (state) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = false;
		},
		// getP2PAdevertisementUpdate: (state, action: PayloadAction<IP2PAdevrtisementResponse>) => {
		// 	const p2pRequestState = state;
		// 	const { payload } = action;

		// 	p2pRequestState.p2pAdvertisement = p2pRequestState.p2pAdvertisement;
		// 	p2pRequestState.p2pBalancesListLoader = false;
		// },
		getP2PAssetsRequest: (state) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},
		getP2PAssetsSuccess: (state, action: PayloadAction<IAssetsResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.p2pAssets = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		getP2PDeletedRequest: (state, action: PayloadAction<IRemoveOrederP2PRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},
		getP2PDeletedSuccess: (state, action: PayloadAction<IRemoveOrederP2PRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = false;
		},
		getP2PQuickRequest: (state, action: PayloadAction<IP2POrderQuickRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},
		getP2PQuickSuccess: (state, action: PayloadAction<IP2POrderQuickResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.p2pOrderQuick = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		getP2PCurrenciesRequest: (state) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},
		getP2PCurrenciesSuccess: (state, action: PayloadAction<IP2PCurrenciesResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.currencies = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},

		setCurrentCoin: (state, action: PayloadAction<string>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.currentCoin = payload;
		},
		setCurrency: (state, action: PayloadAction<IGetCurrenciesItem>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.currencyItem = payload;
		},
		setCountry: (state, action: PayloadAction<ICountry>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.country = payload;
		},
		setCurrentType: (state, action: PayloadAction<string>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.currentType = payload;
		},
		getPaymentRequest: (state) => {
			const p2pRequestState = state;
		},
		getPaymentSuccess: (state, action: PayloadAction<IP2PPaymentResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.paymentsTypes = payload;
		},

		getPaymentMethodsRequest: (state, action: PayloadAction<IP2PPaymentActiveMethod>) => {
			const p2pRequestState = state;
		},
		getPaymentMethodsSuccess: (state, action: PayloadAction<IP2PPaymentResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.paymentsActiveTypes = payload;
		},

		setCurrentPaymnent: (state, action: PayloadAction<IPaymentItem | null>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.paymentsTypeItem = payload;
		},
		setCurrentPayments: (state, action: PayloadAction<IListMethodItem[]>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.paymentTypeItems = payload;
		},
		setPaymentAmount: (state, action: PayloadAction<string>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.paymentAmount = payload;
		},
		setPaymentWindow: (state, action: PayloadAction<number>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.paymentWindow = payload;
		},
		getAllAdvertisementRequest: (state, action: PayloadAction<IP2PAdevertisementRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},

		getAllAdvertisementSuccess: (state, action: PayloadAction<IP2PAdevertisementResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.p2pBalancesListLoader = false;
			switch (payload.type) {
				case 'sell':
					p2pRequestState.allAdvertisementSell = payload;
					return;
				case 'buy':
					p2pRequestState.allAdvertisement = payload;
					return;
				default:
					p2pRequestState.allAdvertisement = payload;
			}
		},
		getProfileRequest: (state, action: PayloadAction<IPofileRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},
		getProfileSuccess: (state, action: PayloadAction<IPofileResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.userProfile = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		getProposalRequest: (state, action: PayloadAction<IP2PProposalRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},
		getProposalSuccess: (state, action: PayloadAction<IP2PProposalResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.p2pProposal = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		postAdvertisementRequest: (state, action: PayloadAction<IPostAdvertisementDataParams>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pStateLoader = true;
		},
		postAdvertisementSuccess: (state) => {
			const p2pRequestState = state;

			p2pRequestState.p2pStateLoader = false;
		},
		postAdvertisementFalure: (state) => {
			const p2pRequestState = state;

			p2pRequestState.p2pStateLoader = false;
		},

		getAdvertisementRequest: (state, action: PayloadAction<IP2PUserSdvertisementRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},
		getAdvertisementSuccess: (state, action: PayloadAction<IP2PUserSdvertisementResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.userAdvrtisement = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		postTradeDetailsRequest: (state, action: PayloadAction<IP2PTradeDetailsRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pBalancesListLoader = true;
		},
		postTradeDetailsSuccess: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.trade_id = payload?.trade_id;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		getTradePaySellRequest: (state, action: PayloadAction<IP2PTradePaySellRequest>) => {
			const p2pRequestState = state;

			p2pRequestState.p2pStateLoader = true;
		},
		getTradePaySellSuccess: (state, action: PayloadAction<IP2PTradePaySellResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.tradePaySellData = payload;
			p2pRequestState.p2pStateLoader = false;
		},
		getTradePaySellUpdate: (state, action: PayloadAction<ISocketTrade>) => {
			const p2pRequestState = state;
			const { payload } = action;

			if (
				p2pRequestState &&
				p2pRequestState.tradePaySellData?.trade.id &&
				p2pRequestState.tradePaySellData?.trade.id === payload?.trade_id
			) {
				p2pRequestState.tradePaySellData.trade.status = payload.status;
			}

			p2pRequestState.p2pStateLoader = false;
		},
		getChatRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;

			// p2pRequestState.p2pBalancesListLoader = true;
		},
		getChatSuccess: (state, action: PayloadAction<IP2PTradeChatResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.p2pChat = payload;
			// p2pRequestState.p2pBalancesListLoader = false;
		},
		postChatMessageSendRequest: (state, action: PayloadAction<IP2PTradeChatMessgeRequest>) => {
			const p2pRequestState = state;
			// p2pRequestState.p2pBalancesListLoader = true;
		},
		postChatMessageSendSuccess: (state, action: PayloadAction<IP2PTradeChatMessgeResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			if (p2pRequestState.p2pChat?.common) {
				p2pRequestState.p2pChat?.common.push(payload);
			}

			// p2pRequestState.p2pBalancesListLoader = false;
		},
		postChatMessageSendUpdate: (state, action: PayloadAction<ITradeSocketResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			if (payload.common && p2pRequestState.tradePaySellData?.trade.id === payload.trade_id) {
				p2pRequestState.p2pChat?.common.push(payload.common[0]);
				return;
			}

			if (payload.admin && p2pRequestState.tradePaySellData?.trade.id === payload.trade_id) {
				p2pRequestState.p2pChat?.admin.push(payload.admin[0]);
			}

			// p2pRequestState.p2pBalancesListLoader = false;
		},
		postChatMessageAdminSendRequest: (state, action: PayloadAction<IP2PTradeChatMessgeRequest>) => {
			const p2pRequestState = state;
			// p2pRequestState.p2pBalancesListLoader = false;
		},
		postChatMessageAdminSendSuccess: (
			state,
			action: PayloadAction<IP2PTradeChatMessgeResponse>,
		) => {
			const p2pRequestState = state;
			const { payload } = action;

			if (p2pRequestState.p2pChat?.admin) {
				p2pRequestState.p2pChat?.admin.push(payload);
			}
		},
		putCancelTradeRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;

			// p2pRequestState.p2pBalancesListLoader = true;
		},

		putHavePaidSellerRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;

			// p2pRequestState.p2pBalancesListLoader = true;
		},

		putRecivedPaymentRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;

			// p2pRequestState.p2pBalancesListLoader = true;
		},
		putOpenDisputeRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;

			// p2pRequestState.p2pBalancesListLoader = true;
		},
		getDocumnetsRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;
		},
		getDocumnetsSuccess: (state, action: PayloadAction<IP2PDocumentResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.photoDocumnets = payload;
		},
		getAllNotificationRequest: (state, action: PayloadAction<IP2PNotificationRequest>) => {
			const p2pRequestState = state;
			p2pRequestState.p2pStateLoader = true;
		},
		getAllNotificationSuccess: (state, action: PayloadAction<IP2PNotificationResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.p2pNotificaion = payload;
			p2pRequestState.p2pStateLoader = false;
		},
		getNotificationBellRequest: (state) => {
			const p2pRequestState = state;
		},
		getNotificationBellSuccess: (state, action: PayloadAction<IP2PNotificationBellResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			p2pRequestState.notifiactionBell = payload;
		},
		changeNotificationStatusRequest: (state, action: PayloadAction<INotificationStatusRequest>) => {
			const p2pRequestState = state;
		},
		postNickNameRequest: (state, action: PayloadAction<ICreateP2PUserRequest>) => {
			const p2pRequestState = state;
		},

		transferSpotToP2PRequest: transferActionsRequest,
		transferSpotToP2PSuccess: unsetTransferActsLoading,
		transferSpotToP2PError: unsetTransferActsLoading,

		transferP2PToSpotRequest: transferActionsRequest,
		transferP2PToSpotSuccess: unsetTransferActsLoading,
		transferP2PToSpotError: unsetTransferActsLoading,

		transferCrossToP2PRequest: transferActionsRequest,
		transferCrossToP2PSuccess: unsetTransferActsLoading,
		transferCrossToP2PError: unsetTransferActsLoading,

		transferP2PToCrossRequest: transferActionsRequest,
		transferP2PToCrossSuccess: unsetTransferActsLoading,
		transferP2PToCrossError: unsetTransferActsLoading,

		transferIsolatedToP2PRequest: transferP2PIsolatedRequest,
		transferIsolatedToP2PSuccess: unsetTransferActsLoading,
		transferIsolatedToP2PError: unsetTransferActsLoading,

		transferP2PToIsolatedRequest: transferP2PIsolatedRequest,
		transferP2PToIsolatedSuccess: unsetTransferActsLoading,
		transferP2PToIsolatedError: unsetTransferActsLoading,

		postUploadEvidenceRequest: (state, action: PayloadAction<IP2PUploadEvidanceRequest>) => {
			const p2pRequestState = state;
		},
		putEvidenceRequest: (state, action: PayloadAction<IP2PDeleteEvidanceRequest>) => {
			const p2pRequestState = state;
			// const { payload } = action;

			// p2pRequestState.photoDocumnets = payload;
		},
		// p2pInitState: () => initialState,
		p2pInitState: (state) => {
			const p2pRequestState = state;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		setP2PTradeInitState: (state) => {
			const tradeState = state;
			state.tradePaySellData = null;
		},
		postPaymentMethodRequest: (state, action: PayloadAction<IPaymentMethodRequest>) => {
			const p2pRequestState = state;
		},
		postPaymentMethodSuccess: (state, action: PayloadAction<ICreateP2PUserRequest>) => {
			const p2pRequestState = state;
		},
		getPaymentMethodRequest: (state, action: PayloadAction<IMethodRequest>) => {
			const p2pRequestState = state;
			p2pRequestState.lisPaymentsLoader = true;
		},
		getPaymentMethodSuccess: (state, action: PayloadAction<IPaymentMethodResponse>) => {
			const p2pRequestState = state;
			p2pRequestState.lisPaymentsMethod = action.payload;
			p2pRequestState.lisPaymentsLoader = false;
		},
		deletePaymentMethodRequest: (state, action: PayloadAction<IParamsDeleteRequest>) => {
			const p2pRequestState = state;
			p2pRequestState.lisPaymentsLoader = true;
		},
		deletePaymentMethodError: (state) => {
			const p2pRequestState = state;
		},
		changeAdvertActiveStatusRequest: (
			state,
			action: PayloadAction<IChangeAdvertActiveStatusParams>,
		) => {
			const p2pRequestState = state;
			p2pRequestState.advertChangeStatusLoader = true;
		},
		changeAdvertActiveStatusSuccess: (
			state,
			{ payload }: PayloadAction<IP2PAdevrtisementBuySell>,
		) => {
			const p2pRequestState = state;
			p2pRequestState.advertChangeStatusLoader = false;
			if (p2pRequestState.p2pAdvertisement?.buy?.data) {
				p2pRequestState.p2pAdvertisement.buy.data = p2pRequestState.p2pAdvertisement.buy.data.map(
					(item) => (item.id === payload.id ? { ...item, status: payload.status } : item),
				);
			}
			if (p2pRequestState.p2pAdvertisement?.sell?.data) {
				p2pRequestState.p2pAdvertisement.sell.data = p2pRequestState.p2pAdvertisement.sell.data.map(
					(item) => (item.id === payload.id ? { ...item, status: payload.status } : item),
				);
			}
		},
		changeAdvertActiveStatusError: (state) => {
			const p2pRequestState = state;
			p2pRequestState.advertChangeStatusLoader = false;
		},
		checkAdvertActiveOrderRequest: (
			state,
			action: PayloadAction<ICheckAdvertActiveOrderPayload>,
		) => {
			const p2pRequestState = state;
			p2pRequestState.checkAdvertActiveLoader = true;
		},
		checkAdvertActiveOrderFinally: (state) => {
			const p2pRequestState = state;
			p2pRequestState.checkAdvertActiveLoader = false;
		},
		getActiveTradeRequest: (state, action: PayloadAction<IGetActiveTradePayload>) => {
			const p2pRequestState = state;
			p2pRequestState.activeTradeLoader = true;
		},
		getActiveTradeFinally: (state) => {
			const p2pRequestState = state;
			p2pRequestState.activeTradeLoader = false;
		},
		checkOrderAmountRequest: (state, action: PayloadAction<ICheckOrderAmountPayload>) => {
			const p2pState = state;

			p2pState.checkAmountLoading = true;
		},
		checkOrderAmountFinally: (state) => {
			const p2pState = state;

			p2pState.checkAmountLoading = false;
		},
	},
});

function transferActionsRequest(state: IP2PStore, action: PayloadAction<IP2PTransferPayload>) {
	state.transferActsLoading = true;
}
function unsetTransferActsLoading(state: IP2PStore) {
	state.transferActsLoading = false;
}
function transferP2PIsolatedRequest(
	state: IP2PStore,
	action: PayloadAction<IP2PIsolatedTransferPayload>,
) {
	state.transferActsLoading = true;
}

export default p2p.reducer;
export const {
	getP2PBalancesListRequest,
	getP2PBalancesListSuccess,
	getP2POpenTradesRequest,
	getP2POpenTradesSuccess,
	getP2PClosedTradesRequest,
	getP2PClosedTradesSuccess,
	getP2PAdevertisementRequest,
	getP2PAdevertisementSuccess,
	getP2PAdevertisementFailure,
	getP2PAssetsRequest,
	getP2PDeletedRequest,
	getP2PAssetsSuccess,
	getP2PDeletedSuccess,
	getP2PQuickRequest,
	getP2PQuickSuccess,
	setCurrentCoin,
	setCurrency,
	setCountry,
	setCurrentType,
	setCurrentPaymnent,
	setCurrentPayments,
	setPaymentAmount,
	setPaymentWindow,
	getP2PCurrenciesRequest,
	getP2PCurrenciesSuccess,
	getAllAdvertisementRequest,
	getAllAdvertisementSuccess,
	getProfileRequest,
	getProfileSuccess,
	getProposalRequest,
	getProposalSuccess,
	getPaymentRequest,
	getPaymentSuccess,
	postAdvertisementRequest,
	postAdvertisementSuccess,
	getAdvertisementRequest,
	getAdvertisementSuccess,
	postTradeDetailsRequest,
	postTradeDetailsSuccess,
	getTradePaySellRequest,
	getTradePaySellSuccess,
	getChatRequest,
	getChatSuccess,
	putCancelTradeRequest,
	putHavePaidSellerRequest,
	putRecivedPaymentRequest,
	getTradePaySellUpdate,
	postChatMessageSendRequest,
	postChatMessageSendSuccess,
	postChatMessageAdminSendRequest,
	postChatMessageAdminSendSuccess,
	postChatMessageSendUpdate,
	getDocumnetsRequest,
	getDocumnetsSuccess,
	transferSpotToP2PRequest,
	transferSpotToP2PSuccess,
	transferSpotToP2PError,
	transferP2PToSpotRequest,
	transferP2PToSpotSuccess,
	transferP2PToSpotError,
	transferCrossToP2PRequest,
	transferCrossToP2PSuccess,
	transferCrossToP2PError,
	transferP2PToCrossRequest,
	transferP2PToCrossSuccess,
	transferP2PToCrossError,
	transferIsolatedToP2PRequest,
	transferIsolatedToP2PSuccess,
	transferIsolatedToP2PError,
	transferP2PToIsolatedRequest,
	transferP2PToIsolatedSuccess,
	transferP2PToIsolatedError,
	putOpenDisputeRequest,
	postUploadEvidenceRequest,
	putEvidenceRequest,
	p2pInitState,
	postAdvertisementFalure,
	getAllNotificationRequest,
	getAllNotificationSuccess,
	getNotificationBellRequest,
	getNotificationBellSuccess,
	changeNotificationStatusRequest,
	postNickNameRequest,
	setP2PTradeInitState,
	getPaymentMethodsRequest,
	getPaymentMethodsSuccess,
	postPaymentMethodRequest,
	getPaymentMethodRequest,
	getPaymentMethodSuccess,
	deletePaymentMethodRequest,
	deletePaymentMethodError,
	changeAdvertActiveStatusRequest,
	changeAdvertActiveStatusSuccess,
	changeAdvertActiveStatusError,
	checkAdvertActiveOrderRequest,
	checkAdvertActiveOrderFinally,
	getActiveTradeRequest,
	getActiveTradeFinally,
	checkOrderAmountRequest,
	checkOrderAmountFinally,
} = p2p.actions;
