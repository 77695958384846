import { FC, useEffect, useRef, useState } from 'react';
import L from 'i18n-react';
import { IWalletOption } from '../types';

import { WalletSelectProps } from './types';

const WalletSelect: FC<WalletSelectProps> = ({ options, onChange, selected }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const selectRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (!selectRef.current?.contains(event.target as Node)) {
				setIsMenuOpen(false);
			}
		};

		document.addEventListener('mousedown', handleOutsideClick);

		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [isMenuOpen]);

	const handleChange = (option: IWalletOption) => {
		onChange(option);
		setIsMenuOpen(false);
	};

	const getSelectClassName = () => {
		const classes = ['select', 'select--regular', 'details-list__select'];
		if (isMenuOpen) classes.push('active');
		return classes.join(' ');
	};

	return (
		<div className={getSelectClassName()} ref={selectRef}>
			<button
				type="button"
				className="select__current select__current--no-coin"
				onClick={() => setIsMenuOpen((state) => !state)}
			>
				{String(L.translate(`Base.Modals.wallets.wallet_${selected.id}`))}
				<span className="select__arrow icon-arrow2" />
			</button>

			<div className="select__drop">
				<div className="select__drop-scroll">
					{options.map((option) => (
						<div key={option.id} className="select__drop-item">
							<button
								type="button"
								className="select__drop-link"
								onClick={() => handleChange(option)}
							>
								<span className="select__drop-text">
									{String(L.translate(`Base.Modals.wallets.wallet_${option.id}`))}
								</span>
							</button>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default WalletSelect;
