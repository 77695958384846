/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { appLanguage } from 'redux/reducers/app/selectors';
import { getCurrentInterval, getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getColorTheme } from 'redux/reducers/app/selectors';
// import { widget, onready } from './charting_library/charting_library.min';
import Datafeed from './datafeed';
import {
	widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	ResolutionString,
} from '../../charting_library';
import './index.css';
// import { getTimezone } from '../../../services/helpers';
import {
	toggleSelectResolution,
	setSelectResolution,
	newHeadInterval,
	newHeadIntervalPopups,
} from './helpers/interval';
// import { storeCurentDecimal } from '../../../redux/decimals/selectors';

export interface ChartContainerProps {
	symbol: ChartingLibraryWidgetOptions['symbol'];
	interval: ChartingLibraryWidgetOptions['interval'];

	// BEWARE: no trailing slash is expected in feed URL
	libraryPath: ChartingLibraryWidgetOptions['library_path'];
	chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
	chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
	clientId: ChartingLibraryWidgetOptions['client_id'];
	userId: ChartingLibraryWidgetOptions['user_id'];
	fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
	autosize: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
	container: ChartingLibraryWidgetOptions['container'];
	timezone: ChartingLibraryWidgetOptions['timezone'];
	theme: ChartingLibraryWidgetOptions['theme'];
}

const Chart = () => {
	const currentPair = useSelector(getCurrentPair);
	const currentInterval = useSelector(getCurrentInterval);
	const appLng = useSelector(appLanguage);
	const pair = currentPair;
	const intervalLs = currentInterval;
	const language = appLng;

	const appTheme = useSelector(getColorTheme);
	const isDark = appTheme === 'dark';

	const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

	const pathname = window?.location?.pathname;
	const getSymbol = (pathname: string) => {
		switch (true) {
			case pathname.includes('/spot'):
				return pair.replace('_', '/').toUpperCase();
			case pathname.includes('/MarginTrading'):
				return pair.replace('_', '/').toUpperCase();
			case pathname.includes('/chart'):
				return pair.replace('_', '/').toUpperCase();
			default:
				return 'BTC/USDT';
		}
	};

	const symbol = getSymbol(pathname);

	const timezone = 'Europe/Athens';

	const interval = intervalLs || '1';

	const defaultProps: Omit<ChartContainerProps, 'container'> = {
		symbol,
		interval: interval as ResolutionString, // 1 minutes
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'nostafaratum',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
		timezone,
		theme: isDark ? 'dark' : 'light',
	};

	useEffect(() => {
		const widgetOptions: ChartingLibraryWidgetOptions = {
			symbol: defaultProps.symbol as string,
			// BEWARE: no trailing slash is expected in feed URL
			// tslint:disable-next-line:no-any
			// datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
			datafeed: Datafeed,
			interval: defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath as string,

			locale: (language.toLowerCase() as LanguageCode) || 'en',
			disabled_features: ['use_localstorage_for_settings', 'header_symbol_search'],
			enabled_features: ['study_templates'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			theme: defaultProps.theme,
			overrides: defaultProps.theme === 'dark' ? {
					"paneProperties.background": "#13131b",
					"paneProperties.backgroundType": "solid",
					"paneProperties.vertGridProperties.color": "transparent",
					"paneProperties.horzGridProperties.color": "transparent"
			} : {},
		};

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				tvWidget.chart().removeAllStudies();
				// tvWidget.chart().createStudy('Volume', false, true);
				tvWidget.chart().createStudy('Moving Average', false, false);
				tvWidget.chart().createStudy('Moving Average', false, false);
				tvWidget.chart().createStudy('Moving Average', false, false);
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () =>
					tvWidget.showNoticeDialog({
						title: 'Notification',
						body: 'TradingView Charting Library API works correctly',
						callback: () => {
							console.log('Noticed!');
						},
					}),
				);
				button.innerHTML = 'Check API';
			});
		});

		return () => {
			tvWidget.remove();
		};
	});

	return <div ref={chartContainerRef} className={'TVChartContainer'} />;
};
export default React.memo(Chart);

