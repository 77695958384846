/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable global-require */
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import L from 'i18n-react';
import { getP2PNotificationBell } from 'redux/reducers/p2p/selectors';
import { getNotificationBellRequest } from 'redux/reducers/p2p/reducer';
import NotificationSound from 'assets/sounds/notification-sound.mp3';
// import {
// 	getBellNotifications,
// 	postChangeNotificationsReadStatus,
// } from '../../../redux/user/bellNotification/operations';
// import routes from '../../../routes';
// import NotificationItem from './NotificationItem';
// import NothingToShowList from '../../Common/NothingToShowList';
// import BellIcon from '../../icons/BellIcon';

const NotificationBell: FC = () => {
	const dispatch = useDispatch();
	const bell = useSelector(getP2PNotificationBell);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const [count, setCount] = useState(bell?.count_unread || 0);
	const audioPlayer = useRef<HTMLAudioElement>(null);

	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!authIsAuthenticated) return;
		dispatch(getNotificationBellRequest());
	}, [authIsAuthenticated]);

	useEffect(() => {
		if (bell && count && bell?.count_unread > count) {
			audioPlayer?.current?.play();
			setCount(bell.count_unread);
		}
	}, [bell?.count_unread, audioPlayer]);

	// const toggleDropBox = () => {
	// 	setOpen((prev) => {
	// 		if (!prev && !!bell?.count_unread) {
	// 			dispatch(postChangeNotificationsReadStatus());
	// 		}
	// 		return !prev;
	// 	});
	// };

	// const unreadNotifsArray =
	// 	+bell?.count_unread > 0
	// 		? bell?.notifications?.filter((item) => {
	// 				return item?.read === false;
	// 		  })
	// 		: [];

	return (
		<SocketWithPrivateTopicConnectWrapper topicName="bell">
			<audio ref={audioPlayer} src={NotificationSound} />
			<div className="select header__select">
				<NavLink to="/notification" activeClassName="active" className="select__current">
					<span className="select__icon icon-Notification">
						{bell?.count_unread ? (
							<span className="d-flex top-notification__count JS_Notif_Button">
								{bell?.count_unread}
							</span>
						) : null}
					</span>
					{String(L.translate('Notification.notification_title'))}
				</NavLink>
			</div>
		</SocketWithPrivateTopicConnectWrapper>
		// <div
		// 	className={open ? 'top-notification-block active' : 'top-notification-block'}
		// 	style={{ marginLeft: '15px' }}
		// >
		// 	<button className="top-notification JS_Notif_Button" type="button" onClick={toggleDropBox}>
		// 		<BellIcon
		// 			onClick={(e) => {
		// 				e.preventDefault();
		// 				e.stopPropagation();
		// 			}}
		// 		/>

		// 		{bell?.count_unread !== 0 && (
		// 			<span className="d-flex top-notification__count JS_Notif_Button">
		// 				{bell.count_unread}
		// 			</span>
		// 		)}
		// 	</button>
		// </div>
	);
};
export default NotificationBell;
