import { FC } from 'react';
import { IDetailsRow } from './types';

const DetailsRow: FC<IDetailsRow> = ({ title, value }) => {
	return (
		<div className="details-list__row">
			<div className="details-list__left">
				<span className="details-list__title">{title}:</span>
			</div>
			<div className="details-list__right">
				<span className="details-list__desc">{value}</span>
			</div>
		</div>
	);
};

export default DetailsRow;
