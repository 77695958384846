/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IConfyrmCryptoWithdrawalRequestPayload,
	ICheckWithdrawalAddressRequestPayload,
	ICheckWithdrawalAddressResponsePayload,
	ICheckWithdrawalCryptoAmountRequestPayload,
	ICheckWithdrawalCryptoAmountResponsePayload,
	ICheckAddressIsInternalRequest,
	ICheckAddressIsInternalResponse,
	IWitdrawCryptoStore,
} from './types';

// ==========================================:
export const initialState: IWitdrawCryptoStore = {
	toWithdrawalAddresIsValid: true,
	checkWithdrawalAddressLoader: false,
	confirmWithdrawLoader: false,
	checkWithdrawalCryptoAmount: null,
	checkWithdrawalCryptoAmountLoader: false,
	addressIsInternal: false,
};

// ==========================================:
const withdrawCrypto = createSlice({
	name: '@@withdrawCrypto',
	initialState,
	reducers: {
		checkWithdrawalAddressRequest: (
			state,
			action: PayloadAction<ICheckWithdrawalAddressRequestPayload>,
		) => {
			const checkWithdrawalAddressRequestState = state;

			checkWithdrawalAddressRequestState.checkWithdrawalAddressLoader = true;
			checkWithdrawalAddressRequestState.toWithdrawalAddresIsValid = true;
		},

		checkWithdrawalAddressSuccess: (
			state,
			action: PayloadAction<ICheckWithdrawalAddressResponsePayload>,
		) => {
			const { payload } = action;
			const checkWithdrawalAddressSuccessState = state;

			checkWithdrawalAddressSuccessState.toWithdrawalAddresIsValid = payload.is_valid;
			checkWithdrawalAddressSuccessState.checkWithdrawalAddressLoader = false;
		},

		sendWithdrawCryptoEmailCodeRequest: (state) => state,

		confirmCryptoWithdrawRequest: (
			state,
			action: PayloadAction<IConfyrmCryptoWithdrawalRequestPayload>,
		) => {
			state.confirmWithdrawLoader = true;
		},
		confirmCryptoWithdrawSuccess: (state) => {
			state.confirmWithdrawLoader = false;
		},
		confirmCryptoWithdrawError: (state) => {
			state.confirmWithdrawLoader = false;
		},

		calculateCryptoWithdrawAmountRequest: (
			state,
			action: PayloadAction<ICheckWithdrawalCryptoAmountRequestPayload>,
		) => {
			const confirmCryptoWithdrawAmountRequestState = state;

			confirmCryptoWithdrawAmountRequestState.checkWithdrawalCryptoAmountLoader = true;
		},

		calculateCryptoWithdrawAmountSuccess: (
			state,
			action: PayloadAction<ICheckWithdrawalCryptoAmountResponsePayload>,
		) => {
			const confirmCryptoWithdrawAmountRequestState = state;
			const { payload } = action;

			confirmCryptoWithdrawAmountRequestState.checkWithdrawalCryptoAmountLoader = false;
			confirmCryptoWithdrawAmountRequestState.checkWithdrawalCryptoAmount = payload;
		},

		checkAddressIsInternalRequest: (
			state,
			action: PayloadAction<ICheckAddressIsInternalRequest>,
		) => {
			const withdrawCryptoState = state;

			withdrawCryptoState.checkWithdrawalAddressLoader = true;
		},

		checkAddressIsInternalSuccess: (
			state,
			{ payload }: PayloadAction<ICheckAddressIsInternalResponse>,
		) => {
			const withdrawCryptoState = state;

			withdrawCryptoState.checkWithdrawalAddressLoader = false;
			withdrawCryptoState.addressIsInternal = payload.is_internal;
		},

		withdrawCryptoInitState: () => initialState,
	},
});

export default withdrawCrypto.reducer;
export const {
	checkWithdrawalAddressRequest,
	checkWithdrawalAddressSuccess,
	sendWithdrawCryptoEmailCodeRequest,
	confirmCryptoWithdrawRequest,
	confirmCryptoWithdrawSuccess,
	confirmCryptoWithdrawError,
	calculateCryptoWithdrawAmountRequest,
	calculateCryptoWithdrawAmountSuccess,
	checkAddressIsInternalRequest,
	checkAddressIsInternalSuccess,
	withdrawCryptoInitState,
} = withdrawCrypto.actions;
