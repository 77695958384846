/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC } from 'react';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { ITransferTableItemProps } from './types';

const TransferTableItem: FC<ITransferTableItemProps> = ({ data }) => {
	const checkType = (value: string) => {
		return value === 'earn' ? 'Partners & Benefits' : value;
	};
	return (
		<div className="tr" key={data?.user_id}>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p>
					{getLocaleDateFromTimestamp(data?.created_at)}{' '}
					{getLocaleTimeFromTimestamp(data?.created_at)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Asset</p>
				<p>{data?.asset?.code?.toLocaleUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">From wallet</p>
				<p>{checkType(data?.from)?.toLocaleUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">To wallet</p>
				<p>{checkType(data?.to)?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{convertExponentialToDecimal(Number(data?.amount))}</p>
			</div>
		</div>
	);
};

export default TransferTableItem;
