/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import L from 'i18n-react';
import FAQItem from './FAQItem';

const FAQ = () => {
	const [active, setActive] = useState<number | null>(null);
	const questions = [
		{
			index: 0,
			question: String(L.translate('FAQ.questions.0.question')),
			answer: String(L.translate('FAQ.questions.0.answer')),
		},
		{
			index: 1,
			question: String(L.translate('FAQ.questions.1.question')),
			answer: String(L.translate('FAQ.questions.1.answer')),
		},
		{
			index: 2,
			question: String(L.translate('FAQ.questions.2.question')),
			answer: String(L.translate('FAQ.questions.2.answer')),
		},
		{
			index: 3,
			question: String(L.translate('FAQ.questions.3.question')),
			answer: String(L.translate('FAQ.questions.3.answer')),
		},
		{
			index: 4,
			question: String(L.translate('FAQ.questions.4.question')),
			answer: String(L.translate('FAQ.questions.4.answer')),
		},
		{
			index: 5,
			question: String(L.translate('FAQ.questions.5.question')),
			answer: String(L.translate('FAQ.questions.5.answer')),
		},
		{
			index: 6,
			question: String(L.translate('FAQ.questions.6.question')),
			answer: String(L.translate('FAQ.questions.6.answer')),
		},
	];

	const onChangeShow = (index: number) => {
		setActive((prev) => (prev === index ? null : index));
	};

	const linkTarget = (
		<Link to="/our-prices" rel="noreferrer">
			{' '}
			{String(L.translate('FooterNav.our_prices'))}
		</Link>
	);

	return (
		<div className="section section--faq">
			<div className="container">
				<h2 className="section-title section-title--big">{L.translate('FAQ.title')}</h2>
				<div className="faq-list">
					{questions.map((item) => {
						const { question, answer, index } = item;
						const props = {
							question,
							answer,
							active,
							index,
							setActive: onChangeShow,
						};
						if (index === 5) {
							return (
								<FAQItem
									key={index}
									{...props}
									renderAnswer={() => {
										return (
											<>
												<p>{L.translate('FAQ.questions.5.answer_title')}</p>
												<ul>
													{Array(4)
														.fill(null)
														.map((_, idx) => {
															return (
																// eslint-disable-next-line react/no-array-index-key
																<li key={idx}>
																	{idx === 3
																		? L.translate(`FAQ.questions.5.AnswerRows.${idx}`, {
																				link: linkTarget,
																		  })
																		: L.translate(`FAQ.questions.5.AnswerRows.${idx}`)}
																	{/* {L.translate(`FAQ.questions.5.AnswerRows.${idx}`)} */}
																</li>
															);
														})}
												</ul>
											</>
										);
									}}
								/>
							);
						}
						return <FAQItem key={index} {...props} />;
					})}
				</div>
				{/* <div className="faq-btn">
					<a href="/" className="button button--new button--new-type2">
						{L.translate('FAQ.see_more')}
						<span className="btn-icon">
							<svg
								width="15"
								height="14"
								viewBox="0 0 15 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.9766 6.1664L6.50658 1.6964L7.68492 0.518066L14.1666 6.99973L7.68492 13.4814L6.50658 12.3031L10.9766 7.83307H0.833252V6.1664H10.9766Z"
									fill="white"
								/>
							</svg>
						</span>
					</a>
				</div> */}
			</div>
		</div>
	);
};

export default FAQ;
