import { TCounties } from 'redux/reducers/app/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAppApi } from './types';

// ==========================================:
export const app: IAppApi = {
	getCountries: () => http.get<TCounties>(endpoint.app.COUNTRIES).then((response) => response.data),
	getTranslates: (params) =>
		http.get(endpoint.app.TRANSLATES, { params }).then((response) => response.data),
};
