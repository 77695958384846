import { FC } from 'react';
import L from 'i18n-react';
import { HIDE_VIP } from 'components/OurPrices/constants';
import { IP2PTableRow } from './types';

const TableRow: FC<IP2PTableRow> = ({ item, limit }) => {
	const {
		asset_code,
		network_name,
		withdraw_min,
		withdraw_max,
		vip_withdraw_min,
		vip_withdraw_max,
		vip_withdraw_no_limit,
		withdraw_no_limit,
	} = item;

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.currency')}</p>
				<p className="td-name">
					{asset_code.toUpperCase()} ({network_name.toUpperCase()})
				</p>
			</div>
			{limit ? null : (
				<div className="td">
					<p className="td-hidden-name">{L.translate('OurPrices.Columns.min_withdrawal')}</p>
					<p className="td-name">{withdraw_no_limit ? 'no limit' : withdraw_min}</p>
				</div>
			)}
			<div className={`td ${HIDE_VIP ? 'td--right' : ''}`}>
				<p className="td-hidden-name">{L.translate('OurPrices.Columns.max_withdrawal')}</p>
				<p className="td-name">{withdraw_no_limit ? 'no limit' : withdraw_max}</p>
			</div>
			{!HIDE_VIP && (
				<>
					{limit ? null : (
						<div className="td">
							<p className="td-hidden-name">
								{L.translate('OurPrices.Columns.vip_min_withdrawal')}
							</p>
							<p className="td-name">{vip_withdraw_no_limit ? 'no limit' : vip_withdraw_min}</p>
						</div>
					)}
					<div className="td td--right">
						<p className="td-hidden-name">{L.translate('OurPrices.Columns.vip_max_withdrawal')}</p>
						<p className="td-name">{vip_withdraw_no_limit ? 'no limit' : vip_withdraw_max}</p>
					</div>
				</>
			)}
		</div>
	);
};

export default TableRow;
