import { FC, useEffect } from 'react';
import L from 'i18n-react';
import snsWebSdk from '@sumsub/websdk';
import { useDispatch, useSelector } from 'react-redux';
import { getColorTheme } from 'redux/reducers/app/selectors';
import { dataLayer } from 'services/gtm';
import { IUserKycIframeProps } from './types';

const ModalIframe: FC<IUserKycIframeProps> = ({
	accessToken,
	// url,
	// name,
	email,
	phone,
	messages,
}) => {
	const theme = useSelector(getColorTheme);
	const launchWebSdk = () => {
		const snsWebSdkInstance = snsWebSdk
			.init(accessToken, () => Promise.resolve(accessToken))
			.withConf({
				lang: 'en',
				email,
				phone,
				// i18n: String(messages),
				// uiConf: {
				// 	customCssStr:
				// 		':root{\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}}',
				// },
				theme: theme === 'dark' ? 'dark' : 'light',
			})

			.on('idCheck.onStepCompleted', (payload) => {
				console.log('onStepCompleted', payload);
				dataLayer.push({
					event: 'complete_verification',
					verification_status: 'success',
				});
			})
			.on('idCheck.onError', (error) => {
				console.log('onError', error);
			})
			.build();

		snsWebSdkInstance.launch('#kyc-sumsub-verify');
	};

	useEffect(() => {
		if (accessToken) launchWebSdk();
		/* eslint-disable-next-line */
	}, [accessToken]);

	return <div id="kyc-sumsub-verify" />;
};

export default ModalIframe;
