import { FC } from 'react';
import {
	InstagramIcon,
	LinkedInIcon,
	FacebookIcon,
	DiscordIcon,
	TwitterIcon,
} from './social-icons';
import { ISocialList } from './types';

// ==========================================:
const SocialList: FC<ISocialList> = ({ isNew }) => {
	return (
		<div className={`social ${isNew ? '' : 'footer__social'}`}>
			<a
				href="https://www.instagram.com/bitanica_com"
				target="_blank"
				rel="noreferrer"
				className={`social__link ${isNew ? 'social__link--new' : ''}`}
				aria-label="facebook link"
			>
				<span className="social__icon social__icon--svg">
					<InstagramIcon />
				</span>
			</a>
			<a
				href="https://discord.gg/EBwHhaMTwJ"
				target="_blank"
				rel="noreferrer"
				className={`social__link ${isNew ? 'social__link--new' : ''}`}
				aria-label="facebook link"
			>
				<span className="social__icon social__icon--svg">
					<DiscordIcon />
				</span>
			</a>
			<a
				href="https://x.com/Bitanica_com"
				target="_blank"
				rel="noreferrer"
				className={`social__link ${isNew ? 'social__link--new' : ''}`}
				aria-label="facebook link"
			>
				<span className="social__icon social__icon--svg">
					<TwitterIcon />
				</span>
			</a>
			<a
				href="https://www.linkedin.com/company/101343389/admin/feed/posts"
				target="_blank"
				rel="noreferrer"
				className={`social__link ${isNew ? 'social__link--new' : ''}`}
				aria-label="twitter link"
			>
				<span className="social__icon social__icon--svg">
					<LinkedInIcon />
				</span>
			</a>
			<a
				href="https://www.facebook.com/people/Bitanica/61557073029250"
				target="_blank"
				rel="noreferrer"
				className={`social__link ${isNew ? 'social__link--new' : ''}`}
				aria-label="instagram link"
			>
				<span className="social__icon social__icon--svg">
					<FacebookIcon />
				</span>
			</a>
		</div>
	);
};

export default SocialList;
