import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getP2PBalancesList } from 'redux/reducers/p2p/selectors';
import { NavLink } from 'react-router-dom';
/* disable-eslint */

// ==========================================:
const TestPage1: FC = () => {
	const [frame, setFrame] = useState<any>('');
	const makeFrame = (url: string) => {
		const frameContainer: any = (
			<iframe
				title="This is a unique title"
				width="100%"
				height="600px"
				src={url}
				id="shuftipro-iframe"
				allow="camera"
				frameBorder="0"
			/>
		);
		console.log('hello');
		setFrame(frameContainer);
	};
	return (
		<>
			<button
				type="button"
				onClick={() =>
					makeFrame(
						'http://test-e-commerce2.cartubank.ge:8980/payscreen/proceed-mpi/LP1Apbvaw4MZPtXr37Rez5jZg2mvTQi',
					)
				}
			>
				Click-Frame-1
			</button>
			<iframe
				title="This is a unique title"
				width="100%"
				frameBorder="0"
				allow="camera"
				height="600px"
				src="http://test-e-commerce2.cartubank.ge:8980/payscreen/proceed-mpi/LP1Apbvaw4MZPtXr37Rez5jZg2mvTQi"
			/>
			<div className="main-test">sdfsdf{frame ? <div className="frame">{frame}</div> : ''}</div>
		</>
	);
};

export default TestPage1;
