import axios from 'axios';
import L from 'i18n-react';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import visaIcon from 'assets/images/general/visa.webp';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletsIsLoad, getWalletsList } from 'redux/reducers/wallets/selectors';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { IWalletItem, IWalletNetworkItem } from 'redux/reducers/wallets/types';
import TextError from 'ui/Formik/TextError';
import DCNDropdown from 'ui/DCNDropdown';
import { DebounceInput } from 'react-debounce-input';
import { getWithdrawalFee, getCardList, getCardStatus } from 'redux/reducers/cards/selectors';
import { cardHideNumber } from 'services/utils/cardHelper';
import { numberValidation } from 'services/utils/numberValidation';
import { clearWalletAddress } from 'redux/reducers/wallets/reducer';
import { ArrowRight } from 'assets/svg-icon';
import { numberExists } from 'services/utils/number';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import DCDropdown from 'ui/DCDropdown';
import Loader from 'ui/Loader';
import { Select } from 'ui/Select/Select';
import Notify from 'ui/StableCoinNotify';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import {
	getWithdrawalFiatFeeRequest,
	getCardsRequest,
	getCardStatusRequest,
	initialWithdrawalFiatFee,
} from 'redux/reducers/cards/reducer';
import { IFeeWithdrowalFiatPayload } from 'redux/reducers/cards/types';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { getAuth } from 'redux/reducers/auth/selectors';
import PhoneInput from 'react-phone-input-2';
import {
	getCurrencyPaymentsListRequest,
	postPaymentsCartubank,
	getBankTransferRequest,
	getFiatLimitationsRequest,
} from 'redux/reducers/payments/reducer';
import {
	getCurrencyPaymentsList,
	getCurrencyPaymentsListLoader,
	getFiatLimitations,
} from 'redux/reducers/payments/selectors';
import { calculateFiatWithdrawalRequest } from 'redux/reducers/withdrawFiat/reducer';
import { getFiatWithdrawResidual } from 'redux/reducers/withdrawFiat/selectors';
import { ICalculateFiatWithdrawalPayload } from 'redux/reducers/withdrawFiat/types';
import {
	ICheckWithdrawalAddressRequestData,
	ICheckWithdrawalCryptoAmountPayloadData,
} from 'redux/reducers/withdrawCrypto/types';
import {
	calculateCryptoWithdrawAmountRequest,
	checkWithdrawalAddressRequest,
	checkAddressIsInternalRequest,
} from 'redux/reducers/withdrawCrypto/reducer';
import {
	getCalculatekWithdrawalCryptoAmount,
	getToWithdrawalAddressIsValid,
	getCheckWithdrawalAddressLoader,
	getAddressIsInternal,
} from 'redux/reducers/withdrawCrypto/selectors';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import { notificationContainer } from 'services/utils/notificationContainer';
import { convertMessage } from 'services/utils/trimComment';
import { isStablecoin } from 'services/utils/wallets';
import { dataLayer } from 'services/gtm';
// import { EPaymentsListType } from 'redux/reducers/payments/types';
import { IStateDepositLocation, ISelectProps } from 'components/CreateDepositFiat/types';
import NetworkFeesTooltip from '../../shared/NetworkFeesTooltip';
import BlockchainConfirm from '../Popups/BlockchainConfirm';
import WithdrawFiatConfirmPopup from '../WithdrawFiatConfirmPopup';
import TransactionFeeTooltip from './TransactionFeeTooltip';
import { CURRENCY_UTF } from './constants';

/* eslint-disable */ // ==========================================:
const SelecetCurrencyPayment: FC<ISelectProps> = ({
	setActiveView,
	selectWallet,
	setSelectWallet,
	amountField,
	selectedPayment,
	setSelectedPayment,
	setAmountField,
	setCheckCard,
	setShowModal,
	checkCard,
	setBankTransferConfirm,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const withdrawalFiatFee = useSelector(getWithdrawalFee);
	const cardList = useSelector(getCardList);
	const fiatLimitations = useSelector(getFiatLimitations);
	const walletAddressToIsValid = useSelector(getToWithdrawalAddressIsValid);
	const residual = useSelector(getFiatWithdrawResidual);
	const addressIsInternal = useSelector(getAddressIsInternal);
	const calculateWithdrawalCryptoAmount = useSelector(getCalculatekWithdrawalCryptoAmount);
	const checkWithdrawalAddressLoader = useSelector(getCheckWithdrawalAddressLoader);
	const [openModal, setOpenModal] = useState(false);
	const { state } = useLocation<IStateDepositLocation>();
	const [fee, setFee] = useState('');
	const [selectedPaymentVariantId, setSelectedPaymentVariantId] = useState('');
	const [selectedPaymentValue, setSelectedPaymentValue] = useState('');
	const [selectedPaymentError, setSelectedPaymentError] = useState('');

	// const [selectWallet, setSelectWallet] = useState<IWalletItem | null>(
	// 	state?.currentWallet || null,
	// );
	// const [selectedPayment, setSelectedPayment] = useState('');
	const [amount, setAmount] = useState(String(amountField) || '');
	const closeModal = () => setOpenModal(false);

	const recive = amountField && fee && Number(amountField) - Number(fee);

	const [amountErrorVisibility, setAmountErrorVisibility] = useState(false);
	const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
	const [amountError, setAmountError] = useState<string | null>(null);

	const [walletNetworks, setWalletNetworks] = useState<Array<IWalletNetworkItem> | null>(
		state?.currentWallet?.networks || null,
	);
	const [selectWalletNetworks, setSelectWalletNetworks] = useState<IWalletNetworkItem | null>(null);
	const [walletAddressTo, setWalletAddressTo] = useState('');
	const [limit, setLimit] = useState({ min: 10, max: 3000 });
	const [visibleWithdrawalInfo, setVisibleWithdrawalInfo] = useState(false);

	const token = useSelector(getAuth);
	const userData = useSelector(getUserSettingsData);
	const walletsList = useSelector(getWalletsList);
	const walletsListLoading = useSelector(getWalletsIsLoad);
	const paymentsList = [...useSelector(getCurrencyPaymentsList), 'blockchain_network'];
	const paymentsListLoading = useSelector(getCurrencyPaymentsListLoader);
	const cardStatus = useSelector(getCardStatus);

	const assetCode = selectWallet?.asset.code;
	const fiatCode = assetCode?.endsWith('b') ? assetCode.slice(0, assetCode.length - 1) : assetCode;

	const utf8Code = String.fromCharCode(CURRENCY_UTF[fiatCode || '']);

	const cryptoWalletsList = useMemo(() => {
		if (!walletsList) return [];

		return walletsList.filter((wallet) => {
			return wallet.asset.type === 'fiat' && wallet.asset.depositable;
		});
	}, [walletsList]);

	const fiatWalletsList = useMemo(
		() => (walletsList?.length ? walletsList.filter((wallet) => isStablecoin(wallet)) : []),
		[walletsList],
	);

	const getWalletsSelectPlaceholder = () => {
		if (!walletsListLoading && !cryptoWalletsList.length) {
			return String(L.translate('DepositFiat.no_currencies'));
		}
		return String(L.translate('DepositFiat.select_currency'));
	};

	let closeCopyMessage: ReturnType<typeof setTimeout>;

	const getLimitation = () => {
		let channelName = selectedPayment;
		if (selectedPayment === 'cartubank') {
			channelName = checkCard?.channel;
		}
		const limitation = fiatLimitations?.find(
			(item) => item.asset_code === fiatCode && item.channel_name === channelName,
		);

		return limitation;
	};

	const limitation = getLimitation();

	const fixedWithdrawFee = userData?.is_vip
		? Number(selectWalletNetworks?.vip_fixed_withdraw_fee)
		: Number(selectWalletNetworks?.fixed_withdraw_fee);

	const withdrawNetworkFee = userData?.is_vip
		? Number(selectWalletNetworks?.vip_withdraw_fee)
		: Number(selectWalletNetworks?.withdraw_fee);

	const withdrawFeeCrypto = fixedWithdrawFee + (fixedWithdrawFee * withdrawNetworkFee) / 100;

	const withdrawMin = useMemo(() => {
		if (selectedPayment === 'blockchain_network' && selectWalletNetworks) {
			return userData?.is_vip
				? Number(selectWalletNetworks?.vip_withdraw_min)
				: Number(selectWalletNetworks?.withdraw_min);
		}
		return userData?.is_vip ? limitation?.vip_withdraw_min : limitation?.withdraw_min;
	}, [selectWalletNetworks, selectedPayment, userData, limitation]);

	const withdrawMax = useMemo(() => {
		if (selectedPayment === 'blockchain_network' && selectWalletNetworks) {
			return userData?.is_vip
				? Number(selectWalletNetworks?.vip_withdraw_max)
				: Number(selectWalletNetworks?.withdraw_max);
		}
		return userData?.is_vip ? limitation?.vip_withdraw_max : limitation?.withdraw_max;
	}, [selectWalletNetworks, selectedPayment, userData, limitation]);

	const noLimit = userData?.is_vip
		? limitation?.vip_withdraw_no_limit === 1
		: limitation?.withdraw_no_limit === 1;

	const handleNetworkSelect = (value: IWalletNetworkItem) => {
		dataLayer.push({
			event: 'select_network',
		});
		setSelectWalletNetworks(value);
		setWalletAddressTo('');
		setAmount('');
		if (assetCode) {
			const params: ICheckWithdrawalCryptoAmountPayloadData = {
				asset_code: assetCode,
				network: value.network_id,
			};
			dispatch(calculateCryptoWithdrawAmountRequest(params));
		}
	};

	const isInvalidFormatAddressChecked = walletAddressTo && !walletAddressToIsValid;

	const isAmountMoreBalance = Number(selectWallet?.balance) < Number(amount);
	const isAmountLessWithdrawMin = !noLimit && amount && Number(withdrawMin) > Number(amount);
	const isAmountMoreWithdrawMax = !noLimit && amount && Number(withdrawMax) < Number(amount);

	const withdrawalInfoIsVisible =
		selectWallet && selectWalletNetworks && walletAddressTo && !isInvalidFormatAddressChecked;

	const isMoreDailyLimitAmount =
		calculateWithdrawalCryptoAmount &&
		calculateWithdrawalCryptoAmount?.residual_amount &&
		Number(calculateWithdrawalCryptoAmount.residual_amount) < Number(amount);

	const receiveAmountIsVisible =
		selectWallet &&
		selectWalletNetworks &&
		walletAddressTo &&
		!!Number(amount) &&
		visibleWithdrawalInfo &&
		!isAmountLessWithdrawMin &&
		!isAmountMoreBalance &&
		!isMoreDailyLimitAmount;

	const getRecieveCryptoAmount = () => {
		if (addressIsInternal) {
			return amount;
		}
		return fixedCropNumber(
			Number(convertExponentialToDecimal(Number(amount) - withdrawFeeCrypto)),
			8,
		);
	};

	const recieveCryptoAmount = getRecieveCryptoAmount();

	useEffect(() => {
		if (!checkWithdrawalAddressLoader) {
			setVisibleWithdrawalInfo(!!withdrawalInfoIsVisible);
		}
	}, [withdrawalInfoIsVisible, checkWithdrawalAddressLoader]);

	useEffect(() => {
		selectWallet && setWalletNetworks(selectWallet.networks);
	}, [selectWallet]);

	useEffect(() => {
		if (selectedPayment === 'cartubank') {
			dispatch(getCardsRequest());
		}
		if (selectedPayment === 'bank_transfer') {
			dispatch(getBankTransferRequest());
		}
	}, [dispatch, selectedPayment]);

	// const handleNetworkSelect = (value: IWalletNetworkItem) => {
	// 	setSelectWalletNetworks(value);

	// 	const walletData = {
	// 		id: Number(selectWallet?.id),
	// 		network: String(value?.network_id),
	// 	};

	// 	dispatch(getGenerateAddressRequest(walletData));
	// };

	// const handleAddressCopy = () => {
	// 	setAddressCopyStatus({ copied: true });

	// 	closeCopyMessage = setTimeout(() => {
	// 		setAddressCopyStatus({ copied: false });
	// 	}, 2000);
	// };

	// const handleMemoCopy = () => {
	// 	setMemoCopyStatus({ copied: true });

	// 	closeCopyMessage = setTimeout(() => {
	// 		setMemoCopyStatus({ copied: false });
	// 	}, 2000);
	// };

	const handleWalletAddressToChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (selectWallet?.asset?.code && value.trim() && selectWalletNetworks?.network_id) {
			const requestData: ICheckWithdrawalAddressRequestData = {
				asset_code: selectWallet?.asset?.code,
				address: value.trim(),
				network: selectWalletNetworks?.network_id,
			};

			dispatch(checkWithdrawalAddressRequest(requestData));
			dispatch(checkAddressIsInternalRequest(requestData));
		}

		setWalletAddressTo(value);
	};

	const depositClose = () => {
		dispatch(clearWalletAddress());
		history.push('/fiat-and-spot');
	};
	// const depositCryptoOpen = () => {
	// 	dispatch(clearWalletAddress());
	// 	history.push('/deposit-crypto');
	// };

	const handlePaymentSelect = (e: ChangeEvent<HTMLInputElement>): void => {
		setCheckCard?.(null);
		setSelectedPayment && setSelectedPayment(e.target.id);
		if (e.target.id === 'cartubank') {
			history.push(
				`/withdraw?currency=${selectWallet?.asset?.code}${
					e.target.id === 'cartubank' ? '&type=card' : ''
				}`,
			);
		} else {
			history.push(`/withdraw?currency=${selectWallet?.asset?.code}`);
		}
		setAmount('');
	};

	useEffect(() => clearTimeout(closeCopyMessage));

	const handleChangePaymentVariantId = (el: string) => {
		setSelectedPaymentVariantId(el);
		setSelectedPaymentValue('');
		setSelectedPaymentError('');
	};

	useEffect(() => {
		dispatch(userSettingsRequest());
		dispatch(getFiatLimitationsRequest());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const checkVerify = (status: string | boolean) => {
		// console.log(status);
		// if (status) {
		// 	setShowModal && setShowModal(true);
		// 	// setVerifyModal && setVerifyModal(true);
		// }
	};

	useEffect(() => {
		if (cardStatus) {
			checkVerify(String(cardStatus?.is_verified));
		}
	}, [cardStatus]);

	const handleContinue = () => {
		if (selectedPayment === 'cartubank' && !cardList?.length) {
			notificationContainer(String(L.translate('WithdrawFiat.please_add_verify_card')), 'info');
			return;
		}
		if (!recive || recive <= 0) {
			setAmountError(String(L.translate('Errors.fee_greater_amount')));
			return;
		}
		switch (selectedPayment) {
			case 'cartubank':
				if (checkCard?.holder_name !== 'online' && checkCard?.id) {
					dispatch(getCardStatusRequest({ card_id: checkCard?.id }));
					setShowModal && setShowModal(true);
					break;
				}
				break;
			case 'bank_transfer':
				// setActiveView('addCard');
				if (checkCard) {
					setBankTransferConfirm?.(true);
				}
				break;
			// dispatch(
			// 	postPaymentsCartubank({
			// 		amount,
			// 		asset_id: selectWallet?.asset?.id || 0,
			// 	}),
			// );
			case 'blockchain_network':
				setOpenModal(true);
				break;
		}
	};

	const handleSelectedPaymentValuePhoneChange = (value: string): void => {
		setSelectedPaymentValue(value);
	};

	const handleClickWithdrawalMax = () => {
		if (selectWallet?.balance) {
			const args: number[] = [Number(selectWallet?.balance), withdrawMax || 0];
			if (!noLimit && numberExists(withdrawMax)) {
				args.push(withdrawMax || 0);
			}
			if (numberExists(residual.daily)) {
				args.push(residual.daily || 0);
			}
			if (numberExists(residual.monthly)) {
				args.push(residual.monthly || 0);
			}
			const result = String(Math.min.apply(this, args));
			setAmount(result);
			setAmountField?.(result);
		}
	};

	const handleSelectedPaymentValueChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.target;
		const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
		const emailTest = emailRegex.test(value);
		const paypalIdRegex = /^[\w\d]{13}$/g;
		const paypalIdTest = paypalIdRegex.test(value);

		if (!emailTest && selectedPaymentVariantId.toLowerCase() === 'email') {
			setSelectedPaymentError(String(L.translate('WithdrawFiat.email_error')));
		} else if (emailTest && selectedPaymentVariantId.toLowerCase() === 'email') {
			setSelectedPaymentError('');
		}
		if (!paypalIdTest && selectedPaymentVariantId.toLowerCase() === 'paypal id') {
			setSelectedPaymentError(String(L.translate('WithdrawFiat.paypal_id_error')));
		} else if (paypalIdTest && selectedPaymentVariantId.toLowerCase() === 'paypal id') {
			setSelectedPaymentError('');
		}
		if (!value) {
			setSelectedPaymentError('');
		}

		setSelectedPaymentValue(value);
	};
	const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setAmountErrorVisibility(true);
		if (!value) {
			setAmountErrorVisibility(false);
		}
		if (numberValidation(value)) {
			setAmount(value);
			setAmountField?.(value);
		}
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
	};

	const withdrawIsDisable = !(
		amount &&
		selectWallet &&
		Number(amount) <= Number(selectWallet?.balance) &&
		(noLimit || (Number(amount) >= (withdrawMin ?? 0) && Number(amount) <= (withdrawMax ?? 0)))
	);

	useEffect(() => {
		if (!amountField || !selectedPayment || selectedPayment === 'blockchain_network') return;
		if (selectedPayment === 'cartubank' && !checkCard?.card_number) return;
		if (!noLimit && (Number(amount) < (withdrawMin ?? 0) || Number(amount) > (withdrawMax ?? 0))) {
			dispatch(initialWithdrawalFiatFee());
			return;
		}
		const fiatWallet = walletsList?.find((wallet) => wallet.asset.code === fiatCode);
		const fiatAssetId = fiatWallet?.asset?.id;
		const params: IFeeWithdrowalFiatPayload = {
			amount: amountField,
			asset_id: String(fiatAssetId),
			channel_name: selectedPayment,
			card_number: selectedPayment === 'cartubank' ? checkCard?.card_number : undefined,
			swift: selectedPayment === 'bank_transfer' ? checkCard?.swift : undefined,
		};

		const newTypingTimeout: NodeJS.Timeout = setTimeout(() => {
			dispatch(getWithdrawalFiatFeeRequest(params));
		}, 700);

		setTypingTimeout(newTypingTimeout);
	}, [dispatch, amountField, fiatCode]);

	useEffect(() => {
		if (!fiatCode || !assetCode || !selectedPayment) return;
		if (selectedPayment === 'cartubank' && !checkCard?.card_number) return;
		if (selectedPayment === 'bank_transfer' && !checkCard?.swift) return;
		if (selectedPayment === 'blockchain_network') {
			return;
			// const params: ICheckWithdrawalCryptoAmountPayloadData = {
			// 	asset_code: assetCode,
			// 	network: '',
			// };
			// dispatch(calculateCryptoWithdrawAmountRequest(params))
		}
		const params: ICalculateFiatWithdrawalPayload = {
			asset_code: fiatCode,
			channel_name: selectedPayment,
			card_number: selectedPayment === 'cartubank' ? checkCard?.card_number : undefined,
			swift: selectedPayment === 'bank_transfer' ? checkCard?.swift : undefined,
		};
		dispatch(calculateFiatWithdrawalRequest(params));
	}, [checkCard, dispatch, fiatCode, assetCode, selectedPayment]);

	useEffect(() => {
		if (withdrawalFiatFee) {
			setFee(String(withdrawalFiatFee) || '0');
		} else {
			setFee('0');
		}
	}, [withdrawalFiatFee]);

	useEffect(() => {
		return (): void => {
			if (typingTimeout) {
				clearTimeout(typingTimeout);
			}
		};
	}, [typingTimeout]);

	useEffect(() => {
		setAmountError(null);
	}, [amount]);

	const errorBlock = (
		<div className="bottom-24">
			{amountErrorVisibility && isAmountLessWithdrawMin ? (
				<TextError>{String(L.translate('WithdrawFiat.amount_min_error'))}</TextError>
			) : null}
			{amountErrorVisibility && isAmountMoreWithdrawMax ? (
				<TextError>{String(L.translate('WithdrawFiat.amount_max_error'))}</TextError>
			) : null}
			{amountErrorVisibility && isAmountMoreBalance ? (
				<TextError>{String(L.translate('WithdrawFiat.amount_more_than_balance_error'))}</TextError>
			) : null}
			{residual.daily && +amount > residual.daily && (
				<TextError>
					{String(L.translate('WithdrawFiat.daily_limit_error', { value: residual.daily }))}
				</TextError>
			)}
			{residual.monthly && +amount > residual.monthly && (
				<TextError>
					{String(L.translate('WithdrawFiat.monthly_limit_error', { value: residual.monthly }))}
				</TextError>
			)}
			{amountError && <TextError>{amountError}</TextError>}
		</div>
	);

	const isInputError =
		((isAmountLessWithdrawMin || isAmountMoreBalance) && amountErrorVisibility) ||
		(residual.daily && +amount > residual.daily) ||
		(residual.monthly && +amount > residual.monthly) ||
		!!amountError;

	return (
		<>
			{!!selectWallet && (
				<>
					{paymentsListLoading ? (
						<div className="enter-value__field--loader">
							<Loader />
						</div>
					) : (
						<>
							{paymentsList.length ? (
								<div className="enter-value__row">
									<div>
										<span className="enter-value__label enter-value__label--padding">
											{String(L.translate('WithdrawFiat.pay_with'))}
										</span>
										<div className="enter-value__field enter-value__field--radio">
											{paymentsList.map((el) => (
												<div key={el} className="radio">
													<label className="radio__label">
														<input
															id={el}
															type="radio"
															name="payment"
															className="hidden"
															checked={selectedPayment === el}
															onChange={handlePaymentSelect}
														/>
														<span className="radio__item" />
														<span className="radio__text">
															{convertMessage(el === 'cartubank' ? 'Card' : el)}
														</span>
													</label>
												</div>
											))}
										</div>
									</div>

									<div className="faq-withdraw">
										<div className="faq-withdraw__title">
											{String(L.translate('WithdrawFiat.faq'))}
										</div>
										<div className="faq-withdraw__name">
											{String(L.translate('WithdrawFiat.withdraw_min_max'))}
										</div>
										{(noLimit || numberExists(withdrawMin) || numberExists(withdrawMax)) && (
											<div className="faq-withdraw__value">
												{noLimit
													? L.translate('WithdrawFiat.no_limit')
													: `${utf8Code}${withdrawMin ?? ''} / ${utf8Code}${withdrawMax ?? ''}`}
											</div>
										)}
									</div>
								</div>
							) : (
								<span className="enter-value__label enter-value__label--padding">
									{String(L.translate('WithdrawFiat.withdraw_not_supported'))}
								</span>
							)}
						</>
					)}
				</>
			)}
			{!!selectedPayment && selectedPayment === 'paypal' && (
				<div className="form-block form-block--padding">
					<div className="enter-value">
						<span className="block-label block-label--grey">
							{String(L.translate('WithdrawFiat.withdraw_info'))}
						</span>

						<span className="enter-value__label enter-value__label--padding">
							{String(L.translate('WithdrawFiat.variant_id'))}
						</span>
						<Select
							activeElement={selectedPaymentVariantId}
							placeholder="Variant id"
							className="enter-value__field"
							arr={['Paypal id', 'Email', 'Phone']}
							onChange={handleChangePaymentVariantId}
						/>

						<span className="enter-value__label enter-value__label--padding">
							{selectedPaymentVariantId}
						</span>

						{!!selectedPaymentVariantId && (
							<>
								<div className="enter-value__group">
									{selectedPaymentVariantId.toLowerCase() === 'phone' ? (
										<div className="enter-value__field">
											<PhoneInput
												country="us"
												value={selectedPaymentValue}
												onChange={handleSelectedPaymentValuePhoneChange}
												placeholder="Enter phone number"
												inputClass="input-item custom-phone-number-input"
											/>
										</div>
									) : (
										<>
											<div
												className={`enter-value__field ${
													selectedPaymentError ? 'enter-value__field--error' : ''
												}`}
											>
												<input
													type="text"
													className={`input-item ${
														selectedPaymentError ? 'input-item--error' : ''
													}`}
													name={selectedPaymentVariantId}
													placeholder={selectedPaymentVariantId}
													onChange={handleSelectedPaymentValueChange}
													value={selectedPaymentValue}
												/>
												{!!selectedPaymentError && <TextError>{selectedPaymentError}</TextError>}
											</div>
										</>
									)}
								</div>
								<div className="enter-value__group">
									<span className="enter-value__label enter-value__label--padding">
										{String(L.translate('WithdrawFiat.amount'))}
									</span>
									<div
										className={`enter-value__field ${
											isInputError ? 'enter-value__field--error' : ''
										}`}
									>
										<div style={{ position: 'relative' }}>
											<input
												className={`input-item input-item--right-icon-and-action ${
													isInputError ? 'input-item--error' : ''
												}`}
												type="text"
												placeholder={String(L.translate('WithdrawFiat.amount'))}
												value={amount}
												name="amount"
												onChange={handleAmountChange}
											/>
											<div className="input-icon withdraw-amount-input-icon input-icon--auto input-icon--right ">
												<span className="enter-value__coin">
													{selectWallet?.asset?.code?.toUpperCase()}
												</span>
											</div>
											<div className="input-icon withdraw-amount-input-icon--more-right input-icon--auto input-icon--more-right ">
												<button
													type="button"
													className="input__link"
													onClick={handleClickWithdrawalMax}
												>
													{String(L.translate('WithdrawFiat.max_button'))}
												</button>
											</div>
										</div>
									</div>
									{errorBlock}
									<div className="enter-value enter-value--fiat-withdrawal-balance">
										<span className="enter-value__label enter-value__label--padding">
											{L.translate('WithdrawFiat.balance')}:
										</span>

										<span className="blance-value">
											{selectWallet?.balance || '0'} {selectWallet?.asset?.code?.toUpperCase()}
										</span>
									</div>
									<div className="enter-value enter-value--fiat-deposit">
										<span className="enter-value__label enter-value__label--padding">
											{L.translate('WithdrawFiat.you_receive')}:
										</span>
										<div className="enter-value__group">
											<span className="enter-value__sum">
												{recive} {selectWallet?.asset?.code?.toUpperCase()}
											</span>
										</div>
										<div className="enter-value__group">
											<span className="enter-value__label enter-value__label--padding">
												<span className="enter-value__text-container">
													<span>{L.translate('WithdrawFiat.transaction_fee')}:</span>
													<span>
														{!amountField ? '0' : fee} {selectWallet?.asset?.code?.toUpperCase()}
													</span>
												</span>
											</span>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			)}
			{selectedPayment && !['paypal', 'blockchain_network'].includes(selectedPayment) && (
				<>
					<div className="form-block form-block--padding">
						<div className="enter-value">
							<span className="block-label block-label--grey">
								{L.translate('WithdrawFiat.enter_amount')}
							</span>
							<span className="enter-value__label enter-value__label--padding">
								{L.translate('WithdrawFiat.amount')}
							</span>
							<div
								className={`enter-value__field ${isInputError ? 'enter-value__field--error' : ''}`}
							>
								<input
									onChange={handleAmountChange}
									value={amountField}
									className={`input-item input-item--right-icon-and-action ${
										isInputError && 'input-item--error'
									}`}
									type="text"
									placeholder={
										selectWallet === null
											? ''
											: !noLimit
											? userData?.status_id === 2
												? String(L.translate('WithdrawFiat.enter_min', { min: withdrawMin }))
												: String(
														L.translate('WithdrawFiat.enter_min_max', {
															min: withdrawMin,
															max: withdrawMax,
														}),
												  )
											: ''
									}
								/>
								<div className="input-icon withdraw-amount-input-icon--more-right input-icon--auto input-icon--more-right ">
									<button type="button" className="input__link" onClick={handleClickWithdrawalMax}>
										{String(L.translate('WithdrawFiat.max_button'))}
									</button>
								</div>
								<div className="input-icon input-icon--auto input-icon--right">
									<span className="enter-value__coin">
										{selectWallet?.asset?.code?.toUpperCase()}
									</span>
								</div>
							</div>
							{errorBlock}
						</div>
					</div>
					<div className="form-block">
						<div className="enter-value enter-value--fiat-withdrawal-balance">
							<span className="enter-value__label enter-value__label--padding">
								{L.translate('WithdrawFiat.balance')}:
							</span>

							<span className="blance-value">
								{selectWallet?.balance || '0'} {selectWallet?.asset?.code?.toUpperCase()}
							</span>
						</div>

						<div className="enter-value enter-value--fiat-deposit">
							<span className="enter-value__label enter-value__label--padding">
								{L.translate('WithdrawFiat.you_receive')}:
							</span>
							<div className="enter-value__group">
								<span className="enter-value__sum">
									{recive} {fiatCode?.toUpperCase()}
								</span>
								{checkCard?.id &&
								(selectedPayment === 'cartubank' || selectedPayment === 'bank_transfer') ? (
									<span className="enter-value__label enter-value__label--padding enter-value__label--flex">
										{L.translate('WithdrawFiat.payment_method')}:
										<img className="enter-value__icon" src={visaIcon} loading="lazy" />
										<span>
											{selectedPayment === 'cartubank'
												? cardHideNumber(String(checkCard?.card_number), 12, 16)
												: checkCard.bank_name}
										</span>
										<button
											type="button"
											onClick={() => setActiveView('addCard')}
											className="enter-value__link"
										>
											{selectedPayment === 'cartubank'
												? L.translate('WithdrawFiat.change_card')
												: L.translate('WithdrawFiat.change_bank')}
											<span className="enter-value__link-arrow">
												<ArrowRight />
											</span>
										</button>
									</span>
								) : (
									''
								)}
							</div>
							<div className="enter-value__group">
								<span className="enter-value__label enter-value__label--padding">
									<span className="enter-value__text-container">
										<span className="enter-value__text-flex">
											{L.translate('WithdrawFiat.transaction_fee')}:
											<TransactionFeeTooltip />
										</span>
										<span>
											{!amountField ? '0' : fee} {selectWallet?.asset?.code?.toUpperCase()}
										</span>
									</span>
								</span>
							</div>
						</div>
					</div>
					<Notify />
					<div className="form-submit">
						<button
							onClick={handleContinue}
							style={{ width: '100%' }}
							className="button button--full-width"
							disabled={withdrawIsDisable}
						>
							{L.translate('WithdrawFiat.continue')}
						</button>
					</div>
				</>
			)}
			{selectedPayment === 'blockchain_network' && (
				<>
					{selectWallet ? (
						<div className="form-block">
							<div className="enter-value">
								<span className="block-label block-label--grey">
									{String(L.translate('WithdrawCrypto.withdraw_to'))}
								</span>

								<div className="enter-value__group">
									<span className="enter-value__label enter-value__label--padding">
										{String(L.translate('WithdrawCrypto.network'))}
									</span>
									<div className="enter-value__field">
										<DCNDropdown
											value={selectWalletNetworks}
											onChange={handleNetworkSelect}
											options={walletNetworks}
										/>
									</div>
								</div>
							</div>
						</div>
					) : null}
					{selectWalletNetworks ? (
						<div className="crypto-stats">
							<div className="crypto-stats__side">
								<span className="crypto-stats__title">
									{selectWallet?.asset?.code?.toUpperCase()}{' '}
									{String(L.translate('WithdrawCrypto.spot_balance'))}
								</span>
								<span className="crypto-stats__desc">
									{String(fixedCropNumber(Number(selectWallet?.balance), 8))}{' '}
									{selectWallet?.asset?.code?.toUpperCase()}
								</span>
							</div>
							<div className="crypto-stats__side">
								<span className="crypto-stats__title">
									{String(L.translate('WithdrawCrypto.minimum_withdrawal'))}
								</span>
								<span className="crypto-stats__desc">
									{noLimit
										? String(L.translate('WithdrawFiat.no_limit'))
										: `${convertExponentialToDecimal(withdrawMin || 0)} ${String(
												selectWallet?.asset?.code?.toUpperCase(),
										  )}`}
								</span>
							</div>
							<div className="crypto-stats__side">
								<span className="crypto-stats__title">
									{String(L.translate('WithdrawCrypto.network_fees'))}
									<NetworkFeesTooltip />
								</span>
								<span className="crypto-stats__desc">
									{addressIsInternal ? '0' : withdrawFeeCrypto}
								</span>
							</div>
						</div>
					) : null}
					{selectWalletNetworks && selectWallet ? (
						<div className="form-block">
							<div className="enter-value">
								<div className="enter-value__group">
									<span className="enter-value__label enter-value__label--padding">
										{String(L.translate('WithdrawCrypto.address'))}
									</span>
									<div
										className={`enter-value__field ${
											isInvalidFormatAddressChecked ? 'enter-value__field--error' : ''
										}`}
									>
										<DebounceInput
											debounceTimeout={200}
											type="text"
											placeholder="Enter address here"
											className={`input-item ${
												isInvalidFormatAddressChecked ? 'input-item--error' : ''
											}`}
											name="address"
											value={walletAddressTo}
											onChange={handleWalletAddressToChange}
										/>
									</div>
								</div>
								{isInvalidFormatAddressChecked ? (
									<TextError>{String(L.translate('WithdrawCrypto.invalid_format'))}</TextError>
								) : null}
							</div>
						</div>
					) : null}
					{visibleWithdrawalInfo ? (
						<div className="form-block form-block--padding">
							<div className="enter-value">
								<span className="block-label block-label--grey">
									{String(L.translate('WithdrawCrypto.withdrawal_info'))}
								</span>
								{calculateWithdrawalCryptoAmount &&
									calculateWithdrawalCryptoAmount.residual_amount &&
									calculateWithdrawalCryptoAmount.residual_amount_usd && (
										<p className="withdraw-daily-limit">{`Your daily withdrawal limit is ${String(
											fixedCropNumber(Number(calculateWithdrawalCryptoAmount.residual_amount), 8),
										)} ${String(selectWallet?.asset.code.toUpperCase())} - ${String(
											calculateWithdrawalCryptoAmount.residual_amount_usd,
										)}$`}</p>
									)}
								<div className="enter-value__group">
									<span className="enter-value__label enter-value__label--padding">
										{String(L.translate('WithdrawCrypto.amount'))}
									</span>
									<div
										className={`enter-value__field ${
											isAmountLessWithdrawMin || isAmountMoreBalance
												? 'enter-value__field--error'
												: ''
										}`}
									>
										<input
											className={`input-item input-item--right-icon-and-action ${
												isAmountLessWithdrawMin || isAmountMoreBalance ? 'input-item--error' : ''
											}`}
											type="text"
											placeholder={String(L.translate('WithdrawCrypto.amount'))}
											value={amount}
											name="amount"
											onChange={handleAmountChange}
										/>
										<div className="input-icon withdraw-amount-input-icon input-icon--auto input-icon--right ">
											<span className="enter-value__coin">
												{selectWallet?.asset?.code?.toUpperCase()}
											</span>
										</div>
										<div className="input-icon withdraw-amount-input-icon--more-right input-icon--auto input-icon--more-right ">
											<button
												type="button"
												className="input__link"
												onClick={handleClickWithdrawalMax}
											>
												{String(L.translate('WithdrawCrypto.max_button'))}
											</button>
										</div>
									</div>
									{isAmountLessWithdrawMin ? (
										<TextError>{String(L.translate('WithdrawCrypto.amount_min_error'))}</TextError>
									) : null}
									{isAmountMoreBalance ? (
										<TextError>
											{String(L.translate('WithdrawCrypto.amount_more_than_balance_error'))}
										</TextError>
									) : null}
								</div>
							</div>
						</div>
					) : null}
					{receiveAmountIsVisible ? (
						<>
							<div className="form-block">
								<div className="enter-value">
									<span className="block-label block-label--grey">
										{String(L.translate('WithdrawCrypto.receive_amount'))}
									</span>
									<div className="enter-value__group">
										<span className="enter-value__sum">
											{recieveCryptoAmount} {selectWallet?.asset?.code?.toUpperCase()}
										</span>
										<span className="enter-value__label enter-value__label--padding">
											{addressIsInternal ? 0 : withdrawFeeCrypto}{' '}
											{selectWallet?.asset?.code?.toUpperCase()}{' '}
											{String(L.translate('WithdrawCrypto.fee_included'))}
										</span>
									</div>
								</div>
							</div>
							<Notify />
							<div className="form-submit">
								<button
									className="button button--full-width"
									type="button"
									disabled={withdrawIsDisable}
									onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
								>
									{String(L.translate('WithdrawCrypto.withdraw_button'))}
								</button>
								<BlockchainConfirm
									openModal={openModal}
									closeModal={closeModal}
									recieveAmount={recieveCryptoAmount || 0}
									amount={Number(amount)}
									address={walletAddressTo}
									currentWallet={selectWallet}
									selectedPayment={selectedPayment}
									withdrawPs={selectedPaymentVariantId}
									to={selectedPaymentValue}
									currentWalletNetwork={selectWalletNetworks}
								/>
							</div>
						</>
					) : null}
				</>
			)}
			{!!amount && selectedPayment === 'paypal' && (
				<>
					<Notify />
					<div className="form-submit form-submit--more-mt">
						<button
							className="button button--full-width"
							type="button"
							disabled={withdrawIsDisable ||
								Boolean(amount && Number(amount) > Number(selectWallet?.balance)) ||
								!selectedPaymentVariantId ||
								!selectedPaymentValue}
							onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
						>
							{L.translate('WithdrawFiat.continue')}
						</button>
						{selectWallet && (
							<WithdrawFiatConfirmPopup
								openModal={openModal}
								closeModal={closeModal}
								recieveAmount={recive || 0}
								amount={Number(amount)}
								currentWallet={selectWallet}
								selectedPayment={selectedPayment}
								withdrawPs={selectedPaymentVariantId}
								to={selectedPaymentValue}
							/>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default SelecetCurrencyPayment;

