import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAppStore, TCounties, ColorTheme, ITranslates } from './types';

// ==========================================:
const getAppState = (state: IStoreState): IAppStore => state.app;
export const getApp = createSelector([getAppState], (app: IAppStore) => app);

// ====================================================:
export const appLanguage = createSelector([getApp], (app): string => app.lang);

export const getCountries = createSelector([getApp], (app): TCounties | null => app.countries);

export const getColorTheme = createSelector([getApp], (app): ColorTheme => app.theme);

export const getTranslates = createSelector([getApp], (app): ITranslates | null => app.translates);
