import { FC, useEffect, useState, ChangeEvent } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
	getAllNotificationRequest,
	changeNotificationStatusRequest,
} from 'redux/reducers/p2p/reducer';
import { getP2PNotification, getP2PSateLoader } from 'redux/reducers/p2p/selectors';
import { IP2PNotificationsItem } from 'redux/reducers/p2p/types';
import Pagination from 'ui/Pagination';
import Loader from 'ui/Loader';
import Checkbox from 'ui/Checkbox';
import P2PItem from './P2PItem';

const NotificationMessage: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const p2pIsLoad = useSelector(getP2PSateLoader);
	const notification = useSelector(getP2PNotification);
	const { pathname } = history.location;
	const { search } = history.location;
	const totalPages = notification?.last_page || 1;

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState<Array<number>>([]);
	const IsDisabled = isCheck.length === 0;

	const searchPage = new URLSearchParams(location.search).get('current_page') || 1;
	useEffect(() => {
		if (search === '') {
			history.push({ pathname, search: `?current_page=1&per_page=15` });
		} else {
			setCurrentPage(Number(searchPage));
			dispatch(
				getAllNotificationRequest({
					params: {
						search,
					},
				}),
			);
		}
	}, [dispatch, history, pathname, search, searchPage]);

	const handlePage = (value: number) => {
		history.push({
			pathname,
			search: `?current_page=${value}&per_page=15`,
		});
	};

	const handleSelectAll = () => {
		setIsCheckAll(!isCheckAll);
		const newList: Array<number> =
			notification?.data?.map((li: IP2PNotificationsItem) => li.id) || [];
		setIsCheck(newList);

		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleCheck = (e: ChangeEvent<HTMLInputElement>): void => {
		const { id, checked } = e.target;
		setIsCheck([...isCheck, Number(id)]);
		if (!checked) {
			setIsCheck(isCheck.filter((item: number) => item !== Number(id)));
		}
	};

	useEffect(() => {
		if (p2pIsLoad) {
			setIsCheckAll(false);
			setIsCheck([]);
		}
	}, [p2pIsLoad]);

	const makeRead = () => {
		dispatch(changeNotificationStatusRequest({ notifications: isCheck, search }));
	};

	return (
		<div className="container">
			<div className="notification-page">
				<div className="page-info page-info--flex">
					<h3 className="page-info__title">
						{String(L.translate('Notification.notification_title'))}
					</h3>
					<div className="notification-nav">
						<button
							onClick={makeRead}
							type="button"
							className={`notification-nav__btn ${
								IsDisabled ? '' : 'notification-nav__btn--active'
							}`}
							disabled={IsDisabled}
						>
							<span className="notification-nav__icon icon-Checkbox" />

							<div className="notification-dropdown">
								<span>{String(L.translate('Notification.mark_as_read'))}</span>
							</div>
						</button>
						{/* <button type="button" className="notification-nav__btn">
							<span className="notification-nav__icon notification-nav__icon--big icon-Iconly-Light-Delete-converted" />
						</button> */}
					</div>
				</div>
				<div className="checkbox checkbox--margin-none checkbox--padinng">
					<label className="checkbox__label">
						<Checkbox
							type="checkbox"
							name="selectAll"
							id="selectAll"
							handleClick={handleSelectAll}
							isChecked={isCheckAll}
						/>
						<span className=" checkbox__item">
							<span className="checkbox__item-icon">
								<span className="icon-Checkbox" />
							</span>
						</span>{' '}
						<span className="checkbox--text">{String(L.translate('Notification.check_all'))}</span>
					</label>
				</div>
				<div className="notification-page__content">
					<div className="notification-list">
						{p2pIsLoad ? (
							<div className="spot-and-fiat-wallets-table-loader-wrapper">
								<Loader />
							</div>
						) : null}

						{!p2pIsLoad && notification?.data?.length
							? notification?.data?.map((item: IP2PNotificationsItem) => (
									<P2PItem key={item.id} data={item} handleCheck={handleCheck} isCheck={isCheck} />
							  ))
							: null}

						{!p2pIsLoad && notification?.data?.length === 0 && (
							<span className="table-empty">
								{String(L.translate('Notification.no_notifications'))}
							</span>
						)}
					</div>
				</div>
				{totalPages > 1 && (
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage - 1}
						onPageChange={handlePage}
					/>
				)}
			</div>
		</div>
	);
};
export default NotificationMessage;
