/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	IAddCardPayload,
	ICardItem,
	ICardsStore,
	IAddCardRequest,
	IFeeDepoitFiatPayload,
	IFeeWithdrowalFiatPayload,
	IFeeDepositResponse,
	ICardStatusPayload,
	ICardStatusResponse,
	IFeeWithdrawalFiatRequest,
	IFeeWithdrawalCardFiatRequest,
	IVerifyCardPayload,
	IVerifyCardResponse,
} from './types';

// ==========================================:
export const initialState: ICardsStore = {
	cards: null,
	responseData: null,
	depositFee: null,
	withdrawalFee: null,
	isVerified: null,
	verifyLoading: false,
};

// ==========================================:
const cards = createSlice({
	name: '@@cards',
	initialState,
	reducers: {
		getCardsRequest: (state) => {},
		getCardsSuccess: (state, action) => {
			const { payload } = action;
			const newState = state;
			newState.cards = payload;
		},
		addCardRequest: (state, action: PayloadAction<IAddCardPayload>) => {},
		addCardSuccess: (state, action) => {
			const { payload } = action;
			const newState = state;
			newState.cards?.push(payload);
		},
		updateCardRequest: (state, action) => {},
		updateCardResponse: (state, { payload }: PayloadAction<ICardItem>) => {
			if (state.cards) {
				state.cards = state.cards.map((el) => (el.id === payload.id ? payload : el));
			}
		},
		deleteCardRequest: (state, action) => {},
		verifyCardRequest: (state, action: PayloadAction<IVerifyCardPayload>) => {
			const newState = state;
			newState.verifyLoading = true;
		},
		verifyCardResponse: (state, { payload }: PayloadAction<IVerifyCardResponse>) => {
			const newState = state;
			newState.verifyLoading = false;
		},
		verifyCardFailed: (state) => {
			const newState = state;
			newState.verifyLoading = false;
		},
		setResponseData: (state, action: PayloadAction<ICardItem>) => {
			const { payload } = action;
			const newState = state;
			newState.responseData = payload;
		},
		removeResponseData: (state) => {
			const newState = state;
			newState.responseData = null;
		},
		addDepositCardRequest: (state, action: PayloadAction<IAddCardRequest>) => {},
		getDepositFiatFeeRequest: (state, action: PayloadAction<IFeeDepoitFiatPayload>) => {},
		getDepositFiatFeeSuccess: (state, action: PayloadAction<IFeeDepositResponse>) => {
			state.depositFee = action?.payload.deposit_fee;
		},
		initialDepositFiatFee: (state) => {
			state.depositFee = 0;
		},
		getWithdrawalFiatFeeRequest: (state, action: PayloadAction<IFeeWithdrowalFiatPayload>) => {},
		getWithdrawalFiatFeeSuccess: (state, action: PayloadAction<IFeeDepositResponse>) => {
			state.withdrawalFee = action?.payload.withdrawal_fee;
		},
		initialWithdrawalFiatFee: (state) => {
			state.withdrawalFee = 0;
		},
		getCardStatusRequest: (state, action: PayloadAction<ICardStatusPayload>) => {},
		getCardStatusSuccess: (state, action: PayloadAction<ICardStatusResponse>) => {
			state.isVerified = action?.payload;
		},
		createCardWithdrawalRequest: (state, action: PayloadAction<IFeeWithdrawalFiatRequest>) => {},
		verifyCardWithdrawalRequest: (
			state,
			action: PayloadAction<IFeeWithdrawalCardFiatRequest>,
		) => {},
	},
});

export default cards.reducer;
export const {
	getCardsRequest,
	getCardsSuccess,
	addCardRequest,
	addCardSuccess,
	updateCardRequest,
	deleteCardRequest,
	setResponseData,
	removeResponseData,
	updateCardResponse,
	addDepositCardRequest,
	getDepositFiatFeeRequest,
	getDepositFiatFeeSuccess,
	getWithdrawalFiatFeeSuccess,
	getWithdrawalFiatFeeRequest,
	getCardStatusRequest,
	getCardStatusSuccess,
	createCardWithdrawalRequest,
	verifyCardWithdrawalRequest,
	initialDepositFiatFee,
	initialWithdrawalFiatFee,
	verifyCardRequest,
	verifyCardResponse,
	verifyCardFailed,
} = cards.actions;
