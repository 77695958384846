import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from 'layouts/Dashboard';
import ClosedTrades from 'components/P2PWallet/P2Psubpages/ClosedTrades';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { getAccountP2P } from 'redux/reducers/auth/selectors';

// ==========================================:
const P2PClosedTrades: FC = () => {
	const accountP2P = useSelector(getAccountP2P);
	const dispatch = useDispatch();

	useEffect(() => {
		if (accountP2P) {
			dispatch(getAssetsRequest());
		}
	}, [dispatch, accountP2P]);

	return (
		<Dashboard title={String(L.translate('PageTitles.p2p_closed_trades'))}>
			<ClosedTrades />
		</Dashboard>
	);
};

export default P2PClosedTrades;
