/* eslint-disable import/no-relative-packages */

import { useCallback, useEffect, useRef, useState } from 'react';
import { languages } from 'languages';
import '../../../../node_modules/flag-icons/css/flag-icons.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { appLanguage } from 'redux/reducers/app/selectors';
import { changeAppLanguage } from 'redux/reducers/app/reducer';
import LanguageItem from './LanguageItem';

const LangSwitcher = () => {
	const language = useSelector(appLanguage);
	const [showList, setShowList] = useState<boolean>(false);
	const dropdownRef = useRef(null);
	const selectRef = useRef(null);
	const dispatch = useDispatch();

	const handleShowDropdown = () => {
		setShowList((prev) => !prev);
	};

	const handleLanguageSelect = (lang: string) => {
		dispatch(changeAppLanguage(lang));
		setShowList(false);
	};

	const renderItems = () => {
		return Object.keys(languages)?.map((item) => (
			<LanguageItem key={item} name={item} selectHandler={() => handleLanguageSelect(item)} />
		));
	};

	const onWindowClick = useCallback(
		(e: Event) => {
			const path = e.composedPath();
			if (dropdownRef?.current && selectRef?.current) {
				if (
					!path.includes(dropdownRef?.current) &&
					!path.includes(selectRef?.current) &&
					showList
				) {
					setShowList(false);
				}
			}
		},
		[showList],
	);

	useEffect(() => {
		window.addEventListener('click', onWindowClick, true);
		return () => window.removeEventListener('click', onWindowClick);
	}, [onWindowClick]);

	return (
		// @TODO: Поставил тут пустой div потому что иначе в гридах появл. пустой элемент
		//  из-за чего верстка не соотв. дизайну
		<div>
			<div className="lang" ref={selectRef} onClick={handleShowDropdown}>
				<div className="lang__header">
					<div className="lang__img">
						<span className={`fi fi-${language === 'EN' ? 'us' : language.toLowerCase()}`} />
					</div>
					<span className="lang__current">{language}</span>
				</div>
			</div>
			<div ref={dropdownRef} className={`select header__select ${showList ? 'active' : ''}`}>
				<div className="select__drop select__drop--fit">{renderItems()}</div>
			</div>
		</div>
	);
};

export default LangSwitcher;
