import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Header from 'layouts-elements/Header';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import creadFigureDefault from 'assets/images/general/cred-figure-default.webp';
import creadFigureBig from 'assets/images/general/cred-figure-big.webp';
import creadFigureMedium from 'assets/images/general/cred-figure-medium.webp';
import { IAuth } from './types';

// ==========================================:
const Auth: FC<IAuth> = ({ children, title, description }) => {
	return (
		<>
			<Helmet>
				<title>{title}</title>
				{description && <meta name="description" content={description} />}
			</Helmet>
			<div className="wrapper">
				<Header />
				<Content>
					<div className="authorization-box">
						<div className="authorization-box__figure">
							<picture>
								<source
									media="(min-width: 861px) and (max-width: 1199px)"
									srcSet={`${creadFigureMedium}`}
								/>
								<source media="(min-width: 1200px)" srcSet={`${creadFigureBig}`} />
								<img src={creadFigureDefault} alt="figure" />
							</picture>
						</div>
						<div className="authorization-box__form">{children}</div>
					</div>
				</Content>
				<Footer />
			</div>
		</>
	);
};

export default Auth;
