/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo, useState, useRef, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
	getTopPairsWithChart,
	getTopPairsWithChartLoader,
	getMoreTopPairsWithChartLoader,
} from 'redux/reducers/assetPairs/selectors';
import { getMoreTopPairsWithChartRequest } from 'redux/reducers/assetPairs/reducer';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { getFirstPartPairCode } from 'services/utils/tradingPairHelpers';
import Loader from 'ui/Loader';
// import chart01 from 'assets/img/icons/сryptocurrencies/chart_01.svg';
/* eslint-disable */

const DEFAULT_ITEMS_NUMBER = 10;

const TopCryptocurrencies: FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const topPairs = useSelector(getTopPairsWithChart);
	const topPairsLoader = useSelector(getTopPairsWithChartLoader);
	const topMorePairsLoader = useSelector(getMoreTopPairsWithChartLoader);

	const morePair =
		topPairs && topPairs?.length && Number(topPairs?.length) === 11
			? topPairs.slice(0, -1)
			: topPairs;

	// const [maxHeight, setMaxHeight] = useState('0px');
	const [minHeight, setMinHeight] = useState('0px');
	const [maxHeight, setMaxHeight] = useState('0px');
	const [isExpanded, setIsExpanded] = useState(false);
	const tableRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setTimeout(() => {
			if (topPairsLoader || !morePair?.length || topMorePairsLoader) {
				return;
			}
			if (tableRef.current) {
				const headerRowHeader = tableRef?.current
					?.querySelector('.table-header .tr')
					?.getBoundingClientRect().height;
				const firstRowHeight = tableRef?.current
					?.querySelector('.table-body .tr')
					?.getBoundingClientRect().height;
				if (firstRowHeight === undefined || headerRowHeader === undefined) {
					return;
				}
				setMinHeight(
					`${
						firstRowHeight *
						(morePair.length > DEFAULT_ITEMS_NUMBER ? DEFAULT_ITEMS_NUMBER : morePair.length)
					}px`,
				);
				setMaxHeight(`${firstRowHeight * morePair.length}px`);
			}
		}, 300);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topPairsLoader, morePair?.length, topMorePairsLoader]);

	useEffect(() => {
		if (!topMorePairsLoader && topPairs && topPairs?.length > 11) {
			setIsExpanded((prev) => !prev);
		}
	}, [topMorePairsLoader, topPairs]);

	const toggleExpanded = () => {
		if (topPairs && topPairs?.length < 12) {
			dispatch(getMoreTopPairsWithChartRequest());
		} else {
			if (tableRef.current && isExpanded) {
				const targetElement = tableRef?.current.querySelector('.table-header .tr');
				if (targetElement) {
					const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
					window.scrollTo({ top: targetPosition, behavior: 'smooth' });
				}
			}
			setIsExpanded((prev) => !prev);
		}
	};

	const getPairChange24Class = (change24: number) => {
		// const classes = ['currency-value'];
		// if (change24 < 0) {
		// 	classes.push('currency-value--red');
		// }
		// return classes.join(' ');
		return `currency-change ${change24 < 0 ? 'red' : ''} ${change24 > 0 ? 'green' : ''}`;
	};

	const formatPairChange24 = (change24: number, dollar = true) => {
		const sign = change24 >= 0 ? '+' : '';
		// return `${sign}${change24}`;
		return (
			<>
				<span className="currency-change__icon">
					{change24 < 0 && (
						<svg
							width="11"
							height="7"
							viewBox="0 0 11 7"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.92624 0.5H1.27393C0.674806 0.5 0.374777 1.26637 0.798406 1.71457L5.1246 6.29165C5.38717 6.56945 5.81303 6.56945 6.0756 6.29165L10.4017 1.71457C10.8255 1.26637 10.5254 0.5 9.92624 0.5Z"
								fill="#EA3943"
							/>
						</svg>
					)}
					{change24 > 0 && (
						<svg
							width="11"
							height="7"
							viewBox="0 0 11 7"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1.07376 6.5L9.72607 6.5C10.3252 6.5 10.6252 5.73363 10.2016 5.28543L5.8754 0.70835C5.61283 0.43055 5.18697 0.43055 4.9244 0.70835L0.598258 5.28543C0.174544 5.73363 0.474614 6.5 1.07376 6.5Z"
								fill="#16C784"
							/>
						</svg>
					)}
				</span>
				{dollar && '$'}
				{String(change24).startsWith('-') ? String(change24).slice(1) : change24}
			</>
		);
	};

	const handleTrade = (pairCode: string) => {
		if (!pairCode) return;

		dispatch(setCurrentPair(pairCode));
		history.push(`/spot/${pairCode.toUpperCase()}`);
	};

	return (
		<div className="section section--currencies">
			<div className="container">
				{!topPairsLoader ? (
					<>
						<h1 className="section-title section-title--big section-title--centered">
							{String(L.translate('HomePage.top_currencies'))}
						</h1>
						<div className="section-content">
							<div className="table table--crypto" ref={tableRef}>
								<div className="table-header">
									<div className="tr">
										<div className="td td--hide">
											<span className="table-header__name" />
										</div>
										<div className="td">
											<span className="table-header__name">
												{String(L.translate('HomePage.currency'))}
											</span>
										</div>
										<div className="td">
											<span className="table-header__name">
												{String(L.translate('HomePage.price'))}
											</span>
										</div>
										<div className="td">
											<span className="table-header__name">
												{String(L.translate('HomePage.24change'))}
											</span>
										</div>
										<div className="td td--hide">
											<span className="table-header__name">
												{String(L.translate('HomePage.change'))} %
											</span>
										</div>
										<div className="td td--hide">
											<span className="table-header__name">
												{String(L.translate('HomePage.market_cap'))}
											</span>
										</div>
										<div className="td td--hide">
											<span className="table-header__name">
												{String(L.translate('HomePage.chart'))}
											</span>
										</div>
										<div className="td td--hide">
											<span className="table-header__name">
												{String(L.translate('HomePage.trade'))}
											</span>
										</div>
									</div>
								</div>
								<div
									className="table-body"
									style={{ maxHeight: isExpanded ? maxHeight : minHeight }}
								>
									<div>
										{morePair?.map((pair, idx) => (
											// <RowItem />
											<div key={pair.id} className="tr">
												<div className="td td--hide">
													<p>{idx + 1}</p>
												</div>
												<div className="td">
													<div className="table-currency">
														<div className="table-coin">
															<img src={pair.img_path} alt={pair.base_asset_name} />
														</div>
														<p className="table-currency__name">
															{pair.base_asset_name}
															<span>{getFirstPartPairCode(pair.code)}</span>
														</p>
													</div>
												</div>

												<div className="td">
													<p>
														$
														{new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(
															Number(pair.last_price.toFixed(2)),
														)}
													</p>
												</div>

												<div className="td">
													<p className={getPairChange24Class(pair.change24h_value)}>
														{formatPairChange24(pair.change24h_value)}
													</p>
												</div>

												<div className="td td--hide">
													<p className={getPairChange24Class(pair.change24h)}>
														{formatPairChange24(pair.change24h, false)}&nbsp;%
													</p>
												</div>

												<div className="td td--hide">
													<p className={getPairChange24Class(pair.change24h_value)}>
														{pair.market_cap_formatted}
													</p>
												</div>

												<div className="td td--hide">
													<div className="currency-chart">
														<picture>
															<source
																media="(min-width: 861px)"
																srcSet={`data:image/png;base64,${String(pair?.chart?.base64)}`}
															/>
															<img src="" alt="currency-chart" />
														</picture>
													</div>
												</div>

												<div className="td td--hide">
													<button
														type="button"
														className="button button--new button--new-type1 button--full-width"
														onClick={() => handleTrade(pair.code)}
													>
														{String(L.translate('HomePage.trade'))}
													</button>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
							{topPairs?.length && topPairs.length > DEFAULT_ITEMS_NUMBER && (
								<div className="home-currency-more">
									<button
										type="button"
										className="button button--new button--new-type2"
										disabled={topMorePairsLoader}
										onClick={toggleExpanded}
									>
										{topMorePairsLoader ? (
											'loading...'
										) : (
											<>{L.translate(`HomePage.${isExpanded ? 'show_less' : 'show_more'}`)}</>
										)}
									</button>
								</div>
							)}
						</div>
					</>
				) : (
					<div className="section-title--centered">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

export default TopCryptocurrencies;
