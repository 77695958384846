import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';

// Layouts elements:
import Header from 'layouts-elements/Header';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import VerificationBanner from 'ui/VerificationBanner';

// Home blocks:
import NotificationMessage from 'components/NotificationMessage';

// ==========================================:
const Notification: FC = () => {
	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)}.com`}</title>
			</Helmet>

			<div className="wrapper">
				<Header />
				<Content>
					<VerificationBanner />
					<div className="content-wrapper content-wrapper--padding-small">
						<NotificationMessage />
					</div>
				</Content>
				<Footer />
			</div>
		</>
	);
};

export default Notification;
