import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationContainer } from 'services/utils/notificationContainer';
import L from 'i18n-react';
import { getUserSettingsData, ggetUserSettingsDataLoader } from 'redux/reducers/settings/selectors';
import { trimFileName } from 'services/utils/trimComment';
import { getStatistics, getBalances } from 'redux/reducers/referral/selectors';
import { getReferralBalanceRequest } from 'redux/reducers/referral/reducer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loader from 'ui/Loader';
import ClaimPopUp from './ClaimPopUp';
import { IAction } from './Table/types';
import { CheckKYC } from './CheckKYC';
import FAQ from './FAQ';
import { Table } from './Table/Table';

/* eslint-disable */
// ==========================================:
const InviteEarnSystem: FC = () => {
	const userData = useSelector(getUserSettingsData);
	const userSettingsDataLoader = useSelector(ggetUserSettingsDataLoader);
	const statistic = useSelector(getStatistics);
	const balance = useSelector(getBalances);
	const isUnverified = userData?.status?.name === 'unverified';

	let closeCopyMessage: ReturnType<typeof setTimeout>;

	const [copyStatus, setCopyStatus] = useState({ copied: false });
	const [action, setAction] = useState<IAction | null>(null);

	const popUpClose = () => {
		setAction(null);
	};

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getReferralBalanceRequest());
	}, [dispatch]);

	const handleCopy = () => {
		setCopyStatus({ copied: true });
		notificationContainer(String(L.translate('InviteEarn.link_copied')), 'success');

		closeCopyMessage = setTimeout(() => {
			setCopyStatus({ copied: false });
		}, 2000);
	};
	useEffect(() => clearTimeout(closeCopyMessage));

	if (!userData || userSettingsDataLoader) {
		return (
			<div className="auth-loader-wrapper">
				<Loader />
			</div>
		);
	}

	return (
		<>
			<div className="main-content__header">
				<h2 className="section-title">Partners & Benefits</h2>
			</div>
			{isUnverified ? (
				<CheckKYC />
			) : (
				<>
					<div className="content-block ">
						<div className="balance-list balance-list--invites-earn">
							<div className="balance-item balance-item--earn">
								<span className="balance-item__title">You Receive</span>
								<span className="balance-item__value">
									<span className="balance-item__value-num"> {statistic?.referral_fee}%</span>{' '}
								</span>
							</div>
							<div className="balance-item balance-item--earn">
								<span className="balance-item__title">Available Balance</span>
								<span className="balance-item__value">
									<span className="balance-item__value-num">{balance?.current_in_btc}</span> BTC{' '}
									<br />$ {balance?.current_in_usd}
								</span>
							</div>
							<div className="balance-item balance-item--earn balance-item--invite-link">
								<span className="balance-item__title">Invitation Link</span>
								<div className="balance-item__value balance-item__value--link">
									<span className="balance-item__value-num">
										{trimFileName(String(userData?.invite_url)) || ''}
									</span>
									<CopyToClipboard text={String(userData?.invite_url)} onCopy={handleCopy}>
										<button type="button" className="copy-btn copy-btn--small copy-btn--icon-only">
											<span className="copy-btn__icon icon-fi_copy" />
										</button>
									</CopyToClipboard>
								</div>
							</div>
						</div>
					</div>
					<div className="content-block content-block--padding-none content-block--border-none content content-block--no-bg">
						<p className="block-subtitle">Details</p>
						<div className="earn-stats">
							<div className="stat-block">
								<p className="stat-block__title">Commisions (USDb)</p>
								<p className="stat-block__value">
									{balance?.total_in_usdb || 0}
									<span>Commissions (USDb)</span>
								</p>
							</div>
							<div className="stat-block">
								<p className="stat-block__title">Referrals</p>
								<p className="stat-block__value">{statistic?.total_referrals || 0}</p>
							</div>
							<div className="stat-block">
								<p className="stat-block__title">Verified Referrals</p>
								<p className="stat-block__value">{statistic?.verified_referrals || 0}</p>
							</div>
							<div className="stat-block">
								<p className="stat-block__title">Traded Referrals</p>
								<p className="stat-block__value">{statistic?.traded_referrals || 0}</p>
							</div>
						</div>
					</div>
					<div className="content-block content-block--padding-none content-block--border-none content content-block--no-bg">
						<p className="block-subtitle">Balance</p>
						<div className="balance-section">
							<div className="balance-block balance-block--bg">
								<Table data={balance && balance.balances} setAction={setAction} />
							</div>
						</div>
					</div>
					<div className="inner-faq">
						<p className="block-subtitle">FAQ</p>
					</div>
					<FAQ />
				</>
			)}

			<ClaimPopUp popUpOpen={action?.action} popUpClose={popUpClose} data={action?.asset_id} />
		</>
	);
};

export default InviteEarnSystem;
