import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch } from 'react-redux';
import {
	getP2PAssetsRequest,
	getP2PCurrenciesRequest,
	getPaymentRequest,
} from 'redux/reducers/p2p/reducer';
import Advetisement from 'layouts/Advertisement';
import CreateAdvertisement from 'components/P2P/CreateAdvertisement/index';

// ==========================================:
const P2PAdvetisement: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getP2PAssetsRequest());
		dispatch(getP2PCurrenciesRequest());
		dispatch(getPaymentRequest());
	}, [dispatch]);

	return (
		<Advetisement title={String(L.translate('PageTitles.advertisement'))}>
			<CreateAdvertisement />
		</Advetisement>
	);
};

export default P2PAdvetisement;
