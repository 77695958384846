import { FC, useState, useEffect, useCallback, useRef, KeyboardEvent } from 'react';
import { useFormikContext, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import L from 'i18n-react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Input from 'ui/Formik/Input';
import UploadedFileInput from 'ui/UploadedFileInput';
import UploadFileInput from 'ui/UploadFileInput';
import Loader from 'ui/Loader';
import { toBase64 } from 'services/utils/toBase64';
import { widthFile } from 'services/utils/fileWidth';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IForgotTwofaSubmitValue } from '../types';
import { IForgotTwoFaFormInner } from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
// ==========================================:
const ForgotTwoFaFormInner: FC<IForgotTwoFaFormInner> = ({
	file,
	setFile,
	captchaLoading,
	setCaptchaLoading,
}) => {
	const [selfie, setSelfie] = useState<File | string | null>(null);
	const [errorSelfie, setErrorSelfie] = useState(false);
	const submitRef = useRef<HTMLButtonElement>(null);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const { isSubmitting, isValid, dirty, setFieldValue, values } =
		useFormikContext<IForgotTwofaSubmitValue>();

	useEffect(() => {
		if (!selfie) return;
		const getImg: any = async () => {
			try {
				const img: any = await toBase64(selfie);
				if (img) {
					setFile(img);
					setErrorSelfie(false);
				}
			} catch {
				setErrorSelfie(true);
				notificationContainer(String(L.translate(`Errors.smg_went_wrong`)), 'error');
			}
		};
		getImg();
		// eslint-disable-next-line
	}, [selfie]);

	const handleDeleteFile = () => {
		setFile(null);
		setSelfie(null);
	};

	const handleUploadFile = (e: React.FormEvent<HTMLInputElement>) => {
		const tragetFile: File | null | undefined = e.currentTarget.files?.item(0);
		if (!widthFile(tragetFile?.size)) {
			setErrorSelfie(true);
			notificationContainer(String(L.translate(`Errors.img_width_wrong`)), 'error'); // error if file size bigger then 3 MB);
			return;
		}
		if (tragetFile) {
			setErrorSelfie(false);
			setSelfie(tragetFile);
		}
	};

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.error('executeRecaptcha not exist');
			setTimeout(() => {
				// eslint-disable-next-line no-void
				void handleReCaptchaVerify();
			}, 300);
			return;
		}

		const token = await executeRecaptcha('login');
		setCaptchaLoading?.(false);
		setFieldValue('captcha', token);
		setFieldValue('captcha_generated', true);
		submitRef?.current?.click();
		// Do whatever you want with the token
	}, [executeRecaptcha, setFieldValue, setCaptchaLoading]);

	const handleGenerate = async () => {
		if (!values.captcha_generated) {
			setCaptchaLoading?.(true);
			await handleReCaptchaVerify();
		} else {
			submitRef?.current?.click();
		}
	};

	const onKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			await handleGenerate();
		}
	};

	return (
		<>
			<Link to="/login" className="back-btn">
				<span className="back-btn__arrow icon-arrow" />
				<span className="back-btn__text">
					{String(L.translate('Forms.ForgotPassword.forgot_password_link'))}
				</span>
			</Link>
			<p className="authorization__title">
				{String(L.translate('Forms.Forgot2fa.forgot_2fa_title'))}
			</p>
			<div className="authorization__details">
				<p>{String(L.translate('Forms.Forgot2fa.forgot_2fa_text'))}</p>
			</div>
			<div className="authorization__form">
				<div className="authorization__field">
					<Field
						type="email"
						placeholder={String(L.translate('Forms.ForgotPassword.forgot_password_field_email'))}
						name="email"
						required
						component={Input}
						onKeyDown={onKeyDown}
					/>
				</div>
				<div className="authorization__field">
					<Field
						type="textarea"
						rows={3}
						className="form-item form-item--area"
						name="message"
						placeholder={L.translate('Forms.Forgot2fa.forgot_2fa_field_text')}
						required
						component={Input}
						onKeyDown={onKeyDown}
					/>
				</div>
				{selfie ? (
					<UploadedFileInput file={selfie} handleDeleteFile={handleDeleteFile} />
				) : (
					<UploadFileInput errorSelfie={errorSelfie} handleUploadFile={handleUploadFile} />
				)}
			</div>
			<div className="form-submit">
				<button
					type="button"
					disabled={!(isValid && dirty) || isSubmitting || !selfie || captchaLoading}
					className="button button--full-width"
					aria-label="form submit"
					onClick={handleGenerate}
				>
					{String(L.translate('Forms.ForgotPassword.forgot_password_btn_submit'))}
				</button>
				<button type="submit" className="hidden" ref={submitRef}>
					{L.translate('Global.submit')}
				</button>
			</div>
		</>
	);
};

export default ForgotTwoFaFormInner;
