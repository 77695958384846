import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Failed } from './Failed';
import { Success } from './Success';

export const PopusModal = () => {
	const location = useLocation();
	const type = location.pathname.split('-')[1];

	const currentPopup = (val: string) => {
		switch (val) {
			case 'success':
				return <Success />;
			case 'failed':
				return <Failed />;
			default:
				return <Failed />;
		}
	};

	return <>{type && currentPopup(type)}</>;
};
