import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	IReferralStore,
	IReferralResponsePayload,
	IBalanceResponsePayload,
	IReferralRequestPayload,
} from './types';

// ==========================================:
export const initialState: IReferralStore = {
	statistic: null,
	balance: null,
	balanceLoader: false,
};

// ==========================================:
const referral = createSlice({
	name: '@@referral',
	initialState,
	reducers: {
		getReferralStatisticRequest: (state) => {
			const referralState = state;
		},

		getReferralStatisticSuccess: (state, action: PayloadAction<IReferralResponsePayload>) => {
			const { payload } = action;
			const referralState = state;

			referralState.statistic = payload;
		},

		getReferralBalanceRequest: (state) => {
			const referralState = state;
			referralState.balanceLoader = true;
		},
		getReferralBalanceSuccess: (state, action: PayloadAction<IBalanceResponsePayload>) => {
			const { payload } = action;
			const referralState = state;

			referralState.balance = payload;
			referralState.balanceLoader = false;
		},
		getReferralBalanceError: (state) => {
			const referralState = state;
			referralState.balanceLoader = false;
		},
		getTransferRequest: (state, action: PayloadAction<IReferralRequestPayload>) => {
			const referralState = state;
		},
		initState: () => initialState,
	},
});

export default referral.reducer;
export const {
	getReferralStatisticRequest,
	getReferralStatisticSuccess,
	getReferralBalanceSuccess,
	getReferralBalanceRequest,
	getReferralBalanceError,
	getTransferRequest,
	initState,
} = referral.actions;
