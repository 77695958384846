import TransferModal from 'components/TransferModal';
import L from 'i18n-react';
import { P2P_OPTION, SPOT_OPTION } from 'components/TransferModal/utils';
import { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { numberFormat } from 'services/utils/numberFormat';
import { P2PItemProps } from './types';
import useClickOutside from '../../../hooks/useClickOutside';

const P2PItem: FC<P2PItemProps> = ({ data, balancesIsHide }) => {
	const dropdownNode = useRef<HTMLDivElement | null>(null);
	const [isShowTransferModal, setIsShowTransferModal] = useState(false);
	const [actionsOpened, setActionsOpened] = useState(false);
	const [dropOpened, setDropOpened] = useState(false);

	const toggleDropHandler = () => {
		setDropOpened(!dropOpened);
	};
	const toggleActionsDropdown = () => {
		setActionsOpened(!actionsOpened);
	};
	const closeActionsDropdown = () => {
		setActionsOpened(false);
	};
	useClickOutside(dropdownNode, closeActionsDropdown);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">{String(L.translate('P2P.P2PWallet.coin'))}</p>
				<div className="balance-currency">
					<div className="balance-currency__img">
						<img src={data?.img_path} alt="" />
					</div>
					<div className="balance-currency__info">
						<span className="balance-currency__title">{data?.asset_code.toUpperCase()}</span>
						{/* <span className="balance-currency__desc">{data?.asset?.name}</span> */}
					</div>
				</div>
			</div>

			<div
				className={`td td--left-auto td--dropdown td-name--center balance ${
					dropOpened ? 'td--dropdown-active' : ''
				}`}
			>
				<p className="td-hidden-name">{String(L.translate('P2P.P2PWallet.available_asset'))}</p>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : data?.balance}
				</span>
				<span className="td-arrow icon-arrow2" onClick={toggleDropHandler} />
			</div>
			<div className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}>
				<p className="td-hidden-name">{String(L.translate('P2P.P2PWallet.frozen'))}</p>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : data?.frozen_balance}
				</span>
			</div>
			<div className={`td td--full ${dropOpened ? 'td--full-active' : ''}`}>
				<p className="td-hidden-name">{String(L.translate('P2P.P2PWallet.total_assets'))}</p>
				<span className="td-name td-name--regular">
					{balancesIsHide ? '********' : data?.total}{' '}
					<span className="td-name td-name--subtext">
						{' '}
						≈ $ {balancesIsHide ? '********' : numberFormat(Number(data?.usd_value), 'en-EN')}
					</span>
				</span>
			</div>
			<div className="td td-name--center" ref={dropdownNode}>
				<div className={`select select--links ${actionsOpened ? 'active' : ''}`}>
					<button type="button" className="select__current" onClick={toggleActionsDropdown}>
						<span className="select__arrow icon-more" />
					</button>
					<div className="select__drop">
						<Link to={`/p2p-trade/buy/${data?.asset_code}`} className="link link--regular">
							{String(L.translate('P2P.P2PWallet.buy'))}
						</Link>

						<Link to={`/p2p-trade/sell/${data?.asset_code}`} className="link link--regular">
							{String(L.translate('P2P.P2PWallet.sell'))}
						</Link>

						<button
							type="button"
							onClick={() => setIsShowTransferModal(true)}
							className="link link--regular"
						>
							{String(L.translate('P2P.P2PWallet.transfer'))}
						</button>
					</div>
				</div>

				<TransferModal
					isOpen={isShowTransferModal}
					onClose={() => setIsShowTransferModal(false)}
					initFrom={P2P_OPTION}
					initTo={SPOT_OPTION}
					initCoinCode={data.asset_code}
					spotSocket
					crossSocket
					isolatedSocket
					p2pSocket
					marginIndexSocket
				/>
			</div>
		</div>
	);
};

export default P2PItem;
