import { FC } from 'react';
import L from 'i18n-react';
import Popup from 'reactjs-popup';
import driver from 'assets/images/general/driver-refresh.svg';
import { INextProps } from '../types';

export const MessageError: FC<INextProps> = ({ openModal, closeModal }) => {
	if (openModal) {
		return (
			<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
				<div className="popup-window">
					<div className="popup-window__inside">
						<div className="popup popup--width-410 popup--padding-type2">
							<button className="popup-close" type="button">
								<span className="popup-close__icon icon-close" onClick={closeModal} />
							</button>
							<div className="popup-header">
								<div className="popup-icon popup-icon--only-img">
									<img src={driver} alt="" />
								</div>
								<p className="popup-header__title">
									{L.translate('WithdrawFiat.Popups.MessageError.title')}
								</p>
							</div>
							<div className="popup-body popup-body--margin-none">
								<div className="popup-text popup-text--center">
									<p>{L.translate('WithdrawFiat.Popups.MessageError.description')}</p>
								</div>
							</div>
							<div className="popup-submit popup-submit--sb">
								<button
									onClick={closeModal}
									className="button button--second-grey button--full-width "
									type="button"
								>
									{L.translate('Global.cancel')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		);
	}
	return null;
};
