import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IOurPricesStore,
	TFiatFees,
	TCryptoFees,
	TP2PLimitations,
	TFiatLimits,
	TCryptoLimits,
	TConvertFees,
	TSpotFees,
} from './types';

// ==========================================:
const getOurPricesState = (state: IStoreState): IOurPricesStore => state.ourPrices;
export const getOurPrices = createSelector(
	[getOurPricesState],
	(ourPrices: IOurPricesStore) => ourPrices,
);

// ====================================================:
export const getLoading = createSelector(
	[getOurPrices],
	(ourPrices: IOurPricesStore): boolean => ourPrices.loading,
);

// ====================================================:
export const getFiatFees = createSelector(
	[getOurPrices],
	(ourPrices: IOurPricesStore): TFiatFees | null => ourPrices.fiatFees,
);

// ====================================================:
export const getCryptoFees = createSelector(
	[getOurPrices],
	(ourPrices: IOurPricesStore): TCryptoFees | null => ourPrices.cryptoFees,
);

// ====================================================:
export const getP2PLimitations = createSelector(
	[getOurPrices],
	(ourPrices: IOurPricesStore): TP2PLimitations | null => ourPrices.p2pLimitations,
);
// ====================================================:
export const getFiatLimits = createSelector(
	[getOurPricesState],
	(ourPrices: IOurPricesStore): TFiatLimits | null => ourPrices.fiatLimits,
);
// ====================================================:
export const getCryptoLimits = createSelector(
	[getOurPrices],
	(ourPrices: IOurPricesStore): TCryptoLimits | null => ourPrices.cryptoLimits,
);

// ====================================================:
export const getLoadingLimits = createSelector(
	[getOurPricesState],
	(ourPrices: IOurPricesStore): boolean => ourPrices.limitsLoading,
);

// ====================================================:
export const getConvertFeesLoading = createSelector(
	[getOurPricesState],
	(ourPrices: IOurPricesStore): boolean => ourPrices.convertFeesLoading,
);

// ====================================================:
export const getConvertFees = createSelector(
	[getOurPricesState],
	(ourPrices: IOurPricesStore): TConvertFees | null => ourPrices.convertFees,
);

// ====================================================:
export const getSpotFeesLoading = createSelector(
	[getOurPricesState],
	(ourPrices: IOurPricesStore): boolean => ourPrices.spotFeesLoading,
);

// ====================================================:
export const getSpotFees = createSelector(
	[getOurPricesState],
	(ourPrices: IOurPricesStore): TSpotFees | null => ourPrices.spotFees,
);
