import L from 'i18n-react';
import { InfoIcon } from 'assets/svg-icon';

const NetworkFeesTooltip = () => {
	return (
		<span className="tooltip-item ">
			<span className="tooltip-item__icon">
				<InfoIcon />
			</span>
			<span className="tooltip tooltip--width-320 tooltip--bottom tooltip--center ">
				<span className="tooltip__text">
					<span className="tooltip__text-title">
						{L.translate('WithdrawCrypto.NetworkFeesTooltip.title_1')}
					</span>
					<span>{L.translate('WithdrawCrypto.NetworkFeesTooltip.text_1')}</span>
					<span className="tooltip__text-title tooltip__text-title--mt-16">
						{L.translate('WithdrawCrypto.NetworkFeesTooltip.title_2')}
					</span>
					<span>{L.translate('WithdrawCrypto.NetworkFeesTooltip.text_2')}</span>
				</span>
			</span>
		</span>
	);
};

export default NetworkFeesTooltip;
