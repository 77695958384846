import { FC, useEffect, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import L from 'i18n-react';
import { numbersAfterPoint } from 'services/utils/numberHelpers';
import { renameCoin } from 'services/utils/renameCoin';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { useLocation, useHistory } from 'react-router-dom';
import {
	getTradePaySellRequest,
	putRecivedPaymentRequest,
	putOpenDisputeRequest,
} from 'redux/reducers/p2p/reducer';
import { getTradeStatus, getTradePaySell } from 'redux/reducers/p2p/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import EvidancePhotos from '../EvidancePhotos';
import InfoTradeSection from '../InfoTradeSection/InfoTradeSection';
import TotpPopup from '../TotpPopup';
/* eslint-disable no-debugger */
const TradeReleaseTether: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const tradeStatus = useSelector(getTradeStatus);
	const { search } = location;
	const user = useSelector(getUserSettingsData);
	const tradeId = queryString.parse(search).trade_id || null;
	const trade = useSelector(getTradePaySell);
	const [isTotpOpen, setIsTotpOpen] = useState(false);

	const coin = (trade && trade.asset_code && trade.asset_code.toUpperCase()) || '';
	const userId = user && user.id;
	const tradId = trade && trade.trader && trade.trader.id;
	const type =
		trade && user && user.id && trade.seller_id && user.id === trade.seller_id ? 'sell' : 'buy';
	const userTrader = tradId === userId ? 'trader' : 'not_trader';

	useEffect(() => {
		if (!tradeId) return;
		dispatch(getTradePaySellRequest({ trade_id: String(tradeId) }));
	}, [tradeId, dispatch]);

	useEffect(() => {
		if (tradeStatus === 'canceled') {
			history.replace(`/trade-pay-close${search}`);
		}
		if (tradeStatus === 'waiting_for_payment') {
			history.replace(`/p2p-trading/pay-sell${search}`);
		}
		if (tradeStatus === 'reviews' || tradeStatus === 'done') {
			history.replace(`/trade-pay-close${search}`);
		}
	}, [tradeStatus, history, search]);

	const handleBuyNow = () => {
		if (!tradeId) return;
		setIsTotpOpen(true);
	};

	const fnProfile = (e: MouseEvent<HTMLAnchorElement>) => {
		const { name } = e.currentTarget.dataset;
		history.push({ pathname: `/p2p-user-center`, search: `profilename=${String(name || '')}` });
	};

	const handleDispute = () => {
		if (!tradeId) return;
		dispatch(putOpenDisputeRequest({ trade_id: String(tradeId) }));
	};

	return (
		<div className="create-offer__left">
			<div className="payment-box">
				<div className="payment-info">
					<h4 className="payment-info__title">
						{String(L.translate('P2P.wait_for_confirmation'))}
					</h4>
					<div className="payment-info__text">
						<p>
							<span className="payment-info__text-bold">
								{' '}
								{type === 'buy' ? L.translate('P2P.t_0219') : L.translate('P2P.t_0220')}{' '}
							</span>
							<span className="payment-info__text-thin">
								{fixedCropNumber(Number(trade?.amount), numbersAfterPoint(String(coin)) || 8) || 0}{' '}
								{coin}{' '}
							</span>
							<span className="payment-info__text-bold">
								{type === 'buy' ? L.translate('P2P.t_0222') : L.translate('P2P.t_0221')}
							</span>{' '}
							<span
								data-name={
									userTrader === 'trader'
										? trade?.advertisement?.user?.username
										: trade?.trader?.username
								}
								role="presentation"
								className="payment-info__text-border"
								onClick={fnProfile}
								style={{ cursor: 'pointer' }}
							>
								{userTrader === 'trader'
									? trade?.advertisement?.user?.username
									: trade?.trader?.username}
							</span>
						</p>
						<p>
							{type === 'buy' ? (
								`${renameCoin(coin)} ${String(L.translate('P2P.paragraph_1'))}`
							) : (
								<>
									<span className="payment-info__text-bold">{L.translate('P2P.paragraph_2')} </span>
									<span className="payment-info__name">{trade?.buyer_full_name}</span>
									<span className="payment-info__text-bold">
										{' '}
										, {L.translate('P2P.paragraph_3')}
									</span>
								</>
							)}
						</p>
					</div>
				</div>
				<div className="payment-descr">
					{trade?.status === 'under_dispute' && <EvidancePhotos trade={trade} />}
					<div className="payment-banner">
						<div className="payment-banner__container payment-banner__container--wider">
							<div className="payment-banner__icon">
								<svg
									width="14"
									height="10"
									viewBox="0 0 14 10"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.3327 1L4.99935 8.33333L1.66602 5"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<h4 className="payment-banner__title">
								{type === 'buy'
									? String(L.translate('P2P.you_send_payment'))
									: String(L.translate('P2P.you_received_payment'))}
							</h4>
							<p className="payment-banner__text">
								{type === 'buy'
									? `${String(L.translate('P2P.confirm_seller'))} ${renameCoin(coin)} ${String(
											L.translate('P2P.to_you'),
									  )}`
									: String(L.translate('P2P.confirm_buyer'))}
							</p>
						</div>
					</div>
					<div className="payment-buttons">
						{type === 'sell' && trade && trade.status === 'waiting_for_confirmation' ? (
							<button onClick={handleBuyNow} type="button" className="button">
								{L.translate('P2P.t_0233')}
							</button>
						) : (
							''
						)}
						{trade?.status === 'waiting_for_confirmation' &&
							String(trade?.status) !== 'under_dispute' && (
								<button onClick={handleDispute} type="button" className="button button--light-red">
									{String(L.translate('P2P.open_dispute'))}
								</button>
							)}
					</div>
				</div>
			</div>

			<InfoTradeSection trade={trade} userTrader={userTrader} type={type} coin={coin} />

			<TotpPopup
				isOpen={isTotpOpen}
				onClose={() => setIsTotpOpen(false)}
				onSubmit={(totp: string) => {
					if (type === 'sell') {
						if (
							trade &&
							trade.status === 'waiting_for_confirmation' &&
							String(trade.status) !== 'under_dispute'
						) {
							dispatch(putRecivedPaymentRequest({ trade_id: String(tradeId), totp }));
						}
					}
				}}
			/>
		</div>
	);
};

export default TradeReleaseTether;
