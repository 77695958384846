import { MouseEvent, FC, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getCurrentPair, getHideBalanceSetting } from 'redux/reducers/tradingSettings/selectors';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import { setHideBalanceSetting } from 'redux/reducers/tradingSettings/reducer';
import MarginCross from './MarginCross';
import MarginModals from './MarginModals';
import MarginIsolated from './MarginIsolated';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

// ==========================================:
const MarginWallet: FC = () => {
	const [currentTab, setCurrentTab] = useState('');
	const location = useLocation();
	const history = useHistory();
	const currentPair = useSelector(getCurrentPair);

	const hideBalance = useSelector(getHideBalanceSetting);

	const dispatch = useDispatch();

	const [activeType, setActiveType] = useState('borrow');
	const [openModal, setOpenModal] = useState(false);

	const closeModal = (): void => {
		setOpenModal(false);
	};

	const handleSetBalancesIsHide = () => {
		dispatch(setHideBalanceSetting(!hideBalance));
	};
	useEffect(() => {
		if (currentTab !== '') {
			history.push(`/margin/${currentTab}`);
		}
	}, [currentTab, history]);

	useEffect(() => {
		const path = location.pathname.split('/')[location.pathname.split('/').length - 1];
		if (path === 'cross' || path === 'isolated') {
			const setPath = location.pathname.split('/')[location.pathname.split('/').length - 1];
			return setCurrentTab(setPath);
		}
		return setCurrentTab('cross');
	}, [location.pathname]);

	const handleModal = (type: string) => {
		setOpenModal((prevOpenModal) => !prevOpenModal);
		setActiveType(type);
	};

	const tabs: any = {
		cross: (
			<MarginCross
				handleSetBalancesIsHide={handleSetBalancesIsHide}
				balancesIsHide={hideBalance}
				currentTab={currentTab}
			/>
		),
		isolated: (
			<MarginIsolated
				handleSetBalancesIsHide={handleSetBalancesIsHide}
				balancesIsHide={hideBalance}
				currentTab={currentTab}
			/>
		),
	};

	const handleTab = (e: MouseEvent<HTMLButtonElement>) => {
		setCurrentTab(e.currentTarget.name);
	};

	return (
		<>
			<SocketWithPrivateTopicConnectWrapper topicName={`balances:${String(currentTab)}`}>
				<SocketWithPrivateTopicConnectWrapper topicName="index_prices">
					<div className="main-content__header">
						<h2 className="section-title">{L.translate('Margin.title')}</h2>
						<div className="margin-switcher">
							<button
								name="cross"
								onClick={handleTab}
								className={`${
									currentTab === 'cross'
										? 'margin-switcher__btn margin-switcher__btn--active'
										: 'margin-switcher__btn'
								}
						`}
								type="button"
							>
								{L.translate('Margin.Cross.title')}
							</button>
							<button
								name="isolated"
								onClick={handleTab}
								className={`${
									currentTab === 'isolated'
										? 'margin-switcher__btn margin-switcher__btn--active'
										: 'margin-switcher__btn'
								}
						`}
								type="button"
							>
								{L.translate('Margin.Isolated.title')}
							</button>
							{currentTab === 'cross' && (
								<button type="button" className="margin-switcher__extra">
									3X
								</button>
							)}
						</div>
						<div className="section-nav">
							<button
								className="button button--third-grey button--nav"
								onClick={() => handleModal('borrow')}
								type="button"
							>
								{L.translate('Margin.Borrow.title')}
							</button>
							<button
								className="button button--third-grey button--nav"
								onClick={() => handleModal('repay')}
								type="button"
							>
								{L.translate('Margin.Repay.title')}
							</button>
							<button
								className="button button--nav"
								onClick={() => handleModal('transfer')}
								type="button"
							>
								{L.translate('Margin.Transfer.title')}
							</button>
						</div>
					</div>
					{tabs[currentTab]}
					<MarginModals
						currentTab={currentTab}
						openModal={openModal}
						closeModal={closeModal}
						activeType={activeType}
						currentPair={currentPair}
					/>
				</SocketWithPrivateTopicConnectWrapper>
			</SocketWithPrivateTopicConnectWrapper>
		</>
	);
};

export default MarginWallet;
