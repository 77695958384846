import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { IApiTopPairWithChart } from 'services/api/assetPairs/types';
import { responseErrors } from 'services/http/responseErrors';
import {
	getAssetPairsRequest,
	getAssetPairsRequestFavorite,
	getAssetPairsSuccessFavorite,
	getAssetPairsSuccess,
	getAssetPairsFeesAndLimitsRequest,
	getAssetPairsFeesAndLimitsSuccess,
	assetPairsInitState,
	putAssetPairsRequestFavoriteId,
	getTopPairsWithChartSuccess,
	getTopPairsWithChartRequest,
	getTopPairsWithChartError,
	getMoreTopPairsWithChartRequest,
	getMoreTopPairsWithChartSuccess,
	getMoreTopPairsWithChartError,
} from './reducer';
import {
	IAssetPairsFavoriteId,
	IAssetPairsFeesAndLimitsResponsePayload,
	IAssetPairsResponsePayload,
} from './types';

// =============================================================:
function* assetPairsRequestWorkerFavorite() {
	try {
		yield put(showLoading());
		const response: IAssetPairsResponsePayload = yield call(api.assetPairs.getAssetPairsFavorite);
		yield put(getAssetPairsSuccessFavorite(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(assetPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* assetPairsRequestWorkerFavoriteId(action: PayloadAction<IAssetPairsFavoriteId>) {
	const { payload } = action;
	yield call(api.assetPairs.putAssetPairsFavorite, payload);
	yield put(getAssetPairsRequestFavorite());
	try {
		yield put(showLoading());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(assetPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* assetPairsRequestWorker() {
	try {
		yield put(showLoading());
		const response: IAssetPairsResponsePayload = yield call(api.assetPairs.getAssetPairs);
		yield put(getAssetPairsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(assetPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* assetPairsFeesAndLimitsRequestWorker() {
	try {
		yield put(showLoading());
		const response: IAssetPairsFeesAndLimitsResponsePayload = yield call(
			api.assetPairs.getAssetPairsFeesAndLimits,
		);
		yield put(getAssetPairsFeesAndLimitsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(assetPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:

function* getTopPairsWithChartWorker() {
	try {
		yield put(showLoading());
		const data: IApiTopPairWithChart[] = yield call(api.assetPairs.getTopPairsWithChart);
		yield put(getTopPairsWithChartSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getTopPairsWithChartError());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:

function* getMoreTopPairsWithChartWorker() {
	try {
		yield put(showLoading());
		const data: IApiTopPairWithChart[] = yield call(api.assetPairs.getMoreTopPairsWithChart);
		yield put(getMoreTopPairsWithChartSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getMoreTopPairsWithChartError());
	} finally {
		yield put(hideLoading());
	}
}

export function* assetPairsSaga() {
	yield takeEvery(getAssetPairsRequest.type, assetPairsRequestWorker);
	yield takeEvery(getAssetPairsRequestFavorite.type, assetPairsRequestWorkerFavorite);
	yield takeEvery(putAssetPairsRequestFavoriteId.type, assetPairsRequestWorkerFavoriteId);
	yield takeEvery(getAssetPairsFeesAndLimitsRequest.type, assetPairsFeesAndLimitsRequestWorker);
	yield takeEvery(getTopPairsWithChartRequest.type, getTopPairsWithChartWorker);
	yield takeEvery(getMoreTopPairsWithChartRequest.type, getMoreTopPairsWithChartWorker);
}
