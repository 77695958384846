/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { getAssetPairsListFavorite } from 'redux/reducers/assetPairs/selectors';
import { putAssetPairsRequestFavoriteId } from 'redux/reducers/assetPairs/reducer';
import { IAssetPairsItem } from 'redux/reducers/assetPairs/types';
import { IPairsTableItemProps } from './types';

const PairsTableItem: FC<IPairsTableItemProps> = ({ pair, marginPairsTab }) => {
	const { code, change24h, last_price } = pair;
	const dispatch = useDispatch();
	const currentPairFavorite = useSelector(getAssetPairsListFavorite);
	const searchIdFavorite = pair.id;
	const favoriteId = currentPairFavorite?.find(
		(e: IAssetPairsItem) => e.id === searchIdFavorite,
	)?.id;
	const transformAssetPairCode = code?.replace('_', '/').toUpperCase();
	const getTableValueClass = (value: number): string => {
		switch (true) {
			case value > 0:
				return 'table-value table-value--green';
			case value < 0:
				return 'table-value table-value--red';

			default:
				return 'table-value';
		}
	};

	const getActiveMargin = () => {
		switch (marginPairsTab) {
			case 'cross':
				return <span className="margin-x">{pair?.shoulder_cross || 0}x</span>;
			case 'isolated':
				return <span className="margin-x">{pair?.shoulder_isolate || 0}x</span>;
			case 'all':
				return (
					<span className="margin-x">
						{pair?.shoulder_cross > pair?.shoulder_isolate
							? pair?.shoulder_cross
							: pair?.shoulder_isolate || 0}
						x
					</span>
				);
			default:
				return null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const handleChangePair = () => {
		dispatch(setCurrentPair(code));
	};
	return (
		<div
			className="tr asset-pair-item"
			onClick={(e) => {
				e.stopPropagation();
				handleChangePair();
			}}
		>
			<div className="td">
				<div className="favorites-name">
					<button
						onClick={(e) => {
							dispatch(putAssetPairsRequestFavoriteId({ asset_pair_id: pair.id }));
							e.stopPropagation();
						}}
						type="button"
					>
						<span
							className={`favorites-name__icon ${
								pair.id === favoriteId ? 'icon-star_active' : 'icon-star'
							}`}
						/>
					</button>

					<span className="favorites-name__text">{transformAssetPairCode}</span>
					{/* {getActiveMargin()} */}
				</div>
			</div>
			<div className="td">
				<span className={getTableValueClass(Number(last_price))}>
					{/* {fixedCropNumber(last_price, view_decimal) || last_price} */}
					{convertExponentialToDecimal(last_price)}
				</span>
			</div>
			<div className="td td--right">
				<span className={getTableValueClass(Number(change24h))}>{change24h}%</span>
			</div>
		</div>
	);
};

export default PairsTableItem;
