/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { useHistory, useLocation } from 'react-router';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GOOGLE_CAPTCHA, APP_NAME } from 'services/constants/env';
import { totpErrors } from 'services/constants/2faErrors';
import { getErrorMessage } from 'services/utils/errors';
import {
	loginRequest,
	sendAppleLoginStringRequest,
	sendFacebookLoginStringRequest,
	sendGoogleLoginStringRequest,
} from 'redux/reducers/auth/reducer';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { getLoginIsLoad, getSocialLoginData } from 'redux/reducers/auth/selectors';
import Auth from 'layouts/Auth';
import LoginForm from 'components/Forms/LoginForm';
import GoogleCaptcha from 'components/GoogleCaptcha';
import { ILoginSubmitValue } from 'components/Forms/LoginForm/types';
import Loader from 'ui/Loader';
import SocialLogin from './SocialLogin';

// ==========================================:
const Login: FC = () => {
	const [open, setOpen] = useState(false);
	const [loginData, setLoginData] = useState<null | ILoginSubmitValue>(null);
	const [captchaLoading, setCaptchaLoading] = useState(false);
	const [showTotp, setShowTotp] = useState(false);
	const [totpIsError, setTotpIsError] = useState(false);
	const [captcha, setCaptcha] = useState('');
	const history = useHistory();
	const location = useLocation();

	const loginIsLoad = useSelector(getLoginIsLoad);
	const socialLogin = useSelector(getSocialLoginData);
	const dispatch = useDispatch();

	useEffect(() => {
		const loginState = new URLSearchParams(location.search).get('state');
		const loginCode = new URLSearchParams(location.search).get('code');
		const referral = sessionStorage.getItem('invite_key') || localStorage.getItem('invite_key');
		if (loginState) {
			if (socialLogin === 'google') {
				dispatch(
					sendGoogleLoginStringRequest({
						token: loginState,
						code: loginCode,
						history,
						referral: referral || undefined,
					}),
				);
			}
			if (socialLogin === 'facebook') {
				dispatch(
					sendFacebookLoginStringRequest({
						token: loginState,
						code: loginCode,
						history,
						referral: referral || undefined,
					}),
				);
			}
			if (socialLogin === 'apple') {
				dispatch(sendAppleLoginStringRequest({ token: loginState, code: loginCode, history }));
			}
		}
	}, [dispatch, location.search]);

	const hangleCaptchaClose = useCallback((): void => {
		setLoginData(null);
		setOpen(false);
	}, []);

	const onError = (error: any) => {
		setCaptcha('');
		const errorMessage = getErrorMessage(error);
		if (errorMessage && totpErrors.includes(errorMessage)) {
			setShowTotp(true);
			setTotpIsError(true);
		}
	};

	const handleLoginSubmit = (values: ILoginSubmitValue): void => {
		setLoginData(values);
		dispatch(
			loginRequest({
				data: { ...values, captcha },
				history,
				onSuccess: () => setCaptcha(''),
				onError,
			}),
		);
		dispatch(getAssetsRequest());
	};

	// const handleCaptcha = useCallback(
	// 	(value: string): void => {
	// 		if (loginData && value) {
	// 			dispatch(
	// 				loginRequest({
	// 					data: {
	// 						...loginData,
	// 						captcha: value,
	// 					},
	// 					history,
	// 					onError,
	// 				}),
	// 			);
	// 			dispatch(getAssetsRequest());
	// 		}

	// 		setLoginData(null);
	// 		setOpen(false);
	// 	},
	// 	[dispatch, loginData, history],
	// );

	return (
		<Auth title={`${String(APP_NAME)}.com | ${String(L.translate('LoginPage.login_page_title'))}`}>
			<GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA}>
				<div className="authorization">
					<LoginForm
						loginSubmit={handleLoginSubmit}
						showTotp={showTotp}
						setShowTotp={setShowTotp}
						totpIsError={totpIsError}
						setTotpIsError={setTotpIsError}
						captchaLoading={captchaLoading}
						setCaptchaLoading={setCaptchaLoading}
						captcha={captcha}
						setCaptcha={setCaptcha}
					/>
					<SocialLogin />
					{(loginIsLoad || captchaLoading) && (
						<div className="auth-loader-wrapper">
							<Loader />
						</div>
					)}
				</div>
				{/* {open && (
				<div className="popup-window popup-window--captcha">
					<div className="popup-window__inside">
						<GoogleCaptcha setCaptcha={handleCaptcha} captchaClose={hangleCaptchaClose} />
						<div className="popup-window__close" onClick={hangleCaptchaClose} />
					</div>
				</div>
			)} */}
			</GoogleReCaptchaProvider>
		</Auth>
	);
};

export default Login;
