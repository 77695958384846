import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Content from 'layouts-elements/Content';
import Header from 'layouts-elements/Header';
import { APP_NAME } from 'services/constants/env';
import VerificationBanner from 'ui/VerificationBanner';
import { appLanguage } from 'redux/reducers/app/selectors';
import ogImage from 'assets/images/content/binatica-og.png';
import { getLocale } from 'services/utils/language';
import { ITrading } from './types';

// ==========================================:
const Trading: FC<ITrading> = ({ children, title, showVerificBanner }) => {
	const pageTitle = title ? `| ${title}` : '';
	const language = useSelector(appLanguage);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)}.com ${pageTitle}`}</title>
				<meta
					name="description"
					content="Engage in secure and instant spot trading of cryptocurrencies. Buy and sell Bitcoin, Ethereum, and more with our easy-to-use platform. Start trading today!"
				/>

				{/* Open Graph Meta Tags */}
				<meta
					property="og:title"
					content="Bitanica.com | Spot Trading Crypto | Buy & Sell Instantly"
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content={`${window.location.host}/spot/LTC_USDT`} />
				<meta property="og:image" content={ogImage} />
				<meta
					property="og:description"
					content="Engage in secure and instant spot trading of cryptocurrencies. Buy and sell Bitcoin, Ethereum, and more with our easy-to-use platform. Start trading today!"
				/>
				<meta property="og:site_name" content="Bitanica.com" />
				<meta property="og:locale" content={getLocale(language)} />

				{/* Twitter Card Meta Tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:title"
					content="Bitanica.com | Spot Trading Crypto | Buy & Sell Instantly"
				/>
				<meta
					name="twitter:description"
					content="Engage in secure and instant spot trading of cryptocurrencies. Buy and sell Bitcoin, Ethereum, and more with our easy-to-use platform. Start trading today!"
				/>
				<meta name="twitter:image" content={ogImage} />
				<meta name="twitter:site" content="@YourTwitterHandle" />
			</Helmet>
			{/* Временный вариант, без этого почему то файл og:image не подгружается */}
			<img src={ogImage} alt="" style={{ display: 'none' }} />
			{/* ---------------------- */}
			<div className="wrapper">
				<Header />
				<Content>
					{showVerificBanner && <VerificationBanner />}
					{children}
				</Content>
			</div>
		</>
	);
};

export default Trading;
