import { FC, useState, useEffect } from 'react';
import L from 'i18n-react';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getOrdersHistoryRequest } from 'redux/reducers/spotTrade/reducer';
import {
	getSpotUserOrdersHistory,
	getSpotUserOrdersHistoryIsLoad,
} from 'redux/reducers/spotTrade/selectors';
// import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { definePage } from 'services/utils/definePage';
import FilteredBar from '../../Trade/SpotHistoryTables/FilteredBar';
import OrdersHistoryItem from '../OrderHistoryItem';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IOrderHistoryTableProps } from './types';
import { navList } from '../../../routes/routesList';

const OrdersHistoryTable: FC<IOrderHistoryTableProps> = ({ currentTab }) => {
	const ordersHistory = useSelector(getSpotUserOrdersHistory);
	const ordersHistoryIsLoad = useSelector(getSpotUserOrdersHistoryIsLoad);

	const totalPages = ordersHistory?.last_page || 1;
	const [currentPeriod, setCurrentPeriod] = useState<null | number>(null);
	const [calendarPeriod, setCalendarPeriod] = useState<[Date | null, Date | null]>([null, null]);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const dispatch = useDispatch();
	const location = useLocation<any>();

	useEffect(() => {
		if (authIsAuthenticated) {
			const [startDate, endDate] = calendarPeriod;
			if (location.state?.margin === 'cross' || location.state?.margin === 'isolated') {
				if (startDate && endDate) {
					dispatch(
						getOrdersHistoryRequest({
							params: {
								current_page: currentPage,
								per_page: 10,
								[`${location.state.margin === 'cross' ? 'margin' : 'isolated'}`]: `${
									location.state.margin === 'cross' ? 'margin' : 'isolated'
								}`,
								period: currentPeriod,
								start_date: format(startDate, 'yyyy-MM-dd'),
								end_date: format(endDate, 'yyyy-MM-dd'),
							},
						}),
					);
					return;
				}
				if (startDate && !endDate) {
					dispatch(
						getOrdersHistoryRequest({
							params: {
								current_page: currentPage,
								per_page: 10,
								[`${location.state.margin === 'cross' ? 'margin' : 'isolated'}`]: `${
									location.state.margin === 'cross' ? 'margin' : 'isolated'
								}`,
								period: currentPeriod,
								start_date: format(startDate, 'yyyy-MM-dd'),
								end_date: !endDate && format(startDate, 'yyyy-MM-dd'),
							},
						}),
					);
					return;
				}
				dispatch(
					getOrdersHistoryRequest({
						params: {
							current_page: currentPage,
							per_page: 10,
							[`${location.state.margin === 'cross' ? 'margin' : 'isolated'}`]: `${
								location.state.margin === 'cross' ? 'margin' : 'isolated'
							}`,
							period: currentPeriod,
						},
					}),
				);
				return;
			}
			if (startDate && endDate) {
				dispatch(
					getOrdersHistoryRequest({
						params: {
							current_page: currentPage,
							per_page: 10,
							period: currentPeriod,
							start_date: format(startDate, 'yyyy-MM-dd'),
							end_date: format(endDate, 'yyyy-MM-dd'),
						},
					}),
				);
				return;
			}
			if (startDate && !endDate) {
				dispatch(
					getOrdersHistoryRequest({
						params: {
							current_page: currentPage,
							per_page: 10,
							period: currentPeriod,
							start_date: format(startDate, 'yyyy-MM-dd'),
							end_date: !endDate && format(startDate, 'yyyy-MM-dd'),
						},
					}),
				);
				return;
			}

			dispatch(
				getOrdersHistoryRequest({
					params: {
						per_page: 10,
						current_page: currentPage,
						period: currentPeriod,
					},
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, authIsAuthenticated, location?.state, currentPage]);

	const handleSearchPerPeriod = (periodValue: number | null) => {
		if (currentTab === 'cross' || currentTab === 'isolated') {
			dispatch(
				getOrdersHistoryRequest({
					params: {
						// current_page: 1,
						per_page: 10,
						period: periodValue === null ? null : periodValue,
						[`${currentTab === 'cross' ? 'margin' : 'isolated'}`]: `${
							currentTab === 'cross' ? 'margin' : 'isolated'
						}`,
					},
				}),
			);
		} else {
			dispatch(
				getOrdersHistoryRequest({
					params: {
						// current_page: 1,
						per_page: 10,
						period: periodValue === null ? null : periodValue,
					},
				}),
			);
		}
	};
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	const hadleSearchDataPerDate = (date: [Date | null, Date | null]) => {
		const [startDate, endDate] = date;
		setCalendarPeriod([startDate, endDate]);

		if (startDate && !endDate) {
			if (currentTab === 'cross' || currentTab === 'isolated') {
				dispatch(
					getOrdersHistoryRequest({
						params: {
							per_page: 10,
							start_date: format(startDate, 'yyyy-MM-dd'),
							end_date: !endDate && format(startDate, 'yyyy-MM-dd'),
							[`${currentTab === 'cross' ? 'margin' : 'isolated'}`]: `${
								currentTab === 'cross' ? 'margin' : 'isolated'
							}`,
						},
					}),
				);
				setCurrentPeriod(null);
				return;
			}
			dispatch(
				getOrdersHistoryRequest({
					params: {
						per_page: 10,
						start_date: format(startDate, 'yyyy-MM-dd'),
						end_date: !endDate && format(startDate, 'yyyy-MM-dd'),
					},
				}),
			);

			setCurrentPeriod(null);
			return;
		}

		if (startDate && endDate) {
			if (currentTab === 'cross' || currentTab === 'isolated') {
				dispatch(
					getOrdersHistoryRequest({
						params: {
							per_page: 10,
							start_date: format(startDate, 'yyyy-MM-dd'),
							end_date: format(endDate, 'yyyy-MM-dd'),
							[`${currentTab === 'cross' ? 'margin' : 'isolated'}`]: `${
								currentTab === 'cross' ? 'margin' : 'isolated'
							}`,
						},
					}),
				);
				setCurrentPeriod(null);
				return;
			}
			dispatch(
				getOrdersHistoryRequest({
					params: {
						per_page: 10,
						start_date: format(startDate, 'yyyy-MM-dd'),
						end_date: format(endDate, 'yyyy-MM-dd'),
					},
				}),
			);
		} else {
			if (currentTab === 'cross' || currentTab === 'isolated') {
				dispatch(
					getOrdersHistoryRequest({
						params: {
							per_page: 10,
							[`${currentTab === 'cross' ? 'margin' : 'isolated'}`]: `${
								currentTab === 'cross' ? 'margin' : 'isolated'
							}`,
						},
					}),
				);
				setCurrentPeriod(null);
				return;
			}
			dispatch(
				getOrdersHistoryRequest({
					params: {
						per_page: 10,
						current_page: currentPage,
					},
				}),
			);
		}

		setCurrentPeriod(null);
	};

	return (
		<>
			<FilteredBar
				handleSearch={hadleSearchDataPerDate}
				period={currentPeriod}
				setCurrentPeriod={setCurrentPeriod}
				handleSearchPerPeriod={handleSearchPerPeriod}
				currentClass="table-date-filter"
			/>

			<div className="table table--spot-orders-history table--wallet-type">
				<div className="table-header table-header-td">
					<div className="tr">
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.date_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(L.translate(definePage(navList.marginOrderHistory.path, location)))}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.type_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.side_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate(
										'Trade.Spot.SpotHistoryTables.OrdersHistoryTable.average_column_name',
									),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.price_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.amount_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.filled_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.total_column_name'),
								)}
							</span>
						</div>
						<div className="td td--right">
							<span className="td-title">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.status_column_name'),
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="table-body">
					{ordersHistoryIsLoad && (
						<div className="open-orders-history-loader-wrapper">
							<Loader />
						</div>
					)}
					{!ordersHistoryIsLoad && ordersHistory?.data?.length
						? ordersHistory?.data?.map((order: any) => (
								<OrdersHistoryItem key={order.id} data={order} />
						  ))
						: null}

					{!ordersHistoryIsLoad && ordersHistory?.data?.length === 0 && (
						<span className="table-empty">
							{String(
								L.translate(
									'Trade.Spot.SpotHistoryTables.OrdersHistoryTable.no_orders_history_text',
								),
							)}
						</span>
					)}
				</div>
			</div>
			{totalPages > 1 && (
				<Pagination
					pageCount={totalPages}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default OrdersHistoryTable;
