/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { getP2PBalancesLoad, getP2POpenTradesList } from 'redux/reducers/p2p/selectors';
import { getP2POpenTradesRequest } from 'redux/reducers/p2p/reducer';
import { getAuthIsAuthenticated, getAccountP2P } from 'redux/reducers/auth/selectors';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { addImges } from 'services/utils/changeArrey';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import P2PItem from '../P2PItem';

const P2PSortingTableSell: FC = () => {
	const openTradesList = useSelector(getP2POpenTradesList);
	const accountP2P = useSelector(getAccountP2P);
	const totalPages = openTradesList?.sell?.last_page || 1;
	const asset = useSelector(getAssetsList);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const p2pIsLoad = useSelector(getP2PBalancesLoad);

	useEffect(() => {
		if (authIsAuthenticated && accountP2P) {
			dispatch(
				getP2POpenTradesRequest({
					params: {
						per_page: 10,
						current_page: currentPage,
						type: 'sell',
					},
				}),
			);
		}
	}, [dispatch, authIsAuthenticated, currentPage, accountP2P]);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<div className="table table--p2p-open-trades table--wallet-type table--p2p-type">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<p className="td-title">{String(L.translate('P2P.ClosedTrades.type'))}</p>
						</div>
						<div className="td">
							<p className="td-title">{String(L.translate('P2P.ClosedTrades.fiat_amount'))}</p>
						</div>
						<div className="td">
							<p className="td-title">{String(L.translate('P2P.ClosedTrades.crypto_amount'))}</p>
						</div>
						<div className="td">
							<p className="td-title">{String(L.translate('P2P.ClosedTrades.counterparty'))}</p>
						</div>
						<div className="td">
							<p className="td-title">{String(L.translate('P2P.ClosedTrades.status'))}</p>
						</div>
						<div className="td td--right">
							<p className="td-title">{String(L.translate('P2P.OpenTrades.operation'))}</p>
						</div>
					</div>
				</div>
				<div className="table-body">
					{p2pIsLoad ? (
						<div className="spot-and-fiat-wallets-table-loader-wrapper">
							<Loader />
						</div>
					) : null}

					{!p2pIsLoad && openTradesList?.sell?.data?.length
						? addImges(openTradesList?.sell?.data, asset)?.map((item: any) => (
								<P2PItem key={item?.id} data={item} type="sell" />
						  ))
						: null}

					{!p2pIsLoad && openTradesList?.sell?.data?.length === 0 && (
						<span className="table-empty">
							{String(L.translate('P2P.OpenTrades.no_open_trades'))}
						</span>
					)}
					{!accountP2P && (
						<span className="table-empty">
							{String(L.translate('P2P.ClosedTrades.p2p_disabled'))}
						</span>
					)}
				</div>
			</div>
			{totalPages > 1 && (
				<Pagination
					pageCount={totalPages}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default P2PSortingTableSell;
