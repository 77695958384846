/* eslint-disable @typescript-eslint/no-explicit-any */
export const addImges = (p2p: any, allAsset: any) => {
	const newArray = p2p?.map((item: any): any => {
		const considence: any = allAsset?.find(
			(assetItem: any) =>
				assetItem?.id === item?.id ||
				assetItem?.id === item?.asset_id ||
				assetItem?.code === item?.asset_code,
		);
		return { ...item, img_path: considence?.img_path, type: considence?.type };
	});
	return newArray;
};
