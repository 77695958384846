import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import L from 'i18n-react';
import { api } from 'services';
import { dataLayer } from 'services/gtm';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { INVITE_KEY } from 'services/constants/storageKeys';
import {
	authInitState,
	createNewPasswordRequest,
	createNewPasswordSuccess,
	emailConfirmRequest,
	emailConfirmStatusCallback,
	emailConfirmStatusRequest,
	emailConfirmSuccess,
	emailResetConfirmTokenRequest,
	emailResetConfirmTokenSuccess,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	generateSmsRequest,
	forgotTwoFaRequest,
	ipConfirmRequest,
	ipConfirmSuccess,
	loginRequest,
	loginSuccess,
	logoutRequest,
	registrationRequest,
	registrationSuccess,
	updateLoginData,
	loginLoaderOff,
	ipConfirmLoaderOff,
	sendFacebookLoginStringRequest,
	sendGoogleLoginStringRequest,
	sendAppleLoginStringRequest,
	refreshRequest,
	refreshSuccess,
	authInitLoaderIpState,
	setSocialLoginData,
	resendRegisterRequest,
} from './reducer';
import {
	ILoginResponse,
	ILoginPayload,
	IRegistrationPayload,
	ILogoutPayload,
	IForgotPasswordPayload,
	ICreateNewPasswordPayload,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IipConfirmPayload,
	IGenerateSmsRequestPayload,
	IResetTwoFaPayload,
	ILoginPayloadData,
	ILoginWithGooglePayload,
	IRefreshResponse,
	ISocialLoginPayloadData,
	IResendRegisterPayloadData,
} from './types';
import { getLoginData, getSocialLoginData, getSocialLoginPayloadData } from './selectors';

declare global {
	interface Window {
		Intercom: any;
	}
}
/* eslint-disable */
// =============================================================:
function* ipConfirmRequestWorker({ payload }: PayloadAction<IipConfirmPayload>) {
	const socialLoginData: ISocialLoginPayloadData | null = yield select(getSocialLoginPayloadData);
	const loginData: ILoginPayloadData | null = yield select(getLoginData);
	const socialLogin: null | 'google' | 'facebook' | 'apple' = yield select(getSocialLoginData);
	try {
		yield put(showLoading());

		if (socialLogin) {
			yield call(api.auth.ipConfirm, {
				code: payload.data.code,
				email: socialLoginData?.email || '',
			});
			window.Intercom('update', {
				email: socialLoginData?.email,
			});
		} else {
			const { data } = yield call(api.auth.ipConfirm, {
				code: payload.data.code,
				email: loginData?.email || '',
			});
			yield put(loginSuccess(data));
			yield put(updateLoginData(null));
			yield put(ipConfirmSuccess());
			notificationContainer(
				String(L.translate('IPConfirmation.ip_confirmation_success_confirm')),
				'info',
			);
			window.Intercom('update', {
				email: loginData?.email,
			});
		}

		if (socialLogin) {
			if (socialLogin === 'google') {
				payload.history.push('/api/gredirect');
				return;
			}
			if (socialLogin === 'facebook') {
				payload.history.push('/api/fredirect');
				return;
			}
			return;
		}
		// if (loginData) {
		// 	yield put(
		// 		loginRequest({
		// 			history: payload.history,
		// 			data: { captcha: payload.captcha, email: loginData.email, password: loginData.password },
		// 		}),
		// 	);
		// 	payload.history.push('/');
		// } else {
		// 	payload.history.push('/login');
		// }

		payload.history.push('/login');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'invalid_verification_code') {
				yield put(authInitLoaderIpState());
				notificationContainer(String(L.translate('Errors.invalid_verification_code')), 'error');
				return;
			}

			if (error?.response?.data?.errors[0] === 'the_code_must_not_be_greater_than_6_characters') {
				yield put(authInitLoaderIpState());
				notificationContainer(
					String(L.translate('Errors.the_code_must_not_be_greater_than_6_characters')),
					'error',
				);
				return;
			}
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* loginRequestWorker(action: PayloadAction<ILoginPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ILoginResponse = yield call(api.auth.login, payload.data);
		dataLayer.push({
			event: 'login',
		});
		yield put(loginSuccess(response));
		window.Intercom('update', {
			email: payload?.data.email,
		});

		yield put(updateLoginData(null));
		payload.onSuccess?.();
		payload.history.push('/fiat-and-spot');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'ip_not_verified') {
				yield put(updateLoginData(payload.data));
				payload.history.push('/ip-confirmation');
			} else if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(String(L.translate(`Errors.${code}`)), 'info');
			} else {
				responseErrors(error);
			}
		}
		payload.onError?.(error);
		yield put(loginLoaderOff());
		yield put(ipConfirmLoaderOff());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* registrationRequestWorker({ payload }: PayloadAction<IRegistrationPayload>) {
	const { apiParams, onSuccess, onError } = payload;

	try {
		yield put(showLoading());
		yield call(api.auth.registration, apiParams);
		yield put(registrationSuccess());

		onSuccess?.();

		notificationContainer(String(L.translate('Errors.registered_successfully')), 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
		onError?.();
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* forgotPasswordRequestWorker(action: PayloadAction<IForgotPasswordPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.forgotPassword, payload);
		yield put(forgotPasswordSuccess());

		notificationContainer(String(L.translate('Errors.check_the_email')), 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}
/* eslint-disable */
// =============================================================:
function* emailConfirmRequestWorker({ payload }: PayloadAction<IEmailConfirmPayload>) {
	const { onError, onSuccess, token, email, timestamp } = payload;
	try {
		yield put(showLoading());
		yield call(api.auth.emailConfirm, { token, email, timestamp });
		yield put(emailConfirmStatusCallback());
		yield put(emailConfirmSuccess());
		dataLayer.push({
			event: 'sign_up_confirmation',
			sign_up_status: 'success',
		});
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors[0] === 'email_already_confirmed'
			) {
				notificationContainer(String(L.translate('Errors.email_already_confirmed')), 'info');
				yield put(emailConfirmStatusRequest());
				yield put(authInitState());
				return;
			}
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors.token &&
				error?.response?.data?.errors.token === 'token_expired'
			) {
				notificationContainer(String(L.translate('Errors.email_already_confirmed')), 'info');
				yield put(authInitState());
				return;
			}
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors.token &&
				error?.response?.data?.errors.token === 'the_selected_token_is_invalid'
			) {
				notificationContainer(String(L.translate('Errors.the_selected_token_is_invalid')), 'info');
				onError?.('the_selected_token_is_invalid');
				yield put(authInitState());
				return;
			}
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors.email[0] &&
				error?.response?.data?.errors.email[0] === 'the_email_must_be_a_valid_email_address'
			) {
				notificationContainer(
					String(L.translate('Errors.the_email_must_be_a_valid_email_address')),
					'info',
				);
				return;
			}
			responseErrors(error);
			yield put(authInitState());
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* emailResetConfirmTokenRequestWorker(
	action: PayloadAction<IEmailResetConfirmTokenPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.emailResetConfirmToken, payload);
		yield put(emailConfirmStatusCallback());
		yield put(emailResetConfirmTokenSuccess());

		payload.history.replace('/email-confirm-check');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors[0] === 'email_already_confirmed'
			) {
				yield put(emailConfirmStatusRequest());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* createNewPasswordWorker(action: PayloadAction<ICreateNewPasswordPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.newPassword, payload.data);
		yield put(createNewPasswordSuccess());
		payload.history.push('/login');

		notificationContainer(String(L.translate(`Errors.password_successfully_changed`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}
/* eslint-disable */
// =============================================================:
function* logoutRequestWorker(action: PayloadAction<ILogoutPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.logout);
		yield put(authInitState());
		payload.history.push({
			search: '',
		});
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* generateSmsRequestWorker(action: PayloadAction<IGenerateSmsRequestPayload>) {
	const { payload } = action;
	console.log('sms');
	try {
		yield put(showLoading());

		yield call(api.auth.generateSms, payload);
		notificationContainer(String(L.translate('Notification.code_sent_to_phone')), 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.status === 400) {
				notificationContainer(String(L.translate('Notification.try_later')), 'error');
				return;
			}

			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* resetTwoFaRequestWorker(action: PayloadAction<IResetTwoFaPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.resetTwoFa, payload);
		notificationContainer(String(L.translate(`Success.2fa_successfully`)), 'success');
		payload.history.push({ pathname: '/forgot-2fa', state: '2fa_success' });
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error.response?.data.errors[0] === 'already_exist') {
				notificationContainer(String(L.translate(`Errors.2fa_request_already_exist`)), 'error');
			} else {
				responseErrors(error);
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

function* googleLoginWorker(action: PayloadAction<ILoginWithGooglePayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ILoginResponse = yield call(api.auth.loginViaGoogle, payload);
		yield put(loginSuccess(response));
		localStorage.removeItem(INVITE_KEY);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'ip_not_verified') {
				yield put(setSocialLoginData({ email: error?.response?.data?.email }));
				payload.history.push('/ip-confirmation');
			} else if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(String(L.translate(`Errors.${code}`)), 'info');
			} else {
				responseErrors(error);
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

function* facebookLoginWorker(action: PayloadAction<ILoginWithGooglePayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ILoginResponse = yield call(api.auth.loginViaFacebook, payload);
		yield put(loginSuccess(response));
		localStorage.removeItem(INVITE_KEY);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'ip_not_verified') {
				yield put(setSocialLoginData({ email: error?.response?.data?.email }));
				payload.history.push('/ip-confirmation');
			} else if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(String(L.translate(`Errors.${code}`)), 'info');
			} else {
				responseErrors(error);
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

function* appleLoginWorker(action: PayloadAction<ILoginWithGooglePayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ILoginResponse = yield call(api.auth.loginViaApple, payload);
		yield put(loginSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'ip_not_verified') {
				payload.history.push('/ip-confirmation');
			} else if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(String(L.translate(`Errors.${code}`)), 'info');
			} else {
				responseErrors(error);
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* refreshRequestWorker() {
	try {
		yield put(showLoading());
		const response: IRefreshResponse = yield call(api.auth.refreshToken);
		yield put(refreshSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* resendRegisterWorker({ payload }: PayloadAction<IResendRegisterPayloadData>) {
	try {
		yield put(showLoading());
		yield call(api.auth.resendRegister, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(ipConfirmRequest.type, ipConfirmRequestWorker);
	yield takeEvery(registrationRequest.type, registrationRequestWorker);
	yield takeEvery(forgotPasswordRequest.type, forgotPasswordRequestWorker);
	yield takeEvery(emailConfirmRequest.type, emailConfirmRequestWorker);
	yield takeEvery(emailResetConfirmTokenRequest.type, emailResetConfirmTokenRequestWorker);
	yield takeEvery(createNewPasswordRequest.type, createNewPasswordWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(generateSmsRequest.type, generateSmsRequestWorker);
	yield takeEvery(forgotTwoFaRequest.type, resetTwoFaRequestWorker);
	yield takeLatest(sendGoogleLoginStringRequest.type, googleLoginWorker);
	yield takeLatest(sendFacebookLoginStringRequest.type, facebookLoginWorker);
	yield takeLatest(sendAppleLoginStringRequest.type, appleLoginWorker);
	yield takeEvery(refreshRequest.type, refreshRequestWorker);
	yield takeEvery(resendRegisterRequest.type, resendRegisterWorker);
}
