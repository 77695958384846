import { FC } from 'react';
import L from 'i18n-react';
import logo from 'assets/img/logo1.png';
import { useSelector } from 'react-redux';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import VerifyLabel from '../VerifyLabel';
import { IPorps } from './type';
import './index.scss';

function formatDateToCustomFormat(date: Date): string {
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}

const PDFFileDownload: FC<IPorps> = ({
	data,
	amount,
	reference,
	correspondentBank,
	showVerify,
}) => {
	const userData = useSelector(getUserSettingsData);
	const currentDate = new Date();
	const formattedDate = formatDateToCustomFormat(currentDate);
	return (
		<div className="pdf-body">
			<div className="pdf-header">
				<div className="pdf-header__text">{L.translate('DepositFiat.PDFFileDownload.invoice')}</div>
				<div className="pdf-header__container">
					<img src={logo} className="pdf-header__logo" alt="logo" />
					<div className="pdf-header__container__text">
						{L.translate('DepositFiat.PDFFileDownload.bitanica')}
					</div>
				</div>
			</div>
			<div className="pdf-main">
				<div className="pdf-main__top-section">
					<div className="pdf-main__top-section--left-item">
						<div className="pdf-main__top-section--left-item__text--disable">
							{L.translate('DepositFiat.PDFFileDownload.indicated_amount')}
						</div>
						<div>
							{amount} {data?.asset_code.toUpperCase()}
						</div>
					</div>
					<div className="pdf-main__top-section--right-item">
						<div className="pdf-main__top-section--left-item__text--disable">
							{L.translate('DepositFiat.PDFFileDownload.date_of_issue')}
						</div>
						<div>{formattedDate}</div>
					</div>
				</div>
				<div className="pdf-main__top-section">
					<div className="pdf-main__top-section--left-item">
						<div className="pdf-main__top-section--left-item__text--disable">
							{L.translate('DepositFiat.PDFFileDownload.billet_to')}:
						</div>
						<div>
							{userData?.data.first_name && userData?.data.last_name
								? `${userData.data.first_name} ${userData.data.last_name}`
								: ''}
						</div>
					</div>
					<div className="pdf-main__bottom-section--right-items">
						<div className="pdf-main__bottom-section--right-items__container">
							<div className="pdf-main__bottom-section--right-items__container--left">
								{L.translate('DepositFiat.PDFFileDownload.beneficiary_bank')}:
							</div>
							<div className="pdf-main__bottom-section--right-items__container--right">
								{data?.beneficiary_bank}
							</div>
						</div>
						<div className="pdf-main__bottom-section--right-items__container">
							<div className="pdf-main__bottom-section--right-items__container--left">
								{L.translate('DepositFiat.PDFFileDownload.beneficiary_bank_address')}:
							</div>
							<div className="pdf-main__bottom-section--right-items__container--right">
								{data?.beneficiary_bank_address}
							</div>
						</div>
						<div className="pdf-main__bottom-section--right-items__container">
							<div className="pdf-main__bottom-section--right-items__container--left">
								{L.translate('DepositFiat.PDFFileDownload.swift')}
							</div>
							<div className="pdf-main__bottom-section--right-items__container--right">
								{data?.swift}
							</div>
						</div>
						<div className="pdf-main__bottom-section--right-items__container">
							<div className="pdf-main__bottom-section--right-items__container--left">
								{L.translate('DepositFiat.PDFFileDownload.beneficiary')}
							</div>
							<div className="pdf-main__bottom-section--right-items__container--right">
								{data?.beneficiary_name}
							</div>
						</div>
						<div className="pdf-main__bottom-section--right-items__container">
							<div className="pdf-main__bottom-section--right-items__container--left">
								{L.translate('DepositFiat.PDFFileDownload.beneficiary_iban')}
							</div>
							<div className="pdf-main__bottom-section--right-items__container--right">
								{data?.iban}
							</div>
						</div>
						<div className="pdf-main__bottom-section--right-items__container">
							<div className="pdf-main__bottom-section--right-items__container--left">
								{L.translate('DepositFiat.PDFFileDownload.reference')}
							</div>
							<div className="pdf-main__bottom-section--right-items__container--right">
								{reference}
							</div>
						</div>
					</div>
				</div>
			</div>
			{showVerify && (
				<div style={{ padding: 25 }}>
					<VerifyLabel isPdf />
				</div>
			)}
			<div className="pdf-footer">
				<div className="pdf-footer__string pdf-footer__string--disable">
					<div>{L.translate('DepositFiat.PDFFileDownload.correspondent_bank')}</div>
					<div>{L.translate('DepositFiat.PDFFileDownload.amount')}</div>
				</div>
				<div className="pdf-footer__string">
					<div>{L.translate('DepositFiat.PDFFileDownload.intermediary_bank')}:</div>
					<div>
						{amount} {data?.asset_code.toUpperCase()}
					</div>
				</div>
				<div className="pdf-footer__string pdf-footer__string--disable">
					<div>{correspondentBank}</div>
				</div>
			</div>
		</div>
	);
};

export default PDFFileDownload;
