import { FC, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSpotOrderBookIsLoad } from 'redux/reducers/spotTrade/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import Loader from 'ui/Loader';
import OrderBookTableItem from '../OrderBookTableItem';
import { IOrderBookTableProps } from './types';

const OrderBookTable: FC<IOrderBookTableProps> = ({ data, type }) => {
	const [dataSize, setDataSize] = useState(0);
	const orderBookListIsLoad = useSelector(getSpotOrderBookIsLoad);
	const currentPair = useSelector(getCurrentPair);
	const rootRef = useRef<HTMLDivElement | null>(null);
	console.log(type);

	useEffect(() => {
		if (
			type === 'ask' &&
			rootRef.current &&
			rootRef.current?.scrollHeight &&
			data?.length &&
			data?.length > dataSize
		) {
			rootRef.current.scrollTo(0, rootRef.current.scrollHeight * 1000);
			setDataSize(data.length);
		}
	}, [type, rootRef.current?.scrollHeight, dataSize, data?.length]);

	useEffect(() => {
		setDataSize(0);
	}, [currentPair]);

	return (
		<div
			ref={rootRef}
			className={`table-body ${type === 'ask' ? 'orderbook-ask-table-body' : ''} ${
				type === 'bid' ? 'orderbook-bid-table-body' : ''
			}`}
		>
			{data?.length &&
				!orderBookListIsLoad &&
				data.map((el) => (
					<OrderBookTableItem key={Number(el.price) + Number(el.total)} data={el} type={type} />
				))}

			{!data?.length && orderBookListIsLoad && (
				<div className="orderbook-loader-wrapper">
					<Loader />
				</div>
			)}
		</div>
	);
};

export default OrderBookTable;
