import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ICardsStore } from './types';

// ==========================================:
const getCardsState = (state: IStoreState): ICardsStore => state.cards;

export const getCards = createSelector([getCardsState], (cards: ICardsStore) => cards);

export const getCardList = createSelector([getCardsState], (cards: ICardsStore) => cards.cards);

export const getResponseCard = createSelector(
	[getCardsState],
	(cards: ICardsStore) => cards.responseData,
);

export const getDepositFee = createSelector(
	[getCardsState],
	(cards: ICardsStore) => cards.depositFee,
);

export const getWithdrawalFee = createSelector(
	[getCardsState],
	(cards: ICardsStore) => cards.withdrawalFee,
);

export const getCardStatus = createSelector(
	[getCardsState],
	(cards: ICardsStore) => cards.isVerified,
);

export const getVerifyLoading = createSelector(
	[getCardsState],
	(cards: ICardsStore) => cards.verifyLoading,
);
