import { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import L from 'i18n-react';
import { getBankTransferDepositRequest } from 'redux/reducers/payments/reducer';
import { getBankDepositTransfer } from 'redux/reducers/payments/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import InfoIcon from 'ui/component-icons/InfoIcon';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import VerifyLabel from '../VerifyLabel';
import PDFFileDownload from '../PDFFileDownload/PDFFileDownload';
import { IDepositModalProps } from '../FormCard/types';
/* eslint-disable */

export const DepositPopup: FC<IDepositModalProps> = ({
	openModal,
	closeModal,
	title,
	selectWallet,
	amount,
	fee,
	showVerify,
	bankAccount,
}) => {
	const userData = useSelector(getUserSettingsData);
	const walletsList = useSelector(getWalletsList);
	const dispatch = useDispatch();

	const recive = amount && Number(amount) - Number(fee);

	useEffect(() => {
		if (openModal) {
			const assetCode = selectWallet?.asset.code;
			const fiatCode = assetCode?.slice(0, assetCode.length - 1);
			const fiatWallet = walletsList?.find((w) => w.asset.code === fiatCode);
			const fiatAssetId = fiatWallet?.asset.id;
			const payload = {
				params: {
					asset_id: String(fiatAssetId),
				},
			};
			dispatch(getBankTransferDepositRequest(payload));
		}
	}, [dispatch, openModal, selectWallet]);

	const handleDownload = async () => {
		const input = document.getElementById('divToPrint');
		if (input) {
			await html2canvas(input).then((canvas) => {
				const imgData: string = canvas.toDataURL('image/jpeg');
				const pdf: jsPDF = new jsPDF();
				pdf.addImage(imgData, 'JPEG', 0, 0, 210, 210);
				// pdf.output('dataurlnewwindow');
				pdf.save('deposit.pdf');
			});
		}
	};

	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--width-560">
						<button type="button" onClick={closeModal} className="popup-close">
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<p className="popup-header__title">{title}</p>
						</div>
						<div className="popup-body popup-body--type2">
							<div className="withdrawal-options ">
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.DepositPopup.indicated_amount')}
									</span>
									<span className="withdrawal-option__desc">
										{amount} {bankAccount?.asset_code.toUpperCase()}
									</span>
								</div>
							</div>
							{showVerify && <VerifyLabel />}
							<div className="withdrawal-option-group">
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.DepositPopup.fee')}
									</span>
									<span className="withdrawal-option__desc">
										{fee} {bankAccount?.asset_code.toUpperCase()}
									</span>
								</div>
							</div>
							<div className="withdrawal-options ">
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.DepositPopup.beneficiary_bank')}
									</span>
									<span className="withdrawal-option__desc">{bankAccount?.beneficiary_bank}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.DepositPopup.beneficiary_bank_address')}
									</span>
									<span className="withdrawal-option__desc">
										{bankAccount?.beneficiary_bank_address}
									</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.DepositPopup.swift')}
									</span>
									<span className="withdrawal-option__desc">{bankAccount?.swift}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.DepositPopup.beneficiary')}
									</span>
									<span className="withdrawal-option__desc">{bankAccount?.beneficiary_name}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.DepositPopup.beneficiary_iban')}
									</span>
									<span className="withdrawal-option__desc">{bankAccount?.iban}</span>
								</div>
								<div className="withdrawal-option">
									<div className="withdrawal-option__title-wrap">
										<span className="withdrawal-option__title">
											{L.translate('DepositFiat.DepositPopup.reference')}
										</span>
										<span className="tooltip-item ">
											<span className="tooltip-item__icon">
												<InfoIcon color="#F44336" />
											</span>
											<span className="tooltip tooltip--width-320 tooltip--bottom tooltip--center ">
												<span className="tooltip__text">
													<span>{L.translate('DepositFiat.DepositPopup.reference_tooltip')}</span>
												</span>
											</span>
										</span>
									</div>
									<span className="withdrawal-option__desc">{userData?.payment_reference}</span>
								</div>
								<div className="withdrawal-option active">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.DepositPopup.correspondent_bank')}
									</span>
									<div className="withdrawal-option__extra">
										<span className="withdrawal-option__desc">
											{bankAccount?.correspondent_bank}
										</span>
									</div>
								</div>
							</div>

							<div className="withdrawal-option-group">
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">
										{L.translate('DepositFiat.DepositPopup.you_receive')}
									</span>
									<span className="withdrawal-option__desc withdrawal-option__desc--bigger">
										{recive || ''} {bankAccount?.asset_code.toUpperCase()}b
									</span>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--type2">
							<button
								type="button"
								onClick={handleDownload}
								className="button button--check button--br-12 button--size-60 button--full-width "
							>
								{L.translate('DepositFiat.DepositPopup.downloads')}
							</button>
						</div>
					</div>
				</div>
				<div id="divToPrint" style={{ position: 'fixed', left: '-10000' }}>
					<PDFFileDownload
						amount={amount}
						data={bankAccount}
						reference={userData?.payment_reference}
						correspondentBank={bankAccount?.correspondent_bank}
						showVerify={showVerify}
					/>
				</div>
			</div>
		</Popup>
	);
};
