import { FC } from 'react';
import L from 'i18n-react';
import { IVerifyLabel } from './types';

const VerifyLabel: FC<IVerifyLabel> = ({ isPdf }) => {
	return (
		<div className="operation-alert-block operation-alert-block--mt-type2">
			<div
				className="operation-alert operation-alert--type2"
				style={isPdf ? { paddingTop: 4 } : {}}
			>
				<div className="operation-alert__header operation-alert__header--small-text">
					<div className="operation-alert__header-icon" style={isPdf ? { paddingTop: 13 } : {}}>
						<svg
							width="23"
							height="20"
							viewBox="0 0 23 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M3.35715 19.8849H19.5791C21.3582 19.8849 22.4362 18.6499 22.4362 17.0487C22.4362 16.5568 22.2896 16.0439 22.0281 15.5835L13.9066 1.43381C13.3624 0.481423 12.431 0 11.4681 0C10.5052 0 9.5633 0.481423 9.02958 1.43381L0.908164 15.5835C0.625589 16.0545 0.5 16.5568 0.5 17.0487C0.5 18.6499 1.57797 19.8849 3.35715 19.8849ZM3.36761 18.2418C2.63501 18.2418 2.19545 17.6767 2.19545 17.0382C2.19545 16.8394 2.23731 16.5882 2.35244 16.3684L10.4633 2.2292C10.6831 1.84197 11.0809 1.67452 11.4681 1.67452C11.8553 1.67452 12.2425 1.84197 12.4623 2.2292L20.5733 16.3788C20.6884 16.5986 20.7408 16.8394 20.7408 17.0382C20.7408 17.6767 20.2802 18.2418 19.5581 18.2418H3.36761Z"
								fill="#F9A000"
							/>
							<path
								d="M11.4682 12.831C11.9706 12.831 12.2636 12.538 12.2741 11.9938L12.4206 6.47833C12.4311 5.94457 12.0124 5.54688 11.4577 5.54688C10.8926 5.54688 10.4949 5.9341 10.5053 6.46786L10.6414 11.9938C10.6519 12.5275 10.9449 12.831 11.4682 12.831ZM11.4682 16.2324C12.0752 16.2324 12.5985 15.751 12.5985 15.1439C12.5985 14.5265 12.0857 14.0555 11.4682 14.0555C10.8507 14.0555 10.3379 14.5369 10.3379 15.1439C10.3379 15.7405 10.8612 16.2324 11.4682 16.2324Z"
								fill="#F9A000"
							/>
						</svg>
					</div>
					<p style={isPdf ? { fontWeight: 400, fontSize: 13 } : {}}>
						{L.translate('DepositFiat.DepositPopup.verifyText', { opn: '[', cls: ']' })}
					</p>
				</div>
			</div>
		</div>
	);
};

export default VerifyLabel;
