import React from 'react';
import Auth from 'layouts/Auth';
import L from 'i18n-react';
import { APP_NAME } from 'services/constants/env';

const CookiePolicy: React.FC = () => {
	const linkTarget = (
		<a href={`${window.location.origin}/storage/docs/aml.pdf`} target="_blank" rel="noreferrer">
			{String(L.translate('CookiePolicy.par_con_2_AML'))}
		</a>
	);
	const mailTO = <a href="mailto:support@bitanica.com">support@bitanica.com.</a>;
	return (
		<Auth
			title={`${String(APP_NAME)}.com | ${String(L.translate('PageTitles.cookie_policy'))}`}
			description={String(L.translate('MetaTags.Description.cookie_policy'))}
		>
			<div className="content-wrapper content-wrapper--padding-all ">
				<div className="container">
					<div className="page-info">
						<h1 className="page-info__title">{String(L.translate('CookiePolicy.title'))}</h1>
					</div>
					<div className="static-content">
						<h2>{String(L.translate('CookiePolicy.paragraph_1'))}</h2>
						<p>{String(L.translate('CookiePolicy.par_content_1'))}</p>
						<p>{String(L.translate('CookiePolicy.par_content_1_1'))}</p>
						<h2>{String(L.translate('CookiePolicy.paragraph_2'))}</h2>

						<p>{String(L.translate('CookiePolicy.par_con_2_1'))}</p>
						<p>{String(L.translate('CookiePolicy.par_con_2_2'))}</p>
						<p>{String(L.translate('CookiePolicy.par_con_2_3'))}</p>
						<p>{String(L.translate('CookiePolicy.par_con_2_4'))}</p>
						<p>{String(L.translate('CookiePolicy.par_con_2_5'))}</p>

						<h2>{String(L.translate('CookiePolicy.paragraph_3'))}</h2>
						<p>{String(L.translate('CookiePolicy.par_content_3'))}</p>
						<p>{String(L.translate('CookiePolicy.par_content_31'))}</p>
						<p>{String(L.translate('CookiePolicy.par_content_32'))}</p>
						<p>{String(L.translate('CookiePolicy.par_content_33'))}</p>
						<h2>{String(L.translate('CookiePolicy.paragraph_4'))}</h2>
					</div>
				</div>
			</div>
		</Auth>
	);
};

export default CookiePolicy;
