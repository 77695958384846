import {
	IReferralResponsePayload,
	IBalanceResponsePayload,
	IReferralRequestPayload,
} from 'redux/reducers/referral/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IReferralApi } from './types';

// ==========================================:
export const referral: IReferralApi = {
	getReferralStatistic: () =>
		http
			.get<IReferralResponsePayload>(endpoint.referral.STATISTICS)
			.then((response) => response.data),
	getReferralBalance: () =>
		http.get<IBalanceResponsePayload>(endpoint.referral.BALANCE).then((response) => response.data),
	getReferralClaim: (payload) =>
		http
			.post<string, any>(endpoint.referral.TRANSFER, { asset_id: payload })
			.then((response) => response.data),
};
