/* eslint-disable react/no-array-index-key */
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getCryptoFees, getLoading } from 'redux/reducers/ourPrices/selectors';
import { HIDE_VIP } from 'components/OurPrices/constants';
import TableRow from './TableRow';

const CryptoTable = () => {
	const loading = useSelector(getLoading);
	const cryptoFees = useSelector(getCryptoFees);

	return (
		<div
			className={`table table--prices-type table--prices-crypto ${
				HIDE_VIP ? 'table--prices-crypto--hide-vip' : ''
			}`}
		>
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.currency')}</p>
					</div>
					<div className="td">
						<p className="td-title">{L.translate('OurPrices.Columns.deposit_fixed_amount')}</p>
					</div>
					<div className={`td ${HIDE_VIP ? 'td--right' : ''}`}>
						<p className="td-title">{L.translate('OurPrices.Columns.withdrawal_fixed_amount')}</p>
					</div>
					{!HIDE_VIP && (
						<>
							<div className="td">
								<p className="td-title">{L.translate('OurPrices.Columns.vip_deposit')}</p>
							</div>
							<div className="td td--right">
								<p className="td-title">{L.translate('OurPrices.Columns.vip_withdrawal')}</p>
							</div>
						</>
					)}
				</div>
			</div>
			<div className="table-body">
				{cryptoFees?.map((item, idx) => (
					<TableRow key={idx} item={item} />
				))}
			</div>
		</div>
	);
};

export default CryptoTable;
