export const cardHideNumber = (value: string, first: number, second: number) => {
	const trimSpace = value.replace(/\s/g, '');
	return `**** **** **** ${trimSpace.slice(first, second)}`;
};
export const tryOnEmpty = (data: any) => {
	const newArray: any = Object.entries(data).filter((item: any) => item[1] !== '');

	const newPayload = newArray.reduce((acc: any, obj: any) => {
		const [one, two] = obj;
		acc[one] = two;
		return acc;
	}, {});

	return newPayload;
};
