import L from 'i18n-react';
import ConvertHistory from 'components/TransatcionsHistory/ConvertHistory';
import DepositWithdrawalFiatHistory from 'components/TransatcionsHistory/DepositWithdrawalFiatHistory';
import TransferHistory from 'components/TransatcionsHistory/TransferHistory';
import StablecoinHistory from 'components/TransatcionsHistory/StablecoinHistory';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import CryptoHistory from './CryptoHistory';

const TransatcionsHistory: FC = () => {
	const [currentHistoryType, setCurrentHistoryType] = useState('crypto');
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (location.pathname.includes('convert')) {
			setCurrentHistoryType('convert');
		}
		if (location.pathname.includes('fiat')) {
			setCurrentHistoryType('fiat');
		}
	}, [location]);

	const backClick = () => {
		history.goBack();
	};

	const handleCurrentHistoryType = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setCurrentHistoryType(name);
		if (location.pathname.includes('fiat') || location.pathname.includes('convert')) {
			history.replace('/transaction-history');
		}
	};

	return (
		<div className="content-block content-block--flex content-block--padding-none content-block--border content-block--min-height">
			<div className="create-offer create-offer--transactions">
				<div className="create-offer__header">
					<button type="button" onClick={backClick} className="back-step">
						<div className="back-step__img">
							<span className="back-step__arrow icon-arrow" />
						</div>
						<span className="back-step__text">
							{String(L.translate('Menu.Wallet.transaction_history'))}
						</span>
					</button>
					<div className="transactions">
						<div className="transactions__tags">
							<button
								className={`transactions__tags-item ${
									currentHistoryType === 'crypto' ? 'active' : ''
								}`}
								type="button"
								name="crypto"
								onClick={handleCurrentHistoryType}
							>
								<span>{String(L.translate('TransactionHistory.crypto'))}</span>
							</button>
							{/* <button
								className={`transactions__tags-item ${
									currentHistoryType === 'fiat' ? 'active' : ''
								}`}
								type="button"
								name="fiat"
								onClick={handleCurrentHistoryType}
							>
								<span>{String(L.translate('TransactionHistory.fiat'))}</span>
							</button> */}
							<button
								className={`transactions__tags-item ${
									currentHistoryType === 'stablecoin' ? 'active' : ''
								}`}
								type="button"
								name="stablecoin"
								onClick={handleCurrentHistoryType}
							>
								<span>{String(L.translate('TransactionHistory.fiat'))}</span>
							</button>
							<button
								className={`transactions__tags-item ${
									currentHistoryType === 'transfer' ? 'active' : ''
								}`}
								type="button"
								name="transfer"
								onClick={handleCurrentHistoryType}
							>
								<span>{String(L.translate('TransactionHistory.transfer'))}</span>
							</button>
							<button
								className={`transactions__tags-item ${
									currentHistoryType === 'convert' ? 'active' : ''
								}`}
								type="button"
								name="convert"
								onClick={handleCurrentHistoryType}
							>
								<span>{String(L.translate('TransactionHistory.convert'))}</span>
							</button>
						</div>
					</div>
				</div>
				{currentHistoryType === 'crypto' && <CryptoHistory />}
				{/* {currentHistoryType === 'fiat' && <DepositWithdrawalFiatHistory />} */}
				{currentHistoryType === 'stablecoin' && <StablecoinHistory />}
				{currentHistoryType === 'transfer' && <TransferHistory />}
				{currentHistoryType === 'convert' && <ConvertHistory />}
			</div>
		</div>
	);
};

export default TransatcionsHistory;
