export interface IPaymentsStore {
	urlPayPal: string;
	paymentsList: string[];
	paymentsListLoader: boolean;
	accountBank: null | Array<IBankAccount>;
	dataBankTransferDeposit: null | IGetBankTransferResponse;
	accountData: null | IPostBankTransferResponse;
	fiatLimitations: TFiatLimitations | null;
	postPayPalLoading: boolean;
}

export enum EPaymentsListType {
	DEPOSIT = 'deposit',
	WITHDRAWAL = 'withdrawal',
}

export interface IGetPaymentsListPayload {
	code: string;
	type: EPaymentsListType;
}

export interface IPostPaymentsPayload {
	amount: string;
	asset_id: number;
}

export interface IPostBankAccountPayload {
	asset_id?: string;
	id?: string;
	bank_name: string;
	beneficiary_name: string;
	iban: string;
	address?: string;
	swift?: string;
	reference: string;
}
export interface IPostBankAccountRequest {
	payload: IPostBankAccountPayload;
	onError: () => void;
	onSuccess: () => void;
}

export type TBankAccountStatus = 'verified' | 'unverified';

export interface IBankAccount {
	id: number;
	currency_name?: string;
	currency_code?: string;
	beneficiary_name?: string;
	bank_name?: string;
	swift?: string;
	iban?: string;
	reference?: string;
	address?: string;
	status?: TBankAccountStatus;
	bank_icon?: string | null;
}
export type IBankAccountResponse = Array<IBankAccount>;

export interface IDeleteBankAccountRequest {
	id: string;
	onSuccess: (value?: string) => void;
	onError: () => void;
	type?: string;
}

export interface IGetBankTransferRequest {
	params: IGetBankTransferPayload;
}

export interface IGetBankTransferPayload {
	asset_id: string;
}

export interface IBankPlatform {
	id: number;
	asset_id: number;
	beneficiary_name: string;
	beneficiary_bank: string;
	beneficiary_bank_address: string;
	swift: string;
	iban: string;
	correspondent_bank: string;
	asset_code: string;
	is_partner: 0 | 1;
	bank_icon?: string | null;
}

export type IGetBankTransferResponse = IBankPlatform[];

export interface IGetPaymentBankAccount {
	amount: number;
	asset_id: number;
	bank_account_id: number;
	email_code?: string;
	totp?: string;
	sms_code?: string;
}

export interface IGetPaymentBankAccountRequest {
	params: IGetPaymentBankAccount;
	onError?: (error: any) => void;
	onSuccess?: () => void;
}

export interface IGetPaymentBankAccountWithdrawalResponse {
	user_id: number;
	asset_id: number;
	asset: { code: string };
	amount: number;
	payment_system: string;
	status: string;
	type: string;
	bank_account_id: number;
	updated_at: number;
	created_at: number;
	id: number;
	indicated_amount: number;
	total_amount: number;
	amount_fee: number;
}

export interface IPostBankTransferResponse {
	id: number;
	beneficiary_name: string;
	swift: string;
	iban: string;
	currency_name: string;
	currency_code: string;
	bank_name: string;
	reference: string;
	address: string;
}

export interface IPostPayPalDepositParams {
	amount: string;
	asset_id: string;
	key: string;
}

export interface IPostPayPalDepositRequset {
	params: IPostPayPalDepositParams;
	onSuccess?: (key: string) => void;
	onError?: () => void;
}

export interface IPostPayPalDepositResponse {
	url: { ready: string };
	id: number;
	asset_code: string;
	amount: number;
	fee: number;
}

export type TFiatLimitations = IFiatLimitationsItem[];

export interface IFiatLimitationsItem {
	asset_code: string;
	channel_name: string;
	deposit_min: number;
	deposit_max: number;
	vip_deposit_min: number;
	vip_deposit_max: number;
	withdraw_min: number;
	withdraw_max: number;
	vip_withdraw_min: number;
	vip_withdraw_max: number;
	deposit_no_limit: number;
	withdraw_no_limit: number;
	vip_deposit_no_limit: number;
	vip_withdraw_no_limit: number;
}
