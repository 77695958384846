import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import L from 'i18n-react';
import { closeConvertModal } from 'redux/reducers/convert/reducer';
import { getConvertResponseData } from 'redux/reducers/convert/selectors';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { dataLayer } from 'services/gtm';

const ConvertConfirmPopup = () => {
	const convertData = useSelector(getConvertResponseData);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleCloseModal = () => {
		dispatch(closeConvertModal());
	};

	const handleCheckStatus = () => {
		dataLayer.push({
			event: 'view_status',
		});
		dispatch(closeConvertModal());
		history.push('/transaction-history/convert');
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--medium ">
					<button className="popup-close" type="button" onClick={handleCloseModal}>
						<span className="popup-close__icon icon-close" />
					</button>
					<div className="popup-header">
						<div className="popup-icon">
							<svg
								width="25"
								height="19"
								viewBox="0 0 25 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M22.5 2.5L8.75 16.25L2.5 10"
									stroke="white"
									strokeWidth="4"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<p className="popup-header__title ">{String(L.translate('ConvertForm.successful'))}</p>
					</div>
					<div className="popup-body">
						<div className="spent-value spent-value--ta-center">
							<p className="spent-value__text">
								{String(L.translate('ConvertForm.you_will_spend'))}
							</p>
							<p className="spent-value__item">
								{convertData?.exchange.quantity} {convertData?.exchange.from_asset.toUpperCase()}
							</p>
						</div>
						<div className="withdrawal-option-group">
							<div className="withdrawal-option ">
								<span className="withdrawal-option__title">
									{String(L.translate('ConvertForm.converted'))}
								</span>
								<span className="withdrawal-option__desc">
									{convertData?.exchange.amount} {convertData?.exchange.to_asset.toUpperCase()}
								</span>
							</div>
							<div className="withdrawal-option ">
								<span className="withdrawal-option__title">
									{String(L.translate('ConvertForm.transaction_fee'))}
								</span>
								<span className="withdrawal-option__desc">
									{convertExponentialToDecimal(Number(convertData?.exchange.fee))}{' '}
									{convertData?.exchange.to_asset.toUpperCase()}
								</span>
							</div>
							<div className="withdrawal-option ">
								<span className="withdrawal-option__title">
									{String(L.translate('ConvertForm.price'))}
								</span>
								<span className="withdrawal-option__desc">
									1 {convertData?.exchange.from_asset.toUpperCase()} = {convertData?.exchange.rate}{' '}
									{convertData?.exchange.to_asset.toUpperCase()}
								</span>
							</div>
						</div>
					</div>
					<div className="popup-submit popup-submit--sb">
						<button
							className="button button--second-grey button--full-width"
							type="button"
							onClick={handleCloseModal}
						>
							{String(L.translate('ConvertForm.back_button'))}
						</button>
						<button className="button button--full-width" type="button" onClick={handleCheckStatus}>
							{String(L.translate('ConvertForm.view_status_button'))}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConvertConfirmPopup;
