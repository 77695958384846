import { FC, useState, MouseEvent } from 'react';
import L from 'i18n-react';

import BorrowTable from './BorrowTable';
import BorrowIsolatedTable from './BorrowIsolatedTable';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
// ==========================================:

const Borrowed: FC = () => {
	const [currentTab, setCurrentTab] = useState('cross');

	const tabs = {
		cross: <BorrowTable />,
		isolated: <BorrowIsolatedTable />,
	};
	const handleTab = (e: MouseEvent<HTMLButtonElement>) => {
		setCurrentTab(e.currentTarget.name);
	};

	return (
		<>
			<div className="main-content__header">
				<h2 className="section-title">{L.translate('Margin.Tables.Borrowing.title')}</h2>

				<div className="margin-switcher">
					<button
						name="cross"
						onClick={handleTab}
						className={`${
							currentTab === 'cross'
								? 'margin-switcher__btn margin-switcher__btn--active '
								: 'argin-switcher__btn'
						}`}
						type="button"
					>
						{L.translate('Margin.Cross.title')}
					</button>
					<button
						name="isolated"
						onClick={handleTab}
						className={`${
							currentTab === 'isolated'
								? 'margin-switcher__btn margin-switcher__btn--active'
								: 'margin-switcher__btn'
						}`}
						type="button"
					>
						{L.translate('Margin.Isolated.title')}
					</button>
				</div>
			</div>
			<div className="content-block content-block--min-height2">
				<div className="balance-section">
					<div className="balance-block balance-block--wallet-type">
						{tabs[currentTab as keyof typeof tabs]}
					</div>
				</div>
			</div>
		</>
	);
};

export default Borrowed;
