import { ChangeEvent, MouseEvent, FC, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getCurrentPair, getWorkspaceSettings } from 'redux/reducers/tradingSettings/selectors';
import { useHistory, useLocation } from 'react-router';
import AssetBar from './AssetBar';
import MarginBar from './MarginBar';
import PairsTable from './PairsTable';

const SpotPairs: FC = () => {
	const workspaceSettings = useSelector(getWorkspaceSettings);
	const [search, setSearch] = useState('');
	const [currentAssetTab, setCurrentAssetTab] = useState('all');
	const [selectDropdown, setSelectDropdown] = useState(false);
	const [marginPairsTab, setMarginPairsTab] = useState('all');
	const location = useLocation();
	const history = useHistory();
	const currentPair = useSelector(getCurrentPair);

	const transformCurrentPair = currentPair?.toUpperCase();

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setSearch(value);
	};
	const [star, changeStar] = useState(false);
	const changeStarFunc = () => {
		changeStar(!star);
	};
	const handleFilteredAssetPairs = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setCurrentAssetTab(name);
		setSelectDropdown(false);
		if (name === 'margin') {
			history.push(`/MarginTrading/${String(transformCurrentPair)}?type=cross`);
		}
	};

	const changeMarginPairsTabs = (e: MouseEvent<HTMLButtonElement>) => {
		setMarginPairsTab(e.currentTarget.name);
		setSearch('');
		if (e.currentTarget.name === 'cross') {
			history.push(`/MarginTrading/${String(transformCurrentPair)}?type=cross`);
		} else if (e.currentTarget.name === 'isolated') {
			history.push(`/MarginTrading/${String(transformCurrentPair)}?type=isolated`);
		}
	};

	useEffect(() => {
		if (location.search.includes('cross')) {
			setCurrentAssetTab('margin');
			setMarginPairsTab('cross');
		}
		if (location.search.includes('isolated')) {
			setCurrentAssetTab('margin');
			setMarginPairsTab('isolated');
		}
	}, [location, history, transformCurrentPair]);

	return (
		<div className="favorites-trades">
			<div className="search">
				<div className="input input--margin-small">
					<div className="input-wrapper">
						<input
							className="input-item input-item--small input-item--right-icon"
							type="text"
							placeholder={String(L.translate('Trade.Spot.SpotPairs.search_pair_placeholder_text'))}
							value={search}
							onChange={handleSearch}
						/>
						<button className="search-btn" type="button">
							<span className="search-btn__icon icon-search-normal" />
						</button>
					</div>
				</div>
			</div>
			<div className="favorites-trades__block">
				<AssetBar
					star={star}
					changeStar={changeStarFunc}
					currentAssetTab={currentAssetTab}
					handleFilteredAssetPairs={handleFilteredAssetPairs}
					selectDropdown={selectDropdown}
					setSelectDropdown={setSelectDropdown}
				/>
				{currentAssetTab === 'margin' && (
					<MarginBar
						marginPairsTab={marginPairsTab}
						changeMarginPairsTabs={changeMarginPairsTabs}
					/>
				)}

				<PairsTable
					star={star}
					workspaceSettings={workspaceSettings}
					searchData={search}
					currentAssetData={currentAssetTab}
					marginPairsTab={marginPairsTab}
				/>
			</div>
		</div>
	);
};

export default SpotPairs;
