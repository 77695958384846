import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import L from 'i18n-react';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import DCDropdown from 'ui/DCDropdown';
import { IWalletItem, IWalletNetworkItem } from 'redux/reducers/wallets/types';
import { ArrowRight } from 'assets/svg-icon';
import {
	getBankAccounts,
	getCurrencyPaymentsList,
	getCurrencyPaymentsListLoader,
	getBankTransfer,
} from 'redux/reducers/payments/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { clearWalletAddress } from 'redux/reducers/wallets/reducer';
import {
	IAddCardPayload,
	IFeeWithdrawalFiatRequest,
	IFeeWithdrawalCardFiatRequest,
} from 'redux/reducers/cards/types';
import {
	createCardWithdrawalRequest,
	verifyCardWithdrawalRequest,
} from 'redux/reducers/cards/reducer';
import { useDispatch, useSelector } from 'react-redux';

import { getCardList, getCardStatus, getWithdrawalFee } from 'redux/reducers/cards/selectors';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { calculateCryptoWithdrawAmountRequest } from 'redux/reducers/withdrawCrypto/reducer';
import { numberValidation } from 'services/utils/numberValidation';
import { isStablecoin } from 'services/utils/wallets';
import { dataLayer } from 'services/gtm';
import { getErrorMessage } from 'services/utils/errors';
import { emailCodeErrors, smsCodeErrors, totpErrors } from 'services/constants/2faErrors';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { ConfirmPopup } from 'components/CreateDepositFiat/Popups/ConfirmPopup';
import { CvcVerify } from 'components/CreateDepositFiat/Popups/CvcVerify';
import { ICardCheck } from 'components/CreateDepositFiat/types';
import {
	IPostBankAccountPayload,
	EPaymentsListType,
	IGetPaymentBankAccountRequest,
} from 'redux/reducers/payments/types';
import { AddCard } from 'components/CreateDepositFiat/AddCard/AddCard';
import { AddCard as AddAccount } from './CreateWithdrawStable/AddCard/AddCard';
import { SicurityVerify } from './CreateWithdrawStable/Popups/SicurityVerify';
import { IStateWithdrawLocation, IVerify } from './CreateWithdrawStable/types';
import Loader from '../../ui/Loader';
import {
	getCurrencyPaymentsListRequest,
	getBankTransferWithdrawalRequest,
} from '../../redux/reducers/payments/reducer';

import { getUserSettingsData } from '../../redux/reducers/settings/selectors';
import SelecetCurrencyPayment from './CreateWithdrawStable/SelecetCurrencyPayment/SelecetCurrencyPayment';
import CreateWithdrawCrypto from './CreateWithdrawCrypto';
import { ConfirmPopup as BankTransferConfirmPopup } from './CreateWithdrawStable/Popups/ConfirmPopup';
/* eslint-disable */
const CreateWithdrawFiat: FC = () => {
	const { state, search } = useLocation<IStateWithdrawLocation>();
	const history = useHistory();
	const bankAccountTransfer = useSelector(getBankTransfer);
	const cardList = useSelector(getCardList);
	const cards = cardList || [];

	const [selectWallet, setSelectWallet] = useState<IWalletItem | null>(
		state?.currentWallet || null,
	);
	const [selectedPayment, setSelectedPayment] = useState('');
	const [selectedPaymentVariantId, setSelectedPaymentVariantId] = useState('');
	const [selectedPaymentValue, setSelectedPaymentValue] = useState('');
	const [selectedPaymentError, setSelectedPaymentError] = useState('');
	const [amountField, setAmountField] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [showModalVerify, setShowModalVerify] = useState(false);
	const [cvc, setCvc] = useState('');
	const [chouse, setChouse] = useState(false);
	const [amount, setAmount] = useState('');
	const [disableButton, setDisableButton] = useState(false);
	const [disableVrify, setDisableVrify] = useState(false);
	const [amountErrorVisibility, setAmountErrorVisibility] = useState(false);
	const [limit, setLimit] = useState({ min: 10, max: 3000 });
	const [fee, setfee] = useState('');
	const [verify, setVerify] = useState<IVerify>({
		email: undefined,
		phone: undefined,
		google: undefined,
	});

	const recive = amount && Number(amount) - Number(fee);

	const [openModal, setOpenModal] = useState(false);
	const [activeView, setActiveView] = useState('currency');
	const closeModal = () => setOpenModal(false);
	const [checkCard, setCheckCard] = useState<ICardCheck | any>(null);
	const [valueSubmit, setValueSubmit] = useState<IAddCardPayload | any>({
		holder_name: '',
		bank_name: '',
		card_number: '',
		expiry_date: '',
		expiry_year: '',
		expiry_month: '',
		cvv: '',
		asset_id: '',
		amount: '',
	});
	const [accountSubmit, setAccountSubmit] = useState<IPostBankAccountPayload | any>({
		asset_id: '',
		bank_name: '',
		beneficiary_name: '',
		reference: '',
		iban: '',
		address: '',
		swift: '',
	});

	const dispatch = useDispatch();

	const userData = useSelector(getUserSettingsData);
	const walletsList = useSelector(getWalletsList);
	const list = useSelector(getBankAccounts);
	const paymentsList = useSelector(getCurrencyPaymentsList);
	const paymentsListLoading = useSelector(getCurrencyPaymentsListLoader);
	const cardStatus = useSelector(getCardStatus);

	const [selectWalletNetworks, setSelectWalletNetworks] = useState<IWalletNetworkItem | null>(null);
	const [walletAddressTo, setWalletAddressTo] = useState('');

	const [emailCodeIsError, setEmailCodeIsError] = useState(false);
	const [smsCodeIsError, setSmsCodeIsError] = useState(false);
	const [totpCodeIsError, setTotpCodeIsError] = useState(false);

	const withdrawMin = userData?.is_vip
		? selectWallet?.asset?.vip_withdraw_min
		: selectWallet?.asset?.withdraw_min;
	const withdrawMax = userData?.is_vip
		? selectWallet?.asset?.vip_withdraw_max
		: selectWallet?.asset?.withdraw_max;

	const assetCode = selectWallet?.asset.code;
	const fiatCode = assetCode?.endsWith('b') ? assetCode.slice(0, assetCode.length - 1) : assetCode;
	const fiatId = walletsList?.find((w) => w.asset.code === fiatCode)?.asset?.id;

	useEffect(() => {
		dispatch(userSettingsRequest());
	}, []);

	useEffect(() => {
		if (selectWallet && isStablecoin(selectWallet) && fiatCode) {
			dispatch(
				getCurrencyPaymentsListRequest({
					code: fiatCode,
					type: EPaymentsListType.WITHDRAWAL,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectWallet, fiatCode]);

	useEffect(() => {
		if (search) {
			const currencySearch = new URLSearchParams(search).get('currency');

			if (currencySearch && walletsList) {
				const currentCurrency = walletsList.find(
					(item) => item.asset.code === String(currencySearch),
				);
				setSelectWallet(currentCurrency || null);

				// setSelectedPayment('');
				// debugger;
				dispatch(clearWalletAddress());
				// dispatch(
				// 	getCurrencyPaymentsListRequest({
				// 		code: String(currentCurrency?.asset.code),
				// 		type: EPaymentsListType.WITHDRAWAL,
				// 	}),
				// );
			}
			const typeSearch = new URLSearchParams(search).get('type');
			if (typeSearch) {
				setSelectedPayment(typeSearch === 'card' ? 'cartubank' : typeSearch);
			}
			const cardSearch = new URLSearchParams(search).get('card_id');
			if (cardSearch && cards) {
				const currentCard = cards.find((item) => String(item.id) === String(cardSearch));
				setCheckCard(currentCard);
			}
		}
	}, [search, walletsList]);

	const backFromChooseBank = () => {
		if (checkCard) {
			setActiveView('currency');
			return;
		}
		setSelectedPayment('');
		setTimeout(() => {
			setActiveView('currency');
		}, 100);
	};

	const handleWalletSelect = (value: IWalletItem): void => {
		dataLayer.push({
			event: isStablecoin(value) ? 'select_currency' : 'select_coin',
		});
		setSelectWallet(value);
		history.push(`/withdraw?currency=${value?.asset?.code}`);
		setSelectedPayment && setSelectedPayment('');
		setSelectWalletNetworks(null);
		setWalletAddressTo('');
		// dispatch(clearWalletAddress());
		// dispatch(
		// 	getCurrencyPaymentsListRequest({
		// 		code: value.asset.code,
		// 		type: EPaymentsListType.WITHDRAWAL,
		// 	}),
		// );
	};

	const handlePaymentSelect = (e: ChangeEvent<HTMLInputElement>): void => {
		setSelectedPayment(e.target.id);
		setSelectedPaymentVariantId('');
		setSelectedPaymentValue('');
	};

	const handleChangePaymentVariantId = (el: string) => {
		setSelectedPaymentVariantId(el);
		setSelectedPaymentValue('');
		setSelectedPaymentError('');
	};

	const handleSelectedPaymentValueChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.target;
		const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
		const emailTest = emailRegex.test(value);
		const paypalIdRegex = /^[\w\d]{13}$/g;
		const paypalIdTest = paypalIdRegex.test(value);

		if (!emailTest && selectedPaymentVariantId.toLowerCase() === 'email') {
			setSelectedPaymentError(String(L.translate('WithdrawFiat.withdraw_fiat_page_title')));
		} else if (emailTest && selectedPaymentVariantId.toLowerCase() === 'email') {
			setSelectedPaymentError('');
		}
		if (!paypalIdTest && selectedPaymentVariantId.toLowerCase() === 'paypal id') {
			setSelectedPaymentError(String(L.translate('WithdrawFiat.withdraw_fiat_page_title')));
		} else if (paypalIdTest && selectedPaymentVariantId.toLowerCase() === 'paypal id') {
			setSelectedPaymentError('');
		}

		setSelectedPaymentValue(value);
	};
	const handleSelectedPaymentValuePhoneChange = (value: string): void => {
		setSelectedPaymentValue(value);
	};

	useEffect(() => {
		const cardSearch = new URLSearchParams(search).get('card_id');
		if (cardList?.length && !chouse && !cardSearch && selectedPayment === 'cartubank') {
			setCheckCard(cardList[0]);
			setValueSubmit({
				...valueSubmit,
				holder_name: cardList[0].holder_name,
				bank_name: cardList[0].bank_name,
				card_number: String(cardList[0].card_number),
				id: cardList[0]?.id,
			});
		}
	}, [cardList, chouse, search]);

	useEffect(() => {
		if (selectedPayment === 'bank_transfer' && !checkCard) {
			setActiveView('addCard');
		}
	}, [selectedPayment]);

	useEffect(() => {
		if (openModal) {
			setEmailCodeIsError(false);
			setSmsCodeIsError(false);
			setTotpCodeIsError(false);
		}
	}, [openModal]);

	const handleCheckCard = (value: ICardCheck | null) => {
		setCheckCard(value);
		if (value && selectedPayment === 'cartubank') {
			history.push(
				`/withdraw?currency=${selectWallet?.asset?.code}${'&type=card'}${
					checkCard.id ? `&card_id=${value.id}` : ''
				}`,
			);
		}
	};

	const handleSubmit = () => {
		if (!amountField) return;
		const params: IFeeWithdrawalFiatRequest = {
			payload: {
				asset_id: String(fiatId),
				amount: Number(amountField),
				card_id: String(checkCard?.id),
				email_code: verify.email ? verify.email?.trim() : undefined,
				totp: verify.google ? verify.google?.trim() : undefined,
				sms_code: verify.phone ? verify.phone?.trim() : undefined,
				asset_code: `${fiatCode}b`,
			},
			onSuccess: (url?: string) => {
				setShowModal(false);
				setDisableVrify(false);
				// setActiveView('iframe');
				// if ((typeof url === 'string' && url !== 'undefined') || url === undefined) {
				// 	window.open(url);
				// } else {
				// 	history.push(`/transaction-history/fiat`);
				// }
				history.push(`/transaction-history/fiat`);
				// setFrame(url);
			},
			onError: (error) => {
				setDisableVrify(false);
				const errorMessage = getErrorMessage(error);
				if (!errorMessage) {
					return;
				}
				if (emailCodeErrors.includes(errorMessage)) {
					setEmailCodeIsError(true);
					return;
				}
				if (smsCodeErrors.includes(errorMessage)) {
					setSmsCodeIsError(true);
					return;
				}
				if (totpErrors.includes(errorMessage)) {
					setTotpCodeIsError(true);
					return;
				}
			},
			type: checkCard?.holder_name === 'online' ? 'online' : 'card',
		};
		if (valueSubmit) {
			setDisableVrify(true);
			dataLayer.push({
				event: 'withdraw_fiat_confirm',
			});
			dispatch(createCardWithdrawalRequest(params));
		}
	};

	const handleSubmitCard = () => {
		if (!amountField) return;
		const params: IFeeWithdrawalCardFiatRequest = {
			payload: {
				asset_id: String(fiatId),
				card_id: String(checkCard?.id),
				cvc,
			},
			onSuccess: (url: string) => {
				// setShowModal(false);

				window.open(url);

				// history.push(`/transaction-history`);
			},
			onError: () => {
				// history.push(`/deposit-failed`);
			},
		};
		dispatch(verifyCardWithdrawalRequest(params));
	};

	const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setAmountErrorVisibility(true);
		if (!value) {
			setAmountErrorVisibility(false);
		}
		if (numberValidation(value)) {
			setAmount(value);
		}
	};

	const handleClickWithdrawalMax = () => {
		if (withdrawMax && selectWallet?.balance) {
			setAmount(
				String(
					Number(selectWallet?.balance) > Number(withdrawMax) ? withdrawMax : selectWallet?.balance,
				),
			);
		}
	};

	const getReceiveAmount = () => {
		return convertExponentialToDecimal(Number(amount));
	};

	const isAmountMoreBalance = Number(selectWallet?.balance) < Number(amount);
	const isAmountLessWithdrawMin = Number(withdrawMin) > Number(amount);

	const withdrawIsDisable = !(
		amount &&
		selectWallet &&
		selectedPaymentVariantId &&
		selectedPaymentValue &&
		Number(amount) <= Number(selectWallet?.balance) &&
		Number(amount) >= (withdrawMin ?? 0) &&
		Number(amount) <= (withdrawMax ?? 0)
	);

	const changeAmountValue = (e: ChangeEvent<HTMLInputElement>) => {
		setAmount(e.target.value);
		// setAmountField && setAmountField(e.target.value);
	};

	const handleConifirmSubmit = (
		value: IPostBankAccountPayload | undefined | string,
		name: string,
	) => {
		setShowModal(false);
		if (name === 'account') {
			setOpenModal(true);
			value && setAccountSubmit(value);
			return;
		}
		if (cardStatus?.is_verified) {
			setOpenModal(true);
			// setShowModalVerify(true);
			// handleSubmit();
		} else {
			setShowModalVerify(true);
		}
	};

	const handleChangeAmount = (amount: string) => {
		setAmountField(amount);
		setValueSubmit({ ...valueSubmit, amount });
	};

	const handlePaymentChange = (value: string) => {
		switch (value) {
			case 'cartubank':
				dataLayer.push({
					event: 'withdraw_card',
				});
				break;
			case 'bank_transfer':
				dataLayer.push({
					event: 'withdraw_bank_transfer',
				});
				break;
			case 'paypal':
				dataLayer.push({
					event: 'withdraw_paypal',
				});
				break;
			default:
				break;
		}
		setSelectedPayment(value);
	};

	const handleSubmitAccount = (value: IPostBankAccountPayload | undefined) => {
		const payload: IGetPaymentBankAccountRequest = {
			params: {
				bank_account_id: checkCard?.id,
				email_code: verify?.email ? String(verify?.email?.trim()) : undefined,
				amount: Number(amountField),
				asset_id: Number(fiatId),
				totp: verify.google ? String(verify.google?.trim()) : undefined,
				sms_code: verify.phone ? String(verify.phone?.trim()) : undefined,
			},
			onSuccess: () => {
				setDisableVrify(false);
				history.push(`/submited`);
			},
			onError: (error) => {
				setDisableVrify(false);
				const errorMessage = getErrorMessage(error);
				if (!errorMessage) {
					return;
				}
				if (emailCodeErrors.includes(errorMessage)) {
					setEmailCodeIsError(true);
					return;
				}
				if (smsCodeErrors.includes(errorMessage)) {
					setSmsCodeIsError(true);
					return;
				}
				if (totpErrors.includes(errorMessage)) {
					setTotpCodeIsError(true);
					return;
				}
				history.push(`/failure`);
			},
		};
		if (bankAccountTransfer) {
			payload.params.bank_account_id = bankAccountTransfer?.id;
			setDisableVrify(true);
			dispatch(getBankTransferWithdrawalRequest(payload));
			return;
		}
		if (checkCard) {
			setDisableVrify(true);
			dispatch(getBankTransferWithdrawalRequest(payload));
		}
	};

	const [bankTransferConfirm, setBankTransferConfirm] = useState(false);
	const withdrawalFee = useSelector(getWithdrawalFee);

	const acitveSelec: any = {
		currency: (
			<div className="create-offer">
				<div className="create-offer__header">
					<Link to="/fiat-and-spot" className="back-step">
						<div className="back-step__img">
							<span className="back-step__arrow icon-arrow" />
						</div>
						<span className="back-step__text">{String(L.translate('Withdraw.page_title'))}</span>
					</Link>
				</div>
				<div className="create-offer__main create-offer__main--border-none create-offer__main--padding-big">
					<div className="create-offer__form">
						<div className="form-block">
							<div className="enter-value">
								<span className="block-label block-label--grey">
									1. {String(L.translate('WithdrawStable.select_currency'))}
								</span>
								<span className="enter-value__label enter-value__label--padding">
									{String(L.translate('WithdrawStable.currency'))}
								</span>
								<div className="enter-value__field">
									<DCDropdown
										value={selectWallet}
										onChange={handleWalletSelect}
										options={
											walletsList
												?.filter((item) => item.asset.type !== 'fiat')
												?.sort((a, b) => {
													if (isStablecoin(a) && !isStablecoin(b)) {
														return -1;
													}
													if (!isStablecoin(a) && isStablecoin(b)) {
														return 1;
													}
													return 0;
												}) || []
										}
										placeholder={String(L.translate('Base.select_currency_placeholder'))}
									/>
								</div>
							</div>
						</div>
						{selectWallet &&
							(isStablecoin(selectWallet) ? (
								<SelecetCurrencyPayment
									setActiveView={setActiveView}
									selectWallet={selectWallet}
									amountField={amountField}
									setSelectWallet={setSelectWallet}
									selectedPayment={selectedPayment}
									setSelectedPayment={handlePaymentChange}
									setCheckCard={setCheckCard}
									setAmountField={handleChangeAmount}
									setShowModal={setShowModal}
									checkCard={checkCard}
									setVerifyModal={setOpenModal}
									selectWalletNetworks={selectWalletNetworks}
									setSelectWalletNetworks={setSelectWalletNetworks}
									walletAddressTo={walletAddressTo}
									setWalletAddressTo={setWalletAddressTo}
									setBankTransferConfirm={setBankTransferConfirm}
								/>
							) : (
								<CreateWithdrawCrypto
									selectWallet={selectWallet}
									setSelectWallet={setSelectWallet}
								/>
							))}
					</div>
				</div>
			</div>
		),
		addCard:
			selectedPayment === 'cartubank' ? (
				<AddCard
					setActiveView={setActiveView}
					selectWallet={selectWallet}
					amountField={amountField}
					setShowModal={setShowModal}
					setValueSubmit={setValueSubmit}
					checkCard={checkCard}
					title="Withdrawal"
					setCheckCard={handleCheckCard}
					setChouse={setChouse}
					cardList={cards}
				/>
			) : (
				<AddAccount
					setActiveView={setActiveView}
					selectWallet={selectWallet}
					setSelectWallet={setSelectWallet}
					amountField={amountField}
					setShowModal={setShowModal}
					setValueSubmit={setValueSubmit}
					checkCard={checkCard}
					title="Withdrawal"
					type="Withdrawal"
					setCheckCard={handleCheckCard}
					handleSubmit={(iem) => {
						return handleConifirmSubmit(iem, 'account');
					}}
					setChouse={setChouse}
					list={list?.filter((item) => item.currency_code === fiatCode && item.status === 'verified')}
					goBack={backFromChooseBank}
				/>
			),
		// iframe: <IFrame src={frame} height="100%" width="100%" />,
	};

	return (
		<div className="content-block content-block--flex content-block--padding-none content-block--border withdrawal-stable">
			{acitveSelec[activeView]}

			<ConfirmPopup
				openModal={showModal}
				closeModal={() => setShowModal(false)}
				message={String(checkCard?.holder_name)}
				valueSubmit={valueSubmit}
				checkCard={checkCard}
				selectWallet={selectWallet}
				title="Withdrawal Confirmation"
				handleSubmit={() => handleConifirmSubmit(String(checkCard?.holder_name), 'withdrawal')}
			/>
			<CvcVerify
				openModal={showModalVerify}
				closeModal={() => setShowModalVerify(false)}
				message={String(checkCard?.holder_name)}
				valueSubmit={valueSubmit}
				checkCard={checkCard}
				selectWallet={selectWallet}
				handleSubmit={handleSubmitCard}
				disableButton={disableButton}
				cvc={cvc}
				setCvc={setCvc}
			/>
			<SicurityVerify
				openModal={openModal}
				closeModal={closeModal}
				message={String(checkCard?.holder_name)}
				disableButton={disableVrify}
				handleSubmit={selectedPayment === 'cartubank' ? handleSubmit : handleSubmitAccount}
				verify={verify}
				setVerify={setVerify}
				emailCodeIsError={emailCodeIsError}
				smsCodeIsError={smsCodeIsError}
				totpCodeIsError={totpCodeIsError}
				setEmailCodeIsError={setEmailCodeIsError}
				setSmsCodeIsError={setSmsCodeIsError}
				setTotpCodeIsError={setTotpCodeIsError}
			/>

			<BankTransferConfirmPopup
				openModal={bankTransferConfirm}
				closeModal={() => setBankTransferConfirm(false)}
				message=""
				data={checkCard}
				fee={withdrawalFee}
				amount={Number(amountField)}
				selectWallet={selectWallet}
				handleSubmit={(iem: any) => {
					return handleConifirmSubmit(iem, 'account');
				}}
			/>
		</div>
	);
};

export default CreateWithdrawFiat;
