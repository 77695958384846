// TODO: Переводы
import { useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getCardsRequest } from 'redux/reducers/cards/reducer';
import { getCardList } from 'redux/reducers/cards/selectors';
import AddCardPopUp from 'layouts-elements/popups/AddCardPopUp';
import DeleteCardPopUp from 'layouts-elements/popups/DeleteCardPopUp';
import DeleteBankAccountPopUp from 'layouts-elements/popups/DeleteBankAccountPopUp';
import { ICardItem } from 'redux/reducers/cards/types';
import { SelectPayment } from 'ui/PaymentsMethod/SelectPayment';
import { getBankTransferRequest } from 'redux/reducers/payments/reducer';
import SuccessPopup from 'layouts-elements/popups/SuccessPopup';
import { getBankAccounts } from 'redux/reducers/payments/selectors';
import { CardTable } from './CardTable/CardTable';
import { TransferTable } from './TransferTable/TransferTable';
import { PopusModal } from './Popups';

export interface IAction {
	action: string;
	data: ICardItem;
}

const PaymentMethodsPage = () => {
	const dispatch = useDispatch();
	const cardList = useSelector(getCardList);
	const list = useSelector(getBankAccounts);
	const [openPopUp, setOpenPopUp] = useState(false);
	const [method, setMethod] = useState('bank');
	const [action, setAction] = useState<IAction | null>(null);
	const [actionBank, setBankAction] = useState<IAction | null>(null);
	const [showPayment, setPaymentModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [type, setType] = useState('Bank account');

	const paymnet = ['Card', 'Bank account'];

	const popUpDeleteClose = () => {
		setAction(null);
	};
	const popUpDeleteBankClose = () => {
		setBankAction(null);
	};

	useEffect(() => {
		dispatch(getCardsRequest());
		dispatch(getBankTransferRequest());
	}, [dispatch]);

	const handlePopUpClose = () => {
		setOpenPopUp(false);
	};

	// const handlePopUpDisplay = () => {
	// 	setOpenPopUp(true);
	// };

	const selectPayment = (val: string) => {
		setType(val);
		setShowModal(false);
		setPaymentModal(true);
	};

	const handleStatus = (value: string, status: string) => {
		setPaymentModal(false);
		status === 'success' && setShowSuccessModal(true);
	};

	const paymentTable = {
		card: <CardTable list={cardList} setAction={setAction} />,
		bank: <TransferTable setAction={setBankAction} />,
	};

	const viewTable = cardList || list;
	const viewTableLenght = cardList?.length || list?.length;

	useEffect(() => {
		if (!cardList?.length && list?.length) {
			setMethod('bank');
		}
	}, [list, cardList]);

	return (
		<>
			<AddCardPopUp popUpOpen={openPopUp} popUpClose={handlePopUpClose} disable name="" />
			<DeleteCardPopUp
				popUpOpen={action?.action === 'delete'}
				popUpClose={popUpDeleteClose}
				data={action?.data}
				category={String(method)}
			/>
			<DeleteBankAccountPopUp
				popUpOpen={actionBank?.action === 'delete'}
				popUpClose={popUpDeleteBankClose}
				data={actionBank?.data}
			/>
			<SelectPayment
				openModal={showModal}
				closeModal={() => setShowModal(false)}
				choosePayment={selectPayment}
				payment={paymnet}
				currentPayment="Card"
			/>
			<PopusModal
				openModal={showPayment}
				closeModal={() => setPaymentModal(false)}
				type={type}
				handleStatus={handleStatus}
				// handleSubmit={handleSubmit}
			/>
			<SuccessPopup
				openModal={showSuccessModal}
				closeModal={() => setShowSuccessModal(false)}
				message="success"
			/>
			<div className="main-content__header">
				<h2 className="section-title">{L.translate('Account.PaymentMethods.title')}</h2>
			</div>
			{viewTable && (
				<div className="content-block content-block--padding-none">
					{viewTableLenght ? (
						<div className="content-block__main content-block__main--pt24">
							<div className="content-block-wrapper">
								<p className="desc desc--w730">
									{L.translate('Account.PaymentMethods.page_description')}
								</p>
								<button
									// onClick={() => setShowModal(true)}
									onClick={() => setPaymentModal(true)}
									type="button"
									className="button button--second-type button--payment-method "
								>
									{L.translate('Account.PaymentMethods.add_payment_method')}
								</button>
							</div>

							{/* <div className="payment-method-p2p payment-method-p2p--user-center">
								<div className="payment-method-p2p__tabs">
									<button
										onClick={() => setMethod('card')}
										type="button"
										className={`${method === 'card' ? 'active' : ''}`}
									>
										{L.translate('Account.PaymentMethods.card')}
									</button>
									<button
										onClick={() => setMethod('bank')}
										type="button"
										className={`${method === 'bank' ? 'active' : ''}`}
									>
										{L.translate('Account.PaymentMethods.bank_transfer')}
									</button>
								</div>
							</div> */}
							{paymentTable[method as keyof typeof paymentTable]}
						</div>
					) : (
						<div className="content-block__main content-block__main--padding">
							<p className="desc">{L.translate('Account.PaymentMethods.page_description')}</p>
							<div className="payment-empty">
								<span className="payment-empty__icon icon-card-add2" />
								<span className="payment-empty__title">
									{L.translate('Account.PaymentMethods.you_have_no_payments')}
								</span>
								<button
									// onClick={handlePopUpDisplay}
									onClick={() => setPaymentModal(true)}
									type="button"
									className="button button--second-type payment-empty__btn"
								>
									{L.translate('Account.PaymentMethods.add_payment_method')}
								</button>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default PaymentMethodsPage;
