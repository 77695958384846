import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import { ChangeEvent, FC, MouseEvent, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getSpotUserOpenOrders } from 'redux/reducers/spotTrade/selectors';
import { getOpenOrdersRequest } from 'redux/reducers/spotTrade/reducer';
import LoginOrRegister from 'ui/LoginOrRegister';
import OpenOrders from './OpenOrders';
import OrdersHistory from './OrdersHistory';
import TradesHitory from './TradesHitory';
import { getAssetPairsRequestFavorite } from '../../../redux/reducers/assetPairs/reducer';

const SpotHistoryTables: FC = () => {
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const openOrders = useSelector(getSpotUserOpenOrders);
	const [currentTableTab, setCurrentTableTab] = useState('open');
	const [currentType, setCurrentType] = useState('spot');
	const location = useLocation();
	const marginTypeSearch = new URLSearchParams(location.search).get('type') || null;

	const [otherPairsIsHide, setOtherPairsIsHide] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAssetPairsRequestFavorite());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (location.pathname.includes('MarginTrading')) {
			setCurrentType(marginTypeSearch === 'cross' ? 'margin' : 'isolated');
		} else {
			setCurrentType('spot');
		}
	}, [location.pathname, marginTypeSearch]);

	useEffect(() => {
		if (authIsAuthenticated) {
			if (marginTypeSearch === 'cross') {
				dispatch(
					getOpenOrdersRequest({
						params: {
							per_page: 100,
							margin: 'margin',
						},
					}),
				);
				return;
			}
			if (marginTypeSearch === 'isolated') {
				dispatch(
					getOpenOrdersRequest({
						params: {
							per_page: 100,
							isolated: 'isolated',
						},
					}),
				);
				return;
			}
			dispatch(
				getOpenOrdersRequest({
					params: {
						per_page: 100,
					},
				}),
			);
		}
	}, [dispatch, authIsAuthenticated, marginTypeSearch]);

	const handleChangeCurrentTableTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setCurrentTableTab(name);
	};

	const totalOpenOrders = openOrders?.total || 0;

	const currentTable = {
		open: <OpenOrders otherPairsIsHide={otherPairsIsHide} />,
		closed: <OrdersHistory otherPairsIsHide={otherPairsIsHide} />,
		trades: <TradesHitory otherPairsIsHide={otherPairsIsHide} />,
	};

	const handleChangeOtherPairsIsHide = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;

		setOtherPairsIsHide(checked);
	};

	return (
		<SocketWithPrivateTopicConnectWrapper topicName={`open_orders:${currentType}`}>
			<SocketWithPrivateTopicConnectWrapper topicName={`closed_orders:${currentType}`}>
				<div className="market-block">
					<div className="market-footer">
						<div className="market-footer__top">
							<div className="market-tabs market-footer__tabs">
								<button
									className={`market-tabs__item ${
										currentTableTab === 'open' ? 'market-tabs__item--active' : ''
									}`}
									type="button"
									name="open"
									onClick={handleChangeCurrentTableTab}
								>
									{String(L.translate('Trade.Spot.SpotHistoryTables.open_orders_tab_name'))} (
									{totalOpenOrders})
								</button>
								<button
									className={`market-tabs__item ${
										currentTableTab === 'closed' ? 'market-tabs__item--active' : ''
									}`}
									type="button"
									name="closed"
									onClick={handleChangeCurrentTableTab}
								>
									{String(L.translate('Trade.Spot.SpotHistoryTables.order_history_tab_name'))}
								</button>
								<button
									className={`market-tabs__item ${
										currentTableTab === 'trades' ? 'market-tabs__item--active' : ''
									}`}
									type="button"
									name="trades"
									onClick={handleChangeCurrentTableTab}
								>
									{String(L.translate('Trade.Spot.SpotHistoryTables.trade_history_tab_name'))}
								</button>
							</div>
							<div className="checkbox checkbox--margin-none">
								<label className="checkbox__label">
									<input
										type="checkbox"
										className="hidden"
										checked={otherPairsIsHide}
										onChange={handleChangeOtherPairsIsHide}
									/>
									<span className=" checkbox__item">
										<span className="checkbox__item-icon">
											<span className="icon-Checkbox" />
										</span>
									</span>
									<span className="checkbox__text">
										{String(L.translate('Trade.Spot.SpotHistoryTables.hide_other_pairs_checkbox'))}
									</span>
								</label>
							</div>
						</div>
						{authIsAuthenticated ? (
							currentTable[currentTableTab as keyof typeof currentTable]
						) : (
							<div className="table-history-login-register-wrapper">
								<LoginOrRegister />
							</div>
						)}
					</div>
				</div>
			</SocketWithPrivateTopicConnectWrapper>
		</SocketWithPrivateTopicConnectWrapper>
	);
};

export default SpotHistoryTables;
