import { spawn } from 'redux-saga/effects';
import { appSaga } from './reducers/app/saga';
import { assetPairsSaga } from './reducers/assetPairs/saga';
import { assetsSaga } from './reducers/assets/saga';
import { authSaga } from './reducers/auth/saga';
import { settingsSaga } from './reducers/settings/saga';
import { socketsSaga } from './reducers/sockets/saga';
import { spotTradeSaga } from './reducers/spotTrade/saga';
import { tradingSettingsSaga } from './reducers/tradingSettings/saga';
import { walletsSaga } from './reducers/wallets/saga';
import { marginWalletsSaga } from './reducers/marginWallets/saga';
import { ordersSaga } from './reducers/orders/saga';
import { withdrawCryptoSaga } from './reducers/withdrawCrypto/saga';
import { withdrawFiatSaga } from './reducers/withdrawFiat/saga';
import { convertSaga } from './reducers/convert/saga';
import { kycSaga } from './reducers/kyc/saga';
import { p2pSaga } from './reducers/p2p/saga';
import { paymentsSaga } from './reducers/payments/saga';
import { cardsSaga } from './reducers/cards/saga';
import { ourPricesSaga } from './reducers/ourPrices/saga';
import { referralSaga } from './reducers/referral/saga';

// ==========================================:
export default function* rootSagas() {
	yield spawn(appSaga);
	yield spawn(assetPairsSaga);
	yield spawn(assetsSaga);
	yield spawn(authSaga);
	yield spawn(settingsSaga);
	yield spawn(socketsSaga);
	yield spawn(spotTradeSaga);
	yield spawn(tradingSettingsSaga);
	yield spawn(walletsSaga);
	yield spawn(marginWalletsSaga);
	yield spawn(ordersSaga);
	yield spawn(withdrawCryptoSaga);
	yield spawn(convertSaga);
	yield spawn(kycSaga);
	yield spawn(p2pSaga);
	yield spawn(paymentsSaga);
	yield spawn(withdrawFiatSaga);
	yield spawn(cardsSaga);
	yield spawn(ourPricesSaga);
	yield spawn(referralSaga);
}
