import { FC, useEffect } from 'react';
import Dashboard from 'layouts/Dashboard';
import P2PWallet from 'components/P2PWallet';
import { useDispatch, useSelector } from 'react-redux';
import { getP2PBalancesListRequest } from 'redux/reducers/p2p/reducer';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { getAccountP2P } from 'redux/reducers/auth/selectors';

// ==========================================:
const P2P: FC = () => {
	const dispatch = useDispatch();
	const accountP2P = useSelector(getAccountP2P);

	useEffect(() => {
		if (accountP2P) {
			dispatch(getAssetsRequest());
			dispatch(getP2PBalancesListRequest());
		}
	}, [dispatch, accountP2P]);
	return (
		<Dashboard title="P2P" showVerificBanner>
			<P2PWallet />
		</Dashboard>
	);
};

export default P2P;
