import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { WarningSircle } from 'assets/svg-icon';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { getTradePaySellRequest } from 'redux/reducers/p2p/reducer';
import { getTradePaySell } from 'redux/reducers/p2p/selectors';
import useTimer from 'hooks/useTimer';
import { IAlreadyTradingPopup } from './types';

const AlreadyTradingPopup: FC<IAlreadyTradingPopup> = ({ isOpen, onClose, tradeId }) => {
	const dispatch = useDispatch();
	const trade = useSelector(getTradePaySell);

	const { advertisement, asset_code: assetCode, created_at } = trade || {};

	const { current_fiat_code, type, amount_min, amount_max, user } = advertisement || {};

	const { username = '' } = user || {};

	const { refHours, refMinutes, refSeconds } = useTimer(
		created_at,
		(trade && trade.advertisement && trade.advertisement.payment_window) || 20,
	);

	useEffect(() => {
		dispatch(getTradePaySellRequest({ trade_id: String(tradeId) }));
	}, [dispatch, tradeId]);

	return (
		<Popup open={isOpen} closeOnDocumentClick onClose={onClose} closeOnEscape lockScroll>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<button type="button" className="popup-close" onClick={onClose}>
							<span className="popup-close__icon icon-close" />
						</button>
						<div className="popup-header">
							<div className="popup-icon popup-icon--alert popup-icon--mt-20">
								<WarningSircle />
							</div>
							<p className="popup-header__title">{L.translate('P2P.AlreadyTradingPopup.title')}</p>
						</div>
						<div className="popup-body popup-body--margin-none">
							<div className="popup-text  popup-text--center popup-text--extra-padding">
								<p>{L.translate('P2P.AlreadyTradingPopup.text')}</p>
							</div>
							<div className="dash-devider">
								<svg
									width="514"
									height="1"
									viewBox="0 0 514 1"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M0 0.5H514" stroke="#DADADA" strokeDasharray="10 10" />
								</svg>
							</div>
							<div className="withdrawal-options  withdrawal-options--type2 withdrawal-options--no-border  withdrawal-options--mt-24 withdrawal-options--no-pt">
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('P2P.AlreadyTradingPopup.ad_number')}
									</span>
									<div className="p2p-provider">
										<div className="p2p-provider__letter"> {username?.[0]?.toUpperCase()} </div>
										<p className="p2p-provider__name"> {username} </p>
										<span className="p2p-provider__check p2p-provider__check--color2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="8"
												height="6"
												viewBox="0 0 8 6"
												fill="none"
											>
												<path
													d="M1.16699 2.99995L3.05366 4.88661L6.83366 1.11328"
													stroke="white"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</span>
									</div>
								</div>
							</div>
							<div className="withdrawal-options withdrawal-options--mt-24">
								<div className="p2p-post-details">
									<div className="p2p-post-details__group p2p-post-details__group--column">
										<span className="p2p-post-details__type p2p-post-details__type--green">
											{type === 'buy' ? L.translate('Global.buy') : L.translate('Global.sell')}
										</span>
										<span className="p2p-post-details__group-currency">
											<span>
												{type === 'buy'
													? current_fiat_code?.toUpperCase()
													: assetCode?.toUpperCase()}
											</span>
											<span className="p2p-post-details__extra">
												{' '}
												{L.translate('P2P.AlreadyTradingPopup.with')}{' '}
											</span>
											<span>
												{type === 'buy'
													? assetCode?.toUpperCase()
													: current_fiat_code?.toUpperCase()}
											</span>
										</span>
									</div>
									<div className="p2p-post-timer">
										<p>{L.translate('P2P.AlreadyTradingPopup.payment_to_be_made')}:</p>
										<p className="p2p-post-timer__item">
											<span className="payment-descr-timer">
												<span className="timer-number" ref={refHours}>
													00
												</span>
												:
												<span className="timer-number" ref={refMinutes}>
													00
												</span>
												:
												<span className="timer-number" ref={refSeconds}>
													00
												</span>
											</span>
										</p>
									</div>
								</div>
								<div className="spent-value spent-value--bottom-margin">
									<p className="spent-value__item spent-value__item--fw-600">
										{fixedCropNumber(
											type === 'buy' ? Number(trade?.price) : Number(trade?.amount),
											10,
										)}{' '}
										{type === 'buy' ? current_fiat_code : assetCode?.toUpperCase()}
									</p>
								</div>
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">
										{L.translate('P2P.AlreadyTradingPopup.amount')}
									</span>
									<span className="withdrawal-option__desc">
										{fixedCropNumber(
											type === 'buy' ? Number(trade?.amount) : Number(trade?.price),
											10,
										)}{' '}
										{type === 'buy' ? assetCode?.toUpperCase() : current_fiat_code}
									</span>
								</div>
								<div className="withdrawal-option ">
									<span className="withdrawal-option__title">Limit</span>
									<span className="withdrawal-option__desc">
										{amount_min} - {amount_max} {assetCode?.toUpperCase()}
									</span>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--sb popup-submit--more-mt">
							<button className="button  button--full-width " type="button" onClick={onClose}>
								{L.translate('P2P.AlreadyTradingPopup.submit_btn')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default AlreadyTradingPopup;
