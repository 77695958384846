import { FC } from 'react';
import L from 'i18n-react';
import { useDispatch } from 'react-redux';
import { putEvidenceRequest } from 'redux/reducers/p2p/reducer';
import PhotosBox from '../PhotosBox';
import { IPhotoBox } from './type';
/* eslint-disable no-debugger */
const ExistedPhotosBoxWrap: FC<IPhotoBox> = ({ photos, getExistedPhotos }) => {
	const dispatch = useDispatch();

	const deletePhoto = (id: number) => {
		dispatch(putEvidenceRequest({ id, getExistedPhotos }));
	};
	return (
		<>
			<p className="market-buy-info__title">{L.translate('P2P.t_0473')}</p>
			{!!photos?.length && <PhotosBox photos={photos} deleteFile={deletePhoto} />}
		</>
	);
};

export default ExistedPhotosBoxWrap;
