import { FC } from 'react';
import { IHeaderDropdownList } from 'layouts-elements/Header/types';
import { MainNavItem } from './MainNavItem/MainNavItem';

// ==========================================:

const MainNav: FC<{ mainNavList: IHeaderDropdownList[] }> = ({ mainNavList }) => {
	return (
		<nav className="main-nav header__nav">
			<ul className="main-nav__list">
				{mainNavList.map((navListItem, idx) => (
					// eslint-disable-next-line react/no-array-index-key
					<MainNavItem {...navListItem} key={`${navListItem.order}-${idx}`} />
				))}
			</ul>
		</nav>
	);
};

export default MainNav;
