export const trimComment = (comment: string): string => {
	if (comment.length > 17) {
		const leftPart = comment?.slice(0, 8);
		const rightPart = comment?.slice(-8);

		return `${leftPart}...${rightPart}`;
	}
	return comment;
};

export const trimAddress = (address: string): string => {
	const leftPart = address?.slice(0, 8);
	const rightPart = address?.slice(-8);

	return `${leftPart}...${rightPart}`;
};

export const trimTransactionHash = (hash: string): string => {
	const leftPart = hash?.slice(0, 8);
	const rightPart = hash?.slice(-8);

	return `${leftPart}...${rightPart}`;
};

export const trimTransactionHashText = (hash: string): string => {
	const leftPart = hash?.slice(13, 21);
	const rightPart = hash?.slice(-8);

	return `${leftPart}...${rightPart}`;
};

export const trimFileName = (name: string): string => {
	const trimName = name.length > 20 ? `${name.slice(0, 20)}...` : name;
	return trimName;
};

export const convertMessage = (message: string) => {
	const firstLetter: string = message.charAt(0).toUpperCase();
	const msg = `${firstLetter}${String(message.slice(1, message.length).split('_').join(' '))}`;
	return msg;
};
