import { FC, useMemo } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { numberFormat } from 'services/utils/numberFormat';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { isStablecoin } from 'services/utils/wallets';
import { ISpotAndFiatBalancesProps } from './types';

const SpotAndFiatBalances: FC<ISpotAndFiatBalancesProps> = ({ balancesIsHide }) => {
	const walletsList = useSelector(getWalletsList);

	const totalBalanceInBTC = useMemo(() => {
		return walletsList?.length
			? walletsList
					.filter((wallet) => wallet.asset.type !== 'fiat')
					.reduce((acc: number, wallet: IWalletItem) => acc + Number(wallet.btc_value), 0)
			: 0;
	}, [walletsList]);

	const totalBalanceInUSD = useMemo(() => {
		return walletsList?.length
			? walletsList
					.filter((wallet) => wallet.asset.type !== 'fiat')
					.reduce((acc: number, wallet: IWalletItem) => acc + Number(wallet.usd_value), 0)
			: 0;
	}, [walletsList]);

	const spotTotalBalanceInBTC = useMemo(() => {
		return walletsList?.length
			? walletsList
					.filter((wallet) => wallet.asset.type !== 'fiat' && !isStablecoin(wallet))
					.reduce((acc: number, wallet: IWalletItem) => acc + Number(wallet.btc_value), 0)
			: 0;
	}, [walletsList]);

	const spotTotalBalanceInUSD = useMemo(() => {
		return walletsList?.length
			? walletsList
					.filter((wallet) => wallet.asset.type !== 'fiat' && !isStablecoin(wallet))
					.reduce((acc: number, wallet: IWalletItem) => acc + Number(wallet.usd_value), 0)
			: 0;
	}, [walletsList]);

	// const fiatTotalBalanceInBTC = useMemo(() => {
	// 	return walletsList?.length
	// 		? walletsList
	// 				.filter((wallet) => wallet.asset.type === 'fiat')
	// 				.reduce((acc: number, wallet: IWalletItem) => acc + Number(wallet.btc_value), 0)
	// 		: 0;
	// }, [walletsList]);

	// const fiatTotalBalanceInUSD = useMemo(() => {
	// 	return walletsList?.length
	// 		? walletsList
	// 				.filter((wallet) => wallet.asset.type === 'fiat')
	// 				.reduce((acc: number, wallet: IWalletItem) => acc + Number(wallet.usd_value), 0)
	// 		: 0;
	// }, [walletsList]);

	const stableTotalBalanceInBTC = useMemo(() => {
		return walletsList?.length
			? walletsList
					.filter(isStablecoin)
					.reduce((acc: number, wallet: IWalletItem) => acc + Number(wallet.btc_value), 0)
			: 0;
	}, [walletsList]);

	const stableTotalBalanceInUSD = useMemo(() => {
		return walletsList?.length
			? walletsList
					.filter(isStablecoin)
					.reduce((acc: number, wallet: IWalletItem) => acc + Number(wallet.usd_value), 0)
			: 0;
	}, [walletsList]);

	return (
		<div className="content-block">
			<div className="balance-list">
				<div className="balance-item">
					<span className="balance-item__title">
						{String(L.translate('SpotAndFiat.total_balance'))}
					</span>
					<span className="balance-item__value">
						<span className="balance-item__value-num">
							{balancesIsHide
								? '********'
								: convertExponentialToDecimal(fixedCropNumber(totalBalanceInBTC, 10))}
						</span>{' '}
						{balancesIsHide ? '' : 'BTC'} <br />
						{balancesIsHide ? '********' : `≈ $ ${numberFormat(totalBalanceInUSD, 'en-EN')}`}
					</span>
				</div>
				<div className="balance-item">
					<span className="balance-item__title">
						{String(L.translate('SpotAndFiat.crypto_balance'))}
					</span>
					<span className="balance-item__value">
						<span className="balance-item__value-num">
							{balancesIsHide
								? '********'
								: convertExponentialToDecimal(fixedCropNumber(spotTotalBalanceInBTC, 10))}
						</span>{' '}
						{balancesIsHide ? '' : 'BTC'} <br />
						{balancesIsHide ? '********' : `≈ $ ${numberFormat(spotTotalBalanceInUSD, 'en-EN')}`}
					</span>
				</div>
				<div className="balance-item">
					<span className="balance-item__title">
						{String(L.translate('SpotAndFiat.stablecoin_balance'))}
					</span>
					<span className="balance-item__value">
						<span className="balance-item__value-num">
							{balancesIsHide
								? '********'
								: convertExponentialToDecimal(fixedCropNumber(stableTotalBalanceInBTC, 10))}
						</span>{' '}
						{balancesIsHide ? '' : 'BTC'} <br />
						{balancesIsHide ? '********' : `≈ $ ${numberFormat(stableTotalBalanceInUSD, 'en-EN')}`}
					</span>
				</div>
			</div>
		</div>
	);
};

export default SpotAndFiatBalances;
