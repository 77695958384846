/* eslint-disable import/no-relative-packages */
import { FC } from 'react';
import '../../../../node_modules/flag-icons/css/flag-icons.min.css';

type ILangItemProps = {
	name: string;
	selectHandler: any;
};

const LanguageItem: FC<ILangItemProps> = ({ name, selectHandler }) => {
	return (
		<div className="select__drop-link">
			<button name={name} type="button" className="select__drop-link" onClick={selectHandler}>
				<span className="select__drop-icon">
					<span className={`fi fi-${name === 'EN' ? 'us' : name.toLowerCase()}`} />
				</span>
				<span className="select__drop-text">{name}</span>
			</button>
		</div>
	);
};

export default LanguageItem;
